<div class="col-md-12 p-7">
        <div  class="notification" [ngClass]="darkMode ? 'smooth-dark' : 'notification'">
                <div  class="row">
                    <div  class="col-md-12">
                        <div  class="greetings blue_heading">{{ 'back_office.billing.subscription.plan_switch.subscription_plan' | translate }}<button aria-label="Close" class="close" data-dismiss="modal" type="button" style="float: right;color: #fff"><strong>✖</strong></button></div>
                    </div>
                </div>
            </div>
            <div  class="notification alert-danger" style="background: linear-gradient(#f96061, #860425); margin-bottom: 5px;" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                    <div  class="row">
                        <div  class="col-md-10">
                            <div  class="greetings red_heading">{{ 'back_office.billing.subscription.plan_switch.switch_to_this_plan' | translate }}</div>
                        </div>
                    </div>
                </div>   
            <div class="border-style pink-shade" [ngClass]="darkMode ? 'pink-shade-dark' : 'pink-shade'">
                <div class="row">
                    <div class="col-md-5">
                            <span class="sure_class">{{ 'back_office.billing.subscription.plan_switch.basic_ultimate' | translate }} ?</span>
                    </div>
                    <div class="col-md-7 pr-30">
                            <div class="radio_buttons">
                                    <label  class="control-label radio-style"><input  class="radio"  name="currency_conversion" type="radio" value="Enabled"><span  class="radio-checkmark"></span><span  class="title" style="padding-right: 25px;">{{ 'back_office.billing.subscription.plan_switch.continue_next_billing_cycle' | translate }}</span></label>
                                    <label  class="control-label radio-style"><input  class="radio"  name="currency_conversion" type="radio" value="Enabled"><span  class="radio-checkmark"></span><span  class="title">{{ 'back_office.billing.subscription.plan_switch.start_billing_from' | translate }}</span></label>
                            </div>
                    </div>
                </div>
                
                
    </div>
    <div class="" style="float: right;display: inline-flex;margin-top: 10px;" [ngClass]="darkMode ? 'dark-btn' : 'null'">
        <button  class="btn btn-primary btn-lg  hov" type="submit">{{ 'back_office.billing.subscription.plan_switch.save' | translate }}</button>
        <a  aria-label="Close" class="btn btn-danger btn-lg hov" data-dismiss="modal" href="#">{{ 'back_office.billing.subscription.plan_switch.cancel' | translate }}</a>
    </div>
</div>

