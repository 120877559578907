import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../../layouts/layout.service";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
declare var $:any;
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';

import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user = JSON.parse(localStorage.getItem('user'));
  collapse;

  Inventory = 'false';
  Reservation= 'false';
  Payments= 'false';
  Rates_Packages= 'false';
  Manage_Otas= 'false';
  Reports= 'false';
  Billing= 'false';
  House_Keeping= 'false';
  Back_Office = 'false';
  Arrivals = 'false'


  selected: string = 'dashboard';
  darkMode: boolean = false;
  constructor(
    public layout: LayoutService,
    public auth: AuthService,
    public helper: HelperService,
    public events: EventsService,
    public router: Router,

    private toastr: ToastrService,
  ) {
    this.helper.user_subject.subscribe(user => {
      this.user = user;
    });

    this.events.receiveDarkMode().subscribe((res:any)=>{
      this.darkMode = res.darkMode;
    })
  }

  ngOnInit() {
  //  console.log('sde')

  this.user = JSON.parse(localStorage.getItem('user'));

    this.collapse = this.layout.collapse;
    this.checkDarkMode();

    $(() => {
      $('.nav li').not('.nav li.bg-white').on('click', () => {
        if ($(window).width() < 456) {
          this.do_collapse();
        }
      });
    });


    this.permissions();

  }

  do_collapse(){
    this.layout.sidebar_collapse();
    this.collapse = this.layout.collapse;
  }

  logout(){
    this.auth.logout();
  }

  checkDarkMode(){
    let a = JSON.parse(localStorage.getItem('user'));
    if(a != null){
      let mode = a.mode;
      if(mode == 'dark'){
        this.darkMode = true;
      }
    }
  }

  permissions(){
    let data = this.user.permissions;
    console.log(this.user.role);
    for(let i = 0 ; i < data.length ; i++){
      if(this.user.permissions[i]['app_permission']['name'] == 'Inventory'){this.Inventory = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'Reservation'){this.Reservation = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'House Keeping'){this.House_Keeping = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'Reports'){this.Reports = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'Rates & Packages'){this.Rates_Packages = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'Back Office'){this.Back_Office = 'true' }
      if(this.user.permissions[i]['app_permission']['name'] == 'Arrivals'){this.Arrivals = 'true' }

      console.log(this.user.log)
    }


  }

  showerror() {
    this.toastr.error('Access Denied');
  }


}
