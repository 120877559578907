<div class="" id="hSet" style="padding-right: 5px;">

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
        <a class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{
      'back_office.hotel_setup.back_office' | translate }}</a>
        <a class="btn btn-link active hotel_set" *ngIf="property_typee == 'hotel_setup'"> {{
      'back_office.hotel_setup.hotel_setup' | translate }}</a>
        <a class="btn btn-link active hotel_set" *ngIf="property_typee == 'Vocational_Rental'"> Property Rental </a>
    </div>
    <div class="scroll no_scroll">
        <form class="form-horizontal back-form hotel-setup" [formGroup]="hotel_setup_form" (ngSubmit)="onSubmit()">

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">
                    <div class="col-md-4 grid_20">
                        <div class="border-style property-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.property_name' | translate }}" formControlName="name">
                        </div>
                        <div *ngIf="submitted && f.name.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.name.errors.required">{{ 'back_office.hotel_setup.property_name_is_required' | translate
                }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style phone-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.phone' | translate }}" formControlName="phone">
                        </div>
                        <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.phone.errors.required">{{ 'back_office.hotel_setup.phone_is_required' | translate
                }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style email-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.email_address' | translate }}" formControlName="email">
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.email.errors.required">{{ 'back_office.hotel_setup.email_address_is_required' | translate
                }}</strong>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">
                    <div class="col-md-4 grid_20">
                        <div class="border-style rooms-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.total_rooms' | translate }}" formControlName="room_count">
                        </div>
                        <div *ngIf="submitted && f.room_count.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.room_count.errors.required">{{ 'back_office.hotel_setup.total_room_is_required' |
                translate }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style address1-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.address_one' | translate }}" formControlName="address_one">
                        </div>
                        <div *ngIf="submitted && f.address_one.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.address_one.errors.required">{{ 'back_office.hotel_setup.address_one_is_required' |
                translate }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style address2-shade">
                            <input type="text" class="form-control" formControlName="address_two" placeholder="{{ 'back_office.hotel_setup.address_two' | translate }}">
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">
                    <div class="col-md-4 grid_20">
                        <div class="border-style country-shade">
                            <ng-select [items]="countries" id="count" bindLabel="full_name" bindValue="full_name" formControlName="country_id" placeholder="{{ 'back_office.hotel_setup.select_country' | translate }}" (change)="get_states($event)" [(ngModel)]="country" (change)="clearState()"
                                [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                        </div>
                        <div *ngIf="submitted && f.country_id.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.country_id.errors.required">{{ 'back_office.hotel_setup.country_is_required' | translate
                }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style state-shade">
                            <ng-select [items]="states" bindLabel="full_name" bindValue="full_name" id="cities" formControlName="state_id" placeholder="{{ 'back_office.hotel_setup.select_state' | translate }}" (change)="get_cities($event)" [(ngModel)]="state" (change)="clearCity()"
                                [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                        </div>
                        <div *ngIf="submitted && f.state_id.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.state_id.errors.required">{{ 'back_office.hotel_setup.state_is_required' | translate
                }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style city-shade">
                            <ng-select [items]="cities" bindLabel="name" bindValue="name" formControlName="city_id" placeholder="{{ 'back_office.hotel_setup.select_city' | translate }}" [(ngModel)]="city" [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                        </div>
                        <div *ngIf="submitted && f.city_id.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.city_id.errors.required">{{ 'back_office.hotel_setup.city_is_required' | translate
                }}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">

                    <div class="col-md-4 grid_20">
                        <div class="border-style post-shade">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.post_code' | translate }}" formControlName="postal_code">
                        </div>
                        <div *ngIf="submitted && f.postal_code.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.postal_code.errors.required">{{ 'back_office.hotel_setup.post_code_is_req' | translate
                }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style weekend-shade">
                            <ng-select [items]="days" bindLabel="name" bindValue="name" formControlName="weekend_starts" placeholder="{{ 'back_office.hotel_setup.select_weekend_start' | translate }}" [(ngModel)]="weekend_starts" [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                        </div>
                        <div *ngIf="submitted && f.weekend_starts.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.weekend_starts.errors.required">{{ 'back_office.hotel_setup.weekend_start_is_required' |
                translate }}</strong>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div class="border-style time-shade">
                            <ng-select [items]="timezones" bindLabel="title" bindValue="title" formControlName="timezone_id" placeholder="{{ 'back_office.hotel_setup.select_timezone' | translate }}" [(ngModel)]="timezone" [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                        </div>
                        <div *ngIf="submitted && f.timezone_id.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.timezone_id.errors.required">{{ 'back_office.hotel_setup.timezone_is_required' |
                translate }}</strong>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">

                    <div class="col-md-2 grid_20" style=" padding-left:20px; padding-right: 0px;">
                        <div class="border-style base-currency-shade" style="display: flex; align-items: center; background: #f3f3f3;" [ngClass]="darkMode ? 'dark-drop-2' : ' null '">
                            <ng-select [items]="currencies" bindLabel="symbol" formControlName="currency_id" bindValue="symbol" placeholder="{{ 'back_office.hotel_setup.select_currency' | translate }}" [(ngModel)]="currency" [ngClass]="darkMode ? 'dark-drop' : ' null '" style="width: 100%;"></ng-select>

                            <i class="fas fa-info-circle" style="margin-left: 13px; font-size: 15px; position: absolute; right: 35px;">
                              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                                style="width:226px; padding:0px 0px 5px 0px;left: 50%;top: 100%;">
                                <p class="small text-danger" style="padding: 0px 5px;">
                                  <strong>{{ 'back_office.hotel_setup.currency_note' | translate }}</strong>
                                </p>
                              </span>
                            </i>

                        </div>
                        <div *ngIf="submitted && f.currency_id.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.currency_id.errors.required">{{ 'back_office.hotel_setup.currency_is_required' |
                translate }}</strong>
                        </div>

                    </div>
                    <div class="col-md-2 grid_21" style="padding-right: 0px; padding-left:5px">
                        <div class="border-style rate-shade" style="display: flex; align-items: center;  background: #f3f3f3;" [ngClass]="darkMode ? 'dark-drop-2' : ' null '">
                            <input type="text" class="form-control" placeholder="{{ 'back_office.hotel_setup.min_rate' | translate }}" formControlName="min_rate" style="width: 350px; ">

                            <i class="fas fa-info-circle" style="font-size: 15px; margin-right: 10px !important; margin-right: 20px !important;">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="padding: 0px 0px 5px 0px; left: 92%;  width: 425px;">
                  <p class="text-danger small" style="padding: 0px 5px;">
                    <strong>
                      {{ 'back_office.hotel_setup.min_rate_note' | translate }}
                    </strong>
                  </p>
                </span>
              </i>

                        </div>
                        <div *ngIf="submitted && f.min_rate.errors" class="small text-danger no-margin invalid-feedback">
                            <strong *ngIf="f.min_rate.errors.required">{{ 'back_office.hotel_setup.min_rate_is_req' | translate
                }}</strong>
                        </div>
                    </div>

                    <div class="col-md-4" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '" style="padding: 0 0px 0 5px;">
                      <div class="border-style color-pt bhs-2" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" style="display: flex; justify-content: space-around; padding-top: 0px;">
                       <div class=" bhs-17 post-shade labelFlex" style="align-items: center;">
                        <label style="margin: 0; margin-bottom: 0px; font-size: 14px; padding:5px 12px 5px 0px;">Clock Format</label>
                       </div>

                        <!-- <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="font-size: 14px;">
                          <label data-toggle="tooltip" title="12 Hr" class="control-label radio-style chbx ellipses hovbulit " style="padding-top: 11px !important;"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="clockformat" value="12hr" formControlName="clockformat">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'" id="type12hr" style="top: 13px !important;"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type12">12 Hr</span>
                          </label>
                        </div>
                        <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'" style="padding-left: 12% !important; font-size: 14px;">
                          <label data-toggle="tooltip" title="24 Hr" class="control-label radio-style chbx ellipses hovbulit" style="padding-top: 11px !important;"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="clockformat" value="24hr" formControlName="clockformat">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"  id="type24hr" style="top: 13px !important; "></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type24">24 Hr</span>
                          </label>
                        </div> -->
                        <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="font-size: 14px;">
                          <label data-toggle="tooltip" title="12 Hr" class="control-label radio-style chbx ellipses hovbulit " style="padding-top: 11px !important;"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" (click)="clockformating('12hr')" name="clockformat" value="12hr" formControlName="clockformat">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'" id="type12hr" style="top: 13px !important;"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type12">12 Hr</span>
                          </label>
                        </div>
                        <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'" style="padding-left: 12% !important; font-size: 14px;">
                          <label data-toggle="tooltip" title="24 Hr" class="control-label radio-style chbx ellipses hovbulit" style="padding-top: 11px !important;"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="clockformat" value="24hr" formControlName="clockformat" (click)="clockformating('24hr')">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"  id="type24hr" style="top: 13px !important; "></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type24">24 Hr</span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="submitted && f.clockformat.errors" class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="f.clockformat.errors.required">Clock Format Required</strong>
                      </div>
                    </div>

                    <!-- <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                        <div class="border-style bhs-17 post-shade labelFlex" [ngStyle]="{'background' : darkMode ? 'linear-gradient(#2d2f41,#0e0f14)' : '#f1f1f1'}">
                            <label [ngStyle]="{'color' : darkMode ? '#fff' : '#00205b'}">ETA</label>
                            <input type="time" class="form-control" placeholder="ETA" formControlName="eta" style="padding: 0px;margin-left: 10px;">
                        </div>
                    </div>
                    <div class="col-md-2" style="padding: 0px 20px 0px 5px;">
                        <div class="border-style bhs-18 post-shade labelFlex ps2" [ngStyle]="{'background' : darkMode ? 'linear-gradient(#2d2f41,#0e0f14)' : '#f1f1f1'}">
                            <label [ngStyle]="{'color' : darkMode ? '#fff' : '#00205b'}">ETD</label>
                            <input type="time" class="form-control" placeholder="ETD" formControlName="etd" style="padding: 0px;margin-left: 10px;">
                        </div>
                    </div> -->
                    <div *ngIf="t4 == true">
                      <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                        <div class="border-style bhs-17 post-shade labelFlex">
                          <label style="margin-left:5px; padding-right:5px">ETA</label>


                          <ng-select placeholder="ETA" class="etdfield" formControlName="eta"
                            style="padding: 0px; margin-left: 0px;width: 71%; display: flex; justify-content: right;"
                            [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option class="optn" *ngFor="let item of mainarray;let i = index" value="{{item}}" style="justify-content: center;display: flex;font-size: 16px;word-spacing: 30px;word-spacing: 15px;
                                    margin-left: 47px;">
                              {{item}}

                            </ng-option>




                          </ng-select>
                        </div>
                      </div>



                      <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                        <div class="border-style bhs-18 post-shade labelFlex" style="width: 90%;">
                          <label style="margin-left:5px; padding-right:5px">ETD</label>

                          <ng-select placeholder="ETA" class="etdfieldd" formControlName="etd"
                            style="padding: 0px; margin-left: 0px;width: 71%; display: flex; justify-content: right;"
                            [ngClass]="darkMode ? 'dark-drop' : 'null'">


                            <ng-option *ngFor="let item of mainarray;let i = index" value="{{item}}" style="justify-content: center;display: flex;font-size: 16px;word-spacing: 30px;word-spacing: 15px;
                                    margin-left: 47px;">
                              {{item}}


                            </ng-option>




                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="t4 == false">
                      <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                        <div class="border-style bhs-17 post-shade labelFlex">
                          <label style="margin-left:5px; padding-right:5px">ETA</label>
                          <input type="time" class="form-control" placeholder="ETA" formControlName="eta"
                            style="padding: 0px; margin-left: 10px;" [ngClass]="darkMode ? 'drker' : 'whter'">
                        </div>
                      </div>



                      <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                        <div class="border-style bhs-18 post-shade labelFlex etd_field">
                          <label style="margin-left:5px; padding-right:5px">ETD</label>
                          <input type="time" class="form-control etdfielddd" placeholder="ETA" formControlName="etd"
                            style="padding: 0px; margin-left: 10px;" [ngClass]="darkMode ? 'drker' : 'whter'">
                        </div>
                      </div>
                  </div>

                </div>
            </div>

            <!-- <div class="row">
    <div class="col-md-12">
      <div class="col-md-12">
        <div class="package-name gradient-bar margin-col-2" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">

          Connectivity
        </div>
      </div>
    </div>
  </div> -->


            <div class="expenceCard">
                <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                    <div class="">
                        <h4>Pricing Model
                            <i class="fas fa-info-circle" style="margin-left: 13px; font-size: 15px; ">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width:227px; padding:0px 0px 5px 0px;left: 80%;top: 57%;">
                  <p class="small text-danger" style="padding: 0px 5px;">
                    <strong style="font-size: 12px;">{{ "back_office.hotel_setup.pricing_pack_i" | translate }}</strong>
                  </p>
                </span>
              </i>
                        </h4>

                    </div>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
              <div class="row">

                <div class="col-md-12" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '">

                  <div class="border-style color-ptt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    <div class="" [ngClass]="darkMode?'radio-box_dark one':'radio-box one'">
                      <label data-toggle="tooltip" title="{{ 'rates_and_packages.setup_package.paid' | translate }}"
                        class="control-label radio-style chbx ellipses hovbulit" [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                        <input type="radio" class="pull-left radio" name="pricingpack" value="PerDayPricing" formControlName="pricingpack">
                        <span class="radio-checkmark  rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                          id="type1"></span>
                        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type1T">{{
                          "back_office.hotel_setup.per_day_pricing" | translate }}</span>
                      </label>
                    </div>


                    <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'">
                      <label data-toggle="tooltip" title="{{ 'rates_and_packages.setup_package.complimentry' | translate }}"
                        class="control-label radio-style chbx ellipses hovbulit" [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                        <input type="radio" class="pull-left radio" name="pricingpack" value="occupancy"
                          formControlName="pricingpack">
                        <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                          id="type2"></span>
                        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">{{
                          "back_office.hotel_setup.occuoancy_base_price" | translate }}</span>
                      </label>
                    </div>


                    <!-- <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'">
                                            <label data-toggle="tooltip" title="{{ 'rates_and_packages.setup_package.exclude' | translate }}" class="control-label radio-style chbx ellipses hovbulit" [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                              <input type="radio" class="pull-left radio" name="pricingpack" value="Both"
                                formControlName="pricingpack">
                              <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                                id="type3"></span>
                              <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">{{
                                "back_office.hotel_setup.both" | translate }}</span>
                            </label>
                                        </div> -->
                  </div>

                  <div *ngIf="submitted && f.pricingpack.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.pricingpack.errors.required">Pricingpack Required</strong>
                  </div>

                </div>
              </div>

              <!-- <div class="col-md-12" style="padding: 0px 0px 0px 5px;">
                  <div class="border-style bhs-17 post-shade labelFlex" [ngStyle]="{'background' : darkMode ? 'linear-gradient(#2d2f41,#0e0f14)' : '#f1f1f1'}">

                      <input type="radio" class="form-control" value="abc"  placeholder="pricingpack" formControlName="pricingpack" style="padding: 0px;margin-left: 10px;" >
                  </div>
                </div>

                 -->

            </div>




            <div class="expenceCard">
                <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                    <div class="">
                        <h4>Tax Setup
                            <i class="fas fa-info-circle" style="margin-left: 13px; font-size: 15px; ">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width:227px; padding:0px 0px 5px 0px;left: 80%;top: 57%;">
                  <p class="small text-danger" style="padding: 0px 5px;">
                    <strong style="font-size: 12px;">{{ "back_office.hotel_setup.tax_setup_i" | translate }}</strong>
                  </p>
                </span>
              </i>
                        </h4>

                    </div>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row">

                  <div class="col-md-3 grid_20" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '"   >

                    <div class="border-style bhs-15 " >
                      <input type="numnber" class="form-control" placeholder="Tax ID" formControlName="taxID">

                    </div>

                  <div *ngIf="submitted && f.taxID.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.taxID.errors.required">Tax ID Required</strong>
                  </div> </div>

                    <div class="col-md-3 bhs-14 grid_20" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '">

                        <div class="border-style post-shade">
                            <input type="numnber" class="form-control" placeholder="Tax Percentage"  formControlName="taxper"  >

                            <i class="fas fa-percent" style="margin-left: 13px; font-size: 12px; position: absolute; right: 20px; top: 15px ">
                              <!-- <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                                style="width:226px; padding:0px 0px 5px 0px;left: 50%;top: 100%;">
                                <p class="small text-danger" style="padding: 0px 5px;">
                                  <strong>%</strong>
                                </p>
                              </span> -->
                            </i>

                            <div *ngIf="submitted && f.taxper.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.taxper.errors.required">Tax Percentage Required</strong>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '" style="padding: 0 20px 0 5px;">
                      <div class="border-style color-pt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                        style="display: flex; justify-content: space-around;">
                        <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="padding: 0px;">
                          <label data-toggle="tooltip" title="Tax Inclusive" class="control-label radio-style chbx ellipses hovbulit"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="taxInclusive" value="false" formControlName="taxInclusive">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                              id="type2"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">{{
                              'back_office.hotel_setup.tax_inclusive' | translate }}</span>
                          </label>
                        </div>
                        <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'" style="padding-left: 17% !important;">
                          <label data-toggle="tooltip" title="Tax exclusive" class="control-label radio-style chbx ellipses hovbulit"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="taxInclusive" value="true" formControlName="taxInclusive">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                              id="type3"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">{{
                              'back_office.hotel_setup.tax_exclusive' | translate }}</span>
                          </label>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '" style="padding: 0 20px 0 5px;">
                      <div class="border-style color-pt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" style="display: flex; justify-content: space-around;">
                        <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="padding: 0px;">
                          <label data-toggle="tooltip" title="Tax Inclusive" class="control-label radio-style chbx ellipses hovbulit"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="taxInclusive" value="true" formControlName="taxInclusive">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                              id="type2"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">Inclusive</span>
                          </label>
                        </div>
                        <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'" style="padding-left: 8% !important;">
                          <label data-toggle="tooltip" title="Tax exclusive" class="control-label radio-style chbx ellipses hovbulit"
                            [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                            <input type="radio" class="pull-left radio" name="taxInclusive" value="false" formControlName="taxInclusive">
                            <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"  id="type3"></span>
                            <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">Exclusive</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="submitted && f.taxInclusive.errors" class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="f.taxInclusive.errors.required">Tax Type Required</strong>
                    </div>



                    <!-- <div class="col-md-12" style="padding: 0px 0px 0px 5px;">
          <div class="border-style bhs-17 post-shade labelFlex" [ngStyle]="{'background' : darkMode ? 'linear-gradient(#2d2f41,#0e0f14)' : '#f1f1f1'}">

              <input type="radio" class="form-control" value="abc"  placeholder="pricingpack" formControlName="pricingpack" style="padding: 0px;margin-left: 10px;" >
          </div>
        </div> -->

                </div>
            </div>



            <div class="expenceCard" style=" margin-bottom: 5px;">
                <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                    <div class="">
                        <h4>Expenses
                            <i class="fas fa-info-circle" style="margin-left: 13px; font-size: 15px; ">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width: 179px;  left: 65% !important;">
                  <p class="small text-danger" style="padding: 0px 0px; margin-top: 0px;">
                    <strong style="font-size: 12px;">{{ "back_office.hotel_setup.tax_setup_i" | translate }}</strong>
                  </p>
                </span>
              </i>
                        </h4>

                    </div>
                </div>
            </div>


            <div class="expenceMainCard">
                <div class="upsell card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                        <h5>
                             {{ "back_office.hotel_setup.upsell" | translate }}
                            <i class="fas fa-info-circle" style="margin-left:5px !important">
                <div class="tooltiptext" style="width: 256px; left: 60% !important;"
                  [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                  {{ "back_office.hotel_setup.upsell_i" | translate }}
                </div>
              </i>
                        </h5>
                        <img alt="" data-target="#expenceUpsell" data-toggle="collapse" height="21px" id="upsellDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('upsellDropdown')">
                    </div>
                    <div class="expenceBody" id="expenceUpsell" class="collapse">
                        <div class="row" formArrayName="extras">
                            <ng-container *ngFor="let expense of hotel_setup_form.get('extras')['controls']; let i = index">
                                <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;" *ngIf="chkForm(expense,i,'upsell')" [formGroupName]="i">
                                    <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[i%randomColors.length]}">
                                            <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[i%randomColors.length]}">
                                            <input type="text" placeholder="Price" class="form-control" formControlName="price">
                                        </div>
                                    </div>
                                    <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                        <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;">
                                <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul excapo">
                                        <input type="text" placeholder="Upsell name" class="form-control" [(ngModel)]="Upsellname" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul excapt">
                                        <div *ngIf="expencePrice">
                                            <input type="text" placeholder="Price" class="form-control" [(ngModel)]="UpsellPrice" [ngModelOptions]="{standalone: true}">
                                            <i class="fa fa-times timesForExp" (click)="expencePrice = false"></i>
                                        </div>
                                        <ng-select placeholder="Price" [(ngModel)]="UpsellPrice" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'" (change)="showRateInp(UpsellPrice)" *ngIf="!expencePrice">
                                            <ng-option value="free">Free</ng-option>
                                            <ng-option value="price">Price</ng-option>
                                            <ng-option value="not-available">Not Available</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                    <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Upsellname,UpsellPrice,'upsell',4)"><i
                      class="fa fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="extras card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                        <h5>
                            {{ "back_office.hotel_setup.extras" | translate }}
                            <i class="fas fa-info-circle"  style="margin-left:5px !important">
                <div class="tooltiptext" style="width: 100px; left: 60% !important;"
                  [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                  {{ "back_office.hotel_setup.extras_i" | translate }}
                </div>
              </i>
                        </h5>
                        <img alt="" data-target="#extraUpsell" data-toggle="collapse" height="21px" id="extraDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('extraDropdown')">
                    </div>
                    <div class="expenceBody" id="extraUpsell" class="collapse">
                        <div class="row" formArrayName="extras">
                            <ng-container *ngFor="let expense of hotel_setup_form.get('extras')['controls']; let i = index">
                                <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;" *ngIf="chkForm(expense,i,'extras')" [formGroupName]="i">
                                    <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[32+i%randomColors.length]}">
                                            <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[32+i%randomColors.length]}">
                                            <input type="text" placeholder="Price" class="form-control" formControlName="price">
                                        </div>
                                    </div>
                                    <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                        <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;">
                                <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul  excapt1">
                                        <input type="text" placeholder="Extras name" class="form-control" [(ngModel)]="Extrasname" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul  excapt2">
                                        <input type="text" placeholder="Price" class="form-control" [(ngModel)]="Extrasprice" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                    <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Extrasname,Extrasprice,'extras',4)"><i
                      class="fa fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="miniBar card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                        <h5>
                          {{ "back_office.hotel_setup.minibar" | translate }}
                            <i class="fas fa-info-circle"  style="margin-left:5px !important">
                <div class="tooltiptext" style="width: 108px; left: 60% !important;"
                  [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                  {{ "back_office.hotel_setup.minibar_i" | translate }}
                  In room expences.
                </div>
              </i>
                        </h5>
                        <img alt="" data-target="#minibarUpsell" data-toggle="collapse" height="21px" id="miniDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('miniDropdown')">
                    </div>
                    <div class="expenceBody" id="minibarUpsell" class="collapse">
                        <div class="row" formArrayName="extras">
                            <ng-container *ngFor="let expense of hotel_setup_form.get('extras')['controls']; let i = index">
                                <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;" *ngIf="chkForm(expense,i,'minibar')" [formGroupName]="i">
                                    <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[67+i%randomColors.length]}">
                                            <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                                        </div>
                                    </div>
                                    <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                        <div class="capsul" [ngStyle]="{'border-color': randomColors[67+i%randomColors.length]}">
                                            <input type="text" placeholder="Price" class="form-control" formControlName="price">
                                        </div>
                                    </div>
                                    <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                        <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-md-12 " style="margin-top: 5px;padding-right: 18px;">
                                <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul excapt2">
                                        <input type="text" placeholder="Mini Bar name" class="form-control" [(ngModel)]="Minisname" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; " [ngClass]="darkMode ? 'card_dark' : 'card2'">
                                    <div class="capsul excapt3">
                                        <input type="text" placeholder="Price" class="form-control" [(ngModel)]="MiniPrice" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                                    <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Minisname,MiniPrice,'minibar',4)">
                                      <i class="fa fa-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="setup_data" class="expenceCard">
    <div class="gradient-bar mb-5 expensesMainBar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'" >
      <h4>Expenses</h4>
      <i class="fas fa-info-circle" style="font-size: 15px;position: relative;">
        <div class="tooltipForExpence" [ngClass]="darkMode ? 'darkTool' : ''">
          Adding the expences here will appear on checkout screen.
        </div>
      </i>
    </div>

                  <div class="upsell card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                      <h5>
                        Upsell
                        <i class="fas fa-info-circle">
                          <div class="tooltipExpence" style="width: 297px;" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                            These are option which are paid or free. These will be selected when creating promotion.
                          </div>
                        </i>
                      </h5>
                      <img alt="" data-target="#expenceUpsell" data-toggle="collapse" height="21px" id="upsellDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('upsellDropdown')">
                    </div>
                    <div class="expenceBody" id="expenceUpsell" class="collapse">
                      <div class="row" formArrayName="extras">
                        <ng-container *ngFor="let expense of hotel_setup_form.controls.extras.controls; let i = index">
                          <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;" *ngIf="chkForm(expense,i,'upsell')" [formGroupName]="i">
                            <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                              <div class="capsul" [ngStyle]="{'border-color': randomColors[i%randomColors.length]}">
                                <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                              </div>
                            </div>
                            <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                              <div class="capsul" [ngStyle]="{'border-color': randomColors[i%randomColors.length]}">
                                <input type="text" placeholder="Price" class="form-control" formControlName="price">
                              </div>
                            </div>
                            <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                              <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;">
                          <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                          <div class="capsul excapo">
                            <input type="text" placeholder="Upsell name" class="form-control" [(ngModel)]="Upsellname" [ngModelOptions]="{standalone: true}" >
                          </div>
                          </div>
                          <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul excapt">
                              <div *ngIf="UpsellPriceYes">
                                <input type="text" placeholder="Price" class="form-control" [(ngModel)]="UpsellPrice" [ngModelOptions]="{standalone: true}" >
                                <i class="fa fa-times forExpence" (click)="UpsellPriceYes = false"></i>
                              </div>
                              <ng-select placeholder="Price" [(ngModel)]="UpsellPrice" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'" (change)="priceInp(UpsellPrice)" *ngIf="!UpsellPriceYes">
                                <ng-option value="free">Free</ng-option>
                                <ng-option value="price">Price</ng-option>
                                <ng-option value="not-available">Not Available</ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                            <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Upsellname,UpsellPrice,'upsell')"><i class="fa fa-plus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="extras card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                      <h5>
                        Extras
                        <i class="fas fa-info-circle">
                          <div class="tooltipExpence" style="width: 115px;" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                            In hotel services.
                          </div>
                        </i>
                      </h5>
                      <img alt="" data-target="#expenceextra" data-toggle="collapse" height="21px" id="extraDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('extraDropdown')">
                    </div>
                    <div class="expenceBody" id="extraUpsell" class="collapse">
                      <div class="row" formArrayName="extras">
                        <ng-container *ngFor="let expense of hotel_setup_form.controls.extras.controls; let i = index">
                          <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;" *ngIf="chkForm(expense,i,'extras')" [formGroupName]="i">
                            <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul" [ngStyle]="{'border-color': randomColors[32+i%randomColors.length]}">
                              <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                            </div>
                            </div>
                            <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                              <div class="capsul" [ngStyle]="{'border-color': randomColors[32+i%randomColors.length]}">
                                <input type="text" placeholder="Price" class="form-control" formControlName="price" >
                              </div>
                            </div>
                            <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                              <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;">
                          <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                          <div class="capsul excapo" >
                            <input type="text" placeholder="Extras name" class="form-control" [(ngModel)]="Extrasname" [ngModelOptions]="{standalone: true}">
                          </div>
                          </div>
                          <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul excapt" >
                              <input type="text" placeholder="Price" class="form-control" [(ngModel)]="Extrasprice" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                            <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Extrasname,Extrasprice,'extras')"><i class="fa fa-plus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="miniBar card" [ngClass]="darkMode ? 'card_dark' : ''">
                    <div class="expencHeader">
                      <h5>
                        Mini Bar
                        <i class="fas fa-info-circle">
                          <div class="tooltipExpence" style="width: 125px;" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                            In room expences.
                          </div>
                        </i>
                      </h5>
                      <img alt="" data-target="#expenceUminibar" data-toggle="collapse" height="21px" id="miniDropdown" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="collapsed" aria-expanded="false" (click)="chngeDrop('miniDropdown')">
                    </div>
                    <div class="expenceBody" id="minibarUpsell" class="collapse">
                      <div class="row" formArrayName="extras">
                        <ng-container *ngFor="let expense of hotel_setup_form.controls.extras.controls; let i = index">
                          <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;" *ngIf="chkForm(expense,i,'minibar')" [formGroupName]="i">
                            <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul" [ngStyle]="{'border-color': randomColors[67+i%randomColors.length]}">
                              <input type="text" placeholder="Upsell name" class="form-control" formControlName="name">
                            </div>
                            </div>
                            <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                              <div class="capsul" [ngStyle]="{'border-color': randomColors[67+i%randomColors.length]}">
                                <input type="text" placeholder="Price" class="form-control" formControlName="price">
                              </div>
                            </div>
                            <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                              <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col-md-12 " style="margin-top: 5px;padding-right: 24px;">
                          <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul excapo" >
                              <input type="text" placeholder="Mini Bar name" class="form-control" [(ngModel)]="Minisname" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; "  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                            <div class="capsul excapt" >
                              <input type="text" placeholder="Price" class="form-control" [(ngModel)]="MiniPrice" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                            <a class="btn btn-md del-btn btn-primary" (click)="addNewExpense(hotel_setup_form.controls.extras,Minisname,MiniPrice,'minibar')"><i class="fa fa-plus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div> -->


            <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                <div class="">
                    <h4>{{ "back_office.create_rooms.photos" | translate }}</h4>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '" style="padding-bottom: 0px;padding-left: 10px;">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row photo-box-wrapper">
                            <div class="col-md-12" style="padding-right: 20px;">
                                <div class="col-md-3 uploadcustom" *ngFor="let img of hotel_images; let i = index" style="padding-right: 0px;">
                                    <div class="photo-box">
                                        <a class="" (click)="remove_photo(i)">
                                            <img width="25px" src="assets/img/manage-rooms/delete.png" />
                                        </a>
                                        <span *ngIf="i == 1" class="pull-left main">Property Images</span>
                                        <img height="128px" [src]="api.imgUrl + img.image" width="100%" />
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-right: 0px;width: 210px;;">
                                    <label class="btn btn-primary photo-style hov hovYY" style="margin-bottom: 5px;">
                    <input #photo_input type="file" name="photo" class="form-control" (change)="onFileChange($event)"
                      multiple="true" />
                    <div class="pluschnge hovY"><img src="assets/img/newstaticicons/back-office/add-image.svg" alt=""
                        style="visibility: hidden;" /></div>
                    <br />
                    <p style="margin-top:0px !important;">
                      {{ "back_office.create_rooms.image_logo" | translate }}
                    </p>
                  </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                <div class="">
                    <h4>{{ 'back_office.hotel_setup.most_popular_amenities' | translate }}</h4>
                </div>
            </div>

            <!-- Popular aminity -->
            <div class="card card-pd" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="room_amenities">
                    <div *ngFor="let amenity of popular_amenities; let i = index">
                        <div class="row" *ngIf="i%4 == 0 || i == 0">
                            <div *ngFor="let a of [0,1,2,3]">
                                <div class="col-md-3 amenity" *ngIf="popular_amenities[i+a] && popular_amenities[i+a].children[0]">
                                    <!-- <img class="" src="{{darkMode ? 'assest/img/hotel-setup/darkmode/icons/{{ popular_amenities[i+a].icon }}' : 'assets/img/hotel-setup/{{ popular_amenities[i+a].icon'}}">  -->
                                    <img class="AmiIcons" src="assets/img/hotel-setup/{{ popular_amenities[i+a].icon }}" *ngIf="darkMode == false ">
                                    <img class="AmiIcons" src="assets/img/hotel-setup/darkmode/icons/{{ popular_amenities[i+a].icon }}" *ngIf="darkMode == true " [ngStyle]="popular_amenities[i+a].title == 'Pets' ?  {'width' : '38px' , 'margin':'0px'} : {'width': '25px'}" style="margin:7px;">
                                    <p><b [ngStyle]="{'color': darkMode ? amenCol[i+a] : '#00205b'}" class="amiTitle">{{ popular_amenities[i+a].title }}</b></p>
                                    <ul>
                                        <li *ngFor="let childs of popular_amenities[i+a].children">
                                            <label class="control-label checkbox-style ">
                        <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}" name="most_amenties[]"
                          value="{{ childs.id }}">
                        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                        <span id="amiTitle" [ngClass]="darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                      </label>
                                            <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right">
                            <img class="" src="assets/img/hotel-setup/trash.png">
                          </a> -->
                                        </li>
                                        <li *ngFor="let childs of popular_amenities[i+a].children_users">
                                            <label class="control-label checkbox-style ">
                        <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}" name="most_amenties[]"
                          value="{{ childs.id }}">
                        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                        <span id="amiTitle" [ngClass]="darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                      </label>
                                            <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right">
                            <img class="" src="assets/img/hotel-setup/trash.png">
                          </a> -->
                                        </li>
                                    </ul>
                                    <hr class="bottom-line" *ngIf="popular_amenities[i+a].show_add == false">
                                    <a class="btn add-new" *ngIf="popular_amenities[i+a].show_add == false" (click)="popular_amenities[i+a].show_add = true">+ {{ 'back_office.hotel_setup.add_new' | translate
                    }}</a>
                                    <div class="add-new-box" *ngIf="popular_amenities[i+a].show_add == true">
                                        <input type="text" class="form-control" formControlName="add_most_amenite">
                                        <a class="btn btn-xs pull-right" (click)="popular_amenities[i+a].show_add = false">
                                            <img src="assets/img/hotel-setup/wrong.png">
                                        </a>
                                        <a class="btn btn-xs pull-right" href="javascript:void(0)" (click)="add_amenitie('most',popular_amenities[i+a].id)"><img src="assets/img/hotel-setup/right.png"></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Popular aminity END-->

            <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                <div class="">
                    <h4>{{ 'back_office.hotel_setup.other_facilities_amenities' | translate }}</h4>
                </div>
            </div>

            <!-- Other aminity -->
            <div class="card card-pd" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="room_amenities">
                    <div class="room_amenities">
                        <div *ngFor="let amenity of other_amenities; let i = index">
                            <div class="row" *ngIf="i%4 == 0 || i == 0">
                                <div *ngFor="let a of [0,1,2,3]">
                                    <div class="col-md-3 amenity" *ngIf="other_amenities[i+a] && other_amenities[i+a].children[0]">
                                        <img class="AmiIcons" src="assets/img/hotel-setup/{{ other_amenities[i+a].icon }}" *ngIf="darkMode == false ">
                                        <img class="AmiIcons" src="assets/img/hotel-setup/darkmode/icons/{{  other_amenities[i+a].icon }}" *ngIf="darkMode == true " style="width:27px ;">

                                        <p><b [ngStyle]="{'color': darkMode ? amenColot[i+a] : '#00205b'}" class="amiTitle">{{
                        other_amenities[i+a].title }}</b></p>
                                        <ul>
                                            <li *ngFor="let childs of other_amenities[i+a].children">
                                                <label class="control-label checkbox-style " [ngClass]="darkMode ? 'titleHove' : 'null'">
                          <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}" name="most_amenties[]"
                            value="{{ childs.id }}">
                          <span class="checkbox-checkmark" [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                          <span id="amiTitle" [ngClass]="darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                        </label>
                                                <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right"><img class="" src="assets/img/hotel-setup/trash.png"></a> -->
                                            </li>


                                        </ul>
                                        <hr class="bottom-line" *ngIf="other_amenities[i+a].show_add == false">
                                        <a class="btn add-new" *ngIf="other_amenities[i+a].show_add == false" (click)="other_amenities[i+a].show_add = true">+ {{ 'back_office.hotel_setup.add_new' | translate}}</a>
                                        <div class="add-new-box" *ngIf="other_amenities[i+a].show_add == true">
                                            <input type="text" class="form-control" formControlName="add_other_amenite">
                                            <a class="btn btn-xs pull-right" (click)="other_amenities[i+a].show_add = false">
                                                <img src="assets/img/hotel-setup/wrong.png">
                                            </a>
                                            <a class="btn btn-xs pull-right" href="javascript:void(0)" (click)="add_amenitie('other',other_amenities[i+a].id)"><img src="assets/img/hotel-setup/right.png"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Other aminity -->

            <div class="modal-footer mymodal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
                <button type="submit" class="btn btn-primary btn-lg hov">{{ 'back_office.hotel_setup.save' | translate
          }}</button>
                <a class="btn btn-danger btn-lg hov" data-dismiss="modal" (click)="hideModal()" aria-label="Close">{{ 'back_office.hotel_setup.close' | translate }}</a>
            </div>
        </form>
    </div>

</div>
