<div class="container-fluid">
    <div class="row">    
        <div id="login-section" class="col-md-5">
            <div class="row">
            
            <div class="col-md-offset-2 col-md-8">
                <div class="text-center">
                <a class="logo btn">
                    LOGO
                </a><br>
                <small class="sub-title">LOGIN TO YOUR</small>
                <h3 class="title no-margin">Channel Manager</h3><br>
                <div class="alert alert-success" *ngIf="successMessage != null">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    {{successMessage}}
                </div>
                <div class="alert alert-danger" *ngIf="errorMessage != null">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    {{errorMessage}}
                </div>
                </div>  
                
                
                <form method="POST" novalidate #form="ngForm" class="form-horizontal" (ngSubmit)="reset(form)">
                    <label class="control-label cg-label">Email</label>
                    <input type="email" name="email" class="form-control cg-input" [(ngModel)]="email" required>
                    <br>
                    <label class="control-label cg-label">Password</label>
                    <input type="password" name="pass" class="form-control cg-input" [(ngModel)]="pass" required minlength="6">
                    <br>
                    <label class="control-label cg-label">Confirm Password</label>
                    <input type="password" name="conf_pass" class="form-control cg-input" [(ngModel)]="conf_pass" required minlength="6">
                    <br><br>
                    <button class="btn btn-info btn-block cg-btn login-btn" type="submit">Reset Password</button>
                    <a class="btn btn-block cg-btn-blank" routerLink="">Go Back</a>
                </form>
                <br><br><br><br>

                <div class="footer"> 
                <div class="row">
                    <div class="col-md-6">
                    <small>Copyright 2019 Hotel Genine</small>
                    </div>
                    <div class="col-md-6 text-right">
                    <small><a href="#">Contact Support</a></small>
                    </div>
                </div>
                </div>
            </div>

            </div>
        </div>
        <div class="col-md-7 login-bg"></div>
    </div>
</div> 
