<div class="jumbotron">
    <div class="jumbo-icon">
        <li class="" title="Page Title" text="Status" style="list-style-type: none;">
            <div class="dash_window w-clearfix dashboard-card dragDash" id="div1"
                [ngClass]="darkMode ? 'dash_window_dark' : 'dash_window'">
                <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'win_header'">
                    <h4 class="heading ota-status" [ngClass]="darkMode ? 'heading_dark' : 'heading'">
                        {{ 'dashboard.OTA_Status' | translate }}
                    </h4>
                </div>
                <div class="win_component_ota w-clearfix"
                    [ngClass]="darkMode ? 'notifications_dark' : 'win_component_ota'">
                    <div class="enable_icon">
                        <img src="{{ darkMode ? 'assets/img/dashboard/darkmode/active_1active_dark.png' : 'assets/img/newstaticicons/dashboard/enable.svg' }}"
                            alt="" class="image-8" />
                    </div>
                    <div class="channels_container w-clearfix no_scroll">
                        <span *ngFor="let otas of activeOTAs">
                            <div class="channels" style="display: flex;align-items: center;justify-content: center;">
                                <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(otas.image) : imgr(otas.image)}}.svg"
                                    alt="external" class="channelImageOtaStatus" />
                            </div>
                        </span>
                        <div *ngIf="activeOTAs.length == 0" class="setUpOta"
                            [ngStyle]="{'color' : darkMode ? 'white' : 'black'}">
                            {{ 'rates_and_packages.extra.please_setup_otas' | translate }}
                        </div>
                    </div>
                </div>
                <div class="win_component_ota w-clearfix"
                    [ngClass]="darkMode ? 'notifications_dark' : 'win_component_ota'">
                    <div class="disable_icon">
                        <img src="{{ darkMode ? 'assets/img/dashboard/darkmode/tower.svg' : 'assets/img/newstaticicons/dashboard/tower.svg' }}"
                            alt="" class="image-8" />
                    </div>
                    <div class="channels_container w-clearfix no_scroll">
                        <span *ngFor="let otas of activeChannel">
                            <div class="channels" style="display: flex;align-items: center;justify-content: center;">
                                <img src="assets/img/svgicons/channels/{{imgr(otas.image)}}.svg" alt="internal"
                                    class="channelImageOtaStatus" />
                            </div>
                        </span>
                        <div *ngIf="activeChannel.length == 0" class="setUpOta"
                            [ngStyle]="{'color' : darkMode ? 'white' : 'black'}">
                            {{ 'rates_and_packages.extra.please_setup_channels' | translate }}
                        </div>
                    </div>
                </div>
                <div class="win_component_ota no_margin w-clearfix"
                    [ngClass]="darkMode ? 'notifications_dark' : 'win_component_ota'">
                    <div class="channels_container2 w-clearfix no_scroll">
                        <div class="" [ngClass]="darkMode ? 'main-new' : 'main-new_light' ">
                            <div class="head-new" [ngClass]="darkMode ? 'head-new':'head-new_light'">{{
                                'rates_and_packages.extra.season' | translate }}</div>
                            <div class="" [ngClass]="darkMode ? 'data-new':'data-new_light'">
                                {{dashboard?.season?.season_name}}</div>
                        </div>
                        <div class="" [ngClass]="darkMode ? 'main-new' : 'main-new_light' ">
                            <div class="head-new" [ngClass]="darkMode ? 'head-new':'head-new_light'">{{
                                'rates_and_packages.extra.dates' | translate}}</div>
                            <div class="" [ngClass]="darkMode ? 'data-new':'data-new_light'">
                                {{dashboard?.season?.date_to | date: 'dd MMM'}} |
                                {{dashboard?.season?.date_from | date: 'dd MMM'}}</div>
                        </div>
                        <div class="" [ngClass]="darkMode ? 'main-new' : 'main-new_light' ">
                            <div class="head-new" [ngClass]="darkMode ? 'head-new':'head-new_light'">{{
                                'rates_and_packages.extra.demand'| translate }}</div>
                            <div class="" [ngClass]="darkMode ? 'data-new':'data-new_light'">
                                {{dashboard?.season?.demand_type}}</div>
                        </div>
                        <div class="" [ngClass]="darkMode ? 'main-new' : 'main-new_light' ">
                            <div class="head-new" [ngClass]="darkMode ? 'head-new':'head-new_light'">{{
                                'rates_and_packages.extra.bar'| translate }}</div>
                            <div class="" [ngClass]="darkMode ? 'data-new':'data-new_light'">$
                                {{dashboard?.season?.season_room_types[0].week_days_rate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
</div>