import { Component, OnInit } from '@angular/core';
import { ConfirmationAlertService } from "./confirmation-alert.service";
@Component({
  selector: 'app-confirmation-alert',
  templateUrl: './confirmation-alert.component.html',
  styleUrls: ['./confirmation-alert.component.css']
})
export class ConfirmationAlertComponent implements OnInit {

  message:any;
  darkMode: boolean = false;
  constructor(
      public alert: ConfirmationAlertService
  ) { }

  ngOnInit() {

    this.checkDarkMode();
    this.alert.getMessage().subscribe(message => {
        this.message = message;
    });
  }


  checkDarkMode(){
    let a = JSON.parse(localStorage.getItem('user'));
    if(a != null){
      let mode = a.mode;
      if(mode == 'dark'){
        this.darkMode = true;
      }
    }
  }

}
