import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray,FormGroup,Validators,ValidationErrors,AbstractControl } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { ManageRatesPackagesComponent } from "../manage-rates-packages.component";
import { Router } from "@angular/router";
declare var $: any;
import * as moment from 'moment';


export interface AbstractFormGroup extends FormGroup {
  controls: {
    [key: string]: AbstractFormGroup & AbstractFormGroup[] & AbstractControl & FormGroup & FormArray,
  }
}
@Component({
  selector: 'app-setup-rate',
  templateUrl: './setup-rate.component.html',
  styleUrls: ['./setup-rate.component.css']
})
export class SetupRateComponent implements OnInit {
  packages;
  bHeight;
  setup_season;
  i;
  package = [];
  darkMode: boolean = false;
  pdetails:any;
  rate_type;
  create_package_form: FormGroup ;
  submitted = false;
  packageDates = [ // for zero form
    {date_day: moment(new Date()).format('YYYY-MM-DD'), price: ''},
  ];

  border_colors = ['#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3' ];
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private service: ManageRatesPackagesService,
    private router: Router,
    private rp: ManageRatesPackagesComponent
  ) { }

  ngOnInit() {
    this.modalfooter();
    this.checkDarkMode();
    console.log('package rate');
    this.get_package();
    this.createZeroForm(); // this is create zero Form
    $("#SP").mouseup(function(){
      $("#grab-9").css({"cursor":"grab"})
    });
    $("#SP").mousedown(function(){
      $("#grab-9").css({"cursor":"grabbing"})
    });
  }

  dodrag2(){
    $('#settings').draggable( "enable" )
    // $('#SSS').css({ "cursor": "grab" })
  }

  stopDrag2(){
    $('#settings').draggable( "disable" )
    // $('#SSS').css({ "cursor": "grabbing" })
  }


  createZeroForm(){ // this is create zero Form
    this.create_package_form = this.fb.group({
      allocation_high_1: '',
      allocation_high_2: '',
      allocation_high_rate: '',
      allocation_medium_1: '',
      allocation_medium_2: '',
      allocation_medium_rate: '',
      allocation_low_1: '',
      allocation_low_2: '',
      allocation_low_rate: '',
      property_id: localStorage.getItem('current_property'),
      season_package_id: '',
      rate_type:  ['$', Validators.required],
      room_type_id: '',
      room_id: '',
      prices: this.fb.array(this.packageDates.map(ob=>{
        return this.date_price(ob.date_day, ob.price);
      }))
    }) as FormGroup ;
  }
  date_price(d, p): AbstractControl  {  // this will be used in create_from_none and createItem_from_createSeason
    return this.fb.group({date_day: [d, Validators.required], price: [p, Validators.required]})
  }

  get f() { return this.create_package_form ; }

  resetform(){
    //this.setup_rate_form.reset();
  }

  onSubmit(){
    this.submitted = true;
    if (this.create_package_form.invalid) {
      Object.keys(this.create_package_form).forEach(key => {
        const controlErrors: ValidationErrors = this.create_package_form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
      return;
    }
    let sendData = JSON.parse(JSON.stringify(this.create_package_form.value));
    this.api.post('api/package_allocation/update', sendData, true).subscribe((res:any) => {
      this.service.get_packages();
      this.helper.alert_success("Rates are now setup");
      this.rp.getRatePlan();
      this.submitted = false;
      this.service.open_tab('setup_restrictions_open');
    }, err => this.error_handling.handle_error(err.status));

  }

  get_package(){  // this will check if new package or old editing
    if(this.service.package_creating !==undefined){
        this.api.get('api/package/show/'+this.service.package_creating,true).subscribe((res:any) => {
          this.pdetails = res.body.data;
          if(res.body.data.allocation.length != 0){
            this.create_package_form = this.fb.group({
              allocation_high_1: [res.body.data.allocation.length >= 1 ? res.body.data.allocation[0].allocation_type == 'High' ? res.body.data.allocation[0].start_point : '' :''],
              allocation_high_2: [res.body.data.allocation.length >= 1 ? res.body.data.allocation[0].allocation_type == 'High' ? res.body.data.allocation[0].end_point : '' :''],
              allocation_high_rate: [res.body.data.allocation.length >= 1 ? res.body.data.allocation[0].allocation_type == 'High' ? res.body.data.allocation[0].allocation_rate : '' :''],
              allocation_medium_1: [res.body.data.allocation.length >= 2 ? res.body.data.allocation[1].allocation_type == 'Medium' ? res.body.data.allocation[1].start_point : '' :''],
              allocation_medium_2: [res.body.data.allocation.length >= 2 ? res.body.data.allocation[1].allocation_type == 'Medium' ? res.body.data.allocation[1].end_point : '' :''],
              allocation_medium_rate: [res.body.data.allocation.length >= 2 ? res.body.data.allocation[1].allocation_type == 'Medium' ? res.body.data.allocation[1].allocation_rate : '' :''],
              allocation_low_1: [res.body.data.allocation.length >= 3 ? res.body.data.allocation[2].allocation_type == 'Low' ? res.body.data.allocation[2].start_point : '' :''],
              allocation_low_2: [res.body.data.allocation.length >= 3 ? res.body.data.allocation[2].allocation_type == 'Low' ? res.body.data.allocation[2].end_point : '' :''],
              allocation_low_rate: [res.body.data.allocation.length >= 3 ? res.body.data.allocation[2].allocation_type == 'Low' ? res.body.data.allocation[2].allocation_rate : '' :''],
              property_id: res.body.data.property_id,
              package_id: res.body.data.id,
              rate_type: res.body.data.allocation[0] >=1 ? res.body.data.allocation[0].rate_type : '$',

              room_type_id: res.body.data.room_type_id,
              room_id: res.body.data.room.id,
              prices: this.fb.array(res.body.data.prices.map(ob=>{
                return this.date_price(ob.date_day, ob.price);
              }))
            }) as FormGroup;
          }else{
            this.create_package_form = this.fb.group({
              allocation_high_1: '',
              allocation_high_2: '',
              allocation_high_rate: '',
              allocation_medium_1: '',
              allocation_medium_2: '',
              allocation_medium_rate: '',
              allocation_low_1: '',
              allocation_low_2: '',
              allocation_low_rate: '',
              property_id: res.body.data.property_id,
              package_id: res.body.data.id,
              rate_type: "$",
              room_type_id: res.body.data.room_type_id,
              room_id: res.body.data.room.id,
              prices: this.fb.array(res.body.data.prices.map(ob=>{
                return this.date_price(ob.date_day, ob.price);
              }))
            }) as FormGroup;
          }
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  selectAllocationPrice(mainIndex, type){
    this.create_package_form.value.rate_type = type;
  }

  modalfooter(){
    setTimeout(()=>{
      let f = document.getElementById("ModalFooterP")
      let h = document.getElementById("SP");
      this.bHeight = h.offsetHeight;
      if(this.bHeight > 700){
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      }
      else if(this.bHeight < 700){
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    },100)
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }


  }
}
