
<div class="" id="mUser">
    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
        <a class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{ 'back_office.manage_users.back_office' | translate}}</a>
        <a class="btn btn-link active">{{ 'back_office.manage_users.manage_users' | translate }}</a>
    </div>

    <div class="alert alert-info" style="    height: 45px;padding-top: 12px;">
        <ul class="nav nav-inline">
            <li style="width: 7%"><a >&nbsp;</a></li>
            <li style="width: 15%"><a style="height: 22px;">{{ 'back_office.manage_users.name' | translate}}</a></li>
            <li style="width: 20%"><a style="height: 22px;">{{ 'back_office.manage_users.email' | translate}}</a></li>
            <li style="width:10%"><a style="height: 22px;"> {{ 'back_office.manage_users.role' | translate}}</a></li>
            <li style="width: 15%"><a style="height: 22px;">{{ 'back_office.manage_users.permissions' | translate}}</a></li>
            <li style="width: 12%"><a style="height: 22px;">{{ 'back_office.manage_users.status' | translate}}</a></li>
            <li style="width: 21%"><a style="height: 22px;">{{ 'back_office.manage_users.actions' | translate}}</a></li>
        </ul>
    </div>

    <div class="card scroll no_scroll" [ngClass]="darkMode ? 'card-dark' : 'card'">
        <div class="">
            <div class="row field-space flex-container" *ngFor="let user of users; let i=index">
                <div class="col-md-8">
                    <div class="border-style boxi" [style.border-color]="colors[i%5]">
                        <div class="element" [ngClass]="darkMode ? 'bc-select' : 'null'">
                            <ul class="nav nav-inline">
                                <li style="width: 10%">
                                    <img src="{{ user.profile_picture ? api.imgUrl + user.profile_picture : 'assets/img/logo.jpg' }}" class="img-responsive img-circle">
                                </li>
                                <li style="width: 22%;">{{ user.first_name+' '+user.last_name }}</li>
                                <li style="width: 30%; text-align: center !important;">{{ user.email }}</li>
                                <li style="width: 20%; text-align: center !important;">{{ user.role }}</li>
                                <li style="width: 18%;padding-left: 4.5%" class="text-center">
                                    <ul class="nav sub-nav" *ngFor="let permission of user.permissions">
                                        <li style="text-align: left">{{ permission?.app_permission?.name }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns" style="padding-left: 8px; display: flex;">
                    <a data-toggle="tooltip" title="" class="btn text-elip {{ user.status=='pending' ? 'btn-warning pending'  : user.status=='active' ? 'btn-danger'  :  user.status=='block' ? 'btn-primary' :'btn-primary' }} hov pan" style="text-transform:capitalize;padding: 8px 5px 6px 0px;" (click)="change_status(user.id)">
                        <img *ngIf="user.status=='pending' || user.status=='active'" src="assets/img/newstaticicons/back-office/lock-close.svg" style="padding: 0px 5px; width:25px;" />
                        <img *ngIf="user.status=='block'" src="assets/img/newstaticicons/back-office/lock-open.svg" style="padding: 0px 5px;" />
                        <span *ngIf="user.status=='pending'" class="pand">{{ 'back_office.manage_users.pending' | translate}}</span>
                        <span *ngIf="user.status=='active'">{{ 'Lock' }}</span>
                        <span *ngIf="user.status=='active'">{{ 'Unlock' }}</span>
                    </a>
                    <a data-toggle="tooltip" title="{{ 'back_office.manage_users.edit' | translate}}" class="btn btn-primary text-elip hov" (click)="edit(user.id, user.first_name+' '+user.last_name)" style="padding: 8px 5px 6px 0px;">
                        <img src="assets/img/newstaticicons/back-office/white-edit.svg" style="margin-bottom: 3px; width: 17px;" />
                        {{ 'back_office.manage_users.edit' | translate}}
                    </a>
                    <a class="btn btn-danger hov" (click)="delete(user.id,user.first_name+' '+user.last_name)" style="padding: 8px 5px 6px 0px;" data-toggle="modal" data-target="#confirmationModal">
                        <img src="assets/img/newstaticicons/back-office/white-delete.svg" style="margin-bottom: 3px; width: 14px;" />
                        {{ 'back_office.manage_users.delete' | translate }}
                    </a>
                </div>
            </div>
            <div *ngIf="users?.length == 0">
                {{ 'back_office.manage_users.no_user' | translate }}
            </div>
        </div>
    </div>

    <div class="modal-footer modal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
        <a class="btn btn-danger btn-lg hov" (click)="hideModal()" data-dismiss="modal" aria-label="Close">{{ 'back_office.manage_users.close' | translate }}</a>
    </div>

</div>

