import { Component, OnInit, Injectable,HostBinding  } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { HelperService } from "../../../services/helper.service";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
declare var $:any;
// @Injectable()
@HostBinding('edit')

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.css']
})
export class ManageRoomsComponent implements OnInit {
  rooms;
  def_id;
  p_room = [];
  amenities;
  aaa = [];
  darkMode: boolean = false;
  roomId: any;
  showBox: boolean = false;
  showBox2: boolean = false;
  desT: boolean = false
  arri: any;
  Ammi: any;
  query:any;
  param:any;
  cTitle: any;
  channels = [];
  aminity = [];
  otas: any = [];
  amenColor = ['#a9aae0','#d24f51','#bf4eeb','#efab20','#32d04a','#468fab']

  constructor(
    public api: ApiService,
    public helper: HelperService,
    public error_handling: ErrorHandlingService,
    public fb: FormBuilder,
    public confirm:ConfirmationAlertService,
    public router: Router,
  ) { }

  ngOnInit() {

    this.get_rooms();
    this.checkDarkMode();
    this.get_amenities();


    $(function(){
      $("#colShrt").mouseup(function(){
        $("#grabingg").css({"cursor":"grab"})
      });

      $("#colShrt").mousedown(function(){
        $("#grabingg").css({"cursor":"grabbing"})
      });
    })
  }

  get_rooms(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/room/index?per_page=500', {property_id:property_id},true).subscribe((res:any) => {
       this.rooms = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
    this.setHi();
  }

  edit(id){
    this.def_id=id;
    this.helper.change.subscribe(def_id => {
      this.def_id = def_id;
    });
    this.helper.gotoEdit();
    $("#create_rooms_li a").click();
  }

  delete(id,name){
    let that = this;
    this.confirm.confirm(
      "Delete Room ("+ name +")",
      'Are your sure you want to Delete this Room?',
      ['This action can not be undone','Deleting this will completely delete this Room & Its Details.'],
      function(){
        that.api.get('api/room/destory/'+id ,true).subscribe((res:any) => {
          that.helper.alert_success('Room Deleted Successfully....');
          that.get_rooms();
        }, err => that.error_handling.handle_error(err.status, err.message));
      }
    );
  }

  jump(){
    $("#create_rooms_li a").click();
  }

  clone(id){
    this.api.get('api/room/clone/'+id ,true).subscribe((res:any) => {
      this.helper.alert_success('Room Cloned Successfully....');
      this.get_rooms();
      this.cloneHeight();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  preview_pop(id2){
    this.p_room = this.rooms;
    this.p_room = this.rooms.find(x => x.id === id2 );
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  conPadd(){
    setTimeout(()=>{
      let a = document.getElementById("colShrt");
      if(a.offsetHeight < 700){
        a.classList.add("paddRight")
      }else{
        a.classList.remove("paddRight")
      }
    },100)
  }

  cloneHeight(){
    setTimeout(()=>{
      let a = document.getElementById("colShrt");
      if(a.offsetHeight < 700){
        a.classList.remove("paddRight")
      }else{
        a.classList.remove("paddRight")
      }
    },100)
  }

  setHi(){
    setTimeout(()=>{
      let a = document.getElementById("colShrt");
      if(a.offsetHeight > 700){
        a.classList.add("paddRight5")
      }else{
        a.classList.remove("paddRight5")
      }
    },100)
  }

  seeDescription(i){
    this.desT = !this.desT
    this.arri = i
  }

  seeDescription2(i){
    this.arri = 9999999999
  }

  descripArray(data){
    return data.split(' ').slice(0, 60).join().replace(/,/g, " ");
  }

  descripArray2(data){
    return data.split(' ').slice(60).join().replace(/,/g, " ");
  }

  get_amenities(){
    this.api.post('api/amenitie/index', {order: 'other', type: 'Room','parent_id':'Null'}, true).subscribe((res:any) => {
      this.amenities = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  openchModal2(data){
    this.aminity = data.with_parent_amenities;
    this.showBox2 = true;
    setTimeout(()=>{
      $( "#draggable2" ).draggable();
    }, 300);
  }

  changeDrop(id){
    let a =document.getElementById(id)
    if(a.classList.contains('rotate180')){
      a.classList.remove('rotate180')
    }
    else{
      a.classList.add('rotate180')
    }
  }

  drag(con){
    con == 'do' ? $("#draggable").draggable("enable") : $("#draggable").draggable("disable")
  }


  /* Open Channel Modal */
  openchModal(id){
    this.roomId = id;
    this.getRoomChannels(id);
    this.getRoomotas(id);
    this.showBox = true;
    setTimeout(()=>{
      $( "#draggable" ).draggable();
      $("#draggable").draggable("disable");
    }, 300);
  }

  getRoomChannels(roomId){
    // this.api.post('api/room_otas/room_ota_status', {room_id: roomId,  property_id:localStorage.getItem('current_property')}, true).subscribe((res:any) => {
    //   console.log(res.body.data);
    //   this.channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status));

    this.api.post('api/property/property_channels_otas', { property_id: localStorage.getItem('current_property'), type:'internal'}, true).subscribe((res:any) => {
      console.log('for channels', res.body.data);
      this.channels = res.body.data;
      if(this.channels === undefined){
        this.channels = [];
      }
    }, err => this.error_handling.handle_error(err.status));
  }
  getRoomotas(roomId){
    // this.api.post('api/room_otas/room_ota_status', {room_id: roomId,  property_id:localStorage.getItem('current_property')}, true).subscribe((res:any) => {
    //   console.log(res.body.data);
    //   this.channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status));

    this.api.post('api/property/property_channels_otas', { property_id: localStorage.getItem('current_property'), type:'external'}, true).subscribe((res:any) => {
      console.log('for otas', res.body.data);
      this.otas = res.body.data;
      if(this.otas === undefined){
        this.otas = [];
      }
    }, err => this.error_handling.handle_error(err.status));
  }

  add_bulk_channels(e, id){
    if (e.target.checked) {
      this.channels.forEach(element => {
        if(element.id == id){
          element.isBinded = true;
        }
      });
    }else{
      this.channels.forEach(element => {
        if(element.id == id){
          element.isBinded = false;
        }
      });
    }
  }

  selectAllOta(e){
    if(e.target.checked){
      this.channels.forEach(element => {
        if(element.type == 'External'){
          element.isBinded = true;
        }
      });
    }else{
      this.channels.forEach(element => {
        if(element.type == 'External'){
          element.isBinded = false;
        }
      });
    }
  }

  selectAllCh(e){
    if(e.target.checked){
      this.channels.forEach(element => {
        if(element.type == 'Internal'){
          element.isBinded = true;
        }
      });
    }else{
      this.channels.forEach(element => {
        if(element.type == 'Internal'){
          element.isBinded = false;
        }
      });
    }
  }

  saveChannels(){
    let addCh = [];
    let rmCh = [];
    this.channels.forEach(e=>{
      if(e.isBinded == true){
        addCh.push(e.id);
      }else{
        rmCh.push(e.id);
      }
    })
    let f = {
      property_id: localStorage.getItem('current_property'),
      room_id: this.roomId,
      channel_id: addCh,
      remove_channels: rmCh
    };
    console.log(f);
    this.api.post('api/room_otas/store', f, true).subscribe((res:any) => {
      this.helper.alert_success(res.body.message);
    }, err => this.error_handling.handle_error(err.status));
  }

  /* Open Channel Modal END*/

  closeModal(){
    this.showBox = false;
    this.showBox2 = false;
  }

  hideModal()
  {
    let modal = document.getElementById('settings')
      modal.classList.remove('show');
      modal.classList.add('hide');
  }


  /* Push ROOM to Expedia */
  pushRoom(id){
    this.api.get('api/expedia_push/room_type/'+id ,true).subscribe((res:any) => {
      this.helper.alert_success('Room Pushed Successfully');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  passVar(){

    // this.router.navigate(['/app/back_office/create_rooms'],  { state: this.tst })
  }


}
