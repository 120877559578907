import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connectivity-status',
  templateUrl: './connectivity-status.component.html',
  styleUrls: ['./connectivity-status.component.scss']
})
export class ConnectivityStatusComponent implements OnInit {

  darkMode: boolean = false;
  @Input('activeOTAs') activeOTAs: any;
  @Input('activeChannel') activeChannel: any;
  @Input('dashboard') dashboard: any;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  imgr(name) {
    let a = name.split('.');
    return a[0];
  }

}
