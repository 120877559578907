<div class="" id="selectPro">

    <!-- <div class="notification" [ngClass]="darkMode ? 'smooth-dark' : 'notification'" id="grabingg" style="cursor: grab;">
            <div class="row">
                <div class="col-md-12">
                    <div class="greetings">{{ 'header.noti' | translate }}<button aria-label="Close"
                            class="close" data-dismiss="modal" type="button" style="float: right;color: #fff"><strong
                                id="strong">✖</strong></button></div>
                    </div>
                </div>
            </div> -->

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
        <a class="btn btn-link" data-dismiss="modal" (click)="hideModal()">{{ 'back_office.select_hotel.back_office' | translate }}</a>
        <a class="btn btn-link active">{{ 'back_office.select_hotel.select_property' | translate }}</a>
    </div>

    <div class="clearfix"></div>
<div class="no_scroll scroll">
    <div class="card m-b-0" *ngFor="let property of properties; let i = index"
        [ngClass]="darkMode ? 'card-dark' : ' card '">
        <div class="row">

            <div class="col-md-4 pr-12" style="width: 49%;">
                <!-- <img class="property_img" *ngIf="property?.images.length == 0" src="assets/img/select_hotel/img1.png">
                <img class="property_img" *ngIf="property?.images.length > 0" src="{{ imgUrl + property?.images[0].image }}">  -->
                <div id="myCarousel{{i}}" class="carousel slide" data-ride="carousel">
                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
                        <div class="item " *ngFor="let img of property.images;let imgIndex = index"
                            [ngClass]="imgIndex == 0 ? 'active' : 'null'">
                            <img class="property_img" src="{{ imgUrl + img.image }}">
                        </div>
                    </div>
                    <!-- Left and right controls -->
                    <a class="left carousel-control adjus1" data-slide="prev" href="#myCarousel{{i}}"
                        *ngIf="property?.images.length > 1">
                        <i class="fas fa-chevron-left ico" style="padding:6px;"></i>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control adjus" data-slide="next" href="#myCarousel{{i}}"
                        *ngIf="property?.images.length > 1">
                        <i class="fas fa-chevron-right ico" style="padding:9px;"></i>
                        <span class="sr-only">Next</span>
                    </a>
                </div>

            </div>
            <div class="col-md-4 pr-12" style="    padding-left: 5px;width: 49%;">
                <div class="imageK">
                    <img src="https://maps.googleapis.com/maps/api/staticmap?center=24.81{{i}}{{i}},67.03{{i}}{{i}}&zoom=18&size=600x300&maptype=roadmap &markers=color:red%7Clabel:S%7C24.81{{i}}{{i}},67.03{{i}}{{i}}&key=AIzaSyBtGPJeKV8bZQuM73Yr97Q_FNKBqEnkDJ4"
                        alt="" class="property_img hovin">
                    <div class="barAdd">
                        <input type="text" disabled value="{{property.address_one}}">
                    </div>
                    <div class="barAdd1">
                        <button class="btn btn-primary hov" (click)="switchproperty(property?.id)"
                            [disabled]="current_property == property.id" [ngClass]="darkMode ? 'bludar' : 'null'">{{
                            property?.name }}</button>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3 pr-12" style="    padding-left: 5px;width: 32.6%;;text-align: center;padding-right: 5px;">
                <h3 class="card-title">
                    <span  class="" [ngStyle]="{'color': coloarray[i%coloarray.length]}"> {{ property?.name }}</span>
                </h3>
                <button class="btn btn-primary hov" (click)="switchproperty(property?.id)"
                [disabled]="current_property == property.id" style="    margin-top: 32%;">{{ 'back_office.select_hotel.select_property_button' | translate }}</button>
               <div class="btnSelectPrp" [ngClass]="darkMode ? 'cardio' : 'cardiolight'">
                <button class="btn btn-primary hov" (click)="switchproperty(property?.id)" [disabled]="current_property == property.id"  [ngClass]="darkMode ? 'bludar' : 'null'">{{ property?.name }}</button>
                <img src="assets/img/newstaticicons/back-office/amenities-01.svg" alt="amenity icon" height="30px" style="cursor: pointer;" (click)="amenityPro(property.with_parent_amenities)">
               </div>
            </div>  -->
            <!-- <div class="col-md-7 ml-9-pl" style="    width: 62%;">
                <h3 class="card-title">
                    <span  class="" [ngStyle]="{'color': coloarray[i%coloarray.length]}"> {{ property?.name }}</span>
                </h3>
                <div style="display: flex;">

                    <div style="text-align: center;width: 50%;">
                        <div style="font-weight: bold;">Email</div>
                        <div class="det-1">{{ property?.email }}</div>

                        <div style="font-weight: bold;">Address</div>
                        <div class="det-1" style="    margin-bottom: 0px;">{{ property?.address_one }}</div>
                    </div>

                    <div style="text-align: center;width: 50%;">
                        <div class="channelsss">
                               <span style="font-weight: bold;">Connected Channels</span>
                             <div class="clako">
                               <div class="row">
                                   <div class="col-md-12">
                                    <div class="" *ngIf="property?.propertychannels.length != 0">
                                        <img  *ngFor="let co of property?.propertychannels | slice: 0 : 38" src="assets/img/svgicons/smallicons/{{imgr(co.channel.image)}}.svg" />
                                        <img class="dropdown-toggle last-img" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" src="assets/img/manage_rates/dots.png" class="btn-hov" style="height: 30px; border-radius: 100px;" *ngIf="property?.propertychannels.length > 38" />
                                           <ul class="dropdown-menu" style="right: auto;">
                                            <li *ngFor="let logo of property?.propertychannels | slice: 38" class="sp-{{ logo.id }}" >
                                              <a href="#"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></a>
                                            </li>
                                          </ul>
                                    </div>
                                   </div>
                               </div>
                            </div>

                            <div class="clako2">
                                <div class="row">
                                    <div class="col-md-12">
                                     <div class="" *ngIf="property?.propertychannels.length != 0">
                                         <img  *ngFor="let co of property?.propertychannels | slice: 0 : 12" src="assets/img/svgicons/smallicons/{{imgr(co.channel.image)}}.svg" />
                                         <img class="dropdown-toggle last-img" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" src="assets/img/manage_rates/dots.png" class="btn-hov" style="height: 30px; border-radius: 100px;" *ngIf="property?.propertychannels.length > 12" />
                                            <ul class="dropdown-menu">
                                             <li *ngFor="let logo of property?.propertychannels | slice: 12 : 24" class="sp-{{ logo.id }}" >
                                               <div href="#"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></div>
                                             </li>
                                             <br *ngIf="property?.propertychannels.length > 24">
                                             <li *ngFor="let logo of property?.propertychannels | slice: 24 : 36" class="sp-{{ logo.id }}" >
                                                <div style="margin-top: -20px;"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></div>
                                              </li>
                                              <br *ngIf="property?.propertychannels.length > 36">
                                              <li *ngFor="let logo of property?.propertychannels | slice:36 : 48" class="sp-{{ logo.id }}" >
                                                <div style="margin-top: -20px;"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></div>
                                              </li>
                                              <br *ngIf="property?.propertychannels.length > 48">
                                              <li *ngFor="let logo of property?.propertychannels | slice:48" class="sp-{{ logo.id }}" >
                                                <div style="margin-top: -20px;"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></div>
                                              </li>
                                           </ul>
                                     </div>
                                    </div>
                                </div>
                             </div>

                        </div>
                    </div>


                    <div style="display: flex;">
                        <div class="border-style row_cols address-shade" style="margin-bottom: 5px; width: 50%; display: flex; margin-right: 5px;">
                            <div class="p_headingCustom">{{ 'back_office.select_hotel.address' | translate }}</div>
                            <div class="addressPro" data-toggle="tooltip" title="{{ property?.address_one }}">{{ property?.address_one }}</div>
                        </div>

                        <div class="border-style row_cols email-shade" style="margin-bottom: 5px;  width: 50%;  display: flex;">
                            <div class="p_headingCustom">{{ 'back_office.select_hotel.email' | translate }}</div>
                            <div class="addressPro" data-toggle="tooltip" title="{{ property?.email }}">{{ property?.email }}</div>
                        </div>

                    </div>


                    <div style="display: flex;">
                        <div class="border-style row_cols website-shade" style="margin-bottom: 5px;color:rgb(190, 127, 47);  width: 50%;  display: flex; margin-right: 5px;">
                            <div class="p_headingCustom">{{ 'back_office.select_hotel.website' | translate }}</div>
                            <div class="addressPro" data-toggle="tooltip" title="{{ property?.website }}">{{ property?.website }}</div>
                        </div>
                       <div class="border-style row_cols users-shade" style="margin-bottom: 5px; width: 50%; display: flex;">
                           <div class="p_headingCustom">{{ 'back_office.select_hotel.users' | translate }}</div>
                           <div class="addressPro" data-toggle="tooltip" title="{{ property?.users_count }}">{{ property?.users_count }}</div>
                       </div>
                    </div>

                       <div class="border-style row_cols total-rooms-shade" style="margin-bottom: 5px;  width: 50%; display: flex;">
                           <div class="p_headingCustom">{{ 'back_office.select_hotel.total_rooms' | translate }}</div>
                           <div class="addressPro" data-toggle="tooltip" title="{{ property?.users_count }}">{{ property?.room_count }}</div>
                       </div>
                </div>
                <div class="lastip">
                    <ul  class="nav navbar-nav pull-left" style="    margin-top: 9px;">
                        <li >
                            <a class="totlo">
                                <img style="margin: -10px 5px -5px 0px;height: 40px;" src="assets/img/newstaticicons/rates-packages/darkmode/bed.svg">
                                <span class="text-limit-15">{{ property?.address_one }}</span>
                                <span class="tooltiptext tooltiptextdark"> {{ property?.address_one }} </span>
                            </a>
                        </li>
                        <li >
                            <a class="totlo">
                                <img src="assets/img/newstaticicons/rates-packages/darkmode/bed.svg" style="margin: -10px 5px -5px 0px; height: 40px;">
                                <span class="text-limit-15">{{ property?.email }}</span>
                                <span class="tooltiptext tooltiptextdark"> {{ property?.email }} </span>
                            </a>
                        </li>
                        <li *ngIf="property?.website != null">
                            <a class="totlo">
                                <img src="assets/img/newstaticicons/rates-packages/darkmode/bed.svg" style="margin: -10px 5px -5px 0px; height: 40px;">
                                <span class="text-limit-15">{{ property?.website }}</span>
                                <span class="tooltiptext tooltiptextdark">{{ property?.website }} </span>
                            </a>
                        </li>
                        <li>
                            <a class="totlo">
                                <img src="assets/img/newstaticicons/rates-packages/darkmode/bed.svg" style="margin: -10px 5px -5px 0px; height: 40px;">
                                <span class="text-limit-15">{{ property?.users_count }}</span>
                                <span  class="tooltiptext tooltiptextdark" > {{ property?.users_count }} </span>
                            </a>
                        </li>
                        <li >
                            <a class="totlo">
                                <img  src="assets/img/newstaticicons/rates-packages/darkmode/nowheel.svg" style="margin: -10px 5px -5px 0px; height: 40px;">
                                <span  class="text-limit-15">{{ property?.room_count }}</span>
                                <span  class="tooltiptext tooltiptextdark" > {{ property?.room_count }} </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding-right: 8px;padding-left: 10px;">
                <button class="btn btn-primary hov" (click)="switchproperty(property?.id)"
                [disabled]="current_property == property.id" style="    margin-top: 32%;">{{ 'back_office.select_hotel.select_property_button' | translate }}</button>


            </div> -->
        </div>
    </div>
  </div>


    <div id="amenityModal" class="modalForm" [ngClass]="darkMode ? 'modalFormD' : 'modalFormL'"
        *ngIf="showAminModal == true">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-56"
            [ngClass]="darkMode ? 'alDark' : 'alLight'" (mouseover)="dodrag('amenityModal')"
            (mouseout)="stopDrag('amenityModal')" (mouseup)="grab('grab-56')" (mousedown)="grabbing('grab-56')">
            Amenities
            <button type="button" class="closeBtnAm" id="cross" (click)="showAminModal = false">
                <strong>✖</strong>
            </button>
        </div>
        <div class="cardBox ">
            <div class="row" style=" padding: 0px 10px 0px 15px;">
                <div class="col-md-6 zero-padding">
                    <div class="col-md-12 AmiCard-C4 ng-star-inserted">
                        <ng-container *ngFor="let ami of amenityData;let pi = index">
                            <div class="" [ngClass]="darkMode ? 'cardAmenityD' : 'cardAmenity'" *ngIf="pi%2 == 0">
                                <div class="row">
                                    <div class="col-md-12 pr20">
                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12 amenityColumn12">
                                                    <div class="icoAmeni">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/{{darkMode ? 'hotel-setup/darkmode/icons' : 'hotel-setup'}}/{{ami.icon}}">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <label class="aminityTitle"
                                                            [ngStyle]="{'color': darkMode ? amenColor[pi%amenColor.length] : '#00205b'}">
                                                            {{ami.title}}
                                                        </label>
                                                        <a data-toggle="collapse" href="#collapse{{pi}}">
                                                            <img alt="" class="drop"
                                                                src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown1">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{pi}}">
                                        <p *ngFor="let ch of ami.children;let chI = index">{{ch.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-6 zero-padding">
                    <div class="col-md-12 AmiCard-C4 ng-star-inserted">
                        <ng-container *ngFor="let ami of amenityData;let pi = index">
                            <div class="" [ngClass]="darkMode ? 'cardAmenityD' : 'cardAmenity'" *ngIf="pi%2 != 0">
                                <div class="row">
                                    <div class="col-md-12 pr20">
                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12 amenityColumn12">
                                                    <div class="icoAmeni">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/{{darkMode ? 'hotel-setup/darkmode/icons' : 'hotel-setup'}}/{{ami.icon}}">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <label class="aminityTitle"
                                                            [ngStyle]="{'color': darkMode ? amenColor[pi%amenColor.length] : '#00205b'}">
                                                            {{ami.title}}
                                                        </label>
                                                        <a data-toggle="collapse" href="#collapse{{pi}}">
                                                            <img alt="" class="drop"
                                                                src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown1">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{pi}}">
                                        <p *ngFor="let ch of ami.children;let chI = index">{{ch.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="card m-b-0" *ngFor="let property of properties; let i = index" [ngClass]="darkMode ? 'card-dark' : ' card '">
        <div class="row">
            <div class="col-md-3 pr-12" style="    width: 21.7%;">
                <div class="imageK">
                    <img class="property_img" *ngIf="property?.images.length == 0" src="assets/img/select_hotel/img1.png">
                    <img class="property_img" *ngIf="property?.images.length > 0" src="{{ imgUrl + property?.images[0].image }}">
                </div>
            </div>
            <div class="col-md-9 ml-9-pl">

                <div class="red-notification alert-danger" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                    <div class="greetings red_heading">{{ property?.name }}</div>
                </div>

                <div class="row">
                    <div class="col-md-9 mt-10">
                        <div class="border-style row_cols address-shade" style="margin-bottom: 5px;">
                             <div class="p_headingCustom">{{ 'back_office.select_hotel.address' | translate }}</div>
                             <div class="addressPro" data-toggle="tooltip" title="{{ property?.address_one }}">{{ property?.address_one }}</div>

                            </div>
                            <div class="border-style row_cols email-shade" style="margin-bottom: 5px;">
                                <div class="p_headingCustom">{{ 'back_office.select_hotel.email' | translate }}</div>
                                <div class="addressPro" data-toggle="tooltip" title="{{ property?.email }}">{{ property?.email }}</div>

                            </div>

                        <div class="border-style row_cols website-shade" style="margin-bottom: 5px;color:rgb(190, 127, 47);">
                            <div class="p_headingCustom">{{ 'back_office.select_hotel.website' | translate }}</div>
                            <div class="addressPro" data-toggle="tooltip" title="{{ property?.website }}">{{ property?.website }}</div>

                        </div>

                            <div class="border-style row_cols users-shade" style="margin-bottom: 5px;">
                                <div class="p_headingCustom">{{ 'back_office.select_hotel.users' | translate }}</div>
                                <div class="addressPro" data-toggle="tooltip" title="{{ property?.users_count }}">{{ property?.users_count }}</div>

                            </div>

                            <div class="border-style row_cols total-rooms-shade" style="margin-bottom: 5px;">
                                <div class="p_headingCustom">{{ 'back_office.select_hotel.total_rooms' | translate }}</div>
                                <div class="addressPro" data-toggle="tooltip" title="{{ property?.users_count }}">{{ property?.room_count }}</div>

                            </div>

                        <div class="border-style row_cols active-channels-shade mb-0 p-0">
                            <div class="active_heading pl-pt">{{ 'back_office.select_hotel.active_channels' | translate }}
                            </div>
                            <div class="display-flow" >
                                <div class="border-left" *ngIf="property?.propertychannels.length != 0">
                                    <img  *ngFor="let co of property?.propertychannels | slice: 0: 31 " src="assets/img/svgicons/smallicons/{{imgr(co.channel.image)}}.svg" />

                                       <ul style="list-style: none;">
                                            <li *ngIf="property?.propertychannels.length > 31" class="pull-right" style="margin-top: -30px;margin-right: 15px;">
                                                <img class="dropdown-toggle last-img" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" src="assets/img/manage_rates/dots.png" class="btn-hov" style="height: 30px; border-radius: 100px;" />
                                                <ul class="dropdown-menu" style="    right: 28px;">
                                                <li *ngFor="let logo of property?.propertychannels | slice: 31" class="sp-{{ logo.id }}" >
                                                    <a href="#"><img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{imgr(logo.channel.image)}}.svg" /></a>
                                                </li>
                                                </ul>
                                            </li>
                                       </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 btn-col align-centre">
                        <button class="btn btn-primary hov" (click)="switchproperty(property?.id)"
                            [disabled]="current_property == property.id">{{ 'back_office.select_hotel.select_property_button' | translate }}</button>
                        <button  class="btn btn-danger btn-delete" (click)="delete()" data-toggle="modal" data-target="#confirmationModal">Delete</button>
                            <p class="text-danger mb-0 pl-50">To delete please contact our support team.</p>
                    </div>
                </div>

            </div>
        </div>
    </div> -->

    <!-- <div class="card mb-0">
            <div class="row" >
                <div class="col-md-3">
                    <img class="property_img" src="assets/img/select_hotel/img1.png">
                </div>
                <div class="col-md-9 pl-0">

                    <div  class="red-notification alert-danger">
                        <div  class="greetings red_heading">Lesisurex Resort <span>You're currently managing this property</span></div>
                    </div>

                    <div class="row">
                        <div class="col-md-9 mt-10">
                            <p class="border-style row_cols address-shade"> <span class="p_heading">Address</span> Lorem ipsum dolor sit amet</p>
                            <p class="border-style row_cols email-shade"> <span class="p_heading">Email</span>Lorem ipsum dolor sit amet</p>
                            <p class="border-style row_cols website-shade"><span class="p_heading">Website</span> Lorem ipsum dolor sit amet</p>
                            <p class="border-style row_cols users-shade"> <span class="p_heading">Users</span>Lorem ipsum dolor sit amet</p>
                            <p class="border-style row_cols total-rooms-shade"><span class="p_heading">Total Rooms</span> Lorem ipsum dolor sit amet</p>
                            <p class="border-style row_cols active-channels-shade mb-0 p-0"><span class="active_heading pl-pt">Active Channels</span>
                                <span class="display-flow">
                                    <img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/caa_small.png"><img src="assets/img/manage_rates/hotelbeds_small.png"><img src="assets/img/manage_rates/booking_small.png"><img src="assets/img/manage_rates/ctrip_small.png"><img src="assets/img/manage_rates/tripadvisor_small.png"><img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/expedia_small.png">
                                </span>

                            </p>
                        </div>
                        <div class="col-md-3 btn-col">
                                <button  class="btn btn-primary" (click)="switchproperty()">Select this Property</button>
                                <button  class="btn btn-danger btn-delete" (click)="delete()" data-toggle="modal" data-target="#confirmationModal">Delete</button>
                                <p class="text-danger mb-0">To delete this property please contact our support team.</p>
                        </div>
                    </div>

                </div>

            </div>
        </div> -->

    <div class="modal-footer" [ngClass]="darkMode ? 'dark-btn' : 'null'" style="    bottom: -50px;right: 10px;">
        <a href="#" class="btn btn-primary btn-lg hov" [routerLink]="['/app/back_office/hotel_setup']"
            [queryParams]="{add_new: true}">+ {{ 'back_office.select_hotel.add_property' | translate }}</a>
        <a class="btn btn-danger btn-lg hov" (click)="hideModal()" data-dismiss="modal" aria-label="Close">{{
            'back_office.select_hotel.close' | translate }}</a>
    </div>

</div>
