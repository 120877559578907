import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators,ValidationErrors  } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-promo-allocation',
  templateUrl: './promo-allocation.component.html',
  styleUrls: ['./promo-allocation.component.css']
})
export class PromoAllocationComponent implements OnInit {
  base_url:any;
  promo_form: FormGroup;
  submitted:boolean = false;
  package:any;
  channels:any;
  promos:any;
  bHeight;
  channel_id = undefined;
  durationDate = { startDate: moment(), endDate: moment() };
  date = { startDate: moment(), endDate: moment() };
  date_to;
  date_from;
  tolo:boolean = false
  darkMode: boolean = false;
  oldPromos:any;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private service: ManageRatesPackagesService
  ) { }

  ngOnInit() {
    this.base_url=this.api.base_url;
    this.get_channels();

    this.get_package();
    this.modalfooter();
    this.checkDarkMode();
    console.log(this.service.package_creating);

    // this.promo_form = this.fb.group({
    //   property_id: '',
    //   package_id: '',
    //   channel_id: [null, Validators.required],
    //   promo_name: ['', Validators.required],
    //   promo_code: ['', Validators.required],
    //   counter: ['', Validators.required],
    //   discount: ['', Validators.required],
    //   //duration:['', Validators.required],
    //   date_from:['', Validators.required],
    //   date_to:['', Validators.required],
    //   email: ['', Validators.required],
    //   cell_no: ['', Validators.required]
    // })

    
    this.promo_form = this.fb.group({
      package_id: '',
      promo_id: [null, Validators.required],
      channel_id: [null, Validators.required],
    })

    $("#SP").mouseup(function(){
      //$(this).after("<p style='color:green;'>Mouse button released.</p>");
      console.log("mouse doupwn")
      $("#grab-11").css({"cursor":"grab"})
    });
    $("#SP").mousedown(function(){
      //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
      console.log("mouse down")
      $("#grab-11").css({"cursor":"grabbing"})
    });

  }

  get f() { return this.promo_form.controls; }

  get_package(){
    if(this.service.package_creating !==undefined){
        this.api.get('api/package/show/'+this.service.package_creating,true).subscribe((res:any) => {
          console.log(res);
          this.package = res.body.data;
          this.promos = res.body.data.promo_allocations || [];
          this.date = { startDate: moment(res.body.data.date_from), endDate: moment(res.body.data.date_from) };
          this.promo_form = this.fb.group({
            // property_id: res.body.data.property_id,
            // package_id: res.body.data.id,
            // channel_id: [null, Validators.required],
            // promo_name: ['', Validators.required],
            // promo_code: ['', Validators.required],
            // counter: ['', Validators.required],
            // discount: ['', Validators.required],
            // //duration:['', Validators.required],
            // date_from:['', Validators.required],
            // date_to:['', Validators.required],
            // email: ['', [Validators.required, Validators.email]],
            // cell_no: ['', Validators.required]
            package_id: res.body.data.id,
            promo_id: [null, Validators.required],
            channel_id: [null, Validators.required],
            property_id:localStorage.getItem('current_property')
          })
          this.getPromos();
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  get_channels() {
    this.api.post('api/channels/index', { status: 'Enabled' }, true).subscribe((res: any) => {
      this.channels = res.body.data.filter(function (e) { return e.type == 'Internal' });
    }, err => this.error_handling.handle_error(err.status, err.message));
  }
  
  getPromos(){
    this.api.post('api/package_promo/index', {property_id: localStorage.getItem('current_property')}, true).subscribe((res:any) => {
      console.log(res);
      this.oldPromos = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message))
  }


  onSubmit(){
    this.submitted = true;
    Object.keys(this.promo_form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.promo_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });

    // this.promo_form.value.date_to = this.date_to;
    // this.promo_form.value.date_from = this.date_from;
    
    if (this.promo_form.invalid) {
      return;
    }

    console.log(this.promo_form.value);

    this.api.post('api/package_promo/attach_promo_with_package', this.promo_form.value, true).subscribe((res: any) => {
      console.log(res);
      this.promo_form.reset();
      this.submitted=false;
      this.getPromos();
      //this.get_package();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  deletePromo(id){
    console.log(id);
    this.api.get('api/package_promo/destory/'+id,true).subscribe((res: any) => {
      console.log(res);
      this.get_package();
    }, err => this.error_handling.handle_error(err.status, err.message));

  }
  calendar_change(e){
    
    if(e.startDate != null && e.endDate != null){
      this.date_to = moment(e.startDate._d).format('DD/MM/YYYY');
      this.date_from = moment(e.endDate._d).format('DD/MM/YYYY');
    }
    // this.promo_form.value.duration = {
      
    //   startDate: moment(durationDate.startDate._d).format('DD MM YYYY'),
    //   endDate: moment(durationDate.endDate._d).format('DD MM YYYY')
    // }
    

  }
  modalfooter(){

    setTimeout(()=>{
    let f = document.getElementById("ModalFooterP")
    let h = document.getElementById("SP");
    

    this.bHeight = h.offsetHeight;

    if(this.bHeight > 700){
      f.classList.add("widthBig");
      f.classList.remove("widthPad");
     
    }
    else if(this.bHeight < 700){
      f.classList.remove("widthBig");
      f.classList.add("widthPad");
     
    }
  },100)

  }
  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }
  
  toloc(){
    this.tolo = !this.tolo
  }


}
