import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";

declare var $: any;
import { EventsService } from "../../services/events.service";
import { BackOfficeCardLayout } from "./backOfficeCardLayout";

@Component({
  selector: "app-back-office",
  templateUrl: "./back-office.component.html",
  styleUrls: ["./back-office.component.css"],
})
export class BackOfficeComponent implements OnInit {
  skeleton: boolean = true;
  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public router: Router,
    // public error_handling: ErrorHandlingService,
    public events: EventsService
  ) {
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    });
  }
  bHeight;

  property_typee;

  cardLayout: BackOfficeCardLayout[] = [];
  updateCardLayoutToServer: boolean = false;
  darkMode: boolean = false;

  ngOnInit() {
    this.user_current();
    this.checkDarkMode();

    $(function () {
      setTimeout(function () {
        $("#drag_box").dragsort({
          dragSelector: ".jumbo-icon",
          dragBetween: true,
          dragEnd: () => {
            var data = $("#drag_box .drag_item")
              .map(function () {
                return $(this).children().html();
              })
              .get();

            let arr: BackOfficeCardLayout[] = [];

            $("#drag_box .drag_item").map(function () {
              let v = $(this).attr("data-value");
              let k = $(this).attr("data-key");
              let value: BackOfficeCardLayout = {
                cardName: v,
                key: k,
              };
              arr.push(value);

            });

            for (var n = 1; n <= arr.length; n++) {
              arr[n - 1].key = n;
            }

            localStorage.setItem("backOfficeCardLayout", JSON.stringify(arr));
            localStorage.setItem("update_backOfficeToServer", "true");
            console.log("arr", arr);
          },

          placeHolderTemplate:
            "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: transparent !important; height: 100%!important'>&nbsp;</div>" +
            "</div>",
          cursor: "move",
        });
      }, 1000);
    });

    $(function () {
      $("#seasoneModal").mouseup(function () {
        $("#grabingg").css({ cursor: "grab" });
      });
      $("#seasoneModal").mousedown(function () {
        $("#grabingg").css({ cursor: "grabbing" });
      });
    });

    this.loadCardLayout();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // getHeight() {

  //   setTimeout(() => {
  //     let a = document.getElementById("seasoneModal");

  //     this.bHeight = a.offsetHeight;
  //     console.log("name" + this.bHeight);
  //     let di = document.getElementById("col10");
  //     di.classList.remove("widthFUllExtra");

  //         di.classList.add("widthBigExtra");
  //         di.classList.add("widthPadExtra");

  //     },
  //     1500)
  //   }

  // removeClassAccount() {
  //   let di = document.getElementById("col10");
  //   di.classList.remove("widthBigExtra");
  //   di.classList.remove("widthPadExtra");

  // }
  // removeClassAccount2() {
  //   let di = document.getElementById("col10");
  //   di.classList.remove("widthBigExtra");
  //   di.classList.remove("widthPadExtra");
  //   di.classList.add("widthFUllExtra");
  // }

  getHeight() {
    setTimeout(() => {
      let a = document.getElementById("seasoneModal");
      this.bHeight = a.offsetHeight;
      console.log("name" + this.bHeight);
      let di = document.getElementById("col10");
      di.classList.remove("widthFUllExtra");
      if (this.bHeight > 700) {
        di.classList.add("widthBigExtra");
        di.classList.remove("widthPadExtra");
      } else if (this.bHeight < 700) {
        di.classList.remove("widthBigExtra");
        di.classList.add("widthPadExtra");
      }
    }, 1500);
  }

  removeClassAccount() {
    let di = document.getElementById("col10");
    di.classList.remove("widthBigExtra");
    di.classList.remove("widthPadExtra");
  }
  removeClassAccount2() {
    let di = document.getElementById("col10");
    di.classList.remove("widthBigExtra");
    di.classList.remove("widthPadExtra");
    di.classList.add("widthFUllExtra");
  }

  showModal() {
    let modal = document.getElementById("settings");

    modal.classList.remove("fade");
    modal.classList.add("show");
  }

  hideModal() {
    let modal = document.getElementById("settings");
    modal.classList.remove("show");
    modal.classList.add("hide");
  }

  assignEventsToShowModal() {
    $("#settings").modal({ backdrop: "static", keyboard: true });
    $("#settings").draggable();
    $("#settings").draggable("disable");
    $("#settings").on("hidden.bs.modal", () =>
      this.router.navigateByUrl("/app/back_office")
    );
  }
  addEventsToCloseButton() {
    let currentObject = this;
    let closeBtn = document.getElementById("closeBtn");
    closeBtn.addEventListener("click", function () {
      currentObject.hideModal();
    });
  }

  dodrag(id) {
    $("#settings").draggable("enable");
  }
  stopDrag(id) {
    //console.log('stopDrag')
    //this.checkLayoutChanges()
    $("#settings").draggable("disable");
  }
  user_current() {
    let user_current = JSON.parse(localStorage.getItem("user"));
    this.api.get("api/user/show/" + user_current.id, true).subscribe(
      (res: any) => {
        this.property_typee = res.body.data.property_type;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  loadCardLayout() {
    this.api.get("api/user/me", true).subscribe(
      (res: any) => {
        if (res.body.data.widgets_backoffice) {
          localStorage.setItem(
            "backOfficeCardLayout",
            res.body.data.widgets_backoffice
          );
          //this.cardLayout = res.body.data.widgets_backoffice;
        }

        let backOfficeCardLayoutarr = JSON.parse(
          localStorage.getItem("backOfficeCardLayout")
        );
        if (backOfficeCardLayoutarr == undefined) {
          let myAccount: BackOfficeCardLayout = {
            cardName: "My Account",
            key: 1,
          };
          let hotelSetup: BackOfficeCardLayout = {
            cardName: "Hotel Setup",
            key: 2,
          };
          let faqs: BackOfficeCardLayout = { cardName: "FAQs", key: 3 };
          let termsOfUse: BackOfficeCardLayout = {
            cardName: "Terms of Use",
            key: 4,
          };
          let billing: BackOfficeCardLayout = { cardName: "Billing", key: 5 };
          let selectHotel: BackOfficeCardLayout = {
            cardName: "Select Hotel",
            key: 6,
          };
          let contactUs: BackOfficeCardLayout = {
            cardName: "Contact Us",
            key: 7,
          };
          let logs: BackOfficeCardLayout = { cardName: "Logs", key: 8 };

          let layoutList: BackOfficeCardLayout[] = [];

          layoutList.push(myAccount);
          layoutList.push(hotelSetup);
          layoutList.push(faqs);
          layoutList.push(termsOfUse);
          layoutList.push(billing);
          layoutList.push(selectHotel);
          layoutList.push(contactUs);
          layoutList.push(logs);

          this.cardLayout = layoutList;
          localStorage.setItem(
            "backOfficeCardLayout",
            JSON.stringify(this.cardLayout)
          );
        } else {
          console.log(backOfficeCardLayoutarr);
          this.cardLayout = backOfficeCardLayoutarr;
        }
        this.skeleton = false;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
    this.addEventsToCloseButton();
  }

  getCardKey(cardName) {
    return this.cardLayout.find((x) => x.cardName === cardName).key;
    //  let key = this.cardLayout.find(x => x.cardName == cardName)[0].Key
  }

  checkLayoutChanges() {
    let status = localStorage.getItem("update_backOfficeToServer");
    if (status) {
      this.cardLayout = JSON.parse(
        localStorage.getItem("backOfficeCardLayout")
      );
      console.log("Updating card");
      this.api
        .post(
          "/api/user/sorting",
          { type: "BackOffice", data: JSON.stringify(this.cardLayout) },
          true
        )
        .subscribe(
          (res: any) => {
            localStorage.removeItem("update_backOfficeToServer");
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  // skeleton
  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }
}
