import { Injectable } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
declare var $: any;
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SeasonPackageIndexResponse } from 'src/app/common/Types/promotion/SeasonPackageIndexModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageRatesPackagesService {

  packages;
  all_packages;
  package_creating;
  is_edit = false;
  is_preview = false;
  langCode;
  private SeasonPackageIndexEndpoint: string = '/api/season_package/index';


  constructor(
    private api: ApiService,
    private http: HttpClient,
    private helper: HelperService,
    private error_handling: ErrorHandlingService
  ) {
    this.getLangCode()
    this.get_packages()
  }

  getLangCode() {
    var value = localStorage.getItem("code");
    this.langCode = value;
    // console.log(this.langCode)
  }

  get_packages() {
    var property_id = localStorage.getItem("current_property");
    var current_user = JSON.parse(localStorage.getItem('user')).id;
    this.api.post('api/package/index', { property_id: property_id }, true).subscribe((res: any) => {
      this.all_packages = res.body.data;
      /*this.packages = current_user;
      console.log(this.packages);*/

      // this.current_user = res.body.data;

      this.all_packages.forEach(element => {
        let date_from = element.date_from;
        let date_to = element.date_to;

        let a = date_from !== null ? (date_from.indexOf('/') ? date_from.split('/') : date_from.split(' ')) : "";
        let b = date_to !== null ? (date_to.indexOf('/') ? date_to.split('/') : date_to.split(' ')) : "";

        element.date_from = a !== "" ? moment(a[1] + '/' + a[0] + '/' + a[2]).format('DD MMM YY') : "";
        element.date_to = b !== "" ? moment(b[1] + '/' + b[0] + '/' + b[2]).format('DD MMM YY') : "";
      });

      // this.all_packages.forEach(element => {
      //   element.date_from = moment(element.date_from.split(' ')[0]).format('DD MMM YY');
      //   element.date_to = moment(element.date_to.split(' ')[0]).format('DD MMM YY');
      // });
    }, err => this.error_handling.handle_error(err.status, err.message));

  }

  delete(id) {
    var current_user = JSON.parse(localStorage.getItem('user')).id;
    this.api.post('api/package/destory', { current_user: current_user, id: id }, true).subscribe((res: any) => {
      var a = document.getElementById('package_id_' + id);
      // console.log(a);
      a.remove();
      this.get_packages();

      this.helper.alert_success('Package has been deleted successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  duplicate_package(id) {
    this.api.get('api/package/clone/' + id, true).subscribe((res: any) => {
      this.get_packages();

    }, err => this.error_handling.handle_error(err.status, err.message));
    this.helper.alert_success('Package has been duplicated successfully !');
  }

  open_tab(id) {
    // $('#'+id).click();
    return false;
  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set('Authorization', bearer_token);
    return header;

  }
  seasonPackageIndex(payload: { property_id: string, sort: string }): Observable<SeasonPackageIndexResponse> {
    return this.http.post<SeasonPackageIndexResponse>(this.api.base_url + this.SeasonPackageIndexEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    });
  }

}
