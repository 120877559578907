<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a class="btn btn-link" routerLink="/app/back_office">{{ 'back_office.billing.subscription.back_office' | translate }}</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">{{ 'back_office.billing.subscription.billing' | translate }}</a>
    <a class="btn btn-link active">{{ 'back_office.billing.subscription.subscription' | translate }}</a>
</div>
<div class="no_scroll scroll">
  <div class="row">
    <div id="drag_box"class="col-md-12 flex">
        <div class="col-md-4 pr-5 drag_item">
          <div class="card hovdark" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <img src="assets/img/subscriptions/small.png" class="h_img" alt="" srcset="">
            <h1 class="center currency_heading">$ 74.99</h1>
            <h5 class="center channels_heading" style="margin-bottom: 48px;">4 {{ 'back_office.billing.subscription.channels' | translate }}</h5>
            <div class="gradient-bar"><div  class="col-md-12" style="margin-top: 1px;"><h4>{{ 'back_office.billing.subscription.basic_package' | translate }}</h4></div></div>
            <br/>
            <p class="border-style row_cols color-1 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-2 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-3 element"> Lorem ipsum dolor sit amet</p>
            <br><br>
            <div class="btn-wrapper">
              <button class="btn btn-primary btn-lg btn-block hov" data-target="#settings" data-toggle="modal" style="padding-top: 8px">{{ 'back_office.billing.subscription.select' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 pr-5 drag_item">
          <div class="card hovdark" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <img src="assets/img/subscriptions/medium.png" class="h_img" alt="" srcset="">
            <h1 class="center currency_heading">$ 149.99</h1>
            <h5 class="center channels_heading" style="margin-bottom: 48px;">8 {{ 'back_office.billing.subscription.channels' | translate }}</h5>
            <div class="gradient-bar"><div  class="col-md-12" style="margin-top: 1px;"><h4>{{ 'back_office.billing.subscription.premium_package' | translate }}</h4></div></div>
            <br/>
            <p class="border-style row_cols color-4 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-5 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-6 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-7 element"> Lorem ipsum dolor sit amet</p>
            <br><br>
            <div class="btn-wrapper">
              <button class="btn btn-primary btn-lg btn-block modal-btn active-btn hov" data-target="#settings" data-toggle="modal" style="padding-top: 8px">{{ 'back_office.billing.subscription.active_plan' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 drag_item">
          <div class="card hovdark" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <img  src="assets/img/subscriptions/large.png" class="h_img" alt="" srcset="">
            <h1 class="center currency_heading">$ 299.99</h1>
            <h5 class="center channels_heading" style="margin-bottom: 48px;">12+ {{ 'back_office.billing.subscription.channels' | translate }}</h5>
            <div class="gradient-bar"><div  class="col-md-12" style="margin-top: 1px;"><h4>{{ 'back_office.billing.subscription.ultimate_package' | translate }}</h4></div></div>
            <br/>
            <p class="border-style row_cols color-8 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-9 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-10 element"> Lorem ipsum dolor sit amet</p>
            <p class="border-style row_cols color-11 element"> Lorem ipsum dolor sit amet</p>
            <br><br>
            <div class="btn-wrapper">
              <button class="btn btn-primary btn-lg btn-block hov" data-target="#settings" data-toggle="modal" style="padding-top: 8px">{{ 'back_office.billing.subscription.select' | translate }}</button>
            </div>
          </div>
         </div>
    </div>
</div>
</div>


<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" [ngClass]="darkMode ? 'dark-border-div' : ' null '">
      <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                  <app-plan-swtich></app-plan-swtich>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

