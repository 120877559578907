import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-manage-how-you-pay',
  templateUrl: './manage-how-you-pay.component.html',
  styleUrls: ['./manage-how-you-pay.component.css']
})
export class ManageHowYouPayComponent implements OnInit {

  constructor() { }
  darkMode: boolean = false;
  ngOnInit() {

    this.checkDarkMode();
  
    // drag function code----------------------
    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate: "<div class='placeHolder' style=''>" +
              "<div style='background-color: transparent !important; height: 70px;margin-bottom:5px;'></div>" +
              "</div>",
              cursor: "move"
      });

  });
  // drag function end----------------------------------------
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }

}
