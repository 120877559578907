<div class="container-fluid">
  <div class="row">    
      <div id="login-section" class="col-md-5">
        <div class="row">
        
          <div class="col-md-offset-2 col-md-8">
            <div class="text-center">
              <a class="logo btn">
                <img src="../../../assets/img/logo.png" alt="">
              </a><br>
              <small class="sub-title">LOGIN TO YOUR</small>
              <h3 class="title no-margin">Channel Manager</h3><br>
              <div class="bg-danger text-white" *ngIf="errorMessage != null">
                {{errorMessage}}
              </div>
            </div> 
            
            
            <form method="POST" novalidate #form="ngForm" class="form-horizontal" (ngSubmit)="forgot(form)">
              <label class="control-label cg-label">Email</label>
              <input type="text" name="email" class="form-control cg-input" [(ngModel)]="email" required pattern="^[A-Za-z0-9@.]+$">
              <br><br>
              <button class="btn btn-info btn-block cg-btn login-btn" type="submit">Submit</button>
              <a class="btn btn-block cg-btn-blank" routerLink="">Go Back</a>
            </form>
            <br><br><br><br>
  
            <div class="footer"> 
              <div class="row">
                <div class="col-md-6">
                  <small>Copyright 2019 Hotel Genine</small>
                </div>
         
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-7 login-bg"></div>
  </div>
</div> 