import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm;
  email;
  pass;
  errorMessage;
  remember;

  constructor(
    private Api : ApiService,
    public router : Router,
    public auth: AuthService,
  ) { }

  ngOnInit() {
  }

  forgot(form: NgForm){
    if(form.valid){
      console.log(form.value.email);
      this.auth.forgot(form.value.email)
      .subscribe((res: any) => {
        return this.router.navigate(["/"], {queryParams: {forgot: "Password Reset Link Send Successfully, Please check your email."}});
      })
    }else{
      this.errorMessage = "Please Enter Email";
    }
  }  

}
