import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators ,ValidationErrors} from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})
export class CreateUsersComponent implements OnInit {

  user;
  // roles=[{id:'2','label':'Admin'},{id:'3','label':'Manager'},{id:'4','label':'User'}];
  roles:any;
  oriper:any
  rolesC;
  permissions =[];
  permises = []
  permissionApi = []

  create_user_form: FormGroup;
  submitted=false;
  role;
  darkMode: boolean = false;
  userImg:any;
  permis:any;
  customR:boolean = false

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    private route: ActivatedRoute,
    private router: Router,

  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.gerPermissions();
    this.gerRoles();
    if(this.route.snapshot.paramMap.get('id')){
        this.get_user(this.route.snapshot.paramMap.get('id'));
    }

    this.create_user_form = this.fb.group({
        first_name: ['',Validators.required],
        last_name: ['',Validators.required],
        phone: ['',Validators.pattern("[0-9]{0,10}")],
        email: ['',Validators.required],
        role: ['',Validators.required],
        admin_id:'',
        permissions:[[], Validators.required],
        email_notifications:true,
        profile_picture:'',
    });
    this.get_img()
    $(function(){
      $("#hSet").mouseup(function(){
        $("#grabingg").css({"cursor":"grab"})
      });
      $("#hSet").mousedown(function(){
        $("#grabingg").css({"cursor":"grabbing"})
      });
    })
  }

  get f() { return this.create_user_form.controls; }

  onSubmit(){
    console.log(this.create_user_form.value);
    this.submitted=true;
    // console.log(this.create_user_form);
        console.log(this.user);
    if (this.create_user_form.invalid) {
      return;
    }

    var arr= [];
    var perm = [];
    // this.permis = this.create_user_form.value.perm
    var permissions = this.oriper;
    console.log(this.oriper);
  
    for(let i = 0 ; i < this.oriper.length; i++){
     console.log(this.oriper[i].app_permission_id == undefined ? this.oriper[i].id : this.oriper[i].app_permission_id)
      perm.push(this.oriper[i].app_permission_id == undefined ? this.oriper[i].id : this.oriper[i].app_permission_id);
    }
    
    
    Object.keys(permissions).forEach(key => {arr.push(permissions);});
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.create_user_form.patchValue({admin_id: id});
    this.create_user_form.value.profile_picture = this.userImg;
    this.create_user_form.value.permissions = perm

    if(this.user){
      console.log(this.create_user_form.value);
       
      this.api.post('api/user/update/'+this.user.id, this.create_user_form.value, true).subscribe((res:any) => {
        this.helper.alert_success('User Updateddd Successfully...');
    }, err => this.error_handling.handle_error(err.status));
      return;
    }
    console.log(this.create_user_form.value);
    this.api.post('api/user/store', this.create_user_form.value, true).subscribe((res:any) => {
        this.helper.alert_success('User Created Successfully...');
        this.router.navigate(['/app/back_office/manage_users']);
    }, err => this.error_handling.handle_error(err.status));
  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }

  get_user(id){
    this.api.get('api/user/show/'+id, true).subscribe((res:any) => {
        this.user = res.body.data;
        this.create_user_form.patchValue(this.user);
        console.log(this.user);
        this.getVal(this.user.permissions)
        // this.create_user_form.patchValue({permis: this.user.permissionss});
        // this.permis = [1];
        var data = [];
        for(let i = 0 ; i <this.user.permissions.length; i ++){
          data.push(this.user.permissions[i]['app_permission']);

        }
        this.permis = data;

        // for(let i = 0; i < this.user.permissions.length ; i++){

        // }
        // console.log(this.permis = [{"id" : "1","name" : "Arsalan"}]);

        this.role=this.user.role;
        Object.keys(res.body.data.permissions).forEach(elm => {
          try {
            (<HTMLInputElement>document.getElementById(res.body.data.permissions[elm].toLowerCase())).checked = true;
          } catch (error) {
          }
        });
    }, err => this.error_handling.handle_error(err.status));
  }


  gerPermissions(){
    this.api.get('api/helper/app_permissions', true).subscribe((res:any) => {
      this.permissionApi = res.body.data;
      console.clear();
      console.log(this.permissionApi);

    }, err => this.error_handling.handle_error(err.status));
  }

  gerRoles(){
    this.api.get('api/helper/app_roles', true).subscribe((res:any) => {
      this.roles = res.body.data
      this.roles.push({
        label:'Custom Role'
      })
      console.log(this.roles)
    }, err => this.error_handling.handle_error(err.status));
  }


  async onFileChange(event){
    if(event.target.files && event.target.files[0]){
      for(var i=0; i < event.target.files.length; i++){
        var r = null;
        var f = event.target.files[i];
        var data = new FormData();
        data.append('image', event.target.files[i], event.target.files[i].name);
          await this.api.imgPost('api/helper/upload_image', data, false).subscribe((img_res:any) => {
          console.log({image:img_res.body.data.image});
          this.userImg = img_res.body.data.image;
        }, err => this.error_handling.handle_error(err.status));
      }
    }
  }


  get_img(){
    const realFileBtn = (<HTMLInputElement>document.getElementById('real-file')) ;
    const customBtn = (<HTMLInputElement>document.getElementById('custom-button')) ;
    const customText = (<HTMLInputElement>document.getElementById('custom-text'));
    customBtn.addEventListener("click", function(){
      realFileBtn.click()
    })

    realFileBtn.addEventListener("change", function(){
      if(realFileBtn.value){
        customText.innerHTML = realFileBtn.value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/)[1];
      }
      else{
        customText.innerHTML = "No File Chossen"
      }
    })
  }

  getVal(val){
    this.oriper = val;
  }

  chngeRoleInp(role){
    if(role == 'Custom Role'){
      this.customR = true
    }
  }

  customRol(role){
    console.log(role)
  }

  hideModal()
  {
    let modal = document.getElementById('settings')
      modal.classList.remove('show');
      modal.classList.add('hide');
  }

}
