import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})

export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    if (!term || !value) return value;
    return (value || []).filter(item => {
      return keys.split(',').some(key => {
        return item.hasOwnProperty(key) && new RegExp(`^${term}$`).test(item[key]);
      });
    });
  }
}

