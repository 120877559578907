<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a  class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{ 'back_office.create_users.back_office' | translate }}</a>
    <a  class="btn btn-link active">{{ 'back_office.create_users.create_users' | translate }}</a>
</div>

<form class="form-horizontal back-form scroll no_scroll" [formGroup]="create_user_form">

  <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style first-name-shade">
                <input type="text" class="form-control" placeholder="{{ 'back_office.create_users.first_name' | translate }}" formControlName="first_name">
              </div>
              <div *ngIf="submitted && f.first_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.first_name.errors.required">{{ 'back_office.create_users.first_name_is_required' | translate }}</strong>
              </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style last-name-shade">
                <input type="text" class="form-control" placeholder="{{ 'back_office.create_users.last_name' | translate }}" formControlName="last_name">
              </div>
              <div *ngIf="submitted && f.last_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.last_name.errors.required">{{ 'back_office.create_users.last_name_is_required' | translate }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style email-shade">
                <input type="email" class="form-control" placeholder="{{ 'back_office.create_users.enter_your_email_address' | translate }}" formControlName="email">
                <span class="ispan" style="top: 31%; right: 2.5%;">
                  <i class="fas fa-info-circle i" >
                    <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style="width: 272px;margin-left: -138px;">{{ 'back_office.create_users.email_address' | translate }}.</span>
                  </i>
                </span>
              </div>
              <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.email.errors.required">{{ 'back_office.create_users.email_is_required' | translate }}</strong>
              </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style contact-shade">
                <input type="number" class="form-control" placeholder="{{ 'back_office.create_users.enter_your_phone_number' | translate }}" formControlName="phone" maxlength="10" required >
              </div>
              <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.phone.errors.required">{{ 'back_office.create_users.phone_number_is_required' | translate }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
    <div class="row">
      <div class="col-md-12">
        <div class="border-style dark-green-shade dark-green-shade2" style="height: 40px;display: inline-flex;width: 100%;background-color: #f3f3f3;border-color: #e651ff;" [ngClass]="darkMode ? 'bc-select' : ' null '">
          <input type="file" name="photo" (change)="onFileChange($event)" id="real-file" style="display:none">
          <div class="upload-div" [ngClass]="darkMode ? 'bc-select' : ' null '">{{ 'back_office.create_users.upload' | translate }}</div>
          <div id="custom-text">{{ 'back_office.create_users.choose_file' | translate }}</div>
          <button type="button" id="custom-button" [ngClass]="darkMode ? 'bc-select' : ' null '">
            {{ 'back_office.create_users.browse' | translate }}
            <span class="ispan ispn">
              <i class="fas fa-info-circle i">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style=" margin-left: -162px; width: 190px;text-align: left;">
                  {{ 'back_office.create_users.browse_i' | translate }}
                </span>
              </i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
      <div class="">
        <h4>{{ 'back_office.create_users.roles_and_permission' | translate }}</h4>
      </div>
  </div>

  <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style role-shade">
                <ng-select [items]="roles" bindLabel="label" bindValue="label" formControlName="role" placeholder="{{ 'back_office.create_users.select_role' | translate }}" [(ngModel)]="role" [ngClass]="darkMode ? 'dark-drop' : ' null '" *ngIf="!customR" (change)="chngeRoleInp(role)"></ng-select>
                <ng-container *ngIf="customR">
                  <input type="text" class="form-control" placeholder="Custom Role" [(ngModel)]="rolesC" [ngModelOptions]="{standalone: true}">
                  <div class="customDivR">
                    <i class="fa fa-check" (click)="customRol(rolesC)"></i>
                    <i class="fa fa-times" (click)="customR = false"></i>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="submitted && f.role.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.role.errors.required">{{ 'back_office.create_users.role_selection_is_required' | translate }}</strong>
              </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">

              <div class="border-style permission-shade">

                <ng-select [items]="permissionApi" bindLabel="name"  Value="id" formControlName="permissions" [(ngModel)]="permis" (change)="getVal(permis)" [multiple]="true" placeholder="{{ 'back_office.create_users.permissions' | translate }}" class="perDrop" [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer mymodal0"  [ngClass]="darkMode ? 'dark-btn' : 'null'">
      <button type="submit" class="btn btn-primary btn-lg save-button hov" (click)="onSubmit()">{{ 'back_office.create_users.save' | translate }}</button>
      <a  class="btn btn-danger btn-lg hov" (click)="hideModal()" data-dismiss="modal" aria-label="Close">{{ 'back_office.create_users.close' | translate }}</a>
  </div>

  <!-- <pre>{{create_user_form.value | json}}</pre> -->

</form>
