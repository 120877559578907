import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { HelperService } from "./services/helper.service";
import { EventsService } from './services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  darkMode: boolean = false;
  constructor(
    private translate: TranslateService,
    private helper: HelperService,
    public events: EventsService
  ) {
    translate.addLangs(['English', 'Spanish','Arabic','German','French','Italian','Dutch','Polish','Portuguese','Cantonese','Russian','Tagalog','Turkish','Mandarin','Vietnamese']);
    // translate.setDefaultLang('English');
    this.events.receiveDarkMode().subscribe((res:any)=>{
      this.darkMode = res.darkMode;
    })
  }

  ngOnInit() {
   this.defaultLanguage();
   this.checkDarkMode();
  }
  
  defaultLanguage(){
    let user = localStorage.getItem("user")
    if(user){
      let parse = JSON.parse(user)
      if (parse.language === null || parse.language == "" ) {
        this.translate.setDefaultLang('English');
      } else if(parse.language !== null || parse.language !== ""){
      this.translate.setDefaultLang(parse.language);
      }
    } else {
      this.translate.setDefaultLang('English');
    }
  }
  
  checkDarkMode(){
    let a = JSON.parse(localStorage.getItem('user'));
    if(a != null){
      let mode = a.mode;
      if(mode == 'dark'){
        this.darkMode = true;
      }
    }
  }
}
