import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrival-skeleton',
  templateUrl: './arrival-skeleton.component.html',
  styleUrls: ['./arrival-skeleton.component.css']
})
export class ArrivalSkeletonComponent implements OnInit {

  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }

  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }

  getGreyType(i) {

    //convert to if else     
    if (i % 3 == 0) {
      return 'bg-darkgrey';
    } else if (i % 3 == 1) {
      return 'bg-grey';
    } else if (i % 3 == 2) {
      return 'bg-lightgrey';
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
