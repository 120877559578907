<header>

    <div class="notification" [ngClass]="darkMode ? 'card-dark-top-noti' : ' card '" style="padding: 5px 5px 5px 15px;  ">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="greetings">Good Evening {{ user.first_name }}</div> -->
                <!-- <div class="message">{{ 'header.good_evening' | translate }} {{ user?.first_name }}, {{ 'header.noti' | translate }}!</div> -->
                <div class="message">Hello {{ user?.first_name }}, {{ 'header.noti' | translate }}!</div>

                <div style="float: right;" class="topImage">
                    <span>{{ user?.first_name }} {{ user?.last_name }}</span>
                    <img src="{{ user?.profile_picture ? 'https://cmtest.pointclickintegrate.com/public/app/images/' + user?.profile_picture : 'assets/img/nav/user.png' }}" class="img-circle profile-img" />
                </div>
            </div>
        </div>
    </div>

</header>
