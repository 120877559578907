<div id="hideColRes">


    <!-- <div class="col-md-12 blur-bar" style=" color: #828abf;font-weight: 600;background: linear-gradient(#30319a, #062460);border: none;border-radius: 10px;height: 50px;padding: 15px;" [ngClass]="darkMode ? 'smooth-dark' : 'blur-bar'"> 
            3 {{ "back_office.unread_noti" | translate }} ! 
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <strong id="strong">&#x2716;</strong>
              </button>
        </div> -->

    <div class="col-md-12 zero-padding mt5">
        <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : 'breadcrum'">
            <a href="" data-dismiss="modal" aria-label="Close" class="btn btn-link">{{
                "back_office.hotel_setup.back_office" | translate }}</a>
            <a class="btn btn-link" routerLink="/app/back_office/manage_rooms">MANAGE ROOMS</a>
            <a class="btn btn-link active">{{ "back_office.preview" | translate }}</a>
        </div>
    </div>

    <!-- <div class="row">
            <div class="col-md-12">
                <div class="col-md-5 zero-padding" style="padding-right: 2px;width: 38%;">
                    <div class="image" >
                        <img src="http://cm.com/cmlocal/public/app/images/{{p_image}}"/>
                    </div>
                    <div class="bottom-right scrollmenu">
        
                        <div class="inner" *ngIf="previewData.room_name_images != undefined" >
                            <img  *ngFor="let img of previewData?.room_name_images" src="http://cm.com/cmlocal/public/app/images/{{img?.image}}" (click)="img_preview(img?.image)"/>
                        </div>
                        <div class="inner" *ngIf="previewData.room_name_images == undefined" >
                            <img  *ngFor="let img of previewData?.images" src="http://cm.com/cmlocal/public/app/images/{{img?.image}}" (click)="img_preview(img?.image)"/>
                        </div>
                    
                    
                    </div>
                </div>
                <div class="col-md-7 zero-padding" style="padding-left: 3px;width: 61.9%;">
                    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
                        <div class="bar b1">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.room_type" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.room_type_name != undefined ? previewData.room_type_name : previewData.roomtype}}</div>
                            </div>
                        </div>
                        <div class="bar b2">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.bed_type" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.bed_type_name != undefined ? previewData.bed_type_name : previewData.bedtype}}</div>
                            </div>
                        </div>
                        <div class="bar b3">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.no_of_bed" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.no_of_beds}}</div>
                            </div>
                        </div>
                        <div class="bar b4">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.sleep" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.adults}} Adults, {{previewData.children}} Children, {{previewData.infants}}Infants </div>
                            </div>
                        </div>
                        <div class="bar b5">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.no_of_rooms" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.room_count}}</div>
                            </div>
                        </div>
                        <div class="bar b6">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar zero-padding">{{ "back_office.room_size" | translate }}</div>
                                <div class="col-md-8 detailBar zero-padding">{{previewData.room_size}} Sq Ft</div>
                            </div>
                        </div>
                        <div class="bar2 b7">
                            <div class="col-md-12 zero-padding">
                                <div class="col-md-4 headingBar2 zero-padding">{{ "back_office.description" | translate }}</div>
                                <div class="col-md-8 detailBar2 zero-padding"> <div>{{previewData.description}}</div> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 4px;">
            <div class="col-md-12">
               <div class="col-md-12 zero-padding card" style="padding-bottom: 0px;">
                <div class="col-md-4" style="    padding-left: 0px;padding-right: 2px;">
                    <div class="bar b90" style="    height: 60px;">
                        <div class="col-md-12 zero-padding">
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.pets == 'true'" ><img class="img-responsive" src="assets/img/newstaticicons/back-office/smoke.svg"></div>
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.pets == 'false'"><img class="img-responsive" src="assets/img/newstaticicons/back-office/nosmoke.svg"></div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.pets == 'true'">Allowed</div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.pets == 'false'">Not Allowed</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" style="    padding-left: 3px;padding-right: 2px;">
                    <div class="bar b91" style="    height: 60px;">
                        <div class="col-md-12 zero-padding">
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.smoking == 'true'" ><img class="img-responsive" src="assets/img/newstaticicons/back-office/pets.svg"></div>
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.smoking == 'false'"><img class="img-responsive" src="assets/img/newstaticicons/back-office/nopets.svg"></div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.smoking == 'true'">Allowed</div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.smoking == 'false'">Not Allowed</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" style="padding-left: 3px;padding-right: 0px;">
                    <div class="bar b92" style="    height: 60px;">
                        <div class="col-md-12 zero-padding">
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.wheelchairaccessibility == 'true'" ><img class="img-responsive" src="assets/img/newstaticicons/back-office/wheel.svg"></div>
                            <div class="col-md-4 headingBar zero-padding" *ngIf="previewData.wheelchairaccessibility == 'false'"><img class="img-responsive" src="assets/img/newstaticicons/back-office/nowheel.svg"></div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.wheelchairaccessibility == 'true'">Available</div>
                            <div class="col-md-8 detailBar zero-padding" style="line-height: 50px;height: 48px;" *ngIf="previewData.wheelchairaccessibility == 'false'">Not Available</div>
                        </div>
                    </div>
                </div>
               </div>
            </div>
        </div>

        <div class="row">
            
                <div class="col-md-12">
                        <div class="col-md-12 zero-padding" style="padding-left: 0px">
                                <div class="col-md-12 zero-padding red-bar" style="text-align: left;padding: 0px 15px">{{ "back_office.amenities" | translate }}</div>
                                <div class="col-md-12 zero-padding">
                                    <div class="card aminity-card" [ngClass]="darkMode ? 'card-dark' : 'card'">
                                     <div class="row">
                                        <div class="col-md-12 zero-padding">
                                            <div class="col-md-2 zero-padding" *ngFor="let amiC of amenity0">
                                                <div class="col-md-12 amenityHead zero-padding">{{amiC.pTitle}}</div>
                                                    <div class="col-md-12 amenityDetail zero-padding" *ngFor="let Cma of amiC.cTitle">{{Cma.name}}</div>
                                            </div>
                                        </div>
                                     </div> 
                                    </div>
                                </div>
                            </div> -->
    <!-- <div class="col-md-12 zero-padding" style="padding-right: 2px">
                        <div class="col-md-12 zero-padding red-bar" style="margin-bottom: 0px;text-align: left;padding:0px 15px">Floor Plan</div>
                        <div class="col-md-12 zero-padding">
                                <details style="outline: none" *ngFor="let fd of previewData.floors_data" >
                                        <summary style="outline: none" (click)="changeCaret1(fd.id)">
                                        <div class="gradient-bar bl2">
                                            <div class="">
                                              <div class="col-md-11" style="padding-left: 15px"> <h4 style="font-size: 14px;line-height: 20px;padding-left: 0px">{{fd.name}}</h4> </div>
                                              <div class="col-md-1 text-right" style="padding-right: 10px ; padding-top: 10px; padding-left: 60px"> <i class="fa fa-caret-down down2" id="{{fd.id}}" ></i> </div> -->
    <!-- <img src="assets/img/create-rooms/plus-4.png" class="img-responsive assign-plus" height="30px" width="30px" > -->
    <!-- </div>
                                        </div>
                                    </summary>
                                        
                                        <div class="card" style="padding-right: 0px!important;padding-bottom: 0px !important" >
                                            <div class="row" style="padding-left: 20px;padding-right: 20px">
                                                <div class="col-md-2 zero-padding wCard" style="padding-right: 5px;" *ngFor="let fn of fd.floors">
                                                 <div class="col-md-12 zero-padding f-card">
                                                        <details style="outline: none" open>
                                                                <summary style="outline: none" (click)="changeCaret2(fn.id)">
                                                            <div class="col-md-12 zero-padding" style="padding: 0px 5px;outline: none">
                                                                <div class="col-md-6 zero-padding f-name" > <span class="fl">FL</span> <span class="s-line">|</span> <span class="f-nm">{{fn.floor_no}}</span> </div>
                                                                <div class="col-md-6 zero-padding text-right" style="margin-top: 2px"> <i class="fa fa-caret-up down1" id="{{fn.id}}" ></i> </div>
                                                            </div>
                                                                 </summary>
                        
                                                            <div class="col-md-12 zero-padding main-rooms" >
                                                                <div class="col-md-12 zero-padding" style="padding: 0px 5px;margin-top: 5px" *ngFor="let rn of fn.room_num">
                                                                    <div class="col-md-12 zero-padding f-name"> <span class="r-no">{{rn.room_number}}</span> </div> -->
    <!-- <div class="col-md-6 zero-padding text-right"> <img src="assets/img/hotel-setup/trash.png"> </div> -->
    <!-- </div>
                                                            </div>
                                                        </details>
                                                 </div>
                                                </div>
                                                   
                                            </div>
                                        </div>
                                    </details>
                        </div>
                        </div> -->
    <!-- </div>
               
            
        </div> -->


    <div class="row">
        <div class="col-md-12">
            <div class="white_box channels preview_box w-clearfix" [ngClass]="darkMode ? 'card-ami' : '00-bar'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row" style=" padding: 0px 10px;">
                            <div class="col-md-3" style="padding-left: 15px;">
                                <img alt="" class="img-responsive"
                                    style="border-radius: 10px;height: 160px;width: 95%;margin-left: -5px;"
                                    src="{{imgUrl+p_image}}">
                                <div class="img-holder" *ngIf="previewData.room_name_images != undefined">
                                    <img class="holder-img" *ngFor="let img of previewData?.room_name_images"
                                        src="{{imgUrl+img?.image}}" (click)="img_preview(img?.image)" />
                                </div>

                                <div class="img-holder" *ngIf="previewData.room_name_images == undefined">
                                    <img class="holder-img" *ngFor="let img of previewData?.images"
                                        src="{{imgUrl+img?.image}}" (click)="img_preview(img?.image)" />
                                </div>
                            </div>
                            <div class="col-md-9 zero-padding" [ngClass]="darkMode ? 'card-dark-back-txt' : '00-bar'"
                                style="margin-left: -25px;">
                                <h3 class="package_title pkg-heading"
                                    [ngStyle]="{'color': darkMode ? previewData.dark_mode : previewData.color_code}">
                                    {{previewData.room_type_name != undefined ? previewData.room_type_name :
                                    previewData.roomtype}}</h3>


                                <div class="description_text p-b txtWhi"
                                    style="height: 100px; overflow: hidden; line-height: 1.4;margin-top: -5px; margin-bottom: 10px;">
                                    {{ descripArray(previewData?.description) }}
                                    <img src="assets/img/manage_rates/dots.png" (mouseover)="seeDescription()"
                                        (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;"
                                        *ngIf="previewData?.description.split(' ').length > 100">
                                </div>
                                <div class=" description_text p-b" *ngIf="desT"
                                    [ngClass]="darkMode ? 'tooldes' : 'tooldeslight'">
                                    <p>{{ descripArray2(previewData?.description) }}</p>
                                </div>


                                <div class="bt" style="clear: both">
                                    <ul style="list-style: none;display: flex;margin-bottom: 0px;margin-left: -5%;">
                                        <li style="margin-right: 10px">
                                            <a class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {}"
                                                    style="margin: -10px 5px -5px 0px;height: 35px;" />
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    {{previewData.no_of_beds}} {{ previewData.bedtype != undefined ?
                                                    previewData?.bedtype : previewData.bed_type_name }}
                                                </span>
                                            </a>
                                        </li>
                                        <li style="margin-right: 10px">
                                            <a class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    {{ "back_office.manage_rooms.sleeps" | translate }} {{
                                                    previewData.adults + previewData.children + previewData.infants }}
                                                </span>
                                            </a>
                                        </li>
                                        <li style="margin-right: 10px">
                                            <a *ngIf="previewData.room_size_type == 'square_feet'" class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    {{ previewData.room_size }} Sqft
                                                </span>
                                            </a>
                                            <a *ngIf="previewData.room_size_type == 'meters'" class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    {{ previewData.room_size }} M
                                                </span>
                                            </a>
                                        </li>
                                        <li style="margin-right: 10px">
                                            <a class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                                                    *ngIf="previewData?.pets == 'true' || previewData?.pets == true"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                                                    *ngIf="previewData?.pets == 'false' || previewData?.pets == false"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    {{ "back_office.manage_rooms.pets" | translate }}
                                                    {{ previewData.pets == 'false' || previewData.pets == false ? 'Not
                                                    Allowed' : 'Allowed'}}
                                                </span>
                                            </a>
                                        </li>
                                        <li style="margin-right: 10px">
                                            <a *ngIf="previewData.wheelchairaccessibility == 'false' || previewData.wheelchairaccessibility == false"
                                                class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <!-- Not Available -->
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    Not Available
                                                </span>
                                            </a>
                                            <a *ngIf="previewData.wheelchairaccessibility == 'true' || previewData.wheelchairaccessibility == true"
                                                class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <!-- Available -->
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    Available
                                                </span>
                                            </a>
                                        </li>
                                        <li style="margin-right: 10px">
                                            <a *ngIf="previewData.smoking == 'false' || previewData.smoking == false"
                                                class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <!-- Not Allowed -->
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    Not Allowed
                                                </span>
                                            </a>
                                            <a *ngIf="previewData.smoking == 'true' || previewData.smoking == true"
                                                class="totlo">
                                                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                                                    [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                                <!-- Allowed -->
                                                <span class="tooltiptext"
                                                    [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                                    Allowed
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="gradient-bar adj" [ngClass]="darkMode ? 'bredcurm-dark' : 'oo-bar'" style="margin-bottom: 5px;">
            <h4>Room Images</h4>
          </div>
          <div class="card"  [ngClass]="darkMode ? 'card-dark' : '00-bar'">
            <div class="row" >
              <div class="col-md-12">
  
                
                <div class="containerIMG2" *ngIf="previewData.room_name_images != undefined" >
                    <img  *ngFor="let img of previewData?.room_name_images" src="http://cm.com/cmlocal/public/app/images/{{img?.image}}" (click)="img_preview(img?.image)"/>
                </div>
               
                <div class="containerIMG2" *ngIf="previewData.room_name_images == undefined" >
                    <img  *ngFor="let img of previewData?.images" src="http://cm.com/cmlocal/public/app/images/{{img?.image}}" (click)="img_preview(img?.image)"/>
                </div>
  
  
  
              </div>
            </div>
          </div> -->

            <div class="gradient-bar adj" [ngClass]="darkMode ? 'bredcurm-dark' : 'oo-bar'" style="margin: 5px 0px;"
                *ngIf="previewData?.extrabedding.length > 0 ">
                <h4>Extra Beding</h4>
            </div>
            <div class="row" *ngIf="previewData?.extrabedding.length > 0">
                <div class="col-md-12" style="padding-top: 0px;">
                    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="field-space">
                                    <div class="smoker" style="width: 100%;">
                                        <div>
                                            <div>

                                                <div class="extrabed">

                                                    <!-- <span class="cutomChk" style="margin-left: 8px;">
                                    <input type="checkbox" id="Cribs-infant" name="Cribs-infant" value="Cribs/infant beds" style="display: none;" [checked]="previewData?.extrabedding[0].quantity != undefined ? true : false">
                                    <label for="Cribs-infant"> Cribs / Infant beds</label>
                                    </span> -->
                                                    <!-- <br> -->

                                                    <div style="display: flex; text-align: center;"
                                                        *ngIf="previewData?.extrabedding[0].quantity != undefined">
                                                        <div style="width: 20%; margin-right: 5px;border-color: #819a65;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="Cribs/Infant Beds" readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: #4edcbc;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[0].quantity}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: #2f8e3f;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[0].surcharge_type}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: #c7530d;"
                                                            class="border-style b-1 roomtype-shade"
                                                            *ngIf="previewData.extrabedding[0].surcharge_type == 'Surcharge'">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[0].surcharge_amount}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%;border-color: #f5e732;"
                                                            class="border-style b-1 roomtype-shade"
                                                            *ngIf="previewData.extrabedding[0].surcharge_type == 'Surcharge'">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[0].surcharge_amount_type}}"
                                                                readonly>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div style="margin-top: 5px;"
                                                    *ngIf="previewData?.extrabedding.length > 1 && previewData?.extrabedding[1].quantity != undefined">
                                                    <!-- <span class="cutomChk" style="margin-left: 8px;">
                                      <input type="checkbox" id="Rollaway" name="Rollaway" value="Rollaway/extra beds" style="display: none;" [checked]="previewData?.extrabedding[1].quantity != undefined ? true : false">
                                    <label for="Rollaway"> Rollaway / Extra beds</label>
                                    </span>
                                    <br> -->

                                                    <div style="display: flex; text-align: center;">
                                                        <div style="width: 20%; margin-right: 5px;border-color: #8474ff;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="Rollaway / Extra beds" readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: #b94747;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[1].quantity}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: cadetblue;"
                                                            class="border-style b-1 roomtype-shade">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[1].surcharge_type}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%; margin-right: 5px;border-color: darkmagenta;"
                                                            class="border-style b-1 roomtype-shade"
                                                            *ngIf="previewData.extrabedding[1].surcharge_type == 'Surcharge'">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[1].surcharge_amount}}"
                                                                readonly>
                                                        </div>

                                                        <div style="width: 20%;border-color: sandybrown;"
                                                            class="border-style b-1 roomtype-shade"
                                                            *ngIf="previewData.extrabedding[1].surcharge_type == 'Surcharge'">
                                                            <input type="text" class="form-control formRead"
                                                                value="{{previewData.extrabedding[1].surcharge_amount_type}}"
                                                                readonly>
                                                        </div>
                                                    </div>
                                                </div>


                                                <!-- <div style="margin-top: 5px;" *ngIf="previewData?.extrabedding[2].bed_type != undefined">
                                    
                                    <span class="cutomChk" style="margin-left: 8px;">
                                      <input type="checkbox" id="Day-Bed" name="Day-Bed" value="Day Bed" style="display: none;" [checked]="previewData?.extrabedding[2].bed_type != undefined ? true : false">
                                    <label for="Day-Bed"> Day Bed</label>
                                    </span>
                                    <br>
                                    <div style="display: flex; text-align: center;" >
                                      <div style="width: 50%; margin-right: 5px;border-color: #819a65;" class="border-style b-1 roomtype-shade">
                                        <input type="text" class="form-control formRead" value="{{previewData.extrabedding[2].bed_type}}" readonly>
                                      </div>
                                      <div style="width: 50%; margin-right: 5px;border-color: darkmagenta;" class="border-style b-1 roomtype-shade">
                                        <input type="text" class="form-control formRead" value="{{previewData.extrabedding[2].quantity}}" readonly>
                                      </div>                        
                                    </div>
                                  </div> -->

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="row">
                <div class="col-md-12">
                    <div class="col-md-12 zero-padding" style="padding-left: 0px">
                        <div class="col-md-12 zero-padding red-bar gradient-bar adj"
                            [ngClass]="darkMode ? 'bredcurm-dark' : 'oo-bar'"
                            style="text-align: left;padding: 0px 15px">
                            <h4>{{ "back_office.amenities" | translate }}</h4>
                        </div>
                        <div class="col-md-12 zero-padding">
                            <div class="card aminity-card" [ngClass]="darkMode ? 'card-ami' : 'card'">
                                <div class="row">
                                    <div class="col-md-12 zero-padding">
                                        <div class="col-md-2 zero-padding" *ngFor="let amiC of amenity0;let i = index">
                                            <div class="col-md-12 amenityHead zero-padding"
                                                [ngStyle]="{'color': darkMode ? amenCol[i%amenCol.length] : '#00205b'}">
                                                <img src="assets/img/create-rooms/{{amiC.pImg}}.png"
                                                    style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;"
                                                    *ngIf="darkMode == false">
                                                <img src=" assets/img/create-rooms/darkmode/{{amiC.pImg}}.png"
                                                    style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;"
                                                    *ngIf="darkMode == true">

                                                {{amiC.pTitle}}
                                            </div>
                                            <div class="col-md-12 amenityDetail zero-padding"
                                                *ngFor="let Cma of amiC.cTitle">{{Cma.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer m-foot" style="bottom:18.3% !important; padding: 0px; right: 10%;"
                [ngClass]="darkMode ? 'dark-btn' : 'null'">
                <a class="btn btn-primary btn-lg saveBtn" [ngClass]="darkMode ? 'btn-hov' : null" (click)="onSubmit()"
                    style="line-height: 22px !important" *ngIf="previewData.room_name_images != undefined">{{
                    "back_office.save" | translate }}</a>
                <a href="" routerLink="create_rooms" class="btn btn-danger btn-lg closeBtn" [ngClass]="darkMode ? 'btn-hov' : null" data-dismiss="modal"
                    aria-label="Close" style="line-height: 22px !important">{{ "back_office.close"
                    | translate }}</a>
            </div>
        </div>