<div class="CpaddR" style="padding-right: 0px;" id="Crpkl">
  <!-- <div class="alert alt alert-info alert-dismissible show" role="alert" [ngClass]="darkMode ? 'smooth-dark' : 'null'" id="grabingg" style="cursor: grab;">
    {{ 'header.noti' | translate }}
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <strong id="strong">&#x2716;</strong>
    </button>
</div> -->

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{
      'back_office.account_settings.back_office' | translate }}</a>
    <a class="btn btn-link active">{{ 'back_office.account_settings.account_settings' | translate }}</a>
  </div>
</div>

<form class="form-horizontal back-form" [formGroup]="account_settings_form" style="padding-right: 0px;">

  <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
    <div class="row">
      <div class="col-md-10 topBarsInfo">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 fr-mb">
              <div class="border-style pink-shade">
                <input type="text" class="form-control"
                  placeholder="{{ 'back_office.account_settings.first_name' | translate }}"
                  formControlName="first_name">
              </div>
              <div *ngIf="submitted && f.first_name.errors" class="small   no-margin invalid-feedback">
                <strong *ngIf="f.first_name.errors.required">{{ 'back_office.account_settings.first_name_is_required' |
                  translate }}</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-style orange-shade">
                <input type="text" class="form-control"
                  placeholder="{{ 'back_office.account_settings.last_name' | translate }}" formControlName="last_name">
              </div>
              <div *ngIf="submitted && f.last_name.errors" class="small   no-margin invalid-feedback">
                <strong *ngIf="f.last_name.errors.required">{{ 'back_office.account_settings.last_name_is_required' |
                  translate }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 fr-mb">
              <div class="border-style green-shade">
                <input type="number" class="form-control"
                  placeholder="{{ 'back_office.account_settings.enter_your_phone_number' | translate }}"
                  formControlName="phone">
              </div>
              <div *ngIf="submitted && f.phone.errors" class="small   no-margin invalid-feedback">
                <strong *ngIf="f.phone.errors.required">{{ 'back_office.account_settings.phone_number_is_required' |
                  translate }}</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-style brown-shade">
                <input type="text" class="form-control"
                  placeholder="{{ 'back_office.account_settings.enter_your_email_address' | translate }}"
                  formControlName="email" [disabled] style="    padding-bottom: 4px;">
                <span class="ispan"><i class="fas fa-info-circle i" style="font-size: 15px;">
                    <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                      <strong>{{ 'back_office.account_settings.contact_support' | translate }}.</strong>
                    </span>
                  </i></span>
              </div>
              <!-- <p class="small email_text no-margin"><strong>{{ 'back_office.account_settings.contact_support' | translate }}</strong>
                </p>  -->
            </div>
          </div>
        </div>

        <div class="row field-space lang-bot">
          <div class="col-md-12">
            <div class="col-md-6">
              <div class="border-style blue-shade">
                <ng-select [items]="languages" bindValue="name" bindLabel="name" formControlName="language"
                  placeholder="{{ 'back_office.account_settings.select_language' | translate }}"
                  [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-style blue-shade">
                <ng-select formControlName="property_type" [(ngModel)]="property_typee"
                  placeholder="{{ 'Property Setup' | translate }}" [ngClass]="darkMode ? 'dark-drop' : ' null '">
                  <ng-option value="hotel_setup">{{ 'back_office.account_settings.hotel_setup' | translate }} </ng-option>
                  <ng-option value="Vocational_Rental"> {{ 'back_office.account_settings.rental_property' | translate }} </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-2 col-sm-6 profile-img zero-padding">
        <div class="img-wrapper">
          <img class="img img-circle" src="{{ show_pic }}" (click)="getImage()" style="cursor: pointer;">
          <input type="file" name="photo" formControlName="profile_picture" (change)="onSelectFile($event)"
            style="cursor: pointer;" id="imageBtn">
          <div class="img-icon">
            <small><strong>{{ 'back_office.account_settings.delete_picture' | translate }}</strong></small><br>
            <!-- <img src="assets/img/back-office/4336.png" width="auto" height="auto" (click)="remove_file()" class="file_uploader"> -->
            <img src="assets/img/newstaticicons/back-office/delete-pic.svg" width="auto" height="auto"
              (click)="remove_file()" class="file_uploader">
          </div>
        </div>
        <!-- <div class="upload-btn-wrapper btn-group-lg hovv" style="cursor: pointer;">
              <button class="btn btn-primary btn-block" style="cursor: pointer;">{{ 'back_office.account_settings.upload_picture' | translate }}</button>
              <input type="file" name="photo" formControlName="profile_picture" (change)="onSelectFile($event)" style="cursor: pointer;">
          </div> -->
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col-md-12 zero-padding">
      <div class="col-md-6" style="padding-right: 2px;width: 64%;">
        <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null '">
          <div>
            <h4>{{ 'back_office.account_settings.Theme_&_Layout' | translate }}</h4>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 grid_20 zero-padding">
              <div class="card mode_layout" [ngClass]="darkMode ? 'card-dark' : ' card '">
                <div class="row row-flex">
                  <div class="col-md-6 grid_20_inside">
                    <div class="radio-box">
                      <label class="control-label radio-style"
                        [ngStyle]="account_settings_form.value.mode == 'light' ? {'border': '2px solid #ff8d00','border-radius': '10px' } : null">
                        <input type="radio" class="pull-left radio  dinon" name="mode" value="light"
                          formControlName="mode">
                        <span class="radio-checkmark dinon"></span>
                        <span class="title dinon">Light Mode</span>
                        <img src="assets/img/back-office/dashlightp.png" class="img-responsive modes" class="app-mode">
                        <!-- [ngStyle]="account_settings_form.value.mode == 'light' ? {'border': '1px solid #ff8d00','border-radius': '10px'} : null" -->
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6 grid_20_inside">
                    <div class="radio-box">
                      <label class="control-label radio-style"
                        [ngStyle]="account_settings_form.value.mode == 'dark' ? {'border': '2px solid #ff8d00','border-radius': '10px'} : null">
                        <input type="radio" class="pull-left radio  dinon" name="mode" value="dark"
                          formControlName="mode">
                        <span class="radio-checkmark dinon"></span>
                        <span class="title dinon">Dark Mode</span>
                        <img src="assets/img/back-office/dashdarkp.png" class="img-responsive modes" class="app-mode">

                        <!-- [ngStyle]="account_settings_form.value.mode == 'dark' ? {'border': '1px solid #ff8d00','border-radius': '10px'} : null" -->

                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-6 grid_20">
                  <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                      <div class="row row-flex">
                          <div class="col-md-6 grid_20_inside">
                              <div class="radio-box">
                                <label class="control-label radio-style">
                                    <input type="radio" class="pull-left radio" name="layout" value="traditional"  formControlName="layout">
                                    <span class="radio-checkmark"></span>
                                    <span class="title">Custom Layout</span>
                                    <img src="assets/img/back-office/4533.png" class="img-responsive">
                                </label>
                              </div>
                          </div>
                          <div class="col-md-6 grid_20_inside">
                              <div class="radio-box">
                                <label class="control-label radio-style">
                                  <input type="radio" class="pull-left radio" name="layout" value="modern" formControlName="layout">
                                  <span class="radio-checkmark"></span>
                                  <span class="title">Modern Layout</span>
                                  <img src="assets/img/back-office/4534.png" class="img-responsive">
                                </label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-6" style="padding-left: 3px;width: 36%;">
        <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null '">
          <div class="">
            <h4>{{ 'back_office.account_settings.credentials_and_security' | translate }}</h4>
          </div>
        </div>


        <div class="card mb-0" [ngClass]="darkMode ? 'card-dark' : ' card '">

          <!-- <div class="row">
            <div class=" field-space">
              <div class="col-md-12">
                <div class="col-md-12" style="    padding: 0px 5px;    margin-top: 5px;">
                  <a *ngIf="open_custom == false" class="btn btn-danger btn-block btn-lg text-left f-s hov"
                    (click)="open_dialoge()">
                    <strong>{{ 'back_office.account_settings.change_credentials' | translate }}</strong>

                    <span class="info"><i class="fas fa-info-circle i" style="font-size: 15px;">
                        <span class="tooltiptext3" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext3'">
                          <p class="small   no-margin" style=" padding: 0px !important; margin: 0px !important;">

                            {{ 'back_office.account_settings.to_change_credentials' | translate }}
                        </span>
                      </i>
                    </span>

                  </a>



                  <a *ngIf="open_custom == true" class="btn btn-primary btn-block btn-lg text-left"
                    (click)="add_custom()" style="font-size: 16px;">
                    <strong>{{ 'back_office.account_settings.add_custom_qa' | translate }}</strong>
                  </a>

                </div>
              </div>

            </div>
          </div> -->

          <!-- <div class="row" *ngIf="open_change_pass"> -->
          <div class="row" >
            <div class="col-md-12">
              <div class="change_password card" [ngClass]="darkMode ? 'card-dark' : ' card '" style="overflow: hidden;"
                style="margin-bottom: 0px;">
                <div class="row">
                  <div class="col-md-12 passchngeColchay">
                    <div class="row">
                      <div class="col-md-12 field-space">
                        <div class="border-style red-shade">
                          <input type="password" class="form-control"
                            placeholder="{{ 'back_office.account_settings.current_password' | translate }}"
                            formControlName="current_password" [(ngModel)]="current_password">
                        </div>
                      </div>
                      <div class="col-md-12 field-space">
                        <div class="border-style sea-blue-shade">
                          <!-- <input type="password" class="form-control" placeholder="Enter New Password" formControlName="new_password" [required]="current_password ? true:null "> -->
                          <input type="password" class="form-control"
                            placeholder="{{ 'back_office.account_settings.new_password' | translate }}"
                            formControlName="new_password">
                        </div>
                        <div class="error-text"
                          *ngIf="account_settings_form.get('new_password').hasError('passwordStrength')">
                          <p class="small no-margin">
                            <strong>{{ account_settings_form.get('new_password').errors['passwordStrength']}}</strong>
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="border-style see-green-shade">
                          <input type="password" class="form-control"
                            placeholder="{{ 'back_office.account_settings.confirm_new' | translate }}"
                            formControlName="confirm_password">
                        </div>
                        <div *ngIf="pass_sub && f.confirm_password.errors" class="small no-margin invalid-feedback">
                          <div *ngIf="f.confirm_password.errors.required"> {{
                            'back_office.account_settings.confirm_pass_required' | translate }} </div>
                        </div>
                        <div
                          *ngIf="account_settings_form.errors?.mismatch && (account_settings_form.controls['confirm_password'].dirty || account_settings_form.controls['confirm_password'].touched)"
                          class="small   no-margin invalid-feedback">
                          <div> {{ 'back_office.account_settings.password_confirm' | translate }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 password-details">
                    <div class="pass_strength field-space" *ngIf="account_settings_form.get('new_password').errors">
                      <h5 class="pass">
                        {{ 'back_office.account_settings.password_strength' | translate }}
                        <!-- <strong class="pull-right">{{ account_settings_form.get('new_password').errors['status']}}</strong> -->

                        <i class="fas fa-info-circle wonka">
                          <span class="tooltiptext2" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext2'">
                            <!-- <p class="pass1">{{ 'back_office.account_settings.uppercase_letter' | translate }}.</p>
                            <p class="pass1">{{ 'back_office.account_settings.add_numbers' | translate }}.</p>
                            <p class="pass1">{{ 'back_office.account_settings.add_symbol' | translate }}.</p> -->
                            <p>Your password can be any combination of letters, numbers, and symbols.</p>
                          </span>
                        </i>

                      </h5>

                      <div class="progress">
                        <div [style.width.%]="account_settings_form.get('new_password').errors['percent']"
                          [style.background-color]="account_settings_form.get('new_password').errors['color']"
                          class="progress-bar">
                        </div>
                      </div>
                    </div>
                    <div class="pass_strength field-space" *ngIf="account_settings_form.get('new_password').errors == null">
                      <h5 class="pass">
                        {{ 'back_office.account_settings.password_strength' | translate }}
                        <strong class="pull-right">{{ 'back_office.account_settings.excellent' | translate }}</strong>
                      </h5>
                      <div class="progress">
                        <div [style.width.%]="100" [style.background-color]="'#3ff204'" class="progress-bar">
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>

  <div class="modal-footer myModal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
    <button type="submit" class="btn btn-primary btn-lg save-button hov" (click)="onSubmit()">{{
      'back_office.account_settings.save' | translate }}</button>
    <a href="javscript:void(0)" class="btn btn-danger btn-lg bt-dark hov" data-dismiss="modal" aria-label="Close"
      (click)="hideModal()" id="closeBtn">{{ 'back_office.account_settings.close' | translate }}</a>
  </div>
</form>
