<div class="jumbotron ">
    <div class="jumbo-icon">
        <li class="" title="Page Title" text="Main title!" style="list-style-type: none;">
            <div class="dash_window w-clearfix dashboard-card dragDash" id="div2"
                [ngClass]="darkMode ? 'dash_window_dark' : 'dash_window'">
                <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'win_header'">
                    <h4 class="heading" [ngClass]="darkMode ? 'heading_dark' : 'heading'">
                        {{ 'dashboard.OTARevenue.key' | translate }} </h4>
                    <div class="bar two-c">
                        <ng-select class="form-control custom input_2 padding-holder " placeholder=" Today "
                            [(ngModel)]="graphDaysPota" (change)="getDashboardDataota()" [items]="graphDaysota"
                            bindValue="name" bindLabel="name"
                            style="height: 30px;padding:0px;padding-left: 0px;padding-right: 0px ; border: none">
                        </ng-select>
                    </div>
                </div>
                <div class="comp_revenue w-clearfix" *ngIf="dashboard?.booking_revenue.highest != null"
                    [ngClass]="darkMode ? 'notifications_dark' : 'comp_revenue'">
                    <h4 class="channel_name" [ngClass]="darkMode ? 'ota_revenue_channel_name_dark' : 'channel_name'">
                        {{ 'dashboard.OTARevenue.highest' | translate }}</h4>
                    <span *ngIf="dashboard?.booking_revenue.highest == 0"
                        class="setup-ota">{{'dashboard.OTARevenue.please_setup_ota' |
                        translate}}</span>
                    <img *ngIf="dashboard?.booking_revenue.highest != 0 && dashboard?.booking_revenue.highest.channel.type == 'External'"
                        src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + (dashboard?.booking_revenue.highest.channel.image) : (dashboard?.booking_revenue.highest.channel.image)}}"
                        alt="Ota Image" class="highest_icon">
                    <img *ngIf="dashboard?.booking_revenue.highest != 0 && dashboard?.booking_revenue.highest.channel.type == 'Internal'"
                        src="assets/img/svgicons/channels/{{dashboard?.booking_revenue.highest.channel.image}}"
                        alt="Ota Image" class="highest_icon">
                    <div class="channel_bar_bg w-clearfix" [ngClass]="darkMode ? 'dash_window_dark' : 'channel_bar_bg'">
                        <div class="channel_bar_progress w-clearfix"
                            [ngStyle]="{'width': per(dashboard?.booking_revenue.highest.total_sum, dashboard?.booking_revenue.total)+'%'}">
                            <h5 class="heading-2" *ngIf="dashboard?.booking_revenue.highest != null">
                                ${{dashboard?.booking_revenue.highest.total_sum}}.00
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="comp_revenue w-clearfix" *ngIf="dashboard?.booking_revenue.lowest != null"
                    [ngClass]="darkMode ? 'notifications_dark' : 'comp_revenue'">
                    <h4 class="channel_name" [ngClass]="darkMode ? 'ota_revenue_channel_name_dark' : 'channel_name'">
                        {{ 'dashboard.OTARevenue.lowest' | translate }}</h4>
                    <span *ngIf="dashboard?.booking_revenue.lowest == 0"
                        class="setup-ota">{{'dashboard.OTARevenue.please_setup_ota' |
                        translate}}</span>
                    <img *ngIf="dashboard?.booking_revenue.lowest != 0 && dashboard?.booking_revenue.lowest.channel.type == 'External'"
                        src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + (dashboard?.booking_revenue.lowest.channel.image):(dashboard?.booking_revenue.lowest.channel.image)}}"
                        alt="Ota Img" class="lowest_icon">
                    <img *ngIf="dashboard?.booking_revenue.lowest != 0 && dashboard?.booking_revenue.lowest.channel.type == 'Internal'"
                        src="assets/img/svgicons/channels/{{dashboard?.booking_revenue.lowest.channel.image}}"
                        alt="Ota Image" class="lowest_icon">
                    <div class="channel_bar_bg w-clearfix" [ngClass]="darkMode ? 'dash_window_dark' : 'channel_bar_bg'">
                        <div class="channel_bar_progress lowest w-clearfix"
                            [ngStyle]="{'width': per(dashboard?.booking_revenue.lowest.total_sum, dashboard?.booking_revenue.total)+'%'}">
                            <h5 class="heading-2" *ngIf="dashboard?.booking_revenue.lowest != null">
                                ${{dashboard?.booking_revenue.lowest.total_sum}}.00
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="comp_revenue w-clearfix" [ngClass]="darkMode ? 'notifications_dark' : 'comp_revenue'">
                    <h4 class="channel_name" [ngClass]="darkMode ? 'ota_revenue_channel_name_dark' : 'channel_name'">
                        {{ 'dashboard.OTARevenue.total' | translate }}</h4>
                    <span *ngIf="dashboard?.booking_revenue.total == 0"
                        class="setup-ota">{{'dashboard.OTARevenue.please_setup_ota' |
                        translate}}</span>
                    <div class="channel_bar_bg w-clearfix" style="top: 22%;"
                        [ngClass]="darkMode ? 'dash_window_dark' : 'channel_bar_bg'">
                        <div class="channel_bar_progress total w-clearfix"
                            [ngStyle]="{'width': dashboard?.booking_revenue.total == 0 ? '50%' : '100%'}">
                            <h5 class="heading-2">
                                ${{dashboard?.booking_revenue.total}}.00
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
</div>