<div class="row">
  <div class="col-md-12">
    <div class="filter-box reports-filter" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
      <form class="form-inline">
        <div class="no-gutters">
          <div class="col-md-12 custom-width">
            <div class="col-md-2 filters_col">
              <img src="assets/img/newstaticicons/report/line-view.svg" alt="" class="placeholder-img" />
              <ng-select class="form-control input_2 padding-holder orangSelect" [items]="charts" bindLabel="name"
                bindValue="id" [(ngModel)]="selectedChart" (change)="changeGrp($event)" ng-init="charts[0].name"
                placeholder="{{ 'reports.line_chart' | translate }}" name="selectedChart">
              </ng-select>
            </div>
            <div class="col-md-2 filters_col">
              <img src="assets/img/newstaticicons/report/compare.svg" alt="" class="placeholder-img" />
              <ng-select [items]="channels" id="idCompare" class="form-control input_2 padding-holder orangSelect"
                placeholder="{{ 'reports.compare' | translate }}" name="compare_channels" [clearable]="true"
                [multiple]="true" bindLabel="channel_name" bindValue="channel_id" (change)="set_compare_channels()"
                [(ngModel)]="compare_channels" [closeOnSelect]="false" [maxSelectedItems]="8">
                <ng-template ng-multi-label-tmp let-items="items">
                  <div class="ng-value" *ngFor="let item of items | slice: 0:1; index as i">
                    <span class="ng-value-label">{{ items[i].channel_name }}</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{ items.length - 1 }} more</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    [disabled]="compare_channels.length == 8 ? true : false" />
                  {{ item.channel_name }}
                </ng-template>
              </ng-select>
            </div>

            <div class="col-md-2 filters_col">
              <img src="assets/img/newstaticicons/report/occup.svg" alt="" class="placeholder-img" />
              <ng-select class="form-control input_2 padding-holder orangSelect"
                placeholder="{{ 'reports.metrics' | translate }}" [items]="metrics" name="metrics" bindLabel="name"
                bindValue="slug" [(ngModel)]="current_metric" (change)="changeMet($event)"></ng-select>
            </div>

            <div class="col-md-2 filters_col" style="width: 410px;">
              <img src="assets/img/newstaticicons/report/date.svg" alt="" class="placeholder-img" />
              <div class="input form-control" style="background: linear-gradient(#fff, lightgrey);">
                <input type="hidden" name="rdate_to" [(ngModel)]="rdate_to">
                <input type="hidden" name="rdate_from" [(ngModel)]="rdate_from">
                <!-- <input type="hidden"  name="date_to" [(ngModel)]="date_to">
                <input type="hidden"  name="date_from" [(ngModel)]="date_from"> -->
                <input type="text" ngxDaterangepickerMd class="form-control date_range_picker arrival-lenght"
                  [(ngModel)]="choosen_date" placeholder="{{ 'reports.date' | translate }}" [singleDatePicker]="false"
                  [autoApply]="true" [dateLimit]="30" (change)="date_change(choosen_date)"
                  [locale]="{format: 'DD MMMM', separator: '  To  '}" [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <!-- channel Dropdown -->
            <div class="col-md-2 filters_col">
              <img src="assets/img/reports/filter.svg" alt="" class="placeholder-img" />
              <ng-select class="form-control input_2 padding-holder orangSelect" placeholder="By Channel"
                (change)="dropCH = $event; getroomWiseData(true);">
                <ng-option value="By Channel">By Channel</ng-option>
                <ng-option value="By Room">By Room</ng-option>
              </ng-select>
            </div>

            <!-- Go Button -->
            <div class="col-md-4 CTom" [ngClass]="darkMode ? 'dark-btn' : 'null'">
              <a class="btn btn-primary btn-md btn-hov" (click)="reset_filter()">
                {{ "reports.reset" | translate }}
              </a>

              <div style="width: 15%;float: right; padding-top: 3px;">
                <div *ngIf="restriction == true" id="revenueFalse" (click)="restriction = false" class="type-img">
                  <img src="assets/img/newstaticicons/report/numaric1.svg" class="btn-hov"
                    style="cursor: pointer; border-radius:20px ;height:30px; margin-top: 5px;" />
                </div>
                <div *ngIf="restriction == false" (click)="restriction = true" id="revenuetrue" class="type-img">
                  <img src="assets/img/newstaticicons/report/percentage1.svg" class="btn-hov"
                    style="cursor: pointer;  border-radius:20px ;height:30px; margin-top: 5px;" />
                </div>
              </div>
            </div>

            <div>
              <img src="assets/img/newstaticicons/report/download.svg" class="go_icon btn-hov"
                style="right: 7px;height: 30px;width: 30px;margin-top: 5px;position: absolute;cursor: pointer;border-radius: 50%;"
                (click)="pdf_css()" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="capture scroll no_scroll" [ngClass]="darkMode ? 'card-dark' : 'null'"
  style="background-color: transparent !important;">
  <div class="card text-center" [ngClass]="[pdfgen == true ? 'card0' : 'card']">
    <div class="row">
      <div class="col-md-12 padding-25" *ngIf="selectedChart != 3">
        <div class="card-heading text-left">
          <div class="heading-style" [ngClass]="darkMode ? 'heading-styleDark' : 'null'">
            {{ "reports.channel_performance" | translate }}
          </div>
          <div class="pull-right">
            <div class="total-earning total-earningDark" [ngClass]="darkMode ? 'null' : 'redColor'"
              style="float: left;    margin: 2px 10px 0px 0px;">
              <!-- {{ "reports.total_earnings_in" | translate }} -->
              <i class="fas fa-info-circle"> <span class="tooltiptext"
                  [ngClass]="darkMode ? 'tooltiptextdark' : 'null'">
                  <p>{{ 'reports.total_earning_in' | translate }} {{ choosen_range.length }} {{ 'reports.days' |
                    translate }}</p>
                </span> </i> {{ choosen_range.length }} {{ "reports.days" | translate }}
            </div>
            <div class="cash-amountDark" [ngClass]="darkMode ? 'null' : 'blueColor'" style="float: left;">
              {{current_currency}} {{
              total_set.revenue_earned }}</div>
          </div>
        </div>
      </div>

      <!-- LIST VIEW -->
      <div *ngIf="selectedChart == 3" [ngClass]="darkMode ? 'card_darkList' : 'null'" class="three">

        <div class="" [ngClass]="darkMode ? 'main-flexDark' : 'main-flex'">
          <div class="list-head">
            {{ 'reports.channels' | translate }}
          </div>
          <div class="list-heads"> {{ 'reports.commission' | translate }} </div>
          <div class="list-heads">{{ 'reports.avg_stay' | translate }}</div>
          <div class="list-heads">{{ 'reports.adr' | translate }}</div>
          <div class="list-heads" style="padding-left: 5px;">{{ 'reports.revpar' | translate }}</div>
          <div class="list-heads" style="padding-left: 5px;">{{ 'reports.occupancy' | translate }}</div>
          <div class="list-heads" style="padding-left: 10px;">{{ 'reports.revenue' | translate }}</div>
        </div>
        <div *ngFor="let page of page_arr[current_page - 1]" class="col-md-12 pd-new3" style="padding-right: 15px;">
          <div class="list-data-flex " [ngClass]="darkMode ? 'Dhovi' : 'Lhovi'"
            style="height: 40px; margin-bottom: 5px;">
            <div class="list-data-img"
              style="margin: 0px 5px 0px 5px;margin-bottom: 0px;border-radius: 10px;width: 13%;"
              [ngClass]="darkMode ? 'list-data-imgDark' : 'list-data-img'">
              <!-- <img src="assets/img/reports/{{page.image.split('.')[0]}}_chart.png" class="chart-img" alt="" width="100%"/> -->
              <!-- <img src="assets/img/svgicons/channel/{{imgr(page.image)}}.svg" class="chart-img" alt=""/> -->
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + page.image : page.image}}"
                *ngIf="page.channel_type == 'External'" class="chart-img" alt="" />
              <img src="assets/img/svgicons/channels/{{page.image}}" *ngIf="page.channel_type == 'Internal'"
                class="chart-img" alt="" />
            </div>
            <div class="forHover">
              <div class="list-data-data border-left colD1">
                {{ page.channel_commission }} %
              </div>
              <div class="list-data-data colD2">{{ page.total_nights }}</div>
              <div class="list-data-data colD3"> {{ current_currency }} {{ page.avg_daily_rate }}</div>
              <div class="list-data-data colD4">{{ current_currency }} {{ roundNo(page.rev_par) }}</div>
              <div class="list-data-data colD5">{{ page.occupany_rate }} %</div>
              <div class="list-data-data colD6 border-right">
                <span *ngIf="restriction == false">% {{ page.rev_percentage }}</span>
                <span *ngIf="restriction == true">{{ current_currency }} {{ page.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BAR VIEW -->
      <div *ngIf="selectedChart == 2" class="Two">
        <div *ngFor="let page of page_arr[current_page - 1]" class="col-md-12 pd-5">
          <div class="col-md-2 chart-padding chart-width">
            <div class="initial-text" style="border-radius: 10px;width: 103%;padding:2px;"
              [ngClass]="darkMode ? 'backpicDark' : 'backpic'">
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + page.image : page.image}}"
                *ngIf="page.channel_type == 'External'" class="chart-img" alt="" />
              <img src="assets/img/svgicons/channels/{{page.image}}" *ngIf="page.channel_type == 'Internal'"
                class="chart-img" alt="" />
            </div>
          </div>
          <div class="col-md-9 bar-padding bar-width">
            <div class="pt-30">
              <div class="progress" [ngClass]="darkMode ? 'darkProBar' : 'null'">
                <div class="progress-bar" [ngStyle]="{ 'width': page.rev_percentage + '%' }"
                  [ngClass]="darkMode ? 'backdarkbar' : 'backlightbar'">
                  <div class="progress-value" [ngClass]="darkMode ? 'darkPro' : 'null'">
                    <span *ngIf="current_metric == ''">
                      {{ restriction ? "$" + page.total : page.rev_percentage + "%" }}
                    </span>
                    <span *ngIf="current_metric == 'checkins'">
                      {{page.occupany_rate + '%'}}
                    </span>
                    <span *ngIf="current_metric == 'avg_daily_rate'">
                      {{ current_currency }} {{roundNo(page.avg_daily_rate)}}
                    </span>
                    <span *ngIf="current_metric == 'rev_par'">
                      {{ current_currency }} {{roundNo(page.rev_par)}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- LINE VIEW  CHART-->
      <div *ngIf="selectedChart == 1">
        <div class="col-md-12" style="display: none;">
          <div id="chartContainer" style="height: 370px; width: 100%;"></div>
        </div>
        <div>
          <div class="col-md-12">
            <div class="line_graph_box">
              <div class="text-left logo-box">
                <label *ngFor="let channel of graph_arr ? graph_arr.slice(0, 5) : []; let k = index;">
                  <span *ngIf="channel.channel_id != 0">
                    <input type="radio" name="graph_radio" id="switch_graph"
                      (change)="switch_paths(channel.channel_id);" (click)="toolColor = k"
                      [checked]="graph_line_switch == channel.channel_id" />
                    <img alt="" *ngIf="channel.type == 'External'"
                      src="assets/img/svgicons/otas/{{ channel.channel_img}}" style="height: 60px !important;" />
                    <img alt="" *ngIf="channel.type == 'Internal'"
                      src="assets/img/svgicons/channels/{{channel.channel_img}}" style="height: 60px !important;" />
                  </span>
                </label>
              </div>

              <!-- Graph Tooltip -->
              <div id="stats_box" [ngStyle]="{ left: stats_box.x + 'px' }" *ngIf="showTool">
                <div class="line-card" [ngStyle]="{ 'top' : stats_box.y < 0 ? 0 + 'px': stats_box.y + 'px' }">
                  <div class="card-wrapper inside-card text-left"
                    [ngClass]="[pdfgen == true ? 'inside-card2' : 'inside-card']"
                    [ngStyle]="{'border-color' : border_colors[toolColor]}">
                    <img *ngIf="stats_box_data.image != '' && stats_box_data.type == 'External'" class="card-img"
                      src="assets/img/svgicons/otas/{{stats_box_data.image}}" style="margin: -10px 0px" />
                    <img *ngIf="stats_box_data.image != '' && stats_box_data.type == 'Internal'" class="card-img"
                      src="assets/img/svgicons/channels/{{stats_box_data.image}}" style="margin: -10px 0px" />
                    <p class="mb-0" *ngIf="stats_box_data.image == ''">
                      <strong>{{"reports.no_path_selected" | translate}}</strong>
                    </p>
                    <hr [ngStyle]="{'border-color': border_colors[toolColor]}" />
                    <div class="left-side pull-left text-left">
                      <div *ngIf="current_metric == 'checkins' || current_metric == ''" class="checkin-box">
                        <p class="no-margin checkIns">{{ "reports.occupancy" | translate }}</p>
                        <img class="pull-left" src="assets/img/reports/checkin.png" />
                        <span class="checkIns-value">{{ stats_box_data.checkins}}</span>
                      </div>
                      <div *ngIf="current_metric == 'rev_par'" class="checkin-box">
                        <p class="no-margin checkIns">{{ "reports.revpar" | translate }}</p>
                        <img class="pull-left" src="assets/img/reports/checkin.png" />
                        <span class="checkIns-value">{{stats_box_data.rev_par}}</span>
                      </div>
                      <div *ngIf="current_metric == 'avg_daily_rate'" class="checkin-box">
                        <p class="no-margin checkIns"> {{ "reports.adr" | translate }}</p>
                        <img class="pull-left" src="assets/img/reports/checkin.png" />
                        <span class="checkIns-value">{{stats_box_data.avg_daily_rate}}</span>
                      </div>
                      <div *ngIf="current_metric == 'arr'" class="checkin-box">
                        <p class="no-margin checkIns">{{ "reports.arr" | translate }}</p>
                        <img class="pull-left" src="assets/img/reports/checkin.png" />
                        <span class="checkIns-value">{{stats_box_data.avrr}}</span>
                      </div>
                    </div>
                    <div class="right-side pull-right text-right"
                      style="top: 40%; position: absolute; right: 4%;color: black;">
                      <div class="date">
                        {{ stats_box_data.date.format("Do, MMMM") }}
                      </div>
                      <div class="day">
                        {{ stats_box_data.date.format("dddd") }}
                      </div>
                      <div class="price">
                        {{ current_currency }} {{ stats_box_data.revenue}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Graph Tooltip END-->

              <!-- <div class="box-shade">&nbsp;</div> -->
              <!--Line Graph -->
              <svg viewBox="0 0 1540 400" id="mousemove" (mousemove)="move_box($event)">
                <defs>
                  <pattern id="pattern-2" viewBox="-10 13 181 180" patternUnits="userSpaceOnUse" width="100"
                    height="100">
                    <!-- vertical lines -->
                    <rect x="86.5" y="71.3" width="6.9" height="180.6" style="fill: gray;"
                      transform="matrix(1, 0, 0, 1, -9.2, -57.9)" />

                    <!-- horizontal lines -->
                    <rect x="86.5" y="80" width="6.9" height="180.6" style="fill: gray; stroke-width: 1;"
                      transform="matrix(0, 1, -1, 0, 242, 13)" />
                  </pattern>

                  <pattern id="pattern-3"
                    attr.patternTransform="matrix({{1500 / choosen_range.length / 100}}, 0, 0, 0.36, 24.5, 0)"
                    xlink:href="#pattern-2" />

                  <pattern id="square_grid" x="0" y="0" attr.width="{{ 1500 / choosen_range.length }}" height="36.4"
                    patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="100%" height="100%" style="stroke: gray; fill: transparent;" />
                  </pattern>

                  <clipPath id="grid_space">
                    <rect x="0" y="0" width="1500" height="378" fill="transprent" />
                  </clipPath>
                </defs>

                <rect #grey_background id="grey_background" width="1560" height="400" style="fill: #f3f3f3;" />
                <rect #grid_background id="grid_background" x="0" y="0" width="1500" height="380"
                  style="fill: url(#square_grid); fill-opacity: 0.5;" transform="translate(0, 380) scale(1,-1)" />

                <g class="graph_lines" style="clip-path: url(#grid_space);">
                  <path attr.id="path{{ channel.channel_id }}"
                    *ngFor="let channel of graph_arr.slice(0, 5); let i = index"
                    (click)="switch_paths(channel.channel_id); toolColor = i" class="graph_line active"
                    [attr.d]="channel.points" stroke-linejoin="round"
                    [attr.fill]="stats_box_data.id == channel.channel_id ? border_colors[i]: 'transparent'"
                    [ngStyle]="{ stroke: stats_box_data.id == channel.channel_id ? 'black' : border_colors[i] }"
                    (mouseenter)="stats_box_data.id == channel.channel_id ? showTool = true : showTool = false"
                    (mouseleave)="showTool = false" />
                  <use id="use" attr.xlink:href="#path{{ graph_line_switch }}" height="90%" />
                </g>

                <g class="y-axis" transform="translate(0,20)">
                  <text *ngFor="let per of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [attr.y]="36 * per" x="1520">
                    {{ (10 - per) * 10 }}%
                  </text>
                </g>
              </svg>
              <!--Line Graph End-->
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div *ngIf="pagination.length > 1 && selectedChart != 1" class="pagination-box text-center"
        [ngClass]="darkMode ? 'darkPagi' : 'null'">
        <div class="col-md-12 display-flex" style="    margin-top: 4px;">
          <div class="page-link" aria-label="Previous" style="margin-right: 5px; display: inline-block;"
            class="no-user">
            <i (click)="change_page('first')" class="fas fa-angle-double-left"></i>
            <i (click)="change_page('backward')" class="fas fa-angle-left" id="prev"></i>
          </div>

          <ul class="div-content">
            <li *ngFor="let page of pagination" id="paginate" class="pagination no-user paginate"
              [ngClass]="{ active: page == current_page }" (click)="change_page(page)">
              {{ page }}
            </li>
          </ul>
          <div class="page-link" aria-label="Next" style="margin-left: 5px; display: inline-block;" class="no-user">
            <i (click)="change_page('forward')" class="fas fa-angle-right" id="next"></i>
            <i (click)="change_page('last')" class="fas fa-angle-double-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!-- ByChannels -->
      <div class="wrapper center-block" *ngIf="dropCH == 'By Channel'">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <!-- Panel One -->
          <div class="row ">
            <div class="col-md-6 pr-5 drag_box">
              <div *ngFor="let compare of sortCompareArr(compare_array); let i = index" class="drag_item"
                [ngClass]="darkMode ? 'card-dark00' : 'null'">
                <div *ngIf="i % 2 == 0" class="channel-box panel panel-default shadow hovv"
                  [ngClass]="[pdfgen == true ? 'shadow-none' : 'shadow']">
                  <div class="padding-left-none padding-right-2">
                    <div class="panel-heading headingOne0" role="tab" id="headingOne">
                      <h4 class="panel-title" id="grab0">
                        <img *ngIf="compare?.channel_type == 'External'"
                          src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + compare?.image : compare?.image}}"
                          alt="" class="no-user" style="height: 40px;" />
                        <img *ngIf="compare?.channel_type == 'Internal'"
                          src="assets/img/svgicons/channels/{{compare?.image}}" alt="" class="no-user"
                          style="height: 40px;" />
                        <span class="results-tag no-user" style="padding-right: 0px;margin-top: 8px">
                          <img src="assets/img/svgicons/generalicons/pin.svg" (click)="pinIt(compare)"
                            class="pin-img btn-hov">
                          {{ "reports.showing_results_from" | translate }}
                          <span class="date">{{this.choosen_date.startDate.format("Do MMM")}}</span>
                          {{ "reports.to" | translate }}
                          <span class="date">{{this.choosen_date.endDate.format("Do MMM")}}</span>
                          <a role="button" data-toggle="collapse" href="#collapse{{ i }}" aria-expanded="false"
                            aria-controls="collapseOne" (click)="dropDown = i" #pkg_label>
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              *ngIf="!pkg_label.classList.contains('collapsed')" class="mR10">
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              *ngIf="pkg_label.classList.contains('collapsed')" class="mR10"
                              style="transform: rotate(180deg);">
                          </a>
                        </span>
                      </h4>
                    </div>
                    <div id="collapse{{ i }}" class="panel-collapse padding-form collapse in" role="tabpanel"
                      aria-labelledby="headingOne">
                      <div class="panel-body" [ngClass]="darkMode ? 'darkYeloo' : 'null'">
                        <div class="col-md-12 padding-none">
                          <div class="col-md-6 custom-padding">
                            <div class="dash_window">
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.occupancy_rate" | translate }}
                                  </h4>
                                  <span class="numbers">{{compare.occupany_rate + "%"}}</span>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress occupany-rate"
                                    [ngStyle]="{width: compare.per_occupany_rate}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.total_night_stayed" | translate}}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.total_nights}} Nights</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress total-night-stayed"
                                    [ngStyle]="{width: compare.per_total_nights}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.avg_night_stayed-per_room" | translate}}
                                  </h4>
                                  <div class="two-values">
                                    <span class="numbers">{{compare.avg_nights_per_room + " Nights"}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress night-stayed-per-room"
                                    [ngStyle]="{width: compare.avg_nights_per_room+'%'}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.avg_daily_rate_adr" | translate}}
                                  </h4>
                                  <span class="numbers"> {{current_currency}} {{compare.avg_daily_rate}}</span>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress avg-daily-rate"
                                    [ngStyle]="{width: compare.per_avg_daily_rate}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.revenue_per_room" | translate }}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.rev_par }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress revenue-per-room"
                                    [ngStyle]="{ width: compare.per_rev_par }"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.avg_lead_time" | translate }}
                                  </h4>
                                  <div class="two-values">
                                    <span class="numbers">{{compare.avg_lead}} Days</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress avg-lead-time"
                                    [ngStyle]="{ width: compare.per_avg_lead }"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.channel_commission" | translate}}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.channel_commission_value
                                      }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress channel-commision"
                                    [ngStyle]="{width: compare.per_channel_commission}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.revenue_earned_in" | translate}}
                                    {{ choosen_range.length }}
                                    {{ "reports.days" | translate }}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.net_revenue +
                                      compare.channel_commission_value}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress revenue-earned-in"
                                    [ngStyle]="{width: compare.per_revenue_earned}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">{{ "reports.net_revenue" | translate }}</h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.net_revenue }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress net-revenue"
                                    [ngStyle]="{width: compare.per_net_revenue}"
                                    [ngClass]="darkMode ? 'net-revenue_dark' : 'null'"></div>
                                </div>
                              </div>


                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">{{ "reports.Cancellation" | translate }}</h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.cancellation}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress cancellation"
                                    [ngStyle]="{width: compare.cancellation+'%'}"></div>
                                </div>
                              </div>

                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">Sold </h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.reservation}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress reservation"
                                    [ngStyle]="{width: compare.reservation+'%'}"></div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 pr-0 pl-10" style="padding-top: 5%;">
                            <svg width="100%" height="260px" viewBox="0 0 42 42" class="donut">
                              <text *ngIf="restriction == false" x="50%" y="53%"
                                style="font-size: 6px; font-weight: bold;" dominant-baseline="middle"
                                text-anchor="middle" [attr.fill]="compare.color">
                                {{ compare.rev_percentage | number: "1.0-0" }}%
                              </text>
                              <text *ngIf="restriction == true" x="50%" y="53%"
                                style="font-size: 6px; font-weight: bold;" dominant-baseline="middle"
                                text-anchor="middle" [attr.fill]="compare.color">
                                {{current_currency}} {{compare.total }}
                              </text>
                              <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                stroke="#f3f3f3" stroke-width="7"></circle>
                              <circle *ngFor="let circle of donutSet(compare_array)" attr.class="donut-segment{{ i }}"
                                [attr.data-per]="circle.rev_percentage" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" [attr.stroke]="circle.color" stroke-width="7"
                                stroke-dasharray="40 60" stroke-dashoffset="25"></circle>
                            </svg>

                            <ul class="nav text-center">
                              <li *ngFor="let small of compare_array | slice: 0:8; let idx = index">
                                <div class="lined">
                                  <div class="square-booking" [ngStyle]="{ background: small.color }"></div>
                                  <img src="assets/img/svgicons/smallicons/{{small.image}}" class="legends" alt="" />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 pl-5 drag_box">
              <div *ngFor="let compare of sortCompareArr(compare_array); let i = index" class="drag_item">
                <div *ngIf="i % 2 != 0" class="channel-box panel panel-default shadow hovv"
                  [ngClass]="[pdfgen == true ? 'shadow-none' : 'shadow']">
                  <div class="padding-left-none padding-right-2">
                    <div class="panel-heading headingOne0" role="tab" id="headingOne">
                      <h4 class="panel-title" id="grab0">
                        <img *ngIf="compare?.channel_type == 'External'"
                          src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + compare?.image : compare?.image}}"
                          alt="" class="no-user" style="height: 40px;" />
                        <img *ngIf="compare?.channel_type == 'Internal'"
                          src="assets/img/svgicons/channels/{{compare?.image}}" alt="" class="no-user"
                          style="height: 40px;" />
                        <span class="results-tag no-user" style="padding-right: 0px; margin-top: 8px;">
                          <img src="assets/img/svgicons/generalicons/pin.svg" (click)="pinIt(compare)"
                            class="pin-img btn-hov" />
                          {{ "reports.showing_results_from" | translate }}
                          <span class="date">{{this.choosen_date.startDate.format("Do MMM")}}</span>
                          to
                          <span class="date">{{this.choosen_date.endDate.format("Do MMM")}}</span>

                          <a role="button" data-toggle="collapse" href="#collapse{{ i }}" aria-expanded="false"
                            aria-controls="collapseOne" #pkg_label>
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="mR10" *ngIf="!pkg_label.classList.contains('collapsed')">
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              *ngIf="pkg_label.classList.contains('collapsed')" class="mR10"
                              style="transform: rotate(180deg);">
                          </a>
                        </span>

                      </h4>
                    </div>
                    <div id="collapse{{ i }}" class="panel-collapse padding-form collapse in" role="tabpanel"
                      aria-labelledby="headingOne">
                      <div class="panel-body" [ngClass]="darkMode ? 'darkYeloo' : 'null'">
                        <div class="col-md-12 padding-none">
                          <div class="col-md-6 custom-padding">
                            <div class="dash_window">
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.occupancy_rate" | translate }}
                                  </h4>
                                  <span class="numbers">{{compare.occupany_rate + "%"}}</span>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress occupany-rate"
                                    [ngStyle]="{width: compare.per_occupany_rate}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.total_night_stayed" | translate}}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.total_nights}} Nights</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress total-night-stayed"
                                    [ngStyle]="{width: compare.per_total_nights}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.avg_night_stayed-per_room" | translate}}
                                  </h4>
                                  <div class="two-values">
                                    <span class="numbers">{{compare.avg_nights_per_room + " Nights"}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress night-stayed-per-room"
                                    [ngStyle]="{width: compare.per_avg_nights_per_room}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.avg_daily_rate_adr" | translate}}
                                  </h4>
                                  <span class="numbers">{{current_currency}} {{compare.avg_daily_rate }}</span>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress avg-daily-rate"
                                    [ngStyle]="{width: compare.per_avg_daily_rate}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.revenue_per_room" | translate }}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.rev_par }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress revenue-per-room"
                                    [ngStyle]="{ width: compare.per_rev_par }"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.avg_lead_time" | translate }}
                                  </h4>
                                  <div class="two-values">
                                    <span class="numbers">{{compare.avg_lead}} Days</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress avg-lead-time"
                                    [ngStyle]="{ width: compare.per_avg_lead }"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.channel_commission" | translate}}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers"> {{current_currency}} {{compare.channel_commission_value
                                      }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress channel-commision"
                                    [ngStyle]="{width: compare.per_channel_commission}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{"reports.revenue_earned_in" | translate }}
                                    {{ choosen_range.length }} {{ 'reports.days' | translate }}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers">{{current_currency}} {{compare.net_revenue +
                                      compare.channel_commission_value }}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress revenue-earned-in"
                                    [ngStyle]="{width: compare.per_revenue_earned}"></div>
                                </div>
                              </div>
                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">
                                    {{ "reports.net_revenue" | translate }}
                                  </h4>
                                  <div class="values">
                                    <span class="numbers"> {{current_currency}} {{compare.net_revenue}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress net-revenue"
                                    [ngStyle]="{width: compare.per_net_revenue}"
                                    [ngClass]="darkMode ? 'net-revenue_dark' : 'null'"></div>
                                </div>
                              </div>

                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">Cancellation</h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.cancellation}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress cancellation"
                                    [ngStyle]="{width: compare.cancellation+'%'}"></div>
                                </div>
                              </div>

                              <div class="comp_revenue">
                                <div class="flex">
                                  <h4 class="channel_name">Sold</h4>
                                  <div class="values">
                                    <span class="numbers">{{compare.reservation}}</span>
                                  </div>
                                </div>
                                <div class="channel_bar_bg">
                                  <div class="channel_bar_progress reservation"
                                    [ngStyle]="{width: compare.reservation+'%'}"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 pr-0 pl-10" style="padding-top: 5%;">
                            <svg width="100%" height="260px" viewBox="0 0 42 42" class="donut">
                              <text *ngIf="restriction == false" x="50%" y="53%"
                                style="font-size: 6px; font-weight: bold;" dominant-baseline="middle"
                                text-anchor="middle" [attr.fill]="compare.color">
                                {{ compare.rev_percentage | number: "1.0-0" }}%
                              </text>
                              <text *ngIf="restriction == true" x="50%" y="53%"
                                style="font-size: 6px; font-weight: bold;" dominant-baseline="middle"
                                text-anchor="middle" [attr.fill]="compare.color">
                                {{current_currency}} {{compare.total}}
                              </text>
                              <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                stroke="#f3f3f3" stroke-width="7"></circle>
                              <circle *ngFor="let circle of donutSet(compare_array)" attr.class="donut-segment{{ i }}"
                                [attr.data-per]="circle.rev_percentage" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" [attr.stroke]="circle.color" stroke-width="7"
                                stroke-dasharray="40 60" stroke-dashoffset="25"></circle>
                            </svg>

                            <ul class="nav text-center">
                              <li *ngFor="let small of compare_array | slice: 0:8; let idx = index">
                                <div class="lined">
                                  <div class="square-booking" [ngStyle]="{ background: small.color }"></div>
                                  <img src="assets/img/svgicons/smallicons/{{small.image}}" class="legends" alt="" />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <strong>
                <h3 *ngIf="!page_arr[current_page - 1]">{{ "reports.no_bookings_found" | translate }}</h3>
              </strong>
            </div>
          </div>
        </div>
      </div>
      <!-- ByRooms -->
      <div class="wrapper center-block" *ngIf="dropCH == 'By Room'">

        <div class="filter-box" style="margin-bottom: 5px;">

          <div class="row">
            <div class="col-md-2" style="line-height: 40px;font-size: 16px;font-weight: bold;">
              Room Rate by Channel
            </div>

            <div class="col-md-2 filters_col" style="width: 410px;">
              <img src="assets/img/newstaticicons/report/date.svg" alt="" class="placeholder-img" />
              <div class="input form-control" style="background: linear-gradient(#fff, lightgrey);">
                <input type="hidden" name="rdate_to" [(ngModel)]="rdate_to">
                <input type="hidden" name="rdate_from" [(ngModel)]="rdate_from">
                <input type="text" ngxDaterangepickerMd class="form-control date_range_picker arrival-lenght"
                  [(ngModel)]="room_choosen_date" placeholder="{{ 'reports.date' | translate }}"
                  [singleDatePicker]="true" [autoApply]="true" [dateLimit]="1"
                  (change)="room_date_change(room_choosen_date)" [locale]="{format: 'DD MMMM', separator: '  To  '}"
                  [ngModelOptions]="{standalone: true}"
                  style="background: linear-gradient(rgb(255 255 255), rgb(211 211 211)); color: #555555;margin-top: -1px;">
              </div>
            </div>

            <!-- <div class="col-md-4 CTom" [ngClass]="darkMode ? 'dark-btn' : 'null'">
              <a class="btn btn-primary btn-md btn-hov" (click)="reset_filter()">
                {{ "reports.reset" | translate }}
              </a>
            </div> -->

          </div>
        </div>

        <!-- Panel Two -->
        <div class="row">
          <div class="col-md-6 pr-5 drag_box" *ngFor="let rr of roomWiseData; let i = index;">
            <div class="drag_item" [ngClass]="darkMode ? 'card-dark00' : 'null'">
              <div class="channel-box panel panel-default shadow hovv"
                [ngClass]="[pdfgen == true ? 'shadow-none' : 'shadow']">
                <div class="padding-left-none padding-right-2">


                  <div class="panel-heading headingOne0" id="headingOne" role="tab">
                    <h4 class="panel-title" id="grab09" style="display: flex;justify-content: space-between;">
                      <!-- <img  alt="" class="no-user ng-star-inserted" style="height: 40px;" src="assets/img/svgicons/channels/walk-in.svg"> -->
                      <div [ngClass]="darkMode ? 'room-name' : 'byroomName'">{{rr?.room_type_name}}</div>
                      <span class="results-tag no-user" style="padding-right: 0px;margin-top: 8px">
                        <!-- <img class="pin-img btn-hov" src="assets/img/svgicons/generalicons/pin.svg"> -->
                        Showing Results From
                        <span class="date">{{room_choosen_date.startDate.format("Do MMM")}}</span>
                        <a aria-controls="collapseOne" aria-expanded="false" data-toggle="collapse" role="button"
                          href="#collapse{{ i }}">
                          <img class="mR10 ng-star-inserted" src="assets/img/reports/button_down.svg">
                        </a>
                      </span>
                    </h4>
                  </div>

                  <div id="collapse{{ i }}" class="panel-collapse padding-form collapse in" role="tabpanel"
                    aria-labelledby="headingSubZero">
                    <div class="panel-body" [ngClass]="darkMode ? 'darkYeloo' : 'null'" style="border-top: 1px solid ;">
                      <div class="col-md-12 padding-none">
                        <div class="col-md-6 custom-padding">
                          <div class="dash_window">
                            <div class="comp_revenue" *ngFor="let od of rr.otas; let ci = index;">
                              <div class="flex">
                                <h4 class="channel_name">
                                  {{od.name}}
                                </h4>
                                <span class="numbers">{{ od.per+'%'}}</span>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress occupany-rate"
                                  [ngStyle]="{width: od.per+'%', 'background': roomColors[ci]}"></div>
                              </div>
                            </div>

                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">
                                  LOS
                                </h4>
                                <div class="values">
                                  <span class="numbers">{{rr?.los}}</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress total-night-stayed" [ngStyle]="{width: '20%'}"></div>
                              </div>
                            </div>
                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">
                                  {{"reports.avg_night_stayed-per_room" | translate}}
                                </h4>
                                <div class="two-values">
                                  <span class="numbers">{{rr?.avg_night_stay}}</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress night-stayed-per-room" [ngStyle]="{width: '20%'}">
                                </div>
                              </div>
                            </div>
                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">
                                  {{"reports.avg_daily_rate_adr" | translate}}
                                </h4>
                                <span class="numbers">{{rr?.avg_daily_rate}}</span>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress avg-daily-rate" [ngStyle]="{width: '20%'}"></div>
                              </div>
                            </div>
                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">
                                  {{ "reports.revenue_per_room" | translate }}
                                </h4>
                                <div class="values">
                                  <span class="numbers">{{rr?.rev_par}}</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress revenue-per-room"
                                  [ngStyle]="{ width:  rr?.rev_par_percentage }"></div>
                              </div>
                            </div>
                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">
                                  {{ "reports.avg_lead_time" | translate }}
                                </h4>
                                <div class="two-values">
                                  <span class="numbers">{{rr?.avg_lead}} Days</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress avg-lead-time" [ngStyle]="{ width: '20%' }"></div>
                              </div>
                            </div>
                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">Cancellation</h4>
                                <div class="values">
                                  <span class="numbers">{{rr?.cancellation}}</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress cancellation" [ngStyle]="{width: '20%'}"></div>
                              </div>
                            </div>

                            <div class="comp_revenue">
                              <div class="flex">
                                <h4 class="channel_name">Reservation</h4>
                                <div class="values">
                                  <span class="numbers">{{rr?.sold}}</span>
                                </div>
                              </div>
                              <div class="channel_bar_bg">
                                <div class="channel_bar_progress reservation" [ngStyle]="{width: '20%'}"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 pr-0 pl-10" style="padding-top: 5%;">
                          <!-- <canvas id="roomChart{{i}}" class="chartjs-render-monitor overridecanvas2"></canvas> -->
                          <svg width="100%" height="260px" viewBox="0 0 42 42" class="donut">
                            <text x="50%" y="53%" style="font-size: 6px; font-weight: bold;" dominant-baseline="middle"
                              text-anchor="middle">
                              60%
                            </text>
                            <circle *ngFor="let circle of donutSet(roomWiseData)" attr.class="donut-segment{{ i }}"
                              [attr.data-per]="circle?.rev_par_percentage" class="donut-ring" cx="21" cy="21"
                              r="15.91549430918954" fill="transparent" stroke="#f3f3f3" stroke-width="7"></circle>
                            <!-- <circle *ngFor="let circle of donutSet(roomWiseData)" attr.class="donut-segment{{ i }}" [attr.data-per]="circle?.rev_par_percentage" cx="21" cy="21" r="15.91549430918954" fill="transparent" [attr.stroke]="circle.color" stroke-width="7" stroke-dasharray="40 60" stroke-dashoffset="25"></circle> -->
                          </svg>

                          <ul class="nav text-center">
                            <li *ngFor="let small of getOTAs(rr.otas) | slice: 0:8; let idx = index">
                              <div class="lined">
                                <div class="square-booking" [ngStyle]="{ background: roomColors[idx] }"></div>
                                <img src="assets/img/svgicons/smallicons/{{small.image}}" class="legends" alt="" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>