

<div id="">
    <!-- Backoffice Skeleton start (working on saud 👇) -->
    <div class="no-gutters" *ngIf="skeleton" [ngClass]="darkMode ? 'skeleton-card-dark' :
    'null'">
        <div class="col-md-3 col-sm-6 drag_item" *ngFor="let x of getEmptyElements(12)">
            <div class="jumbotron grey-shade-skeleton skeleton-animation">
                <div class="jumbo-icon">
                    <div class="profile_circle"></div>
                </div>
                <a class="block-btn">
                    <div class="jumbo-text"></div>
                </a>
            </div>
    
        </div>
    </div>
    <!-- Backoffice Skeleton end (working on saud 👇) -->

    <div class="row no-gutters drag_item" [ngClass]="darkMode ? 'card-dark' :
        'null'">
        <div id="drag_box" class="col-md-12">
            <ng-container *ngFor="let card of this.cardLayout; let i= index">
                <ng-container *ngFor="let card of cardLayout;">

                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName ==='My Account' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron red-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4421.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/my-account.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn modal-btn" id="openIt" routerLink="account_settings" (click)="removeClassAccount();
                                assignEventsToShowModal() ; showModal()">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.my_account" | translate
                                        }}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Hotel Setup' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron blue-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4415.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/hotel-setup.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn modal-btn" routerLink="hotel_setup" (click)="showModal()">
                                <div class="jumbo-text" *ngIf="property_typee ==
                                    'Vocational_Rental'">
                                    <!-- <p>{{ "back_office.hotel_setup_main" | translate }}</p> -->
                                    <p>Property Setup</p>
                                </div>
                            </a>
                            <a class="block-btn modal-btn" routerLink="hotel_setup" (click)="showModal()">
                                <div class="jumbo-text" *ngIf="property_typee ==
                                    'hotel_setup'">
                                    <p>{{ "back_office.hotel_setup_main" |
                                        translate }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- <div class="col-md-3 col-sm-6 drag_item">
            <a class="block-btn modal-btn" routerLink="manage_users">
              <div class="jumbotron purple-shade">
                <div class="jumbo-icon">
                  <img src="assets/img/back-office/4416.png" class="img-responsive">
                </div>
                <div class="jumbo-text">
                  <p>{{ 'back_office.hotel_users_main' | translate }}</p>
                </div>
              </div>
            </a>
          </div> -->
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='FAQs' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron maroon-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4417.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/faqs.svg" class="img-responsive hov" />
                            </div>
                            <a class="block-btn" routerLink="/main/online_help">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.online_help" | translate
                                        }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Terms of Use' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron orange-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4418.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/terms-use.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn" routerLink="/main/terms_of_use">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.terms_of_use" | translate
                                        }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Billing' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron brown-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4422.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/billing.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn" routerLink="billing">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.billing_main" | translate
                                        }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Select Hotel' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <!-- <a class="block-btn modal-btn" routerLink="select_hotel" (click)="getHeight()"> -->
                        <div class="jumbotron green-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4420.png" class="img-responsive">   -->
                                <img src="assets/img/newstaticicons/back-office/select-hotel.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn modal-btn" routerLink="select_hotel" (click)="showModal()">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.select_hotel_main" |
                                        translate }}</p>
                                    <!-- <p>Select Property</p> -->
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Contact Us' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="this.checkLayoutChanges()">
                        <div class="jumbotron sea-green-shade">
                            <div class="jumbo-icon">
                                <!-- <img src="assets/img/back-office/4419.png" class="img-responsive" /> -->
                                <img src="assets/img/newstaticicons/back-office/contact-us.svg"
                                    class="img-responsive hov" />
                            </div>
                            <a class="block-btn" routerLink="contact_support">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.customer_support_main" |
                                        translate }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName==='Logs' && card.key===i+1"
                        [attr.data-value]="card.cardName" [attr.data-key]="getCardKey(card.cardName)"
                        (mouseout)="checkLayoutChanges()">
                        <div class="jumbotron darkBlue-shade">
                            <div class="jumbo-icon">
                                <img src="assets/img/back-office/log.png" class="img-responsive hov" />
                            </div>
                            <a class="block-btn" routerLink="logs">
                                <div class="jumbo-text">
                                    <p>{{ "back_office.logs" | translate }}</p>
                                </div>
                            </a>
                        </div>
                    </div>

                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<div id="settings" class="modal fade draggablei" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" [ngClass]="['/app/back_office/select_hotel'].includes(router.url) ?
        'yesMod' :
        ['/app/back_office/account_settings'].includes(router.url) ?
        'AccountYes' : 'null'">
        <div class="modal-content" [ngClass]="darkMode ? 'dark-border-div
            modal-content-dark' : ' null '">
            <div class="modal-body" [ngClass]="['/app/back_office/account_settings'].includes(router.url)
                ? 'accountYes' : 'accountNo'">
                <div class="row flex-override">
                    <div class="width-side popup-nav-padd"
                        *ngIf="!['/app/back_office/select_hotel','/app/back_office/account_settings'].includes(router.url)">
                        <ul class="nav flex-column">
                            <!-- <li class="nav-item li-col-r" routerLinkActive="active">
                                    <a class="nav-link" routerLink="account_settings">
                                    <img src="assets/img/back-office/4499.png">
                                    <span data-toggle="tooltip" data-placement="top" title="{{ 'back_office.settings.account' | translate }} {{ 'back_office.settings.settings' | translate }}" style="text-overflow: ellipsis;width: 106px;white-space: nowrap;overflow: hidden; height:42px;">{{ 'back_office.settings.account' | translate }}<br><strong>{{ 'back_office.settings.settings' | translate }}</strong></span>
                                    </a>
                                </li> -->
                            <li class="nav-item li-col cp" routerLinkActive="active" routerLink="hotel_setup"
                                (click)="getHeight()" (click)="showModal()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4485.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/ht-setup.svg" />
                                    <span class="" [ngClass]="darkMode ?
                                        'hovY' : 'null'">{{
                                        "back_office.settings.hotel" |
                                        translate }}
                                        <br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">{{
                                            "back_office.settings.setup" |
                                            translate }}</strong>
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item li-col-r cp" routerLinkActive="active" routerLink="create_users"
                                (click)="getHeight()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4486.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/crt-user.svg" />
                                    <span data-placement="top" data-toggle="tooltip" title="{{
                                        'back_office.settings.create' |
                                        translate }}
                                        {{'back_office.settings.users' |
                                        translate}}" style="text-overflow:
                                        ellipsis; width: 106px;white-space:
                                        nowrap;overflow: hidden" class="" [ngClass]="darkMode ? 'hovY' :
                                        'null'">
                                        {{ "back_office.settings.create" |
                                        translate}}<br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">{{
                                            "back_office.settings.users" |
                                            translate}}</strong>
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item li-col cp" routerLinkActive="active" routerLink="manage_users"
                                (click)="getHeight()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4487.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/mg-user.svg" />
                                    <span data-placement="top" data-toggle="tooltip" title="{{
                                        'back_office.settings.manage' |
                                        translate }}
                                        {{'back_office.settings.users' |
                                        translate}}" style="text-overflow:
                                        ellipsis;width: 106px;white-space:
                                        nowrap;overflow: hidden;" class="" [ngClass]="darkMode ? 'hovY' :
                                        'null'">
                                        {{ "back_office.settings.manage" |
                                        translate}}<br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">{{"back_office.settings.users"
                                            |
                                            translate}}</strong>
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item li-col-r cp" routerLinkActive="active" routerLink="create_rooms"
                                (click)="getHeight()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4488.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/crt-room.svg" />
                                    <span class="" [ngClass]="darkMode ?
                                        'hovY' : 'null'">{{
                                        "back_office.settings.create" |
                                        translate
                                        }}
                                        <br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">{{"back_office.settings.rooms"
                                            |
                                            translate}}</strong>
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item li-col cp" routerLinkActive="active" routerLink="manage_rooms"
                                (click)="getHeight()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4489.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/mg-room.svg" />
                                    <span class="" [ngClass]="darkMode ?
                                        'hovY' : 'null'">{{
                                        "back_office.settings.manage" |
                                        translate
                                        }}
                                        <br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">{{"back_office.settings.rooms"
                                            | translate
                                            }}</strong>
                                    </span>
                                </a>
                            </li>

                            <li class="nav-item li-col cp" routerLinkActive="active" routerLink="preview-hotel"
                                (click)="getHeight()">
                                <a class="nav-link">
                                    <!-- <img src="assets/img/back-office/4489.png" /> -->
                                    <img src="assets/img/newstaticicons/back-office/mg-room.svg" />
                                    <span class="" [ngClass]="darkMode ?
                                        'hovY' : 'null'">Manage<br />
                                        <strong class="" [ngClass]="darkMode
                                            ? 'hovY' : 'null'">Hotel</strong>
                                    </span>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div [ngClass]="{select_property_wrapper: router.url ==
                        '/app/back_office/select_hotel' || router.url ==
                        '/app/back_office/account_settings', 'wid-100 pl-0
                        pr-10': !['/app/back_office/select_hotel',
                        '/app/back_office/account_settings'].includes(router.url)}" id="col10">
                        <div class="tab-content scrollo">
                            <div class="tab-pane active" id="seasoneModal">
                                <div class="alert alert-info
                                    alert-dismissible show" role="alert" [ngClass]="darkMode ? 'smooth-dark' :
                                    'null'" id="grabingg" style="cursor:
                                    grab;height: 45px;padding-top: 12px;" (mouseover)="dodrag('settings')"
                                    (mouseout)="stopDrag('settings')">
                                    {{ 'header.noti' | translate }}
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        (click)="hideModal()">
                                        <strong id="strong">&#x2716;</strong>
                                    </button>
                                </div>

                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>