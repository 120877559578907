import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router } from "@angular/router";
declare var $: any;
import { ManageRatesPackagesComponent } from "../manage-rates-packages.component";

@Component({
  selector: 'app-link-ota',
  templateUrl: './link-ota.component.html',
  styleUrls: ['./link-ota.component.css']
})
export class LinkOTAComponent implements OnInit {
  channels;
  create_package_form: FormGroup;
  submitted = false;
  base_url;
  bHeight;
  link_to_ota;
  pricing_modell;
  darkMode: boolean = false;
  oBChannel = [];
  pdChannel = [];
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private service: ManageRatesPackagesService,
    private router: Router,
    private rp: ManageRatesPackagesComponent
  ) {
    this.base_url = this.api.base_url;
  }

  ngOnInit() {
    this.get_channels();
    this.modalfooter();
    this.checkDarkMode();
    this.pricing_dataa();
    this.create_package_form = this.fb.group({
      // package_name: ['',Validators.required],
      // date_from: ['',Validators.required],
      // room_id: ['',Validators.pattern("[0-9]{0,10}")],
      // description: ['',Validators.required],
      // breakfast_include:['',Validators.required],
    });
    console.log(this.service.package_creating);
    if (this.service.package_creating !== undefined) {
      this.setChecked(this.service.package_creating);
    }

    $("#SSAASS").mouseup(function(){
      //$(this).after("<p style='color:green;'>Mouse button released.</p>");
      console.log("mouse doupwn")
      $("#grab-13").css({"cursor":"grab"})
    });
    $("#SSAASS").mousedown(function(){
      //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
      console.log("mouse down")
      $("#grab-13").css({"cursor":"grabbing"})
    });

  }

  get f() { return this.create_package_form.controls; }

  get_channels() {
    this.api.post('api/property/property_channels_otas', {property_id: localStorage.getItem('current_property'),"type":"External" }, true).subscribe((res: any) => {
      console.log('channels ', res)
      this.channels = res.body.data.filter(function (e) { return e.type == 'External' && e.connected == "true" });
      this.pdChannel = this.channels.filter((e)=>{return e.pricing_model == 'PerDayPricing'});
      this.oBChannel = this.channels.filter((e)=>{return e.pricing_model != 'PerDayPricing'});
      if (this.service.package_creating !== undefined) {
        this.setChecked(this.service.package_creating);
      }
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  onSubmit() {
    var arr = [];
    var pk_id = this.service.package_creating;
    $('input.channels_checkbox:checkbox:checked').each(function () {
      // this.service.package_creating=1;
      arr.push({ 'channel_id': $(this).val(), 'package_id': pk_id });
    });
    if (arr.length == 0) {
      this.helper.alert_error('Select at least one OTA!');
      return;
    }
    this.api.post('api/ota_package/update_type/External', arr, true).subscribe((res: any) => {
      // console.log(res.body.data);
      this.helper.alert_success('OTAs has been Linked successfully !');
      this.rp.getRatePlan();
      this.service.open_tab('preview_package_open');
      this.service.get_packages();
    }, err => this.error_handling.handle_error(err.status, err.message));

     console.log(arr);
  }
  setChecked(id) {
    var data = { package_id: id };
    this.api.post('api/ota_package/index', data, true).subscribe((res: any) => {
      console.log('check hona chaiye yahan', res.body.data);
      res.body.data.forEach(element => {
        $("#check-" + element.channel_id).prop('checked', true);
      });
      // this.helper.alert_success('OTAs has been Linked successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }


  selectAll($evt){
    console.log('all select')
    if ($evt.target.checked) {
      this.channels.forEach(element => {
        element.is_checked = true;
      });
    }else{
      this.channels.forEach(element => {
        element.is_checked = false;
      });
    }
  }

  modalfooter(){

    setTimeout(()=>{
    let f = document.getElementById("ModalFooterP")
    let h = document.getElementById("SP");

    this.bHeight = h.offsetHeight;

    if(this.bHeight > 700){
      f.classList.add("widthBig");
      f.classList.remove("widthPad");
      h.classList.remove("paddR5");
      h.classList.add("paddR2");
    }
    else if(this.bHeight < 700){
      f.classList.remove("widthBig");
      f.classList.add("widthPad");
      h.classList.add("paddR5");
      h.classList.remove("paddR2");
    }
  },100)

  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }
  pricing_dataa(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/properties_channel/pricing', {property_id:property_id},  true).subscribe((res: any) => {
      this.pricing_modell = res.body.data;
      console.log(res.body.data);

    }, err => this.error_handling.handle_error(err.status));
  }

}
