<div class="jumbotron ">
    <div class="jumbo-icon">
        <li class="front-dest-hold" data-value="Front Desk" title="" text="Main title!" style="list-style-type: none;">
            <div class="dash_window no_margin w-clearfix dashboard-card"
                [ngClass]="darkMode ? 'dash_window_dark':'null'">
                <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'null'" title="">
                    <h4 class="heading">{{ 'rates_and_packages.extra.front_desk' | translate}}
                    </h4>
                    <div class="bar two-c">
                        <ng-select class="form-control custom input_2 padding-holder " placeholder=" Today "
                            [(ngModel)]="graphDaysP" (change)="getDashboardData()" [items]="graphDays" bindValue="name"
                            bindLabel="name"
                            style="height: 30px;padding:0px;padding-left: 0px;padding-right: 0px ; border: none">
                        </ng-select>
                    </div>
                </div>
                <div class="cardFrontDesk" [ngClass]="darkMode ? 'card2_dark':'null'">
                    <div class="row" style="padding: 0px 20px;">
                        <div class="col-md-6 zero-padding pt13 pl10 pr10">
                            <div class="mainLine col-md-12 zero-padding">
                                <div class="ico"> <img
                                        src="{{darkMode ? 'assets/img/dashboard/darkmode/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                                        alt="" width="30px" style="    margin-top: 8px;"> </div>
                                <div class="sumDe avai1" [ngClass]="darkMode ? 'purp2' : 'null'"> <span>{{
                                        'rates_and_packages.extra.available' | translate
                                        }}</span>
                                    <span>{{frontDesk?.today_available}}</span>
                                </div>
                            </div>
                            <div class="mainLine col-md-12 zero-padding">
                                <div class="ico"> <img
                                        src="{{darkMode ? 'assets/img/dashboard/darkmode/sold-dollar-dark.svg':'assets/img/dashboard/sold.svg'}}"
                                        alt="" width="30px" style="margin-top: 7px;"> </div>
                                <div class="sumDe avai2"> <span>{{
                                        'rates_and_packages.extra.sold' | translate}}</span>
                                    <span>{{frontDesk?.sold_today}}</span>
                                </div>
                            </div>
                            <div class="mainLine col-md-12 zero-padding">
                                <div class="ico"> <img src="assets/img/dashboard/in-house2.svg" alt="" width="30px">
                                </div>
                                <div class="sumDe avai3"> <span>{{
                                        'rates_and_packages.extra.in_house' | translate}}</span>
                                    <span>{{frontDesk?.in_house}}</span>
                                </div>
                            </div>

                            <div class="mainLine col-md-12 zero-padding ndBar mt15">
                                <div class="ico"> <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt=""
                                        width="30px" style="margin-left: -10px;"> </div>
                                <div class="sumDe arriv1"> <span>{{
                                        'rates_and_packages.extra.arrival' | translate }}</span>
                                    <span>{{frontDesk?.arrival_today}}</span>
                                </div>
                            </div>
                            <div class="mainLine col-md-12 zero-padding ndBar">
                                <div class="ico"> <img
                                        src="assets/img/{{darkMode ? 'dashboard/darkmode/departure' : 'newstaticicons/dashboard/departure' }}.svg"
                                        alt="" width="20px"> </div>
                                <div class="sumDe arriv2" [ngClass]="darkMode ? 'purp' : 'null'"> <span>{{
                                        'rates_and_packages.extra.departure'| translate}}</span>
                                    <span>{{frontDesk?.departure_today}}</span>
                                </div>
                            </div>
                            <div class="mainLine col-md-12 zero-padding ndBar">
                                <div class="ico"> <img
                                        src="assets/img/{{darkMode ? 'dashboard/darkmode/reservation' : 'newstaticicons/dashboard/reservation'}}.svg"
                                        alt="" width="25px" style="    margin-top: 5px;"> </div>
                                <div class="sumDe arriv3" [ngClass]="darkMode ? 'purp1' : 'null'"> <span>{{
                                        'rates_and_packages.extra.reservation' | translate
                                        }}</span>
                                    <span>{{frontDesk?.reservation_today}}</span>
                                </div>
                            </div>
                            <div class="mainLine col-md-12 zero-padding ndBar">
                                <div class="ico"> <img src="assets/img/dashboard/cancellation.svg" alt="" width="23px"
                                        style="    margin-top: 6px;"> </div>
                                <div class="sumDe arriv4"> <span>{{
                                        'rates_and_packages.extra.cancellation' | translate
                                        }}</span>
                                    <span>{{frontDesk?.cancellation_today}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 zero-padding">
                            <div class="col-md-12 zero-padding">
                                <div class="cardRadius">

                                    <div class="speedbox" *ngIf="frontDesk != undefined">
                                        <div class="speedbox__score" id="speedbox-score"
                                            [ngStyle]="{'transform': 'rotate(' + meterCount(frontDesk.percentage_today) +'deg)'}">
                                        </div>
                                        <div class="speedbox__groove"></div>
                                        <div class="speedbox__odo">
                                            <div class="speedbox__down">
                                                {{frontDesk?.percentage_today}}<span>%</span>
                                            </div>
                                        </div>
                                        <div class="speedbox__base"
                                            [ngStyle]="{'background': darkMode ? '#000213' : '#f8f8f8'}">
                                        </div>
                                    </div>


                                    <!-- <canvas id="myCanvas" width="250" height="150" style="margin-left: 5px;"></canvas>
<div class="rate">{{frontDesk?.percentage_today}}%</div> -->
                                    <div class="occupancy">{{
                                        'rates_and_packages.extra.occupancy' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 zero-padding" style="z-index: 9999;margin-top: -8%;">
                                <div class="cardBarsRedGreen">
                                    <div class="caret_up">
                                        <div class="greenbar">{{frontDesk?.percentage_arrival}}
                                            %</div>
                                        <i class="fa fa-caret-up"></i>
                                    </div>
                                    <div class="caret_up">
                                        <div class="greenbar">
                                            {{frontDesk?.percentage_departure}} %</div>
                                        <i class="fa fa-caret-up"></i>
                                    </div>
                                    <div class="caret_up">
                                        <div class="greenbar">
                                            {{frontDesk?.percentage_reservation}} %</div>
                                        <i class="fa fa-caret-up"></i>
                                    </div>
                                    <div class="caret_up">
                                        <div class="greenbar">{{frontDesk?.percentage_today}} %
                                        </div>
                                        <i class="fa fa-caret-down" style="margin-top: 1px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
</div>