<form
  class="form-horizontal back-form"
  [formGroup]="create_package_form"
  (ngSubmit)="onSubmit()"
>
  <div class="" style="padding-right: 5px" id="SS">
    <div
      class="alert alert-danger breadcrum mb-breadcrum"
      [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '"
    >
      <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">
        {{"rates_and_packages.link_channel.rates_and_packages" | translate}}</a>
      <a routerLink="setup_season" class="btn btn-link active">
        <!-- <span class="abc">{{ 'rates_and_packages.link_channel.setup_rates_and_allocation' | translate }}</span>  -->
        <span class="abc">{{"rates_and_packages.menu.rate_distribution" | translate}} </span>
      </a>
    </div>
<div class="no_scroll scroll">
    <div formArrayName="season_rates"*ngFor="let item of create_package_form.get('season_rates')['controls']; let i = index">
      <div [formGroupName]="i">
        <details [ngClass]="darkMode ? 'dark-details' : 'light-details'">
          <summary>
            <div class="summary-name" [ngStyle]="{ color: name_colors[i] }">
              {{ item.value.display_name || item.value.roomtypeName }}


              <span style="float: right">
                <img
                  style="cursor: pointer; height: 22px; margin-top: -3px"
                  src="assets/img/reports/{{
                    darkMode ? 'button_down_dark' : 'button_down'
                  }}.svg"
                  id="show{{ i }}"
                  class=""
                  (click)="dropchnage('show' + i)"
                />
              </span>

              <span style="float: right">
                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/pets.svg"
                  title="Allowed"
                  *ngIf="item.value.pets === 'true'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-10px 5px -5px 0px', height: '35px' }
                      : { margin: '-10px 5px -5px 0px', height: '20px' }
                  "
                />
                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/nopets.svg"
                  title="Not Allowed"
                  *ngIf="item.value.pets === 'false'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-10px 5px -5px 0px', height: '35px' }
                      : { margin: '-10px 5px -5px 0px', height: '20px' }
                  "
                />
              </span>

              <span style="float: right">
                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/nowheel.svg"
                  title="Not Allowed"
                  *ngIf="item.value.wheelIt === 'false'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-10px 5px -5px 0px', height: '35px' }
                      : { margin: '-10px 5px -5px 0px', height: '20px' }
                  "
                />

                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/wheel.svg"
                  title="Allowed"
                  *ngIf="item.value.wheelIt === 'true'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-10px 5px -5px 0px', height: '35px' }
                      : { margin: '-10px 5px -5px 0px', height: '20px' }
                  "
                />
              </span>

              <span style="float: right">
                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/nosmoke.svg"
                  title="Not Allowed"
                  *ngIf="item.value.smoking === 'false'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-15px 5px -5px 0px', height: '35px' }
                      : { margin: '-15px 5px -5px 0px', height: '20px' }
                  "
                />

                <img
                  src="assets/img/newstaticicons/rates-packages/{{
                    darkMode ? 'darkmode' : 'lightmode'
                  }}/smoke.svg"
                  title="Allowed"
                  *ngIf="item.value.smoking === 'true'"
                  [ngStyle]="
                    darkMode
                      ? { margin: '-15px 5px -5px 0px', height: '35px' }
                      : { margin: '-15px 5px -5px 0px', height: '20px' }
                  "
                />
              </span>

              <!-- <span style="float: right; margin-top: 3px;" *ngIf="this.change_img == true">
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="btn-hov" [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' " style="width: 22px; cursor: pointer; border-radius: 100%;transform: rotate(180deg);margin-top: -4px;" (click)="change_drop()" />
                      </span> -->
            </div>
          </summary>
          <!-- Per day heading -->
          <!-- this.pricing_data) -->
          <div
            class="row"
            *ngIf="pricing_data == 'PerDayPricing' || pricing_data == 'Both'"
          >
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="package-name margin-col-1">
                  {{"rates_and_packages.menu.per_Day_pricing" | translate}}  &nbsp;
                  <i class="fas fa-info-circle">
                    <span
                      class="tooltiptext1"
                      [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                    >
                      <p>
                        {{"rates_and_packages.menu.per_day_pricing_P" | translate}}
                      </p>
                    </span>
                  </i>
                </div>
              </div>
            </div>
          </div>
          <!-- Per day Pricing And Weekday -->

          <div
            class="row"
            *ngIf="pricing_data == 'PerDayPricing' || pricing_data == 'Both'"
          >
            <div class="col-md-12">
              <div class="col-md-12">
                <div
                  class="card cO1"
                  [ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
                >
                  <ng-container>
                    <div class="row" style="padding: 0px 15px">
                      <div class="col-md-6" style="padding: 0px 3px 0px 5px">
                        <div class="border-style color-5 b1">
                          <input
                            type="text"
                            class="input form-control"
                            placeholder="{{
                              'rates_and_packages.link_channel.please_enter_room_rates'
                                | translate
                            }}"
                            style="outline: none"
                            formControlName="week_days_rate"
                            [ngClass]="
                              darkMode ? 'card-dark-side-end' : ' card-null '
                            "
                            value="0"
                          />
                        </div>
                        <div
                          *ngIf="
                            submitted &&
                            f.season_rates.value[i].week_days_rate == ''
                          "
                          class="small text-danger no-margin invalid-feedback"
                        >
                          <strong>{{
                            "rates_and_packages.link_channel.room_rates_are_required"
                              | translate
                          }}</strong>
                        </div>
                      </div>
                      <div class="col-md-6" style="padding: 0px 5px 0px 2px">
                        <div
                          class="border-style color2 b2"
                          style="padding: 2px 0px 0px 0px"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                        >
                          <div
                            class="text-center permission-wrapper fs-9"
                            style="margin-left: 9.5%"
                          >
                            <label
                              class="control-label checkbox-style"
                              *ngFor="
                                let wd of item.value.week_days;
                                let wi = index
                              "
                            >
                              <input
                                type="checkbox"
                                class="checkbox"
                                value="{{ wd.value }}"
                                [disabled]="
                                  create_package_form.value.season_rates[i]
                                    .weekend_days[wi].checked
                                "
                                [checked]="
                                  create_package_form.value.season_rates[i]
                                    .week_days[wi].checked
                                "
                                (click)="addWeekday(wd.value, wi, $event, i)"
                              />
                              <span
                                class="checkbox-checkmark"
                                [ngClass]="
                                  darkMode
                                    ? 'checkbox-checkmark_dark'
                                    : 'checkbox-checkmark'
                                "
                              ></span>
                              <span class="permission-title-2">{{
                                wd.name
                              }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md"  style="padding: 0px;">
                                 <a class="btn btn-primary btn-hov" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"  style="margin-left: 5px;height: 40px;padding: 10px 16px;"  [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                                   <i class="fa fa-plus" ></i>
                                 </a>
                              </div> -->
                    </div>
                  </ng-container>
                  <div class="row mt-10" style="padding: 0px 15px">
                    <div class="col-md-6" style="padding: 0px 3px 0px 5px">
                      <div class="border-style color-5 b3">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.please_enter_room_rates'
                              | translate
                          }}"
                          style="outline: none"
                          formControlName="weekend_days_rate"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                      <div
                        *ngIf="
                          submitted &&
                          f.season_rates.value[i].weekend_days_rate == ''
                        "
                        class="small text-danger no-margin invalid-feedback"
                      >
                        <strong>{{
                          "rates_and_packages.link_channel.room_rates_are_required"
                            | translate
                        }}</strong>
                      </div>
                    </div>
                    <div class="col-md-6" style="padding: 0px 5px 0px 2px">
                      <div
                        class="border-style color2 b4"
                        style="padding: 2px 0px 0px 0px"
                        [ngClass]="
                          darkMode ? 'card-dark-side-end' : ' card-null '
                        "
                      >
                        <div
                          class="text-center permission-wrapper fs-9"
                          style="margin-left: 9.5%"
                        >
                          <label
                            class="control-label checkbox-style"
                            *ngFor="
                              let wed of item.value.weekend_days;
                              let wei = index
                            "
                          >
                            <input
                              type="checkbox"
                              class="checkbox"
                              value="{{ wed.value }}"
                              [disabled]="
                                create_package_form.value.season_rates[i]
                                  .week_days[wei].checked
                              "
                              [checked]="
                                create_package_form.value.season_rates[i]
                                  .weekend_days[wei].checked
                              "
                              (click)="addWeekend(wed.value, wei, $event, i)"
                            />

                            <span
                              class="checkbox-checkmark"
                              [ngClass]="
                                darkMode
                                  ? 'checkbox-checkmark_dark'
                                  : 'checkbox-checkmark'
                              "
                            ></span>
                            <span class="permission-title-2">{{
                              wed.name
                            }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- OBP heading -->
          <div
            class="row"
            *ngIf="pricing_data == 'occupancy' || pricing_data == 'Both'"
          >
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="package-name margin-col-1">
                  {{"rates_and_packages.menu.Occupancy_Base_Price" | translate}}&nbsp;
                  <i class="fas fa-info-circle">
                    <span
                      class="tooltiptext2"
                      [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                    >
                      <p>  {{"rates_and_packages.menu.Occupancy_Base_Price_P" | translate}}
                       </p>
                    </span>
                  </i>
                </div>
              </div>
            </div>
            <div
              class="col-md-12"
              formArrayName="obp"
              *ngFor="let ob of item.get('obp').controls; let j = index"
            >
              <div class="col-md-12" [formGroupName]="j">
                <div
                  class="card cO1"
                  [ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
                >
                  <div class="row" style="padding: 0px 15px">
                    <div class="col-md-6" style="padding: 0px 3px 0px 5px">
                      <div
                        class="border-style color-5"
                        [ngClass]="darkMode ? '' : 'b23'"
                      >
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{'rates_and_packages.menu.occupant' | translate}}"
                          style="outline: none"
                          formControlName="occupant_value"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                      <div
                        *ngIf="
                          submitted &&
                          f.season_rates.value[i].obp[j].occupant_value == ''
                        "
                        class="small text-danger no-margin invalid-feedback"
                      >
                        <strong>
                          {{'rates_and_packages.menu.occupant_P' | translate}}</strong>
                      </div>
                    </div>
                    <div class="col-md-6" style="padding: 0px 5px 0px 2px">
                      <div class="border-style color-5 b23">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder=" {{'rates_and_packages.menu.enterplan' | translate}}"
                          style="outline: none"
                          formControlName="occupant_price"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                      <div
                        *ngIf="
                          submitted &&
                          f.season_rates.value[i].obp[j].occupant_price == ''
                        "
                        class="small text-danger no-margin invalid-feedback">
                        <strong> {{'rates_and_packages.menu.enterplan_P' | translate}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Rate Distribution heading -->
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div
                  class="package-name2 margin-col-1 col-md-4"
                  style="
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    padding-left: 20px;
                  "
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                  {{
                    "rates_and_packages.menu.rate_distribution" | translate
                  }}
                  &nbsp;
                  <i class="fas fa-info-circle">
                    <span
                      class="tooltiptext"
                      [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
>
                      <p>
                        {{
                          "rates_and_packages.link_channel.inthis_section" | translate
                        }}.
                      </p>
                      <p style="margin-bottom: 0px; margin-top: -10px">
                        {{
                          "rates_and_packages.link_channel.you_can_sel"
                            | translate
                        }}.
                      </p>
                    </span>
                  </i>
                </div>
                <div
                  class="package-name2 margin-col-1 col-md-4 text-center"
                  style="border-radius: 0px"
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '"
                >
                  Room Count: {{ item.value.roomCount }}
                </div>
                <div
                  class="package-name2 margin-col-1 col-md-4"
                  style="
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding-right: 20px;
                  "
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '"
                >
                  <div style="float: right">
                    <div style="float: left; margin-right: 30px">
                      <input
                        type="radio"
                        id="{{ 'test1' + i }}"
                        name="radio-group{{ i }}"
                        (click)="selectAllocationPrice(i, '$')"
                        [checked]="item.value.rate_type == '$' ? true : false"
                      />
                      <label for="{{ 'test1' + i }}" id="paid">$</label>
                    </div>
                    <div style="float: left">
                      <input
                        type="radio"
                        id="{{ 'test2' + i }}"
                        name="radio-group{{ i }}"
                        (click)="selectAllocationPrice(i, '%')"
                        [checked]="item.value.rate_type == '%' ? true : false"
                      />
                      <label for="{{ 'test2' + i }}" id="comp">%</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Rate Distribution Rates -->
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div
                  class="card"
                  [ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
                >
                  <!-- Allocation 1st row -->
                  <div class="row" style="padding: 0px 15px">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px">
                      <div class="border-style color-4 bc1">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.start_high'
                              | translate
                          }} "
                          style="outline: none"
                          formControlName="allocation_high_1"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px">
                      <div class="border-style color-4 bc2">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.close_high'
                              | translate
                          }} "
                          style="outline: none"
                          formControlName="allocation_high_2"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px">
                      <div class="border-style color-4 bc3">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder=" {{
                            'rates_and_packages.link_channel.rate' | translate
                          }} (-/+)"
                          style="outline: none"
                          formControlName="allocation_high_rate"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Allocation 2nd row -->
                  <div class="row mt-10" style="padding: 0px 15px">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px">
                      <div class="border-style color-4 bc4">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.start_medium_rate'
                              | translate
                          }} "
                          style="outline: none"
                          formControlName="allocation_medium_1"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px">
                      <div class="border-style color-4 bc5">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.close_medium_rate'
                              | translate
                          }}"
                          style="outline: none"
                          formControlName="allocation_medium_2"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px">
                      <div class="border-style color-4 bc6">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.rate' | translate
                          }} (-/+)"
                          style="outline: none"
                          formControlName="allocation_medium_rate"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                          value="0"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Allocation 3rd row -->
                  <div class="row mt-10" style="padding: 0px 15px">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px">
                      <div class="border-style color-4 bc7">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.start_low_rate'
                              | translate
                          }} "
                          style="outline: none"
                          value="0"
                          formControlName="allocation_low_1"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px">
                      <div class="border-style color-4 bc8">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.close_low_rate'
                              | translate
                          }} "
                          style="outline: none"
                          value="0"
                          formControlName="allocation_low_2"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px">
                      <div class="border-style color-4 bc9">
                        <input
                          type="text"
                          class="input form-control"
                          placeholder="{{
                            'rates_and_packages.link_channel.rate' | translate
                          }} (-/+)"
                          style="outline: none"
                          value="0"
                          formControlName="allocation_low_rate"
                          [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </details>
      </div>
    </div>
</div>
  </div>
  <div
    class="modal-footer text-right"
    id="ModalFooter"
    style="right: 10px !important; bottom: -50px"
  >
    <button
      class="btn btn-info btn-lg"
      [ngClass]="darkMode ? 'card-dark-btn-g hovoo' : ' card-null '"
    >
      {{ "rates_and_packages.setup_package.save" | translate }}
    </button>
    <button
      class="btn btn-danger btn-lg"
      aria-label="Close"
      data-dismiss="modal"
      type="button"
      (click)="onClose()"
      [ngClass]="darkMode ? 'card-dark-top-nav hovoo' : ' card-null '"
    >
      {{ "rates_and_packages.setup_package.cancel" | translate }}
    </button>
  </div>
</form>
