import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router } from "@angular/router";
declare var $: any;
import { ManageRatesPackagesComponent } from "../manage-rates-packages.component";

@Component({
  selector: 'app-setup-restriction',
  templateUrl: './setup-restriction.component.html',
  styleUrls: ['./setup-restriction.component.css']
})
export class SetupRestrictionComponent implements OnInit {
  submitted=false;
  darkMode: boolean = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    public helper: HelperService,
    private error_handling: ErrorHandlingService,
    public service: ManageRatesPackagesService,
    private router: Router,
    private rp: ManageRatesPackagesComponent
  ) { }

  channels;
  bHeight;
  show_channels = [];
  cancelation_channels = [];
  channels2;
  f;
  cancellaction_type;
  setup_restrictions: FormGroup;
  selectedOta:boolean = false;
  ngOnInit() {
    console.log('setup Restriction');
    this.get_channels();
    this.modalfooter();
    this.checkDarkMode();
    this.setup_restrictions = this.fb.group({
      min_stay: ['', Validators.required],
      max_stay: ['', Validators.required],
      minimum_age: ['', Validators.required],
      pets: [null, Validators.required],
      pets_extra_amount: ['', Validators.required],
      cancellation_days: ['', Validators.required],
      cancellaction_type: [null, Validators.required],
      cancellation_value: ['', Validators.required],
      cancellation_channels: ['', Validators.required]
    });


    $("#SSS").mouseup(function(){
      //$(this).after("<p style='color:green;'>Mouse button released.</p>");
      console.log("mouse doupwn")
      $("#grab-8").css({"cursor":"grab"})
    });
    $("#SSS").mousedown(function(){
      //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
      console.log("mouse down")
      $("#grab-8").css({"cursor":"grabbing"})
    });

  }

  dodrag2(){
    $('#settings').draggable( "enable" )
    // $('#SSS').css({ "cursor": "grab" })
  }

  stopDrag2(){
    $('#settings').draggable( "disable" )
    // $('#SSS').css({ "cursor": "grabbing" })
  }


  onSubmit(){
    this.setup_restrictions.patchValue({
      cancelation_channels: this.cancelation_channels.join()
    });
    this.api.post('api/package/update/'+this.service.package_creating, this.setup_restrictions.value,true).subscribe((res:any) => {
      this.helper.alert_success("Restriction has been updated !");
      // this.setup_restrictions.reset()
      this.submitted = false;
      this.rp.getRatePlan();
      this.service.open_tab('link_to_ota_open');
      this.service.get_packages();
    }, err => this.error_handling.handle_error(err.status));
  }

  increment(inp_name){
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    this.setup_restrictions.get(inp_name).setValue((val) + 1);
  }

  decrement(inp_name){
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    if(val >= 1){
      this.setup_restrictions.get(inp_name).setValue((val) - 1);
    }
  }

  get_channels(){
    this.api.post('api/channels/index',  {property_id: localStorage.getItem('current_property')} ,true).subscribe((res:any) => {
      this.channels = res.body.data.filter(function(e){
        return e.type == 'External' && e.connected == true;
      });
      this.channels2 = res.body.data.filter(function(e){
        return e.type == 'Internal'  && e.connected == true;
      });
      this.get_package();
      // console.log(this.channels);
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  setChannel(value, event){
    if (event.target.checked) {
      this.cancelation_channels.push(value);
    }else{
      this.cancelation_channels = this.cancelation_channels.filter(e => {
        return e !== value;
      });
    }
  }

  get_package(){
    if(this.service.package_creating !==undefined){
      this.api.get('api/package/show/'+this.service.package_creating,true).subscribe((res:any) => {
        this.setup_restrictions = this.fb.group({
          min_stay: res.body.data.min_stay,
          max_stay:  res.body.data.max_stay,
          minimum_age:  res.body.data.minimum_age,
          pets:  res.body.data.pets,
          pets_extra_amount:  res.body.data.pets_extra_amount,
          cancellation_days: res.body.data.cancellation_days,
          cancellaction_type:  res.body.data.cancellaction_type,
          cancelation_channels: res.body.data.cancelation_channels,
          cancellation_value: res.body.data.cancellation_value,
        });
        this.show_channels = res.body.data.cancelation_channels.length > 0 ? res.body.data.cancelation_channels.split(',') : [];
        if(this.show_channels){
          this.show_channels.forEach((res) => {
            this.channels.forEach(element => {
              if(element.id == parseInt(res)){
                element.is_checked = true;
              }
            });
            this.channels2.forEach(element => {
              if(element.id == parseInt(res)){
                element.is_checked = true;
              }
            });
            this.cancelation_channels.push(parseInt(res));
          });
        }
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  selectAll($evt){
    if ($evt.target.checked) {
      this.channels.forEach(element => {
        element.is_checked = true;
        this.cancelation_channels.push(element.id);
      });
    }else{
      this.channels.forEach(element => {
        element.is_checked = false;
      });
      this.cancelation_channels = [];
    }
  }

  selectAll2($evt){
    if ($evt.target.checked) {
      this.channels2.forEach(element => {
        element.is_checked = true;
        this.cancelation_channels.push(element.id);
      });
    }else{
      this.channels2.forEach(element => {
        element.is_checked = false;
      });
      this.cancelation_channels = [];
    }
  }

  modalfooter(){

    setTimeout(()=>{
    let f = document.getElementById("ModalFooterP")
    let h = document.getElementById("SP");
    let P = document.getElementById("SSS");

    this.bHeight = h.offsetHeight;

    if(this.bHeight > 700){
      f.classList.add("widthBig");
      f.classList.remove("widthPad");
      P.classList.remove("paddR5");
      P.classList.add("paddR2");
    }
    else if(this.bHeight < 700){
      f.classList.remove("widthBig");
      f.classList.add("widthPad");
      P.classList.add("paddR5");
      P.classList.remove("paddR2");
    }
  },100)

  }

  imgr(name){
    let a = name.split('.');
    return a[0];
  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }


}
