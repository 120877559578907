import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-linked-ota',
  templateUrl: './linked-ota.component.html',
  styleUrls: ['./linked-ota.component.scss']
})
export class LinkedOTAComponent implements OnInit {

  @Input('external_channels') external_channels: any;
  @Output() otaSettingModal = new EventEmitter<{ id: any, connected: any }>();
  darkMode: boolean = false;

  constructor(public helper: HelperService) {
    console.log(this.external_channels, "external_channels");
  }

  ngOnInit() {
    this.checkDarkMode();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  ota_setting_modal(id, connected) {
    this.otaSettingModal.emit({ id: id, connected: connected });
  }

  mat_change($event, status, id) {

  }
}
