<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '" style="    height: 47px;">
    <a class="btn btn-link" routerLink="/app/back_office">{{ 'back_office.billing.invoice.back_office' | translate }}</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">{{ 'back_office.billing.invoice.billing' | translate }}</a>
    <a class="btn btn-link active">{{ 'back_office.billing.invoice.invoices' | translate }}</a>
</div>

<!-- <div class="alert alert-info">
    <ul class="nav nav-inline">
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.invoice_id' | translate }}</a></li>
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.subscription_plan' | translate}}</a></li>
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.amount_due'| translate }}</a></li>
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.due_date' | translate}}</a></li>
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.status' | translate}}</a></li>
        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.payment'| translate }}</a></li>

    </ul>
</div> -->
<div id="drag-list">
    <div class="card" style="padding: 5px;cursor: default"  [ngClass]="darkMode ? 'card-dark' : 'card'">

            <div class="alert alert-info" style="cursor: default">
                    <ul class="nav nav-inline">
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.invoice_id' | translate }}</a></li>
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.subscription_plan' | translate}}</a></li>
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.amount_due'| translate }}</a></li>
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.due_date' | translate}}</a></li>
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.status' | translate}}</a></li>
                        <li class="li-width"><a href="">{{ 'back_office.billing.invoice.payment'| translate }}</a></li>

                    </ul>
                </div>
<div class="no_scroll scroll">
  <div class="" id="drag">

    <div class="row field-space">
        <div class="col-md-12">
            <div class="border-style border-color-green" data-toggle="modal" data-target="#summary">
                <div class="element dark-sec">
                    <ul class="nav nav-inline">
                        <li class="li-invoice greytxt">3215655</li>
                        <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.basic' | translate}}</li>
                        <li class="li-amount greytxt">$ 175.99</li>
                        <li class="li-date">14 Aug 2019</li>
                        <li class="li-payment-status"> <button class="btn btn-status btn-paid hov">{{ 'back_office.billing.invoice.paid' | translate}}</button>
                            <li class="paid-by">John Doe</li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 action-btns">
        </div>
    </div>

    <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style border-color-red">
                    <div class="element  dark-sec">
                        <ul class="nav nav-inline">
                            <li class="li-invoice greytxt">3215687</li>
                            <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.premium' | translate}}</li>
                            <li class="li-amount greytxt">$ 200.99</li>
                            <li class="li-date">07 Dec 2018</li>
                            <li class="li-payment-status"> <button class="btn btn-status btn-pending btn-adj hov can">{{ 'back_office.billing.invoice.cancelled'| translate }}</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj opacityM hov">{{ 'back_office.billing.invoice.pay_now'| translate }}</button>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
            </div>
    </div>

    <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style border-color-green">
                    <div class="element dark-sec">
                        <ul class="nav nav-inline">
                            <li class="li-invoice greytxt">3215621</li>
                            <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.standard' | translate}}</li>
                            <li class="li-amount greytxt">$ 120.31</li>
                            <li class="li-date">13 Feb 2019</li>
                            <li class="li-payment-status"> <button class="btn btn-status btn-paid hov">{{ 'back_office.billing.invoice.paid' | translate}}</button>
                                <li class="paid-by">Mr Clark</li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
            </div>
    </div>

    <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style border-color-orange">
                    <div class="element dark-sec">
                        <ul class="nav nav-inline">
                            <li class="li-invoice greytxt">3215649</li>
                            <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.premium' | translate }}</li>
                            <li class="li-amount greytxt">$ 149.99</li>
                            <li class="li-date">07 Aug 2019</li>
                            <li class="li-payment-status"> <button class="btn btn-status btn-danger btn-adj hov pan">{{ 'back_office.billing.invoice.pending'| translate }}</button>
                            <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj opacityM hov">{{ 'back_office.billing.invoice.pay_now'| translate }}</button>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
            </div>
    </div>

    <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style border-color-red">
                    <div class="element dark-sec">
                        <ul class="nav nav-inline">
                            <li class="li-invoice greytxt">3215687</li>
                            <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.premium' | translate }}</li>
                            <li class="li-amount greytxt">$ 200.99</li>
                            <li class="li-date">07 Dec 2018</li>
                            <li class="li-payment-status"> <button class="btn btn-status btn-pending btn-adj hov can">{{ 'back_office.billing.invoice.cancelled' | translate}}</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj opacityM hov">{{ 'back_office.billing.invoice.pay_now' | translate}}</button>
                            <!-- <li style="width: 11.5%; padding-left: 40px;">
                                <img src="assets/img/invoices/download.png" alt="">
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
            </div>
    </div>

    <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style border-color-orange">
                    <div class="element dark-sec">
                        <ul class="nav nav-inline">
                            <li class="li-invoice greytxt">3215655</li>
                            <li class="li-subscription greytxt">{{ 'back_office.billing.invoice.basic' | translate}}</li>
                            <li class="li-amount greytxt">$ 143.99</li>
                            <li class="li-date">14 Aug 2019</li>
                            <li class="li-payment-status"> <button class="btn btn-status btn-danger hov pan">{{ 'back_office.billing.invoice.pending' | translate}}</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj opacityM hov">{{ 'back_office.billing.invoice.pay_now' | translate}}</button>
                            <!-- <li style="width: 11.5%; padding-left: 40px;">
                                <img src="assets/img/invoices/download.png" alt="">
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
            </div>
        </div>

</div>
</div>

    </div>
</div>
    <!-- <div class="card">
        <div class="">
            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-red">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice">3215687</li>
                                <li class="li-subscription">{{ 'back_office.billing.invoice.premium' | translate}}</li>
                                <li class="li-amount">$ 200.99</li>
                                <li class="li-date">07 Dec 2018</li>
                                <li class="li-payment-status"> <button class="btn btn-status btn-pending btn-adj">{{ 'back_office.billing.invoice.cancelled'| translate }}</button>
                                    <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj">{{ 'back_office.billing.invoice.pay_now'| translate }}</button>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="card">
        <div class="">
            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-green">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice">3215621</li>
                                <li class="li-subscription">{{ 'back_office.billing.invoice.standard' | translate}}</li>
                                <li class="li-amount">$ 120.31</li>
                                <li class="li-date">13 Feb 2019</li>
                                <li class="li-payment-status"> <button class="btn btn-status btn-paid">{{ 'back_office.billing.invoice.paid' | translate}}</button>
                                    <li class="paid-by">Mr Clark</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>
        </div>


    </div> -->

    <!-- <div class="card">
            <div class="">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="border-style border-color-red">
                            <div class="element">
                                <ul class="nav nav-inline">
                                    <li class="li-invoice">3215649</li>
                                    <li class="li-subscription">{{ 'back_office.billing.invoice.premium' | translate }}</li>
                                    <li class="li-amount">$ 149.99</li>
                                    <li class="li-date">07 Aug 2019</li>
                                    <li class="li-payment-status"> <button class="btn btn-status btn-danger btn-adj">{{ 'back_office.billing.invoice.pending'| translate }}</button>
                                    <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj">{{ 'back_office.billing.invoice.pay_now'| translate }}</button>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 action-btns">
                    </div>
                </div>
            </div>
    </div> -->

    <!-- <div class="card">
            <div class="">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="border-style border-color-red">
                            <div class="element">
                                <ul class="nav nav-inline">
                                    <li class="li-invoice">3215687</li>
                                    <li class="li-subscription">{{ 'back_office.billing.invoice.premium' | translate }}</li>
                                    <li class="li-amount">$ 200.99</li>
                                    <li class="li-date">07 Dec 2018</li>
                                    <li class="li-payment-status"> <button class="btn btn-status btn-pending btn-adj">{{ 'back_office.billing.invoice.cancelled' | translate}}</button>
                                        <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj">{{ 'back_office.billing.invoice.pay_now' | translate}}</button> -->


                                            <!-- cmnt -->
                                    <!-- <li style="width: 11.5%; padding-left: 40px;">
                                        <img src="assets/img/invoices/download.png" alt="">
                                    </li> -->
                                    <!-- cmnt -->

<!--
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 action-btns">
                    </div>
                </div>
            </div>
    </div>
    <div class="card">
            <div class="">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="border-style border-color-green">
                            <div class="element">
                                <ul class="nav nav-inline">
                                    <li class="li-invoice">3215655</li>
                                    <li class="li-subscription">{{ 'back_office.billing.invoice.basic' | translate}}</li>
                                    <li class="li-amount">$ 143.99</li>
                                    <li class="li-date">14 Aug 2019</li>
                                    <li class="li-payment-status"> <button class="btn btn-status btn-danger">{{ 'back_office.billing.invoice.pending' | translate}}</button>
                                        <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj">{{ 'back_office.billing.invoice.pay_now' | translate}}</button> -->


                                            <!-- cmnt -->
                                    <!-- <li style="width: 11.5%; padding-left: 40px;">
                                        <img src="assets/img/invoices/download.png" alt="">
                                    </li> -->
                                    <!-- cmnt -->


                                <!-- </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 action-btns">
                    </div>
                </div>
            </div>
    </div>
</div> -->



<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content" >
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">{{"back_office.log.modal_header"}}</h4>
        </div>
        <div class="modal-body">
          <p>{{"back_office.log.some_text_in"}}.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">{{"back_office.log.close"}}</button>
        </div>
      </div>

    </div>
  </div>





    <!-- <div class="create-new" style="height: 50px;margin-top: 20px;">
        <a class="btn btn-lg btn-primary pull-right" style="height: 46px; width: 176px; border-radius: 25px;" data-target="#summary" data-toggle="modal">
            Pay Now
        </a>
    </div> -->

<!--
<div class="alert alert-danger breadcrum" style="height: 50px; padding: 20px;">
    <span class="bread_text">Billing History</span>
</div>
<div class="alert alert-info">
    <ul class="nav nav-inline">
        <li style="width: 16.66%"><a href="">Invoice ID</a></li>
        <li style="width: 16.66%"><a href="">Subscription Plan</a></li>
        <li style="width: 16.66%"><a href="">Date</a></li>
        <li style="width: 16.66%"><a href="">Billed</a></li>
        <li style="width: 16.66%"><a href="">Status</a></li>
        <li style="width: 16.66%"><a href="">Paid By</a></li>

    </ul>
</div>

<div class="card">
    <div class="">
        <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style">
                    <div class="element">
                        <ul class="nav nav-inline">
                            <li style="width: 16.66%">3215649</li>
                            <li style="width: 16.66%;">Premuim</li>
                            <li style="width: 16.66%;">07 Aug 2019</li>
                            <li style="width: 16.66%;">$ 0.00</li>
                            <li style="width: 16.66%;"><button class="btn btn-paid btn-status green btn-size">Paid</button></li>
                            <li style="width: 16.66%;">Jane Doe</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">

            </div>
        </div>

    </div>
</div>

<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <app-plan-swtich></app-plan-swtich>
                </div>
            </div>
        </div>
        </div>
    </div>
</div> -->

<div id="summary" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="summaryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 5px;" [ngClass]="darkMode ? 'darkpoP' : ' null '">
                <div class="alert alert-info" style=" height: 40px !important; padding-top: 10px !important;" [ngClass]="darkMode ? 'smooth-dark' : ' null '">
                    <h4 class="no-margin">
                        <strong>{{"back_office.log.billing_summary" | translate}}</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <strong id="cross">&#x2716;</strong>
                          </button>
                    </h4>
                </div>

                <div class="alert alert-info" style="background: linear-gradient(#f96061, #860425); height: 40px !important; padding-top: 10px !important;" [ngClass]="darkMode ? 'bredcurm-dark' : ' null '">
                    <h4 class="no-margin">
                        <strong>{{"back_office.log.invoices" | translate}}</strong>
                    </h4>
                </div>

                <div class="card" style="padding: 5px;" [ngClass]="darkMode ? 'card-dark' : 'card'">
                    <div class="border-style b1">
                        <div class="element" style="height: 40px;padding:0px;margin-bottom: 5px">
                           <div class="row" style="margin-top: 0px">
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-left: 15px;border-right: 1px solid lightgrey;"><label class="bsTxt">{{"back_office.log.invoices_id" | translate}}</label></div>
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;"><label class="bsNm">3215642</label></div>
                           </div>
                        </div>
                    </div>

                    <div class="border-style b2">
                        <div class="element" style="height: 40px;padding:0px;margin-bottom: 5px">
                           <div class="row" style="margin-top: 5px">
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-left: 15px;border-right: 1px solid lightgrey;"><label class="bsTxt">{{"back_office.log.status" | translate}}</label></div>
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;"><label class="bsNm">Unpaid</label></div>
                           </div>
                        </div>
                    </div>

                    <div class="border-style b3">
                        <div class="element" style="height: 40px;padding:0px;margin-bottom: 5px">
                           <div class="row" style="margin-top: 5px">
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-left: 15px;border-right: 1px solid lightgrey;"><label class="bsTxt">{{"back_office.log.billing_amount" | translate}}</label></div>
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;"><label class="bsNm">$450.00</label></div>
                           </div>
                        </div>
                    </div>

                    <div class="border-style b4">
                        <div class="element" style="height: 40px;padding:0px;margin-bottom: 5px">
                           <div class="row" style="margin-top: 5px">
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-left: 15px;border-right: 1px solid lightgrey;"><label class="bsTxt">{{"back_office.log.paid" | translate}}</label></div>
                            <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;"><label class="bsNm">$00.00</label></div>
                           </div>
                        </div>
                    </div>

                    <div class="text-center m-t-5" style="margin-top: 5px !important;" >
                        <a href="#" class="btn btn-primary btn-adj hov" style="height: 30px; width: 100px; border-radius: 50px; padding: 4px; font-weight: 700;" >{{"back_office.log.pay_now" | translate}}</a><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
