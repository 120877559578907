import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../layout.service";
declare var $: any;
@Component({
  selector: 'app-without-header',
  templateUrl: './without-header.component.html',
  styleUrls: ['./without-header.component.css']
})
export class WithoutHeaderComponent implements OnInit {
  dark_mode;
  constructor(
    public layout_service: LayoutService,
  ) { }

  ngOnInit() {

   }

}
