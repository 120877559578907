<div class="jumbotron ">
  <div class="jumbo-icon">
    <li class="  weather-hold" data-value="Todays Weather" title="" text="Main title!" style="list-style-type: none;">
      <div class="dash_window dashboard-card dragDash" id="div5"
        [ngClass]="darkMode ? 'dash_window_dark' : 'dash_window dashboard-card'">
        <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'win_header'" title="">
          <h4 class="heading" [ngClass]="darkMode ? 'heading_dark' : 'heading'">
            {{ 'dashboard.Todays_Weather' | translate }}</h4>
        </div>
        <div id="box4" class="box">
          <div class="weather_bg w-clearfix side side{{ i+1 }}"
            *ngFor="let update_weather of weather_update; let i = index"
            [ngClass]="darkMode ? 'notifications_dark' : 'weather_bg'">

            <div class="row">
              <div class="col-md-8">
                <h1 class="weather-title" [ngClass]="darkMode ? 'weather-title_dark': 'weather-title'">
                  {{ update_weather.title }}</h1>
                <h4 class="weather-sub-title">{{
                  update_weather.moment.format('dddd DD MMMM') }}</h4>
                <div class="weather">
                  <h1 class="temp">
                    <span class="weather-tm temp">{{ update_weather.min_temp
                      | number:'1.0-0' }}</span>°
                  </h1>
                  <p class="weather_con">
                    <span class="con_d"
                      [ngClass]="darkMode ? 'con_d_dark' : 'con_d'">{{update_weather.condition}}</span>
                  </p>
                  <p>
                    <span class="weather-wind">{{update_weather.wind}} {{
                      'rates_and_packages.extra.km_h' |
                      translate}}</span>
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div class="weather-sky">
                  <i class='wi wi-main wi-{{ update_weather.icon }}'></i>
                </div>
              </div>
            </div>

            <table>
              <tr>
                <td *ngFor="let forcast of update_weather.forcast; let i = index" class="mon txt-day"
                  [class]="weather_classes[i%weather_classes.length]">
                  {{ forcast.day.split(' ')[0] }}
                </td>
              </tr>
              <tr>
                <td *ngFor="let forcast of update_weather.forcast; let i = index" class="wi_icon icon mon_icon"
                  [class]="weather_classes[i%weather_classes.length]">
                  <i class='wi wi-{{ forcast.icon.split()[0] }}'></i>
                </td>
              </tr>
              <tr>
                <td *ngFor="let forcast of update_weather.forcast; let i = index" class="mon_min"
                  [class]="weather_classes[i%weather_classes.length]">
                  {{ forcast.min_temp }}°
                </td>
              </tr>
              <tr>
                <td *ngFor="let forcast of update_weather.forcast; let i = index" class="mon_max"
                  [class]="weather_classes[i%weather_classes.length]">
                  {{ forcast.max_temp }}°
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </li>
  </div>
</div>