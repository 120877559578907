import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-front-desk',
  templateUrl: './front-desk.component.html',
  styleUrls: ['./front-desk.component.css']
})
export class FrontDeskComponent implements OnInit {

  graphDays = [
    { name: 'Today' },
    { name: 'Previous Month' },
    { name: 'Current Month' }
  ];
  darkMode: boolean = false;
  graphDaysP;
  @Input('frontDesk') frontDesk: any;
  @Output() dashboardData = new EventEmitter<any>();

  constructor(
    public http: HttpClient,
    public weather: DashboardService,
  ) { }

  ngOnInit() {
    this.checkDarkMode()
  }

  meterCount(p) {
    return Math.round(p * 180 / 100) - 45;
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getDashboardData(){
    console.log("emit dashboard data");
    
    this.dashboardData.emit()
  }
}
