import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { ApiService } from '../../services/api.service';
// import { HelperService } from '../../services/helper.service';
// import { NgForm } from "@angular/forms";
// import { Router, ActivatedRoute } from '@angular/router';
// import { AuthService } from "../../services/auth.service";
// import { ErrorHandlingService } from "../../services/error-handling.service";
// import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
// import { EventsService } from '../../services/events.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  current_date;
  errorMessage;
  successMessage;
  pass;
  cpass;
  email;
  uname;


  constructor(

    public router : Router,
    public route : ActivatedRoute,

    ) { }

  ngOnInit() {
    this.current_date = new Date().getFullYear()

    this.route.queryParams.subscribe(params => {
      if(params.forgot){
        this.successMessage = params.forgot;
      }
    });
  }

  

}
