<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a class="btn btn-link" routerLink="/app/back_office">{{ 'back_office.billing.manage_how_you_pay_tab.back_office' | translate }}</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">{{ 'back_office.billing.manage_how_you_pay_tab.billing' | translate }}</a>
    <a class="btn btn-link active">{{ 'back_office.billing.manage_how_you_pay_tab.manage_how_you_pay' | translate }}</a>
</div>
<div class="no_scroll scroll">
<div id="drag-list">

    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
        <div class="row" >
            <div class="col-md-3">
                <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.credit_card' | translate }}</h1>
                <img class="img-responsive" src="assets/img/manage_how_you_pay/american_express.jpg" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/visa_new.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/discover.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/master_new.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px;">
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec1">
                    {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                </h3> -->
                <div class="border-style description-shade" style="margin-top: 10px;">
                    <div class="element">
                        {{ 'back_office.billing.manage_how_you_pay_tab.use_credit_card' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <div class="row" >
                <div class="col-md-3">
                    <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.debit_card' | translate }}<span class="sp_class" style="margin-left: 5px;">({{ 'back_office.billing.manage_how_you_pay_tab.us_Customers_only' | translate }})</span></h1>
                    <!-- <p>US Customers only</p> -->
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/visa_electron.png" style="border-radius: 0px; padding-right: 0px;
                    margin-right: 10px;">
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/maestro.png" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px;">
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/uni.png" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px;">
                </div>
                <div class="col-md-6">
                    <!-- <h3 class="card-title description dec2">
                        {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                    </h3> -->
                    <div class="border-style debit-shade" style="margin-top: 10px;">
                        <div class="element">
                            {{ 'back_office.billing.manage_how_you_pay_tab.use_debit_card' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 active-padding">
                    <div class="text-right text-padding">
                        <div class="btn-wrapper">
                            <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <div class="row" >
                <div class="col-md-3">
                    <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.electronic_fund_transfer' | translate }}<span class="sp_class" style="margin-left: 5px;">({{ 'back_office.billing.manage_how_you_pay_tab.ach_or_wire' | translate }})</span></h1>
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/home.png" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px;">
                    <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/stripe.png" style="border-radius: 10px;">                    -->
                </div>
                <div class="col-md-6">
                    <!-- <h3 class="card-title description dec3">
                        {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                    </h3> -->
                    <div class="border-style electronic-shade" style="margin-top: 10px;">
                        <div class="element">
                            {{ 'back_office.billing.manage_how_you_pay_tab.use_electronic_transfer' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 active-padding">
                    <div class="text-right text-padding">
                        <div class="btn-wrapper">
                            <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <div class="row" >
                <div class="col-md-3">
                    <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.pay_with_stripe' | translate }}</h1>
                    <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/home.png" style="border-radius: 10px;"> -->
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/stripe_new.png" style="border-radius: 0px; padding-right: 0px;
                    margin-right: 10px;">
                </div>
                <div class="col-md-6">
                    <!-- <h3 class="card-title description dec4">
                        {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                    </h3> -->
                    <div class="border-style stripe-shade" style="margin-top: 10px;">
                        <div class="element">
                            {{ 'back_office.billing.manage_how_you_pay_tab.use_stripe_method' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 active-padding">
                    <div class="text-right text-padding">
                        <div class="btn-wrapper">
                            <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
            <div class="row" >
                <div class="col-md-3">
                    <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.pay_with_paypal' | translate }}</h1>
                    <img class="img-responsive" src="assets/img/manage_how_you_pay/paypal.png" style="border-radius: 6px; padding-right: 0px;
                    margin-right: 10px;">
                    <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/square.png" style="border-radius: 10px;"> -->
                </div>
                <div class="col-md-6">
                    <!-- <h3 class="card-title description dec5">
                        {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                    </h3> -->
                    <div class="border-style paypal-shade" style="margin-top: 10px;">
                        <div class="element">
                            {{ 'back_office.billing.manage_how_you_pay_tab.pay_with_paypal' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 active-padding">
                    <div class="text-right text-padding">
                        <div class="btn-wrapper">
                            <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
        <div class="row" >
            <div class="col-md-3">
                <h1 class="blue_heading">{{ 'back_office.billing.manage_how_you_pay_tab.pay_with_square' | translate }}</h1>
                <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/paypal.png" style="border-radius: 10px;"> -->
                <img class="img-responsive" src="assets/img/manage_how_you_pay/square_updated.png" style="width: 120px; padding-right: 0px;
                margin-right: 10px;">
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec6">
                    {{ 'back_office.billing.manage_how_you_pay_tab.description' | translate }}
                </h3> -->
                <div class="border-style square-shade" style="margin-top: 10px;">
                    <div class="element">
                        {{ 'back_office.billing.manage_how_you_pay_tab.use_square' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block hov">{{ 'back_office.billing.manage_how_you_pay_tab.activate' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
</div>
</div>
