import { Injectable, AfterViewInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  dark_mode;
  spinner = false;
  collapse;
  constructor() {
    this.collapse = this.check_sidebar_collapse();
  }

  check_sidebar_collapse(){
    let res;
    let status = localStorage.getItem('sidebar_collapse');
    res = status == 'true' ? true : false;
    return res;
  }

  sidebar_collapse(){
    this.collapse = !this.check_sidebar_collapse();
    localStorage.setItem('sidebar_collapse', this.collapse);
  }

}
