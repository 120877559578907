import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../services/auth.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm;
  email;
  pass;
  errorMessage;
  successMessage;
  remember;
  form: NgForm
  user;
  current_date;
  darkMode: boolean = false;
  showPassword: boolean = false;

  constructor(
    public Api : ApiService,
    public router : Router,
    public route : ActivatedRoute,
    public auth: AuthService,
    public error_handling: ErrorHandlingService,
    public confirmation: ConfirmationAlertService,
    public helper: HelperService,
    public events: EventsService
  ) {
    this.events.receiveDarkMode().subscribe((res:any)=>{
      this.darkMode = res.darkMode;
    })
   }

  ngOnInit() {
    this.checkDarkMode();
    this.current_date = new Date().getFullYear()
    if(localStorage.getItem('auth_token')){
      return this.router.navigateByUrl("app/dashboard");
    }
    this.route.queryParams.subscribe(params => {
      if(params.forgot){
        this.successMessage = params.forgot;
      }
    });
  }

  checkDarkMode(){
    let a = JSON.parse(localStorage.getItem('user'));
    if(a != null){
      let mode = a.mode;
      if(mode == 'dark'){
        this.darkMode = true;
      }
    }
  }

  login(form){
    if(form.valid){
      this.auth.authenticate(form.value.email, form.value.pass).subscribe((res: any) => {
        if (res.status == 200) {
          localStorage.setItem('auth_token', res.body.access_token);
          localStorage.setItem('refresh_token', res.body.refresh_token);
          this.Api.post('api/logs/login_activity',res.status, true).subscribe((res:any) => {

          }, err => this.error_handling.handle_error(err.status));



          this.Api.get('api/user/me', true).subscribe((res:any) => {
            var user = res.body.data;
            this.user = res.body.data;
            localStorage.setItem('user', JSON.stringify(user));
            if(user.mode == 'dark'){ this.darkMode = true; this.checkDarkMode(); this.events.publishDarkMode({darkMode: true});}
            else{this.darkMode = false; this.checkDarkMode(); this.events.publishDarkMode({darkMode: false});}
            if (user.default_property != null) {
              localStorage.setItem('current_property', user.default_property);
              localStorage.setItem('current_currency', user.default_property);
            }
            this.helper.user_subject.next(user);
          }, err => this.error_handling.handle_error(err.status));


          this.Api.get('api/user/current_property', true).subscribe((res:any) => {




            if (this.user.default_property != null) {

              localStorage.setItem('current_currency', res.body.data.currency);
            }
            return this.router.navigateByUrl("app/dashboard");
          }, err => this.error_handling.handle_error(err.status));






        }else{
          this.errorMessage = res.body.message;
        }
      }, err => {
        if(err.status == 401){
          this.errorMessage = "Email or Password is incorrect";
        }else{
          this.errorMessage = err.message;
        }
      });
    }else{
      this.errorMessage = "Email or Password is Invalid";
    }
  }

  confirmBox(){
    this.confirmation.confirm(
        "Unlock User (Mr John)",
        'Are your sure you want to do this action?',
        ['is this you', 'here are your godies', 'bye bye'],
        function(){
            // console.log("Confirm YES....");
        }
    );
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

}
