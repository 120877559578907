<div class="container-fluid">
  <div class="row">
    <div id="login-section" class="col-md-5">
      <div class="row">

        <div class="col-md-offset-2 col-md-8">
          <div class="text-center">
            <a class="logo btn">


              <!-- logo for cgsl (local site) -->
              <!-- <img src="../../../assets/img/cgsl_logo.png" alt=""> -->

              <!-- logo for pointclickstay (foreign site) -->
              <img src="../../../assets/img/logo-pcs-pointclickstay-dark-color.png" alt="" style="width:70%">
            </a><br>
            <small class="sub-title">LOGIN TO YOUR</small>
            <h3 class="title no-margin">Channel Manager</h3><br>
            <div class="alert alert-success" *ngIf="successMessage != null">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{successMessage}}
            </div>
            <div class="alert alert-danger" *ngIf="errorMessage != null">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{errorMessage}}
            </div>
          </div>


          <form method="POST" novalidate #form="ngForm" class="form-horizontal">
            <label class="control-label cg-label">Email</label>
            <input type="text" name="email" class="form-control cg-input" [(ngModel)]="email" placeholder="" required
              pattern="^[A-Za-z0-9@.]+$" />
            <br>
            <!-- <label class="control-label cg-label">Password <span class="fg-pass-span"><a class="" routerLink="forgot_password">Forgot password</a></span></label> -->
            <!-- <input type="password" name="pass" class="form-control cg-input" [(ngModel)]="pass" required minlength="6"> -->
            <!-- <input type="password" name="pass" class="form-control cg-input" placeholder="" [(ngModel)]="pass" required /> -->
            <div class="nameIcon">

              <input name="pass" class="form-control cg-input" [(ngModel)]="pass" required
                [type]="showPassword ? 'text' : 'password'" />
              <i class="pass_eye" [ngClass]="showPassword ? 'fa fa-eye-slash' : ' fa fa-eye'"
                (click)="togglePasswordVisibility()" aria-hidden="true"></i>
              <!-- <mat-icon class="pass_eye"  (click)="togglePasswordVisibility()"> {{showPassword?'visibility_off':'visibility'}}</mat-icon> -->
            </div>
            <br>
            <label>
              <input type="checkbox" name="remember" class="cg-checkbox no-margin" [(ngModel)]="remember" />
              <span class="remember-email">Remember me</span>
            </label>
            <br>
            <button class="btn btn-info btn-block cg-btn login-btn" type="submit" (click)="login(form)">LOGIN</button>
            <br>
            <!-- <a class="btn btn-block cg-btn-blank" routerLink="forgot_password">Forgot password</a> -->

            <p class="create-p">Don't have an account? &nbsp; <a class="create-a"
                href="https://www.pointclickstay.com/create_account">CREATE
                ACCOUNT</a></p>

            <!-- <button class="btn btn-info btn-block cg-btn registration-btn" type="submit" routerLink="registration">CREATE BUSINESS ACCOUNT</button> -->
            <!-- <a class="btn btn-block cg-btn-blank" routerLink="reset-password/MeCgvUpOlrqymen2OcjqLWoV0DpgeCRbKmLeLn4UUfksTWj6x1LP4fnBKrwR">Reset password</a> -->
            <!-- <a class="btn btn-block cg-btn-blank confirm-box" (click)="confirmBox()" data-toggle="modal" data-target="#confirmationModal">Test</a> -->
          </form>
          <br><br><br><br>

          <div class="footer">
            <div class="row">
              <div class="col-md-6">
                <small>Copyright {{ current_date }} Hotel Genine</small>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-7 login-bg"></div>
  </div>
</div>
<app-confirmation-alert></app-confirmation-alert>