import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute } from "@angular/router";
import { ManageRatesPackagesComponent } from "../manage-rates-packages.component";
import { ManageRatesPackagesService } from "../manage-rates-packages.service";
declare var $: any;
import * as moment from "moment";
import { GlobaldataService } from "src/app/common/globaldata.service";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { Console } from "console";
import { parse } from "querystring";

@Component({
  selector: "app-setup-package",
  templateUrl: "./setup-package.component.html",
  styleUrls: ["./setup-package.component.css"],
  providers: [ManageRatesPackagesComponent],
})
export class SetupPackageComponent implements OnInit {
  create_package_form: FormGroup;
  submitted = false;
  open_custom = false;
  setup_package;
  rooms;
  room;
  roomrate;
  start_c;
  end_c;
  data;
  abc;
  getSelectedRooms;
  property_id;
  bHeight;
  room_id_id;
  season_id;
  playerName;
  greenbar;
  season_package_id;
  package_id;
  package_idd;
  selectroom;
  roomtypes;
  season_packages_data;
  seasonname;
  rate_plan_id;
  rd;
  starting;
  ending;
  roomname;
  Dayjs;
  package_data;
  minDate = new Date(2019, 0, 1);
  minDate2 = new Date(2019, 0, 1);
  maxDatee;
  minDatee;
  // maxDate;
  //maxDate is 1st Jan 2020
  //minDate is 1st Jan 2019
  //maxDate is 1st Jan 2020
  maxDate = new Date(2020, 0, 1);
  selectedRoom;
  testvar;
  edit = false;
  current_user = JSON.parse(localStorage.getItem("user")).id;
  darkMode: boolean = false;
  datess = [];
  date;
  date_to;
  date_to_rate;
  date_from;
  date_from_rate;
  start_date;
  end_date;
  room_id;

  amenities = [];

  wheelT: boolean = false;
  smoke: boolean = false;
  pet: boolean = false;
  breakfast: boolean = false;

  date_between;
  upsells = [];
  upsel_item;
  upsel_value;
  d = Array();
  newdate;
  colors = [
    "#4db7d8",
    "#059800",
    "#6b4020",
    "#c7a563",
    "#2c2d99",
    "#73f199",
    "#925593",
    "#fd7600",
    "#5624ff",
  ];
  colors2 = [
    "#004386",
    "#ff6f00",
    "#e651ff",
    "#9c27b0",
    "#2dabd3",
    "#4caf50",
    "#ff6f00",
    "#ff51f3",
    "#673ab7",
  ];
  upsell_items = [];
  langCode;
  options;
  selectedD: any;
  rtyp: any;
  get_rate_by_checkin_checkout;

  suggList = GlobaldataService.holidays;
  slist: boolean = false;
  packageDates = [
    // for zero form
    { date_day: moment(new Date()).format("YYYY-MM-DD"), price: "" },
  ];

  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public helper: HelperService,
    public fb: FormBuilder,
    public manageRates: ManageRatesPackagesComponent,
    public service: ManageRatesPackagesService,
    public Aroute: ActivatedRoute
  ) {
    this.Aroute.params.subscribe((val) => {
      this.get_package();
    });
  }

  ngOnInit() {
    this.createZeroForm();
    this.getHolidays();
    this.getLangCode();
    this.get_rooms();
    this.modalfooter();
    this.checkDarkMode();
    this.getUpsell();
    this.get_season();

    this.create_package_form = this.fb.group({
      property_id: "",
      package_name: ["", Validators.required],
      package_id: "",
      date: ["", Validators.required],
      date_from: ["", Validators.required],
      date_to: ["", Validators.required],
      room_type_id: [null, Validators.pattern("[0-9]{0,10}")],
      description: ["", Validators.required],
      // breakfast_include: ['', Validators.required],
      room_id: "",
      upsel_item: null,
      upsel_value: "",
      current_user: JSON.parse(localStorage.getItem("user")).id,
      rate_plan_id: "",
      upsell: "",
      roomname: "",
      wheelchairaccessibility: this.wheelT,
      pets: this.pet,
      smoking: this.smoke,
      breakfasts: this.breakfast,
      custom_room_type: "",
      package_type: ["", Validators.required],
    });
    this.options = { year: "numeric", month: "long", day: "numeric" };
    var today = new Date();
    this.minDate = new Date(today.toLocaleDateString("en-US", this.options));
    this.minDate2 = new Date(today.toLocaleDateString("en-US", this.options));
    this.minDate2.setDate(this.minDate2.getDate() + 1);

    this.get_package();
    this.f["upsel_item"].valueChanges.subscribe((res) => {
      if (res != undefined && res != "" && res == "open_custom") {
        this.on_sec_change();
      }
    });

    $("#SP").mouseup(function () {
      $("#grab-10").css({ cursor: "grab" });
    });

    $("#SP").mousedown(function () {
      $("#grab-10").css({ cursor: "grabbing" });
    });
  }

  dodrag2() {
    $("#settings").draggable("enable");
    // $('#SSS').css({ "cursor": "grab" })
  }

  stopDrag2() {
    $("#settings").draggable("disable");
    // $('#SSS').css({ "cursor": "grabbing" })
  }

  getHolidays() {
    this.api.get("api/helper/get_holidays", true).subscribe(
      (res: any) => {
        GlobaldataService.holidays = res.body.data;
        this.suggList = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  get_rooms() {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post("api/room/index?per_page=500", { property_id: property_id }, true)
      .subscribe(
        (res: any) => {
          this.roomtypes = res.body.data;
          // console.log(this.roomtypes);
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_season() {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/package/get_season_name_by_property",
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.seasonname = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_rates(start_date, end_date, room_id) {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/get_rates_by_checkin_checkout",
        { property_id: property_id, start_date, end_date, room_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.get_rate_by_checkin_checkout = res.body.data;
          //  console.log(this.get_rate_by_checkin_checkout);
          //  console.log("lpc");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getLangCode() {
    var value = localStorage.getItem("code");
    this.langCode = value;
  }

  createZeroForm() {
    // this is create zero Form
    this.create_package_form = this.fb.group({
      allocation_high_1: "",
      allocation_high_2: "",
      allocation_high_rate: "",
      allocation_medium_1: "",
      allocation_medium_2: "",
      allocation_medium_rate: "",
      allocation_low_1: "",
      allocation_low_2: "",
      allocation_low_rate: "",
      property_id: localStorage.getItem("current_property"),
      season_package_id: "",
      rate_type: localStorage.getItem("current_currency"),
      room_type_id: "",
      room_id: "",

      prices: this.fb.array(
        this.packageDates.map((ob) => {
          return this.date_price(ob.date_day, ob.price);
        })
      ),
    });
  }

  date_price(d, p): FormGroup {
    // this will be used in create_from_none and createItem_from_createSeason
    return this.fb.group({
      date_day: [d, Validators.required],
      price: [p, Validators.required],
    });
  }

  get f() {
    return this.create_package_form.controls;
  }

  on_sec_change() {
    setTimeout(() => {
      let que = this.create_package_form.value.upsel_item;
      if (que == "open_custom") {
        this.open_custom = true;
        this.create_package_form.value.upsel_item = null;
        this.upsel_item = null;
        setTimeout(() => {
          $("#sec_custom_room_type").val(null);
        }, 100);
      }
    }, 500);
  }

  resetOptions() {
    this.create_package_form.value.upsel_item = null;
    this.open_custom = false;
    this.upsel_item = null;
  }

  onSubmit() {
    this.submitted = true;

    Object.keys(this.create_package_form.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.create_package_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {});
      }
    });
    if (this.create_package_form.invalid) {
      return;
    }
    var package_prices = [];

    var property_id = localStorage.getItem("current_property");
    var current_user = JSON.parse(localStorage.getItem("user")).id;
    let roomrate = document.getElementsByClassName("newrate");
    for (let i = 0; i < roomrate.length; i++) {
      package_prices.push({
        price: (<HTMLInputElement>document.getElementsByClassName("newrate")[i])
          .value,
          date_day: (<HTMLInputElement>(
          document.getElementsByClassName("season-date")[i]
        )).getAttribute("id"),
      });
    }

    this.create_package_form.patchValue({
      property_id: property_id,
      room_id: this.selectedRoom.id,
      room_type_id: this.selectedRoom.room_type_id,
      upsell: JSON.stringify(this.upsells),
    });

    if (this.edit == true) {
      this.updatePackage(this.service.package_creating);
      return;
    }

    // this.package_data.push(package_prices);
    this.create_package_form.addControl(
      "prices",
      this.fb.array(package_prices)
    );

    console.log(this.create_package_form.value);

    this.api
      .post("api/package/store", this.create_package_form.value, true)
      .subscribe(
        (res: any) => {


          this.current_user = res.body.data.current_user;
          this.service.package_creating = res.body.data.id;
          this.package_idd = res.body.data.id;

          this.updating(res.body.data.id)
          console.log(res.body.data.id);
         this.service.get_packages();
          this.submitted = false;
          this.selectedRoom = "";
           this.create_package_form.reset();
           this.manageRates.getRatePlan();
          this.helper.alert_success("Package has been created successfully !");
          // this.service.open_tab('setup_rate_open');
        },
        (err) => this.error_handling.handle_error(err.status)
      );


  }

  updating(id:any){
    this.create_package_form.patchValue({
      package_id: id,
    });
    let sendData = JSON.parse(JSON.stringify(this.create_package_form.value));

    this.api.post("api/package_allocation/update", sendData, true).subscribe(
      (res: any) => {
        this.service.get_packages();
        this.helper.alert_success("Rates are now setup");
        this.manageRates.getRatePlan();
        this.submitted = false;
        this.service.open_tab("setup_restrictions_open");
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }
  //
  getSelectedRoom(id) {
    // console.log(id);
    // this.get_rates_for_update(this.date_to_rate,this.date_from_rate)

    this.api.get("api/room/show/" + id.id, true).subscribe(
      (res: any) => {
        //
        // date_from: {startDate:Dayjs ,endDate:Dayjs};
        this.rd = id.id;

        this.get_rates(this.date_to_rate, this.date_from_rate, this.rd);
        // console.log(thisrd);
        //  this.get_rates(this.date_to_rate,this.date_from_rate ,this.rd);
        this.selectedRoom = res.body.data;
        console.log(this.selectedRoom);
      },
      (err) => this.error_handling.handle_error(err.status)
    );

    //       }, err => this.error_handling.handle_error(err.status));

    // this.breakfast = this.room.breakfasts.toLowerCase() == 'true' ? true : false;;
    // this.create_package_form.patchValue({breakfasts: this.breakfast});
  }

  getSelectedrooms(season) {
    var season_package_id = season.season_id;
    this.rate_plan_id = season.season_id;
    // console.log(this.rate_plan_id);

    this.api
      .post("api/package/get_rooms_rate_plan", { season_package_id }, true)
      .subscribe(
        (res: any) => {
          this.season_packages_data = res.body.data;

          // this.selectedD = {startDate: moment(season.date_to),endDate:moment(season.date_from)}
          // this.date = {startDate: moment(season.date_to),endDate:moment(season.date_from)}
          //
          // this.minDatee= moment(season.date_to);
          // this.maxDatee = moment(season.date_from);

          // this.date_from = season.date_from
          // this.date_to = season.date_to;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getroomsbysesaon(arr) {
    var property_id = localStorage.getItem("current_property");
    // var ar = JSON.parse(arr);

    this.api
      .post(
        "api/package/get_rooms_rate_plan",
        {
          property_id: property_id,
          arr: arr,
          startDate: this.date_to_rate,
          enddate: this.date_from_rate,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.season_packages_data = res.body.data;
          //  this.season_packages_data = res.body.data;

          // return res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_rates_for_update(startDate, enddate) {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/package/get_data_from_rate_plan",
        { property_id: property_id, startDate, enddate },
        true
      )
      .subscribe(
        (res: any) => {
          this.get_rate_by_checkin_checkout = res.body.data;
          console.log(this.get_rate_by_checkin_checkout);

          // return res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_package() {
    if (
      this.service.package_creating !== undefined &&
      this.service.package_creating !== ""
    ) {
      this.api
        .get("api/package/show/" + this.service.package_creating, true)
        .subscribe(
          (res: any) => {
            // if(res.body.data.room.smoking === 'true'){
            //   this.smoke = true;
            // }
            var abc = [];
            res.body.data.room.smoking === "true"
              ? (this.smoke = true)
              : (this.smoke = false);
            res.body.data.room.wheelchairaccessibility === "true"
              ? (this.wheelT = true)
              : (this.wheelT = false);
            res.body.data.room.pets === "true"
              ? (this.pet = true)
              : (this.pet = false);
            var property_id = localStorage.getItem("current_property");

            res.body.data.room.smoking === "true"
              ? abc.push({ smoking: true })
              : "";
            res.body.data.room.wheelchairaccessibility === "true"
              ? abc.push({ wheelT: true })
              : "";
            res.body.data.room.pets === "true" ? abc.push({ pets: true }) : "";

            this.api
              .post(
                "api/package/get_rooms_rate_plan",
                {
                  property_id: property_id,
                  arr: abc,
                  startDate: res.body.data.date_to,
                  enddate: res.body.data.date_from,
                },
                true
              )
              .subscribe(
                (res: any) => {
                  this.season_packages_data = res.body.data;
                },
                (err) => this.error_handling.handle_error(err.status)
              );

            this.package_id = this.service.package_creating;
            // console.log(res.body.data.room.smoking)
            // this.season_packages_data =  this.getroomsbysesaon(res.body.data.rate_plan_id);
            this.create_package_form.patchValue({
              property_id: res.body.data.property_id,
              package_name: res.body.data.package_name,
              date_from: res.body.data.date_from,
              rate_plan_id: res.body.data.rate_plan_id,
              date_to: res.body.data.date_to,
              room_type_id: res.body.data.room_type_id,
              roomname: res.body.data.room_id,
              description: res.body.data.description,
              room_id: res.body.data.room_id,
              package_type: res.body.data.package_type,
              selectroom: res.body.data.id,
            });
            this.edit = true;

            let a = res.body.data.date_to.split("/");
            let b = res.body.data.date_from.split("/");

            var end = moment(a[1] + "/" + a[0] + "/" + a[2]);
            var start = moment(b[1] + "/" + b[0] + "/" + b[2]);
            var endd = moment(a[1] + "-" + a[0] + "-" + a[2]);
            var startt = moment(b[1] + "-" + b[0] + "-" + b[2]);
            this.date = { startDate: end, endDate: start };
            console.log((this.date = { startDate: end, endDate: start }));
            // console.log(this.date = {startDate: end, endDate: start });
            // this.selectedD = {startDate: end, endDate: start}
            // this.minDatee= moment(this.ending);
            // this.maxDatee = moment(this.starting);
            this.date_from = res.body.data.date_from;
            this.date_to = res.body.data.date_to;
            current_user: res.body.data.current_user;
            this.start_c = a[2] + "-" + a[1] + "-" + a[0];
            this.end_c = b[2] + "-" + b[1] + "-" + b[0];

            this.api
              .post(
                "api/booking/get_rates_by_checkin_checkout",
                {
                  property_id: property_id,
                  start_date: this.start_c,
                  end_date: this.end_c,
                  room_id: res.body.data.room_id,
                },
                true
              )
              .subscribe(
                (ress: any) => {
                  this.get_rate_by_checkin_checkout = [];

                  for (let l = 0; l < res.body.data.prices.length; l++) {
                    var data_def: any =
                      parseInt(res.body.data.prices[l]["price"]) -
                      parseInt(ress.body.data[l]["price"]);
                    var sum_data: any =
                      100 * (data_def / parseInt(ress.body.data[l]["price"]));
                    var final_data = sum_data.toFixed(0) + "%";
                    this.get_rate_by_checkin_checkout.push({
                      date: res.body.data.prices[l]["date_day"],
                      price: ress.body.data[l]["price"],
                      package_price: res.body.data.prices[l]["price"],
                      name: "Apartment, 1 Bedroom",
                      per: final_data,
                    });
                  }

                  // console.log( this.get_rate_by_checkin_checkout);
                },
                (err) => this.error_handling.handle_error(err.status)
              );

            // console.log(this.get_rate_by_checkin_checkout);
            //this.get_rate_by_checkin_checkout =  res.body.data.prices//this.get_rates_for_update(this.start_c,this.end_c);

            this.upsells = JSON.parse(res.body.data.upsell) || [];
            this.upsells.forEach((sel) => {
              this.upsell_items = this.upsell_items.filter((el) => {
                return sel.name != el.value;
              });
            });
            this.getSelectroom(res.body.data.room_id);
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  getDifferenceInDays(date1, date2) {
    let date_between = Math.abs(date2 - date1);
    return date_between / (1000 * 60 * 60 * 24);
  }

  getSelectroom(id) {
    this.api.get("api/room/show/" + id, true).subscribe(
      (res: any) => {
        this.selectedRoom = res.body.data;
        console.log(res.body.data);
        this.data = {
          id: res.body.data.id,
          Name: res.body.data.roomtype,
        };
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  updatePackage(id) {
    var package_prices = [];
    let roomrate = document.getElementsByClassName("newrate");
    for (let i = 0; i < roomrate.length; i++) {
      package_prices.push({
        price: (<HTMLInputElement>document.getElementsByClassName("newrate")[i])
          .value,
        date_day: (<HTMLInputElement>(
          document.getElementsByClassName("season-date")[i]
        )).getAttribute("id"),
      });
    }
    this.create_package_form.addControl(
      "package_data",
      this.fb.array(package_prices)
    );
    this.api
      .post("api/package/update/" + id, this.create_package_form.value, true)
      .subscribe(
        (res: any) => {
          this.service.get_packages();
          this.getSelectedRooms = id;
          console.log(this.create_package_form);
          this.helper.alert_success(
            "Promotion has been updated successfully !"
          );
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  onClose() {
    this.service.package_creating = "";
    this.selectedRoom = "";
    this.create_package_form.reset();
  }

  // add_upsell(item, val){
  //   if(item != '' && val != ''){
  //    this.upsel_item = null;
  //    this.upsel_value = '';
  //    this.upsells.push({name: item, value: val});
  //    this.upsell_items = this.upsell_items.filter(el => {
  //      return item != el.value;
  //    });
  //   }
  // }
  // remove_upsell(i){
  //   this.upsells = this.upsells.filter(e => { return e.name != i });
  // }

  changeWheel() {
    this.wheelT = !this.wheelT;
    this.create_package_form.patchValue({
      wheelchairaccessibility: this.wheelT,
    });
    var obj = { wheelT: this.wheelT };
    if (obj["wheelT"] == true) {
      this.amenities.push({ wheelT: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.wheelT !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changeSmoke() {
    this.smoke = !this.smoke;
    this.create_package_form.patchValue({ smoking: this.smoke });
    var obj = { smoking: this.smoke };
    if (obj["smoking"] == true) {
      this.amenities.push({ smoking: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.smoking !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changePet() {
    this.pet = !this.pet;
    this.create_package_form.patchValue({ pets: this.pet });
    var obj = { pets: this.pet };
    if (obj["pets"] == true) {
      this.amenities.push({ pets: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.pets !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changebreakfast() {
    this.breakfast = !this.breakfast;
    this.create_package_form.patchValue({ breakfasts: this.breakfast });
  }

  choose_date(e) {
    if (e.startDate != null && e.endDate != null) {
      this.date_to = moment(e.startDate._d).format("DD/MM/YYYY");
      this.date_from = moment(e.endDate._d).format("DD/MM/YYYY");

      // console.log(this.date_to);

      this.date_to_rate = moment(e.startDate).format("YYYY-MM-DD");
      this.date_from_rate = moment(e.endDate).format("YYYY-MM-DD");
      // this.get_rates_for_update(this.date_to_rate,this.date_from_rate)

      this.onSearchChange;

      if (this.edit == true) {
        this.getSelectedRoom(this.data);
      }

      var arr = [];
      this.getroomsbysesaon(arr);

      if (this.selectedRoom != undefined) {
        console.log(this.selectedRoom);
        var property_id = localStorage.getItem("current_property");
        var room_id = this.selectedRoom.id;
        var start_date = this.date_to_rate;
        var end_date = this.date_from_rate;
        this.api
          .post(
            "api/booking/get_rates_by_checkin_checkout",
            { property_id: property_id, start_date, end_date, room_id },
            true
          )
          .subscribe(
            (res: any) => {
              this.get_rate_by_checkin_checkout = res.body.data;
            },
            (err) => this.error_handling.handle_error(err.status)
          );
      }
    }
  }

  modalfooter() {
    setTimeout(() => {
      let f = document.getElementById("ModalFooterP");
      let h = document.getElementById("SP");
      this.bHeight = h.offsetHeight;
      if (this.bHeight > 700) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      } else if (this.bHeight < 700) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    }, 100);
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  showList() {
    this.slist = true;
  }

  hideList() {
    this.slist = false;
  }

  selectHoliday(name) {
    setTimeout(() => {
      this.create_package_form.patchValue({ package_name: name });
    });
  }

  colorRadio(id, idt) {
    var a = document.getElementById(id);
    var b = document.getElementById(idt);
    a.classList.contains("radioByJs")
      ? a.classList.remove("radioByJs")
      : a.classList.add("radioByJs");
    b.classList.contains("radioByJs")
      ? b.classList.remove("radioByJs")
      : b.classList.add("radioByJs");
  }

  getUpsell() {
    this.api
      .post(
        "api/helper/upsell",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe((res: any) => {
        this.upsell_items = res.body.data;
      });
  }

  add_upsell(item, value) {
    let u: any = undefined;
    u = this.upsell_items.filter((el) => {
      return item == el.id;
    });
    this.upsells.push({
      name: u[0].name,
      upsell_id: u[0].id,
      price: value,
    });
    this.upsel_item = null;
    this.upsel_value = null;
  }

  remove_upsell(i) {
    this.upsells = this.upsells.filter((e) => {
      return e.name != i;
    });
  }

  getUpsellPrice(data) {
    this.upsell_items.forEach((element) => {
      if (element.id == data) {
        let p;
        element.price == "free"
          ? (p = "Free")
          : element.price == "not-available"
          ? (p = "Not Available")
          : (p = element.price);
        this.create_package_form.patchValue({
          upsel_value: p,
        });
      }
    });
  }

  onSearchChange(event, i) {
    let newrate = document.getElementsByClassName("newrate");
    let roomrate = document.getElementsByClassName("roomrate");
    let diss = document.getElementsByClassName("dis");
    let getid = document.getElementById(i);
    let dis = document.getElementById(i);
    let greenbar = document.getElementsByClassName("disbox");
    let promotion_rate = (<HTMLInputElement>document.getElementById(`c${i}`))
      .value;
    let bar_rate = roomrate[i].getAttribute("id");

    var data_def: any = parseInt(promotion_rate) - parseInt(bar_rate);
    var sum_data: any = 100 * (data_def / parseInt(bar_rate));

    if (isNaN(sum_data)) {
      sum_data = 0;
    }

    if (sum_data > 0) {
      greenbar[i].innerHTML =
        '<div class="cardBarsRedGreen" style="height: 25px; background-color: green; border-radius: 20px; width: 40%; margin-left: 31%;margin-top:8px; text-align: center; font-weight: bold; color: white; line-height: 26px; font-size: 14px;"><div class="caret_up" style=""><div class="greenbar"> ' +
        sum_data.toFixed(0) +
        '% </div></div></div><i class="fa fa-caret-up" style="position: absolute; top: 11px; color: white; left: 92px; font-size: 20px;"></i>';
    } else {
      greenbar[i].innerHTML =
        '<div class="cardBarsRedGreen" style="height: 25px; background-color: red;margin-top:8px; border-radius: 20px; width: 40%; margin-left: 31%; text-align: center; font-weight: bold; color: white; line-height: 26px; font-size: 14px;"><div class="caret_up" style=""><div class="greenbar"> ' +
        Math.abs(sum_data.toFixed(0)) +
        '% </div></div></div><i class="fa fa-caret-down" style="position: absolute; top: 11px; color: white; left: 92px; font-size: 20px;"></i>';
    }
  }
}
