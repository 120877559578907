<div [ngClass]="[service.is_preview == true ? 'padd5' : 'padd0']" id="SP">

	<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <!-- <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">{{ 'rates_and_packages.preview_package.rates_and_packages' | translate }}</a>
    <a href="" class="btn btn-link active" data-dismiss="modal" aria-label="Close" [routerLink]="setup_rate">{{ 'rates_and_packages.preview_package.preview_package' | translate }}</a> -->
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">
      {{ "rates_and_packages.link_channel.rates_and_packages" | translate }}
    </a>
    <a href="" class="btn btn-link active" data-dismiss="modal" aria-label="Close" [routerLink]="setup_rate">PREVIEW PROMOTION</a>
  </div>
<div class="no_scroll scroll">
	<div class="white_box channels preview_box w-clearfix" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '">
		<div class="col-md-12">
			<div class="row" style="padding: 0px 10px">
				<div class="col-md-4 zero-padding" style="    width: 35%;">
					<div class="col-md-12 zero-padding" style="padding-right: 0px !important">
						<div class="col-md-7 zero-padding" style="    width: 54%;">
							<div class="nmD">
								<h3 class="package_title pkg-heading" data-placement="top" data-toggle="tooltip" title="{{ package_data?.package_name }}">{{ package_data?.package_name }}</h3>
								<div>
                  <span class="nights">
                    <img src="assets/img/newstaticicons/rates-packages/night.svg" alt="" class="im-mr" style="margin-top: 0px;margin-left: 1px;float: left;margin-right: 5px;" />
                    {{ nights }} {{ 'rates_and_packages.preview_package.nights' | translate }}
                  </span>
                </div>
								<h4 class="validity" *ngIf="package_dates != undefined" style="padding: 0px;">
                  {{ package_dates.start | date:"MMM dd" | uppercase }} | {{package_dates.end | date:"MMM dd" | uppercase}}
                </h4>
              </div>
						</div>
						<div class="col-md-5 zero-padding" style="    width: 45%;">
							<div class="image-00">
                <div style="overflow: hidden;border-radius: 10px;">
                  <img src="{{ preview_image }}" alt="" style="border-radius: 10px; margin-right: 5px;" class="image-14 pointImg" data-toggle="modal" data-target="#imgModal" (click)="showImgModal(package_data?.room.images)">
									<div class="imageDots">
										<ng-container *ngFor="let a of package_data?.room.images | slice:0:4">
                      <i class="fa fa-circle" data-toggle="modal" data-target="#imgModal" (click)="showImgModal(package_data?.room.images)"></i>
                    </ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-5 zero-padding descript" [ngClass]="[service.is_preview == true ? 'wd2' : 'w1']">
					<!-- <h3 class="package_title descrption">{{ 'rates_and_packages.preview_package.description' | translate }}</h3> -->
					<h3 class="package_title descrption">{{ package_data.roomttype.name}}</h3>

					<div>
						<div class="description_text p-b">
              {{ descripArray(package_data.room.description) }}
              <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == false && package_data?.description.split(' ').length > 30">
              <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == true && package_data?.description.split(' ').length > 52">
            </div>
						<div class=" description_text p-b" *ngIf="desT" [ngClass]="darkMode ? 'tooldes' : 'tooldeslight'">
							<p>{{ descripArray2(package_data.room.description) }}</p>
						</div>
					</div>
					<div class="bt" style="clear: both; display: flex; ">
						<div class="totlo" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg" alt="{{ package_data?.room?.bedtype?.name }}" class="image-adj" [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '28px'}">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo1">
                <p style="margin: 0px;">{{ package_data?.room?.bedtype?.name }}</p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']" *ngIf="service.is_preview == true" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
              {{ package_data?.room?.bedtype?.name }}
            </span>
						<div class="totlo" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg" alt="" class="image-adj" [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '21px'}">
               <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo2">
                <p style="margin: 0px;">{{ "rates_and_packages.list.sleeps" | translate }} {{ package_data?.room?.adults + package_data?.room?.children }}</p>
               </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']" *ngIf="service.is_preview == true" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
              {{ 'rates_and_packages.preview_package.sleeps' | translate }} {{ package_data?.room?.adults + package_data?.room?.children }}
            </span>
						<div class="totlo" (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg" alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'square_feet'" [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg" alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'meters'" [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo3">
                <p style="    margin: 0px;">{{  package_data?.room?.room_size }} {{package_data?.room?.room_size_type == 'square_feet' ? 'm' : 'rates_and_packages.menu.sqft' | translate }}</p>
              </span>
            </div>
            <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']" *ngIf="package_data?.room?.room_size_type == 'square_feet' && service.is_preview == true" (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              {{  package_data?.room?.room_size }} {{ 'rates_and_packages.menu.sqft' | translate }}
            </span>
            <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']" *ngIf="package_data?.room?.room_size_type == 'meters' && service.is_preview == true" (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              {{  package_data?.room?.room_size }} m
            </span>

            <div class="totlo" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg" alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'false'" [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg" alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'true'" [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo4">
                <p style="    margin: 0px;">{{  package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}</p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']" *ngIf="service.is_preview == true" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
              {{  package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}
            </span>
						<div class="totlo" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg" alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'false'" [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '20px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg" alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'true'" [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '18px'}">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo5">
              <p style="    margin: 0px;">{{  package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}</p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']" *ngIf="service.is_preview == true" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
              {{  package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}
            </span>
						<div class="totlo" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg" alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'false'" [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg" alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'true'" [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo6">
                <p style="margin: 0px;">{{  package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}</p>
              </span>
            </div>
              <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']" *ngIf="service.is_preview == true" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
                {{  package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}
              </span>
          </div>
				</div>
				<div class="col-md-3 zero-padding" [ngClass]="[service.is_preview == true ? 'wd30' : 'w2']">
					<div class="season-box2 onoone" style="width:54.2%" [ngClass]="darkMode ? 'card-dark-side-season' : ' card-null '">
						<div class="new" style="height: 100%;">
							<div style="display: flex;align-items: center;height: 100%;">
                <div style="height: 100%;">
                  <img src="assets/img/newstaticicons/rates-packages/promotion.svg" style="width: 90px;margin-bottom: 22%;">
									<!-- <div class="pkg-off" *ngIf="percentage != 0">{{percentage}}% Off</div> -->
                  <div style="font-size: 14px;color: #707070;" >
                    <!-- <b>Per Night</b>
                    <span class="price"
                      style="font-size: 16px !important;">
                      $100
                    </span> -->
                    <b>{{ "rates_and_packages.menu.PerNight" | translate }}</b>

																<span class="price {{ colors[i % 7] }}"
																*ngIf="((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)),'days')) >= 1"
																style="font-size: 16px !important;">

																${{ roundPricee(package_data?.sum_price /
																((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)),"days")))
																}}

															</span>

															<span class="price {{ colors[i % 7] }}"
																*ngIf="((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)),'days')) == 0"
																style="font-size: 16px !important;">

																{{ package_data?.sum_price | currency:current_currency}}

															</span>

                  </div>

                  <span class="breakfast"
                  *ngIf="package_data.breakfast_include == 'Yes'">
                  <i class="fa fa-utensils"></i>
                  {{ "rates_and_packages.menu.BreakfastIncluded" | translate }}
                </span>

                <span class="breakfast"
                  *ngIf="package_data.breakfast_include == 'No' || package_data.breakfast_include == '' ">
                  <i class="fa fa-utensils"></i> {{ "rates_and_packages.menu.BreakfastNotIncluded" | translate }}
                </span>
                  <!-- <div style="color: #707070;">
                        <span style="font-size: 20px;color: red;">Free {{package_data?.zero_price}}</span> Nights
                       </div> -->
                  <!-- <span class="breakfast" *ngIf="package_data?.breakfast_include=='Complimentry'"><i class="fa fa-utensils"></i> {{ 'rates_and_packages.link_channel.breakfast_include' | translate }}. </span>
                  <span class="breakfast" *ngIf="package_data?.breakfast_include=='Paid'"><i class="fa fa-utensils"></i>  Breakfast Not Include. </span> -->
								</div>
                <!-- <div class="DicsDiv">
                  <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="">
                  <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{ roundPrice(package_data.sum_price , package_data.season_rates) }}</p>
                </div> -->

                <div class="DicsDiv">
                  <img src="assets/img/newstaticicons/rates-packages/discount.svg"
                    alt="" *ngIf="getRoundPrice(package_data.sum_price , package_data.season_rates).length>0 && getRoundPrice(package_data.sum_price , package_data.season_rates).charAt(0)!=='-'">
                  <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
                    {{ getRoundPrice(package_data.sum_price , package_data.season_rates)}}
                  </p>
                </div>
							</div>
						</div>
					</div>
					<div class="season-box2" style="width: 41.5%" [ngClass]="darkMode ? 'card-dark-side-season' : ' card-null '">
						<div class="new" style="height: 120px">
							<div class="col-md-12 zero-padding my-col" style="padding: 0px !important">
								<div class="col-md-12 zero-padding" [ngClass]="[package_data?.breakfast_include=='Complimentry' ? 'm_b_r' : 'm_b_r2']">
                  <span class="total " style="font-size: 14px;font-weight: bold;text-transform:uppercase;font-family: robotoblack;color: #00a721">{{ 'rates_and_packages.menu.total' | translate }}</span>
                </div>
              </div>

								<div class="col-md-12 zero-padding" style="padding-left: 0px !important;">
                  <div style="font-size: 14px;color: #707070;">
                    <b>{{((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)), "days"))}}</b>
                    <img src="assets/img/newstaticicons/rates-packages/night.svg" style="width: 15px; margin-left: 5px;" />
                  </div>
                  <span class="tax_cl" style="text-decoration: line-through 1px black; font-weight: bold;"> {{ package_data?.seasonRoomPrice | currency:current_currency}}</span>

                  <span class="price ">{{package_data?.sum_price | currency:current_currency}} </span>
                  <div class="tax_cl">{{ "rates_and_packages.menu.includeTax" | translate }}</div>
                </div>
								<!-- <div class="col-md-12 zero-padding">
                  <span class="breakfast" *ngIf="package_data?.breakfast_include=='Complimentry'">{{ 'rates_and_packages.link_channel.breakfast_include' | translate }}.</span>
                </div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


  <div class="aminity-Section">
    <div class="gradient-bar adj" *ngIf="package_data " [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <h4 class="h4">{{ 'rates_and_packages.link_channel.amenities' | translate }}</h4>
    </div>

    <div class="card aminity-card" style="padding:0px 10px !important;" [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '" *ngIf="package_data ">
      <div class="row" style="padding: 0px 10px !important">
        <div class="col-md-12 zero-padding" style="padding: 5px 5px 10px 5px !important; display: flex; justify-content: center;">
          <div class=" zero-padding" style="width: 15.6%;" *ngFor="let amiC of package_data?.room.with_parent_amenities;let i = index">
            <div class="col-md-12 amenityHead zero-padding" [ngStyle]="{'color': darkMode ? amenCol[i%amenCol.length] : '#00205b'}">
              <img src="assets/img/create-rooms/{{amiC.icon}}" style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;" *ngIf="darkMode == false">
              <img src=" assets/img/create-rooms/darkmode/{{amiC.icon}}" style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;" *ngIf="darkMode == true"> {{amiC.title}}
            </div>
            <div class="col-md-12 amenityDetail zero-padding" style="padding-left: 38px !important;" *ngFor="let Cma of amiC.children">{{Cma.title}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

	<div class="otas">
		<div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
			<div class="col-md-10 zero-padding">
				<h4 *ngIf="package_data.push_status == 'pushed'">{{ 'rates_and_packages.link_channel.connected_otas' | translate }}</h4>
				<h4 *ngIf="package_data.push_status == 'pending'" style="padding: 0px 20px;">{{ 'rates_and_packages.link_channel.selected_otas' | translate }}&nbsp;
          <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
            <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
              <p style="margin: 0px;">{{ 'rates_and_packages.link_channel.when_you_push' | translate }}</p>
            </span>
          </i>
        </h4>
      </div>
    </div>

		<div class="white_box channels w-clearfix fixes" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
			<div class="col-md-12" style="    padding-left: 1px !important;">
				<div class="{{ service.is_preview == true ? 'col-md-3' : 'col-md-4' }} csw" *ngFor="let ota of externals">
          <div class="channel_container {{ ota.chan.channel.name }} w-clearfix" style="display: flex;align-items: center;" *ngIf="package_data.promo_allocations.length >= 1" [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
            <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota.chan.channel_image : ota.chan.channel_image }}" alt="" class="tripadvisor ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
          </div>
          <div class="channel_container {{ ota.channel.name }} w-clearfix" style="display: flex;align-items: center;" *ngIf="package_data.promo_allocations.length < 1" [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
            <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota.channel.image : ota.channel.image}}" alt="" class="tripadvisor ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
          </div>
				</div>
        <h3 *ngIf="otas?.length == 0" class="no-margin" style="padding-bottom: 10px; font-weight: 600; padding-left: 5px;">{{ 'rates_and_packages.link_channel.no_otas_connected' | translate }}</h3>
      </div>
    </div>

		<div class="channels">
			<div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
				<div class="col-md-10 zero-padding">
					<h4 *ngIf="package_data.push_status == 'pushed'">{{ 'rates_and_packages.link_channel.connected_channels' | translate }}</h4>
					<h4 *ngIf="package_data.push_status == 'pending'" style="padding: 0px 20px;">{{ 'rates_and_packages.link_channel.selected_channels' | translate }} &nbsp;
            <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                <p style="margin: 0px;">{{ 'rates_and_packages.link_channel.when_you_push' | translate }}</p>
              </span>
            </i>
          </h4>
        </div>
			</div>
			<div class="white_box channels w-clearfix fixes" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '" style="margin-bottom: 0px;">
				<div class="col-md-12" style="    padding-left: 1px !important;">
					<div class="{{ service.is_preview == true ? 'col-md-3' : 'col-md-4' }} csw" *ngFor="let ota of internals">
            <div class="channel_container {{ ota.chan.channel.name }} w-clearfix" style="display: flex; align-items: center;" *ngIf="package_data.promo_allocations.length >= 1" [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
              <img src="assets/img/svgicons/channels/{{ ota.chan.channel_image }}" alt="" class="tripadvisor  ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']" *ngIf="ota.prom.promo_code == null">
							<div class="img0" *ngIf="ota.prom.promo_code != null" style="width: 100%">
								<div class="image000">
                  <img src="assets/img/svgicons/channels/{{ ota.chan.channel_image }}" alt="" class="tripadvisor  ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']" style="transform: translateY(20%);">
                </div>
								<div class="col-md-12">
									<div class="null" [ngClass]="[service.is_preview == true ? 'promo2' : 'promo']">
										<div class="promotion" [ngClass]="[service.is_preview == true ? 'promotion' : 'promotion2']"> {{ 'rates_and_packages.link_channel.promo' | translate }}: {{ota.prom.promo_code}} | {{ota.prom.counter}} </div>
									</div>
								</div>
							</div>
						</div>
            <div class="channel_container {{ ota.channel.name }} w-clearfix" style="display: flex; align-items: center;" *ngIf="package_data.promo_allocations.length < 1" [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
              <img src="assets/img/svgicons/channels/{{ ota.channel.image }}" alt="" class="tripadvisor ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
            </div>
					</div>
          <h3 *ngIf="otas?.length == 0" class="no-margin" style="padding-bottom: 10px; font-weight: 600; padding-left: 5px;">{{ 'rates_and_packages.link_channel.no_channels_connected' | translate }}</h3>
        </div>
			</div>
		</div>
		<div class="row  card" *ngIf="!package_data" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
			<div class="col-md-12">
        <h3 style="margin: 0px;">{{ 'rates_and_packages.link_channel.NoPreviewAvailable' | translate }}</h3>
      </div>
		</div>
    <div class="modal-footer oneM" *ngIf="service.is_preview == false" id="ModalFooterP" style="bottom: -50px;right: 10px!important">
      <a class="btn btn-danger btn-lg btn-hov" data-dismiss="modal" aria-label="Close" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">{{ 'rates_and_packages.preview_package.cancel' | translate }}</a>
    </div>
    <div class="modal-footer twoM" *ngIf="service.is_preview == true" style="right: 10px!important;bottom: -50px;">
      <a class="btn btn-danger btn-lg btn-hov" data-dismiss="modal" aria-label="Close" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">{{ 'rates_and_packages.preview_package.cancel' | translate }}</a>
    </div>
  </div>
</div>



	<div *ngIf="showImg" class="lightHouse">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <button type="button" (click)="hideImg()" class="lightHouse-btn"> <i class="fa fa-times"></i> </button>
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null" attr.data-slide-to="{{i}}">
        <img [src]="api.imgUrl+im.image" alt="Room Image" class="hovo">
      </li>
    </ol>
    <div class="carousel-inner">
      <div class="item" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null">
        <div> <img [src]="api.imgUrl+im.image" alt="Room Image"> </div>
      </div>
      <a class="left carousel-control" href="#myCarousel" data-slide="prev"> <span class="glyphicon glyphicon-chevron-left"></span> <span class="sr-only">Previous</span> </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next"> <span class="glyphicon glyphicon-chevron-right"></span> <span class="sr-only">Next</span> </a>
    </div>
    </div>
  </div>
</div>
