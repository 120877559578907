import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-internal-channels',
  templateUrl: './internal-channels.component.html',
  styleUrls: ['./internal-channels.component.scss']
})
export class InternalChannelsComponent implements OnInit {

  @Input('internal_channels') internal_channels: any;
  @Output() otaSettingModal = new EventEmitter<{ id: any, connected: any }>();
  darkMode: boolean = false;

  constructor(public helper: HelperService) { }

  ngOnInit() {
    console.log(this.internal_channels, "internal_channels");
    this.checkDarkMode();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  ota_setting_modal(id, connected) {
    this.otaSettingModal.emit({ id: id, connected: connected });
  }

  mat_change($event, status, id) {

  }
}
