<form [formGroup]="promo_form" (ngSubmit)="onSubmit()" id="SP">
<!-- <div class="alert alert-info alert-dismissible show" role="alert" id="grab-11" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
    {{ service.is_edit == true ? "Edit Event" : "Create Event" }}
<button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <strong id="strong">&#x2716;</strong>
</button>
</div> -->
<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
<a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">{{ 'rates_and_packages.link_to_ota.rates_and_packages' | translate }}</a>
<a href="" class="btn btn-link active">{{ 'rates_and_packages.link_channel.promo_code_allocation' | translate }}</a>
</div>

<div>
    
    <div>
        <div class="row" style="margin-left: 0px;margin-right: 0px;">

           <div class="col-md-12 " style="padding-left: 0px;padding-right: 0px">
                <div class="col-md-6" *ngFor="let pd of promos" style="padding-left: 0px;padding-right:5px;margin-bottom:5px">
                        <div class="margin-fix" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
                            <details open>
                                <summary>
                                    <span class="create-head" [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
                                        {{pd?.channel.name}}
                                    </span>
                                    <span class="icon-span">
                                        <img src="assets/img/manage-rooms/promodelete.png" class="delete-img" (click)="deletePromo(pd.id)"/>
                                        <img src="assets/img/reports/button_down.png" class="icon-img"/>
                                    </span>
                                </summary>
                                <div class="create-main">
                                    <div>
                                        <div class="create-div">
                                            <div class="row " style="padding: 0px 15px;  margin-top: 5px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                    <!-- <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.promo_title' | translate }}</span> -->
                                                    <div class="border-style color-4 bc1">
                                                        <input type="text" class="input form-control" readonly placeholder="{{ 'rates_and_packages.link_channel.promo_title' | translate }}"
                                                         style="outline: none;" value="{{pd?.promo?.promo_name}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                    </div>
                                                    
                                                </div>
        
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                    <!-- <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.promo_code' | translate }}</span> -->
                                                    <div class="border-style color-4 bc2">
                                                        <input type="text" class="input form-control" readonly placeholder="{{ 'rates_and_packages.link_channel.promo_code' | translate }}"
                                                            style="outline: none;" value="{{pd?.promo?.promo_code}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="row " style="padding: 0px 15px; margin-top: 3px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                    <!-- <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.set_counter' | translate | translate }}</span> -->
                                                    <div class="border-style color-4 bc3">
                                                        <input type="text" class="input form-control" readonly placeholder="{{ 'rates_and_packages.link_channel.set_counter' | translate }}"
                                                         style="outline: none;" value="{{pd?.promo?.counter}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                    </div>
                                                </div>
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                    <!-- <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.set_counter' | translate }}</span> -->
                                                    <div class="border-style color-4 bc4">
                                                        <input type="text" class="input form-control" readonly placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }}"
                                                         style="outline: none;" value="{{pd?.promo?.discount}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="row " style="padding: 0px 15px; margin-top: 3px;">
                                                <!-- <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                    <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.discount' | translate }}</span>
                                                    <div class="border-style color-4 bc5" style="height: 30px;">
                                                        <input type="text" class="input form-control" readonly placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }}"
                                                         style="outline: none;height: 30px;" value="{{pd.promo.discount}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                    </div>
                                                </div> -->
                                                <!-- <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                    <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.duration' | translate }}</span>
                                                    <div class="border-style color-4 bc6" style="height: 30px;">
                                                        <input type="text" class="input form-control" readonly
                                                            placeholder="{{ 'rates_and_packages.link_channel.duration' | translate }}" style="outline: none;height: 30px;" value="{{pd.date_from}}, {{pd.date_to}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                            >
                                                    </div>
                                                </div> -->
                                            </div>
        
                                            <!-- <div class="row " style="padding: 0px 15px; margin-top: 3px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                    <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.email' | translate }}</span>
                                                    <div class="border-style color-4 bc7" style="height: 30px;">
                                                        <input type="text" class="input form-control" readonly
                                                            placeholder="{{ 'rates_and_packages.link_channel.email' | translate }}" style="outline: none;height: 30px;" value="{{pd.email}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                            >
                                                    </div>
                                                </div>
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                    <span style="color: #0c236c;text-indent: 10px;padding-left: 10px">{{ 'rates_and_packages.link_channel.cell_no' | translate }}.</span>
                                                    <div class="border-style color-4 bc8" style="height: 30px;">
                                                        <input type="text" class="input form-control" readonly
                                                            placeholder="{{ 'rates_and_packages.link_channel.cell_no' | translate }}." style="outline: none;height: 30px;" value="{{pd.cell_no}}" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                            >
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
        
                                </div>
                            </details>
                        </div>
                </div>
                
                <div class="col-md-6 create-promo-div" style="padding-left: 0px;padding-right:5px;margin-bottom:5px">
                    <div class="margin-fix" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
                        <details open>
                            <summary>
                                <span class="create-head" [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
                                    <!-- {{ 'rates_and_packages.link_channel.create' | translate }} -->
                                    <i class="fas fa-info-circle totlo" (mouseover)="toloc()" (mouseout)="toloc()"></i>
                                </span>
                                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" *ngIf="tolo == true">
                                    <p style="    margin: 0px;">Allot promo discount to your travel partner.</p> 
                                  </span>
                                <span class="icon-span btn-hov" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'">
                                    <img src="assets/img/manage-rooms/createp.png" class="icon-img "/>
                                </span>
                            </summary>
                            <div class="create-main">
                                
                                    <div>
                                        <div class="create-div">
                                            <div class="row " style="padding: 0px 15px;  margin-top: 5px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                <div class="border-style color-4 bc1">
                                                    <ng-select placeholder="Select Promo" formControlName="promo_id" [items]="oldPromos" bindLabel="promo_name" bindValue="id" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"></ng-select>
                                                </div>
                                                <div *ngIf="submitted && f.promo_id.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.promo_id.errors.required">Select Promo Code</strong>
                                                </div>
                                                </div>
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                <div class="border-style color-4 bc2">
                                                    <ng-select placeholder="{{ 'rates_and_packages.link_channel.select_channel' | translate }}" [(ngModel)]="channel_id" formControlName="channel_id" [items]="channels" bindLabel="name" bindValue="id" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"></ng-select>
                                                </div>
                                                <div *ngIf="submitted && f.channel_id.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.channel_id.errors.required">{{ 'rates_and_packages.link_channel.allocation_required' | translate }}</strong>
                                                </div>
                                                </div>
                                            </div>
        
                                            <!-- <div class="row " style="padding: 0px 15px; margin-top: 5px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                <div class="border-style color-4 bc3">
                                                    <input type="text" class="input form-control"
                                                        placeholder="{{ 'rates_and_packages.link_channel.promo_code' | translate }}" style="outline: none;" 
                                                        formControlName="promo_code" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"               
                                                        >
                                                </div>
                                                <div *ngIf="submitted && f.promo_code.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.promo_code.errors.required">{{ 'rates_and_packages.link_channel.promo_code_required' | translate }}</strong>
                                                </div>
                                                </div>
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                <div class="border-style color-4 bc4">
                                                    <input type="text" class="input form-control"
                                                        placeholder="{{ 'rates_and_packages.link_channel.set_counter' | translate }}" style="outline: none;"
                                                        formControlName="counter" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                        >
                                                </div>
                                                <div *ngIf="submitted && f.counter.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.counter.errors.required">{{ 'rates_and_packages.link_channel.promo_counter_required' | translate }}</strong>
                                                </div>
                                                </div>
                                            </div> -->
        
                                            <!-- <div class="row " style="padding: 0px 15px; margin-top: 5px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                <div class="border-style color-4 bc5">
                                                    <input type="text" class="input form-control"
                                                        placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }} (%)" style="outline: none;" 
                                                        formControlName="discount" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"            
                                                        >
                                                </div>
                                                <div *ngIf="submitted && f.discount.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.discount.errors.required">{{ 'rates_and_packages.link_channel.discount_is_required' | translate }}</strong>
                                                </div>
                                                </div>
                                                <div class="col-md-6 calendar-top f-calendar" style="padding: 0px 5px 0px 2px;">
                                                <div class="border-style color-4 bc6" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                  
                                                            <div class="input form-control" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                                <input type="hidden" formControlName="date_to" name="date_to" [(ngModel)]="date_to" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                                <input type="hidden" formControlName="date_from" name="date_from" [(ngModel)]="date_from" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                                    <input type="text" 
                                                                    ngxDaterangepickerMd 
                                                                    class="form-control date_range_picker arrival-lenght"
                                                                    [(ngModel)]="durationDate"
                                                                    placeholder="{{ 'rates_and_packages.link_channel.duration' | translate }} {{ 'rates_and_packages.link_channel.days' | translate }}" 
                                                                    [singleDatePicker]="false"
                                                                    [autoApply]="true"
                                                                    (change)="calendar_change(durationDate)"
                                                                    
                                                                    [locale]="{format: 'DD, MMMM YYYY', separator: '  To  '}"
                                                                    style="width: 100% !important; padding: 2px;float: right;"
                                                                    
                                                                    formControlName="date"
                                                                    [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                                    >
                                                            </div>
                                                </div>
                                                <div *ngIf="submitted && f.date_from.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.date_from.errors.required">{{ 'rates_and_packages.link_channel.promo_duration_required' | translate }}</strong>
                                                </div>
                                                </div>
                                            </div> -->
        
                                            <!-- <div class="row " style="padding: 0px 15px; margin-top: 5px;">
                                                <div class="col-md-6" style="padding: 0px 3px 0px 5px;">
                                                <div class="border-style color-4 bc7">
                                                    <input type="text" class="input form-control"
                                                        placeholder="{{ 'rates_and_packages.link_channel.email' | translate }}" style="outline: none;" 
                                                        formControlName="email" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                                        >
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.email.errors.required">{{ 'rates_and_packages.link_channel.email_is_required' | translate }}</strong>
                                                    <strong *ngIf="f.email.errors.email">{{ 'rates_and_packages.link_channel.correct_email_is_required' | translate }}</strong>                                                    
                                                </div>
                                                </div>
                                                <div class="col-md-6" style="padding: 0px 5px 0px 2px;">
                                                <div class="border-style color-4 bc8">
                                                    <input type="text" class="input form-control"
                                                        placeholder="{{ 'rates_and_packages.link_channel.cell_no' | translate }}" style="outline: none;"
                                                        formControlName="cell_no" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" 
                                                        >
                                                </div>
                                                <div *ngIf="submitted && f.cell_no.errors" class="small text-danger no-margin invalid-feedback">
                                                    <strong *ngIf="f.cell_no.errors.required">{{ 'rates_and_packages.link_channel.cell_number_is_required' | translate }}</strong>
                                                </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                            </div>
                            </details>
                        </div>
                    </div>
           </div>

        </div>

    </div>



</div>

<div class="modal-footer text-right" id="ModalFooterP" style="    bottom: -50px;right: 10px!important">
    <button class="btn btn-info btn-lg colorg btn-hov" [ngClass]="darkMode ? 'card-dark-btn-g_save' : ' card-null '">{{ 'rates_and_packages.setup_package.save' | translate }}</button>
    <button class="btn btn-danger btn-lg btn-hov" aria-label="Close" data-dismiss="modal" type="button" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">{{ 'rates_and_packages.setup_package.cancel' | translate }}</button>
  </div>
      
</form>