<div class="dd" (click)="helper.selectMe = 3" [ngStyle]="{'opacity': helper.selectMe == 3 ? '1' : '0.3'}">
    <div class="head" [ngClass]="darkMode ? 'head_dark':'head'">{{ 'manage_ota.linked_otas' | translate }}</div>
    <div class="drag-list ota_tabs scroll-class " style="padding-top: 5px;"
        [ngStyle]="{ 'pointer-events': helper.selectMe == 3 ? 'auto' : 'none'}">
        <div class="badger scroll no_scroll">
            <div class="grid_20 ">
                <div id="drag-box1-ota" class=" drag-list">
                    <div id="drag-item1-ota" *ngFor="let external of external_channels">
                        <div class="shade shade-{{ external.image.split('.')[0]  }} ">
                            <div class="border-height">
                                <div class="dark_gradient" [ngClass]="darkMode ? 'dark_gradient_dark':'dark_gradient'">
                                    <div class="uper">
                                        <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + external.image : external.image }}"
                                            alt="" class="ota-icon">
                                        <div class="toggle-btn pull-right">
                                            <mat-slide-toggle
                                                (change)="mat_change($event , external.pr_status , external.id)"
                                                [color]="'accent'" [ngClass]="darkMode ? 'darkmatetoggle' : 'null'"
                                                [checked]="external.pr_status == 'Active' ? true : false"></mat-slide-toggle>
                                        </div>
                                    </div>
                                    <div class="between customo">
                                        <div class="leftOta">
                                            <p class="pi margin0 text-align chS hovChan"
                                                (click)="ota_setting_modal(external.property_channel_id,external.connected)"
                                                [ngClass]="darkMode? 'pinki' : 'null'">{{
                                                'rates_and_packages.extra.channel_setting' | translate}}</p>
                                            <p class="margin0 text-right cogI hovChan"
                                                (click)="ota_setting_modal(external.property_channel_id,external.connected)"
                                                [ngClass]="darkMode? 'pinki' : 'null'"> <i
                                                    class="fa fa-cog hovChan"></i> </p>
                                        </div>
                                        <div class="RightOta">
                                            <p class="p2 margin0 text-align coMM"
                                                [ngClass]="darkMode? 'pinki' : 'null'">{{
                                                'manage_ota.commission' | translate }}</p>
                                            <p class="margin0 text-right percentT"
                                                [ngClass]="darkMode? 'pinki' : 'null'">{{
                                                external.commission }}<span class="">%</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lower" [ngClass]="darkMode ? 'lower_dark':'lower'">
                                    <a [routerLink]="['/app/rates_packages']"
                                        [ngClass]="darkMode ? 'span-weight_dark':'span-weight'"
                                        [queryParams]="{filter_channel: external.id}">{{ 'manage_ota.packages_linked' |
                                        translate }}
                                        <span class="num" [ngClass]="darkMode ? 'num_dark':'num'"> {{
                                            external.package_count
                                            }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>