import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class ApiService {

  base_url = "https://cmtest.pointclickintegrate.com/";
  // base_url = "https://cmtest.pointclickintegrate.com/"
  base_url2 = "https://cmtest.pointclickintegrate.com/public/";

  imgUrl: string = 'https://cmtest.pointclickintegrate.com/public/app/images/';
  response;
  constructor(
    private http: HttpClient
  ) { }

  get(endpoint: String, token){

    var header;
    if(token == true){
        let auth_token = localStorage.getItem('auth_token');
        let bearer_token = 'Bearer ' + auth_token;

        header = new HttpHeaders();
        header = header.set("Authorization", bearer_token);
    }

    return this.http.request('get', this.base_url + endpoint, {headers: header, observe: 'response'});
  }

  post(endpoint: String, data: any, token){

    var header;
    if(token == true){
        let auth_token = localStorage.getItem('auth_token');
        let bearer_token = 'Bearer ' + auth_token;

        header = new HttpHeaders();
        header = header.set("Authorization", bearer_token);
    }
    // console.log(data);

    return this.http.request('post', this.base_url + endpoint, {body: data, headers: header, observe: 'response'})
  }

  imgPost(endpoint: String, data: any, token){

    var header;
    if(token == true){
        let auth_token = localStorage.getItem('auth_token');
        let bearer_token = 'Bearer ' + auth_token;
        header = new HttpHeaders();
        header = header.set("Authorization", bearer_token);
    }
    // console.log(data);
    return this.http.request('post', this.base_url2 + endpoint, {body: data, headers: header, observe: 'response'})
  }

  put(endpoint: String, data: any){
    return this.http.put(this.base_url + endpoint, data);
  }

  delete(endpoint: String, data: any){
    return this.http.delete(this.base_url + endpoint, data);
  }



}
