<div *ngIf="message?.type == 'confirm'" id="confirmationModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body" [ngClass]="darkMode ? 'dark-border-div' : ' null '">
            <div class="alert alert-info alert-dismissible show" role="alert" [ngClass]="darkMode ? 'smooth-dark' : ' null '">
                <strong>{{ message?.title }}</strong>
                <a href="" class="close" data-dismiss="modal" aria-label="Close">
                    <strong id="cross">&#x2716;</strong>
                </a>
            </div>

            <div class="alert alert-danger text-center" [ngClass]="darkMode ? 'bredcurm-dark' : ' null '">
                <table>
                    <tr>
                        <td>
                            <img src="assets/img/alert/error.png">
                        </td>
                        <td>
                            <ul class="alert-list">
                                <li *ngFor="let instruction of message.instructions">{{ instruction }}</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="border-style" [ngClass]="darkMode ? 'border-style ' : 'dark-border-style'">
                        <div class="element" [ngClass]="darkMode ? 'dark-element' : ' element '">
                            <label>{{ message?.text }}</label><br>
                            <strong>
                                <div *ngIf="message.permissions">
                                    <small>
                                        {{ 'rates_and_packages.delete_package.permission' | translate }} : {{ message.permissions }}<br>
                                    </small>
                                </div>
                                <small *ngIf="message.created_on" class="text-danger">{{ 'rates_and_packages.delete_package.created_on' | translate }} {{ message.created_on }}</small>
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pl-63" [ngClass]="darkMode ? 'dark-btn' : 'null'">
                    <button type="button" class="btn btn-primary btn-block btn-lg hov" data-dismiss="modal" (click)="message.siFn()">{{ 'rates_and_packages.delete_package.yes' | translate }}</button>
                </div>
                <div class="col-md-3 pl-26" [ngClass]="darkMode ? 'dark-btn' : 'null'">
                    <button type="button" class="btn btn-danger btn-block btn-lg hov" data-dismiss="modal">{{ 'rates_and_packages.delete_package.no' | translate}}</button>
                </div>
            </div>

        </div>
      </div>

    </div>
</div>
