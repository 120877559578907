<div class="alert alert-danger breadcrum" style="margin-bottom: 5px;"
  [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
  <ul class="nav navbar-nav">
    <li class="active">
      <a data-toggle="tab" href="#all_arrivals" class="btn btn-link active" (click)="all_arrivals_pagi()">{{
        "arrivals.arrivals" | translate }}</a>
    </li>
    <li>
      <a data-toggle="tab" href="#todays_arrivals" class="btn btn-link" (click)="today_arrivals_pagi()">{{
        "arrivals.today_arrival" | translate }}</a>
    </li>
    <li>
      <a data-toggle="tab" href="#last_week_arrivals" class="btn btn-link" (click)="lastweek_arrivals_pagi()">{{
        "arrivals.next_week_arrival" | translate }}</a>
    </li>
    <li>
      <a data-toggle="tab" href="#in_house" class="btn btn-link" (click)="in_house_pagi()">{{ "arrivals.in_house" |
        translate }} </a>
    </li>


    <!-- <li>
      <a data-toggle="tab" href="#in_house" class="btn btn-link" (click)="in_house_pagi()">In House</a>
    </li> -->
  </ul>

  <div class="col-md-1 pdf-pic-col">
    <img src="assets/img/newstaticicons/report/download.svg" class="pdf-img btn-hov"
      [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'" style="cursor: pointer; padding: 5px;" (click)="pdf_css()" />
  </div>

  <!-- radio button starts -->
  <!-- <div class="div" style="    width: 30%;  display: flex;  float: right; padding:15px;">
    <div class="" [ngClass]="darkMode?'radio-box_dark':'radio-box'">
      <label data-toggle="tooltip" title="High" class="control-label radio-style chbx ellipses  hovbulit"
        [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('radio2','radio2T')"
        (mouseout)="colorRadio('radio2','radio2T')">
        <input type="radio" class="pull-left radio" name="demand_type" value="High" formControlName="demand_type">
        <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
          id="radio2"></span>
        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="radio2T">
          Checked In date
        </span>
      </label>
    </div>

    <div class="" [ngClass]="darkMode?'radio-box_dark':'radio-box'">
      <label data-toggle="tooltip" title="Low" class="control-label radio-style chbx ellipses hovbulit"
        [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('radio3','radio3T')"
        (mouseout)="colorRadio('radio3','radio3T')" b>
        <input type="radio" class="pull-left radio" name="demand_type" value="Low" formControlName="demand_type">
        <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
          id="radio3"></span>
        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="radio3T">Booked Date</span>
      </label>
    </div>
  </div> -->

  <!-- radio button ends -->
</div>

<div class="tab-content capture " id="capture">

  <!-- Todays Arrivals -->
  <div id="todays_arrivals" class="tab-pane fade mt5"
    [ngStyle]="{'box-shadow':pdfgen == true ? 'none' : 'box-shadow: 1px 2px 6px #00000040;' }"
    [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
    <div class="card-table table-responsive" id="card" [ngClass]="[pdfgen == true ? 'card-0' : 'card-090']">

      <div class="col-md-12 blue-head" style="padding: 0px;">
        <ul class="blue-ul">
          <li style="width: 10%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.Booked_by" | translate }}</a> -->
            <a>Channel</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.guest_name" | translate }}</a> -->
            <a> {{ "arrivals.list.Guest" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.reservation_id" | translate }}</a> -->
            <a>{{"arrivals.list.Booking_ID" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{"arrivals.list.check_in" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{"arrivals.list.check_out" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{"arrivals.list.date_booked" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <a> {{ "arrivals.list.Payment" | translate }}</a>
          </li>
          <li style="width: 15%">
            <a>{{ "arrivals.list.Room" | translate }}</a>
          </li>
        </ul>
      </div>

      <div id="todays-arri" class="scroll no_scroll">
        <div *ngFor="let arrival of today_arrivals"
          class="col-md-12 border-div {{ setBorderArr(arrival) }}-{{darkMode ? 'borderdark' : 'border'}}"
          style="page-break-before: always; padding: 0px;" id="mouse"
          [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
          <ul class="grey-ul nav1">
            <li style="width: 10%;">
              <a>
                <img *ngIf="arrival?.booking?.channel?.type == 'External'"
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(arrival?.booking.channel_image) : imgr(arrival?.booking.channel_image) }}.svg"
                  alt="Channel image" class="external-img" />
                <img *ngIf="arrival?.booking?.channel?.type == 'Internal'"
                  src="assets/img/svgicons/channels/{{imgr(arrival?.booking.channel_image)}}.svg" alt="Channel image"
                  class="internal-img" />
              </a>
            </li>
            <li style="width: 13.5%;">
              <a data-target="#summary" (click)="show_arrival(arrival)" style="cursor: pointer;"
                class="greytxthov">{{arrival?.booking?.guest_firstname}}</a>
            </li>
            <li style="width: 14.5%;">
              <a style="cursor: pointer;" class="greytxthov">{{arrival?.booking?.reservation_id}}</a>
            </li>
            <li style="width: 10.5%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{chkIn(arrival)}}.svg" alt="" class=""
                  style="height: 35px;" />
                {{ arrival.check_in | date: "dd MMM" }}
              </a>
            </li>
            <li style="width: 12%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{departurPending(arrival)}}.svg" alt="" class=""
                  style="height: 35px;" /> {{ arrival.check_out | date: "d MMM" }}</a>
            </li>
            <li style="width: 11.5%;">
              <a class="greytxt">{{ arrival?.booking?.created_at | date : 'd MMM | h:mm '}} </a>
            </li>
            <li style="width: 13%;">
              <!-- <a>
                  <button type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'" [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0))' : 'linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0))' }">
                    Hold
                  </button>
                </a> -->

              <a>
                <button
                  *ngIf=" arrival?.booking?.payment_status == 'unpaid' && arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf="arrival?.booking?.payment_status == 'paynow' && arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>

                <button *ngIf="arrival?.booking?.payment_status == 'paid' && arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf="arrival?.booking?.payment_status == 'unpaid' && arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf="arrival?.booking?.payment_status == 'paid' && arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf="arrival?.booking?.payment_status == 'paynow' && arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>
              </a>

            </li>
            <li style="width: 13.5%; float: right;">
              <a class="greytxt">{{arrival?.room?.roomtype?.name}}</a>
            </li>
          </ul>
        </div>
        <!-- arrival skeleton (working on saud 👇) -->
        <ng-container *ngIf="loader">
          <app-arrival-skeleton></app-arrival-skeleton>
        </ng-container>
        <nav aria-label="Page navigation example" style="text-align: center;" id="paginav">
          <div style="margin-left: 45%;">
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px; height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="first_page(1); selectedIndex2 = 0">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="prev()">
                    <span aria-hidden="true">&lsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div style="float: left;" class="cuspagidiv" id="cuspagidiv2">
              <div class="page-item cusPagi" *ngFor="let numbers of pagi2;let i = index" id="active{{numbers.page_no}}"
                (click)="selectedIndex2 = i" [class.active]="selectedIndex2 == i"
                (click)="paginationPage(numbers.url, numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a>
              </div>
            </div>

            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="next()">
                    <span aria-hidden="true">&rsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="last_page()">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Todays Arrivals -->

  <!-- Last Week -->
  <div id="last_week_arrivals" class="tab-pane fade mt5"
    [ngStyle]="{'box-shadow':pdfgen == true ? 'none' : 'box-shadow: 1px 2px 6px #00000040;' }"
    [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
    <div class="card-table table-responsive" id="card" [ngClass]="[pdfgen == true ? 'card-0' : 'card-090']">
      <div class="col-md-12 blue-head" style="padding: 0px;">
        <ul class="blue-ul">
          <li style="width: 10%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Booked_by" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.guest_name" | translate }}</a> -->
            <a>{{ "arrivals.list.Guest" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.reservation_id" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_in" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_out" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.date_booked" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Payment" | translate }}</a>
          </li>
          <li style="width: 15%">
            <a>{{ "arrivals.list.Room" | translate }}</a>
          </li>
        </ul>
      </div>
      <div id="last-week-arri" class="scroll no_scroll">
        <div *ngFor="let last_arrival of lastweek"
          class="col-md-12 border-div {{ setBorderArr(last_arrival) }}-{{darkMode ? 'borderdark' : 'border'}}"
          style="page-break-before: always; padding: 0px;" id="mouse"
          [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
          <ul class="grey-ul nav1">
            <li style="width: 10%;">
              <a>
                <img *ngIf="last_arrival?.booking?.channel?.type == 'External'"
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(last_arrival?.booking.channel_image) : imgr(last_arrival?.booking.channel_image) }}.svg"
                  alt="Channel image" class="external-img" />
                <img *ngIf="last_arrival?.booking?.channel?.type == 'Internal'"
                  src="assets/img/svgicons/channels/{{imgr(last_arrival?.booking.channel_image)}}.svg"
                  alt="Channel image" class="internal-img" />
              </a>
            </li>
            <li style="width: 13.5%;">
              <a data-target="#summary" (click)="show_arrival(last_arrival)" style="cursor: pointer;"
                class="greytxthov">{{last_arrival?.booking?.guest_firstname}}</a>
            </li>
            <li style="width: 14.5%;">
              <a style="cursor: pointer;" class="greytxthov">{{ last_arrival?.booking?.reservation_id }}</a>
            </li>

            <li style="width: 10.5%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{chkIn(last_arrival)}}.svg" alt="" class=""
                  style="height: 35px;" />
                {{ last_arrival.check_in | date: "dd MMM" }}
              </a>
            </li>
            <li style="width: 12%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{departurPending(last_arrival)}}.svg" alt=""
                  class="" style="height: 35px;" /> {{ last_arrival.check_out | date: "d MMM" }}</a>
            </li>
            <li style="width: 11.5%;">
              <a class="greytxt">{{ last_arrival?.booking?.created_at | date : 'd MMM | h:mm '}} </a>
            </li>
            <li style="width: 13%;">
              <a>
                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'unpaid' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paynow' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paid' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'unpaid' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paid' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paynow' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>
              </a>
            </li>
            <li style="width: 13.5%; float: right;">
              <a class="greytxt">{{last_arrival?.room?.roomtype?.name}}</a>
            </li>
          </ul>
        </div>
        <!-- arrival skeleton (working on saud 👇) -->
        <ng-container *ngIf="loader">
          <app-arrival-skeleton></app-arrival-skeleton>
        </ng-container>
        <nav aria-label="Page navigation example" style="text-align: center;" id="paginav">
          <div style="margin-left: 45%;">
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="first_page(1);selectedIndex3 = 0">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="prev()">
                    <span aria-hidden="true">&lsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div style="float: left;" class="cuspagidiv" id="cuspagidiv3">
              <div class="page-item cusPagi" *ngFor="let numbers of pagi3;let i = index" id="active{{numbers.page_no}}"
                (click)="selectedIndex3 = i" [class.active]="selectedIndex3 == i"
                (click)="paginationPage(numbers.url, numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a>
              </div>
            </div>

            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="next()">
                    <span aria-hidden="true">&rsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="last_page()">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Last Week -->

  <!-- All Arrivals -->
  <div id="all_arrivals" class="tab-pane fade in active mt5 "
    [ngStyle]="{'box-shadow':pdfgen == true ? 'none' : 'box-shadow: 1px 2px 6px #00000040;' }"
    [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
    <div class="card-table table-responsive " id="card" [ngClass]="[pdfgen == true ? 'card-0' : 'card-090']">
      <div class="col-md-12 blue-head" style="padding: 0px;">
        <ul class="blue-ul">
          <li style="width: 10%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.channel" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.guest_name" | translate }}</a> -->
            <a>{{ "arrivals.list.Guest" | translate }}</a>
          </li>
          <li style="width: 10%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.reservation_id" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_in" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_out" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.date_booked" | translate }}</a>
          </li>
          <li style="width: 12%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Payment" | translate }}</a>
          </li>
          <li style="width: 12%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Payment" | translate }}</a>
          </li>
          <li style="width: 9%;">
            <a>{{ "arrivals.list.total_amount" | translate }}</a>
          </li>
        </ul>
      </div>
      <div id="all-arri" class="scroll no_scroll">
        <!-- class="scroll no_scroll" -->
        <div *ngFor="let arrival of arrivals" class="col-md-12 border-div
         {{ setBorder(arrival) }}-{{darkMode ? 'borderdark' : 'border'}}
         " style="page-break-before: always; padding: 0px;" id="mouse"
          [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
          <ul class="grey-ul nav1">
            <li style="width: 10%;">
              <a>
                <img *ngIf="arrival?.channel.type == 'External'"
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(arrival?.channel.image) : imgr(arrival?.channel.image) }}.svg"
                  alt="Channel image" class="external-img" />
                <img *ngIf="arrival?.channel.type == 'Internal'"
                  src="assets/img/svgicons/channels/{{imgr(arrival?.channel.image)}}.svg" alt="Channel image"
                  class="internal-img" />
              </a>
            </li>
            <li style="width: 13.5%;">
              <a data-target="#summary" (click)="show_arrival(arrival)" style="cursor: pointer;" class="greytxthov">{{
                arrival.guest_firstname }}</a>
            </li>
            <li style="width: 10.5%;">
              <a data-target="#myModal" (click)="show_arrivall(arrival)" style="cursor: pointer;" class="greytxthov">{{
                arrival.reservation_id }}</a>
            </li>
            <li style="width: 10.5%;">
              <a>
                <img src="assets/img/newstaticicons/arrivels/newicons/{{chkIn(arrival)}}.svg" alt="" class=""
                  style="height: 35px;" />
                {{ arrival.date_arrival | date: "d MMM" }}
              </a>
            </li>
            <li style="width: 11.5%;">
              <a>
                <img src="assets/img/newstaticicons/arrivels/newicons/{{departurPending(arrival)}}.svg" alt="" class=""
                  style="height: 35px;" /> {{ arrival.date_departure | date: "d MMM" }}
              </a>
            </li>
            <li style="width: 11.5%;">
              <a class="greytxt">{{ arrival.created_at | date : 'd MMM | h:mm:ss '}} </a>
            </li>
            <li style="width: 12%;">
              <a>
                <button *ngIf=" arrival.payment_status == 'unpaid' && arrival.status != 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf=" arrival.payment_status == 'paynow' && arrival.status != 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>

                <button *ngIf=" arrival.payment_status == 'paid' && arrival.status != 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf=" arrival.payment_status == 'unpaid' && arrival.status == 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf=" arrival.payment_status == 'paid' && arrival.status == 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf=" arrival.payment_status == 'paynow' && arrival.status == 'cancelled' " type="submit"
                  class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  {{ "arrivals.list.PayNow" | translate }}
                </button>
              </a>
            </li>
            <li style="width: 12%;">
              <a>
                <button *ngIf=" arrival.status == 'hold' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#fd216c, #c11953, #731132)' : 'linear-gradient(#fd216c, #c11953, #731132)' }">
                  {{ "arrivals.list.Hold" | translate }}
                </button>

                <button *ngIf="arrival.status == 'confirm' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.Confirmed" | translate }}
                </button>

                <button *ngIf=" arrival.status == 'cancelled' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(249 25 25), rgb(115 6 35))' : 'linear-gradient(rgb(249 25 25), rgb(115 6 35))' }">
                  {{ "arrivals.list.cancelled" | translate }}
                </button>

                <!-- <button *ngIf=" arrival.status == 'modified' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(0 207 241), rgb(8 95 121))' : 'linear-gradient(rgb(0 207 241), rgb(8 95 121))' }">
                  Modified
                </button> -->
                <button *ngIf=" arrival.status == 'modified' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(0 207 241), rgb(8 95 121))' : 'linear-gradient(rgb(0 207 241), rgb(8 95 121))' }">
                  {{ "arrivals.list.Modified" | translate }}
                </button>

                <button *ngIf=" arrival.status == 'modify' " type="submit" class="paid w-button btn-0 btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0))' : 'linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0))' }">
                  {{ "arrivals.list.Modify" | translate }}
                </button>
              </a>
            </li>
            <li style="width: 8.5%; float: right;">
              <a class="greytxt"> {{ arrival.total | currency : current_currency }}</a>
            </li>
            <!-- <div class="div" *ngFor="let rr of current_arrival?.bookingitems;">
            <li style="width: 8.5%; float: right;">
              <a class="greytxt">$ {{rr?.pricesjson?.total_value}}</a>
            </li>
          </div> -->

          </ul>
        </div>
        <!-- arrival skeleton (working on saud 👇) -->
        <ng-container *ngIf="loader">
          <app-arrival-skeleton></app-arrival-skeleton>
        </ng-container>
        <nav aria-label="Page navigation example" style="text-align: center;" id="paginav">
          <div style="margin-left: 45%;">
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px; padding-right: 10px;padding-left: 10px; margin-right:5px"
                    (click)="first_page(1)">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="prev()">
                    <span aria-hidden="true">&lsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div style="float: left;" class="cuspagidiv" id="cuspagidiv1">
              <div class="page-item cusPagi" *ngFor="let numbers of pagi;let i = index" id="active{{numbers.page_no}}"
                (click)="selectedIndex = i" [class.active]="selectedIndex == i"
                (click)="paginationPage(numbers.url, numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a>
              </div>
            </div>

            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px; padding: 1px; padding-left: 10px; padding-right: 10px;margin-left:5px"
                    (click)="next()">
                    <span aria-hidden="true">&rsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="last_page()">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

    </div>
  </div>
  <!-- All Arrivals -->

  <!-- In House -->
  <div id="in_house" class="tab-pane fade mt5"
    [ngStyle]="{'box-shadow':pdfgen == true ? 'none' : 'box-shadow: 1px 2px 6px #00000040;' }"
    [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
    <div class="card-table table-responsive" id="card" [ngClass]="[pdfgen == true ? 'card-0' : 'card-090']">
      <div class="col-md-12 blue-head" style="padding: 0px;">
        <ul class="blue-ul">
          <li style="width: 10%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Booked_by" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <!-- <a>{{ "arrivals.list.guest_name" | translate }}</a> -->
            <a>{{ "arrivals.list.Guest" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.reservation_id" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_in" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.check_out" | translate }}</a>
          </li>
          <li style="width: 11%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.date_booked" | translate }}</a>
          </li>
          <li style="width: 14%; border-right: 1px solid white;">
            <a>{{ "arrivals.list.Payment" | translate }}</a>
          </li>
          <li style="width: 15%">
            <a>{{ "arrivals.list.Room" | translate }}</a>
          </li>
        </ul>
      </div>
      <div id="last-week-arri" class="scroll no_scroll">
        <div *ngFor="let  house of inhouse"
          class="col-md-12 border-div {{ setBorderArr(house) }}-{{darkMode ? 'borderdark' : 'border'}}"
          style="page-break-before: always; padding: 0px;" id="mouse"
          [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
          <ul class="grey-ul nav1">
            <li style="width: 10%;">
              <a>
                <img *ngIf="house?.booking?.channel?.type == 'External'"
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(house?.booking.channel_image) : imgr(house?.booking.channel_image) }}.svg"
                  alt="Channel image" class="external-img" />
                <img *ngIf="house?.booking?.channel?.type == 'Internal'"
                  src="assets/img/svgicons/channels/{{imgr(house?.booking.channel_image)}}.svg" alt="Channel image"
                  class="internal-img" />
              </a>
            </li>
            <li style="width: 13.5%;">
              <a data-target="#summary" (click)="show_arrival(house)" style="cursor: pointer;"
                class="greytxthov">{{house?.booking?.guest_firstname}}</a>
            </li>
            <li style="width: 14.5%;">
              <a style="cursor: pointer;" class="greytxthov">{{
                house?.booking?.reservation_id }}</a>
            </li>

            <li style="width: 10.5%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{chkIn(house)}}.svg" alt="" class=""
                  style="height: 35px;" />
                {{ house.check_in | date: "dd MMM" }}
              </a>
            </li>
            <li style="width: 12%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{departurPending(house)}}.svg" alt="" class=""
                  style="height: 35px;" /> {{ house.check_out | date: "d MMM" }}</a>
            </li>
            <li style="width: 11.5%;">
              <a class="greytxt">{{ house?.booking?.created_at | date : 'd MMM | h:mm '}} </a>
            </li>
            <li style="width: 13%;">
              <a>
                <button *ngIf=" house?.booking?.payment_status == 'unpaid' && house?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf=" house?.booking?.payment_status == 'paynow' && house?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  Pay Now
                </button>

                <button *ngIf=" house?.booking?.payment_status == 'paid' && house?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf=" house?.booking?.payment_status == 'unpaid' && house?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button *ngIf=" house?.booking?.payment_status == 'paid' && house?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button *ngIf=" house?.booking?.payment_status == 'paynow' && house?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  Paynow
                </button>
              </a>
            </li>
            <li style="width: 13.5%; float: right;">
              <a class="greytxt">{{house?.room?.roomtype?.name}}</a>
            </li>
          </ul>
        </div>
        <!-- arrival skeleton (working on saud 👇) -->
        <ng-container *ngIf="loader">
          <app-arrival-skeleton></app-arrival-skeleton>
        </ng-container>
        <nav aria-label="Page navigation example" style="text-align: center;" id="paginav">
          <div style="margin-left: 45%;">
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="first_page(1);selectedIndex3 = 0">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                    (click)="prev()">
                    <span aria-hidden="true">&lsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div style="float: left;" class="cuspagidiv" id="cuspagidiv4">
              <div class="page-item cusPagi" *ngFor="let numbers of pagi3;let i = index" id="active{{numbers.page_no}}"
                (click)="selectedIndex3 = i" [class.active]="selectedIndex3 == i"
                (click)="paginationPage(numbers.url, numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a>
              </div>
            </div>

            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="next()">
                    <span aria-hidden="true">&rsaquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left;">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link" aria-label="Next"
                    style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                    (click)="last_page()">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- In House -->

</div>


<!--in house start -->

<div id="in_houseee" class="tab-pane fade mt5"
  [ngStyle]="{'box-shadow':pdfgen == true ? 'none' : 'box-shadow: 1px 2px 6px #00000040;' }"
  [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
  <div class="card-table table-responsive" id="card" [ngClass]="[pdfgen == true ? 'card-0' : 'card-090']">
    <div class="col-md-12 blue-head" style="padding: 0px;">
      <ul class="blue-ul">
        <li style="width: 10%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.Booked_by" | translate }}</a>
        </li>
        <li style="width: 14%; border-right: 1px solid white;">
          <!-- <a>{{ "arrivals.list.guest_name" | translate }}</a> -->
          <a>{{ "arrivals.list.Guest" | translate }}</a>
        </li>
        <li style="width: 14%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.reservation_id" | translate }}</a>
        </li>
        <li style="width: 11%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.check_in" | translate }}</a>
        </li>
        <li style="width: 11%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.check_out" | translate }}</a>
        </li>
        <li style="width: 11%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.date_booked" | translate }}</a>
        </li>
        <li style="width: 14%; border-right: 1px solid white;">
          <a>{{ "arrivals.list.Payment" | translate }}</a>
        </li>
        <li style="width: 15%">
          <a>{{ "arrivals.list.Room" | translate }}</a>
        </li>
      </ul>
    </div>
    <!-- <div id="last-week-arri">
        <div *ngFor="let last_arrival of lastweek"
          class="col-md-12 border-div {{ setBorderArr(last_arrival) }}-{{darkMode ? 'borderdark' : 'border'}}"
          style="page-break-before: always; padding: 0px;" id="mouse"
          [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
          <ul class="grey-ul nav1">
            <li style="width: 10%;">
              <a>
                <img *ngIf="last_arrival?.booking?.channel?.type == 'External'"
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(last_arrival?.booking.channel_image) : imgr(last_arrival?.booking.channel_image) }}.svg"
                  alt="Channel image" class="external-img" />
                <img *ngIf="last_arrival?.booking?.channel?.type == 'Internal'"
                  src="assets/img/svgicons/channels/{{imgr(last_arrival?.booking.channel_image)}}.svg"
                  alt="Channel image" class="internal-img" />
              </a>
            </li>
            <li style="width: 13.5%;">
              <a data-target="#summary" (click)="show_arrival(last_arrival)" style="cursor: pointer;"
                class="greytxthov">{{last_arrival?.booking?.guest_firstname}}</a>
            </li>
            <li style="width: 14.5%;">
              <a data-target="#myModal" (click)="show_arrivall(last_arrival)" style="cursor: pointer;"
                class="greytxthov">{{ last_arrival?.booking?.reservation_id }}</a>
            </li>

            <li style="width: 10.5%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{chkIn(last_arrival)}}.svg" alt="" class=""
                  style="height: 35px;" />
                {{ last_arrival.check_in | date: "dd MMM" }}
              </a>
            </li>
            <li style="width: 12%;">
              <a>
                <img src=" assets/img/newstaticicons/arrivels/newicons/{{departurPending(last_arrival)}}.svg" alt=""
                  class="" style="height: 35px;" /> {{ last_arrival.check_out | date: "d MMM" }}</a>
            </li>
            <li style="width: 11.5%;">
              <a class="greytxt">{{ last_arrival?.booking?.created_at | date : 'd MMM | h:mm '}} </a>
            </li>
            <li style="width: 13%;">
              <a>
                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'unpaid' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paynow' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  Pay Now
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paid' && last_arrival?.booking?.status != 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'unpaid' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' : 'linear-gradient(rgb(230 60 2),#e45b00,#943b00 )' }">
                  {{ "arrivals.list.unpaid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paid' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? ' linear-gradient(#0ade24,#087115 )' : 'linear-gradient(180deg, #00e107, #028807)' }">
                  {{ "arrivals.list.paid" | translate }}
                </button>

                <button
                  *ngIf=" last_arrival?.booking?.payment_status == 'paynow' && last_arrival?.booking?.status == 'cancelled' "
                  type="submit" class="paid w-button btn-0 btn-hov" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov'"
                  [ngStyle]="{'background':darkMode ? 'linear-gradient(#7340ce, #501cad, #5b34a0)' : 'linear-gradient(#7340ce, #501cad, #5b34a0)' }">
                  Paynow
                </button>
              </a>
            </li>
            <li style="width: 13.5%; float: right;">
              <a class="greytxt">{{last_arrival?.room?.roomtype?.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <nav aria-label="Page navigation example" style="text-align: center;" id="paginav">
        <div style="margin-left: 45%;">
          <div style="float: left;">
            <ul class="pagination" id="myDIVActive">
              <li class="page-item">
                <a class="page-link" aria-label="Previous"
                  style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                  (click)="first_page(1);selectedIndex3 = 0">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                </a>
              </li>
            </ul>
          </div>
          <div style="float: left;">
            <ul class="pagination" id="myDIVActive">
              <li class="page-item">
                <a class="page-link" aria-label="Previous"
                  style="font-size: 20px;height: 32px;padding: 1px;padding-right: 10px;padding-left: 10px;margin-right:5px"
                  (click)="prev()">
                  <span aria-hidden="true">&lsaquo;</span>
                  <span class="sr-only">{{ 'rates_and_packages.extra.previous' }}</span>
                </a>
              </li>
            </ul>
          </div>

          <div style="float: left;" class="cuspagidiv" id="cuspagidiv3">
            <div class="page-item cusPagi" *ngFor="let numbers of pagi3;let i = index" id="active{{numbers.page_no}}"
              (click)="selectedIndex3 = i" [class.active]="selectedIndex3 == i"
              (click)="paginationPage(numbers.url, numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a></div>
          </div>

          <div style="float: left;">
            <ul class="pagination" id="myDIVActive">
              <li class="page-item">
                <a class="page-link" aria-label="Next"
                  style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                  (click)="next()">
                  <span aria-hidden="true">&rsaquo;</span>
                  <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                </a>
              </li>
            </ul>
          </div>
          <div style="float: left;">
            <ul class="pagination" id="myDIVActive">
              <li class="page-item">
                <a class="page-link" aria-label="Next"
                  style="font-size: 20px;height: 32px;padding: 1px;padding-left: 10px;padding-right: 10px;margin-left:5px"
                  (click)="last_page()">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">{{ 'rates_and_packages.extra.next' }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> -->
  </div>
</div>

<!-- In house end -->

<a type="button" (click)="goToBottom()" id="myBtn">
</a>

<!-- Details Popup -->
<div id="summary" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  [ngClass]="darkMode ? 'darkOn' : 'darkOff' ">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document">
    <div class="modal-content bg-modal" style="background-color: #f3f3f3;"
      [ngClass]="darkMode ? 'dark-border-div dar-modal-body modal-content-dark' : 'null'">
      <div class="modal-header">
        <div class="bluheader booking-sumary" id="grab-3" style="cursor: grab;" (mouseover)="dodrag()"
          (mouseout)="stopDrag()">
          <h3 class="h3_blu_header bookingsummary">{{ "arrivals.booking_summary" | translate}}
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <strong id="strong">&#x2716;</strong>
            </button>
          </h3>
        </div>
      </div>
      <div class="modal-body">


        <div class="main-box">

          <!-- Stays Box -->
          <div class="data-box">
            <div class="red-bar">{{ "arrivals.list.stays" | translate }} </div>

            <div [ngClass]="darkMode ? 'datas' : 'datas-light'">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal one'">
                <img src="assets/img/newstaticicons/arrivels/forchk/check-in.svg">
                {{current_arrival?.date_arrival | date: 'dd MMM yyyy'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal two'">
                <img src="assets/img/newstaticicons/arrivels/forchk/check-out.svg">
                {{current_arrival?.date_departure | date: 'dd MMM yyyy'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal three'">
                <img src="assets/img/newstaticicons/arrivels/forchk/ETD-clock.svg">
                {{convertTime(current_arrival?.eta)}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal four'">
                <img src="assets/img/newstaticicons/arrivels/forchk/ETA-clock.svg">
                {{convertTime(current_arrival?.etd)}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteen'">
                <img
                  src="{{darkMode ? 'assets/img/newstaticicons/arrivels/forchk/nights.svg' : 'assets/img/newstaticicons/rates-packages/night.svg'}}">
                {{nightsCount(current_arrival?.date_arrival, current_arrival?.date_departure)}}
              </div>

              <!--    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteenop'">
                <img src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'reservation.svg' : 'reservation-light.svg'}}" >
                {{current_arrival?.reservation_id}}
              </div> -->

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal ten'">
                <img src="assets/img/newstaticicons/arrivels/forchk/hotel.svg">
                {{current_arrival?.property?.name}}
              </div>


              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'"
                [ngStyle]="{'color': current_arrival?.currency != null && current_arrival?.currency != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'coins.svg' : 'coins-light.svg'}}">
                {{current_arrival?.currency != null && current_arrival?.currency != '' ? current_arrival?.currency :
                'Currency'}}
              </div>


            </div>
          </div>
          <!-- Stays Box END-->

          <!-- Payment Box -->
          <div class="data-box">
            <div class="red-bar">{{ "arrivals.list.Payment" | translate }}</div>

            <div [ngClass]="darkMode ? 'datas' : 'datas-light'">
              <div class="scrollDiv" style="margin-top: 0;">
                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty1'"
                  [ngStyle]="{'color': current_arrival?.payee_name != null && current_arrival?.payee_name != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/cardhold.svg">
                  {{current_arrival?.payee_name != null && current_arrival?.payee_name != '' ?
                  current_arrival?.payee_name : 'Cardholder Name'}}
                </div>

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal four'"
                  [ngStyle]="{'color': current_arrival?.guest_card_type != null && current_arrival?.guest_card_type != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/cardtype.svg">
                  {{current_arrival?.guest_card_type == 'MC' ? 'Master Card' : current_arrival?.guest_card_type != null
                  && current_arrival?.guest_card_type != '' ? current_arrival?.guest_card_type : 'Card Type'}}
                </div>

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty2'"
                  [ngStyle]="{'color': current_arrival?.guest_card != null && current_arrival?.guest_card != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg">
                  <!-- {{current_arrival?.guest_card != null && current_arrival?.guest_card != '' ? current_arrival?.guest_card : 'Card Number'}} -->
                  <!-- **********{{current_arrival?.guest_card.slice(current_arrival?.guest_card.length-4)}} -->
                </div>
                <!-- guest card_expiry -->
                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal three'" [ngStyle]="{'color': current_arrival?.guest_card_expiry != null && current_arrival?.guest_card_expiry != '' ? '' : 'grey'}">
                    <img src="assets/img/newstaticicons/arrivels/forchk/cardtype.svg">
                    {{current_arrival?.guest_card_expiry != null && current_arrival?.guest_card_expiry != '' ? current_arrival?.guest_card_expiry : 'Guest Card Expiry'}}
                  </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal ten'" [ngStyle]="{'color': current_arrival?.guest_card_series_code != null && current_arrival?.guest_card_series_code != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/cardhold.svg">
                {{current_arrival?.guest_card_series_code != null && current_arrival?.guest_card_series_code != '' ? current_arrival?.guest_card_series_code : 'Guest Card Series'}}
              </div> -->
                <div class="row">
                  <div class="col-md-6 zero-padding" style="padding-right: 2px; padding-left: 25px;">
                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal seventeen'" style="margin: 0px;"
                      [ngStyle]="{'color': current_arrival?.guest_card_expiry != null && current_arrival?.guest_card_expiry != '' ? '' : 'grey'}">
                      <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg" alt="">
                      {{current_arrival?.guest_card_expiry != null && current_arrival?.guest_card_expiry != '' ?
                      current_arrival?.guest_card_expiry : 'MM / YY'}}
                      <!-- {{current_arrival?.guest_card_expiry}} -->
                    </div>
                  </div>
                  <div class="col-md-6 zero-padding" style="padding-left: 3px; padding-right: 25px;">
                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal ten'" style="margin: 0px;"
                      [ngStyle]="{'color': current_arrival?.guest_card_series_code != null && current_arrival?.guest_card_series_code != '' ? '' : 'grey'}">
                      <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg" alt="">
                      {{current_arrival?.guest_card_series_code != null && current_arrival?.guest_card_series_code != ''
                      ? current_arrival?.guest_card_series_code : 'CVV'}}
                      <!-- {{current_arrival?.guest_card_series_code}} -->
                    </div>
                  </div>
                </div>


                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fifteen'" [ngStyle]="{'color': current_arrival?.confirmation_number != null && current_arrival?.confirmation_number != '' ? '' : 'grey'}">
                <img alt="" src="assets/img/newstaticicons/arrivels/id-verified-light.svg" >
                {{current_arrival?.confirmation_number != null && current_arrival?.confirmation_number != '' ? current_arrival?.confirmation_number : 'Confirmation Number'}}
               </div> -->

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty4'"
                  [ngStyle]="{'color': current_arrival?.channel?.name != null && current_arrival?.channel?.name != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/channel.svg">
                  {{current_arrival?.channel?.name}}
                </div>

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty44'"
                  [ngStyle]="{'color': current_arrival?.pos != null && current_arrival?.pos != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/point-of-service-svgrepo-com.svg">

                  {{current_arrival?.pos != null && current_arrival?.pos != '' ? current_arrival?.pos : 'POS'}}
                </div>

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteenop'"
                  [ngStyle]="{'color': current_arrival?.collect_details != null && current_arrival?.collect_details != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/hotelcollect.svg" style="height: 22px;">
                  <!-- {{current_arrival?.collect_details != null && current_arrival?.collect_details != '' ? current_arrival?.collect_details == 1 : 'Hotel Collect | Expedia Collect'}} -->
                  <!-- {{current_arrival?.collect_details == 1 : 'hotel Collect' && current_arrival?.collect_details == 2 : 'Expedia Collect' ? current_arrival?.collect_details: 'Hotel Collect | Expedia Collect'}} -->
                  {{current_arrival?.collect_details == 1 ? 'Hotel Collect' : 'Expedia Collect'}}
                </div>


                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteenop'"
                  [ngStyle]="{'color': current_arrival?.promo != null && current_arrival?.promo != '' ? '' : 'grey'}">
                  <img src="assets/img/newstaticicons/arrivels/forchk/promo-code-small.svg" style="height: 22px;">
                  {{current_arrival?.promo != null && current_arrival?.promo != '' ? current_arrival?.promo :
                  'Promocode'}}
                </div> -->

                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'" [ngStyle]="{'color': current_arrival?.currency != null && current_arrival?.currency != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'coins.svg' : 'coins-light.svg'}}" >
                {{current_arrival?.currency != null && current_arrival?.currency != '' ? current_arrival?.currency : 'Currency'}}
              </div> -->
                <!--
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty44'" [ngStyle]="{'color': current_arrival?.promo != null && current_arrival?.promo != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/promo-code-small.svg" style="height: 22px;">
                 {{current_arrival?.promo != null && current_arrival?.promo != '' ? current_arrival?.promo : 'Promocode'}}
              </div> -->

                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'" [ngStyle]="{'color': current_arrival?.currency != null && current_arrival?.currency != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'coins.svg' : 'coins-light.svg'}}" >
                 {{current_arrival?.currency != null && current_arrival?.currency != '' ? current_arrival?.currency : 'Currency'}}
                {{ current_currency }}

              </div> -->
                <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'" [ngStyle]="{'color': current_arrival?.currency != null && current_arrival?.currency != '' ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'hotelcollect.svg' : 'hotelcollect.svg'}}" >
             {{current_arrival?.currency != null && current_arrival?.currency != '' ? current_arrival?.currency : 'Currency'}}
                {{current_arrival?.collect_details == 1 ? 'Hotel Collect' : 'Expedia Collect'  }}

              </div> -->

                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteenopo'" id="flip">
                  <div *ngFor="let update_weather of weather_update; let i = index">
                    <i class='wi wi-main wi-{{ update_weather.icon }}'
                      [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                    <div class="weatherDiv">
                      <span class="wh_name">{{update_weather.title}} </span>
                      <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                      <span class="wh_con">{{update_weather.condition}} </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- Payment Box END-->

          <!-- Room Booked -->
          <div class="data-box">
            <div class="red-bar">{{ "arrivals.list.WhocheckingIn" | translate }}<span
                class="book-room-count">{{current_arrival?.bookingitems?.length}}</span></div>

            <div class="scrollDiv" style="height: 287px !important;">
              <div [ngClass]="darkMode ? 'room-box' : 'room-box-light'"
                *ngFor="let rd of current_arrival?.bookingitems; let ik = index">
                <div class="room-name">
                  <a data-toggle="collapse" href="#demo{{ik}}" [ngStyle]="{'color': darkMode ? '#ffffff' : '#00205b' }">
                    {{rd?.room?.roomtype?.name}}
                    <span class="drop-icons" style="float: right;">
                      <img
                        src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'button_down_dark.svg' : 'button_down.svg'}}" />
                    </span>
                  </a>
                </div>
                <div id="demo{{ik}}" class="collapse in" style="border-top: 1px solid;width: 100%;padding-top: 0px;">

                  <div [ngClass]="darkMode ? 'datas2' : 'datas-light2 nine2'" style="margin-top: 5px;">
                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal'"
                      [ngStyle]="{'color': rd?.guest_name != null ? '' : 'grey'}">
                      <img src="assets/img/newstaticicons/arrivels/forchk/full-name0.svg" style="height: 16px;">
                      {{rd?.guest_name != null && rd?.guest_name != '' ? rd?.guest_name : 'Guest name'}}
                    </div>

                    <div class="sameDates" *ngIf="current_arrival?.same_date == 0">
                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal one'">
                        <img src="assets/img/newstaticicons/arrivels/forchk/check-in.svg">
                        {{rd?.check_in | date: 'dd MMM yyyy'}}
                      </div>

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal two'">
                        <img src="assets/img/newstaticicons/arrivels/forchk/check-out.svg">
                        {{rd?.check_out | date: 'dd MMM yyyy'}}
                      </div>
                    </div>

                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal eight'">
                      <div style="display: flex;flex-direction: row; justify-content: space-between;">
                        <img
                          src="assets/img/newstaticicons/back-office/{{rd?.room?.smoking == 'true' ? 'smoke.svg' : 'nosmoke.svg' }}"
                          style="width: 30px;height: 30px;transform: translate(0px, 2px);margin-left: 5px;" />

                        <img
                          src="assets/img/newstaticicons/back-office/{{rd?.room?.pets == 'true' ? 'pets.svg' : 'nopets.svg' }}"
                          style="width: 30px;height: 30px;transform: translate(0px, 2px);" />

                        <img
                          src="assets/img/newstaticicons/back-office/{{rd?.room?.wheelchairaccessibility == 'true' ? 'wheel.svg' : 'nowheel.svg' }}"
                          style="width: 30px;height: 30px;transform: translate(0px, 2px);margin-right: 7px;" />
                      </div>
                    </div>

                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal five'">
                      <img src="assets/img/newstaticicons/arrivels/forchk/room-id-infinity1dark.svg">
                      {{rd?.room?.roomtype?.name}}
                    </div>

                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fifteen'">
                      <img src="assets/img/dashboard/in-house2.svg">
                      {{rd?.status}}
                    </div>


                    <div class="guestCheckedIncard" *ngIf="rd?.status == 'Checked In'">
                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal eighteen'">
                        <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg" alt="">
                        <!-- **********{{rd?.card_no.slice(rd?.card_no.length-4)}} -->
                      </div>
                      <div class="row" style=" margin-left: 0px;  margin-right: 0px; ">
                        <div class="col-md-6 zero-padding" style="padding-right: 2px">
                          <div [ngClass]="darkMode ? 'border-orange' : 'border-normal seventeen'" style="margin: 0px;">
                            <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg" alt="">
                            {{rd?.card_expiry}}
                          </div>
                        </div>
                        <div class="col-md-6 zero-padding" style="padding-left: 3px">
                          <div [ngClass]="darkMode ? 'border-orange' : 'border-normal ten'" style="margin: 0px;">
                            <img src="assets/img/newstaticicons/arrivels/forchk/cardnumber.svg" alt="">
                            {{rd?.card_cvv}}
                          </div>
                        </div>
                      </div>

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                        [ngStyle]="{'color': rd?.id_type != null ? '' : 'grey'}">
                        <img src="assets/img/newstaticicons/arrivels/forchk/idtype.svg">
                        {{rd?.id_type != null && rd?.id_type != '' ? rd?.id_type : 'Id Type'}}
                      </div>

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteen'"
                        [ngStyle]="{'color': rd?.id_value != null ? '' : 'grey'}">
                        <img src="assets/img/newstaticicons/arrivels/forchk/id-number-dark.svg">
                        {{rd?.id_value != null && rd?.id_value != '' ? rd?.id_value : 'Id Value'}}
                      </div>

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty'"
                        [ngStyle]="{'color': rd?.phone_number != null ? '' : 'grey'}">
                        <img src="assets/img/newstaticicons/arrivels/r-call-red.svg">
                        {{rd?.phone_number != null && rd?.phone_number != '' ? rd?.phone_number : 'Guest name'}}
                      </div>

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty2'"
                        [ngStyle]="{'color': rd?.email != null ? '' : 'grey'}">
                        <img src="assets/img/newstaticicons/arrivels/forchk/mail-infinity0.svg">
                        {{rd?.email != null && rd?.email != '' ? rd?.email : 'Guest Email'}}
                      </div>

                    </div>



                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal eleven'"
                      [ngStyle]="{'color': rd?.package_id != null ? '' : 'grey'}">
                      <img src="assets/img/newstaticicons/arrivels/forchk/package.svg">
                      {{rd?.package_id != null && rd?.package_id != '' ? rd?.pricesjson.Package_name :
                      'arrivals.list.PromotionName' | translate }}
                    </div>

                    <!-- <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fifteen'">
                    <img src="assets/img/newstaticicons/arrivels/forchk/bed2.svg" >
                    {{rd?.roomnum?.room_number}}
                  </div> -->

                    <div class="flexChild" style="display: flex;">

                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal six'" style="margin: 0 0px;">
                        <img
                          src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/adult-dark.svg':'arrivels/forchk/adult0.svg'}}">
                        {{rd?.no_of_adults}}
                      </div>
                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal fourteenop'" style="margin: 0 0px;">
                        <img
                          src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/infant-dark.svg':'arrivels/forchk/infant-dark.svg'}}">
                        {{rd?.no_of_infants}}
                      </div>
                      <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'" style="margin: 0 0px;">
                        <img
                          src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/child-dark.svg':'arrivels/forchk/child.svg'}}">
                        {{rd?.no_of_childs}}
                      </div>
                    </div>


                    <!-- <div class="flexChild" style="display: flex;">
                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty509'">
                      <img src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/child-dark.svg':'arrivels/forchk/child.svg'}}">
                      {{rd?.no_of_childs}}
                    </div>

                    <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty57'">
                      <img src="assets/img/newstaticicons/arrivels/forchk/birthday.png">
                      Child Age: {{current_arrival?.child_ages}}
                    </div>
                  </div> -->
                    <div [ngClass]="darkMode ? 'border-orange paid-com' : 'border-normal thirteen paid-com'">
                      <label class="containers" [ngClass]="rd?.breakfast == 'Paid' ? 'true' : 'false'">
                        <div class="block "></div>
                        <span> {{ "arrivals.list.Paid" | translate }}</span>
                      </label>
                      <label class="containers" [ngClass]="rd?.breakfast == 'Complimentry' ? 'true' : 'false'">
                        <div class="block "></div>
                        <span> {{ "arrivals.list.Complimentry" | translate }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- Room Booked END-->

          <!-- Guest-->
          <div class="data-box">
            <div class="red-bar">{{ "arrivals.list.bookedby" | translate }}</div>
            <div [ngClass]="darkMode ? 'datas' : 'datas-light'">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twelve'"
                [ngStyle]="{'color': current_arrival?.guest_firstname != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/full-name-infinity0.svg" style="height: 16px;">
                {{current_arrival?.guest_firstname != null && current_arrival?.guest_firstname != '' ?
                current_arrival?.guest_firstname : 'Full Name'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty44'"
                [ngStyle]="{'color': current_arrival?.guest_phone != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/r-call-red.svg">
                {{current_arrival?.guest_phone != null && current_arrival?.guest_phone != '' ?
                current_arrival?.guest_phone : 'Phone'}}
              </div>

              <div class="ovrflow" title=" {{current_arrival?.guest_email != null && current_arrival?.guest_email != '' ?
                current_arrival?.guest_email : 'Email'}}"
                [ngClass]="darkMode ? 'border-orange' : 'border-normal fifteen'"
                [ngStyle]="{'color': current_arrival?.guest_email != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/mail-infinity0.svg">
                {{current_arrival?.guest_email != null && current_arrival?.guest_email != '' ?
                current_arrival?.guest_email : 'Email'}}


              </div>

              <div [ngClass]="darkMode ? 'border-orange elip' : 'border-normal sixteen elip'"
                [ngStyle]="{'color': current_arrival?.guest_address != null ? '' : 'grey'}">
                <img
                  src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'location.svg' : 'location-light.svg'}}">
                {{current_arrival?.guest_address != null && current_arrival?.guest_address != '' ?
                current_arrival?.guest_address : 'Address'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal seventeen'"
                [ngStyle]="{'color': current_arrival?.guest_city != null ? '' : 'grey'}">
                <img
                  src="assets/img/newstaticicons/arrivels/forchk/{{darkMode ? 'location.svg' : 'location-light.svg'}}">
                {{current_arrival?.guest_city != '' && current_arrival?.guest_city != null ? current_arrival?.guest_city
                : 'City'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal eighteen'"
                [ngStyle]="{'color': current_arrival?.personal_id_type != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/idtype.svg">
                {{current_arrival?.personal_id_type != null && current_arrival?.personal_id_type != '' ?
                current_arrival?.personal_id_type : 'Id Type' }}
              </div>
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal nineteen'"
                [ngStyle]="{'color': current_arrival?.personal_id_value != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/id-number-dark.svg">
                {{current_arrival?.personal_id_value != null && current_arrival?.personal_id_value != '' ?
                current_arrival?.personal_id_value : 'Id value'}}
              </div>

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty'"
                [ngStyle]="{'color': current_arrival?.privilege != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/pervilige.svg">
                {{current_arrival?.privilege != null && current_arrival?.privilege != '' ? current_arrival?.privilege :
                'Priviliges'}}
              </div>
            </div>
          </div>
          <!-- Guest END-->

        </div>

        <div class="side-tray" id="sidetRAY" style=" right: -1px;" [ngClass]="darkMode ? 'darkTray' : 'lightTray'"
          [ngStyle]="{'background': darkMode ? '#292b40' : '#fff'}">

          <div class="dataSide">

            <div>

              <div class="room-rate" *ngFor="let rr of current_arrival?.bookingitems; let c = index">
                <div class="r-name">

                  {{rr.pricesjson?.season_name == null ? rr.season.season_name : rr.pricesjson?.season_name }}
                </div>
                <div class="r-name">
                  {{rr.pricesjson?.roomName}}
                </div>
                <div>
                  <div [ngClass]="darkMode ? 'room-n-r' : 'room-n-r-light'"
                    *ngFor="let rate of rr?.pricesjson?.rates; let c = index;"
                    [ngStyle]="{'border-color': colors[c%colors.length] }">
                    <div class="r-n" [ngStyle]="{'color': darkMode ? 'orange': 'rgb(0, 32, 91)'}">{{rate?.date | date:
                      'EEE dd MMM'}}</div>
                    <div class="r-r" [ngStyle]="{'color': darkMode ? '#fff': '#00205b'}"> {{ rate?.price | currency :
                      current_currency }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="disc " [ngStyle]="{'background': darkMode ? '#292b40' : '#fff'}">
            <div class="datas ">
              <div class="ovrflowreq " [ngClass]="darkMode ? 'border-orange' : 'border-normal one'">
                {{specialReq(current_arrival?.SpecialRequests) != null ? specialReq(current_arrival?.SpecialRequests) :
                'Special Request' }}
              </div>
            </div>

            <div class="datas">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                [ngStyle]="{'color': current_arrival?.discount != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/addpersonicon1.svg">
                {{current_arrival?.extra_person_fee != null ? current_arrival?.extra_person_fee : 'Extra Person Fee' }}
              </div>
            </div>

            <div class="datas">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                [ngStyle]="{'color': current_arrival?.discount != null ? '' : 'grey'}">
                <img src="assets/img/newstaticicons/arrivels/forchk/subtotal.svg">
                {{ subtotal(current_arrival?.prices,current_arrival?.extra_person_fee || 0) }}
              </div>
            </div>

            <div class="datas">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal'">
                <div class="dFlex segmo">
                  <label (click)="changeSegment('true','segmon')" [ngClass]="segmonLeft ? 'active' : 'null'">{{
                    "back_office.hotel_setup.tax_inclusive" | translate }}</label>
                  <label (click)="changeSegment('false','segmon')" [ngClass]="!segmonLeft ? 'active' : 'null'">{{
                    "back_office.hotel_setup.tax_exclusive" | translate }}</label>
                  <div class="segmon" id="segmon"></div>
                </div>
              </div>
            </div>



            <div class="datas">

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                [ngStyle]="{'color': current_arrival?.discount != null ? '' : 'grey'}">
                <img
                  src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/discount.svg':'arrivels/forchk/discount-light.svg'}}">
                {{current_arrival?.discount != null ? current_arrival?.discount : 'Discount' }}
              </div>
            </div>

            <div class="datas" *ngIf="current_arrival?.cashDeposit != null">

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                [ngStyle]="{'color': current_arrival?.discount != null ? '' : 'grey'}">
                <img
                  src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/discount.svg':'arrivels/forchk/amount-deposit.svg'}}">
                {{current_arrival?.cashDeposit != null ? current_arrival?.cashDeposit : 'Deposit' }}
              </div>
            </div>

            <div class="datas" *ngIf="TaxInclusivee == 'false'">

              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty69'"
                [ngStyle]="{'color': current_arrival?.discount != null ? '' : 'grey'}">
                <img
                  src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/tax-icon.svg':'arrivels/forchk/taxicon.svg'}}">
                {{ taxcaclute(current_arrival?.prices,current_arrival?.Taxper) }}
              </div>
            </div>

            <div class="datas">
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal twenty5'">
                <img
                  src="assets/img/newstaticicons/{{darkMode ? 'arrivels/forchk/dollar.svg':'arrivels/forchk/dollar-light.svg'}}">

                {{ current_arrival?.total | currency : current_currency }}
              </div>
            </div>
          </div>


          <i class="fa fa-caret-right" (click)="toggleTray('sidetRAY')"></i>
        </div>

      </div>

      <div class="modal-footer" style="position: absolute; border: none;bottom: -45px;">
        <button class="close-btn" data-dismiss="modal" aria-label="Close">
          {{ 'back_office.hotel_setup.close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- reservation-id Modal -->
<div class="modal fade" id="myModal" role="dialog" [ngClass]="darkMode ? 'darkOn' : 'darkOff' ">
  <div class="modal-dialog modal-sm">
    <!-- Modal content-->
    <div class="modal-content" style="background-color: #f3f3f3; border-radius: 15px !important;"
      [ngClass]="darkMode ? 'dark-border-div dar-modal-body modal-content-dark' : 'null'">
      <div class="modal-header" style="padding: 0; border: 0px;">
        <div class="bluheader booking-sumary" id="grab-4" style="cursor: grab;" (mouseover)="dodrag()"
          (mouseout)="stopDrag()">
          <h3 class="h3_blu_header bookingsummary">{{ "arrivals.list.reservation_id" | translate }}
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <strong id="strong">&#x2716;</strong>
            </button>
          </h3>
        </div>
      </div>

      <div class="modal-body">
        <div class="main-box2" *ngFor="let rd of current_arrival?.bookingitems">
          <!-- Stays Box -->
          <div class="data-box2">
            <div class="red-bar"
              style="text-align: center; background: linear-gradient(rgb(115, 64, 206), rgb(80, 28, 173), rgb(91, 52, 160))!important;">
              <!-- {{current_arrival?.pos}} -->
              {{current_arrival?.pos || current_arrival?.channel.name}}
            </div>
            <div [ngClass]="darkMode ? 'datas' : 'datas-light'">

              <div *ngIf="current_arrival?.confirmation_number != null">

                <div class="red-bar" style="text-align:center;">{{ "arrivals.list.conformation_id" | translate }}</div>
                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal two'">
                  <div style="text-align: center;">
                    {{current_arrival?.confirmation_number}}
                  </div>
                </div>
              </div>

              <div class="red-bar" style="text-align: center;">{{ "arrivals.list.reservation_id" | translate }}</div>
              <div [ngClass]="darkMode ? 'border-orange' : 'border-normal three'">
                <div style="text-align: center;"> {{current_arrival?.reservation_id}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" data-dismiss="modal"
             style="margin-top: 5px; margin-left: 5px;margin-right: 10px;border-radius: 25px;padding: 8px 30px;float: right;" (click)="save_ota_update()" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'">
              {{ "inventory.promo.save" | translate }}
            </button> -->
    </div>
  </div>
</div>