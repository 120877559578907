import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LayoutService } from "../layout.service";
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { NgZone, Renderer2, ElementRef, ViewChild } from '@angular/core'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit, AfterViewInit {

  constructor(
    public layout_service: LayoutService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
  }

  ngAfterViewInit() {
    this.spinnerShow();
  }

  private spinnerShow() {
    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        this.cdr.detectChanges()
        this.layout_service.spinner = true;
        this.spinner.show('sp6');
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.cdr.detectChanges()
        this.layout_service.spinner = false;
        this.spinner.hide();
      }
    });
  }

  ngOnInit() { }

}
