<!-- OTA skeleton start -->
<div class="skeleton" style="display: flex;" *ngIf="OTA_skeleton">
   <div style="width: 33%;margin-right: 8px;" class="main" [ngClass]="darkMode ? 'main_dark':'main'" *ngFor="let rp of getEmptyElements(3)">
      <div class="dd">
         <div class="head" style="background:#707070a8; height: 50px;"></div>
         <div class="no_scroll">
            <div class="grid_20" style="padding-top:0px">
               <div id="drag-box-ota scroll no_scroll OTA-skeleton" style="padding-top: 5px;">
                  <div id="drag-item-ota" *ngFor="let rp of getEmptyElements(5)"
                     style="padding-left: 1px; padding-right: 0px;">
                     <div class="shade" style="background-color: #707070a8;">
                        <div class="border-height OTA-skeleton-animation">
                           <div class="dark_gradient" [ngClass]="darkMode ? 'dark_gradient_dark':'dark_gradient'">
                              <div class="uper" style="display: flex; align-items: center;background: transparent;">
                                 <div class="first-div"></div>
                              </div>
                              <div class="between between1" style="background: transparent;">
                                 <div class="second-div"></div>
                              </div>
                           </div>
                           <div class="lower" [ngClass]="darkMode ? 'lower_dark':'lower'">
                              <div class="third-div"></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- OTA skeleton end -->

<div style="display: flex;" class="scroll" *ngIf="!OTA_skeleton">
   <!-- Awaiting / Click to Setup-->
   <div style="width: 33%;margin-right: 8px;" class="main" [ngClass]="darkMode ? 'main_dark':'main'">
      <app-ota-setup [awaiting_channels]="awaiting_channels" (toggleChange)="toggle_change($event)"></app-ota-setup>
   </div>
   <!-- Ineternal Channels -->
   <div style="width: 33%; margin-right: 8px;" class="main" [ngClass]="darkMode ? 'main_dark':'main'">
      <app-internal-channels [internal_channels]="internal_channels" (otaSettingModal)="ota_setting_modal($event)"></app-internal-channels>
   </div>
   <!-- Linked OTA -->
   <div style="width: 33%;" class="main" [ngClass]="darkMode ? 'main_dark':'main'">
      <app-linked-ota [external_channels]="external_channels" (otaSettingModal)="ota_setting_modal($event)"></app-linked-ota>
   </div>
</div>
<!-- Update Channel/OTA Settings Popup -->
<div id="ota_settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true" [formGroup]="settings_update">
   <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
         <div class="modal-body" style="padding: 5px;padding-bottom: 1px;"
            [ngClass]="darkMode ? 'modal-content_dark':'null'">
            <div class="alert alert-info alert-dismissible show" role="alert"
               [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" role="alert" (mouseover)="dodrag3()"
               (mouseout)="stopDrag3()"> {{ 'manage_ota.otas_and_channels' | translate }}
               <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                     id="strong">&#x2716;</strong> </button>
            </div>
            <div class="alert alert-danger breadcrum channel-settings"
               [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a class="button btn-link al-lin"
                  style="box-shadow: none; color: #ffffff">{{ 'manage_ota.channel_settings' | translate }}</a> </div>
            <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
               <div class="row field-space">
                  <div class="col-md-12">
                     <div class="col-md-6" style="padding-right: 2.5px">
                        <div class="border-style blue-shade" [ngClass]="darkMode ? 'blue-shade_dark':'blue-shade'">
                           <div class="element" [ngClass]="darkMode ? 'element_dark' : 'element'">
                              <label [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                 style="color: #00205b;">{{ 'manage_ota.channel_status' | translate }}</label>
                              <div class="pull-right flex">
                                 <div class="radio-box">
                                    <label class="control-label radio-style">
                                       <input type="radio" name="status"
                                          class="pull-left radio channel_status_{{ updateData ? updateData.id:'' }}"
                                          [value]="updateData ? (updateData.status==='Active' ? updateData.status: 'Enabled') :'Enabled'"
                                          [(ngModel)]="checked" formControlName="status"> <span class="radio-checkmark"
                                          [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"></span> <span
                                          class="title"
                                          [ngClass]="darkMode ? 'ng-untouched_dark_radio': 'ng-untouched'">{{
                                          'manage_ota.enabled' | translate }}</span> </label>
                                 </div>
                                 <div class="radio-box">
                                    <label class="control-label radio-style">
                                       <input type="radio" name="status"
                                          class="pull-left radio channel_status_{{ updateData ? updateData.id:'' }}"
                                          [value]="updateData ? (updateData.status !=='Active' ? updateData.status: 'Disabled') :'Disabled'"
                                          [(ngModel)]="checked" formControlName="status"> <span
                                          class="radio-checkmark"></span> <span class="title"
                                          [ngClass]="darkMode ? 'ng-untouched_dark_radio': 'ng-untouched'">{{
                                          'manage_ota.disabled' | translate }}</span> </label>
                                 </div>
                                 <div class="radio-box" *ngIf="updateData">
                                    <label class="control-label radio-style" style="display: flex;"
                                       [ngClass]="updateData.status == 'Active' ? 'yesActive' : ''">
                                       <div class="sudoChkBox"></div>
                                       <span class="title"
                                          [ngClass]="darkMode ? 'ng-untouched_dark_radio': 'ng-untouched'">{{
                                          'manage_ota.enabled' | translate }}</span>
                                    </label>
                                 </div>
                                 <div class="radio-box" *ngIf="updateData">
                                    <label class="control-label radio-style" style="display: flex;"
                                       [ngClass]="updateData.status != 'Active' ? 'yesActive' : ''">
                                       <div class="sudoChkBox"></div>
                                       <span class="title"
                                          [ngClass]="darkMode ? 'ng-untouched_dark_radio': 'ng-untouched'">{{
                                          'manage_ota.disabled' | translate }}</span>
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6" style="padding-left: 2.5px">
                        <div class="border-style green-shade" [ngClass]="darkMode ? 'green-shade_dark' : 'green-shade'">
                           <input type="text" class="form-control" [ngClass]="darkMode ? 'border-style_dark' : 'null'"
                              placeholder="{{ 'manage_ota.enter_username' | translate }}"
                              [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                              value="{{ updateData ?  updateData.username:'' }}" formControlName="username">
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row field-space">
                  <div class="col-md-12">
                     <div class="col-md-6" style="padding-right: 2.5px">
                        <div class="border-style yellow-shade"
                           [ngClass]="darkMode ? 'yellow-shade_dark':'yellow-shade'">
                           <ng-select placeholder="{{ 'manage_ota.select_default_currency' | translate }}"
                              name="default_currency" [items]="currency" bindLabel="country" bindValue="id"
                              [(ngModel)]="default_currency" formControlName="default_currency"
                              [ngClass]="darkMode ? 'dark-drop' : ' null '"> </ng-select>
                        </div>
                     </div>
                     <div class="col-md-6" style="padding-left: 2.5px">
                        <div class="border-style purple-shade"
                           [ngClass]="darkMode ? 'purple-shade_dark' : 'purple-shade'">
                           <input type="password" class="form-control"
                              [ngClass]="darkMode ? 'border-style_dark' : 'null'"
                              placeholder="{{ 'manage_ota.enter_password' | translate }}" autocomplete="off"
                              formControlName="password">
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row field-space">
                  <div class="col-md-12">
                     <div class="col-md-6" style="padding-right: 2.5px">
                        <div class="border-style orange-shade"
                           [ngClass]="darkMode ? 'orange-shade_dark' : 'orange-shade'">
                           <input type="text" class="form-control" [ngClass]="darkMode ? 'border-style_dark' : 'null'"
                              placeholder="{{ 'manage_ota.Enter_rate_multiplier' | translate }}"
                              value="{{ updateData ?  updateData.rate:'' }}" formControlName="rate">
                           <span class="info">
                              <i class="fas fa-info-circle i" style="font-size: 15px;cursor:pointer"
                                 [ngClass]="darkMode ? 'dark_i' : 'null'">
                                 <span class="tooltiptext3" [ngClass]="darkMode ? 'dark_tool' : 'null'"
                                    style="width:248px;left: -244px;">
                                    <p class="small   no-margin"
                                       style=" padding: 0px !important; margin: 0px !important;">
                                       {{ 'manage_ota.decimal_number_cannot_be_lower_than' | translate }}0.5
                                    </p>
                                 </span>
                              </i>
                           </span>
                        </div>
                     </div>
                     <div class="col-md-6" style="padding-left: 2.5px">
                        <div class="border-style grey-shade">
                           <input type="text" class="form-control" [ngClass]="darkMode ? 'border-style_dark' : 'null'"
                              placeholder="{{ 'manage_ota.enter_hotel_code' | translate }}"
                              value="{{ updateData ?  updateData.hotel_code : ''}}" formControlName="hotel_code">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer text-right ota_set_foot ide1"> <a class="btn btn-info btn-lg  btn-hov"
                  [ngClass]="darkMode ? 'btn-info_dark': 'btn-info'" (click)="updateSettings()"
                  style="line-height: 20px;">{{ 'manage_ota.settings_ota.save' | translate }}</a> <a aria-label="Close"
                  class="close" data-dismiss="modal" class="btn btn-danger btn-lg btn-hov"
                  [ngClass]="darkMode ? 'btn-danger_dark': 'btn-danger'" style="line-height: 20px;">{{
                  'manage_ota.settings_ota.cancel' | translate }}</a> </div>
         </div>
      </div>
   </div>
</div>
<!-- Enable Created Ota/ Channel Popup -->
<div [formGroup]="settings_form">
   <div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
         <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
            <div class="modal-body" style="padding: 5px !important" [ngClass]="darkMode ? 'modal-content_dark':'null'">
               <div class="row">
                  <div class="col-md-2 popup-nav-padd rescol0">
                     <ul class="nav flex-column" [ngClass]="darkMode ? 'flex-column_dark': 'flex-column'">
                        <li class="nav-item active" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#setupchannel" id="setup_channelli">
                              <img src="assets/img/newstaticicons/ota-channels/male.svg">
                              <span class="tb-adj tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br>
                                 <strong>{{ 'manage_ota.settings_ota.channel' | translate }}</strong>
                              </span>
                           </a>
                        </li>
                        <li class="nav-item" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#setupmultiplier" id="multiplierli"
                              style="display: flex;">
                              <img src="assets/img/newstaticicons/ota-channels/mony-in-hand.svg">
                              <span class="tb-adj tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'"
                                 style="text-overflow: ellipsis;overflow: hidden;" data-placement="top"
                                 data-toggle="tooltip"
                                 title="{{ 'manage_ota.settings_ota.setup' | translate }} {{ 'manage_ota.settings_ota.multiplier' | translate }}">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br>
                                 <strong>{{ 'manage_ota.settings_ota.multiplier' | translate }}</strong>
                              </span>
                           </a>
                        </li>
                        <li class="nav-item" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#preview" id="previewli">
                              <img src="assets/img/newstaticicons/ota-channels/search.svg">
                              <span class="tb-adj tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br>
                                 <strong>{{ 'manage_ota.settings_ota.preview' | translate }}</strong>
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-10 pl-0 rescol">
                     <div class="tab-content scrollo">
                        <div class="tab-pane active" id="setupchannel">
                           <div class="pane-content" [ngClass]="darkMode ? 'pane-content_dark':'pane-content'">
                              <div class="alert alert-info  alert-dismissible show"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" role="alert"
                                 (mouseover)="dodrag2()" (mouseout)="stopDrag2()"> {{
                                 'manage_ota.settings_ota.channel_butler_setup' | translate }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a
                                    class="btn btn-link al-lin" data-dismiss="modal">{{
                                    'manage_ota.settings_ota.manage_otas' | translate }}</a> <a
                                    class="btn btn-link active al-lin" style="color: white">{{
                                    'manage_ota.settings_ota.setup_channel' | translate }}</a> </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div *ngIf="settings?.image !== null">
                                             <img *ngIf="settings?.type == 'External'"
                                                src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + settings?.image : settings?.image }}"
                                                class="img-set">
                                             <img *ngIf="settings?.type == 'Internal'"
                                                src="assets/img/svgicons/channels/{{ settings?.image }}"
                                                class="img-set">
                                          </div>
                                          <div style="visibility: hidden" *ngIf="settings?.image == null">
                                             <img src="assets/img/manage_otaz/{{ settings?.image }}" class="img-set">
                                          </div>
                                          <div class="thr-tbs">
                                             <div class="border-style pink-shade thr-tbs-one ul-div element">
                                                <ul class="ul-up" [ngClass]="darkMode ? 'ul-up_dark': 'ul-up'">
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_1' | translate }}
                                                   </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_2' | translate }}
                                                   </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_3' | translate }}
                                                   </li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper">
                                             <img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 22px;">
                                          </div>
                                          <div class="upload-btn-wrapper"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="alert alert-danger"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a
                                    class="btn btn-link active c-inf" style="color: white">{{
                                    'manage_ota.settings_ota.channel_credentials' | translate }}</a> </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row field-space">
                                    <div class="col-md-12">
                                       <div class="col-md-6 pr-5" style="padding-right: 3px !important;">
                                          <div class="border-style br-col-4">
                                             <input class="form-control in-adj"
                                                [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                placeholder="{{ 'manage_ota.settings_ota.user_name' | translate }}"
                                                type="text" [(ngModel)]="username" formControlName="username"
                                                id="username">
                                          </div>
                                       </div>
                                       <div class="col-md-6 pl-5" style="padding-left: 2px !important;">
                                          <div class="border-style br-col-5">
                                             <input class="form-control in-adj"
                                                [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                placeholder="{{ 'manage_ota.settings_ota.password' | translate }}"
                                                type="password" [(ngModel)]="password" formControlName="password"
                                                id="password">
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row field-space">
                                    <div class="col-md-12">
                                       <div class="col-md-6 pr-5">
                                          <div class="border-style br-col-66 field_hov">
                                             <input class="form-control in-adj"
                                                [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                placeholder="{{ 'manage_ota.settings_ota.hotel_code' | translate }}"
                                                type="text" [(ngModel)]="hotel_code" formControlName="hotel_code"
                                                id="hotel_code">
                                          </div>
                                       </div>
                                       <div class="col-md-6 pl-5" style="padding-left: 2px !important;">
                                          <div class="border-style br-col-6 field_hov" *ngIf="pricing_data == 'Both'">
                                             <ng-select placeholder="Select Pricing Model"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ''"
                                                formControlName="pricing_model" [(ngModel)]="pricing_model">
                                                <ng-option value="PerDayPricing">{{
                                                   'rates_and_packages.extra.per_day_pricing' | translate}}</ng-option>
                                                <ng-option value="OccupancyBasedPricing">{{
                                                   'rates_and_packages.extra.occupancy_base_pricing' |
                                                   translate}}</ng-option>
                                             </ng-select>
                                          </div>
                                          <div class="border-style br-col-6 field_hov"
                                             *ngIf="pricing_data == 'occupancy'">
                                             <ng-select placeholder="Select Pricing Model"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ''"
                                                formControlName="pricing_model" [(ngModel)]="pricing_model">
                                                <ng-option value="OccupancyBasedPricing">{{
                                                   'rates_and_packages.extra.occupancy_base_pricing' |
                                                   translate}}</ng-option>
                                             </ng-select>
                                          </div>
                                          <div class="border-style br-col-6 field_hov"
                                             *ngIf="pricing_data == 'PerDayPricing'">
                                             <ng-select placeholder="Select Pricing Model"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ''"
                                                formControlName="pricing_model" [(ngModel)]="pricing_model">
                                                <ng-option value="PerDayPricing">{{
                                                   'rates_and_packages.extra.per_day_pricing' | translate}}</ng-option>
                                             </ng-select>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer ide2"> <a class="btn btn-primary btn-lg btn-hov"
                                 [ngClass]="darkMode ? 'btn-info_dark': 'btn-info'" id="activeMultiplier"
                                 data-toggle="tab" (click)="activeMultiplier()">{{ 'manage_ota.settings_ota.next' |
                                 translate }}</a> <a class="btn btn-danger btn-lg btn-hov"
                                 [ngClass]="darkMode ? 'btn-danger_dark': 'btn-danger'" data-dismiss="modal"
                                 aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate }}</a> </div>
                        </div>
                        <div class="tab-pane" id="setupmultiplier">
                           <div class="pane-content">
                              <div class="alert alert-info alert-dismissible show"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" role="alert"
                                 (mouseover)="dodrag2()" (mouseout)="stopDrag2()"> {{
                                 'manage_ota.settings_ota.channel_butler_setup' | translate }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a
                                    class="btn btn-link al-lin" data-dismiss="modal">{{
                                    'manage_ota.settings_ota.manage_otas' | translate }}</a> <a
                                    class="btn btn-link active al-lin" style="color: white">{{
                                    'manage_ota.settings_ota.set_rate_multiplier' | translate }}</a> </div>
                              <div class="card crd-mt" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div class="thr-tbs field-space"
                                             [ngClass]="darkMode ? 'tbs_br_dark':'tbs_br'">
                                             <div>
                                                <div>
                                                   <div class="border-style pink-shade tbs-br thr-tbs-one element pd-10"
                                                      [ngClass]="darkMode ? 'tbs_br_dark':'tbs_br'">
                                                      <p class="" type="text"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"> {{
                                                         'manage_ota.settings_ota.bullet_4' | translate }} </p>
                                                      <p class="" type="text"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"> {{
                                                         'manage_ota.settings_ota.bullet_5' | translate }} </p>
                                                      <p class="no-margin" type="text"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"> {{
                                                         'manage_ota.settings_ota.bullet_6' | translate }} </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="">
                                                <div class="">
                                                   <div class="">
                                                      <div class="col-md-5">
                                                         <div class="border-style br-col-4 enter-br">
                                                            <input class="form-control in-adj"
                                                               [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                               placeholder="{{ 'manage_ota.settings_ota.enter_rate_if_required' | translate }}"
                                                               type="number" [(ngModel)]="rate" formControlName="rate">
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <p class="small text-danger sp-sp-txt"> <strong>
                                                {{ 'manage_ota.settings_ota.decimal_number' | translate }}
                                             </strong>
                                          </p>
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper"><img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 22px;"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card crd-mt" [ngClass]="darkMode ? 'card_dark': 'card'"
                                 style="margin-bottom:0px">
                                 <div class="row">
                                    <div class="">
                                       <div class="field-space">
                                          <div class="col-md-12 col-pd">
                                             <div class="col-md-12">
                                                <div class="border-style currency-shade al-br">
                                                   <div class="element pd-10"
                                                      [ngClass]="darkMode? 'col-pd_dark': 'col-pd'">
                                                      <img class="img img-responsive img-class"
                                                         src="assets/img/newstaticicons/ota-channels/red-warning.svg"
                                                         style="width: 92px;">
                                                      <div class="al-mar">
                                                         <span class="al-span-one"
                                                            [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                            <strong>
                                                               <span class="change-bor"
                                                                  [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                                  {{ 'manage_ota.settings_ota.please_note' | translate
                                                                  }}:
                                                               </span>
                                                            </strong>
                                                            {{ 'manage_ota.settings_ota.imp_note' | translate }}
                                                         </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer ide3"> <a class="btn btn-primary btn-lg"
                                 [ngClass]="darkMode ? 'foot-btn_dark': null" id="activePreview" data-toggle="tab"
                                 href="#preview" (click)="activePreview()">{{ 'manage_ota.settings_ota.next' | translate
                                 }}</a> <a class="btn btn-danger btn-lg" [ngClass]="darkMode ? 'foot-btn_close': null"
                                 data-dismiss="modal" aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate
                                 }}</a> </div>
                        </div>
                        <div class="tab-pane" id="preview">
                           <div class="pane-content" [ngClass]="darkMode ? 'pane-content_dark':'pane-content'"
                              style="height: 376px;">
                              <div class="alert alert-info alert-dismissible show"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" role="alert"
                                 (mouseover)="dodrag2()" (mouseout)="stopDrag2()"> {{
                                 'manage_ota.settings_ota.channel_butler_setup' | translate }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'">
                                 <a class="btn btn-link al-lin" data-dismiss="modal">{{
                                    'manage_ota.settings_ota.manage_otas' | translate }}</a>
                                 <a class="btn btn-link active al-lin align-ch" style="color: white">
                                    {{ 'manage_ota.settings_ota.preview' | translate }}
                                    {{ 'manage_ota.settings_ota.setup' | translate }} </a>
                              </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div class="thr-tbs">
                                             <div>
                                                <div style="height: 134px; padding-top: 50px;"
                                                   class="border-style element pd-10 br-col-69 thr-tbs-one" role="alert"
                                                   [ngClass]="darkMode? 'pd-10_dark': 'pd-10'">
                                                   <p class=""
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"> {{
                                                      'manage_ota.settings_ota.note_preview' | translate }} </p>
                                                </div>
                                             </div>
                                          </div>
                                          <!--- <p class="small text-danger sp-sp-txt">
                                   <strong>
                                       {{ 'manage_ota.settings_ota.review_information' | translate }}
                                   </strong>
                                   </p> --->
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper"> <img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 22px;"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card crd-mg" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12 two">
                                       <div class="row field-space">
                                          <div class="col-md-12">
                                             <div class="col-md-12 ">
                                                <div class="col-md-6 pr-5">
                                                   <div class="border-style currency-shade br-col-one">
                                                      <div class="element"
                                                         [ngClass]="darkMode ? 'element_dark': 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12 h-40">
                                                               <div class="col-md-6">
                                                                  <label class="currency_label lbl-col"
                                                                     [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                                     {{ 'manage_ota.settings_ota.channel_to_connect' |
                                                                     translate }} </label>
                                                               </div>
                                                               <div class="col-md-6">
                                                                  <div class="status_box_img">
                                                                     <img class="im-adj"
                                                                        *ngIf="settings?.type == 'External'"
                                                                        src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + settings?.image : settings?.image }}">
                                                                     <img class="im-adj"
                                                                        *ngIf="settings?.type == 'Internal'"
                                                                        src="assets/img/svgicons/channels/{{ settings?.image }}">
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-6 pl-5" style="padding-left: 0px!important">
                                                   <div class="border-style brown-shade br-col-2">
                                                      <div class="element"
                                                         [ngClass]="darkMode ? 'element_dark': 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12 h-40"
                                                               style="padding-left: 5px!important">
                                                               <a data-toggle="tab" id="activeMultiplier"
                                                                  href="#setupmultiplier" (click)="activeMultiplier()">
                                                                  <div class="col-md-6">
                                                                     <label class="currency_label lbl-col rt-pad"
                                                                        [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                                        {{ 'manage_ota.settings_ota.rate_multiplier' |
                                                                        translate }} </label>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="status_box"> <span class="sp-adj"
                                                                           [ngClass]="darkMode ? 'sp-adj_dark':'sp-adj'">{{
                                                                           rate }}</span> </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row field-space">
                                          <div class="col-md-12">
                                             <div class="col-md-12">
                                                <div class="col-md-6 pr-5">
                                                   <div class="border-style blue-shade br-col-33">
                                                      <div class="element"
                                                         [ngClass]="darkMode ? 'element_dark': 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12 h-40">
                                                               <a data-toggle="tab" id="activeChannel"
                                                                  href="#setupchannel" (click)="activeChannel()">
                                                                  <div class="col-md-6">
                                                                     <label class="lbl-col"
                                                                        [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                                        style="padding-right: 77px;">{{
                                                                        'manage_ota.settings_ota.hotel_code' | translate
                                                                        }}</label>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="status_box"> <span class="spa-txt"
                                                                           [ngClass]="darkMode ? 'spa-txt_dark': 'spa-txt'">{{
                                                                           hotel_code }}</span> </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-6">
                                                   <form [formGroup]="settings_form">
                                                      <input type="hidden" value="{{ rate }}" name="rate"
                                                         formControlName="rate">
                                                      <input type="hidden" value="{{ username }}" name="username"
                                                         formControlName="username">
                                                      <input type="hidden" value="{{ password }}" name="password"
                                                         formControlName="password">
                                                      <input type="hidden" value="{{ hotel_code }}" name="hotel_code"
                                                         formControlName="hotel_code">
                                                   </form>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer ide4"><a class="btn btn-primary btn-lg"
                                 [ngClass]="darkMode ? 'foot-btn_dark': null" id="onSubmit" (click)="onSubmit()">{{
                                 'manage_ota.settings_ota.connect' | translate }}</a> <a class="btn btn-danger btn-lg"
                                 [ngClass]="darkMode ? 'foot-btn_close': null" data-dismiss="modal"
                                 aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate }}</a> </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Add new Ota/ Channel From Scratch Popup -->
<div [formGroup]="add_setup_ota" [hidden]="isModelShow">
   <div id="add_setup_ota" class="modal fade" data-backdrop="false" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
         <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
            <div class="modal-body modal-adjus" [ngClass]="darkMode?'modal-body_dark' : 'modal-body'"
               style="padding: 5px !important;">
               <div class="row">
                  <div class="col-md-2 popup-nav-padd rescol0">
                     <ul class="nav flex-column" [ngClass]="darkMode ? 'flex-column_dark': 'flex-column'"
                        style="height: 476px;">
                        <li class="nav-item active" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#setup_channel" id="setupchannel_li"> <img
                                 src="assets/img/newstaticicons/ota-channels/male.svg"> <span
                                 class="tb-adj tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br><strong>{{
                                    'manage_ota.settings_ota.channel' | translate }}</strong></span> </a>
                        </li>
                        <li class="nav-item" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#setup_multiplier" id="multiplier_li"
                              style="display: flex;"> <img
                                 src="assets/img/newstaticicons/ota-channels/mony-in-hand.svg"> <span
                                 class="tb-adj tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'"
                                 style="text-overflow: ellipsis;overflow: hidden;" data-placement="top"
                                 data-toggle="tooltip"
                                 title="{{ 'manage_ota.settings_ota.setup' | translate }} {{ 'manage_ota.settings_ota.multiplier' | translate }}">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br><strong>{{
                                    'manage_ota.settings_ota.multiplier' | translate }}</strong></span> </a>
                        </li>
                        <li class="nav-item" [ngClass]="darkMode ? 'nav-link_dark':'nav-link'">
                           <a class="nav-link" data-toggle="tab" href="#preview_setup" id="preview_li"
                              style="display: flex;"> <img src="assets/img/newstaticicons/ota-channels/search.svg">
                              <span data-toggle="tooltip" title="{{ 'manage_ota.settings_ota.preview' | translate }}"
                                 class="tb-adj ellipses tb-adj_dark " [ngClass]="darkMode ? 'tb-adj_dark':'tb-adj'">{{
                                 'manage_ota.settings_ota.setup' | translate }}<br><strong>{{
                                    'manage_ota.settings_ota.preview' | translate }}</strong></span> </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-10 pl-0 rescol">
                     <div class="tab-content scrollo">
                        <div class="tab-pane active" id="setup_channel">
                           <div class="pane-content pino" [ngClass]="darkMode ? 'pane-content_dark':'pane-content'">
                              <div class="alert alert-info alert-dismissible show" role="alert"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" (mouseover)="dodrag1()"
                                 (mouseout)="stopDrag1()"> {{ 'manage_ota.settings_ota.channel_butler_setup' | translate
                                 }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a
                                    class="btn btn-link al-lin" routerLink="/app/manage_otas" id="manageOta"
                                    data-dismiss="modal">{{ 'manage_ota.settings_ota.manage_otas' | translate }}</a> <a
                                    class="btn btn-link active al-lin" style="color: white">{{
                                    'manage_ota.settings_ota.setup_channel' | translate }}</a> </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div class="thr-tbs">
                                             <div class="border-style pink-shade thr-tbs-one ul-div element">
                                                <ul class="ul-up" [ngClass]="darkMode ? 'ul-up_dark': 'ul-up'">
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_1' | translate }}
                                                   </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_2' | translate }}
                                                   </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'manage_ota.settings_ota.bullet_3' | translate }}
                                                   </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text"> {{ 'rates_and_packages.extra.pricing_module' |
                                                      translate }} </li>
                                                   <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                      type="text">{{ 'rates_and_packages.extra.setup_policy' | translate
                                                      }}</li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper"><img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 25px;"> </div>
                                          <div class="upload-btn-wrapper"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="alert alert-danger" [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"
                                 style="background: linear-gradient(#e06c6d, #0c1f44);"> <a
                                    class="btn btn-link active c-inf" style="color: white;">{{
                                    'manage_ota.settings_ota.channel_credentials' | translate }}</a> </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row field-space">
                                    <div class="col-md-12">
                                       <div class="col-md-6 pr-5" style="padding-right: 3px !important;">
                                          <div class="border-style channel_shade">
                                             <input class="form-control in-adj "
                                                [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                placeholder="{{ 'manage_ota.settings_ota.channel_name' | translate }}"
                                                type="text" [(ngModel)]="name" formControlName="name" id="channel_name">
                                          </div>
                                       </div>
                                       <div class="col-md-6 pl-5" style="padding-left: 2px !important;">
                                          <div class="border-style website_shade">
                                             <input class="form-control in-adj"
                                                [ngClass]="darkMode ? 'in-adj_dark': 'in-adj'"
                                                placeholder="{{ 'manage_ota.settings_ota.channel_url' | translate }}"
                                                type="text" [(ngModel)]="website" formControlName="website">
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row field-space">
                                    <div class="col-md-12">
                                       <div class="col-md-6 pr-5" style="padding-right: 3px !important;">
                                          <div class="border-style br-col-44">
                                             <input class="form-control in-adj"
                                                placeholder="{{ 'manage_ota.settings_ota.user_name' | translate }}"
                                                type="text" [(ngModel)]="username" formControlName="username">
                                          </div>
                                       </div>
                                       <div class="col-md-6 pl-5" style="padding-left: 2px !important;">
                                          <div class="border-style br-col-5">
                                             <input class="form-control in-adj"
                                                placeholder="{{ 'manage_ota.settings_ota.password' | translate }}"
                                                type="password" [(ngModel)]="password" formControlName="password">
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row field-space">
                                    <div class="col-md-12">
                                       <div class="col-md-6 pr-5" style="padding-right: 3px !important;">
                                          <div class="border-style br-col-6_shadow">
                                             <input class="form-control in-adj"
                                                placeholder="{{ 'manage_ota.settings_ota.hotel_code' | translate }}"
                                                type="text" [(ngModel)]="hotel_code" formControlName="hotel_code">
                                          </div>
                                       </div>
                                       <div class="col-md-6 pl-5" style="padding-left: 2px !important;">
                                          <!-- <div class="border-style br-col-6_shadows"  *ngIf="pricing_data == 'Both'">
                                      <ng-select placeholder="Select Pricing Model" [ngClass]="darkMode ? 'card-dark-side-end' : ''" formControlName="pricing_model" [(ngModel)]="pricing_model" >
                                      <ng-option value="PerDayPricing">{{ 'rates_and_packages.extra.per_day_pricing' | translate}}</ng-option>
                                      <ng-option value="OccupancyBasedPricing">{{ 'rates_and_packages.extra.occupancy_base_pricing' | translate}}</ng-option>
                                      </ng-select>
                                   </div> -->
                                          <div class="border-style br-col-6_shadows"
                                             *ngIf="pricing_data == 'occupancy'">
                                             <ng-select placeholder="Select Pricing Model"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ''"
                                                formControlName="pricing_model" [(ngModel)]="pricing_model">
                                                <ng-option aria-selected="true" value="OccupancyBasedPricing">{{
                                                   'rates_and_packages.extra.occupancy_base_pricing' |
                                                   translate}}</ng-option>
                                             </ng-select>
                                          </div>
                                          <div class="border-style br-col-6_shadows"
                                             *ngIf="pricing_data == 'PerDayPricing'">
                                             <ng-select placeholder="Select Pricing Model"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ''"
                                                formControlName="pricing_model" [(ngModel)]="pricing_model">
                                                <ng-option aria-selected="true" value="PerDayPricing">{{
                                                   'rates_and_packages.extra.per_day_pricing' | translate}}</ng-option>
                                             </ng-select>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer addFooter ide5" style="right: 5px;"> <a
                                 class="btn btn-primary btn-lg" [ngClass]="darkMode ? 'foot-btn_dark': null"
                                 data-toggle="tab" (click)="activeSetupMultiplier()">{{ 'manage_ota.settings_ota.next' |
                                 translate }}</a> <a class="btn btn-danger btn-lg"
                                 [ngClass]="darkMode ? 'foot-btn_close': null" data-dismiss="modal"
                                 aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate }}</a> </div>
                        </div>
                        <div class="tab-pane" id="setup_multiplier">
                           <div class="pane-content pino">
                              <div class="alert alert-info alert-dismissible show" role="alert"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" (mouseover)="dodrag1()"
                                 (mouseout)="stopDrag1()"> {{ 'manage_ota.settings_ota.channel_butler_setup' | translate
                                 }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'"> <a
                                    class="btn btn-link al-lin" [routerLink]="manage_otas" data-dismiss="modal">{{
                                    'manage_ota.settings_ota.manage_otas' | translate }}</a> <a
                                    class="btn btn-link active al-lin" style="color: white">{{
                                    'manage_ota.settings_ota.set_rate_multiplier' | translate }}</a> </div>
                              <div class="card crd-mt" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div class="thr-tbs field-space">
                                             <div>
                                                <div>
                                                   <div class="border-style pink-shade tbs-br thr-tbs-one element pd-10"
                                                      [ngClass]="darkMode ? 'tbs_br_dark':'tbs_br'">
                                                      <p class="" placeholder="First Name" type="text"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                         innerHTML="{{ 'manage_ota.settings_ota.bullet_4' | translate }}">
                                                      </p>
                                                      <p class="" placeholder="First Name"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                         type="text"> {{ 'manage_ota.settings_ota.bullet_5' | translate
                                                         }} </p>
                                                      <p class="no-margin" placeholder="First Name"
                                                         [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                         type="text"> {{ 'manage_ota.settings_ota.bullet_6' | translate
                                                         }} </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="">
                                                <div class="">
                                                   <div class="">
                                                      <div class="col-md-12">
                                                         <div class="border-style br-col-4 enter-br"
                                                            style="margin: 0px 9px;">
                                                            <input class="form-control in-adj"
                                                               placeholder="{{ 'manage_ota.settings_ota.enter_rate_if_required' | translate }}"
                                                               type="number" [(ngModel)]="rate" formControlName="rate">
                                                            <i class="fas fa-info-circle icn" style="margin-left: 13px; font-size: 15px; position: absolute;
                                                     right: 38px; top:14px;cursor: pointer;"
                                                               [ngClass]="darkMode ? 'icn_dark' : 'icn' ">
                                                               <span class="tooltiptext"
                                                                  [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                                                                  style="width: 250px;padding: 0px 0px 5px 0px;left: -242px;">
                                                                  <p class="small text-danger"
                                                                     style="padding: 0px 5px;">
                                                                     {{ 'manage_ota.settings_ota.decimal_number' |
                                                                     translate }}
                                                                  </p>
                                                               </span>
                                                            </i>
                                                         </div>
                                                      </div>
                                                      <div class="col-md-7 zero-padding"> </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!-- <p class="small text-danger sp-sp-txt">
                                   <strong>

                                   </strong>
                                   </p> -->
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper"> <img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 22px;"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card crd-mt" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="">
                                       <div class="field-space">
                                          <div class="col-md-12 col-pd">
                                             <div class="col-md-12">
                                                <div class="border-style currency-shade al-br ">
                                                   <div class="element pd-10 one-line"
                                                      [ngClass]="darkMode? 'col-pd_dark': 'col-pd'">
                                                      <img class="img img-responsive img-class"
                                                         src="assets/img/newstaticicons/ota-channels/red-warning.svg"
                                                         style="width: 92px; padding:20px;">
                                                      <div class="al-mar"> <span class="al-span-one">
                                                            <strong>
                                                               <span class="change-bor">
                                                                  {{ 'manage_ota.settings_ota.please_note' | translate
                                                                  }}:
                                                               </span> </strong> {{ 'manage_ota.settings_ota.imp_note' |
                                                            translate }} </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer addFooter ide6" style="right: 5px;"> <a
                                 class="btn btn-primary btn-lg" [ngClass]="darkMode ? 'foot-btn_dark': null"
                                 data-toggle="tab" href="#preview_setup" (click)="activePreviewSetup()">{{
                                 'manage_ota.settings_ota.next' | translate }}</a> <a class="btn btn-danger btn-lg"
                                 [ngClass]="darkMode ? 'foot-btn_close': null" data-dismiss="modal"
                                 aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate }}</a> </div>
                        </div>
                        <div class="tab-pane" id="preview_setup">
                           <div class="pane-content pino">
                              <div class="alert alert-info alert-dismissible show" role="alert"
                                 [ngClass]="darkMode ? 'alert-info_dark':'alert-info'" (mouseover)="dodrag1()"
                                 (mouseout)="stopDrag1()"> {{ 'manage_ota.settings_ota.channel_butler_setup' | translate
                                 }}
                                 <button aria-label="Close" class="close" data-dismiss="modal" type="button"> <strong
                                       id="strong">&#x2716;</strong> </button>
                              </div>
                              <div class="alert alert-danger breadcrum"
                                 [ngClass]="darkMode ? 'alert-danger_dark': 'alert-danger'">
                                 <a class="btn btn-link al-lin" data-dismiss="modal">
                                    {{ 'manage_ota.settings_ota.manage_otas' | translate }}
                                 </a>
                                 <a class="btn btn-link active al-lin align-ch" style="color: white">
                                    {{ 'manage_ota.settings_ota.preview' | translate }}
                                    {{ 'manage_ota.settings_ota.setup' | translate }}
                                 </a>
                              </div>
                              <div class="card" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="col-md-10">
                                          <div class="thr-tbs">
                                             <div>
                                                <div style="height: 134px;"
                                                   class="border-style element pd-10 br-col-69 thr-tbs-one "
                                                   [ngClass]="darkMode ? 'tbs_br_dark':'tbs_br'">
                                                   <p class="" style="padding-top: 24px;"
                                                      [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"> {{
                                                      'manage_ota.settings_ota.note_preview' | translate }} </p>
                                                </div>
                                             </div>
                                          </div>
                                          <!-- <p class="small text-danger sp-sp-txt">
                                <strong>
                                    {{ 'manage_ota.settings_ota.review_information' | translate }}
                                </strong>
                                </p> -->
                                       </div>
                                       <div class="col-md-2 profile-img">
                                          <div class="img-wrapper"> <img class="img img-responsive img-class"
                                                src="assets/img/newstaticicons/ota-channels/buttler.svg"
                                                style="margin-top: 16px;"> </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card crd-mg" [ngClass]="darkMode ? 'card_dark': 'card'">
                                 <div class="row">
                                    <div class="col-md-12 one">
                                       <div class="row field-space">
                                          <div class="col-md-12">
                                             <div class="col-md-12">
                                                <div class="col-md-6 pr-5">
                                                   <div class="border-style currency-shade br-col-one"
                                                      [ngClass]="darkMode ? 'blue-shade_dark':'blue-shade'">
                                                      <div class="element"
                                                         [ngClass]="darkMode ? 'element_dark' : 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12">
                                                               <a data-toggle="tab" href="#setup_channel"
                                                                  (click)="activeChannelSetup()">
                                                                  <div class="col-md-6">
                                                                     <label class="currency_label lbl-col"
                                                                        [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                                        {{ 'manage_ota.settings_ota.channel_to_connect'
                                                                        | translate }} </label>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="status_box"> <span class="sp-adj"
                                                                           [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                                           *ngIf="name">{{ name }}</span> <span
                                                                           class="sp-adj"
                                                                           [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                                           *ngIf="!name">{{
                                                                           'manage_ota.settings_ota.enter_channel_name'
                                                                           | translate }}</span> </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-6 pl-5" style="padding-left: 0px!important">
                                                   <div class="border-style brown-shade br-col-2"
                                                      [ngClass]="darkMode ? 'border-style_dark' : 'null'">
                                                      <div cla ss="element"
                                                         [ngClass]="darkMode ? 'element_dark' : 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12" style="padding-left: 5px!important">
                                                               <a data-toggle="tab" (click)="activeSetupMultiplier()">
                                                                  <div class="col-md-6">
                                                                     <label class="currency_label lbl-col rt-pad"
                                                                        style="display: flex; align-items: center;"
                                                                        [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'">
                                                                        {{ 'manage_ota.settings_ota.rate_multiplier' |
                                                                        translate }} </label>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="status_box"> <span class="sp-adj"
                                                                           *ngIf="rate">{{ rate }}</span> <span
                                                                           class="sp-adj" *ngIf="!rate">{{
                                                                           'manage_ota.settings_ota.enter_rate' |
                                                                           translate }}</span> </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row field-space">
                                          <div class="col-md-12">
                                             <div class="col-md-12">
                                                <div class="col-md-6 pr-5">
                                                   <div class="border-style blue-shade_field br-col-3">
                                                      <div class="element"
                                                         [ngClass]="darkMode ? 'element_dark' : 'element'">
                                                         <div class="row">
                                                            <div class="col-md-12">
                                                               <a data-toggle="tab" href="#setup_channel"
                                                                  (click)="activeChannelSetup()">
                                                                  <div class="col-md-6">
                                                                     <label class="lbl-col"
                                                                        [ngClass]="darkMode ? 'ng-untouched_dark': 'ng-untouched'"
                                                                        style="padding-right: 77px;">{{
                                                                        'manage_ota.settings_ota.hotel_code' | translate
                                                                        }}</label>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="status_box"> <span class="sp-adj"
                                                                           *ngIf="hotel_code">{{ hotel_code }}</span>
                                                                        <span class="sp-adj" *ngIf="!hotel_code">{{
                                                                           'manage_ota.settings_ota.enter_hotel_code' |
                                                                           translate }}</span>
                                                                     </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-6" style="padding-left:0px;">
                                                   <form [formGroup]="add_setup_ota">
                                                      <input type="hidden" value="{{ rate }}" name="rate"
                                                         formControlName="rate">
                                                      <input type="hidden" value="{{ name }}" name="name"
                                                         formControlName="name">
                                                      <input type="hidden" value="{{ website }}" name="website"
                                                         formControlName="website">
                                                      <input type="hidden" value="{{ username }}" name="username"
                                                         formControlName="username">
                                                      <input type="hidden" value="{{ password }}" name="password"
                                                         formControlName="password">
                                                      <input type="hidden" value="{{ hotel_code }}" name="hotel_code"
                                                         formControlName="hotel_code">
                                                      <input type="hidden" value="{{ pricing_model }}" name="hotel_code"
                                                         formControlName="pricing_model">
                                                   </form>
                                                   <div class="col-md-12 iMain"
                                                      [ngClass]="darkMode ? 'iMian_dark' : ' iMain'">
                                                      <div class="icon_con" [ngClass]="darkMode ? 'icon_dark' : 'null'">
                                                         <i class=" fas fa-info-circle icn"
                                                            [ngClass]="darkMode ? 'icn_dark' : 'icn' "></i>
                                                      </div>
                                                      <div class="text_con"
                                                         [ngClass]="darkMode ? 'text_dark' : 'text_con' ">
                                                         <p class="small text-danger sp-sp-txt">
                                                            <strong>
                                                               {{ 'manage_ota.settings_ota.review_information' |
                                                               translate }}
                                                            </strong>
                                                         </p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer addFooter ide7" style="right: 5px;"> <a
                                 class="btn btn-primary btn-lg" [ngClass]="darkMode ? 'foot-btn_dark': null"
                                 (click)="onSetup()">{{ 'manage_ota.settings_ota.connect' | translate }}</a> <a
                                 class="btn btn-danger btn-lg" [ngClass]="darkMode ? 'foot-btn_close': null"
                                 data-dismiss="modal" aria-label="Close">{{ 'manage_ota.settings_ota.cancel' | translate
                                 }}</a> </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>