import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-ota-setup',
  templateUrl: './ota-setup.component.html',
  styleUrls: ['./ota-setup.component.scss']
})
export class OTASetupComponent implements OnInit {

  @Input('awaiting_channels') awaiting_channels: any;
  @Output() toggleChange = new EventEmitter<{ event: any, value: any, id: any }>();
  darkMode: boolean = false;

  constructor(public helper: HelperService) { }

  ngOnInit() {
    console.log(this.awaiting_channels, "awaiting_channels");
    this.checkDarkMode();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  toggle_change(e, val, id) {
    this.toggleChange.emit({ event: e, value: val, id: id });
  }

}
