import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  @Input('dashboard') dashboard: any;
  @Output() readReservation = new EventEmitter<any>();
  darkMode: boolean = false;
  colors = ['#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  color_border = ['#ff5e57', '#a07cc6', '#6af686', '#48fff3', '#b3bf00'];
  color_borders_dark = ['#f53232', 'green', 'purple', 'orange', 'blue', 'olive'];

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  readReserve(id: any) {
    this.readReservation.emit(id)
  }

}
