import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
declare var $: any;
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  weather_update = [];
  weather_classes = ['txt-orangered', 'txt-purple', 'txt-skyblue', 'txt-green', 'txt-blue', 'txt-orange', 'txt-aqua'];
  darkMode: boolean = false;

  constructor(
    public http: HttpClient,
    public weather: DashboardService,
  ) { }

  ngOnInit() {
    this.getWH();
    this.checkDarkMode()
  }

  getWH() {
    let cities = ["karachi"];
    //   var cities:any ='';
    // var prop;cities=null!=JSON.parse(localStorage.getItem("property"))?[(prop=JSON.parse(localStorage.getItem("property"))).city]:["Toronto"];
    cities.forEach(city => {
      let req = 'https://api.openweathermap.org/data/2.5/weather?q=' + city + '&units=imperial&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1&lang=en';
      let forcast = 'https://api.openweathermap.org/data/2.5/forecast/daily?q=' + city + '&cnt=7&lang=en&units=imperial&APPID=bfab95ebe3bbb8966c54139aefd539b4';
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split('.')[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString('en-us', { weekday: 'short' }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: []
        }

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach(element => {
            var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', { weekday: 'short' });
            var icon = element.weather[0].icon.split('.')[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
}
