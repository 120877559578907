<div id="settings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="row">
          <div class="col-md-2 popup-nav-padd">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#setupchannel">
                  <img src="assets/img/manage_otaz/male.png">
                  <span class="tb-adj">Setup<br><strong>Channel</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#setupmultiplier">
                  <img src="assets/img/manage_otaz/money_in_hand.png">
                  <span class="tb-adj">Setup<br><strong>Multiplier</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#preview">
                  <img src="assets/img/manage_otaz/search.png">
                  <span class="tb-adj"><strong>Preview</strong></span>
                </a>
              </li>

            </ul>
          </div>

          <div class="col-md-10 pl-0 pr-10">
            <div class="tab-content scrollo">
              <div class="tab-pane" id="preview">

                <div class="alert alert-info alert-dismissible show mb-ch" role="alert">
                  <p class="c-but-p ">
                    Channel Butler Setup
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button">
                    <strong>✖</strong>
                  </button>
                </div>
                <!-- <div class="alert alert-danger breadcrum">
                  <a href="" class="btn btn-link active c-inf ch-an">Channel Information</a>
                </div> -->
                <div class="alert alert-danger breadcrum">
                  <a class="btn btn-link al-lin" href="">Manage OTAs</a>
                  <a class="btn btn-link active al-lin align-ch" href="">Channel Information</a>
                </div>


                <div class="card crd-pb">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row field-space thr-tbs">
                        <div>
                          <div>
                            <div class="border-style pink-shade thr-tbs-one">

                              <p class="ng-untouched ng-pristine ng-valid tx-col">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo distinctio odio sunt adipisci officiis sit
                                tempora repellat alias magni eos ducimus iste eum.
                              </p>
                              <p class="ng-untouched ng-pristine ng-valid tx-col">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo distinctio odio sunt adipisci officiis sit
                                tempora repellat alias magni eos ducimus iste eum.
                              </p>
                              <p class="ng-untouched ng-pristine ng-valid tx-col">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo distinctio odio sunt adipisci officiis sit
                                tempora repellat alias magni eos ducimus iste eum.
                              </p>


                            </div>
                          </div>
                        </div>

                      </div>
                      <p class="small text-danger sp-txt rev-bx">
                        <strong>
                          Review information entered in the previous steps
                        </strong>
                      </p>

                    </div>
                    <div class="col-md-2 profile-img">
                      <div class="img-wrapper but-im">
                        <img class="img" src="assets/img/manage_otaz/buttler.png">

                      </div>
                      <div class="upload-btn-wrapper">

                      </div>
                    </div>
                  </div>
                </div>



                <div class="card crd-mg">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row field-space">
                        <div>

                        </div>
                        <div class="col-md-11 col-pd">
                          <div class="col-md-6">
                            <div class="border-style currency-shade br-col-one">
                              <div class="element">
                                <label class="currency_label lbl-col">
                                  Channel to Connect
                                </label>
                                <div class="status_box st-box-one">
                                  <img class="im-adj" src="assets/img/manage_otaz/traveloka.png">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="border-style brown-shade br-col-2">
                              <div class="element">
                                <label class="currency_label lbl-col rt-pad">
                                  Rate Multiplier
                                </label>
                                <div class="status_box st-box-two">
                                  <span class="sp-adj">None</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="row field-space">
                        <div class="col-md-11 cl-adj">
                          <div class="col-md-6">
                            <div class="border-style blue-shade br-col-3">
                              <div class="element">
                                <label class="lbl-col" style="padding-right: 77px;">Hotel Code</label>
                                <div class="status_box st-box-three">
                                  <span class="spa-txt">ABCD1234</span>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary btn-lg">Connect</button>
                  <a href="#" class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
              <div class="tab-pane active" id="setupchannel">

                <div class="alert alert-info alert-dismissible show mb-ch" role="alert">
                  <p class="cn-but">
                    Channel Butler Setup
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button">
                    <strong>✖</strong>
                  </button>
                </div>
                <!-- <div class="alert alert-danger breadcrum">
                  <a href="" class="btn btn-link active c-inf " style="
                  margin-left: -13px; font-size: 15px;">Setup Channel</a>
                </div> -->
                <div class="alert alert-danger breadcrum">
                  <a class="btn btn-link al-lin" href="">Manage OTAs</a>
                  <a class="btn btn-link active al-lin" href="">Setup Channel</a>
                </div>


                <div class="card pd-adj-cr">
                  <img src="assets/img/manage_otaz/traveloka.png" class="img-set">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row field-space thr-tbs">
                        <div>
                          <div>
                            <div class="border-style pink-shade thr-tbs-one ul-div">
                              <ul class="ul-up">

                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  In order to proceed with conecting to the selected Channel , you will need to have an
                                  established account with that channel.
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  If you already have an established account , please continue on to the next steps ,
                                  otherwise please contact the channel directly to sign-up
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  You will need information from that channel such as log-in credentials & hotel code to
                                  complete the remaing steps in this process
                                </li>
                              </ul>


                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                    <div class="col-md-2 profile-img img-adj">
                      <div class="img-wrapper but-im">
                        <img class="img" src="assets/img/manage_otaz/buttler.png">

                      </div>
                      <div class="upload-btn-wrapper">

                      </div>
                    </div>
                  </div>
                </div>
                <div class="alert alert-danger chan-ar">
                  <a href="" class="btn btn-link active c-inf" style="padding-right: 28px">Channel Credentials</a>
                </div>

                <div class="card">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row field-space">
                        <div>

                        </div>
                        <div class="col-md-12 col-pd">
                          <div class="col-md-6">
                            <div class="border-style br-col-4">
                              <input class="form-control in-adj" placeholder="User Name" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="border-style br-col-5">
                              <input class="form-control in-adj" placeholder="Password" type="text">
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="row field-space">
                        <div class="col-md-12 cl-adj">
                          <div class="col-md-6">
                            <div class="border-style br-col-6">
                              <input class="form-control in-adj" placeholder="Hotel Code" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary btn-lg">Next Step</button>
                  <a href="#" class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Skip</a>
                </div>
              </div>
              <div class="tab-pane" id="setupmultiplier">

                <div class="alert alert-info alert-dismissible show mb-ch" role="alert">
                  <p class="ch-bt">
                    Channel Butler Setup
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button">
                    <strong>✖</strong>
                  </button>
                </div>

                <!-- <div class="gradient-bar gr-adj">
                  <div class="col-md-12 rat-mul">
                    <h4 class="rat-h4">Set Rate Multiplier</h4>
                  </div>
                </div> -->
                <div class="alert alert-danger breadcrum">
                  <a class="btn btn-link al-lin" href="">Manage OTAs</a>
                  <a class="btn btn-link active al-lin" href="">Set Rate Multiplier</a>
                </div>


                <div class="card crd-mt">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row field-space thr-tbs">
                        <div>
                          <div>
                            <div class="border-style pink-shade thr-tbs-one">

                              <p class="ng-untouched ng-pristine ng-valid tx-col" placeholder="First Name" type="text">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo
                              </p>
                              <p class="ng-untouched ng-pristine ng-valid tx-col" placeholder="First Name" type="text">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo distinctio odio sunt adipisci officiis sit
                                tempora repellat alias magni eos ducimus iste eum.
                              </p>
                              <p class="ng-untouched ng-pristine ng-valid tx-col" placeholder="First Name" type="text">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias, nesciunt quam
                                minus maiores nostrum consectetur illo distinctio odio sunt adipisci officiis sit
                                tempora repellat alias magni eos ducimus iste eum distinctio odio sunt adipisci officiis
                                sit
                                tempora
                              </p>


                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row field-space">

                            <div class="col-md-12 col-pd enter-adj">
                              <div class="col-md-6">

                                <div class="border-style br-col-4 enter-br">
                                  <input class="form-control in-adj" placeholder="Enter rate if required" type="text">
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>

                      <p class="small text-danger sp-txt sp-sp-txt">
                        <strong>
                          Decimal number cannot be lower than 0.5
                        </strong>
                      </p>

                    </div>
                    <div class="col-md-2 profile-img">
                      <div class="img-wrapper but-im">
                        <img class="img" src="assets/img/manage_otaz/buttler.png">

                      </div>

                    </div>
                  </div>
                </div>



                <div class="card crd-mt">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row field-space">
                        <div>

                        </div>
                        <div class="col-md-12 col-pd">
                          <div class="col-md-12">
                            <div class="border-style currency-shade al-br">
                              <div class="element el-al-adj">
                                <img class="al-img" src="assets/img/alert/error.png" alt="">
                                <div class="al-mar">
                                  <span class="al-span-one">
                                    <strong>
                                      <span style="color: red; font-family: robotoregular;">
                                        Please Note :</span></strong> It is important to understand that all rates
                                    entered for this channel will be multiplied by the value enetered in the Rate
                                    Multiplier field before being sent to the booking channel . An incorrect Rate
                                    Multiplier means that you will be selling

                                    your rooms at the wrong price

                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <!-- ye best hai -->
                <!-- <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-lg">Next Setup</button>
                    <a href="#" class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Skip</a>
                  </div> -->
                <div class="modal-footer text-right">
                  <a href="#" class="btn btn-primary btn-lg">Connect</a>
                  <a href="#" class="btn btn-danger btn-lg">Cancel</a>
                </div>

              </div>
              <!-- ye end hai  -->
            </div>



          </div>
        </div>

      </div>

    </div>