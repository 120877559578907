import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ota-revenue',
  templateUrl: './ota-revenue.component.html',
  styleUrls: ['./ota-revenue.component.scss']
})
export class OTARevenueComponent implements OnInit {

  darkMode: boolean = false;
  @Input('dashboard') dashboard: any;
  @Output() dashboardDataOTA = new EventEmitter<any>();

  graphDaysota = [
    { name: 'Today' },
    { name: 'Previous Month' },
    { name: 'Current Month' }
  ];
  graphDaysPota;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  per(value, outOff) {
    let fv = (value * 100) / outOff;
    return fv > 20 ? fv : 50;
  }

  getDashboardDataota() {
    this.dashboardDataOTA.emit();
  }
}
