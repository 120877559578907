import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';

import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { NotFoundComponent } from './common/not-found/not-found.component';

import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { BackOfficeComponent } from './components/back-office/back-office.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TestComponent } from './components/test/test.component';
import { AccountSettingsComponent } from './components/back-office/account-settings/account-settings.component';
import { HotelSetupComponent } from './components/back-office/hotel-setup/hotel-setup.component';
import { CreateUsersComponent } from './components/back-office/create-users/create-users.component';
import { ManageUsersComponent } from './components/back-office/manage-users/manage-users.component';
import { CreateRoomsComponent } from './components/back-office/create-rooms/create-rooms.component';
import { ManageRoomsComponent } from './components/back-office/manage-rooms/manage-rooms.component';
import { ToastrModule } from 'ngx-toastr';
import { RequestInterceptor } from "./services/requestInterceptor";
import { ConfirmationAlertComponent } from './common/confirmation-alert/confirmation-alert.component';
import { SearchPipe } from './common/search.pipe';
import { RecursiveSearchPipe } from './common/recursive.pipe';
import { BillingComponent } from './components/back-office/billing/billing.component';
import { TermsOfUseComponent } from './components/back-office/terms-of-use/terms-of-use.component';
import { OnlineHelpComponent } from './components/back-office/online-help/online-help.component';
import { ManageOtasComponent } from './components/manage-otas/manage-otas.component';
import { InvoiceComponent } from './components/back-office/billing/invoice/invoice.component';
import { SubscriptionComponent } from './components/back-office/billing/subscription/subscription.component';
import { ManageHowYouPayComponent } from './components/back-office/billing/manage-how-you-pay/manage-how-you-pay.component';
import { PlanSwtichComponent } from './components/back-office/billing/plan-swtich/plan-swtich.component';
import { ContactSupportComponent } from './components/back-office/contact-support/contact-support.component';
import { SelectHotelComponent } from './components/back-office/select-hotel/select-hotel.component';
import { ManageRatesPackagesComponent } from './components/manage-rates-packages/manage-rates-packages.component';
import { Test2Component } from './components/test2/test2.component';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule, MatRippleModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { ManageRatesPackagesService } from './components/manage-rates-packages/manage-rates-packages.service';
import { SetupPackageComponent } from './components/manage-rates-packages/setup-package/setup-package.component';
import { SetupRateComponent } from './components/manage-rates-packages/setup-rate/setup-rate.component';
import { SetupRestrictionComponent } from './components/manage-rates-packages/setup-restriction/setup-restriction.component';
import { LinkOTAComponent } from './components/manage-rates-packages/link-ota/link-ota.component';
import { PreviewPackageComponent } from './components/manage-rates-packages/preview-package/preview-package.component';
import { ArrivalsComponent } from './components/arrivals/arrivals.component';
import { WithoutHeaderComponent } from './layouts/without-header/without-header.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { ReportsComponent } from './components/reports/reports.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { LogComponent } from './components/back-office/log/log.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreviewRoomsComponent } from './components/back-office/preview-rooms/preview-rooms.component';
import { SetupSeasonComponent } from './components/manage-rates-packages/setup-season/setup-season.component';
import { SetupSeasonRateComponent } from './components/manage-rates-packages/setup-season-rate/setup-season-rate.component';
import { SetupSeasonPolicyComponent } from './components/manage-rates-packages/setup-season-policy/setup-season-policy.component';
import { SetupSeasonOtaComponent } from './components/manage-rates-packages/setup-season-ota/setup-season-ota.component';
import { SetupSeasonPreviewComponent } from './components/manage-rates-packages/setup-season-preview/setup-season-preview.component';
import { SetupSeasonChannelsComponent } from './components/manage-rates-packages/setup-season-channels/setup-season-channels.component';
import { LinkChannelsComponent } from './components/manage-rates-packages/link-channels/link-channels.component';
import { PromoAllocationComponent } from './components/manage-rates-packages/promo-allocation/promo-allocation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PreviewHotelComponent } from './components/back-office/preview-hotel/preview-hotel.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ArrivalSkeletonComponent } from './components/arrivals/arrival-skeleton/arrival-skeleton.component';
import { InternalChannelsComponent } from './components/manage-otas/internal-channels/internal-channels.component';
import { LinkedOTAComponent } from './components/manage-otas/linked-ota/linked-ota.component';
import { OTASetupComponent } from './components/manage-otas/ota-setup/ota-setup.component';
import { WeatherComponent } from './components/dashboard/weather/weather.component';
import { FrontDeskComponent } from './components/dashboard/front-desk/front-desk.component';
import { ConnectivityStatusComponent } from './components/dashboard/connectivity-status/connectivity-status.component';
import { ReservationComponent } from './components/dashboard/reservation/reservation.component';
import { OTARevenueComponent } from './components/dashboard/ota-revenue/ota-revenue.component';
import { NotificationsComponent } from './components/dashboard/notifications/notifications.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    DashboardComponent,
    SidebarComponent,
    ForgotPasswordComponent,
    BackOfficeComponent,
    ResetPasswordComponent,
    TestComponent,
    AccountSettingsComponent,
    HotelSetupComponent,
    CreateUsersComponent,
    ManageUsersComponent,
    CreateRoomsComponent,
    ManageRoomsComponent,
    ConfirmationAlertComponent,
    SearchPipe,
    RecursiveSearchPipe,
    BillingComponent,
    TermsOfUseComponent,
    OnlineHelpComponent,
    ManageOtasComponent,
    InvoiceComponent,
    SubscriptionComponent,
    ManageHowYouPayComponent,
    PlanSwtichComponent,
    ContactSupportComponent,
    SelectHotelComponent,
    ManageRatesPackagesComponent,
    Test2Component,
    SetupPackageComponent,
    SetupRateComponent,
    SetupRestrictionComponent,
    LinkOTAComponent,
    PreviewPackageComponent,
    ArrivalsComponent,
    WithoutHeaderComponent,
    InventoryComponent,
    ReportsComponent,
    LogComponent,
    PreviewRoomsComponent,
    SetupSeasonComponent,
    SetupSeasonRateComponent,
    SetupSeasonPolicyComponent,
    SetupSeasonOtaComponent,
    SetupSeasonPreviewComponent,
    SetupSeasonChannelsComponent,
    LinkChannelsComponent,
    PromoAllocationComponent,
    PreviewHotelComponent,
    RegistrationComponent,
    ArrivalSkeletonComponent,
    InternalChannelsComponent,
    LinkedOTAComponent,
    OTASetupComponent,
    WeatherComponent,
    FrontDeskComponent,
    ConnectivityStatusComponent,
    ReservationComponent,
    OTARevenueComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      // positionClass: 'toast-bottom-center',
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }),
    NgxDaterangepickerMd.forRoot(),
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTableModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // VirtualScrollerModule,
    NgxSpinnerModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApiService,
    AuthService,
    ManageRatesPackagesService,
    AuthGuardService,
    HomeLayoutComponent,
    WithoutHeaderComponent,
    RequestInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    SearchPipe,
    RecursiveSearchPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
