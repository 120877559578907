<div class="" id="colShrt" style="padding-right: 5px;">

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '" style="height: 52px;">
    <a class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{"back_office.manage_rooms.back_office" | translate}}</a>
    <a class="btn btn-link active">{{"back_office.manage_rooms.manage_rooms" | translate}}</a>

    <div class="searchLeft">
      <div class="row" style="    margin-top: 5px; margin-right: -14px;">
        <div class="col-md-7 sear">
          <input type="text" class="form-control keyword" placeholder="{{'back_office.manage_rooms.search_by_keyword' | translate}}" [(ngModel)]="query" />
        </div>
        <div class="col-md-1 btngo" >
          <img src="assets/img/other/go.png" alt="" class=" gobtn" (click)="param = query" style="height:35px !important; margin-top:3px; margin-left: 3px;" (click)="conPadd()">
        </div>
        <div class=" col-md-4 btncreate" >
          <a [routerLink]="['/app/back_office/create_rooms']" class="btn btn-danger btn-block btn-lg hov " [ngClass]="darkMode ? 'darkBtn' : 'null'">+ {{ "back_office.manage_rooms.create_new_room" | translate }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="drag_box scroll no_scroll">
    <div class="card drag_item"  *ngFor="let room of rooms | search: 'bedtype,roomtype':param; let i = index" [ngClass]="darkMode ? 'card-dark' : 'card'" style="height: 150px;">
      <div class="row">
        <div class="col-md-3"[ngClass]="darkMode ? 'borrightdARKk' : 'borrightLightt'">

          <div id="myCarousel{{i}}" class="carousel slide myCarousel" data-ride="carousel" data-interval="false">
            <!-- Indicators -->

            <!-- Wrapper for slides -->
            <div class="carousel-inner">

              <div class="item" *ngFor="let img of room.images;let imgIndex = index" [ngClass]="imgIndex == 0  ? 'active' : null">
                <img [src]="api.imgUrl + img.image" alt="room images">
              </div>

            </div>

            <!-- Left and right controls -->
            <a class="left carousel-control" href="#myCarousel{{i}}" data-slide="prev" *ngIf="room.images.length > 1">
              <span class="glyphicon "><i class="fa fa-chevron-left"></i></span>
              <span class="sr-only">
                {{ "back_office.list.previous" | translate }}
                </span>
            </a>
            <a class="right carousel-control" href="#myCarousel{{i}}" data-slide="next" *ngIf="room.images.length > 1">
              <span class="glyphicon "><i class="fa fa-chevron-right"></i></span>
              <span class="sr-only">{{ "back_office.list.Next" | translate }}</span>
            </a>
          </div>

        </div>

        <div class="col-md-7" [ngClass]="darkMode ? 'borrightdARK' : 'borrightLight'">
          <h3 class="card-title">
            <span (click)="preview_pop(room.id)" class="" [ngStyle]="{'color': darkMode ? room.dark_mode : room.color_code}">
              {{ room.roomtype }}
              <span class="roomco totlo2">
                <span class="roomc" [ngStyle]="{'padding': room.room_count < 10 ? ' 3px 8px' : (room.room_count < 99 ? '3px 5px' : '6px 5px') }">{{room.room_count}}</span>
                <span class="tooltiptext " [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                  {{ "back_office.manage_rooms.room_count" | translate }}
                </span>
              </span>

            </span>
            <span *ngIf="room.is_duplicated == '1'" class="duplicate_text">( {{ "back_office.manage_rooms.duplicated" | translate }} )</span>
          </h3>
          <p class="room-content">
            {{ descripArray(room?.description) }}
            <img src="assets/img/manage_rates/dots.png" (mouseover)="seeDescription(i)" (mouseout)="seeDescription2(i)" style="height: 25px;cursor: pointer;" *ngIf="room?.description.split(' ').length > 60">
          </p>
          <div class=" description_text p-b" *ngIf="arri == i" [ngClass]="darkMode ? 'tooldes' : 'tooldeslight'"><p>{{ descripArray2(room?.description) }}</p></div>

          <div class="room-details" style="margin-top: 18px;">
            <ul class="nav navbar-nav pull-left">
              <li>
                <a class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg" [ngStyle]="darkMode ? {'margin' : '-7px 5px -5px 0px', 'height': '35px'} : {}" style="margin: -7px 5px -5px 0px;height: 35px;"/>
                  <span class="text-limit-155">{{ room.bedtype }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    {{ "back_office.bed_type" | translate }}
                  </span>
                </a>
              </li>
              <li>
                <a class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                  <span class="text-limit-15"> {{ room.adults + room.children + room.infants }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    {{ "back_office.manage_rooms.sleeps" | translate }}
                  </span>
                </a >
              </li>
              <li>
                <a *ngIf="room.room_size_type == 'square_feet'"  class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                  <span class="text-limit-15">{{ room.room_size }} Sqft</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    {{ room.room_size }} Sqft
                  </span>
                </a>
                <a *ngIf="room.room_size_type == 'meters'"  class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                  <span class="text-limit-15">{{ room.room_size }} m</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                   {{ "back_office.room_size" | translate }}
                  </span>
                </a>
              </li>
              <li>
                <a class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg" *ngIf="room?.pets == 'true'" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}"/>
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg" *ngIf="room?.pets == 'false'" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}"/>
                  <span class="text-limit-15">{{ "back_office.manage_rooms.pets" | translate }} {{ room.pets == 'false' ? 'Not Allowed' : 'Allowed'}} </span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                     {{ "back_office.manage_rooms.pets" | translate }}
                  </span>
                </a>
              </li>
              <li>
                <a *ngIf="room.wheelchairaccessibility == 'false'"  class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}"/>
                  <!-- Not Available -->
                  <span class="text-limit-15"> {{ "back_office.manage_rooms.Not_Available" | translate }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    <!-- Not Available -->
                    {{ "back_office.manage_rooms.Accessibility" | translate }}
                  </span>
                </a>
                <a *ngIf="room.wheelchairaccessibility == 'true'"  class="totlo">
                  <img  src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg" [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}"/>
                  <!-- Available -->
                  <span class="text-limit-15"> {{ "back_office.manage_rooms.Available" | translate }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    {{ "back_office.manage_rooms.Accessibility" | translate }}
                  </span>
                </a>
              </li>
              <li>
                <a *ngIf="room.smoking == 'false'"  class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg" [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}"/>
                  <!-- Not Allowed -->
                  <span class="text-limit-15">{{ "back_office.manage_rooms.Not_Allowed" | translate }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    <!-- Not Allowed  -->
                    {{ "back_office.manage_rooms.Smoking" | translate }}
                  </span>
                </a>
                <a *ngIf="room.smoking == 'true'"  class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg" [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}"/>
                  <!-- Allowed -->
                  <span class="text-limit-15">{{ "back_office.manage_rooms.Allowed" | translate }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    <!-- Allowed  -->
                    {{ "back_office.manage_rooms.Smoking" | translate }}
                  </span>
                </a>
              </li>
              <li>
                <a class="totlo">
                  <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/room-icon.svg" [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}"/>
                  <!-- Not Allowed -->
                  <span class="text-limit-15"> {{ room.display_name ||  room.roomtype }}</span>
                  <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                    <!-- Not Allowed  -->
                    <!-- {{ room.display_name}} -->Display Name
                  </span>
                </a>

              </li>
            </ul>

          </div>
        </div>

        <div class="col-md-2 col26" >
          <div class="neomocard" [ngClass]="darkMode ? 'neomocarddark' : 'neomocardlight'">
            <div>
              <a class="btn btn-channel link_to_ota_btn btn-hov" *ngIf="room.expedia_status == 'Inactive'" [ngClass]="darkMode ?  'btn-hov_dark' : 'btn-hov'" (click)="pushRoom(room.id)">{{ "rates_and_packages.menu.push" | translate }}</a>
              <a class="btn btn-channel link_to_ota_btn btn-hov" *ngIf="room.expedia_status == 'Active'" [ngClass]="darkMode && room.expedia_status == 'Active' ?  'btn-hov_dark live-btn' : 'btn-hov live-btn'" style="cursor: default;">LIVE</a>
            </div>
            <div class="card_ui">
              <span style="float: right;margin-top: 2px;">
                <a href="javascript:void(0)" class=""  (click)="openchModal2(room)" style=" margin-right: 5px;">
                  <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/amenities-01.svg' : 'assets/img/newstaticicons/back-office/amenities-01.svg'}}" alt="" class="ichove" style="width: 29px;">
                </a>
                <a href="javascript:void(0)" class=""  (click)="openchModal(room.id)" style=" margin-right: 5px;">
                  <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/await.svg' : 'assets/img/newstaticicons/back-office/await.svg'}}" alt="" class="ichove" style="width: 29px;">
                </a>
                <a routerLink="/app/back_office/create_rooms/{{ room.id }}"  style="margin-right: 5px;">
                  <img width="30px" src="assets/img/newstaticicons/back-office/edit.svg" class="ichove" (click)="passVar()"/>
                </a>
                <a href="javascript:void(0)" (click)="delete(room.id, room.roomtype)" class="" data-toggle="modal" data-target="#confirmationModal">
                  <img width="30px" src="assets/img/newstaticicons/back-office/delete.svg" class="ichove"/>
                </a>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="card" *ngIf="rooms?.length == 0" [ngClass]="darkMode ? 'card-dark' : 'card'">
    {{ "back_office.manage_rooms.sorry_no_rooms_available" | translate }}
  </div>

  <div class="modal-footer mymodal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
    <a class="btn btn-danger btn-lg hov" data-dismiss="modal"  (click)="hideModal()" aria-label="Close">{{ "back_office.manage_rooms.close" | translate }}</a>
  </div>
</div>





  <div id="draggable" *ngIf="showBox == true" >
         <div class="modal-dialog">

        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
          [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
          style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;" (mouseover)="drag('do')"
          (mouseout)="drag('dont')">
          <!-- {{ "inventory.table.channels" | translate }} -->
          {{"back_office.manage_rooms.CONNECTIVITY" | translate }}
          <button aria-label="Close" class="close hor" type="button" (click)="closeModal()">
            <strong>&#x2716;</strong>
          </button>
        </div>

    <div class="scroll"   style="height: 296px;"   [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#f0f0f0'}">
     <div class="col-md-12 editable-column pr-0" style="padding-right: 5px; margin-top: 5px;">
      <div class=" col-md-6 pl-0 pr-0">
        <label class="allLabel" for="allSelectOta">
          <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">OTA's</div>
          <input class="checkbox" name="booked_date" id="allSelectOta" type="checkbox" (click)="selectAllOta($event)" />
          <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
        </label>
        <ng-container *ngFor="let ota of otas">
          <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null"
            *ngIf="ota.type == 'External'">
            <label>
              <span class="con-chan">
                <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota?.image : ota?.image}}" alt=""
                  class="image-137 pr-5 pl-5" style="width: 96%;" />
              </span>
              <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}"
                (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
              <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
            </label>
          </div>
        </ng-container>
      </div>

      <div class=" col-md-6 pl-0 pr-0">
        <label class="allLabel" for="allSelectChannel" style="padding-right: 5px;">
          <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
          <input class="checkbox" name="booked_date" id="allSelectChannel" type="checkbox"
            (click)="selectAllCh($event)" />
          <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
        </label>
        <ng-container *ngFor="let ota of channels">
          <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null"
            *ngIf="ota.type == 'Internal'">
            <label>
              <span class="con-chan">
                <img src="assets/img/svgicons/channels/{{ota?.image}}" alt="" class="image-137 pr-5 pl-5"
                  style="width: 96%;" />
              </span>
              <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}"
                (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
              <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

    <button type="button" class="form-control save-buton save-chan btn-hov-save"
      [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name=""
      id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
      {{ "inventory.promo.save" | translate }}
    </button>
  </div>
 </div>


  <div id="draggable2" *ngIf="showBox2 == true" [ngClass]="darkMode ? 'AmiBack' : 'null'">

    <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5" [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '" style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;">
      {{ "inventory.promo.amenities" | translate }}
      <button aria-label="Close" class="close hor" type="button" (click)="closeModal()">
        <strong>&#x2716;</strong>
      </button>
    </div>

    <div class="cardBox" [ngClass]="darkMode ? 'AmiBack' : 'null'">

      <div class="row" style="    padding: 0px 15px 0px 20px;">
        <div class="col-md-6 zero-padding">
          <ng-container *ngFor="let ami of aminity;let i = index">
            <div class="col-md-12 AmiCard-C4" *ngIf="i%2 != 0" >
              <div class="cardAmenity">
                <div class="row" >
                <div class="col-md-12 pr20">

                  <div class="line">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="icoAmeni">
                          <img src="assets/img/{{darkMode ? 'create-rooms/darkmode' : 'create-rooms'}}/{{ami.icon}}" alt="ameniti image" class="amiIcon">
                        </div>
                        <div  class="nameAmeni">
                          <span [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                          <a data-toggle="collapse" href="#collapse{{i}}">
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" id="dropDown{{i}}" class="drop" alt="" (click)="changeDrop('dropDown'+i)">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{i}}" >
                  <p *ngFor="let child of ami.children">{{child.title}}</p>
                </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>


        <div class="col-md-6 zero-padding">
          <ng-container *ngFor="let ami of aminity;let i = index">
            <div class="col-md-12 AmiCard-C4" *ngIf="i%2 == 0" >
              <div class="cardAmenity">
                <div class="row" >
                  <div class="col-md-12 pr20">
                    <div class="line">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="icoAmeni">
                            <img src="assets/img/{{darkMode ? 'create-rooms/darkmode' : 'create-rooms'}}/{{ami.icon}}" alt="ameniti image" class="amiIcon">
                          </div>
                          <div  class="nameAmeni">
                            <span [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                            <a data-toggle="collapse" href="#collapse{{i}}">
                              <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" id="dropDown{{i}}" class="drop" alt="" (click)="changeDrop('dropDown'+i)">
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{i}}" >
                    <p *ngFor="let child of ami.children">{{child.title}}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

      </div>

    </div>

  </div>
