import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { HelperService } from "../../services/helper.service";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    user;
    darkMode: boolean = false;

    constructor(
      public events: EventsService,
      public helper: HelperService,
    ) {
      this.events.receiveDarkMode().subscribe((res:any)=>{
        this.darkMode = res.darkMode;
      });
      this.helper.user_subject.subscribe(user => {
        this.user = user;
      });
    }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.checkDarkMode();
    }

    checkDarkMode(){
      let a = JSON.parse(localStorage.getItem('user'));
      if(a != null){
        let mode = a.mode;
        if(mode == 'dark'){
          this.darkMode = true;
        }
      }
    }





}





