<div class="dd" (click)="helper.selectMe = 1" [ngStyle]="{'opacity' : helper.selectMe == 1 ? '1' : '0.3'}">
    <ul class="nav navbar-nav full-width" [ngClass]="darkMode ? 'full-width_dark':'full-width'"
        style="border-radius: 10px;" [ngClass]="{'opac': helper.opac !== 3 ? true : false}">
        <li class="awaiting-li" [ngClass]="darkMode ? 'awaiting-li_dark':'awaiting-li'" (click)="helper.opac = 3"
            style="border-radius: 10px;">
            <a data-toggle="tooltip" data-placement="top" title="{{ 'manage_ota.otas_awaiting_setup' | translate }} "
                class=" myA btn btn-link awaiting-anchor"
                [ngClass]="darkMode ? 'awaiting-anchor_dark':'awaiting-anchor'"
                style="border-radius: 10px 30px 30px 10px; text-overflow: ellipsis;width: 155px;white-space: nowrap;display: inline-block;overflow: hidden;color: #fff; font-weight: 600;border:none">
                <span> {{ 'manage_ota.otas_awaiting_setup' | translate }} </span> </a>
        </li>
        <li class="add-setup" [ngClass]="{'not-allowed': helper.opac !== 3 ? true : false}">
            <a class="myA" class="btn btn-link btn-hover chj" [ngClass]="darkMode ? 'myA_dark':'myA'"
                data-target="#add_setup_ota" data-toggle="modal"
                style="border-radius: 0px; box-shadow: none; text-align: inherit; padding-left: 6px; margin-right: 20px;border-radius: 0px 30px 30px 0px; padding-right: 20px; box-shadow: 5px 0px 12px -5px #00000082; color: #fff; font-weight: 600;border:none"
                onMouseOver="this.style.background='linear-gradient(#f96061, #860425)'"
                onMouseOut="this.style.background='linear-gradient(#f96061, #860425)'">
                + {{ 'manage_ota.add_new_ota' | translate }}
            </a>
        </li>
    </ul>
    <div class="no_scroll scroll">
        <div class="grid_20 " style="padding-top:0px"
            [ngStyle]="{ 'pointer-events': helper.selectMe == 1 ? 'auto' : 'none'}">
            <div id="drag-box-ota scroll no_scroll" style="padding-top: 5px;">
                <div id="drag-item-ota" *ngFor="let awaiting of awaiting_channels"
                    style="padding-left: 1px; padding-right: 0px;">
                    <div class="shade"
                        [ngClass]="awaiting.image!==null ? 'shade-' + awaiting.image.split('.')[0] : 'no-shade'">
                        <div class="border-height">
                            <div class="dark_gradient" [ngClass]="darkMode ? 'dark_gradient_dark':'dark_gradient'">
                                <div class="uper">
                                    <a
                                        *ngIf="awaiting.type !== 'Custom' && awaiting.status == 'Awaiting' && awaiting.image!==null">
                                        <div *ngIf="awaiting.image">
                                            <img *ngIf="awaiting.type == 'External'"
                                                src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + awaiting.image : awaiting.image }}"
                                                alt="" class="ota-icon">
                                            <img *ngIf="awaiting.type == 'Internal'"
                                                src="assets/img/svgicons/channels/{{ awaiting.image }}" alt=""
                                                class="ota-icon">
                                        </div>
                                    </a>
                                    <a *ngIf="awaiting.type !== 'Custom' && awaiting.status !== 'Awaiting'"
                                        (click)="toggle_change($event, awaiting.connected, awaiting.id)">
                                        <div *ngIf="awaiting.image">
                                            <img *ngIf="awaiting.type == 'External'"
                                                src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + awaiting.image : awaiting.image }}"
                                                alt="" style="cursor: text;margin-top: 5px; height: 70px; width:170px;">
                                            <img *ngIf="awaiting.type == 'Internal'"
                                                src="assets/img/svgicons/channels/{{ awaiting.image }}" alt=""
                                                style="cursor: text;margin-top: 5px; height: 70px; width:170px;">
                                        </div>
                                    </a>
                                    <a *ngIf="awaiting.type === 'Custom'">
                                        <div *ngIf="!awaiting.image" class="name-display">
                                            <p class="external-name"> {{ awaiting.name }} </p>
                                        </div>
                                    </a>
                                    <div class="pull-right toggle-btn toggle-adj"
                                        [ngClass]="awaiting.image==null ? 'toggle-fix' : ''">
                                        <mat-slide-toggle [disabled]="'disabled'" [color]="'accent'"> </mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="between between1">
                                    <div class="inline inline1">
                                        <span class="setting-pdg"
                                            [ngClass]="darkMode ? 'setting-pdg_darkK':'setting-pdg'"
                                            style="padding-left: 0px;">{{ 'manage_ota.commission' | translate }}</span>
                                        <div class="bold-text pull-right"
                                            [ngClass]="darkMode ? 'bold-text_dark':'bold-text'">
                                            <div style="display: inline-block; font-family: 'robotoblack';"> {{
                                                awaiting.commission}} </div>
                                            <span class="percent"
                                                [ngClass]="darkMode ? 'percent_dark':'percent'">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lower" [ngClass]="darkMode ? 'lower_dark':'lower'">
                                <a *ngIf="awaiting.pr_status !== 'Awaiting'"
                                    (click)="toggle_change($event, awaiting.connected, awaiting.id)">
                                    <span class="span-weight btn-hov"
                                        [ngClass]="darkMode ? 'span-weight_dark':'span-weight'"
                                        style="text-decoration: none !important;">
                                        {{ 'manage_ota.click_to_Setup' | translate }}
                                    </span>
                                </a>
                                <a *ngIf="awaiting.pr_status == 'Awaiting'">
                                    <span class="span-weight" [ngClass]="darkMode ? 'span-weight_dark':'span-weight'"
                                        style="text-decoration: none !important;">
                                        {{ 'manage_ota.awaiting' | translate }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>