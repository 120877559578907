import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
declare var $:any;
@Component({
  selector: 'app-create-rooms',
  templateUrl: './create-rooms.component.html',
  styleUrls: ['./create-rooms.component.css']
})
export class CreateRoomsComponent implements OnInit {
  open_custom = true;
  room;
  amenities;
  roomtypes;
  bedtypes;
  bedtype;
  photos;
  photo_list = [];
  submitted=false;
  room_name_images=[];
  langCode;
  aaa = [];
  building_detail = [];
  catchVar:any;
  amenCol = ['#a9aae0','#d24f51','#bf4eeb','#efab20','#32d04a','#468fab']

  wheelT:boolean = false;
  smoke:boolean = false;
  pet:boolean = false;

  parking:boolean = false;
  wifi:boolean = false;
  breakfast:boolean = false;


  roomclass;
  bedSize;
  roomView;


  prevSave = undefined

  public matForm ;
  @ViewChild('photo_input', {static: false}) phIn: any;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  sleeps_adult;
  sleeps_infants;
  sleeps_children;
  create_room_form: FormGroup;

  age_adult;
  age_infants;
  age_children;
  wheelChair:boolean;

  addInclu;
  darkMode: boolean = false;

  extraBed:boolean = false;
  Cribs:boolean = true;
  Rollaway:boolean = true;
  dayBed:boolean = false;

  bedExtra;

  pAmount;

  cType = 'Crib';
  cQuantity;
  cSurcharge_type = 'Free';
  cAmount;

  cSurcharge_amount_type;

  rType = 'Rollaway Bed';
  rBedType;
  rQuantity;
  rSurcharge_type = 'Free';
  rAmount;
  rSurcharge_amount_type;

  dType;
  dBedType;
  dQuantity;
  extraBedA = [{name: ' Cribs', val : 'cribs'}, {name: ' Rollaway', val: 'rollaway'}]

  ngOnInit() {
    this.checkDarkMode();
    if(this.route.snapshot.paramMap.get('id')){
        this.get_room(this.route.snapshot.paramMap.get('id'));
        this.open_custom = true;
    }

    this.matForm =  document.getElementById("matForm")

    this.getLangCode()
    this.get_amenities();
    this.get_roomtypes();
    this.get_bedtypes();
    this.create_room_form = this.fb.group({
      bed_type_id: [null,Validators.required],
      no_of_beds: ['',Validators.required],
      display_name: '',
      room_count: ['',Validators.required],
      room_size: ['',Validators.required],
      adults: ['',Validators.required],
      children: ['',Validators.required],
      infants: ['',Validators.required],
      room_type_id: [null, Validators.required],
      property_id:'',

      description:['',Validators.required],
      most_amenties:'',
      add_most_amenite:'',
      images:'',
      room_name_images:'',
      custom_room_type:null,
      room_type_class:  [null, Validators.required],
      room_size_type: [null, Validators.required],
      bedsize:  [null, Validators.required],
      room_view_id: [null, Validators.required],
      adults_age: ['', Validators.required],
      children_age: ['', Validators.required],
      infants_age: ['', Validators.required],
      wheelchairaccessibility: this.wheelT,
      pets: this.pet,
      smoking: this.smoke,
      parkings: this.parking,
      wifis: this.wifi,
      breakfasts: this.breakfast,

      value_add_inclusion:[null, Validators.required],
      extrabedding: ''
    });
    this.f['room_type_id'].valueChanges.subscribe((res) => {
      this.create_room_form.value.room_type_id = res == '' ? '' : res;
      this.on_sec_change();
    });
    this.building_detail = [];
    this.catchVar = history.state;

    $(function(){
      $("#CRoom").mouseup(function(){
        $("#grabingg").css({"cursor":"grab"})
      });
      $("#CRoom").mousedown(function(){
        $("#grabingg").css({"cursor":"grabbing"})
      });
    })

  }

  getLangCode(){
    var value = localStorage.getItem("code");
    this.langCode = value
  }

  test(option){
    setTimeout(function(){
      this.matForm.classList.remove('mat-focused' )
    }, 100);
  }

  get f() { return this.create_room_form.controls; }

  get_amenities(){
    this.api.post('api/amenitie/index', {order: 'other', type: 'Room','parent_id':'Null'}, true).subscribe((res:any) => {
      this.amenities = res.body.data;
      this.amenities.forEach((amenity) => {
        amenity.show_add = false;
      });
    }, err => this.error_handling.handle_error(err.status));
  }

  on_sec_change(){
    let que = this.create_room_form.value.room_type_id;
    if (que == '') {
        this.open_custom = false;
    }
  }

  add_custom_roomtype(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/roomtype/store', {property_id:property_id,name: this.create_room_form.value.custom_room_type, description:this.create_room_form.value.custom_room_type}, true).subscribe((res:any) => {
    this.helper.alert_success("Room Type Added Successfully...");
    this.open_custom=true;
    this.get_roomtypes();
    }, err => this.error_handling.handle_error(err.status));
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  get_roomtypes(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/roomtype/index?per_page=50', {property_id:property_id},true).subscribe((res:any) => {
      this.roomtypes = res.body.data.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_bedtypes(){
    this.api.get('api/helper/bed_types', true).subscribe((res:any) => {
      this.bedtypes = res.body.data.filter((bt)=> bt.type != "extra");
      this.bedExtra = res.body.data.filter((bt)=> bt.type == "extra");
    }, err => this.error_handling.handle_error(err.status));

    this.api.get('api/helper/room_class', true).subscribe((res:any) => {
      this.roomclass = res.body.data;
    }, err => this.error_handling.handle_error(err.status));

    this.api.get('api/helper/room_view', true).subscribe((res:any) => {
      this.roomView = res.body.data;
    }, err => this.error_handling.handle_error(err.status));

    this.api.post('api/helper/value_add_inclusion',{type: 'Standalone'}, true).subscribe((res:any) => {
      this.addInclu = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  async onFileChange(event){
    if(event.target.files && event.target.files[0]){
      for(var i=0; i < event.target.files.length; i++){
        var r = null;
        var f = event.target.files[i];
        var data = new FormData();
        data.append('image', event.target.files[i], event.target.files[i].name);
        await this.api.imgPost('api/helper/upload_image', data, false).subscribe((img_res:any) => {
          this.room_name_images.push({image:img_res.body.data.image});
        }, err => this.error_handling.handle_error(err.status));

        var reader = new FileReader();
        reader.onload = (eve:any) => {
          this.photo_list.push({url: eve.target.result, file: f});
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    this.phIn.nativeElement.value = '';
  }

  remove_photo(index){
    if(this.room) {
      try {
        this.api.get('api/room_gallery/destory/'+this.photo_list[index].id,true).subscribe((res:any) => {
        }, err => this.error_handling.handle_error(err.status));
      } catch (error) {
      }
    }
    this.photo_list.splice(index, 1);
  }

  uncheck(id){
    try {
      var del = (<HTMLInputElement>document.getElementById(id)).value;
      this.api.get('api/room_amenitie/destory/'+del+'/'+this.room.id,true).subscribe((res:any) => {
      }, err => this.error_handling.handle_error(err.status));
    } catch (error) {
    }
    return  (<HTMLInputElement>document.getElementById(id)).checked = false;
  }

  calculate(type, id) {
    var current = Number((<HTMLInputElement>document.getElementById(id)).value);
    if(type=='-'){
      if(current > 0){
        this[id]=current - 1;
        return current = current - 1;
      }
    }
    else if(type=='+'){
      if(current >= 0){
        this[id]=current + 1;
        return  current = current + 1;
      }
    }
  }

  calculateAge(type, id) {
    var current = Number((<HTMLInputElement>document.getElementById(id)).value);
    if(type=='-'){
      if(current > 0){
        this[id]=current - 1;
        return current = current - 1;
      }
    }
    else if(type=='+'){
      if(current >= 0){
        this[id]=current + 1;
        return  current = current + 1;
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    var most_arr =[];
    var property_id = localStorage.getItem("current_property");
    var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
    Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value});});
    this.create_room_form.patchValue({property_id:property_id,most_amenties: most_arr,
      room_name_images: this.room_name_images
    });
    if (this.create_room_form.invalid) {
      return;
    }

    if(this.room) {


      this.create_room_form.value.smoking = this.create_room_form.value.smoking.toString();
      this.create_room_form.value.wheelchairaccessibility = this.create_room_form.value.wheelchairaccessibility.toString();
      this.create_room_form.value.pets = this.create_room_form.value.pets.toString();
      if(this.extraBed == true){
        this.create_room_form.value.extrabedding = [


          {
            type: this.cType,
            quantity: this.cQuantity,
            size: 'Crib',
            surcharge_type:this.cSurcharge_type,
            surcharge_amount_type: this.cSurcharge_amount_type,
            surcharge_amount: this.cAmount
          },
          {
            type: this.rType,
            quantity: this.rQuantity,
            bed_type: this.rBedType,
            size:"Full",
            surcharge_type:this.rSurcharge_type,
            surcharge_amount_type: this.rSurcharge_amount_type,
            surcharge_amount: this.rAmount
          },
          // {
          //   type: this.dType,
          //   quantity: this.dQuantity,
          //   bed_type: this.dBedType,
          // }
        ];
      }


      this.api.post('api/room/update/'+this.room.id, this.create_room_form.value,true).subscribe((res:any) => {
        this.helper.alert_success("Room Updated Successfully...");
      }, err => this.error_handling.handle_error(err.status));
      return;
    }

    this.roomtypes.map((val)=>{
      if(val.id == this.create_room_form.value.room_type_id){
        this.create_room_form.value.room_type_name = val.name;
      }
    })

    this.bedtypes.map((val)=>{
      if(val.id == this.create_room_form.value.bed_type_id){
        this.create_room_form.value.bed_type_name = val.name;
      }
    })

    this.amenities.map((val)=>{
      val.children.map((val2)=>{
        this.create_room_form.value.most_amenties.map((val3)=>{
          if(val2.id == val3.amenitie_id){
            this.aaa.push({
              title: val2.title,
              parentId: val.id,
              parentTitle:val.title
            })

          }
        })
      })

    })

    if(this.extraBed == true){
      this.create_room_form.value.extrabedding = [
        {
          type: this.cType,
          quantity: this.cQuantity,
          size: 'Crib',
          surcharge_type:this.cSurcharge_type,
          surcharge_amount_type: this.cSurcharge_amount_type,
          surcharge_amount: this.cAmount
        },
        {
          type: this.rType,
          quantity: this.rQuantity,
          bed_type: this.rBedType,
          size:"Full",
          surcharge_type:this.rSurcharge_type,
          surcharge_amount_type: this.rSurcharge_amount_type,
          surcharge_amount: this.rAmount
        }
      ];
    }

    this.create_room_form.value.amenities_title = this.aaa;
    this.create_room_form.value.floors_data = this.building_detail;
    this.router.navigate(['/app/back_office/preview-room'],  { state: this.create_room_form.value })
  }

  add_amenitie(order, parent_id){
    var type='Room';
    var status = 'Enabled';
    var title ='';
    if(order=='other') {
      title=this.create_room_form.value.add_most_amenite;
    }
    var data = {title:title,order:order,status:status,type:type,parent_id:parent_id,property_id:localStorage.getItem('current_property')};
    this.api.post('api/amenitie/store',data,true).subscribe((res:any) => {
      setTimeout(() => {
        this.get_amenities();
      }, 2000);
    }, err => this.error_handling.handle_error(err.status));
  }

  get_room(id){
    this.api.get('api/room/show/'+id, true).subscribe((res:any) => {
      this.room = res.body.data;
      this.prevSave = id
      this.setchecked(this.room.amenities);
      Object.keys(this.room.images).forEach(key => {this.photo_list.push({id:this.room.images[key].id,url: this.api.imgUrl + this.room.images[key].image});});
      setTimeout(()=>{
        this.create_room_form.patchValue(this.room);
      }, 500);

      this.wheelT = this.room.wheelchairaccessibility.toLowerCase() == 'true' ? true : false;
      this.create_room_form.patchValue({wheelchairaccessibility: this.wheelT});

      this.smoke = this.room.smoking.toLowerCase() == 'true' ? true : false;;
      this.create_room_form.patchValue({smoking: this.smoke});

      this.pet = this.room.pets.toLowerCase() == 'true' ? true : false;;
      this.create_room_form.patchValue({pets: this.pet});

      this.parking = this.room.parkings.toLowerCase() == 'true' ? true : false;
      this.create_room_form.patchValue({parkings: this.parking});

      this.wifi = this.room.wifis.toLowerCase() == 'true' ? true : false;;
      this.create_room_form.patchValue({parkings: this.wifi});

      this.breakfast = this.room.breakfasts.toLowerCase() == 'true' ? true : false;;
      this.create_room_form.patchValue({breakfasts: this.breakfast});

      if(this.room.extrabedding.length > 0){
        this.extraBed = true;
        let crib = this.room.extrabedding.find((e)=> e.type == "Crib");
        let roll = this.room.extrabedding.find((r)=> r.type == "Rollaway Bed");

        if(crib){
          this.Cribs = true;
          this.cType = crib.type;
          this.cQuantity = crib.quantity;
          this.cSurcharge_type = crib.surcharge_type;
          this.cAmount = crib.surcharge_amount;
          this.cSurcharge_amount_type = crib.surcharge_amount_type;
        }

        if(roll){
          this.Rollaway = true;
          this.rType = roll.type;
          this.rBedType = roll.size;
          this.rQuantity =  roll.quantity;
          this.rSurcharge_type = roll.surcharge_type;
          this.rAmount = roll.surcharge_amount;
          this.rSurcharge_amount_type = roll.surcharge_amount_type;
        }
      }

    }, err => this.error_handling.handle_error(err.status));
  }

  setchecked(data){
    var checked_data = data;
    setTimeout(()=>{
      checked_data.forEach(element => {
        try {
          (<HTMLInputElement>document.getElementById('trash-'+element.amenitie_id)).checked = true;
        }
        catch(err) {
        }
      },2000)

    });
  }

  updateRoom(id){
    this.submitted = true;
    var most_arr =[];
    var property_id = localStorage.getItem("current_property");
    var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
    Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value});});
    this.create_room_form.patchValue({property_id:property_id,most_amenties: most_arr,
      room_name_images:this.room_name_images
    });
    if (this.create_room_form.invalid) {
      return;
    }
    this.api.post('api/room/update/'+id, this.create_room_form.value,true).subscribe((res:any) => {
      this.helper.alert_success("Room Updated Successfully...");
      this.create_room_form.reset();
    }, err => this.error_handling.handle_error(err.status));
  }

  changeWheel(){
    this.wheelT = !this.wheelT;
    this.create_room_form.patchValue({wheelchairaccessibility: this.wheelT});
  }

  changeSmoke(){
    this.smoke = !this.smoke;
    this.create_room_form.patchValue({smoking: this.smoke});
  }

  changePet(){
    this.pet = !this.pet;
    this.create_room_form.patchValue({pets: this.pet});
  }


  changeparking(){
    this.parking = !this.parking;
    this.create_room_form.patchValue({parkings: this.parking});
  }

  changewifi(){
    this.wifi = !this.wifi;
    this.create_room_form.patchValue({wifis: this.wifi});
  }

  changebreakfast(){
    this.breakfast = !this.breakfast;
    this.create_room_form.patchValue({breakfasts: this.breakfast});
  }


  bedChange(e, bedtype){
    this.bedSize = e.bed_sizes;
  }

  selectExtraBed(){
    setTimeout(() => {
      this.extraBed = !this.extraBed;
    })
  }

  checkCribs(e){
    this.Cribs = !this.Cribs;
    if(this.Cribs){
      this.cType = 'Crib';
    }
  }

  checkRollaway($event){
    this.Rollaway = !this.Rollaway;
    if(this.Rollaway){
      this.rType = 'Rollaway Bed';
    }
  }

  checkdayBed(e){
    this.dayBed = !this.dayBed;
    if(this.dayBed){
      this.dType = 'Day Bed';
    }
  }

  hideModal()
  {
    let modal = document.getElementById('settings')
      modal.classList.remove('show');
      modal.classList.add('hide');
  }

}
