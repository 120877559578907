<div class="jumbotron ">
    <div class="jumbo-icon">
        <li class="  ota-reservation-hold" data-value="Reservations" title="" text="Main title!"
            style="list-style-type:none ;">
            <div class="dash_window no_margin w-clearfix dashboard-card dragDash" id="div3"
                [ngClass]="darkMode ? 'dash_window_dark' : 'dash_window'">
                <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'win_header'" title="">
                    <h4 class="heading" [ngClass]="darkMode ? 'heading_dark' : 'heading'">
                        {{ 'dashboard.Notifications' | translate }}</h4>
                </div>
                <div class="scroll no_scroll" [ngClass]="darkMode ? 'no_scroll_dark' : 'no_scroll'">

                    <div class="notifications w-clearfix" *ngFor="let resrv of dashboard?.reservations; let i = index"
                        [ngClass]="darkMode ? 'notifications_dark' : 'notifications greenNoti' && resrv?.read_status == 'unread' ? 'redn  oti': 'greenNoti'"
                        (click)="readReserve(resrv?.booking_id)" [ngStyle]="{'border-color': colors[i%colors.length]}">
                        <div class="notiImg">
                            <img src="assets/img/newstaticicons/dashboard/open-envelop.svg" alt="" class="unread_icon"
                                *ngIf="resrv?.read_status != 'unread'">
                            <img src="assets/img/newstaticicons/dashboard/close-envelop.svg" style="height: 30px;"
                                alt="" class="unread_icon" *ngIf="resrv?.read_status == 'unread'">
                        </div>
                        <div class="noti_content w-clearfix">
                            <div class="noti_text w-clearfix">
                                <p class="paragraph"
                                    [ngStyle]="{'color': color_borders_dark[i%color_borders_dark.length]}"
                                    *ngIf="darkMode == false">{{resrv?.text}}</p>
                                <p class="paragraph" [ngStyle]="{'color': color_border[i%color_border.length]}"
                                    *ngIf="darkMode == true">{{resrv?.text}}</p>
                            </div>
                            <div class="noti_sec_left w-clearfix" *ngIf="resrv?.persons != undefined">
                                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/person_dark.png' : 'assets/img/newstaticicons/dashboard/person.svg'}}"
                                    alt="" class="image-4">
                                <h6 class="heading-3" [ngClass]="darkMode ? 'heading-3_dark' : 'heading-3'">
                                    {{resrv?.persons}} {{'dashboard.Notificationss.persons' |
                                    translate }}</h6>
                            </div>
                            <div class="noti_sec_right w-clearfix" *ngIf="resrv?.time != undefined">
                                <h6 class="heading-5" [ngClass]="darkMode ? 'heading-5_dark': 'heading-5'">
                                    {{resrv?.time}}</h6>
                                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/month_dark.png' : 'assets/img/newstaticicons/dashboard/month.svg'}}"
                                    alt="" class="image-7">
                            </div>

                            <div class="noti_sec_mid w-clearfix" *ngIf="resrv?.nights != undefined">
                                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/night_dark.png': 'assets/img/newstaticicons/rates-packages/night.svg' }}"
                                    alt="" class="image-5">
                                <h6 class="heading-4" [ngClass]="darkMode ? 'heading-4_dark':'heading-4'">
                                    {{resrv?.nights}} {{ 'dashboard.Notificationss.nights' |
                                    translate }}</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </li>
    </div>
</div>