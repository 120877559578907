<div class="row mainheader" style="position: fixed; z-index: 99;background-color: #282B93;">
  <div class="col-md-3 col-sm-3">
      <div class="logo-inline">
          <div class="logo-text"><img class="image-height" src="assets/img/terms_of_use/Asset.png" alt=""></div>
      </div>
  </div>

  <div class="col-md-6 col-sm-6">
      <div class="heading">

      </div>
  </div>

  <div class="terms_of_use_select col-md-3 padding col-sm-3">
      <!-- <ng-select class="selector" [(ngModel)]="language">
              <ng-option value="English" >English</ng-option>
              <ng-option value="German">German</ng-option>
              <ng-option value="French">French</ng-option>
              <ng-option value="Duetch">Duetch</ng-option>
              <ng-option value="Italiano">Italiano</ng-option>
            </ng-select> -->
      <div id="google_translate_element"></div>
  </div>
</div>
<div class="container-fluid no_scroll scroll" style="padding: 0;padding: 0;overflow: scroll;height: 100vh;overflow-x: hidden;    width: 88.6vw;
left: -2%;">
  <div class="row back-color-1">
    <img src="assets/img/terms_of_use/term-full-size.png" alt="" class="image-38" />
    <div class="div-block-15"></div>
    <div class="col-md-12 col-sm-12">
    </div>
  </div>

  <div class="row">
    <div class="bg-image" [ngClass]="darkMode ? 'card_dark' : 'bg-image'">
        <div class="section-2" [ngClass]="darkMode ? 'sec-1' : 'section-2'">
            <div class="container-3 w-container">
                <!-- <div class="text-block-10 getting-started table bottom-pd cont">Table of Content</div> -->
                <!-- <div class="w-col w-col-2"></div> -->
                <div class="columns-2 w-row">
                    <div class="column-4 w-col w-col-4 panel-group" id="terms_of_use">
                        <div class="div-block-12">
                            <div class="html-embed w-embed">
                                <hr>
                            </div>
                            <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                            <div>
                                <div class="">
                                    <div class="dropdown-4 w-dropdown">
                                        <div class="dropdown-toggle-4 w-dropdown-toggle">

                                            <a data-parent="#terms_of_use" href="main/terms_of_use#termscondition"
                                                class="text-block-11">{{ 'back_office.term_of_use.terms' | translate }}
                                                <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                                            </a>
                                        </div>
                                        <div class="html-embed w-embed">
                                            <hr>
                                        </div>
                                        <!-- <div id="collapse1" class="text panel-collapse collapse ">
                            <a href="#" class="w-dropdown-link">Link 1</a>
                            <a href="#" class="w-dropdown-link">Link 2</a>
                            <a href="#" class="w-dropdown-link">Link 3</a>
                         </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-col w-col-4">
                        <div class="div-block-12">
                            <div class="html-embed w-embed">
                                <hr>
                            </div>
                            <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                            <div class="" id="terms_of_use">
                                <div class="">
                                    <div class="dropdown-4 w-dropdown">
                                        <div class="dropdown-toggle-4 w-dropdown-toggle">

                                            <a data-parent="#terms_of_use" href="main/terms_of_use#privacypolicy"
                                                class="text-block-11">{{ 'back_office.term_of_use.policy' | translate }}
                                                <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                                            </a>
                                        </div>
                                        <div class="html-embed w-embed">
                                            <hr>
                                        </div>
                                        <!-- <div id="collapse4" class="text panel-collapse collapse ">
                               <a href="main/online_help#policy4" class="w-dropdown-link">Privacy Policy</a>
                               <a [routerLink] = "['/']"  fragment="policy4"> Privacy Policy</a>
                               <a href="#" class="w-dropdown-link">Link 2</a>
                               <a href="#" class="w-dropdown-link">Link 3</a>
                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-col w-col-4">
                        <div class="div-block-12">
                            <div class="html-embed w-embed">
                                <hr>
                            </div>
                            <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                            <div class="" id="terms_of_use">
                                <div class="">
                                    <div class="dropdown-4 w-dropdown">
                                        <div class="dropdown-toggle-4 w-dropdown-toggle">

                                            <a data-parent="#terms_of_use" href="main/terms_of_use#serviceagreement"
                                                class="text-block-11">{{ 'back_office.term_of_use.service' | translate }}
                                                <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                                            </a>
                                        </div>
                                        <div class="html-embed w-embed">
                                            <hr>
                                        </div>
                                        <!-- <div id="collapse4" class="text panel-collapse collapse ">
                                   <a href="main/online_help#policy4" class="w-dropdown-link">Privacy Policy</a>
                                   <a [routerLink] = "['/']"  fragment="policy4"> Privacy Policy</a>
                                   <a href="#" class="w-dropdown-link">Link 2</a>
                                   <a href="#" class="w-dropdown-link">Link 3</a>
                                </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" id="termscondition" [ngClass]="darkMode ? 'container_dark' : 'container'">

            <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
                    [ngClass]="darkMode ? 'service_s':'null'">T</span>erms <span
                    [ngClass]="darkMode ? 'terms_a':'null'">&</span>&nbsp;<span
                    [ngClass]="darkMode ? 'service_a':'null'">C</span>ondition
            </div>
            <div class="translation panel-group" id="terms_of_use">
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse1" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_1')">
                            1. {{ 'back_office.term_of_use.parties' | translate }}
                            <img id="term1"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term1')">
                        </a>


                    </div>
                    <hr class="hr-pd">
                    <div id="collapse1" class="text panel-collapse collapse"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.parties_text' | translate }}

                        </p>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse2" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_2')">
                            2.{{ 'back_office.term_of_use.definitions' | translate }}
                            <img id="term2"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term2')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse2"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_service' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_terms' | translate }}
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_customer' | translate }}
                        </p>

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_property' | translate }}
                        </p>

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_agency' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_platform' | translate }}

                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_web' | translate }}

                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.definition_comm' | translate }}
                        </p>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse3" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_3')">
                            3.  {{ 'back_office.term_of_use.subject_of_the_terms' | translate }}
                            <img id="term3"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term3')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse3"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                        {{ 'back_office.term_of_use.term_one' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.term_two' | translate }}
                        </p>

                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse4" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            4.  {{ 'back_office.term_of_use.rights_obligation' | translate }}
                            <img id="term4"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term4')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse4"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_one' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_two' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_three' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_four' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_five' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_six' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_seven' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_eight' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_nine' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_ten' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_eleven' | translate }}
                        </p>
                        <div class="cardcolor">
                            <div class="head">

                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.rights_twelve' | translate }}
                                </p>
                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.rights_thirteen' | translate }}
                                </p>
                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.rights_fourteen' | translate }}
                                </p>
                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.rights_fifteen' | translate }}
                                </p>
                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.rights_sixteen' | translate }}
                                </p>
                            </div>
                        </div>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.rights_seventeen' | translate }}
                        </p>
                    </div>

                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse5" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_5')">
                            5. {{ 'back_office.term_of_use.platform_term' | translate }}
                            <img id="term5"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term5')">
                        </a>


                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse5"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_one' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_two' | translate }}
                        </p>
                        <div class="cardcolor">
                            <div class="head">

                                <p class="indent sub-bullet-points"> {{ 'back_office.term_of_use.platform_three' | translate }}
                                </p>
                                <p class="indent sub-bullet-points">  {{ 'back_office.term_of_use.platform_four' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="textarea text-mr">
                        </div>


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_five' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_six' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_seven' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_eight' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_nine' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_ten' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_thirteen' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_eleven' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.platform_twelve' | translate }}
                        </p>

                    </div>

                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse6" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_6')">
                            6. {{ 'back_office.term_of_use.intellectual_property' | translate }}
                            <img id="term6"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term6')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse6"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.intellect_one' | translate }}
                        </p>


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.intellect_two' | translate }}
                        </p>

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.intellect_three' | translate }}
                        </p>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse7" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_7')">
                            7.  {{ 'back_office.term_of_use.limitation_of_liabilities' | translate }}
                            <img id="term7"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term7')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse7"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">



                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_one' | translate }}
                        </p>




                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_two' | translate }}

                        </p>


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_three' | translate }}
                        </p>

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_four' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_five' | translate }}
                        </p>

                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.limit_six' | translate }}
                        </p>


                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse8" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_8')">
                            8. {{ 'back_office.term_of_use.duration_and_termination' | translate }}
                            <img id="term8"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term8')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse8"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">




                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_one' | translate }}
                        </p>






                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_two' | translate }}
                        </p>





                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_three' | translate }}
                        </p>



                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_four' | translate }}
                        </p>


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_five' | translate }}
                        </p>



                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_six' | translate }}
                        </p>


                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.point_seven' | translate }}
                        </p>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse9" class="collapsed abc"
                            [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_9')">
                            9.{{ 'back_office.term_of_use.miscellaneous' | translate }}
                            <img id="term9"
                            src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                            alt="" class="imagedrop" (click)="chngedrop('term9')">
                        </a>

                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapse9"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">





                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_one' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_two' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_three' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_four' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_five' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_six' | translate }}
                        </p>
                        <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                          {{ 'back_office.term_of_use.mis_seven' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- privacy policy starts -->
        <div class="container" id="privacypolicy" [ngClass]="darkMode ? 'container_darkk' : 'containerr'">
            <div class="text-block-12 head-2 color" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
                    [ngClass]="darkMode ? 'privacy_p':'null'">P</span>rivacy <span
                    [ngClass]="darkMode ? 'privacy2_p':'null'">P</span>olicy
            </div>
            <div class="w-container">
                <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy1"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            1. {{ 'back_office.term_of_use.Purpose' | translate }}
                            <img id="privacy1"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy1')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy1"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Purpose_text' | translate }}
                        </p>
                    </div>
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy2"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            2.   {{ 'back_office.term_of_use.Disclosure_of_Information' | translate }}
                            <img id="privacy2"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy2')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy2"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Disclosure_of_Information_textone' | translate }}
                        </p>

                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Disclosure_of_Information_texttwo' | translate }}
                        </p>
                    </div>
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy3"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            3.  {{ 'back_office.term_of_use.Property’s_Transactions' | translate }}
                            <img id="privacy3"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy3')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy3"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Property’s_Transactions_text' | translate }}
                        </p>
                    </div>
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy4"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            4.   {{ 'back_office.term_of_use.Disclosure_of_Third_Party' | translate }}
                            <img id="privacy4"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy4')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy4"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Disclosure_of_Third_Party_text' | translate }}
                        </p>

                        <div class="cardcolorr">
                            <div class="headd">
                                <p class="indent sub-bullet-points">
                                  {{ 'back_office.term_of_use.Disclosure_of_Third_Party_one' | translate }}
                                </p>

                                <p class="indent sub-bullet-points">
                                  {{ 'back_office.term_of_use.Disclosure_of_Third_Party_two' | translate }}
                                </p>
                                <p class="indent sub-bullet-points">
                                  {{ 'back_office.term_of_use.Disclosure_of_Third_Party_three' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy5"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            5. {{ 'back_office.term_of_use.System_Related_Issues' | translate }}
                            <img id="privacy5"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy5')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy5"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.System_Related_Issues_text' | translate }}
                        </p>
                    </div>
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy6"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            6.  {{ 'back_office.term_of_use.Transfer_of_Data' | translate }}
                            <img id="privacy6"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy6')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy6"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Transfer_of_Data_text' | translate }}
                        </p>
                    </div>


                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy7"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            7.  {{ 'back_office.term_of_use.Website_&_Application' | translate }}
                            <img id="privacy7"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy7')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy7"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Website_&_Application_text' | translate }}</p>
                    </div>

                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy8"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            8.  {{ 'back_office.term_of_use.Amendments' | translate }}
                            <img id="privacy8"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy8')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy8"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Amendments_text' | translate }}
                          </p>
                    </div>

                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy9"
                            class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                            (click)="arrow_toggle('col_4')">
                            9. {{ 'back_office.term_of_use.Compliance' | translate }}
                            <img id="privacy9"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                class="imagedrop" alt="" (click)="chngedrop('privacy9')">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div class="text panel-collapse collapse" id="collapsePrivacy9"
                        [ngClass]="darkMode ? 'text_dark' : 'text'">
                        <p class="indent bullet-points">
                          {{ 'back_office.term_of_use.Compliance_text' | translate }}
                        </p>
                    </div>


                </div>
            </div>
        </div>


        <!-- privacy policy ends -->





        <!-- <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'" id="serviceagreement"><span [ngClass]="darkMode ? 'service_s':'null'">S</span>ervice <span [ngClass]="darkMode ? 'service_a':'null'">A</span>greement
            </div> -->

        <div class="container" id="serviceagreement" [ngClass]="darkMode ? 'container_dark' : 'container'">

            <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
                    [ngClass]="darkMode ? 'service_s':'null'">S</span>ervice <span
                    [ngClass]="darkMode ? 'service_a':'null'">A</span>greement
            </div>
            <div class="w-container">
                <div class="translation panel-group" id="terms_of_use">
                    <div class="panel panel-default">
                        <div class="abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse1"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a1')">
                                1.  {{ 'back_office.term_of_use.Terms_of_Hosting_Service' | translate }}
                                <!-- {{ "back_office.term_of_use.parties" | translate }} -->
                                <img id="service1"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service1')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse1" class="text panel-collapse collapse"
                            [ngClass]="darkMode ? 'text_dark' : 'text'">
                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">

                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_one' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_two' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_three' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_four' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_five' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_six' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_seven' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_eight' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_nine' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_ten' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_eleven' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_twelve' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Terms_of_Hosting_Service_thirteen' | translate }}
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse2"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a2')">
                                2.  {{ 'back_office.term_of_use.Registration_and_Administration' | translate }}
                                <img id="service2"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service2')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse2" class="text panel-collapse collapse">
                            <div class="service_content">
                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_one' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_two' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_three' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_four' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_five' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_six' | translate }}
                                </p>

                                <p class="indent bullet-points bullet-points"
                                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                                    {{ 'back_office.term_of_use.Registration_and_Administration_seven' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse3"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a3')">
                                3. {{ 'back_office.term_of_use.Payment_System' | translate }}
                                <img id="service3"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service3')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse3" class="text panel-collapse collapse">

                            <div class="service_content">

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Payment_System_one' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Payment_System_two' | translate }}
                                </p>


                                <div class="cardcolor">
                                    <div class="head">
                                        <div class="service_contentt">

                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_three' | translate }}
                                            </p>
                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_four' | translate }}
                                            </p>
                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_five' | translate }}
                                            </p>
                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_six' | translate }}
                                            </p>
                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_seven' | translate }}
                                            </p>

                                            <p class="indent sub-bullet-points">
                                              {{ 'back_office.term_of_use.Payment_System_eight' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Payment_System_nine' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Payment_System_ten' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Payment_System_eleven' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse4"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a4')">
                                4.  {{ 'back_office.term_of_use.Management_Service' | translate }}
                                <img id="service4"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service4')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse4" class="text panel-collapse collapse">

                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_one' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_two' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_three' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_four' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_five' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_six' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_seven' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_eight' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_nine' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_ten' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_eleven' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_twelve' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_thirteen' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Management_Service_fourteen' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse5"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a5')">
                                5.{{ 'back_office.term_of_use.TermsofContentManagementService' | translate }}
                                <img id="service5"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service5')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse5" class="text panel-collapse collapse">

                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofContentManagementService_one' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofContentManagementService_two' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofContentManagementService_three' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofContentManagementService_four' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofContentManagementService_five' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse6"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a6')">
                                6. {{ 'back_office.term_of_use.TermsofPromotionService' | translate }}
                                <img id="service6"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service6')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse6" class="text panel-collapse collapse">
                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofPromotionService_one' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofPromotionService_two' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.TermsofPromotionService_three' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse7"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a7')">
                                7. {{ 'back_office.term_of_use.EngineOptimizationService' | translate }}
                                <img id="service7"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service7')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse7" class="text panel-collapse collapse">

                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.EngineOptimizationService_one' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.EngineOptimizationService_two' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.EngineOptimizationService_three' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.EngineOptimizationService_four' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="abc">
                            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse8"
                                class="collapsed abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false"
                                (click)="arrow_toggle('col_a8')">
                                8.{{ 'back_office.term_of_use.Miscellaneous_Terms_Regarding_Services' | translate }}
                                <img id="service8"
                                src="assets/img/reports/{{darkMode ? 'button_downn' : 'button_down'}}.svg"
                                alt="" class="imagedrop" (click)="chngedrop('service8')">
                            </a>
                        </div>
                        <hr class="hr-pd">
                        <div id="servicecollapse8" class="text panel-collapse collapse">

                            <div class="service_content">
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Miscellaneous_Terms_Regarding_Services_one' | translate }}
                                </p>

                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Miscellaneous_Terms_Regarding_Services_two' | translate }}
                                </p>
                                <p class="indent bullet-points" [ngClass]="darkMode ? 'text_dark' : 'text'">
                                  {{ 'back_office.term_of_use.Miscellaneous_Terms_Regarding_Services_three' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

