<div *ngFor="let x of getEmptyElements(16); let i = index" class="col-md-12 border-div"
    style="page-break-before: always; padding: 0px">
    <ul class="grey-ul skeleton-animation" id="ko" [ngClass]="darkMode ? 'dark-border-div' : 'null'">
        <li style="width: 10%">
            <div class="skeleton-box " [ngClass]="getGreyType(i)"></div>
        </li>
        <li style="width: 13.5%">
            <div class="skeleton-box" [ngClass]="getGreyType(i+2)"></div>
        </li>
        <li style="width: 10.5%">
            <div class="skeleton-box " [ngClass]="getGreyType(i+1)"></div>
        </li>
        <li style="width: 10.5%">
            <div class="skeleton-box" [ngClass]="getGreyType(i)"></div>
        </li>
        <li style="width: 11.5%">
            <div class="skeleton-box " [ngClass]="getGreyType(i+2)"></div>
        </li>
        <li style="width: 11.5%">
            <div class="skeleton-box" [ngClass]="getGreyType(i+1)"></div>
        </li>
        <li style="width: 12%">
            <div class="skeleton-box" [ngClass]="getGreyType(i)"></div>
        </li>
        <li style="width: 12%">
            <div class="skeleton-box" [ngClass]="getGreyType(i+2)"></div>
        </li>
        <li class="totalLast">
            <div class="skeleton-box " [ngClass]="getGreyType(i+1)"></div>
        </li>
    </ul>
</div>