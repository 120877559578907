import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuardService } from '../app/services/auth-guard.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BackOfficeComponent } from './components/back-office/back-office.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { AccountSettingsComponent } from './components/back-office/account-settings/account-settings.component';
import { HotelSetupComponent } from "./components/back-office/hotel-setup/hotel-setup.component";
import { CreateUsersComponent } from "./components/back-office/create-users/create-users.component";
import { ManageUsersComponent } from "./components/back-office/manage-users/manage-users.component";
import { CreateRoomsComponent } from "./components/back-office/create-rooms/create-rooms.component";
import { ManageRoomsComponent } from "./components/back-office/manage-rooms/manage-rooms.component";
import { BillingComponent } from "./components/back-office/billing/billing.component";
import { OnlineHelpComponent } from './components/back-office/online-help/online-help.component';
import { TermsOfUseComponent } from './components/back-office/terms-of-use/terms-of-use.component';
import { InvoiceComponent } from './components/back-office/billing/invoice/invoice.component';
import { ManageHowYouPayComponent } from './components/back-office/billing/manage-how-you-pay/manage-how-you-pay.component';
import { SubscriptionComponent } from './components/back-office/billing/subscription/subscription.component';
import { ContactSupportComponent } from './components/back-office/contact-support/contact-support.component';
import { SelectHotelComponent } from './components/back-office/select-hotel/select-hotel.component';
import { ManageOtasComponent } from './components/manage-otas/manage-otas.component';
import { ManageRatesPackagesComponent } from './components/manage-rates-packages/manage-rates-packages.component';
import { SetupPackageComponent } from './components/manage-rates-packages/setup-package/setup-package.component';
import { SetupRateComponent } from './components/manage-rates-packages/setup-rate/setup-rate.component';
import { SetupRestrictionComponent } from './components/manage-rates-packages/setup-restriction/setup-restriction.component';
import { LinkOTAComponent } from './components/manage-rates-packages/link-ota/link-ota.component';
import { PreviewPackageComponent } from './components/manage-rates-packages/preview-package/preview-package.component';
import { ArrivalsComponent } from './components/arrivals/arrivals.component';
import { WithoutHeaderComponent } from './layouts/without-header/without-header.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LogComponent } from './components/back-office/log/log.component';
import {TranslateModule} from '@ngx-translate/core';
import { PreviewRoomsComponent } from './components/back-office/preview-rooms/preview-rooms.component';
import { SetupSeasonComponent } from './components/manage-rates-packages/setup-season/setup-season.component';
import { SetupSeasonRateComponent } from './components/manage-rates-packages/setup-season-rate/setup-season-rate.component';
import { SetupSeasonPolicyComponent } from './components/manage-rates-packages/setup-season-policy/setup-season-policy.component';
import { SetupSeasonOtaComponent } from './components/manage-rates-packages/setup-season-ota/setup-season-ota.component';
import { SetupSeasonChannelsComponent } from './components/manage-rates-packages/setup-season-channels/setup-season-channels.component';
import { SetupSeasonPreviewComponent } from './components/manage-rates-packages/setup-season-preview/setup-season-preview.component';
import { LinkChannelsComponent } from './components/manage-rates-packages/link-channels/link-channels.component';
import { PromoAllocationComponent } from './components/manage-rates-packages/promo-allocation/promo-allocation.component';
import { PreviewHotelComponent } from './components/back-office/preview-hotel/preview-hotel.component';
import {RegistrationComponent} from './components/registration/registration.component';
import { from } from 'rxjs';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'registration', component: RegistrationComponent},
  {path: 'forgot_password', component: ForgotPasswordComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'app', component: HomeLayoutComponent, children: [
    {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
    {path: 'back_office', component: BackOfficeComponent, canActivate: [AuthGuardService], children: [
        {path: 'account_settings', component: AccountSettingsComponent, canActivate: [AuthGuardService]},
        {path: 'hotel_setup', component: HotelSetupComponent, canActivate: [AuthGuardService]},
        {path: 'create_users', component: CreateUsersComponent, canActivate: [AuthGuardService]},
        {path: 'create_users/:id', component: CreateUsersComponent, canActivate: [AuthGuardService]},
        {path: 'manage_users', component: ManageUsersComponent, canActivate: [AuthGuardService]},
        {path: 'create_rooms', component: CreateRoomsComponent, canActivate: [AuthGuardService]},
        {path: 'create_rooms/:id', component: CreateRoomsComponent, canActivate: [AuthGuardService]},
        {path: 'manage_rooms', component: ManageRoomsComponent, canActivate: [AuthGuardService]},
        {path: 'select_hotel', component: SelectHotelComponent, canActivate: [AuthGuardService]},
        {path: 'preview-room', component: PreviewRoomsComponent, data:{}, canActivate: [AuthGuardService]},
        {path: 'preview-hotel', component: PreviewHotelComponent, data:{}, canActivate: [AuthGuardService]},
    ]},
    {path: 'back_office/billing', component: BillingComponent, canActivate: [AuthGuardService]},
    {path: 'back_office/billing/invoice', component: InvoiceComponent, canActivate: [AuthGuardService]},
    {path: 'back_office/billing/manage_how_you_pay', component: ManageHowYouPayComponent, canActivate: [AuthGuardService]},
    {path: 'back_office/billing/subscription', component: SubscriptionComponent, canActivate: [AuthGuardService]},
    {path: 'back_office/contact_support', component: ContactSupportComponent, canActivate: [AuthGuardService]},
    {path: 'back_office/logs', component: LogComponent, canActivate: [AuthGuardService]},
    {path: 'manage_otas', component: ManageOtasComponent, canActivate: [AuthGuardService]},
    {path: 'rates_packages', component: ManageRatesPackagesComponent, canActivate: [AuthGuardService], children: [
      {path: 'setup_package', component: SetupPackageComponent, canActivate: [AuthGuardService]},
      {path: 'setup_rate', component: SetupRateComponent, canActivate: [AuthGuardService]},
      {path: 'setup_restriction', component: SetupRestrictionComponent, canActivate: [AuthGuardService]},
      {path: 'link_to_ota', component: LinkOTAComponent, canActivate: [AuthGuardService]},
      {path: 'link_to_channels', component: LinkChannelsComponent, canActivate: [AuthGuardService]},
      {path: 'promo_allocation', component: PromoAllocationComponent, canActivate: [AuthGuardService]},
      {path: 'preview_package', component: PreviewPackageComponent, canActivate: [AuthGuardService]},
      {path: 'setup_season', component: SetupSeasonComponent, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      {path: 'setup_season_rates', component: SetupSeasonRateComponent, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      {path: 'setup_season_policy', component: SetupSeasonPolicyComponent, data:{}, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      {path: 'setup_season_ota', component: SetupSeasonOtaComponent, data:{}, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      {path: 'setup_season_channel', component: SetupSeasonChannelsComponent, data:{}, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      {path: 'setup_season_preview', component: SetupSeasonPreviewComponent, data:{}, canActivate: [AuthGuardService], outlet: 'seasonPanel'},
      
    ]},
    {path: 'arrivals', component: ArrivalsComponent, canActivate: [AuthGuardService]},
    {path: 'inventory', component: InventoryComponent, canActivate: [AuthGuardService]},
    {path: 'reports_analytics', component: ReportsComponent, canActivate: [AuthGuardService]}
  ]},
  {path: 'main', component: WithoutHeaderComponent, children: [
    {path: '', pathMatch: 'full', redirectTo: 'terms_of_use'},
    {path: 'terms_of_use', component: TermsOfUseComponent, canActivate: [AuthGuardService]},
    {path: 'online_help', component: OnlineHelpComponent, canActivate: [AuthGuardService]},
  ]},
  {path: '**', component: NotFoundComponent},
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    TranslateModule
  ], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
