import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  constructor() { }
  darkMode:boolean = false;
  ngOnInit() {

    this.checkDarkMode();

// drag functionality code-------------------------------------
    // $(function () {
    //   setTimeout(function(){
    //       $("#drag_box").dragsort({
    //           dragSelector: "div",
    //           dragBetween: true,
    //           dragEnd: () => {
    //               var data = $("#drag_box .drag_item").map(function () {
    //                   return $(this).children().html();
    //               }).get();
    //               // $("input[name=list1SortOrder]").val(data.join("|"));
    //           },
    //           placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
    //           "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
    //           "</div>",
    //           cursor: "move"
    //       });
    //   }, 1000)
    // });
    // drag function end-----------------------------------------------
  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }


}
