import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { HelperService } from "../../../services/helper.service";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
declare var $:any;
declare var google:any;

@Component({
  selector: 'app-select-hotel',
  templateUrl: './select-hotel.component.html',
  styleUrls: ['./select-hotel.component.css']
})
export class SelectHotelComponent implements OnInit {


  map : any;

  @ViewChild('map', {read:ElementRef, static: false}) mapRef: ElementRef;
 

  properties = [];
  coloarray = ['#48fff3', '#a07cc6', '#ff6f00', '#15b8ea', '#cf9595', '#09fb3d', '#000000','#a07cc6','#48fff3'];
  channels = [];
  users = [];
  user;
  darkMode:boolean = false;
  propertyList : FormGroup;
  user_id: number;
  imgUrl:string = this.api.base_url2+'app/images/';
  showAminModal:boolean = false;
  amenityData:any;
  amenColor = ['#a9aae0','#d24f51','#bf4eeb','#efab20','#32d04a','#468fab','#ffafaf']
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private confirm:ConfirmationAlertService,
    private router: Router,
  ) {

  }
  
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.get_channels();
    this.getPropertyIndex();
    this.checkDarkMode();
    //this.getLocation();
    
    $(function(){

      $("#selectPro").mouseup(function(){
        //$(this).after("<p style='color:green;'>Mouse button released.</p>");
        //console.log("mouse doupwn")
        $("#grabingg").css({"cursor":"grab"})
      });
      $("#selectPro").mousedown(function(){
        //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
        //console.log("mouse down")
        $("#grabingg").css({"cursor":"grabbing"})
      });

    })

  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      //console.log('dark Mode Enabled')
    }
  }


  getPropertyIndex(){
    // let id = JSON.parse(localStorage.getItem('user')).id;
    // let id = 90;
    this.api.post('api/property/index', {user_id:this.user.id} , true).subscribe((res:any) => {
      this.properties = res.body.data;
      //console.log( this.properties)
      this.get_users();
    }, err => this.error_handling.handle_error(err.status)); 
    this.setHi()
  }
  
  get_channels(){
    this.api.post('api/channels/index?per_page=50',{} ,true).subscribe((res:any) => {
      this.channels = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  get_users(){
    // let id = JSON.parse(localStorage.getItem('user')).id;
    this.properties.forEach((property) => {
      this.api.post('api/user/index',{admin_id:property.id} ,true).subscribe((res:any) => {
        property.users_count = res.body.data.length;
      }, err => this.error_handling.handle_error(err.status, err.message));
    })
   // console.log(this.properties);
  }


  delete(){
    this.confirm.confirm(
        "Back Office", 
        'Delete Property - Leisurex Resort',
        ['Delete Property - Leisurex Resort'],
        function(){
            
        }
    );
  }

  AddNewPropety(){
    // this.confirm.confirm(
    //   "Back Office", 
    //   'Add New Property',
    //   ['Add New Property'],
    //   function(){
        
    //   }
    // );
    this.router.navigate(['/app/back_office/hotel_setup']);
  }

  switchproperty(id){
    
    this.user.default_property = id;
    this.api.post('api/user/update/' + this.user.id, this.user, true).subscribe((res:any) => {
      localStorage.setItem('user' , JSON.stringify(res.body.data))
      localStorage.setItem('current_property', id);
      this.helper.alert_success('Active propety switched.');
      // }, err => this.error_handling.handle_error(err.status, err.message));
    }, err => this.error_handling.handle_error(err.status));

  }

  get current_property(){
    return localStorage.getItem('current_property');
  }
  setHi(){
    setTimeout(()=>{
      let a = document.getElementById("selectPro");
      console.log(a.offsetHeight)
      if(a.offsetHeight > 700){
    a.classList.add("paddRight5")
      }else{
        a.classList.remove("paddRight5")
      }
    },500)
  }

  imgr(name){
    let a = name.split('.');
    return a[0];
  }
  

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res)=>{
       // console.log(res);
        //this.showMap(res.coords.latitude, res.coords.longitude);
      })
    } else { 
      
    }
  }



  showMap(lat, lng){
    const location = new google.maps.LatLng(lat, lng);
    const options = {
      center: location,
      zoom: 15,
      
    }
    this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    this.addMarker()
  }



addMarker(){
    let marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: this.map.getCenter()
    });
    let content = "<h4>Information!</h4>";          
  }

  amenityPro(data){
    this.showAminModal = true;
    this.amenityData = data
    setTimeout(()=>{
      $( "#amenityModal" ).draggable();
      $( "#amenityModal" ).draggable("disable");
    });
  }

  dodrag(id){
    $( "#"+id ).draggable("enable");
  }
  stopDrag(id){
    $( "#"+id ).draggable("disable");
  }
  grab(id){
   document.getElementById(id).style.cursor = 'grab'
  }
  grabbing(id){
    document.getElementById(id).style.cursor = 'grabbing'
   }

   hideModal()
   {
     let modal = document.getElementById('settings')
       modal.classList.remove('show');
       modal.classList.add('hide');
   }
 


}
