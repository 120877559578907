<div class="dd" (click)="helper.selectMe = 2" [ngStyle]="{'opacity': helper.selectMe == 2 ? '1' : '0.3'}"
    [ngClass]="darkMode ? 'dd_dark':'dd'">
    <div class="head" [ngClass]="darkMode ? 'head_dark':'head'">{{ 'manage_ota.internal_channels' | translate }}
    </div>
    <div class="grid_20 scroll no_scroll" style="margin-top: 5px;"
        [ngStyle]="{ 'pointer-events': helper.selectMe == 2 ? 'auto' : 'none'}">
        <div id="drag-box2-ota" style="padding-left: 0px; padding-right: 0px">
            <div id="drag-item2-ota" *ngFor="let internal of internal_channels">
                <div class="shade shade-{{ internal.image.split('.')[0]  }}">
                    <div class="border-height">
                        <div class="dark_gradient" [ngClass]="darkMode ? 'dark_gradient_dark':'dark_gradient'">
                            <div class="uper">
                                <img src="assets/img/svgicons/channels/{{ internal.image }}" alt="" class="ota-icon">
                                <div class="toggle-btn pull-right">
                                    <mat-slide-toggle [color]="'accent'" [ngClass]="darkMode ? 'darkmatetoggle' : 'null'"
                                        (change)="mat_change($event , internal.pr_status , internal.id)"
                                        [checked]="internal.pr_status == 'Active' ? true : false"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="between customo">
                                <div class="leftOta">
                                    <p class="pi margin0 text-align chS hovChan"
                                        (click)="ota_setting_modal(internal.property_channel_id,internal.connected)"
                                        [ngClass]="darkMode? 'pinki' : 'null'">{{
                                        'rates_and_packages.extra.channel_setting'
                                        | translate }}</p>
                                    <p class="margin0 text-right cogI hovChan"
                                        (click)="ota_setting_modal(internal.property_channel_id,internal.connected)">
                                        <i class="fa fa-cog hovChan" [ngClass]="darkMode? 'pinki' : 'null'"></i>
                                    </p>
                                </div>
                                <div class="RightOta">
                                    <p class="p2 margin0 text-align coMM" [ngClass]="darkMode? 'pinki' : 'null'">{{
                                        'manage_ota.commission' | translate }}</p>
                                    <p class="margin0 text-right percentT" [ngClass]="darkMode? 'pinki' : 'null'">
                                        {{ internal.commission }}
                                        <span class="">%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="lower" [ngClass]="darkMode ? 'lower_dark':'lower'">
                            <a [routerLink]="['/app/rates_packages']"
                                [ngClass]="darkMode ? 'span-weight_dark':'span-weight'"
                                [queryParams]="{filter_channel: internal.id}">{{ 'manage_ota.packages_linked' |
                                translate
                                }}
                                <span class="num" [ngClass]="darkMode ? 'span-weight_dark':'span-weight'"> {{
                                    internal.package_count }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>