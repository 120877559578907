import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private fooSubject = new Subject<any>();
  constructor() { }

  publishDarkMode(data: any) {
    this.fooSubject.next(data);
  }

  receiveDarkMode(): Subject<any> {
      return this.fooSubject;
  }
}
