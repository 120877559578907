<div class="" id="SSS" style="padding-right: 3px;">
    <!-- <div class="alert alert-info alert-dismissible show" role="alert" id="grab-3" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
      {{ratehead}}
      <button type="button" class="close op-adj" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <strong id="strong">&#x2716;</strong>
      </button>
    </div> -->

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
        <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">{{ 'rates_and_packages.link_channel.rates_and_packages' | translate }}</a>
        <a routerLink="setup_restriction" class="btn btn-link active resw"> <span class="abc">{{ service.is_edit ? "EDIT" : "SETUP" }} {{ 'rates_and_packages.link_channel.policy' | translate }}</span></a>
    </div>

    <div class="no_scroll scroll">
        <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            <h4>{{ 'rates_and_packages.link_channel.stay' | translate }}</h4>
        </div>

        <form [formGroup]="setup_restrictions" (ngSubmit)="onSubmit()">
            <div class="" id="SS">
                <div class="">
                    <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row field-space">
                                    <div class="col-md-6 grid_20 padding-rg" style="padding-right: 0px;">
                                        <div class="incremental">
                                            <div class="border-style minimum-stay-shade">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" placeholder="{{ 'rates_and_packages.setup_restriction.enter_minimum_stay' | translate }}" min="1" name="min_stay" formControlName="min_stay" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" />
                                                    <div class="input-group-addon addon-border addon-borders" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-side-end '">
                                                        <a class="btn" (click)="increment('min_stay')"><i class="fa fa-plus"></i></a>
                                                    </div>
                                                    <div class="input-group-addon addon-border addon-borders" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-side-end '">
                                                        <a class="btn" (click)="decrement('min_stay')"><i class="fa fa-minus"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && f.min_stay.errors" class="small text-danger no-margin invalid-feedback">
                                                <strong *ngIf="f.min_stay.errors.required">{{ 'rates_and_packages.link_channel.minimum_stay_required' | translate }}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 grid_20 padding-lg">
                                        <div class="incremental">
                                            <div class="border-style maximum-stay-shade">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" placeholder="{{ 'rates_and_packages.setup_restriction.enter_maximum_stay' | translate }}" name="max_stay" formControlName="max_stay" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" />
                                                    <div class="input-group-addon addon-border addon-borders" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-side-end '">
                                                        <a class="btn" (click)="increment('max_stay')"><i class="fa fa-plus"></i></a>
                                                    </div>
                                                    <div class="input-group-addon addon-border addon-borders" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-side-end'">
                                                        <a class="btn" (click)="decrement('max_stay')"><i class="fa fa-minus"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && f.max_stay.errors" class="small text-danger no-margin invalid-feedback">
                                                <strong *ngIf="f.max_stay.errors.required">{{ 'rates_and_packages.link_channel.max_stay_required' | translate }}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="gradient-bar adj" style=" width: 50%;display: inline-block; margin-right: 10px">
              <h4 style="margin-top: 0;">{{ 'rates_and_packages.link_channel.childerns' | translate }}</h4>
          </div>
          <div class="gradient-bar adj" style=" width: 49%;display: inline-block; ">
            <h4 style="margin-top: 0;">{{ 'rates_and_packages.link_channel.pets' | translate }}</h4>
        </div> -->
                    <!-- <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 grid_20 padding-rg"  style="padding-right: 0px;">
                    <div class="incremental">
                      <div class="border-style minimum-age-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" placeholder="{{ 'rates_and_packages.setup_restriction.enter_minimum_age' | translate }}" min="1" name="minimum_age" formControlName="minimum_age"/>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="increment('minimum_age')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="decrement('minimum_age')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="submitted && f.minimum_age.errors" class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="f.minimum_age.errors.required">{{ 'rates_and_packages.link_channel.child_age_required' | translate }}</strong>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 check padding-rg"  style="padding-right: 0px;">
                    <div class="border-style policy-shade">
                      <ng-select bindLabel="label" bindValue="label" placeholder="Select Policy" name="pets" formControlName="pets">
                        <ng-option value="Allowed">{{ 'rates_and_packages.setup_restriction.allowed' | translate }}</ng-option>
                        <ng-option value="Not Allowed">{{ 'rates_and_packages.setup_restriction.not_allowed' | translate }}</ng-option>
                        <ng-option value="Extra Amount">{{ 'rates_and_packages.setup_restriction.extra_amount' | translate }}</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && f.pets.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.pets.errors.required">{{ 'rates_and_packages.setup_restriction.please_select_policy' | translate }}</strong>
                    </div>
                  </div>
                  <div class="col-md-3 check padding-rg" *ngIf="setup_restrictions.value.pets == 'Extra Amount'">
                    <div class="border-style extra-charge-shade">
                      <input type="number" class="form-control" placeholder="{{ 'rates_and_packages.setup_restriction.enter_extra_charge_amount' | translate }}" maxlength="10"
                        required name="pets_extra_amount" formControlName="pets_extra_amount" min="1" />
                    </div>
                    <div *ngIf="submitted && f.pets_extra_amount.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.pets_extra_amount.errors.required">{{ 'rates_and_packages.link_channel.enter_pets_extra_amount' | translate }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

                    <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                        <h4>{{ 'rates_and_packages.link_channel.cancellation_policy' | translate }}</h4>
                    </div>

                    <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row field-space">
                                    <div class="col-md-6 check padding-rg" style="padding-right: 0px;">
                                        <div class="border-style days-check-shade">
                                            <input type="number" class="form-control" min="1" placeholder="{{ 'rates_and_packages.setup_restriction.days_cefore_check_in' | translate }}" maxlength="10" required name="cancellation_days" formControlName="cancellation_days" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                            />
                                        </div>
                                        <div *ngIf="submitted && f.cancellation_days.errors" class="small text-danger no-margin invalid-feedback">
                                            <strong *ngIf="f.cancellation_days.errors.required">{{ 'rates_and_packages.setup_restriction.please_enter_days_before_check_in' | translate }}</strong>
                                        </div>
                                    </div>

                                    <div class="col-md-3 check padding-rg" style="padding-right: 0px;">
                                        <div class="border-style penalty-shade">
                                            <ng-select [(ngModel)]="cancellaction_type" name="cancellaction_type" bindLabel="label" bindValue="label" placeholder="{{ 'rates_and_packages.setup_restriction.select_method' | translate }}" name="cancellaction_type" formControlName="cancellaction_type"
                                                [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                                                <ng-option value="percentage">{{ 'rates_and_packages.setup_restriction.percentage' | translate }}</ng-option>
                                                <ng-option value="fixed">{{ 'rates_and_packages.setup_restriction.fixed_amount' | translate }}</ng-option>
                                                <ng-option value="no_refund">{{ 'rates_and_packages.setup_restriction.no_refund' | translate }}</ng-option>
                                                <ng-option value="full_refund">{{ 'rates_and_packages.link_channel.full_refund' | translate }}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div *ngIf="submitted && f.cancellaction_type.errors" class="small text-danger no-margin invalid-feedback">
                                            <strong *ngIf="f.cancellaction_type.errors.required">{{ 'rates_and_packages.setup_restriction.please_select_penalty_method' | translate }}</strong>
                                        </div>
                                    </div>

                                    <div class="col-md-3 check padding-rg" *ngIf="setup_restrictions.value.cancellaction_type == 'percentage' || setup_restrictions.value.cancellaction_type == 'fixed'">
                                        <div class="border-style" style="border-color:darkcyan">
                                            <input type="number" class="form-control" min="1" placeholder="{{ 'rates_and_packages.setup_restriction.enter_value' | translate }}" required name="cancellation_value" formControlName="cancellation_value" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                                            />
                                        </div>
                                        <div *ngIf="submitted && f.cancellation_value.errors" class="small text-danger no-margin invalid-feedback">
                                            <strong *ngIf="f.cancellation_value.errors.required">{{ 'rates_and_packages.setup_restriction.enter_value' | translate }}</strong>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                        <div class="col-md-10 zero-padding" style="width: 90%;">
                            <h4 style="padding-top: 10px !important;padding-right: 0px !important;">{{ 'rates_and_packages.link_channel.ota_policy' | translate }}
                                <div class="tool-policy"><i class="fas fa-info-circle">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                <p style="margin: 0px;">{{ 'rates_and_packages.link_channel.cancellation_and' | translate }} {{ 'rates_and_packages.link_channel.what_con' | translate }} || Apply hotel policy or OTA's policy.</p>
                <!-- <p style="margin-bottom: 0px;margin-top: -10px;">{{ 'rates_and_packages.link_channel.what_con' | translate }}.</p> -->
              </span>
            </i> <span></span></div>
                            </h4>
                        </div>
                        <div class="col-md-2 select-all" style="width: 9.2%;">
                            <label class="control-label checkbox-style" style="margin-left: 6px">
                      <input type="checkbox" class="checkbox" id="allS" (change)="selectAll($event)">
                      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'checkbox-checkmark_dark':'checkbox-checkmark'"></span>
                      <span class="permission-title">{{ 'rates_and_packages.link_channel.select_all' | translate }}</span>
                  </label>
                        </div>
                    </div>

                    <div class="white_box channels w-clearfix channel_padding" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

                        <div class="col-md-12" style="padding-right: 5px;padding-top: 0px;padding-left: 0px;    margin-top: -2px;">
                            <div *ngFor="let channel of channels; let i=index" class="col-md-4 csw" style="padding-right: 0px !important">
                                <div class="channel_container clr_{{ channel?.image.split('.')[0] }} w-clearfix" style="display: flex;" [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                                    <div class="form_container">
                                        <div class="form-block w-clearfix w-form">
                                            <div class="form">
                                                <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                          <input type="checkbox" name="cancelation_channels" value="{{ channel?.id }}" (change)="setChannel(channel?.id, $event)" [checked]="channel?.is_checked">
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor" /> -->
                                    <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(channel.image) :  imgr(channel.image) }}.svg" alt="" class="tripadvisor ota-icon" />

                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="gradient-bar adj t22" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                        <div class="col-md-10 zero-padding" style="width: 90%;">
                            <h4 style="padding-top: 10px !important;padding-right: 0px !important;">Channel Policy
                                <div class="tool-policy"><i class="fas fa-info-circle">
              <span class="tooltiptext t2" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                <p style="margin: 0px;">{{ 'rates_and_packages.link_channel.can_and_pay' | translate }} {{ 'rates_and_packages.link_channel.your_pre' | translate }}.</p>
                <!-- <p style="margin-bottom: 0px;margin-top: -10px;">{{ 'rates_and_packages.link_channel.your_pre' | translate }}.</p> -->
              </span>
            </i> <span></span></div>
                            </h4>
                        </div>
                        <div class="col-md-2 select-all" style="width: 9.2%;">
                            <label class="control-label checkbox-style" style="margin-left: 6px">
                    <input type="checkbox" class="checkbox" id="allS2" (change)="selectAll2($event)">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'checkbox-checkmark_dark':'checkbox-checkmark'"></span>
                    <span class="permission-title">{{ 'rates_and_packages.link_channel.select_all' | translate }}</span>
                </label>
                        </div>
                    </div>

                    <div class="white_box channels w-clearfix channel_padding" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

                        <div class="col-md-12" style="padding-right: 5px;padding-top: 0px;padding-left: 0px;    margin-top: -2px;">
                            <div *ngFor="let channel of channels2; let i=index" class="col-md-4 csw" style="padding-right: 0px !important">
                                <div class="channel_container clr_{{ channel?.image.split('.')[0] }} w-clearfix" style="display: flex;" [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                                    <div class="form_container">
                                        <div class="form-block w-clearfix w-form">
                                            <div class="form">
                                                <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                        <input type="checkbox" name="cancelation_channels" value="{{ channel?.id }}" (change)="setChannel(channel?.id, $event)" [checked]="channel?.is_checked">
                      </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor" /> -->
                                    <img src="assets/img/svgicons/channels/{{ imgr(channel.image) }}.svg" alt="" class="tripadvisor ota-icon {{ channel?.name }}" />

                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
            <div class="modal-footer" id="ModalFooter" style="right: 10px!important;bottom: -50px;">
                <a class="btn btn-primary btn-lg" (click)="onSubmit()" [ngClass]="darkMode ? 'card-dark-btn-g hovoo' : ' card-null '">{{ 'rates_and_packages.setup_restriction.save' | translate }}</a>
                <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close" (click)="onClose()" [ngClass]="darkMode ? 'card-dark-top-nav hovoo' : ' card-null '">{{ 'rates_and_packages.setup_restriction.cancel' | translate }}</a>
            </div>
        </form>