import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
declare var $: any;
import { GlobaldataService } from "../../common/globaldata.service";
declare var $: any;
import * as moment from "moment";
import ScrollBooster from "scrollbooster";
import { element } from "protractor";
import { Package } from "src/app/common/Types/Inventory/CalendarDataResponse";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./inventory.component.css"],
})
export class InventoryComponent implements OnInit {
  colors = ["#f98282", "#49fc6d", "#ef85ff", "#be7f2f", "#ffe878", "#43eecc"];
  holidays: any = GlobaldataService.holidays;
  roomTypeIds: string[];
  calendar_date = { startDate: moment(), endDate: moment().add("8", "days") };
  calendar_range = [];
  channels = [];
  channel_list = [];
  room_types = [];
  packages: Package[] = [];
  hoverH: boolean = true;
  inventory = [];
  inventory_calendar: any = [];
  checked_pkg = [];
  selected_packages = [];
  darkMode: boolean = false;
  scroller;

  edit_rate;
  set_edit_rate;
  save_rate_update;
  open_restriction_box;
  save_bulk_update;
  save_restrictions;
  bulk_changes;
  remove_vowel;
  push_to_ota;

  stop_scroll = false;
  return;

  current_view = {
    startDate: moment().subtract("8", "days"),
    endDate: moment().add("8", "days"),
    gap: 0,
    interval: null,
    scrollx: 0,
    scrolly: 0,
  };
  current_range = [];

  //details popup vars
  showPackageDetails: any;
  showSeasonDetails: any;

  //New Inventory Work
  dayChannelUpdate = {
    room_id: "",
    change_date: "",
    season_id: "",
    package_id: "",
  };
  dayChannelUpdateDate: any;
  dayRstrictionUpdte = {
    room_id: "",
    change_date: "",
    season_id: "",
    package_id: "",
  };
  res_obj = {
    close_rates: false,
    stop_sell: false,
    cta: false,
    ctd: false,
    min_stay: {
      allowed: false,
      value: 1,
    },
    max_stay: {
      allowed: false,
      value: 1,
    },
  };
  selected_boxes = [];
  bulk_channel = [];
  bulkUpdate_add_channel = [];
  bulkUpdate_remove_channel = [];
  bulk_price: any = "";
  bulk_min_stay: any = "";
  bulk_max_stay: any = "";
  res_bulk_obj = {
    stop_sell: false,
    cta: false,
    ctd: false,
  };
  edit_price: any = undefined;
  new_package_name: string = "";
  bulkOptionSelect: any;
  bulkOption = [
    { name: "Bulk Update", val: 1 },
    { name: "Create Promotion", val: 2 },
  ];
  bulkCheck: boolean = false;
  pacType = "Loyalty";
  current_currency = localStorage.getItem("current_currency");

  roomPackagesMap: Map<number, Package[]>;
  roomSeasonsMap: Map<number, any> = new Map();
  today: Date = new Date();

  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public helper: HelperService,
    public ref: ChangeDetectorRef
  ) {
    console.log("inventory page");
  }

  @HostListener("scroll", ["$event"])
  onTableScroll(event) {
    let gap = Math.floor(event.target.scrollLeft / 180);
    if (this.stop_scroll) {
      this.stop_scroll = false;
      return;
    }
    if (
      event.target.scrollLeft + event.target.clientWidth ==
      event.target.scrollWidth
    ) {
      this.calendar_date.endDate = this.calendar_date.endDate.add("3", "days");
      this.get_range();
    }
    if (
      event.target.scrollLeft == 0 &&
      this.current_view.scrollx == event.target.scrollTop &&
      this.current_view.scrolly != event.target.scrollLeft
    ) {
      this.calendar_date.startDate = this.calendar_date.startDate.subtract(
        "4",
        "days"
      );
      this.get_range();
      this.current_view.startDate = this.current_view.startDate.subtract(
        "4",
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.subtract(
        "4",
        "days"
      );
      gap = 1;
      event.target.scrollLeft = 5;
      this.stop_scroll = true;
    }
    if (gap != this.current_view.gap) {
      let space = gap - this.current_view.gap;
      this.current_view.startDate = this.current_view.startDate.add(
        space,
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.add(space, "days");
      clearTimeout(this.current_view.interval);
      let id = setTimeout(() => {
        this.current_table();
      }, 250);
      this.current_view.gap = gap;
      this.current_view.interval = id;
    }
    this.current_view.scrollx = event.target.scrollTop;
    this.current_view.scrolly = event.target.scrollLeft;
  }

  ngOnInit() {
    this.get_channels();
    this.table_drag();
    this.get_packages();
    this.getHolidays();
    this.checkDarkMode();

    $(() => {
      $("table").on("click", ".dropdown-menu > *", function (e) {
        e.stopPropagation();
      });
      $("table").on("click", ".channel-menu > *", function (e) {
        e.stopPropagation();
      });
      $("table").on("click", "#closechannel", function (e) {
        $(this).closest(".otas_connected").toggleClass("open");
      });

      $("#rate_change").mouseup(function () {
        $("ul").css({ cursor: "grab" });
      });
      $("#rate_change").mousedown(function () {
        $("ul").css({ cursor: "grabbing" });
      });

      $("#package_details_popup").mouseup(function () {
        $("#grab-2").css({ cursor: "grab" });
      });
      $("#package_details_popup").mousedown(function () {
        $("#grab-2").css({ cursor: "grabbing" });
      });

      $("#season_details_popup").mouseup(function () {
        $("#grab-3").css({ cursor: "grab" });
      });
      $("#season_details_popup").mousedown(function () {
        $("#grab-3").css({ cursor: "grabbing" });
      });

      $("#bulk_update").mouseup(function () {
        $("#grab-4").css({ cursor: "grab" });
      });
      $("#bulk_update").mousedown(function () {
        $("#grab-4").css({ cursor: "grabbing" });
      });

      $("#myModal").mouseup(function () {
        $("#grab-5").css({ cursor: "grab" });
      });
      $("#myModal").mousedown(function () {
        $("#grab-5").css({ cursor: "grabbing" });
      });

      $(".modal").mouseup(function () {
        $(".alert-danger").css({ cursor: "grab" });
      });
      $(".modal").mousedown(function () {
        $(".alert-danger").css({ cursor: "grabbing" });
      });

      $("#ReModal").mouseup(function () {
        $("#grab-6").css({ cursor: "grab" });
      });
      $("#ReModal").mousedown(function () {
        $("#grab-6").css({ cursor: "grabbing" });
      });
    });
  }

  get_range(from_picker = false) {
    let range = [];
    let start = moment(this.calendar_date.startDate).toDate();
    let end = moment(this.calendar_date.endDate).toDate();
    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }
    if (from_picker) {
      document.querySelector(".scroll").scrollLeft = 5;
      while (range.length < 9) {
        range.push(moment(range[range.length - 1]).add("1", "days"));
      }
      this.current_view.startDate = range[0];
      this.current_view.endDate = range[range.length - 1];
      this.current_view.gap = 0;
    }
    this.calendar_range = range;
  }

  current_table() {
    var new_start = null,
      new_end = null;
    let range = [];
    let start = moment(new_start || this.current_view.startDate).toDate();
    let end = moment(new_end || this.current_view.endDate).toDate();
    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }
    this.current_range = range;
    this.get_inventory();
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range(true);
      this.current_table();
    }
  }

  get_channels() {
    this.api
      .post(
        "api/properties_channel/index?per_page=50",
        {
          user_id: JSON.parse(localStorage.getItem("user")).id,
          property_id: localStorage.getItem("current_property"),
          status: "Active",
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.channels = res.body.data;
          console.log(this.channels, "channels");
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  get_room_types() {
    this.api.post("api/roomtype/index?per_page=50", {}, true).subscribe(
      (res: any) => {
        this.room_types = res.body.data;
        this.room_types.forEach((el) => {
          el.packages = this.packages.filter((res) => {
            return res.room_type_id == el.id;
          });
        });
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  get_packages() {
    // this.api
    //   .post(
    //     "api/package/season_and_package",
    //     { property_id: localStorage.getItem("current_property") },
    //     true
    //   )
    //   .subscribe(
    //     (res: any) => {
    this.api
      .post(
        "api/season_package/index",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          // this.api.post('api/season_package/index',{property_id: localStorage.getItem("current_property")} ,true).subscribe((res:any) => {

          this.packages = res.body.data.packages;
          this.packages = this.packages.concat(res.body.data.packages);
          this.inventory_calendar = this.packages.reduce(function (
            results,
            pkg
          ) {
            (results[pkg.room_type_id] = results[pkg.room_type_id] || []).push(
              pkg
            );

            return results;
          },
            []);

          this.roomPackagesMap = new Map<number, Package[]>();
          let roomTypeIds = Object.keys(this.inventory_calendar);
          this.roomTypeIds = roomTypeIds;

          roomTypeIds.forEach((roomId) => {
            this.roomPackagesMap.set(+roomId, this.inventory_calendar[roomId]);
          });

          this.inventory_calendar = this.inventory_calendar.filter(function (
            e
          ) {
            return e;
          });

          this.get_range();
          this.current_table();
          this.map_calendar();
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  get_inventory() {
    this.api
      .post(
        "api/inventory/index",
        {
          property_id: localStorage.getItem("current_property"),
          user_id: JSON.parse(localStorage.getItem("user")).id,
          change_dates:
            this.current_view.startDate.format("YYYY-MM-DD") +
            "," +
            this.current_view.endDate.format("YYYY-MM-DD"),
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.inventory = res.body.data;
          this.map_boxes();
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  map_calendar() {
    this.inventory_calendar = this.inventory_calendar.map((inv) => {
      let result = inv.map((i) => {
        let arr = {
          id: i.id,
          package_name: i.package_name,
          room_type_id: i.room_type_id,
          room_type: i.roomttype,
          room_rate:
            i.season_name == undefined
              ? i.week_days_rate
              : i.season_room_types.week_days_rate,
          room: i.room,
          closed_dates: [],
          boxes: [],
          season_name: i.season_name != undefined ? i.season_name : undefined,
          week_days_rate:
            i.season_name != undefined
              ? i.season_room_types.week_days_rate
              : i.week_days_rate,
          weekend_days_rate:
            i.season_name != undefined
              ? i.season_room_types.weekend_days_rate
              : i.weekend_days_rate,
          week_day:
            i.season_name != undefined
              ? i.season_room_types.week_day
              : i.week_day,
          weekend_day:
            i.season_name != undefined
              ? i.season_room_types.weekend_day
              : i.weekend_day,
        };
        return arr;
      });
      return result;
    });
  }

  map_boxes() {
    // this.api.post('api/inventory/calendar',{property_id: localStorage.getItem("current_property"), user_id: JSON.parse(localStorage.getItem('user')).id, change_dates: this.current_view.startDate.format('YYYY-MM-DD') + ',' + this.current_view.endDate.format('YYYY-MM-DD')}, true).subscribe((res: any) => {
    this.api
      .post(
        "api/inventory/calendar_new",
        {
          property_id: localStorage.getItem("current_property"),
          user_id: JSON.parse(localStorage.getItem("user")).id,
          change_dates:
            this.current_view.startDate.format("YYYY-MM-DD") +
            "," +
            this.current_view.endDate.format("YYYY-MM-DD"),
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.inventory_calendar = res.body.data;
          this.ref.markForCheck();
          console.log("Season data", this.inventory_calendar);
          let rooms = [];

          let flattenedArray = []
            .concat(...this.inventory_calendar)
            .filter((x) => x.season_id);
          let roomTypeIds = new Set(flattenedArray.map((x) => x.room_type_id));

          roomTypeIds.forEach((roomTypeId) => {
            let season = flattenedArray.find(
              (x) => x.room_type_id === roomTypeId
            );
            this.roomSeasonsMap.set(roomTypeId, season);
          });
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  week_move(move) {
    let scroll_obj = document.querySelector(".scroll");
    if (move == "next") {
      scroll_obj.scrollLeft += 1440;
    } else {
      scroll_obj.scrollLeft -= 1440;
    }
  }

  table_drag() {
    let viewport = <HTMLElement>document.querySelector(".scroll");
    let content = <HTMLElement>document.querySelector(".grid_calendar");

    this.scroller = new ScrollBooster({
      viewport,
      content,
      direction: "horizontal",
      bounce: false,
      emulateScroll: false,
      inputsFocus: true,
      onUpdate: (state) => {
        viewport.scrollLeft = state.position.x;
      },
    });
  }

  // table_drag() {
  //   let viewport = <HTMLElement>document.querySelector(".scroll");
  //   let content = <HTMLElement>document.querySelector(".grid_calendar");

  //   this.scroller = new ScrollBooster({
  //     viewport,
  //     content,
  //     direction: "horizontal",
  //     bounce: true,
  //     scrollMode: "native",
  //     emulateScroll: true,
  //     inputsFocus: true,

  //     onUpdate: (state) => {
  //       console.log(state)

  //         content.style.transform = `translate(
  //           ${viewport.scrollLeft  =     state.position.x}px,
  //         )`;

  //     },

  //     // onUpdate: (state) => {
  //     //   console.log(state)
  //     //   viewport.scrollTo = state.position.x;
  //     //   // viewport.scrollLeft = state.position.y;

  //     // },
  //   });
  // }

  is_box_shown(boxes, date) {
    return boxes.find((b) => b.date == date.format("YYYY-MM-DD")) || false;
  }

  trackByInv(index) {
    return index;
  }

  trackByVen(index) {
    return index;
  }

  trackByBox(pkg_id, index, item) {
    return index + "_" + pkg_id;
  }

  trigger_click() {
    let obj = <HTMLElement>(
      document.querySelector(".buttons .buttons_input .btn")
    );
    obj.click();
  }

  input_focus($event) {
    $event.target.focus();
  }
  //Box Count
  invCount(c, d) {
    if (c != undefined && d != undefined) {
      let f = c.filter((e) => e.date == d);
      return f.length == 0 ? 0 : f[0].room_count;
    }
    return 0;
  }
  //Signle Price Update on Box
  addSinglePrice(rate, date, ven) {
    let final = [];
    final.push({
      user_id: JSON.parse(localStorage.getItem("user")).id,
      property_id: localStorage.getItem("current_property"),
      room_id: ven.room.id,
      change_date: date,
      season_id: ven.season_id,
      package_id: ven.package_id,

      channel_add: "",
      channel_remove: "",
      price: rate,
      restriction_add: "",
      restriction_remove: "",
      min_stay: "",
      max_stay: "",
    });
    this.api.post("api/inventory/bulk_calendar_store", final, true).subscribe(
      (res: any) => {
        this.setRate(final);
        this.map_boxes();
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  /* Open Channel Modal */
  open_channel_box(el, date, box_id, ven) {
    let list = JSON.parse(JSON.stringify(this.channels));
    console.log(list, "channel list");
    this.channel_list = list;
    this.channel_list.forEach((ota) => {
      ota.is_binded = false;
      el.forEach((ele) => {
        if (ele.id == ota.id) {
          ota.is_binded = ele.status;
        }
      });
    });
    this.dayChannelUpdate = {
      room_id: ven.room.id,
      change_date: date,
      season_id: ven.season_id,
      package_id: ven.package_id,
    };
    this.dayChannelUpdateDate = date;
    $("#myModal").modal({ backdrop: "static", keyboard: true });
    $("#myModal").draggable();
    $("#myModal").draggable("disable");
  }

  checkChannel($event, id, i) {
    if ($event.target.checked) {
      this.channel_list[i].is_binded = true;
    } else {
      this.channel_list[i].is_binded = false;
    }
  }

  checkAllOta($event) {
    if ($event.target.checked) {
      this.channel_list.forEach((element) => {
        if (element.type == "External") {
          element.is_binded = true;
        }
      });
    } else {
      this.channel_list.forEach((element) => {
        if (element.type == "External") {
          element.is_binded = false;
        }
      });
    }
  }

  checkAllChannel($event) {
    if ($event.target.checked) {
      this.channel_list.forEach((element) => {
        if (element.type == "Internal") {
          element.is_binded = true;
        }
      });
    } else {
      this.channel_list.forEach((element) => {
        if (element.type == "Internal") {
          element.is_binded = false;
        }
      });
    }
  }

  save_ota_update() {
    let f = {
      user_id: JSON.parse(localStorage.getItem("user")).id,
      property_id: localStorage.getItem("current_property"),
      room_id: this.dayChannelUpdate.room_id,
      change_date: this.dayChannelUpdateDate,
      season_id: this.dayChannelUpdate.season_id,
      package_id: this.dayChannelUpdate.package_id,
      channels: this.channel_list,
    };

    this.api.post("api/inventory/bulk_calendar_ota_store", f, true).subscribe(
      (res: any) => {
        //this.dayChannelUpdate = {room_id:'',change_date: '',season_id: '',package_id: ''};
        this.helper.alert_warning(
          "Changes has been saved but you have to push them!"
        );
        this.map_boxes();
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  checkLength(arr) {
    // check box channels length
    let r = arr.filter((e) => {
      return e.status == true;
    });
    return r.length;
  }
  /* Open Channel Modal END*/

  /* Open Restriction Modal */
  open_restriction_box2(box, date, ven) {
    this.dayRstrictionUpdte = {
      room_id: ven.room.id,
      change_date: date,
      season_id: ven.season_id,
      package_id: ven.package_id,
    };
    this.res_obj.cta = box.restrictions.cta;
    this.res_obj.ctd = box.restrictions.ctd;
    this.res_obj.stop_sell = box.restrictions.stop_sell;
    this.res_obj.max_stay.allowed = true;
    this.res_obj.min_stay.allowed = true;
    this.res_obj.max_stay.value = box.restrictions.max_stay;
    this.res_obj.min_stay.value = box.restrictions.min_stay;
    $("#ReModal").modal({ backdrop: "static", keyboard: false });
    $("#ReModal").draggable();
  }

  add_restriction(key, value) {
    if (key == "ctd") {
      this.res_obj.ctd = value;
    } else if (key == "cta") {
      this.res_obj.cta = value;
    } else if (key == "stop_sell") {
      this.res_obj.stop_sell = value;
    }
  }

  check_box_status(res) {
    // Check Box is close or not on date
    if (res.cta == true && res.ctd == true && res.stop_sell == true) {
      return true;
    } else if (res.cta == true) {
      return true;
    } else if (res.ctd == true) {
      return true;
    } else if (res.stop_sell == true) {
      return true;
    } else {
      return false;
    }
  }

  save_restriction() {
    let rAdd = [];
    let rRemove = [];

    if (this.res_obj.cta == true) {
      rAdd.push("cta");
    }
    if (this.res_obj.ctd == true) {
      rAdd.push("ctd");
    }
    if (this.res_obj.stop_sell == true) {
      rAdd.push("stop_sell");
    }
    if (this.res_obj.cta == false) {
      rRemove.push("cta");
    }
    if (this.res_obj.ctd == false) {
      rRemove.push("ctd");
    }
    if (this.res_obj.stop_sell == false) {
      rRemove.push("stop_sell");
    }

    let f = {
      user_id: JSON.parse(localStorage.getItem("user")).id,
      property_id: localStorage.getItem("current_property"),
      room_id: this.dayRstrictionUpdte.room_id,
      change_date: this.dayRstrictionUpdte.change_date,
      season_id: this.dayRstrictionUpdte.season_id,
      package_id: this.dayRstrictionUpdte.package_id,
      channel_add: "",
      channel_remove: "",
      channel_all: "",
      price: "",
      restriction_add: rAdd.join(),
      restriction_remove: rRemove.join(),
      min_stay: this.res_obj.min_stay.value,
      max_stay: this.res_obj.max_stay.value,
    };

    this.api.post("api/inventory/store_calendar", f, true).subscribe(
      (res: any) => {
        this.dayRstrictionUpdte = {
          room_id: "",
          change_date: "",
          season_id: "",
          package_id: "",
        };
        this.res_obj = {
          close_rates: false,
          stop_sell: false,
          cta: false,
          ctd: false,
          min_stay: { allowed: false, value: 1 },
          max_stay: { allowed: false, value: 1 },
        };
        // this.addChannel = [];
        // this.removeChannel = [];
        // this.allChannel = [];
        this.helper.alert_warning("Rate has been Pushed!");
        this.setRate([f]);
        this.map_boxes();
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }
  /* Open Restriction Modal END*/

  /* Holiday Get and Set on Date */
  getHolidays() {
    this.api.get("api/helper/get_holidays", true).subscribe(
      (res: any) => {
        this.holidays = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  checkHoliday(range) {
    if (this.holidays) {
      return this.holidays.find((e) => {
        if (
          moment(e.holiday_date).format("YYYY-MM-DD") ==
          moment(range).format("YYYY-MM-DD")
        ) {
          return e.name;
        }
      });
    }
  }
  /* Holiday Get and Set on Date END*/

  /* Open Package or Season detail Popup */
  openPackageDetail(ven) {
    if (ven.package_id != "") {
      let a = this.packages.filter(
        (e) => e.id == ven.package_id && e.room.id == ven.room.id
      );
      a = a.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      this.showPackageDetails = a[0];
      let start = moment(this.showPackageDetails.date_to, "DD/MM/YYYY");
      let end = moment(start, "YYYY-MM-DD").add("7", "days");
      this.calendar_date.startDate = start;
      this.calendar_date.endDate = end;
      this.get_range(true);
      this.current_table();
      setTimeout(() => {
        $("#package_details_popup").modal({
          backdrop: "static",
          keyboard: false,
        });
        $("#package_details_popup").draggable();
        $("#package_details_popup").draggable("disable");
      }, 2000);
    } else if (ven.season_id != "") {
      this.api
        .get(
          "api/season_package/show/" + ven.season_id + "/" + ven.room.id,
          true
        )
        .subscribe((res: any) => {
          this.showSeasonDetails = res.body.data;
          setTimeout(() => {
            $("#season_details_popup").modal({
              backdrop: "static",
              keyboard: true,
            });
            $("#season_details_popup").draggable();
            $("#season_details_popup").draggable("disable");
            $("#season_details_popup").click();
          }, 500);
        });
    }
  }

  showDate(date) {
    let d = date.split("/");
    let fd = d[1] + "/" + d[0] + "/" + d[2];
    return moment(fd).format("Do MMM");
  }

  showDate2(date) {
    return moment(date).format("Do MMM");
  }

  dateFunc2(d) {
    let a = d.split("/");
    return moment(a[1] + "/" + a[0] + "/" + a[2].split(" ")[0]);
  }

  checkDays(d) {
    let w = [
      { name: "Monday", status: false },
      { name: "Tuesday", status: false },
      { name: "Wednesday", status: false },
      { name: "Thursday", status: false },
      { name: "Friday", status: false },
      { name: "Saturday", status: false },
      { name: "Sunday", status: false },
    ];
    w.map((val) => {
      d.map((val2) => {
        if (val2 == val.name) {
          val.status = true;
        }
      });
    });
    return w;
  }

  nightCount(date_to, date_from) {
    let td = date_to.split("/");
    let tfd = td[1] + "/" + td[0] + "/" + td[2];
    let fd = date_from.split("/");
    let ffd = fd[1] + "/" + fd[0] + "/" + fd[2];
    return moment(ffd).diff(moment(tfd), "days");
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  chngeH(id) {
    let a = document.getElementById(id);
    a.classList.add("whitecolo");
  }

  chngeH2(id) {
    let a = document.getElementById(id);
    a.classList.remove("whitecolo");
  }

  imagech() {
    return "jiii";
  }

  /* Select Box new Work */
  selectBox($event, box, ven) {
    if ($event.target.checked) {
      this.selected_boxes.push(box);
      this.dayChannelUpdate = {
        room_id: ven.room.id,
        change_date: "",
        season_id: ven.season_id,
        package_id: ven.package_id,
      };
    } else {
      this.selected_boxes = this.selected_boxes.filter((val, i) => {
        console.log();
        return val.date == box.date &&
          val.id == box.id &&
          val.room_type_id == box.room_type_id
          ? false
          : true;
      });
    }
  }

  is_pkg_checked(date, id, rti) {
    return this.selected_boxes.some(
      (e) => e.date == date && e.id == id && e.room_type_id == rti
    );
  }

  is_pkg_disabled(rid, id, c, d) {
    // if (this.selected_boxes.length != 0) {
    //   return this.selected_boxes[0].room_type_id == rid && this.selected_boxes[0].id == id ? false : true;
    // }
    if (c != undefined && d != undefined) {
      let f = c.filter((e) => e.date == d.format("YYYY-MM-DD"));
      return f[0].room_count == 0 ? true : false;
    }
    return true;
  }

  mouo(id) {
    document.getElementById(id).style.visibility = "visible";
  }

  mouout(id) {
    document.getElementById(id).style.visibility = "hidden";
  }

  /* Select Box New Work END*/

  /* Open Bulk Update Popup*/
  selectOptiopns(param) {
    if (param == 1) {
      this.open_bulk_update();
      this.bulkCheck = true;
      setTimeout(() => (this.bulkOptionSelect = null));
    } else {
      this.bulkCheck = false;
      this.open_bulk_update();
      setTimeout(() => (this.bulkOptionSelect = null));
    }
  }

  async open_bulk_update() {
    let fdate = [];
    let newArrD = [];
    this.selected_boxes.map((val) => {
      fdate.push(val.date);
      newArrD.push({ room_id: val.room_id, dates: [] });
    });
    newArrD = newArrD.filter(
      (v, i, a) => a.findIndex((t) => t.room_id === v.room_id) === i
    );
    newArrD.map((val1, i) => {
      this.selected_boxes.map((val2, i2) => {
        if (val1.room_id == val2.room_id) {
          newArrD[i]["dates"].push(val2.date);
        }
      });
      val1.dates = val1.dates.sort((x, y) => +new Date(x) - +new Date(y));
    });
    let returnDays: boolean = false;
    if (this.selected_boxes.length == 0) {
      this.helper.alert_warning("Nothing Selected");
      return false;
    }

    /* for 3 day selection restriction */
    //  newArrD.map((val)=>{
    //   if(val.dates.length < 3){
    //     returnDays = true;
    //   }
    // })
    // if(returnDays){
    //   this.helper.alert_warning('Please Select Atleast 3 Days');
    //   return
    // }
    /* for 3 day selection restriction*/

    let result = [];
    let resultD = null;
    newArrD.map((val, i) => {
      for (let i = 0, l = val.dates.length - 1; i < l; i++) {
        let current = new Date(val.dates[i]),
          next = new Date(val.dates[i + 1]);
        if (
          1 <
          Math.ceil(
            Math.abs(next.getTime() - current.getTime()) / (1000 * 3600 * 24)
          )
        ) {
          // result = new Date(current.setDate(current.getDate() + 1));
          result.push(true);
          break;
        } else {
          result.push(false);
        }
      }
    });
    result.forEach((element) => {
      if (element == true) {
        resultD = true;
        return;
      }
    });
    if (resultD != null && !this.bulkCheck) {
      this.helper.alert_warning(`Select dates in sequence.`);
      return false;
    }
    if (this.bulkCheck == true) {
      if (this.selected_boxes.length == 1) {
        let list = JSON.parse(JSON.stringify(this.channels));
        list.forEach((ota) => {
          this.selected_boxes[0].otas.map((val) => {
            if (ota.id == val.id) {
              ota.is_binded = val.status;
            }
          });
        });
        this.bulk_channel = list;
        this.openModal();
      } else {
        let list = JSON.parse(JSON.stringify(this.channels));
        list.forEach((ota) => {
          ota.is_binded = false;
        });
        this.bulk_channel = list;
        this.openModal();
      }
    } else {
      let list = JSON.parse(JSON.stringify(this.channels));
      list.forEach((ota) => {
        this.selected_boxes[0].otas.map((val) => {
          if (ota.id == val.id) {
            ota.is_binded = val.status;
          }
        });
      });
      this.bulk_channel = list;
      this.openModal();
      this.getPackageType("param");
    }
  }

  openModal() {
    $("#bulk_update").modal({ backdrop: "static", keyboard: true });
    $("#bulk_update").draggable();
    $("#bulk_update").draggable("disable");
  }

  add_bulk_channels(e, id) {
    if (e.target.checked) {
      this.bulkUpdate_add_channel.push(id);
    } else {
      this.bulkUpdate_add_channel.splice(
        this.bulkUpdate_add_channel.indexOf(id),
        1
      );
    }
  }

  add_bulk_restriction(key, value) {
    if (key == "ctd") {
      this.res_bulk_obj.ctd = value;
    } else if (key == "cta") {
      this.res_bulk_obj.cta = value;
    } else if (key == "stop_sell") {
      this.res_bulk_obj.stop_sell = value;
    }
  }

  save_bulkUpdate() {
    let final = [];
    let finalPackage: any;
    let rAdd = [];
    if (this.bulkCheck == true) {
      // for Update inventory
      if (this.res_bulk_obj.cta == true) {
        rAdd.push("cta");
      }
      if (this.res_bulk_obj.ctd == true) {
        rAdd.push("ctd");
      }
      if (this.res_bulk_obj.stop_sell == true) {
        rAdd.push("stop_sell");
      }
      this.selected_boxes.map((val) => {
        final.push({
          user_id: JSON.parse(localStorage.getItem("user")).id,
          property_id: localStorage.getItem("current_property"),
          // room_id: this.dayChannelUpdate.room_id,
          room_id: val.room_id,
          change_date: val.date,
          season_id: val.season_id,
          package_id: val.package_id,
          channel_add: this.bulkUpdate_add_channel.join(),
          channel_remove: "",
          price: this.bulk_price != "" ? this.bulk_price : val.room_rate,
          restriction_add: rAdd.join(),
          restriction_remove: "",
          min_stay: this.bulk_min_stay,
          max_stay: this.bulk_max_stay,
        });
      });
      console.log(final);

      this.api.post("api/inventory/bulk_calendar_store", final, true).subscribe(
        (res: any) => {
          this.closeBulkModal(true);
          this.map_boxes();
          this.setRate(final);
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    } else {
      // For Create Package

      if (this.new_package_name == "") {
        this.helper.alert_warning("Please Enter Package Name");
        return false;
      }
      let rAdd = [];
      if (this.res_bulk_obj.cta == true) {
        rAdd.push("cta");
      }
      if (this.res_bulk_obj.ctd == true) {
        rAdd.push("ctd");
      }
      if (this.res_bulk_obj.stop_sell == true) {
        rAdd.push("stop_sell");
      }
      if (this.bulk_price == "") {
        this.helper.alert_warning("Please Enter Pakcage Rates");
        return false;
      }
      let fdate = [];
      let newArrD = [];
      let finalPackageDemo = [];
      this.selected_boxes.map((val) => {
        fdate.push(val.date);
        newArrD.push({ room_id: val.room_id, dates: [] });
      });
      newArrD = newArrD.filter(
        (v, i, a) => a.findIndex((t) => t.room_id === v.room_id) === i
      );
      newArrD.map((val1, i) => {
        this.selected_boxes.map((val2, i2) => {
          if (val1.room_id == val2.room_id) {
            newArrD[i]["dates"].push(val2.date);
          }
        });
        val1.dates = val1.dates.sort((x, y) => +new Date(x) - +new Date(y));
        finalPackageDemo.push({
          user_id: JSON.parse(localStorage.getItem("user")).id,
          property_id: localStorage.getItem("current_property"),
          room_id: val1.room_id,
          change_date: val1.dates,
          season_id: this.selected_boxes[0].season_id,
          package_id: this.selected_boxes[0].package_id,
          otas: this.bulkUpdate_add_channel.join(),
          channel_remove: "",
          price: this.bulk_price,
          restriction_add: rAdd.join(),
          restriction_remove: "",
          min_stay: this.bulk_min_stay,
          max_stay: this.bulk_max_stay,
          package_name: this.new_package_name,
          package_type: this.pacType,
        });
      });
      if (this.bulk_min_stay == "") {
        this.helper.alert_warning("Please Enter Min Stay.");
        return false;
      }
      if (this.bulk_max_stay == "") {
        this.helper.alert_warning("Please Enter Max Stay.");
        return false;
      }
      console.log({ data: finalPackageDemo });
      this.api
        .post(
          "api/package/store_calendar_package",
          { data: finalPackageDemo },
          true
        )
        .subscribe(
          (res: any) => {
            this.closeBulkModal(true);
            this.map_boxes();
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  closeBulkModal(check) {
    if (check) {
      this.res_bulk_obj = { stop_sell: false, cta: false, ctd: false };
      this.dayChannelUpdate = {
        room_id: "",
        change_date: "",
        season_id: "",
        package_id: "",
      };
      this.bulk_min_stay = undefined;
      this.bulk_max_stay = undefined;
      this.bulk_price = "";
      this.bulkUpdate_add_channel = [];
      this.bulk_channel = [];
      this.selected_boxes = [];
      $("#bulk_update").modal("hide");
    } else {
      this.bulk_min_stay = undefined;
      this.bulk_max_stay = undefined;
      this.bulk_price = "";
      this.bulkUpdate_add_channel = [];
      $("#bulk_update").modal("hide");
    }
  }
  /* Open Bulk Update Popup END*/
  dodrag(id) {
    $("#" + id).draggable("enable");
  }

  stopDrag(id) {
    $("#" + id).draggable("disable");
  }

  colorRadio(id, idt) {
    var a = document.getElementById(id);
    var b = document.getElementById(idt);
    a.classList.contains("radioByJs")
      ? a.classList.remove("radioByJs")
      : a.classList.add("radioByJs");
    b.classList.contains("radioByJs")
      ? b.classList.remove("radioByJs")
      : b.classList.add("radioByJs");
  }

  getPackageType(param) {
    setTimeout(() => {
      this.bulk_channel.forEach((element, index) => {
        let chkB1 = <HTMLInputElement>(
          document.getElementById("channelCheckbox" + index)
        );
        let chkB2 = <HTMLInputElement>(
          document.getElementById("otaCheckbox" + index)
        );
        this.bulkUpdate_add_channel = [];
        if (this.pacType == "Channel" && element.type == "External") {
          element.is_binded = false;
          chkB2.checked = false;
        } else if (this.pacType == "OTAs" && element.type == "Internal") {
          element.is_binded = false;
          chkB1.checked = false;
        } else if (this.pacType == "Loyalty") {
          element.is_binded = false;
          chkB1 != null ? (chkB1.checked = false) : null;
          chkB2 != null ? (chkB2.checked = false) : null;
        }
      });

      this.ref.detectChanges();
    }, 500);
  }

  setRate(final) {
    this.api.post("api/expedia_push/setRestrictions", final, true).subscribe(
      (res: any) => {
        console.log(res);
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  getBoxPrice(roomTypeId: number, packageId: number, date: string): string {
    let packages = this.roomPackagesMap.get(roomTypeId);

    if (packages) {
      let currentPackage = packages.find((x) => x.id == packageId);

      if (!currentPackage) {
        return "Bar";
      }

      let prices = currentPackage.prices;
      let val = prices.find((x) => x.date_day == date);

      if (val) {
        let packagePrice = val.price;

        let season = this.roomSeasonsMap.get(roomTypeId);
        let barPrice = season.room_rate;

        if (isNaN(packagePrice) || isNaN(barPrice) || barPrice === 0) {
          return "Error";
        }

        let diff = ((packagePrice - barPrice) / barPrice) * 100;

        return diff.toFixed(1) + "%";
      }
    }

    return "Bar";
  }


  getBackgroundColor(box): string {
    const percentage = parseFloat(
      this.getBoxPrice(box.room_type_id, box.package_id, box.date)
    );
    let bgColor = "";

    if (!isNaN(percentage)) {
      if (percentage > 0) {
        bgColor = "linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21))";
      } else if (percentage < 0) {
        bgColor = "linear-gradient(rgb(249 25 25), rgb(115 6 35))";
      } else {
        bgColor = "linear-gradient(#7340ce, #300087)";
      }
    }

    return bgColor;
  }

  getArrowIconClass(box): string {
    const percentage = parseFloat(
      this.getBoxPrice(box.room_type_id, box.package_id, box.date)
    );
    let iconClass = "";

    if (!isNaN(percentage)) {
      iconClass = percentage > 0 ? "fa fa-caret-up" : "fa fa-caret-down";
    }

    return iconClass;
  }

  getEmptyElements(count: number) {
    return new Array(count).map(x => '');
  }
}
