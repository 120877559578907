<div class="" id="colShrt" style="padding-right: 5px;">

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '"
    style="height: 52px;">
    <a class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{"back_office.manage_rooms.back_office" |
      translate}}</a>
    <a class="btn btn-link active" routerLink="../preview-hotel">{{"back_office.manage_rooms.preview_hotel" | translate}}</a>

    <!-- <div class="searchLeft">
      <div class="row" style="    margin-top: 5px; margin-right: -14px;">
        <div class="col-md-7 sear">
          <input type="text" class="form-control keyword" placeholder="{{'back_office.manage_rooms.search_by_keyword' | translate}}" [(ngModel)]="query" />
        </div>
        <div class="col-md-1 btngo" >
          <img src="assets/img/other/go.png" alt="" class=" gobtn" (click)="param =query" style="height:35px !important; margin-top:3px; margin-left: 3px;" (click)="conPadd()">
        </div>
        <div class=" col-md-4 btncreate" >
          <a [routerLink]="['/app/back_office/create_rooms']" class="btn btn-danger btn-block btn-lg hov " [ngClass]="darkMode ? 'darkBtn' : 'null'">+ {{ "back_office.manage_rooms.create_new_room" | translate }}</a>
        </div>
      </div>
    </div> -->
  </div>


  <div [ngClass]="darkMode ? 'card-dark' : 'card'"
    [ngStyle]="{'padding': darkMode ? '5px' : '', 'border-radius': darkMode ? '10px' : ''}">
    <div class="row">
      <div class="col-md-3" [ngClass]="darkMode ? 'borrightdARKk' : 'borrightLightt'">
        <div id="myCarousel1" class="carousel slide myCarousel" data-ride="carousel" data-interval="false">
          <div class="carousel-inner">
            <div class="item" *ngFor="let img of property?.images;let imgIndex = index"
              [ngClass]="imgIndex == 0  ? 'active' : null">
              <img [src]="api.imgUrl + img.image" alt="room images">
            </div>
          </div>

          <a class="left carousel-control" href="#myCarousel1" data-slide="prev">
            <span class="glyphicon "><i class="fa fa-chevron-left"></i></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="#myCarousel1" data-slide="next">
            <span class="glyphicon "><i class="fa fa-chevron-right"></i></span>
            <span class="sr-only">Next</span>
          </a>

        </div>
      </div>

      <div class="col-md-7" [ngClass]="darkMode ? 'borrightdARK' : 'borrightLight'">
        <div style="padding: 0px 5px 0 10px;">
          <div class="row">
            <div class="col-md-4 grid_20">
              <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp one'">{{"back_office.manage_rooms.name" | translate }} : {{property?.name}}</div>
            </div>
            <div class="col-md-4 grid_20">
              <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp two'">{{"back_office.manage_rooms.phone" | translate }} : {{property?.phone}}</div>
            </div>
            <div class="col-md-4 grid_20">
              <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp three'">{{"back_office.manage_rooms.email" | translate }} : {{property?.email}}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp four'">{{"back_office.manage_rooms.totalrooms" | translate }} :
                {{property?.room_count}}</div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp five'">{{"back_office.manage_rooms.address" | translate }} : {{property?.address_one}}
              </div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">{{"back_office.manage_rooms.address" | translate }} : {{property?.address_two}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">{{"back_office.manage_rooms.country" | translate }}: {{property?.country}}
              </div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp eight'">{{"back_office.manage_rooms.state" | translate }}: {{property?.state}}</div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp nine'">{{"back_office.manage_rooms.city" | translate }}: {{property?.city}}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-2 col26">
        <div class="neomocard" [ngClass]="darkMode ? 'neomocarddark' : 'neomocardlight'">
          <div>
            <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="darkMode ?  'btn-hov_dark' : 'btn-hov'">{{
              "rates_and_packages.menu.push" | translate }}</a>
            <!-- <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="darkMode && room.expedia_status == 'Active' ?  'btn-hov_dark live-btn' : 'btn-hov live-btn'" style="cursor: default;">LIVE</a> -->
          </div>
          <div class="card_ui">
            <span style="float: right;margin-top: 2px;">

              <a href="javascript:void(0)" class="" (click)="amenittyModal(property.with_parent_amenities)"
                style=" margin-right: 5px;">
                <img
                  src="{{darkMode ? 'assets/img/newstaticicons/back-office/amenities-01.svg' : 'assets/img/newstaticicons/back-office/amenities-01.svg'}}"
                  alt="" class="ichove" style="width: 29px;">
              </a>

              <!-- OTAs Modal -->
              <a href="javascript:void(0)" class="" (click)="openOTAsModal()" style=" margin-right: 5px;">
                <img
                  src="{{darkMode ? 'assets/img/newstaticicons/back-office/connetivity.svg' : 'assets/img/newstaticicons/back-office/connetivity.svg'}}"
                  alt="" class="ichove" style="width: 29px;">
              </a>

              <!-- Channels Modal -->
              <a href="javascript:void(0)" class="" (click)="openChannelsModal()" style=" margin-right: 5px;">
                <img
                  src="{{darkMode ? 'assets/img/newstaticicons/back-office/await.svg' : 'assets/img/newstaticicons/back-office/await.svg'}}"
                  alt="" class="ichove" style="width: 29px;">
              </a>


              <a routerLink="/app/back_office/hotel_setup" style="margin-right: 5px;">
                <img width="30px" src="assets/img/newstaticicons/back-office/edit.svg" class="ichove" />
              </a>

            </span>
          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-md-12">
        <div style="padding-left: 20px;padding-right: 5px;">
          <div class="row">
            <div class="col-md-3 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp ten'">{{"back_office.manage_rooms.postal_cade" | translate }}:
                {{property?.postal_code}}</div>
            </div>
            <div class="col-md-3 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp eleven'">{{"back_office.manage_rooms.weekdays" | translate }}:
                {{property?.weekend_starts}}</div>
            </div>
            <div class="col-md-3 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twelve'">{{"back_office.manage_rooms.timezone" | translate }}: {{property?.timezone}}
              </div>
            </div>
            <div class="col-md-3  grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twenty'">{{"back_office.manage_rooms.pricing_pack" | translate }}:
                {{property?.pricingpack}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twenty'">{{"back_office.manage_rooms.currency" | translate }}: {{property?.currency}}
              </div>
            </div>
            <div class="col-md-3 grid_20">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp fourteen'">{{"back_office.manage_rooms.min_rate" | translate }}:
                {{property?.min_rate}}</div>
            </div>
            <div class="col-md-3" style="padding-left: 5px;">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp fifteen'">{{"back_office.manage_rooms.eta" | translate }}:{{property?.eta}}</div>
            </div>
            <div class="col-md-3" style="padding-left: 5px;">
              <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp sixteen'">{{"back_office.manage_rooms.etd" | translate }}:{{property?.etd}}</div>
            </div>
          </div>


          <!-- Extra expenses  headding starting from here -->

          <!-- <div class="col-md-12 grid_20"> -->
            <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
                <div class="row">

                <div class="col-md-4 grid_20" style="border-right: 2px solid white;   background: linear-gradient( #e06c6d, #0c1f44); color: white; border-top-left-radius: 10px;
                border-bottom-left-radius: 10px; font-weight: 700;">
                  <h4 class="extra_expense"> {{"back_office.manage_rooms.upsell" | translate }}</h4>
                </div>
                <div class="col-md-4 grid_20" style="border-right: 2px solid white;  color: white;  background: linear-gradient( #e06c6d, #0c1f44)"  >
                  <h4 class="extra_expense"> {{"back_office.manage_rooms.extras" | translate }}</h4>
                </div>
                <div class="col-md-4 grid_20" style=" color: white; border-top-right-radius: 10px;
                border-bottom-right-radius: 10px; font-weight: 700;">
                  <h4 class="extra_expense"> {{"back_office.manage_rooms.mini_bar" | translate }}</h4>
                </div>

              </div>
            </div>
          <!-- </div> -->




            <div class="row">

              <div class="col-md-4 grid_20" style="margin-top: 4px; padding-right: 5px !important;">

                  <div *ngFor="let up of upsell">
                    <div class="col-md-8  " style="padding-right: 0px; padding-left: 0px;">

                      <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp one'"> {{up.name}}</div>
                    </div>

                    <div class="col-md-4" style="padding-left: 5px; padding-right: 0px;">
                      <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp two'">{{up.price}}</div>
                    </div>
                  </div>
                </div>


              <div class="col-md-4 grid_20" style="margin-top: 4px; padding-left: 0px !important;">

                  <div *ngFor="let ex of extra">
                    <div class="col-md-8" style="padding-right:7px; padding-left: 0px;">
                      <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp three'">{{ex.name}}
                      </div>
                    </div>

                    <div class="col-md-4 " style="padding-left: 0px; padding-right: 0px;">
                      <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp four'">
                        {{ex.price}}
                      </div>
                    </div>
                  </div>
                </div>


              <div class="col-md-4 grid_20" style="margin-top: 4px;">

                  <div *ngFor="let mini of minibar">
                    <div class="col-md-8 " style="padding-right: 5px; padding-left: 0px;">
                      <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp five'">{{mini.name}}
                      </div>
                    </div>

                    <div class="col-md-4" style="padding: 0px;">
                      <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">
                        {{mini.price}}
                      </div>
                    </div>
                  </div>
                </div>


            </div>
          </div>


      </div>
    </div>
  </div>


  <div class="modal-footer mymodal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
    <a (click)="hideModal()" class="btn btn-danger btn-lg hov" data-dismiss="modal" aria-label="Close">{{
      "back_office.manage_rooms.close" | translate }}</a>
  </div>
</div>



<div id="draggable" *ngIf="showBoxOta == true"
  [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">

  <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
    [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
    style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;" (mouseover)="drag('do')"
    (mouseout)="drag('dont')">
    {{ "back_office.manage_rooms.CONNECTIVITY_test" | translate }}
    <button aria-label="Close" class="close hor" type="button" (click)="showBoxOta = !showBoxOta">
      <strong>&#x2716;</strong>
    </button>
  </div>

  <div class="scroll"
    [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">
    <div class=" col-md-12 pl-0 pr-0">
      <label class="allLabel" for="allSelectOta">
        <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">OTA's</div>
        <!-- <input class="checkbox" name="booked_date" id="allSelectOta" type="checkbox" (click)="allSelectOta($event)"/> -->
        <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
      </label>
      <ng-container *ngFor="let ota of otas">
        <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null">
          <label>
            <span class="con-chan" [ngClass]="darkMode ? 'con-chan-dark' : 'con-chan'">
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota?.image : ota?.image}}" alt=""
                class="image-137 pr-5 pl-5" style="width: 100%;" />
            </span>
            <!-- <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}"
              (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" /> -->
            <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
          </label>
        </div>
      </ng-container>
      <label *ngIf="this.otas.length<1">No OTAs Connected</label>
    </div>


  </div>

  <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
    {{ "inventory.promo.save" | translate }}
  </button> -->
</div>





<div id="draggable3" *ngIf="showBoxExtra == true"
  [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">

  <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
    [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
    style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;" (mouseover)="drag('do')"
    (mouseout)="drag('dont')">
    {{ "back_office.manage_rooms.CONNECTIVITY" | translate }}
    <button aria-label="Close" class="close hor" type="button" (click)="showBoxExtra = !showBoxExtra">
      <strong>&#x2716;</strong>
    </button>
  </div>

  <!-- <div class=" col-md-6 pl-0 pr-0" >
      <label class="allLabel" for="allSelectChannel" style="padding-right: 5px;">
        <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
          <input class="checkbox" name="booked_date" id="allSelectChannel" type="checkbox" (click)="selectAllCh($event)"/>
        <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
      </label>
      <ng-container *ngFor="let ota of channels">
        <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null" *ngIf="ota.type == 'Internal'">
          <label>
          <span class="con-chan">
            <img src="assets/img/svgicons/channels/{{ota?.image}}" alt="" class="image-137 pr-5 pl-5" style="width: 96%;" />
          </span>
            <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}" (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
            <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
          </label>
        </div>
      </ng-container>
    </div> -->

  <div class="scroll"
    [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">
    <div class=" col-md-12 pl-0 pr-0">
      <label class="allLabel" for="allSelectChannel">
        <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'"> {{ "back_office.manage_rooms.Channels" | translate }}</div>
        <!-- <input class="checkbox" name="booked_date" id="allSelectOta" type="checkbox" (click)="selectAllChannels($event)"/> -->
        <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
      </label>

      <ng-container *ngFor="let channel of channels">
        <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null">
          <label>
            <span class="con-chan">
              <img src="assets/img/svgicons/channels/{{channel?.image}}" alt="" class="image-137 pr-5 pl-5"
                style="width: 100%;" />
            </span>
            <input class="checkbox" name="booked_date" type="checkbox" value="{{ channel?.name }}"
              (click)="add_bulk_channels($event, channel.id)" [checked]="channel?.isBinded" />
            <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
          </label>
        </div>
      </ng-container>
      <label *ngIf="this.channels.length<1">No Channels Connected </label>
    </div>
  </div>

  <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
    {{ "inventory.promo.save" | translate }}
  </button> -->
</div>
<!-- <div class=" col-md-6 pl-0 pr-0" >
      <label class="allLabel" for="allSelectChannel" style="padding-right: 5px;">
        <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
          <input class="checkbox" name="booked_date" id="allSelectChannel" type="checkbox" (click)="selectAllCh($event)"/>
        <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
      </label>
      <ng-container *ngFor="let ota of channels">
        <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null" *ngIf="ota.type == 'Internal'">
          <label>
          <span class="con-chan">
            <img src="assets/img/svgicons/channels/{{ota?.image}}" alt="" class="image-137 pr-5 pl-5" style="width: 96%;" />
          </span>
            <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}" (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
            <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
          </label>
        </div>
      </ng-container>
    </div> -->





<!-- <div id="draggable3" *ngIf="showBoxExtra == true" [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#f0f0f0'}">

<div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5" [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '" style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;" (mouseover)="drag('do')" (mouseout)="drag('dont')" >
  {{ "inventory.table.channels" | translate }}
  <button aria-label="Close" class="close hor" type="button" (click)="showBoxExtra = !showBoxExtra">
    <strong>&#x2716;</strong>
  </button>
</div>

  <div class="scroll" [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#f0f0f0'}">
    <div class=" col-md-12 pl-0 pr-0" >
      <ng-container *ngFor="let e of extra">
        <label class="allLabel" for="allSelectOta">
          <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Extras</div>
        </label>
        <ng-container *ngFor="let ota of channels">
          <div style="display: flex;">
            <div>{{e.name}}</div>
            <div>{{e.price}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </div>

  <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
    {{ "inventory.promo.save" | translate }}
  </button>
</div> -->

<div id="draggable2" *ngIf="showAmeBox == true" [ngClass]="darkMode ? 'AmiBack' : 'null'">

  <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
    [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
    style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;">
    {{ "back_office.manage_rooms.Amenities" | translate }}
    <button aria-label="Close" class="close hor" type="button" (click)="showAmeBox = !showAmeBox">
      <strong>&#x2716;</strong>
    </button>
  </div>

  <div class="cardBox" [ngClass]="darkMode ? 'AmiBack' : 'null'">

    <div class="row" style="padding: 0px 15px 0px 20px;">
      <div class="col-md-6 zero-padding">
        <ng-container *ngFor="let ami of aminity;let i = index">
          <div class="col-md-12 AmiCard-C4" *ngIf="i%2 != 0">
            <div class="cardAmenity">
              <div class="row">
                <div class="col-md-12 pr20">

                  <div class="line">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="icoAmeni">
                          <img src="assets/img/hotel-setup/{{darkMode ? 'darkmode/icons/'+ ami.icon  : ami.icon}}"
                            alt="ameniti image" class="amiIcon">
                        </div>
                        <div class="nameAmeni">
                          <span
                            [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                          <a data-toggle="collapse" href="#collapse{{i}}">
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              id="dropDown{{i}}" class="drop" alt="" (click)="changeDrop('dropDown'+i)">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{i}}">
                  <p *ngFor="let child of ami.children">{{child.title}}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>


      <div class="col-md-6 zero-padding">
        <ng-container *ngFor="let ami of aminity;let i = index">
          <div class="col-md-12 AmiCard-C4" *ngIf="i%2 == 0">
            <div class="cardAmenity">
              <div class="row">
                <div class="col-md-12 pr20">
                  <div class="line">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="icoAmeni">
                          <img src="assets/img/hotel-setup/{{darkMode ? 'darkmode/icons/'+ ami.icon  : ami.icon}}"
                            alt="ameniti image" class="amiIcon">
                        </div>
                        <div class="nameAmeni">
                          <span
                            [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                          <a data-toggle="collapse" href="#collapse{{i}}">
                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              id="dropDown{{i}}" class="drop" alt="" (click)="changeDrop('dropDown'+i)">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{i}}">
                  <p *ngFor="let child of ami.children">{{child.title}}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

    </div>

  </div>

</div>
