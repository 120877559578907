import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor() { }

  darkMode: boolean = false;

  ngOnInit() {

    this.checkDarkMode()
    // drag function code--------------------
  //   $(function () {
  //     $("#drag-list").dragsort({
  //       placeHolderTemplate: "<div class='placeHolder' style=''>" +
  //             "<div style='background-color: #fff !important; height: 70px;margin-bottom:5px;'></div>" +
  //             "</div>",
  //             cursor: "move"
  //     });

  // });
  // drag function end-------------------

  $("#drag").mouseup(function(){
    //$(this).after("<p style='color:green;'>Mouse button released.</p>");
    console.log("mouse doupwn")
    $(".field-space").css({"cursor":"grab"})
  });
  $("#drag").mousedown(function(){
    //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
    console.log("mouse down")
    $(".field-space").css({"cursor":"grabbing"})
  });
  $("#drag").sortable({
    // scroll: false,
    // axis: 'y'
  });

  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }



}
