import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input('dashboard') dashboard: any;
  darkMode: boolean = false;
  color_border = ['#ff5e57', '#a07cc6', '#6af686', '#48fff3', '#b3bf00'];
  color_borders_dark = ['#f53232', 'green', 'purple', 'orange', 'blue', 'olive'];
  
  constructor() { }

  ngOnInit() {
    this.checkDarkMode()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

}
