import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
// import { BackOfficeComponent } from '../../back-office/back-office.component';
declare var $: any;
@Component({
  selector: 'app-preview-rooms',
  templateUrl: './preview-rooms.component.html',
  styleUrls: ['./preview-rooms.component.css']
})
export class PreviewRoomsComponent implements OnInit {

  previewData:any;
  p_image;
  amenities;
  aaa = [];
  ami;
  darkMode:boolean = false;
  desT:boolean = false
  imgUrl:string = this.api.base_url2+'app/images/';

  constructor(
    private api: ApiService,
    private helper: HelperService,
    private router: Router,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    // private bo: BackOfficeComponent
  ) { }

 amenity0 = [
  {
    pTitle:'Bedding & Bath',
    id:18,
    pImg:'room_bed',
    cTitle:[]
  },
  {
    pTitle:'Cookery',
    id:19,
    pImg:'cookery',
    cTitle:[]
  },
  {
    pTitle:'Entertainment',
    id:20,
    pImg:'entertainment',
    cTitle:[]
  },
  {
    pTitle:'General',
    id:17,
    pImg:'room_general',
    cTitle:[]
  },
  {
    pTitle:'Laundry',
    id:21,
    pImg:'laundry',
    cTitle:[]
  },
  {
    pTitle:'Room Specific',
    id:22,
    pImg:'room_specific',
    cTitle:[]
  },
   ];
   amenCol = ['#a9aae0','#d24f51','#bf4eeb','#efab20','#32d04a','#519eb9']

  ngOnInit() {
    this.checkDarkMode();
    console.log(history.state)
    this.previewData = history.state;
    console.log('preview data',this.previewData);
    this.getHightPrev()



this.get_amenities()
this.imageindex();

  }

  img_preview(img){
    this.p_image  = img
  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }




  getArr(a){
    let r = a.split(',');
    return r;
  }

  changeCaret1(y){
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let a = document.getElementById(y);
    if(a.className == "fa fa-caret-up down2"){
      a.className = "fa fa-caret-down down2"
  }
  else{
    a.className = "fa fa-caret-up down2"
  }
    console.log(a)
  }

  changeCaret2(z){
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let b = document.getElementById(z) ;
    if(b.className == "fa fa-caret-down down1"){
        b.className = "fa fa-caret-up down1"
    }
    else{
      b.className = "fa fa-caret-down down1"
    }

  }

imageindex(){
  if(this.previewData.room_name_images != undefined){
    this.p_image = this.previewData.room_name_images[0].image;
  }
  else{
    this.p_image = this.previewData.images[0].image;
  }
}


  onSubmit(){
    // this.api.post('api/room/store', this.create_room_form.value,true).subscribe((res:any) => {
    //   this.helper.alert_success("Room Added Successfully...");
    //   this.router.navigate(['/app/back_office/manage_rooms']);
    // }, err => this.error_handling.handle_error(err.status));
    console.log(this.previewData);
    let f = [];
    this.previewData.floors_data.map((val)=>{
      val.floors.map((val2)=>{
        f.push(
          {
            building_id: val.id,
            floor_id: val2.id,
            room_num: val2.room_num
          })
      })
      var filtered = f.filter(function (el) {
        return el != null;
      });
      console.log(filtered + "rooms")
    })
    console.log(f);


    let a = {
      adults: this.previewData.adults,
      bed_type_id: this.previewData.bed_type_id,
      bed_type_name: this.previewData.bed_type_name,
      children: this.previewData.children,
      description: this.previewData.description,
      floors_data: f,
      infants: this.previewData.infants,
      most_amenties: this.previewData.most_amenties,
      no_of_beds: this.previewData.no_of_beds,
      property_id: this.previewData.property_id,
      room_count: this.previewData.room_count,
      room_name_images:this.previewData.room_name_images,
      room_size: this.previewData.room_size,
      room_type_id: this.previewData.room_type_id,
      room_type_name: this.previewData.room_type_name,
      smoking: this.previewData.smoking.toString(),

      room_type_class:  this.previewData.room_type_class,
      room_size_type: this.previewData.room_size_type,
      bedsize:  this.previewData.bedsize,

      room_view_id: this.previewData.room_view_id,
      adults_age: this.previewData.adults_age,
      children_age: this.previewData.children_age,
      infants_age: this.previewData.infants_age,
      pets: this.previewData.pets.toString(),
      wheelchairaccessibility: this.previewData.wheelchairaccessibility.toString(),
      value_add_inclusions: this.previewData.value_add_inclusion.join(),
      extrabedding: this.previewData.extrabedding
    }
    this.api.post('api/room/store', a,true).subscribe((res:any) => {
        this.helper.alert_success("Room Added Successfully...");
        // this.bo.getHeight();
        this.router.navigate(['/app/back_office/manage_rooms']);
      }, err => this.error_handling.handle_error(err.status));
  }



  get_amenities(){
    this.api.post('api/amenitie/index', {order: 'other', type: 'Room','parent_id':'Null'}, true).subscribe((res:any) => {
      this.amenities = res.body.data;
      console.log(this.amenities);

      if( this.previewData.amenities_title != undefined ){
        this.amenities.map((val)=>{
          val.children.map((val2)=>{
            this.previewData.amenities_title.map((val3)=>{
              if(val2.id == val3.amenitie_id){
                //this.create_room_form.value.amenities_title.push({name:val2.title})
                this.aaa.push({
                  title: val2.title,
                  parentId: val.id,
                  parentTitle:val.title
                })

              }
            })
          })

        })
      }
      else{
        this.amenities.map((val)=>{
          val.children.map((val2)=>{
            this.previewData.amenities.map((val3)=>{
              if(val2.id == val3.amenitie_id){
                //this.create_room_form.value.amenities_title.push({name:val2.title})
                this.aaa.push({
                  title: val2.title,
                  parentId: val.id,
                  parentTitle:val.title
                })

              }
            })
          })

        })
      }




      console.log(this.aaa)
      this.aminity0();
    }, err => this.error_handling.handle_error(err.status));




  }





aminity0(){

if( this.previewData.amenities_title != undefined ){
  this.ami = this.previewData.amenities_title;
}
else{
  this.ami = this.aaa
}




  this.ami.map((val0)=>{
    this.amenity0.map((val1)=>{
      if(val1.id == val0.parentId){
        val1.cTitle.push({name:val0.title})
      }
    })
  })
}

getHightPrev(){
  var a = document.getElementById("hideColRes")
  if(a.offsetHeight > 700){
    a.style.paddingRight = '5px'
  }else{
    a.style.paddingRight = '5px'
  }
}

seeDescription(){
  this.desT = !this.desT
      }

      descripArray(data){
        return data.split(' ').slice(0, 100).join().replace(/,/g, " ");
      }
      descripArray2(data){
        return data.split(' ').slice(100).join().replace(/,/g, " ");
      }


}

