<div id="SSAASS">
	<!-- <div class="alert alert-info alert-dismissible show" role="alert" id="grab-13" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
    {{ service.is_edit == true ? "Edit Event" : "Create Event" }}
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <strong id="strong">&#x2716;</strong>
    </button>
  </div> -->
</div>
<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '"> <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">{{ 'rates_and_packages.link_to_ota.rates_and_packages' | translate }}</a> <a href="" class="btn btn-link active" [routerLink]="link_to_ota">{{ 'rates_and_packages.link_to_ota.link_to_otas' | translate }}</a>
	<div class="col-md-1 select-all">
		<label class="control-label checkbox-style" style="margin-left: 6px">
			<input type="checkbox" class="checkbox" id="allS" (change)="selectAll($event)"> <span class="checkbox-checkmark"></span> <span class="permission-title">{{ "rates_and_packages.link_channel.select_all" | translate }}</span> </label>
	</div>
</div>
<!-- pricing_model -->
<div class="no_scroll scroll">
  <form [formGroup]="create_package_form" class="form-horizontal back-form pr" >
    <div class="white_box channels w-clearfix padding-adj" id="SP" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
      <div class="col-md-12" style="padding-right: 5px;padding-top: 0px;padding-bottom: 0px;padding-left: 0px;">
        <div *ngIf="pricing_modell == 'PerDayPricing' || pricing_modell == 'Both'">
        <div class="row" *ngIf="pdChannel.length > 0">
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="price-heading">
                Per Day Pricing
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let channel of channels">
          <div class="col-md-4 csw" style="padding-right: 0px;" *ngIf="channel?.pricing_model == 'PerDayPricing'">
            <div class="channel_container {{ channel.name }} w-clearfix" style="display: flex;" [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '" >
              <div class="form_container">
                <div class="form-block w-clearfix">
                  <div class="form">
                    <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                      <input type="checkbox" id="check-{{ channel.id }}" value="{{ channel.id }}" name="channel" data-name="Channel Icon" class="channels_checkbox w-checkbox-input checkbox chkbx" [checked]="channel?.is_checked">
                    </label>
                  </div>
                  <div class="w-form-done"></div>
                  <div class="w-form-fail"></div>
                </div>
              </div>
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + channel.image : channel.image }}" alt="" class="tripadvisor ota-icon">
            </div>
          </div>
        </ng-container>
      </div>
        <div *ngIf="pricing_modell == 'occupancy' || pricing_modell == 'Both'">
        <div class="row" *ngIf="oBChannel.length > 0">
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="price-heading">
                Occupancy Base Pricing
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let channel of channels">
          <div class="col-md-4 csw" style="padding-right: 0px;" *ngIf="channel?.pricing_model != 'PerDayPricing'" >
            <div class="channel_container {{ channel.name }} w-clearfix" style="display: flex;" [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
              <div class="form_container">
                <div class="form-block w-clearfix">
                  <div class="form">
                    <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                      <input type="checkbox" id="check-{{ channel.id }}" value="{{ channel.id }}" name="channel" data-name="Channel Icon" class="channels_checkbox w-checkbox-input checkbox chkbx" [checked]="channel?.is_checked">
                    </label>
                  </div>
                  <div class="w-form-done"></div>
                  <div class="w-form-fail"></div>
                </div>
              </div>
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + channel.image : channel.image }}" alt="" class="tripadvisor ota-icon">
            </div>
          </div>
        </ng-container>
      </div>

      </div>
    </div>
    <div class="modal-footer" id="ModalFooterP" style="    bottom: -50px;right: 10px!important"> <a class="btn btn-primary btn-lg btn-hov" (click)="onSubmit()" [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">{{ 'rates_and_packages.link_to_ota.save' | translate }}</a> <a class="btn btn-danger btn-lg btn-hov" data-dismiss="modal" aria-label="Close" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">{{ 'rates_and_packages.link_to_ota.cancel' | translate }}</a> </div>
  </form>
</div>

