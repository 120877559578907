<div class="jumbotron ">
    <div class="jumbo-icon">
        <li class="notification-hold" id="div6" data-value="Notifications" title="" text="Main title!"
            style="list-style-type: none;">
            <div class="dash_window no_margin w-clearfix dashboard-card dragDash"
                [ngClass]="darkMode ? 'dash_window_dark' : 'dash_window dashboard-card'">
                <div class="win_header" [ngClass]="darkMode ? 'win_header_dark' : 'win_header'" title="">
                    <h4 class="heading" [ngClass]="darkMode ? 'heading_dark' : 'heading'">{{
                        'dashboard.Keynotes' | translate }}
                    </h4>
                </div>
                <div class="scroll no_scroll">
                    <div class="gifDiv" *ngFor="let ev of dashboard?.Event_images " class="hodilay-Class "
                        [ngStyle]="{ 'background-image': 'url(https://pms.pointclickintegrate.com/public/'+ev.image+ ')'} ">
                        <span style="visibility: hidden; "> </span>
                    </div>
                    <div *ngFor="let noti of dashboard?.user_keynotes; let i = index">
                        <div class="keynotes _1 w-clearfix" *ngIf="noti.keynote.is_holiday == 0"
                            [ngClass]="darkMode ? 'notifications_dark' : 'keynotes'"
                            [ngStyle]="{'border-color': color_borders_dark[i%color_borders_dark.length]}">

                            <img src="{{darkMode ? 'assets/img/dashboard/darkmode/bell.svg' : 'assets/img/dashboard/darkmode/bell.svg'}}"
                                alt="" class="keynote_icon">
                            <div class="noti_content_keynote w-clearfix">
                                <p class="keynote_noti_text_morelines"
                                    [ngStyle]="{'color': color_border[i%color_border.length]}"
                                    *ngIf="darkMode == false">
                                    {{noti.keynote.text}}</p>
                                <p class="keynote_noti_text_morelines"
                                    [ngStyle]="{'color': color_border[i%color_border.length]}" *ngIf="darkMode == true">
                                    {{noti.keynote.text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
</div>