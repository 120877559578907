import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, FormArray  , AbstractControl} from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute } from "@angular/router";
declare var $: any;


export interface AbstractFormGroup extends FormGroup {
  controls: {
    [key: string]: AbstractFormGroup & AbstractFormGroup[] & AbstractControl & FormGroup & FormArray,
  }
}
@Component({
  selector: 'app-hotel-setup',
  templateUrl: './hotel-setup.component.html',
  styleUrls: ['./hotel-setup.component.css']
})
export class HotelSetupComponent implements OnInit {

  popular_amenities;
  other_amenities;
  days;
  countries;
  country_md;
  country;
  states;
  state;
  cities;
  city;
  timezones;
  currencies;
  setup_data;
  weekend_starts;
  timezone;
  currency;
  controls;
  hotel_setup_form: FormGroup ;
  submitted=false;
  darkMode: boolean = false;
  amenCol = ['#7ae997','#de888d','#6c6ce7','#da8639','#f1c1f8','#d4575a','#e7d67c','#4cdbbf','#81a2ff'];
  amenColot = ['#5f5fc0','#51f3d6','#e59196','#ce7219','#daca78','#ba585b','#72df8b','#eabdf5'];
  edit:boolean = false;
  hotel_images = [];
  photo_list = [];
  randomColors = [];
  expencePrice:boolean = false;
  Upsellname;
  UpsellPrice;
  Extrasname ;
  Extrasprice ;
  Minisname ;
  MiniPrice ;
  pricingpack;
  taxInclusive;
  taxExclusive;
  property_typee;
  mainarray: any;
  t4 = false;
  t12 = false;
  taxper;
  hrs;


  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public fb: FormBuilder,
    public helper: HelperService,
    public Aroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getproperty();
    this.get_popular_amenities();
    this.get_other_amenities();
    this.get_days();
    this.get_countries();
    this.get_timezones();
    this.get_currencies();
    this. getRandomColors();
    this.user_current();
    this.clockFormat();


    // this.taxper = 120;
    this.hotel_setup_form = this.fb.group({
      name: ['',Validators.required],
      phone: ['',Validators.required],
      email: ['',Validators.required],
      pricingpack:['',Validators.required],
      // taxExclusive:['',Validators.required],
      // taxInclusive:['',Validators.required],
      room_count:['',Validators.required],
      // tax:['',Validators.required],
      address_one:['',Validators.required],
      address_two:'',
      country_id:['',Validators.required],
      state_id:['',Validators.required],
      city_id:['',Validators.required],
      postal_code:['',Validators.required],
      weekend_starts:['',Validators.required],
      timezone_id:['',Validators.required],
      currency_id:['',Validators.required],
      min_rate:['',Validators.required],
      user_id:['',Validators.required],
      taxInclusive:['',Validators.required],
      taxper:['',Validators.required],
      // taxID:['',Validators.required],
      // clockformat:['',Validators.required],
      taxID: "",
      clockformat:'',

      //currency_conversion:['',Validators.required],
      most_amenties:'',
      add_most_amenite:'',
      add_other_amenite:'',
      images: '',
      eta: null,
      etd: null,
      extras:this.fb.array([])
    }) as FormGroup;

    $(function(){
      $("#hSet").mouseup(function(){
        $("#grabingg").css({"cursor":"grab"})
      });
      $("#hSet").mousedown(function(){
        $("#grabingg").css({"cursor":"grabbing"})
      });
    })

  }

  get f() { return this.hotel_setup_form.controls; }

  get_popular_amenities(){
    this.api.post('api/amenitie/index', {order: 'most_popular', type: 'hotel', parent_id: 'Null'}, true).subscribe((res:any) => {
      this.popular_amenities = res.body.data;
      console.log(res.body.data);
      this.popular_amenities.forEach((amenity) => {
        amenity.show_add = false;
      });
    }, err => this.error_handling.handle_error(err.status));
  }

  get_other_amenities(){
    this.api.post('api/amenitie/index', {order: 'other', type: 'hotel', parent_id: 'Null'}, true).subscribe((res:any) => {
      this.other_amenities = res.body.data;
      this.other_amenities.forEach((amenity) => {
        amenity.show_add = false;
      });
    }, err => this.error_handling.handle_error(err.status));
  }

  get_days(){
    this.api.get('api/helper/days', true).subscribe((res:any) => {
      this.days = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_countries(){
    this.states =[];
    this.cities =[];
    this.api.get('api/helper/countries', true).subscribe((res:any) => {
       this.countries = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_states($event){
    this.cities =[];
     var id;
    if(typeof $event=='object') {
      id=$event.id;
    }
    this.api.get('api/helper/states/'+id, true).subscribe((res:any) => {
       this.states = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_cities($event){
    var state_id;
    if(typeof $event=='object') {
      state_id=$event.id;
    }
    this.api.get('api/helper/cities/'+state_id, true).subscribe((res:any) => {
       this.cities = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_timezones(){
    this.api.get('api/helper/timezones', true).subscribe((res:any) => {
      this.timezones = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_currencies(){
    this.api.get('api/helper/currency', true).subscribe((res:any) => {
      this.currencies = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  colorRadio(id,idt){
    var a = document.getElementById(id)
    var b = document.getElementById(idt)
    a.classList.contains('radioByJs') ? a.classList.remove('radioByJs') : a.classList.add('radioByJs')
    b.classList.contains('radioByJs') ? b.classList.remove('radioByJs') : b.classList.add('radioByJs')
  }

  onSubmit(){
    this.submitted = true;
    let id = JSON.parse(localStorage.getItem('user')).id;
    let current_property = localStorage.getItem('current_property');
    this.hotel_setup_form.get('user_id').setValue(id);

    var most_arr =[];
    if(!this.Aroute.snapshot.queryParams['add_new']){
      var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
      Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value, property_id: current_property});});
    }
    this.hotel_setup_form.patchValue({most_amenties: most_arr, images: this.hotel_images});

    console.log(this.hotel_setup_form.value);
    if (this.hotel_setup_form.invalid) {
      return;
    }
    console.log(this.hotel_setup_form.value);

    this.api.post('api/property/store', this.hotel_setup_form.value,true).subscribe((res:any) => {
      // this.sendExpenses()
      localStorage.removeItem('current_currency');
      localStorage.setItem('current_currency', res.body.data.currency);
      if(this.Aroute.snapshot.queryParams['add_new']){
        var most_arr =[];
        var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
        Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value, property_id: res.body.data.id});});
        this.hotel_setup_form.patchValue({most_amenties: most_arr});
        this.api.post('api/property/store', this.hotel_setup_form.value,true).subscribe((res:any) => {
          localStorage.setItem('current_property', res.body.data.id);

          this.helper.alert_success("New Hotel has been setup!");
        }, err => this.error_handling.handle_error(err.status));
      }
      this.helper.alert_success("Changes has been saved !");
    }, err => this.error_handling.handle_error(err.status));

  }

  setchecked(){
    var checked_data = this.setup_data.amenities;
    checked_data.forEach(element => {
      try {
        (<HTMLInputElement>document.getElementById('trash-'+element.amenitie_id)).checked = true;
      } catch (error) {
      }
    });
  }

  uncheck(id){
  var del = (<HTMLInputElement>document.getElementById(id)).value;
  try {
    this.api.get('api/hotel_amenitie/destory/'+del+'/'+this.setup_data.id,true).subscribe((res:any) => {
    }, err => this.error_handling.handle_error(err.status));
  } catch (error) {
  }
  return  (<HTMLInputElement>document.getElementById(id)).checked = false;
  }

  getproperty(){
    if (!this.Aroute.snapshot.queryParams['add_new']) {
      let current_property = localStorage.getItem('current_property');
      this.api.post('api/property/index', {id: current_property},true).subscribe((res:any) => {
        console.log(res.body.data);
        this.setup_data = res.body.data[0];
        this.hotel_setup_form.patchValue(this.setup_data);
        this.country_md = this.setup_data.country_id;
        this.country= this.setup_data.country;
        this.hrs = this.setup_data.clockformat;
          this.clockformating(this.setup_data.clockformat);
        this.city= this.setup_data.city;
        this.pricingpack = this.setup_data.pricingpack;
        this.taxInclusive = this.setup_data.taxInclusive;
        this.state= this.setup_data.state;
        this.weekend_starts=this.setup_data.weekend_starts;
        this.timezone=this.setup_data.timezone;
        this.currency=this.setup_data.currency;
        this.hotel_images = this.setup_data.images;
        localStorage.setItem("current_property", this.setup_data.id);
        this.setchecked();
        this.edit = true;
        this.setup_data.extras.forEach(element => {
          this.addNewExpense(this.hotel_setup_form.controls.extras,element.name,element.price,element.type,element.id)
        });
      }, err => this.error_handling.handle_error(err.status));
    }
  }

  add_amenitie(order, parent_id){
    var type='Hotel';
    var status = 'Enabled';
    var title ='';
    if(order=='other') {
      title=this.hotel_setup_form.value.add_other_amenite;
    }
    else if(order=='most') {
      title=this.hotel_setup_form.value.add_most_amenite;
    }
    var data = {title:title,order:order,status:status,type:type,parent_id:parent_id,property_id:localStorage.getItem('current_property')};
    this.api.post('api/amenitie/store',data,true).subscribe((res:any) => {

        this.get_popular_amenities();
        this.get_other_amenities();


        this.setchecked();

      console.log(res.body.data);
    }, err => this.error_handling.handle_error(err.status));
  }

  clearState(){
    this.state = undefined;
    this.city = undefined;
  }

  clearCity(){
    this.city = undefined;
  }

  async onFileChange(event){
    if(event.target.files && event.target.files[0]){
      for(var i=0; i < event.target.files.length; i++){
        var r = null;
        var f = event.target.files[i];
        var data = new FormData();
        data.append('image', event.target.files[i], event.target.files[i].name);
        await this.api.imgPost('api/helper/upload_image', data, false).subscribe((img_res:any) => {
          this.hotel_images.push({image:img_res.body.data.image});
        }, err => this.error_handling.handle_error(err.status));
        var reader = new FileReader();
        reader.onload = (eve:any) => {
          this.photo_list.push({url: eve.target.result, file: f});
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  remove_photo(index){
    if(this.edit){
      try {
        this.api.get('api/properties_gallery/destory/'+this.hotel_images[index].id,true).subscribe((res:any) => {
          this.hotel_images.splice(index, 1);
        }, err => this.error_handling.handle_error(err.status));
      } catch (error) {
      }
    }
    this.photo_list.splice(index, 1);
  }

  chngeDrop(id){
    let dropdown = (<HTMLIFrameElement>document.getElementById(id));
    dropdown.classList.contains('rotate180') ? dropdown.classList.remove('rotate180') : dropdown.classList.add('rotate180')
  }

  getRandomColors(){
    for(let i=0; i <=100 ;i++){
     this.randomColors.push(`#${Math.floor(Math.random()*16777215).toString(16)}`)
    }
  }

  showRateInp(data){
    //console.log(data)
    data == 'price' ? (this.expencePrice = true, this.UpsellPrice = null) : this.expencePrice = false
  }

  // async sendExpenses(name,price,type){
  //   let a = {
  //     "extras": [
  //         {
  //             name: name,
  //             price: price,
  //             property_id: localStorage.getItem('current_property'),
  //             type: type
  //         }
  //     ]
  //   }
  //   await this.api.post('api/property/extras',a,true).subscribe((res:any)=>{
  //     this.nullTheObj();
  //     this.helper.alert_success(`Successfully added in ${type}`)
  //   })
  // }

  // async removeExpence(data){
  //   await this.api.post('api/property/delete_extras/'+data.id,{},true).subscribe((res:any)=>{
  //    this.nullTheObj();
  //    this.helper.alert_success(`${data.type} ${data.name} successfully deleted.`)
  //   },err => this.error_handling.handle_error(err))
  //   console.log(data)
  // }

  async sendExpenses(){
    if(this.hotel_setup_form.value.extras.length > 0){
    let a = {'extras':[]}
    this.hotel_setup_form.value.extras.map((val)=>{
      a.extras.push(val)
    })
     await this.api.post('api/property/extras',a,true).subscribe((res:any)=>{})
  }
   }

  async removeExpence(data,i){
    let langArr = <FormArray>this.hotel_setup_form.controls["extras"];
    langArr.removeAt(i)
    if(data.value.id != null){
      await this.api.post('api/property/delete_extras/'+data.value.id,{},true).subscribe((res:any)=>{
        this.helper.alert_success(`${data.value.type} ${data.value.name} successfully deleted.`)
       },err => this.error_handling.handle_error(err))
    }
    // console.log(data)
   }

   user_current() {
    let user_current = JSON.parse(localStorage.getItem('user'));
    this.api.get('api/user/show/' + user_current.id, true).subscribe((res: any) => {
      this.property_typee = res.body.data.property_type;

    }, err => this.error_handling.handle_error(err.status));
  }


  addNewExpense(controls,name,price,type,id){

    controls.push(
      this.fb.group({
        name: name,
        price: price,
        property_id: localStorage.getItem('current_property'),
        type: type,
        id:id
      })
    );
    this.Upsellname = null;
    this.UpsellPrice = null;
    this.Extrasname = null;
    this.Extrasprice = null;
    this.Minisname = null;
    this.MiniPrice = null;
   // console.log(controls)
   // console.log(controls.value)
  }

  chkForm(data,i,type){
    if(data.value.type == type){
      return true
    }
    else{
      return false
    }
  }


  hideModal()
  {
    let modal = document.getElementById('settings')
      modal.classList.remove('show');
      modal.classList.add('hide');
  }

  clockFormat() {
    var ar24 = [
      "00 : 00",
      "00 : 30",
      "01 : 00",
      "01 : 30",
      "02 : 00",
      "02 : 30",
      "03 : 00",
      "03 : 30",
      "04 : 00",
      "04 : 30",
      "05 : 00",
      "05 : 30",
      "06 : 00",
      "06 : 30",
      "07 : 00",
      "07 : 30",
      "08 : 00",
      "08 : 30",
      "09 : 00",
      "09 : 30",
      "10 : 00",
      "10 : 30",
      "11 : 00",
      "11 : 30",
      "12 : 00",
      "12 : 30",
      "13 : 00",
      "13 : 30",
      "14 : 00",
      "14 : 30",
      "15 : 00",
      "15 : 30",
      "16 : 00",
      "16 : 30",
      "17 : 00",
      "17 : 30",
      "18 : 00",
      "18 : 30",
      "19 : 00",
      "19 : 30",
      "20 : 00",
      "20 : 30",
      "21 : 00",
      "21 : 30",
      "22 : 00",
      "22 : 30",
      "23 : 00",
      "23 : 30",
    ];

    var ar12 = [
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
    ];
    this.mainarray = ar24;
  }
  clockformating(a: any) {
    if (a == "24hr") {
      this.t4 = true;
      this.t12 = false;

    }
    if (a == "12hr") {
      this.t4 = false;
      this.t12 = true;

    }
  }
}
