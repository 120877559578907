
<div class="" id="CRoom" style="padding-right: 5px;">

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a  class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="hideModal()">{{
      "back_office.create_rooms.back_office" | translate
    }}</a>
    <a  class="btn btn-link active">{{
      "back_office.create_rooms.create_rooms" | translate
    }}</a>
  </div>

  <form class="form-horizontal back-form scroll no_scroll"[formGroup]="create_room_form" (ngSubmit)="onSubmit()">
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="row field-space">
            <div class="col-md-4 grid_20">

              <div class="border-style b-1 roomtype-shade">
                <div>
                  <ng-select [items]="roomtypes" bindLabel="name" bindValue="id" formControlName="room_type_id" placeholder='{{"back_office.create_rooms.Select_Room_Type" | translate}}' [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                </div>
                <span class="info1"><i class="fas fa-info-circle i" style="font-size: 15px;"
                  [ngClass]="darkMode ? 'i_dark' : 'i'">
                  <span class="tooltiptext3" [ngClass]="darkMode ? 'tool_dark' : 'tooltiptext3'" style="width: 425px;">
                    <p class="small   no-margin" style=" padding: 0px !important; margin: 0px !important;">
                      {{"back_office.create_rooms.Select_Room_Type_i" | translate}}

                    </p>
                  </span>
                </i>
              </span>
              </div>

              <div *ngIf="submitted && f.room_type_id.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.room_type_id.errors.required">{{"back_office.create_rooms.room_type_is_required" | translate}}</strong>
              </div>
            </div>

            <div class="col-md-4 grid_20">

              <div>
                <div class="border-style roomsize-shade2" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="width: 100%; display: inline-block;margin-right: 5px;border-color: #c7a563;">
                  <input type="text" class="form-control"  placeholder="{{'back_office.create_rooms.room_count' | translate}}" formControlName="room_count"/>
                </div>
                <div *ngIf="submitted && f.room_count.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.room_count.errors.required">{{"back_office.create_rooms.room_count_is_required" | translate}}</strong>
                </div>
              </div>
            </div>

            <div class="col-md-4 grid_20">
              <div>
                <div class="border-style roomsize-shade2"  style="width: 100%; display: inline-block;float: right;">
                  <ng-select [items]="roomclass" bindLabel="name" bindValue="id" formControlName="room_type_class" placeholder='{{ "back_office.create_rooms.Select_Room_Class" | translate }}'  [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
                </div>
                <div *ngIf="submitted && f.room_type_class.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.room_type_class.errors.required">Room Class is required</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="row field-space">
            <div class="col-md-4 grid_20">
              <div class="border-style b-1 roomtype-shade2" >
                <div>
                  <ng-select [items]="roomView" bindLabel="name" bindValue="id" formControlName="room_view_id" placeholder='{{ "back_office.create_rooms.Select_Room_View" | translate }}' [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                </div>
              </div>
              <div *ngIf="submitted && f.room_view_id.errors" class="small text-danger no-margin invalid-feedback" >
                <strong *ngIf="f.room_view_id.errors.required">Room View is required</strong>
              </div>
            </div>

            <div class="col-md-4 grid_20">
              <div >
                <div class="border-style roomsize-shade3" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="width: 100%; display: inline-block;margin-right: 5px;">
                  <input type="text" class="form-control" placeholder="{{'back_office.create_rooms.room_size' | translate}}" formControlName="room_size"/>
                </div>
                <div *ngIf="submitted && f.room_size.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.room_size.errors.required">
                    {{"back_office.create_rooms.room_size_is_required" | translate }}
                  </strong>
                </div>
              </div>

            </div>
            <div class="col-md-4 grid_20">
              <div>
                <div class="border-style roomsize-shade23">
                  <ng-select  placeholder='{{"back_office.create_rooms.select_room_measurement" | translate }}' formControlName="room_size_type" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                    <ng-option value="meters">{{"back_office.create_rooms.select_room_measurement_sqM" | translate }}</ng-option>
                    <ng-option value="square_feet">{{"back_office.create_rooms.select_room_measurement_sqF" | translate }}</ng-option>
                  </ng-select>
                </div>
                <div *ngIf="submitted && f.room_size_type.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.room_size_type.errors.required">
                    {{"back_office.create_rooms.room_size_is_required" | translate }}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="row field-space">

            <div class="col-md-3 grid_20">
              <div class="border-style bedType-shade">
                <ng-select [items]="bedtypes" bindLabel="name" bindValue="id" formControlName="bed_type_id" placeholder="{{'back_office.create_rooms.select_bed_type' | translate}}" [(ngModel)]="bedtype" (change)="bedChange($event, bedtype)" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
              </div>
              <div *ngIf="submitted && f.bed_type_id.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.bed_type_id.errors.required">{{"back_office.create_rooms.bed_type_is_required" | translate}}</strong>
              </div>
            </div>

            <div class="col-md-3 grid_20">
              <div>
                <div class="border-style no-of-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="width: 100%; display: inline-block;margin-right: 5px;">
                  <input type="text"  class="form-control" placeholder="{{'back_office.create_rooms.no_of_beds' | translate}}" formControlName="no_of_beds"/>
                </div>
                <div *ngIf="submitted && f.no_of_beds.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.no_of_beds.errors.required">{{"back_office.create_rooms.no_of_beds_is_required" | translate}}</strong>
                </div>
              </div>
            </div>

            <div class="col-md-3 grid_20">
              <div>
                <div class="border-style roomsize-shade2"  style="width: 100%; display: inline-block;float: right;border-color:#057071">
                  <ng-select [items]="bedSize" bindLabel="name" bindValue="name" formControlName="bedsize"  placeholder='{{"back_office.create_rooms.select_bed_size" | translate}}' [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                </div>
                <div *ngIf="submitted && f.bedsize.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.bedsize.errors.required">{{"back_office.create_rooms.bed_type_is_required" | translate}}</strong>
                </div>
              </div>
            </div>

            <div class="col-md-3 grid_20">
              <div style="float: right;width: 100%;">
                <div class="border-style roomsize-shade" style="width: 100%; display: inline-block;margin-right: 5px;border-color: #03A9F4;">
                  <input type="text" class="form-control" formControlName="display_name" placeholder="Room Display Name" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">

            <div class="col-md-6 grid_20">

              <div class="border-style b-1 roomtype-shade" style="border-color:#34d640;">

                  <ng-select [items]="addInclu" bindLabel="name" class="kuchbhi" bindValue="id" formControlName="value_add_inclusion" placeholder='{{"back_office.create_rooms.Select_Value_Added_Inclusion" | translate}}' [multiple]="true" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                </div>

                <div *ngIf="submitted && f.value_add_inclusion.errors" class="small text-danger no-margin invalid-feedback" >
                  <strong *ngIf="f.value_add_inclusion.errors.required"></strong>
                </div>
            </div>

            <div class="col-md-6 grid_20">
              <div class="row iconst">
                <div class="col-md-12">
                  <div class="col-md-4 zero-padding" style="height: 40px;">
                    <div class="smoker hovimg" style="width: 100%;height: 44px;" *ngIf="smoke == true" (click)="changeSmoke()">
                        <label class="control-label radio-style">
                          <div style="width: 40%;float: left;margin-top: -3px;">
                            <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/smoke.svg" style="height:55px !important;">
                          </div>
                          <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                            <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.smoking_room" | translate}}</span>
                          </div>
                        </label>
                    </div>
                    <div class="smoker hovimg" style="width: 100%; height: 44px;" *ngIf="smoke == false" (click)="changeSmoke()">
                      <label class="control-label radio-style">
                        <div style="width: 40%;float: left;margin-top: -3px;">
                          <img class="img-responsive " src="assets/img/newstaticicons/back-office/nosmoke.svg" style="height:55px !important;">
                        </div>
                        <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                          <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.non_smoking" | translate}}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4 zero-padding" style="height: 40px;">
                    <div class="smoker hovimg" style="width: 100%;height: 44px;" *ngIf="pet == true" (click)="changePet()">
                      <label class="control-label radio-style">
                        <div style="width: 40%;float: left;margin-top: -3px">
                          <img class="img-responsive" src="assets/img/newstaticicons/back-office/pets.svg" style="height:55px !important;">
                        </div>
                        <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                          <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.Pets_Allowed" | translate}}</span>
                        </div>
                      </label>
                    </div>
                    <div class="smoker hovimg" style="width: 100%; height: 44px;" *ngIf="pet == false" (click)="changePet()">
                      <label class="control-label radio-style">
                        <div style="width: 40%;float: left;margin-top: -3px">
                          <img class="img-responsive" src="assets/img/newstaticicons/back-office/nopets.svg" style="height:55px !important;">
                        </div>
                        <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                          <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.Pets_not_Allowed" | translate}}</span>
                        </div>
                      </label>
                    </div>

                  </div>
                  <div class="col-md-4 zero-padding" style="height: 40px;">
                    <div class="smoker hovimg" style="width: 100%;height: 44px;" *ngIf="wheelT == true" (click)="changeWheel()">
                      <label class="control-label radio-style">
                        <div style="width: 40%;float: left;margin-top: -3px;">
                          <img class="img-responsive" src="assets/img/newstaticicons/back-office/wheel.svg" style="height:55px !important;">
                        </div>
                        <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                          <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.available" | translate}}</span>
                        </div>
                      </label>
                    </div>
                    <div class="smoker hovimg" style="width: 100%;height: 44px;" *ngIf="wheelT == false" (click)="changeWheel()">
                      <label class="control-label radio-style">
                        <div style="width: 40%;float: left;margin-top: -3px;">
                          <img class="img-responsive" src="assets/img/newstaticicons/back-office/nowheel.svg" style="height:55px !important;">
                        </div>
                        <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                          <span class="hov" style="font-size: 12px; font-weight: bold;">{{"back_office.create_rooms.un_available" | translate}}</span>
                        </div>
                      </label>
                    </div>
                  </div>
               </div>
             </div>
            </div>
        </div>
      </div>
    </div>


    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="field-space">
            <div class="smoker" style="width: 100%;">
              <div>
                <details [attr.open]="extraBed ? 'open' : null">
                  <summary (click)="selectExtraBed()">
                    <div style="display: flex; line-height: 40px;border-color: #e91e63; " class="border-style b-1 roomtype-shade yesno " id="customRadio" [ngClass]="darkMode ? 'dark-drop-2' : ' null '">
                      <div class="colpdrp" style="width: 98%;text-indent: 10px; color: #00205b; font-weight: bold;" >
                        {{"back_office.create_rooms.Add_extra's_to_this_room" | translate}}
                      </div>

                      <span style="float: right;">
                        <img src="assets/img/reports/button_down.svg" style="width: 13px; cursor: pointer;" *ngIf="darkMode == false">
                        <img src="assets/img/reports/button_down_dark.svg" style="width: 13px; cursor: pointer;" *ngIf="darkMode == true">
                      </span>

                    </div>
                  </summary>

                  <div>
                    <div style="margin-top: -10px">
                      <br>
                      <div style="display: flex; text-align: center;" >
                        <div style="width: 20%; margin-right: 5px;border-color: #8474ff;" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" class="border-style b-1 roomtype-shade">
                          <input type="text" class="form-control" value='{{"back_office.create_rooms.Extra_Person_|_Child" | translate}} ' readonly>
                        </div>
                        <!--  -->
                        <!-- <div style="width: 20%; margin-right: 5px;border-color: #4edcbc;" class="border-style b-1 roomtype-shade">
                          <ng-select placeholder="How Many?" [(ngModel)]="cQuantity" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="1">1</ng-option>
                            <ng-option value="2">2</ng-option>
                            <ng-option value="3">3</ng-option>
                            <ng-option value="4">4</ng-option>
                            <ng-option value="5">5</ng-option>
                            <ng-option value="6">6</ng-option>
                            <ng-option value="7">7</ng-option>
                            <ng-option value="8">8</ng-option>
                            <ng-option value="9">9</ng-option>
                            <ng-option value="10">10</ng-option>
                            <ng-option value="11">11</ng-option>
                            <ng-option value="12">12</ng-option>
                            <ng-option value="13">13</ng-option>
                            <ng-option value="14">14</ng-option>
                            <ng-option value="15">15</ng-option>
                            <ng-option value="16">16</ng-option>
                            <ng-option value="17">17</ng-option>
                            <ng-option value="18">18</ng-option>
                            <ng-option value="19">19</ng-option>
                            <ng-option value="20">20</ng-option>
                          </ng-select>
                        </div> -->

                        <div style="width: 40.4%; margin-right: 5px;border-color: #c7530d;" class="border-style b-1 roomtype-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" >
                          <input type="text" class="form-control" placeholder='{{"back_office.create_rooms.Amount" | translate}}' [(ngModel)]="pAmount" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'" />
                        </div>

                      </div>
                    </div>

                    <div style="margin-top: -10px">
                      <br>
                      <div style="display: flex; text-align: center;" >
                        <div style="width: 20%; margin-right: 5px;border-color: #8474ff;" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" class="border-style b-1 roomtype-shade">
                          <input type="text" class="form-control" value='{{"back_office.create_rooms.Cribs_|_Infant beds" | translate}}' readonly>
                        </div>
                        <div style="width: 20%; margin-right: 5px;border-color: #4edcbc;" class="border-style b-1 roomtype-shade">
                          <ng-select placeholder='{{"back_office.create_rooms.How_Many?" | translate}}' [(ngModel)]="cQuantity" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">

                            <ng-option value="1">1</ng-option>
                            <ng-option value="2">2</ng-option>
                            <ng-option value="3">3</ng-option>
                            <ng-option value="4">4</ng-option>
                            <ng-option value="5">5</ng-option>
                            <ng-option value="6">6</ng-option>
                            <ng-option value="7">7</ng-option>
                            <ng-option value="8">8</ng-option>
                            <ng-option value="9">9</ng-option>
                            <ng-option value="10">10</ng-option>
                            <ng-option value="11">11</ng-option>
                            <ng-option value="12">12</ng-option>
                            <ng-option value="13">13</ng-option>
                            <ng-option value="14">14</ng-option>
                            <ng-option value="15">15</ng-option>
                            <ng-option value="16">16</ng-option>
                            <ng-option value="17">17</ng-option>
                            <ng-option value="18">18</ng-option>
                            <ng-option value="19">19</ng-option>
                            <ng-option value="20">20</ng-option>
                          </ng-select>
                        </div>
                        <div style="width: 20%; margin-right: 5px;border-color: #2f8e3f;" class="border-style b-1 roomtype-shade">
                          <ng-select placeholder='{{"back_office.create_rooms.Cost?" | translate}}' [(ngModel)]="cSurcharge_type" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="Free">{{"back_office.create_rooms.free" | translate}}</ng-option>
                            <ng-option value="Surcharge">{{"back_office.create_rooms.subcharges" | translate}}</ng-option>
                          </ng-select>
                        </div>
                        <div style="width: 20%; margin-right: 5px;border-color: #c7530d;" class="border-style b-1 roomtype-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" *ngIf="cSurcharge_type == 'Surcharge'">
                          <input type="text" class="form-control" placeholder='{{"back_office.create_rooms.Amount" | translate}}' [(ngModel)]="cAmount" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'" />
                        </div>
                        <div style="width: 20%;border-color: #f5e732;" class="border-style b-1 roomtype-shade" *ngIf="cSurcharge_type == 'Surcharge'">
                          <ng-select placeholder="Select Type" [(ngModel)]="cSurcharge_amount_type" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="per day">Per Day</ng-option>
                            <ng-option value="per night">Per Night</ng-option>
                            <ng-option value="per stay">Per Stay</ng-option>
                            <ng-option value="per week">Per Week</ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div style="margin-top: -10px;">
                      <br>
                      <div style="display: flex; text-align: center;">
                        <div style="width: 20%; margin-right: 5px;border-color: #819a65;" class="border-style b-1 roomtype-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                          <input type="text" class="form-control" value= '{{"back_office.create_rooms.Rollaway_|_Extra Beds" | translate}}' readonly>
                        </div>

                        <div style="width: 20%; margin-right: 5px;border-color: #b94747;" class="border-style b-1 roomtype-shade">
                          <ng-select placeholder='{{"back_office.create_rooms.How_Many?" | translate}}' [(ngModel)]="rQuantity" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="1">1</ng-option>
                            <ng-option value="2">2</ng-option>
                            <ng-option value="3">3</ng-option>
                            <ng-option value="4">4</ng-option>
                            <ng-option value="5">5</ng-option>
                            <ng-option value="6">6</ng-option>
                            <ng-option value="7">7</ng-option>
                            <ng-option value="8">8</ng-option>
                            <ng-option value="9">9</ng-option>
                            <ng-option value="10">10</ng-option>
                            <ng-option value="11">11</ng-option>
                            <ng-option value="12">12</ng-option>
                            <ng-option value="13">13</ng-option>
                            <ng-option value="14">14</ng-option>
                            <ng-option value="15">15</ng-option>
                            <ng-option value="16">16</ng-option>
                            <ng-option value="17">17</ng-option>
                            <ng-option value="18">18</ng-option>
                            <ng-option value="19">19</ng-option>
                            <ng-option value="20">20</ng-option>
                          </ng-select>
                        </div>
                        <div style="width: 20%; margin-right: 5px;border-color: cadetblue;" class="border-style b-1 roomtype-shade">
                          <ng-select placeholder='{{"back_office.create_rooms.Cost?" | translate}}' [(ngModel)]="rSurcharge_type" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="Free">{{"back_office.create_rooms.free" | translate}}</ng-option>
                            <ng-option value="Surcharge">{{"back_office.create_rooms.subcharges" | translate}}</ng-option>
                          </ng-select>
                        </div>
                        <div style="width: 20%; margin-right: 5px;border-color: darkmagenta;" class="border-style b-1 roomtype-shade" *ngIf="rSurcharge_type == 'Surcharge'" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                          <input type="text" class="form-control" placeholder='{{"back_office.create_rooms.Amount" | translate}}' [(ngModel)]="rAmount" [ngModelOptions]="{standalone: true}"/>
                        </div>
                        <div style="width: 20%;border-color: sandybrown;" class="border-style b-1 roomtype-shade" *ngIf="rSurcharge_type == 'Surcharge'">
                          <ng-select placeholder="Select Type" [(ngModel)]="rSurcharge_amount_type" [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                            <ng-option value="per day">Per Day</ng-option>
                            <ng-option value="per night">Per Night</ng-option>
                            <ng-option value="per stay">Per Stay</ng-option>
                            <ng-option value="per week">Per Week</ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <!-- Age Section -->
    <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
      <div class="">
        <h4>{{"back_office.create_rooms.ages" | translate}}</h4>
      </div>
    </div>
    <div class="card"  [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="row field-space">
            <div class="col-md-4 grid_20">
              <div class="incremental">
                <div class="border-style adults-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="border-color: #057071">
                  <div class="input-group" >
                    <input type="number" class="form-control adult" name="sleeps_adult" placeholder="{{'back_office.create_rooms.adults' | translate}}" formControlName="adults_age" min="0" id="age_adult" [(ngModel)]="age_adult"/>
                    <div class="input-group-addon addon-border adult-plus dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('+', 'age_adult')" ><i class="fa fa-plus adult-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('-', 'age_adult')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.adults_age.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.adults_age.errors.required">Adult Age is required</strong>
                </div>
              </div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="incremental">
                <div class="border-style children-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="border-color: #ff6f00;">
                  <div class="input-group">
                    <input type="number" class="form-control" name="sleeps_children" placeholder="{{'back_office.create_rooms.children' | translate }}" formControlName="children_age" id="age_children" [(ngModel)]="age_children"/>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('+', 'age_children')"><i class="fa fa-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('-', 'age_children')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.children_age.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.children_age.errors.required">Children Age is required</strong>
              </div>
              </div>
            </div>
            <div class="col-md-4 grid_20" >
              <div class="incremental">
                <div class="border-style infants-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'" style="border-color: #e651ff;">
                  <div class="input-group">
                    <input type="number" class="form-control" name="sleeps_infants" placeholder="{{'back_office.create_rooms.infants' | translate}}" formControlName="infants_age" id="age_infants" [(ngModel)]="age_infants"/>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('+', 'age_infants')"><i class="fa fa-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculateAge('-', 'age_infants')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.infants_age.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.infants_age.errors.required"> Infants Age is required</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Age Section End-->

    <!-- Sleep Section -->
    <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
      <div class="">
        <h4>{{ "back_office.create_rooms.sleeps" | translate }}</h4>
      </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'">
      <div class="row">
        <div class="col-md-12">
          <div class="row field-space">
            <div class="col-md-4 grid_20">
              <div class="incremental">
                <div class="border-style adults-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                  <div class="input-group">
                    <input type="number" class="form-control adult" name="sleeps_adult" placeholder="{{'back_office.create_rooms.adults' | translate}}" formControlName="adults" min="0" id="sleeps_adult" [(ngModel)]="sleeps_adult"/>
                    <div class="input-group-addon addon-border adult-plus dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('+', 'sleeps_adult')"><i class="fa fa-plus adult-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('-', 'sleeps_adult')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.adults.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.adults.errors.required"> Adult count are required</strong>
                </div>
              </div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="incremental">
                <div class="border-style children-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                  <div class="input-group">
                    <input type="number" class="form-control" name="sleeps_children" placeholder="{{ 'back_office.create_rooms.children' | translate}}" formControlName="children" id="sleeps_children" [(ngModel)]="sleeps_children" />
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('+', 'sleeps_children')"><i class="fa fa-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('-', 'sleeps_children')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.children.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.children.errors.required"> Children count are required</strong>
              </div>
              </div>
            </div>
            <div class="col-md-4 grid_20">
              <div class="incremental">
                <div class="border-style infants-shade" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                  <div class="input-group">
                    <input type="number" class="form-control" name="sleeps_infants" placeholder="{{'back_office.create_rooms.infants' | translate}}" formControlName="infants" id="sleeps_infants" [(ngModel)]="sleeps_infants"/>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('+', 'sleeps_infants')"><i class="fa fa-plus"></i></a>
                    </div>
                    <div class="input-group-addon addon-border dc" [ngClass]="darkMode ? 'hovYellow' : 'null'">
                      <a class="btn" (click)="calculate('-', 'sleeps_infants')"><i class="fa fa-minus"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && f.infants.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.infants.errors.required"> Infants count are required</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sleep Section -->

    <!-- Description -->
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'cardio'">
      <div class="row">
        <div class="col-md-12">
          <div class="border-style description-shade">
            <textarea name="" class="form-control txtdes" id="" cols="8" rows="4" placeholder="{{'back_office.create_rooms.description' | translate}}" formControlName="description" ></textarea>
          </div>
          <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback">
            <strong *ngIf="f.description.errors.required">{{ "back_office.create_rooms.description" | translate }}</strong>
          </div>
        </div>
      </div>
    </div>
    <!-- Description -->

    <!-- Photos -->
    <div class="card" [ngClass]="darkMode ? 'card-dark' : 'card'" style="padding-bottom: 0px !important;padding-left: 15px!important">
      <div class="row">
        <div class="col-md-12">
          <div class="row photo-box-wrapper">
            <div class="col-md-12" style="padding-right: 20px;">
              <div class="col-md-3 uploadcustom" *ngFor="let img of photo_list; let i = index" >
                <div class="photo-box">
                  <a class="" (click)="remove_photo(i)">
                    <img width="25px" src="assets/img/manage-rooms/delete.png" />
                  </a>
                  <span *ngIf="i == 1" class="pull-left main">{{"back_office.create_rooms.main" | translate}}</span>
                  <img  src="{{ img.url }}" width="100%" height="128px" />
                </div>
              </div>
              <div class="col-md-3" style="padding-right: 0px;width: 210px;" [ngClass]="darkMode ? 'adulDark' : 'adulLight'">
                <label class="btn btn-primary photo-style hov hovYY" style="margin-bottom: 5px;" >
                  <input #photo_input type="file" name="photo" class="form-control" (change)="onFileChange($event)" multiple="true" style="display: none;" />

                  <!-- <img src="assets/img/create-rooms/Asset_10.png" alt=""> -->
                  <div class="pluschnge hovY"><img src="assets/img/newstaticicons/back-office/add-image.svg" alt="" style="visibility: hidden;"/></div>
                  <br />
                  <p style="margin-top: 0px !important;">
                    {{ "back_office.create_rooms.add_more_photos" | translate }}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="row field-space photo-box-wrapper">
            <div class="col-md-12">
              <div class="col-md-3" style="padding-right: 0px; width: 15%;">
                <label class="btn btn-primary photo-style hov">
                  <input #photo_input type="file" name="photo" class="form-control" (change)="onFileChange($event)" multiple="true" />


                  <img src="assets/img/newstaticicons/back-office/add-image.svg" alt=""/>
                  <br />
                  <p style="margin-top: 23px !important;">
                    {{ "back_office.create_rooms.add_more_photos" | translate }}
                  </p>
                </label>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Photos -->

    <div class="gradient-bar" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
      <div class="">
        <h4>{{ "back_office.create_rooms.room_amenities" | translate }}</h4>
      </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card-ami' : 'card'">
      <div class="room_amenities">
        <div *ngFor="let amenity of amenities; let i = index">
          <!-- <p><b>{{ amenities[1+1].title }}</b></p> -->
          <div class="row" *ngIf="i % 4 == 0 || i == 0">
            <div *ngFor="let a of [0, 1, 2, 3]; let parI = index">
              <div
                class="col-md-3 amenity"
                *ngIf="amenities[i + a] && amenities[i + a].children[0]" >
                <img class="AmiIcons"  src="assets/img/create-rooms/{{ amenities[i + a].icon }}" *ngIf="darkMode == false ">
                <img class="AmiIcons"  src="assets/img/create-rooms/darkmode/{{  amenities[i+a].icon }}" *ngIf="darkMode == true ">
                <img src="" />
                <!-- <p><b>{{ amenities[i+a].title }}</b></p> -->
                <p>
                  <b id="{{'idd'+parI}}" [ngStyle]="{'color': darkMode ? amenCol[i+a] : '#00205b'}" class="amiTitle">{{ amenities[i + a].title }}</b>
                </p>
                <ul>
                  <li *ngFor="let childs of amenities[i + a].children">
                    <label class="control-label checkbox-style" [ngClass]="darkMode ? 'titleHove' : 'titleHoveDark'">
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="trash-{{ childs.id }}"
                        name="most_amenties[]"
                        value="{{ childs.id }}"
                      />
                      <span class="checkbox-checkmark" [ngClass]= "darkMode ? 'dark-checkmark' : 'null'"></span>
                      <span class="title " [ngClass]= "darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                    </label>
                    <a
                      href="javascript:void(0)"
                      (click)="uncheck('trash-' + childs.id)"
                      class="pull-right"
                      >
                      <!-- <img class="" src="assets/img/hotel-setup/trash.png" /> -->
                    </a>
                  </li>
                </ul>
                <hr
                  class="bottom-line"
                  *ngIf="amenities[i + a].show_add == false"
                />
                <a
                  class="btn add-new"
                  *ngIf="amenities[i + a].show_add == false"
                  (click)="amenities[i + a].show_add = true"
                  >+ {{ "back_office.create_rooms.add_new" | translate }}</a
                >
                <div
                  class="add-new-box"
                  *ngIf="amenities[i + a].show_add == true"
                >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="add_most_amenite"
                  />
                  <a
                    class="btn btn-xs pull-right"
                    (click)="amenities[i + a].show_add = false"
                  >
                    <img src="assets/img/hotel-setup/wrong.png" />
                  </a>
                  <a
                    class="btn btn-xs pull-right"
                    href="javascript:void(0)"
                    (click)="add_amenitie('other', amenities[i + a].id)"
                    ><img src="assets/img/hotel-setup/right.png"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mymodal0" [ngClass]="darkMode ? 'dark-btn' : 'null'">
      <!-- <button type="submit" class="btn btn-primary btn-lg save">{{ 'back_office.create_rooms.save' | translate }}</button> -->
      <button type="submit" class="btn btn-primary btn-lg save hov" *ngIf="prevSave == undefined"> Preview </button>
      <button type="submit" class="btn btn-primary btn-lg save" *ngIf="prevSave != undefined"> Save </button>
      <a
      (click)="hideModal()"
        class="btn btn-danger btn-lg hov"
        data-dismiss="modal"
        aria-label="Close"
        >{{ "back_office.create_rooms.close" | translate }}</a
      >
    </div>
  </form>
</div>

