import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-online-help',
  templateUrl: './online-help.component.html',
  styleUrls: ['./online-help.component.css']
})
export class OnlineHelpComponent implements OnInit {

  constructor() { }
  language = 'English';
  dropDown;
  windowScrolled:any;
  scrollFunction:any;
  darkMode: boolean = false;
  ngOnInit() {
    this.checkDarkMode();


    // $('.panel-collapse').on('show.bs.collapse', function () {
    //   $(this).siblings('.panel-heading').addClass('active');
    // });

    // $('.panel-collapse').on('hide.bs.collapse', function () {
    //   $(this).siblings('.panel-heading').removeClass('active');
    // });

  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }
  changeDrop(){
    this.dropDown = !this.dropDown
  }

  chngedrop(id){
   let a = (<HTMLInputElement>document.getElementById(id))
 if(a.classList.contains('trans180')){
  a.classList.remove('trans180')
 }
 else{
  a.classList.add('trans180')
 }
  }

}
