<div class="filter-box" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
    <form class="form-inline">
        <div class="row no-gutters">
            <div class="col-md-12">
                <div class="col-md-8">
                    <!-- <h1 class="page-title">{{ 'back_office.log.change_log' | translate }}</h1> -->

                    <div class="alert alert-danger breadcrum" style="margin-bottom: 5px;" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
                        <ul class="nav navbar-nav" style="padding-left: 0px;">
                            <li class="active">
                                <a data-toggle="tab" href="#changeLog" (click)="change_log(true)" class="btn btn-link active"><span>{{ 'back_office.log.change_log'
                    | translate }}</span></a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#errorLog" (click)="change_log(false)" class="btn btn-link"><span>{{ 'back_office.log.OTA_error' |translate }}</span></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-1 filters_col" *ngIf="changelog == true">
                    <ng-select [(ngModel)]="filter.table_name" name="byCategory" bindLabel="name" bindValue="id" class="form-control input_2 fntsz" placeholder="{{ 'back_office.log.by_category' | translate }}" (change)="set_filter()">
                        <ng-option value="reservations,reservation_payments,bookings,guests,invoices">
                            {{'back_office.log.reservation' | translate }}</ng-option>
                        <!-- <ng-option value="">{{ 'back_office.log.maintenance' | translate }}</ng-option> -->
                        <!-- <ng-option value="">{{ 'back_office.log.housekeeping' | translate }}</ng-option> -->

                        <ng-option value="packages">Packages</ng-option>
                        <ng-option value="ratePlan,season_packages">RatePlan</ng-option>
                        <ng-option value="roomtype,season_room_types">Room types</ng-option>
                        <ng-option value="Restriction">Restriction</ng-option>



                        <ng-option value="rooms,room_galleries,room_types">{{ 'back_office.log.rooms' | translate }}</ng-option>
                        <!-- <ng-option value="">{{ 'back_office.log.vendor_and_supplier' | translate }}</ng-option> -->
                        <ng-option value="users,user_keynotes,user_notifications,user_security_questions,role_user">{{ 'back_office.log.user' | translate }}</ng-option>
                        <ng-option value="room_amenities,hotel_amenities,amenities">{{ 'back_office.log.amenities' | translate }}
                        </ng-option>
                        <ng-option value="subscriptions,subscription_plans">{{ 'back_office.log.subscription' | translate }}
                        </ng-option>
                        <ng-option value="room_rates,channels,season_allocations">{{ 'back_office.log.rate_changes' | translate }}</ng-option>
                        <ng-option value="users">{{ 'back_office.log.user_login' | translate }}</ng-option>
                        <ng-option value="notifications,user_notifications">{{ 'back_office.log.notification' | translate }}
                        </ng-option>
                    </ng-select>
                </div>

                <div class="col-md-1 filters_col" *ngIf="changelog == false">
                    <ng-select class="form-control input_2 fntsz" placeholder="OTA's & Channels" (change)="set_filter()">
                        <ng-option value="">
                            <img src="assets/img/svgicons/otas/expedia.svg" alt="" class="image-137 pr-5 pl-5" style="width: 100%;height: 70px;" />
                        </ng-option>

                    </ng-select>
                </div>


                <!-- <div *ngFor="let ota of channels">
        <div *ngIf="ota.type == 'External'">
          <span class="con-chan">
            <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota?.image : ota?.image}}" alt=""
              class="image-137 pr-5 pl-5" style="width: 96%;" />
          </span>
        </div>
      </div> -->

                <div class="col-md-1 filters_col">
                    <ng-select [(ngModel)]="filter.userId" name="byUser" class="form-control input_2 fntsz" placeholder="{{ 'back_office.log.by_user' | translate }}" [items]="users" bindLabel="first_name" bindValue="id" (change)="set_filter()"></ng-select>
                </div>

                <div class="col-md-1 filters_col">
                    <!-- <ng-select [(ngModel)]="byDate" name="byDate" [items]="dates" bindLabel="name" bindValue="id" class="form-control input_2" placeholder="By Date"></ng-select> -->
                    <input name="date" type="text" [(ngModel)]="filter._date" ngxDaterangepickerMd class="form-control date_range_picker" [autoApply]="true" placeholder="{{ 'back_office.log.by_date' | translate }}" [locale]="{format: 'DD MMMM  YYYY'}" [singleDatePicker]="true" style="padding: 0; text-align: center; font-weight:700;"
                        (change)="set_filter()">
                </div>

                <div class="col-md-1 filters_col">
                    <button class="btn btn-block resetBtn" (click)="reset()">{{ 'back_office.log.reset' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
</div>




<div class="tab-content capture" id="capture">

    <div id="changeLog" class="tab-pane fade  in active  mt5 card card-table table-responsive" [ngClass]="darkMode ? 'card-dark' : 'card'">
        <!-- <table class="arrivals-table table">
          <thead>
              <tr>
                  <th width="14.2%" class="head-left"><div class="th-border">{{ 'back_office.log.category' | translate }}</div></th>
                  <th width="14.2%"><div class="th-border">{{ 'back_office.log.change_type' | translate }}</div></th>
                  <th width="14.2%"><div class="th-border"> ID</div></th>
                  <th width="14.2%"><div class="th-border">{{ 'back_office.log.before' | translate }}</div></th>
                  <th width="14.2%"><div class="th-border">{{ 'back_office.log.after' | translate }}</div></th>
                  <th width="14.2%"><div class="th-border">{{ 'back_office.log.user' | translate }}</div></th> -->
        <!-- cmnt -->
        <!-- <th width="10%"><div class="th-border"> {{ 'back_office.log.date' | translate }}</div></th> -->
        <!-- <th width="10%" class="head-right">{{ 'back_office.log.time' | translate }}</th> -->
        <!-- cmnt -->
        <!-- <th width="14.2%" class="head-right">Time Date</th>
              </tr>
          </thead>
          <tbody id="drag-list">
              <tr *ngFor="let row of table_data.data; let i=index">
                  <td class="arr-left" [ngStyle]="{'border-color': colors[i%colors.length]}">{{ row.table_name | titlecase }}</td>
                  <td>{{ row.description.split(' ')[1] | titlecase }}</td>
                  <td>{{ row.id }}</td>
                  <td> <div class="elipsis" data-toggle="tooltip" data-placement="left" title="{{ row.before_change_json }}">
                      {{ row.before_change }}
                  </div></td>
                  <td> <div class="elipsis" data-toggle="tooltip" data-placement="left" title="{{ row.after_change_json }}">
                      {{ row.after_change }}
                  </div></td>
                  <td>{{ row.user.first_name }}</td>
                  <td class="arr-right" [ngStyle]="{'border-color': colors[i%colors.length]}">
                      {{ row.created_date }} | {{ row.created_time }}
                  </td>
              </tr>
          </tbody>
      </table> -->
        <ul class="blue-ul">
            <!-- <li><a>{{ 'back_office.log.id' | translate }}</a></li> -->
            <li><a>{{ 'back_office.log.category' | translate }}</a></li>
            <li style="width: 25%;"><a>{{ 'back_office.log.change_type' | translate }}</a></li>
            <li><a>{{ 'back_office.log.before' | translate }}</a></li>
            <li><a>{{ 'back_office.log.after' | translate }}</a></li>
            <li><a>{{ 'back_office.log.user' | translate }}</a></li>
            <li style="border-right: none"><a>{{ 'back_office.log.date_time' | translate }}</a></li>
        </ul>

        <div class="tablediv no_scroll scroll ">
            <ul class="body-ul dark-log " *ngFor="let row of table_data.data; let i=index" [ngStyle]="{'border-color': colors[i%colors.length]}" [ngClass]="darkMode ? 'orangeHov' : 'greyHov'">
                <!-- <li style="width: 13.6%;"><a>{{ row.id }}</a></li> -->
                <li style="width: 13.6%;"><a>{{ tc(row.table_name) | titlecase }}</a></li>
                <!-- <li style="width: 15%"><a>{{ row.description.split(' ')[1] | titlecase }}</a></li> -->
                <li style="width: 25%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 17px;" data-toggle="tooltip" title="{{ row.description }}"><a>{{ row.description }}</a></li>
                <li style="width: 15.5%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 45px 0px 40px;" data-toggle="tooltip" title="{{ row.before_change }}"><a>{{ row.before_change }}</a></li>
                <li style="width: 13%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 25px 0px 20px;" data-toggle="tooltip" title="{{ row.after_change }}"><a> {{ row.after_change }}</a></li>
                <li style="width: 15.7%"><a>{{ row.user.first_name }}</a></li>
                <li style="width: 13%"><a>{{ row.created_date }} | {{ row.created_time }}</a></li>
            </ul>
        </div>


        <nav aria-label="Page navigation example" style="text-align: center;" id="paginav" *ngIf="page_arr.length > 1">
            <div style="margin-left:32%;">
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link" aria-label="Previous" style="font-size: 20px;
                     height: 32px;
                     padding: 1px;
                     padding-right: 10px;
                     padding-left: 10px;
                     margin-right:5px" (click)="switch_page('firstPage')">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link" aria-label="Previous" style="font-size: 20px;
                     height: 32px;
                     padding: 1px;
                     padding-right: 10px;
                     padding-left: 10px;
                     margin-right:5px" (click)="switch_page('back')">
                                <span aria-hidden="true">&lsaquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div style="float: left;" class="cuspagidiv" id="cuspagidiv1">
                    <div class="page-item cusPagi" *ngFor="let current_page of page_arr" (click)="switch_page(current_page+1)" [ngClass]="{'active': page == current_page+1}"><a class="page-link">{{ current_page+1 }}</a></div>
                </div>

                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link" aria-label="Next" style="font-size: 20px;
                   height: 32px;
                   padding: 1px;
                   padding-left: 10px;
                   padding-right: 10px;
                   margin-left:5px" (click)="switch_page('forward')">
                                <span aria-hidden="true">&rsaquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link" aria-label="Next" style="font-size: 20px;
                   height: 32px;
                   padding: 1px;
                   padding-left: 10px;
                   padding-right: 10px;
                   margin-left:5px" (click)="switch_page('lastPage')">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <p *ngIf="page_arr.length == 0" class="no-record">{{ 'back_office.log.no_record_found' | translate }}.</p>
    </div>

    <div id="errorLog" class="tab-pane fade mt5 card card-table table-responsive" [ngClass]="darkMode ? 'card-dark' : 'card'">

        <ul class="blue-ul">
            <li style="width: 16.6%;"><a>OTA</a></li>
            <!-- <li style="width: 16.6%;"><a>User ID</a></li> -->
            <li style="width: 10.6%;"><a>{{ 'back_office.log.error_code' | translate }}</a></li>
            <li style="width: 10.6%;"><a>{{ 'back_office.log.short_text' | translate }}</a></li>
            <li style="width: 10.6%"><a>{{ 'back_office.log.error_type' | translate }}</a></li>
            <li style="width: 28.6%"><a>{{ 'back_office.log.error_message' | translate }}</a></li>
            <li style="border-right: none; width: 18.6%;"><a>{{ 'back_office.log.date_time' | translate }}</a></li>
        </ul>


        <div class="no_scroll scroll" style="height: 77.5vh;">
            <ul class="body-ul dark-log" *ngFor="let row of errorlog; let i=index" [ngStyle]="{'border-color': colors[i%colors.length]}" [ngClass]="darkMode ? 'orangeHov' : 'greyHov'">
                <li style="width: 16.6%;">
                    <a><img src="assets/img/svgicons/otas/expedia.svg" style="width: 120px;height: 45px;margin-top: -3px;"> </a>
                </li>
                <li style="width: 10.6%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 17px;" data-toggle="tooltip" title=""><a>{{row.code}}</a></li>
                <li style="width: 10.6%;"><a>{{row.short_text}}</a></li>
                <li style="width: 10.6%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 45px 0px 40px;" data-toggle="tooltip" title=""><a>{{row.error_type}}</a></li>
                <li style="width: 28.6%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 25px 0px 20px;" data-toggle="tooltip" title=""><a>{{row.text}} </a></li>
                <li style="width: 18.6%"><a>{{ row.created_date }} | {{ row.created_time }} </a></li>
            </ul>
        </div>

        <nav aria-label="Page navigation example" style="text-align: center;" id="paginavv" *ngIf="page_err.length > 1">
            <div style="margin-left: 45%;">
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActivee">
                        <li class="page-item">
                            <a class="page-link" aria-label="Previous" style="font-size: 20px; height: 32px; padding: 1px;   padding-right: 10px;
                          padding-left: 10px;
                          margin-right:5px" (click)="switch_page('firstPage')">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActivee">
                        <li class="page-item">
                            <a class="page-link" aria-label="Previous" style="font-size: 20px;
                          height: 32px;
                          padding: 1px;
                          padding-right: 10px;
                          padding-left: 10px;
                          margin-right:5px" (click)="switch_page('back')">
                                <span aria-hidden="true">&lsaquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div style="float: left;" class="cuspagidiv" id="cuspagidiv11">
                    <div class="page-item cusPagi" *ngFor="let current_page of page_err" (click)="switch_page(current_page+1)" [ngClass]="{'active': pagee == current_page+1}"><a class="page-link">{{ current_page+1 }}</a></div>
                </div>

                <div style="float: left;">
                    <ul class="pagination" id="myDIVActivee">
                        <li class="page-item">
                            <a class="page-link" aria-label="Next" style="font-size: 20px;
                        height: 32px;
                        padding: 1px;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-left:5px" (click)="switch_page('forward')">
                                <span aria-hidden="true">&rsaquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActivee">
                        <li class="page-item">
                            <a class="page-link" aria-label="Next" style="font-size: 20px;
                        height: 32px;
                        padding: 1px;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-left:5px" (click)="switch_page('lastPage')">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <p *ngIf="page_err.length == 0" class="no-record">{{ 'back_office.log.no_record_found' | translate }}.</p>
    </div>


</div>
