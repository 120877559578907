<div class="alert alert-danger breadcrum mar-adj" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <a  class="btn btn-link" routerLink="/app/back_office">
        {{ "back_office.customer_support.back_office" | translate }}
    </a>
    <a  class="btn btn-link active"
        style="padding-left: 23px;">{{ "back_office.customer_support.contact_support" | translate }}</a>
</div>

<!-- <div class="bg-white-one"> -->


<!-- cmnt -->
<!-- <div class="row">s
                <div class="col-md-12">
                <div class="flex">
                    <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-left: 0px;
                    padding-right: 0px;
                    margin-right: 0px;">
                        <div class="bg-colr">
                            <img src="assets/img/contact_support/new_male.png" alt="" class="pd-img">
                            <h3 class="red-text">We are here for help</h3>
                            <h2 class="f-s">Our world-wide team of specialist have got you covered</h2>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-right: 0px;
                    padding-left: 0px;
                    margin-left: 5px;">
                        <div class="bg-colr">
                            <img src="assets/img/contact_support/new_female.png" alt="" class="pd-img">
                            <h3 class="red-text">Call Support</h3>
                            <h2 class="f-s">XX - XXXX -X XXXXX</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row one-card c-div">
            <div class="col-md-12 text-center cr-txt">
                <div class="border-style one-card-text">
                    <p class="f-fam">Fill in this form we'll get back you</p>
                </div>
            </div>
        </div> -->
<!-- cmnt -->




<!-- <div class="row">
            <div class="col-md-12">
            <div class="flex">
                <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-left: 0px;
                padding-right: 0px;
                margin-right: 0px;background-color: #021355;height: 100px;">
                    <div class="bg-colr">
                        <div class="row">
                            <div class="col-md-2">
                                    <img src="assets/img/contact_support/4388.png" alt="" class="pd-img" style="float: left;">

                            </div>
                            <div class="col-md-10" style="padding-left: 0;position: relative;margin-left: -10px;margin-top: 10px;">
                                    <h3 class="red-text">{{ 'back_office.customer_support.help_text' | translate }}</h3>
                                    <h2 class="f-s">{{ 'back_office.customer_support.team_text' | translate }}</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-right: 0px;
                padding-left: 0px;
                margin-left: 5px;background-color: #021355;height: 100px;">
                    <div class="bg-colr">
                        <div class="row">
                            <div class="col-md-2">
                                    <img src="assets/img/contact_support/3368.png" alt="" class="pd-img" style="float: left;">
                            </div>
                            <div class="col-md-10" style="padding-left: 0;position: relative;margin-left: -10px;margin-top: 10px;">
                                    <h3 class="red-text">{{ 'back_office.customer_support.call_support' | translate }}</h3>
                                    <h2 class="f-s">XX - XXXX -X XXXXX</h2>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row one-card c-div">
        <div class="col-md-12 text-center cr-txt">
            <div class="border-style one-card-text">
                <p class="f-fam">{{ 'back_office.customer_support.fill_form' | translate }}</p>
            </div>
        </div>
    </div>

        <form [formGroup]="contact_support_form">
            <div class="row row-pd">
                <div>
                    <div class="col-md-4 text-center i-col-1" style="padding-right: 0px;">
                        <input type="email" class="border-style form-control web-one" placeholder="{{ 'back_office.customer_support.web_email' | translate }}" formControlName="web_email" required/>
                        <div *ngIf="submitted && f.web_email.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.web_email.errors.email">{{ 'back_office.customer_support.enter_valid_email' | translate }}</strong>

                                <strong *ngIf="f.web_email.errors.required">{{ 'back_office.customer_support.email_required' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-4 text-center i-col-2" style="padding-right: 0px;">
                        <input type="text" class="border-style form-control web-two" placeholder="{{ 'back_office.customer_support.property_name' | translate }}" formControlName="property_name"/>
                        <div *ngIf="submitted && f.property_name.errors" class="small text-danger no-margin invalid-feedback text-left">
                            <strong *ngIf="f.property_name.errors.required">{{ 'back_office.customer_support.property_name_required' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-4 text-center i-col-3">
                        <input type="text" class="border-style form-control web-three" placeholder="{{ 'back_office.customer_support.subject' | translate }}" formControlName="subject"/>
                        <div *ngIf="submitted && f.subject.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.subject.errors.required">{{ 'back_office.customer_support.subject_is_req' | translate }}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-pd">
                <div class="col-md-4 text-center i-col-1" style="padding-right: 0px;">
                    <div>
                        <div class="border-style country-shade web-four"> -->


<!-- cmnt -->
<!-- <ng-select bindvalue="full_name" formControlName="whichproduct" placeholder="What product are you using ?">
                                <div class="ng-select-container">
                                    <div class="ng-value-container">
                                        <ng-option>Volvo</ng-option>
                                    </div>
                                </div>
                            </ng-select> -->
<!-- cmnt -->


<!-- <ng-select formControlName="product_using" placeholder="{{ 'back_office.customer_support.what_product' | translate }} ?" [clearable]="false">
                                    <ng-option value="channel_manager">{{ 'back_office.customer_support.channel_manager' | translate }}</ng-option>
                                    <ng-option value="pms">{{ 'back_office.customer_support.property_mgmt_sys' | translate }}</ng-option>
                                    <ng-option value="test1">Test 1</ng-option>
                                    <ng-option value="test2">Test 2</ng-option>
                                    <ng-option value="test2">Test 2</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && f.product_using.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.product_using.errors.required">{{ 'back_office.customer_support.select_one_product' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center i-col-2" style="padding-right: 0px;">
                        <div>
                            <div class="border-style country-shade web-five">
                                <ng-select formControlName="request_related_to" placeholder="{{ 'back_office.customer_support.my_req_related' | translate }}" [clearable]="false">
                                        <ng-option value="property_inventory">{{ 'back_office.customer_support.property_inventory' | translate }}</ng-option>
                                        <ng-option value="using_the_interface">{{ 'back_office.customer_support.using_the_interface' | translate }}</ng-option>
                                        <ng-option value="channels">{{ 'back_office.customer_support.channels' | translate }}</ng-option>
                                        <ng-option value="users_and_passwords">{{ 'back_office.customer_support.users_and_passwords' | translate }}</ng-option>
                                        <ng-option value="bookings_and_Reservation">{{ 'back_office.customer_support.booking_reservation' | translate }}</ng-option>
                                        <ng-option value="property_rates">{{ 'back_office.customer_support.property_rates' | translate }}</ng-option>
                                </ng-select>
                            </div>
                            <div *ngIf="submitted && f.request_related_to.errors" class="small text-danger no-margin invalid-feedback text-left">
                                    <strong *ngIf="f.request_related_to.errors.required" style="padding-right: 8px;">{{ 'back_office.customer_support.request_related_is_required' | translate }}</strong>
                            </div>
                        </div>
                </div>
                <div class="col-md-4 text-center i-col-3">
                    <div>
                        <div class="border-style country-shade web-six">
                            <ng-select formControlName="need_assistance_with" placeholder="{{ 'back_office.customer_support.need_assistance' | translate }}"  [clearable]="false">
                                    <ng-option value="adding_updating_changing">{{ 'back_office.customer_support.adding_updating_or_changing' | translate }}</ng-option>
                                    <ng-option value="configuring_or_mapping_my_property">{{ 'back_office.customer_support.configure_my_property' | translate }}</ng-option>
                                    <ng-option value="being_shown_how_to_do_something">{{ 'back_office.customer_support.being_shown_something' | translate }}</ng-option>
                                    <ng-option value="an_error_message_from_the_product">{{ 'back_office.customer_support.an_error_message_from_the_product' | translate }}</ng-option>
                                    <ng-option value="logging_in_or_access">{{ 'back_office.customer_support.logging_access' | translate }}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && f.need_assistance_with.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.need_assistance_with.errors.required" style="padding-right: 14px;">{{ 'back_office.customer_support.please_select_one_option' | translate }}</strong>
                        </div>
                    </div>
                </div>
                    <input type="hidden" name="user_id" formControlName="user_id">
                </div>
                <div class="row row-pd desc-pd-adj">
                    <div>
                        <div class="col-md-8 text-center pd-lft">
                                <textarea type="textarea" class="border-style form-control web-desc" id="" cols="8" rows="4" placeholder="{{ 'back_office.customer_support.description' | translate }}" formControlName="description"></textarea>
                                <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback text-left">
                                    <strong *ngIf="f.description.errors.required">{{ 'back_office.customer_support.desc_req' | translate }}</strong>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="pull-right"> -->



<!-- cmnt -->
<!-- <div class="row">
                        <div class="col-md-3 text-center btn-sty">
                                <button type="submit" class="form-control btn-sty-one">Submit</button>
                        </div>
                        <div class="col-md-3 text-center btn-sty-three">
                                <button type="button" class="form-control btn-sty-four" (click)="resetform()">Reset</button>
                        </div>
                    </div> -->
<!-- cmnt -->



<!-- <div class="modal-footer text-right modal-border" style="padding-right: 0px;
                    margin-right: -20px;">
                        <button type="button" class="btn btn-primary btn-lg btn-set-one submit-button" (click)="onSubmit()">{{ 'back_office.customer_support.submit' | translate }}</button> -->


<!-- cmnt -->
<!-- <button type="button" class="btn btn-danger btn-lg btn-set-two reset-form" (click)="resetform()">{{ 'back_office.customer_support.cancel' | translate }}</button> -->
<!-- cmnt -->



<!-- </div>
                </div>

    </form>

    </div> -->


<div class="no_scroll scroll" style="    height: 77vh;">
  <div class="row main-row ">

    <form [formGroup]="contact_support_form">
        <div class="col-md-6 zero-padding" style="padding-right: 3px">
            <div class="col-md-12 zero-padding">
                <!-- <div class="head">
                    <div class="icon">
                        <img src="assets/img/newstaticicons/back-office/c-messege.svg" class="contactIcon">
                    </div>
                    <div class="heading">{{ "back_office.log.we_are_here" | translate }}</div>
                </div> -->
            </div>
            <div class="col-md-12 zero-padding">
                <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                    <div class="row main-row">
                        <div class="col-md-12 zero-padding">
                            <!-- <div class="mb5">
                                <img src="assets/img/newstaticicons/back-office/c-messege.svg" class="contactIcon"><div class="direct">{{ "back_office.log.we_are_here" | translate }}</div>
                            </div> -->
                            <div class="bar">
                                <input type="text" placeholder="Subject" formControlName="subject">
                                <div *ngIf="submitted && f.subject.errors"
                                    class="small text-danger no-margin invalid-feedback text-left">
                                    <strong *ngIf="f.subject.errors.required">{{ "back_office.customer_support.subject_is_required" | translate }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 zero-padding">
                            <textarea placeholder="Message" rows="12" class="bor"
                                formControlName="description"></textarea>
                            <div *ngIf="submitted && f.description.errors"
                                class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.description.errors.required">{{ "back_office.customer_support.mesage_is_required" | translate }}</strong>
                            </div>
                        </div>
                        <div class="col-md-12 zero-padding">
                            <!-- <button class="submit hov" (click)="onSubmit()">{{ "back_office.customer_support.submit" | translate }}</button> -->
                            <button class="submit hov" (click)="onSubmit()"><img src="assets/img/newstaticicons/back-office/c-submit.svg" class="contactIcon hov"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>


    <div class="col-md-6 zero-padding" style="padding-left: 2px">
        <div class="col-md-12 zero-padding">
            <!-- <div class="head">
                <div class="icon">

                    <img src="assets/img/newstaticicons/back-office/c-call.svg" class="contactIcon"
                        style="margin-left: 5px;">
                </div>
                <div class="heading">{{ "back_office.log.on_call" | translate }}</div>
            </div> -->
        </div>
        <div class="col-md-12 zero-padding">
            <div class="cardd mycar" [ngClass]="darkMode ? 'card-dark' : 'card'">

                <div class="row main-row">
                    <!-- <div class="col-md-12 zero-padding direct"><img src="assets/img/newstaticicons/back-office/c-call.svg" class="contactIconph" style="margin-right: 10px;">{{ "back_office.log.direct_line" | translate }}</div> -->

                    <div class="col-md-12 zero-padding red-bar">
                        <div class="col-md-4 zero-padding text-left"><img src="assets/img/newstaticicons/back-office/c-receiver.svg" class="contactIconph" style="margin-right: 10px;">{{ "back_office.log.phone_num" | translate }} 1</div>
                        <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                    </div>
                    <div class="col-md-12 zero-padding red-bar2">
                        <div class="col-md-4 zero-padding text-left"><img src="assets/img/newstaticicons/back-office/c-receiver.svg" class="contactIconph" style="margin-right: 10px;">{{ "back_office.log.phone_num" | translate }} 1</div>
                        <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                    </div>
                    <div class="col-md-12 zero-padding red-bar3">
                        <div class="col-md-4 zero-padding text-left"><img src="assets/img/newstaticicons/back-office/c-receiver.svg" class="contactIconph" style="margin-right: 10px;">{{ "back_office.log.phone_num" | translate }} 1</div>
                        <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="col-md-12 zero-padding" style="margin-top: 5px">
            <div class="row main-row">
                <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
                    <div class="row main-row">
                        <div class="col-md-12 zero-padding"
                            style="border-bottom: 2px solid lightgrey;padding-bottom: 5px;">
                            <div class="col-md-6 zero-padding text-left direct">
                                {{ "back_office.log.book_a_call" | translate }}
                            </div>
                            <div class="col-md-6 zero-padding text-right" style="transform: translateY(3px);">
                                <span style="margin-right: 15px;border-right: 1px solid red;padding-right: 15px;">
                                    <input type="radio" id="test1" name="radio-group" value="15 min"
                                        [(ngModel)]="duaration">
                                    <label for="test1" [ngClass]="darkMode ? 'hovYellowTxtDarkMode' : 'null'">15 {{ "back_office.log.min" | translate }}</label>
                                </span>
                                <span>
                                    <input type="radio" id="test2" name="radio-group" value="30 min"
                                        [(ngModel)]="duaration">
                                    <label for="test2" [ngClass]="darkMode ? 'hovYellowTxtDarkMode' : 'null'">30 {{ "back_office.log.min" | translate }}</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12 zero-padding" [ngClass]="darkMode ? 'dardCal' : 'null'">
                            <div class="empCard">
                                <div class="calendarCard">
                                    <div class="col-md-12 calendar-top f-calendar shown"
                                        style="padding: 0px 5px 0px 2px;">
                                        <div class=" color-4 bc6">

                                            <ngx-daterangepicker-material [locale]="{ applyLabel: 'Done', firstDay: 1 }"
                                                name="inline-daterangepicker" (startDateChanged)="eventClicked($event)"
                                                [singleDatePicker]="true" [alwaysShowCalendars]="true">
                                            </ngx-daterangepicker-material>

                                            <div>{{ "back_office.customer_support.chosen_date" | translate }}: {{ inlineDate | json }}</div>
                                            <!-- <input type="text" class="input form-control"
                                                placeholder="Duration (Days)" style="outline: none;"
                                                formControlName="duration"
                                                > -->
                                            <!-- <div class="input form-control">
                                                            <input type="hidden" name="date_to">
                                                            <input type="hidden" name="date_from">
                                                            <input type="text"
                                                            ngxDaterangepickerMd
                                                            class="form-control date_range_picker arrival-lenght"
                                                            [(ngModel)]="durationDate"
                                                            placeholder="Duration (Days)"
                                                            [singleDatePicker]="true"
                                                            [autoApply]="true"
                                                            (change)="calendar_change(durationDate)"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [locale]="{format: 'DD, MMMM YYYY', separator: '  To  '}"
                                                            style="width: 100% !important; padding: 2px;float: right;"
                                                            [dateLimit]="12"
                                                            shown

                                                            >
                                                    </div> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 redCardCalendar zero-padding" style="display: block;" id="redCard">
                                <div class="col-md-12 " style="padding: 0px 20px;">
                                    <div class="selectTimeCalendar">
                                        <div class="mainSelectTime"><span
                                                style="color:white;margin-right: 3px;">{{ "back_office.log.select" | translate }}</span> <span>{{ "back_office.log.time" | translate }}</span>
                                        </div>
                                        <div class="timeZoneCalendar"> {{city}}, {{countryByTime[1]}} ({{zoneTime}})
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 " style="padding: 0px 20px;">
                                    <div class="timesCalendar">

                                        <div class="timeChip hov" *ngFor="let timess of selectedDateTime; let i = index"
                                            (click)="selectedTime = i; getTime(timess)"
                                            [ngClass]="[selectedTime == i ? 'colorRedCard' : 'w1']">
                                            <div class="leftTime">{{ timess.startTime }} pm</div>
                                            <div class="rightTime">{{ timess.endTime }} pm</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 zero-padding">
                                <button class="submit hov" (click)="subletmit2()">{{ "back_office.customer_support.submit" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</div>


