<div class="row">
  <div class="col-md-12">
    <div class="filter-box" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <form class="form-inline">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="col-md-2 filters_col" style="padding-left: 10px;">
              <ng-select [(ngModel)]="filterLinked" name="filterLinked" [items]="link_drop" bindLabel="name"
                bindValue="val" data-toggle="tooltip"
                title="{{'rates_and_packages.menu.filter_by_linked_unlinked' | translate}}"
                class="form-control input_2 orangSelect"
                placeholder="{{'rates_and_packages.menu.filter_by_linked_unlinked' | translate}}"
                (change)="filterlinked(filterLinked)"></ng-select>
            </div>
            <!-- <div class="col-md-2 filters_col">
                          <ng-select [(ngModel)]="filterRoom" name="filterRoom"
                            class="form-control input_2 orangSelect"
                            placeholder="{{'rates_and_packages.menu.filter_by_room' | translate}}"
                            (change)="filterByRoom(filterRoom)" [items]="roomtypes" bindLabel="name" bindValue="id">
                          </ng-select>
                        </div> -->
            <div class="col-md-2 filters_col">
              <ng-select [(ngModel)]="filteration" name="filterChannel" (change)="filterationByPrice(filteration)"
                class="form-control input_2 orangSelect"
                placeholder="{{ 'rates_and_packages.menu.filter_by' | translate}}">
                <ng-option value="low_to_high">{{'rates_and_packages.link_channel.high_to_low' | translate}}
                </ng-option>
                <ng-option value="high_to_low">{{'rates_and_packages.link_channel.low_to_high' | translate}}
                </ng-option>
                <ng-option value="all">{{'rates_and_packages.link_channel.reset' | translate}}
                </ng-option>
              </ng-select>
            </div>

            <div class="col-md-8 filters_col" style="padding: 0px 5px 0px 0px">
              <div style="display: flex; justify-content: flex-end;">
                <div style="width: 40%; text-align: right" class="filters_col"></div>
                <div style=" text-align: right; padding-right: 5px" class="filters_col abc">
                  <ng-select [(ngModel)]="createViewSelect" (change)="createSeason(createViewSelect)"
                    class="cevt-drop orangSelect" name="createView" [items]="createView" bindLabel="name"
                    bindValue="val" class="form-control input_2 card-dark-btn-g_place orangSelect"
                    placeholder="+ Create" style="
                                                width: 50% !important;
                                                color: #4a596e;
                                                background: linear-gradient(#30319a, #062460);
                                                border: none;
                                                cursor: pointer;
                                              " [ngClass]="
                                                darkMode
                                                  ? 'card-dark-btn-g_place_dark'
                                                  : 'card-dark-btn-g_place'
                                              ">
                  </ng-select>

                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Filter Bar -->

    <!-- Filter By Room Package -->
    <div *ngIf="searchView == true">
      <div class="card try" *ngFor="
          let package of ratePackage?.reverse() | search : 'package_name,roomttype.name,room_rate,is_linked,room_id,otas.channel_id' : param; let i = index
        " style="padding-bottom: 0px; padding-right: 4px; padding-top: 5px"
        [ngClass]="darkMode ? 'card-darkP' : ' card-null '" id="package_id{{package.id}}">
        <div class="row">
          <div class="col-md-3 pr-10 packageCol1">
            <div style="height: 65px">
              <h1 (click)="
                  service.is_preview = true;
                  edit_package(package.id, true);
                  selectpercentage(package.percentage)
                " data-target="#settings" data-toggle="modal" class="{{ colors[i % 7] }} pkg_title hover_shade" style="
                  text-transform: capitalize;
                  font-size: 18px;
                  line-height: 1 !important;
                  transform: translateY(-2px);">
                {{ package.package_name }}
              </h1>
            </div>

            <p class="linked" id="linked4">
              {{ dateFunc(package.date_to) | date: "MMM dd" | uppercase }} | 
              {{ dateFunc(package.date_from) | date: "MMM dd" | uppercase }}
            </p>
            <h4 class="linked" id="linked5" *ngIf="getOTAs(package).length == 0">
              {{ "rates_and_packages.list.no_channels_connected" | translate }}
            </h4>
            <div #logoContainer class="icon-group-{{ package.id }} ico1 channelsLine">
              <ul #logoList class="nav nav-pills otas-list">
                <li *ngFor="
                    let logo of getOTAs(package) | slice: 0:logos_quantity;
                    let logosi = index
                  " class="sp-{{ logo.id }}">
                  <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                      logo.channel.image
                    }}" style="height: 32px" />
                </li>
                <li *ngIf="getOTAs(package).length > logos_quantity" class="pull-right">
                  <img class="dropdown-toggle last-img" src="assets/img/manage_rates/dots.png"
                    (click)="openPackageChannels(getOTAs(package), package.id)" class="btn-hov"
                    style="height: 32px; border-radius: 100px; cursor: pointer" />
                  <ul class="dropdown-menu" style="right: auto">
                    <li *ngFor="let logo of getOTAs(package) | slice: logos_quantity" class="sp-{{ logo.id }}">
                      <a href="#">
                        <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                            logo.channel.image
                          }}" style="height: 32px" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-5 pl-10 pr-10 packageCol2">
            <div class="row">
              <div class="img-box col-md-4 pr-10">
                <a (click)="
                    service.is_preview = true;
                    edit_package(package.id, true);
                    selectpercentage(package.percentage)
                  " data-target="#settings" data-toggle="modal">
                  <img class="img-responsive hover_shade" style="
                      border-radius: 10px;
                      width: 100%;
                      height: 133px;
                      cursor: pointer;
                    " src="{{
                      (package.room?.images)[0]?.image
                        ? api.imgUrl + package.room.images[0]?.image
                        : 'assets/img/other/no-room.jpg'
                    }}" />
                </a>
              </div>
              <div class="text-box col-md-8 pl-10">
                <span (click)="
                    service.is_preview = true;
                    edit_package(package.id, true);
                    selectpercentage(package.percentage)
                  " data-target="#settings" data-toggle="modal" class="description {{ colors[i % 7] }} hover_shade"
                  style="cursor: pointer; text-transform: capitalize">
                  {{ package.roomttype.name }}
                </span>
                <span *ngIf="package.is_duplicated == '1'" class="duplicate_text">
                  {{ "rates_and_packages.list.duplicated" | translate }}
                </span>
                <p (click)="
                    service.is_preview = true;
                    edit_package(package.id, true);
                    selectpercentage(package.percentage)
                  " data-target="#settings" data-toggle="modal" class="desc_text hover_shade">
                  {{ package.room.description }}
                </p>
                <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                  <ul class="nav navbar-nav pull-left">
                    <li>
                      <a class="totlo">
                        <img src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'}}/bed.svg" [ngStyle]="  darkMode ? { margin: '-10px 5px -5px 0px', height: '40px' } : {}
                          " style="margin: -10px 5px -5px 0px; height: 40px" />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{ package.room?.bedtype.name }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="totlo">
                        <img src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/sleep.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{ "rates_and_packages.list.sleeps" | translate }}
                            {{
                            package.room?.adults +
                            package.room?.children +
                            package.room?.infants
                            }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="totlo">
                        <img src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/area.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{ package.room?.room_size }}
                            {{
                            package.room?.room_size_type == "meters"
                            ? "m"
                            : "Sq ft"
                            }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.pets != ''">
                      <a class="totlo">
                        <img *ngIf="package?.room?.pets == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/pets.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " class="image-adj" style="height: 20px" />
                        <img *ngIf="package?.room?.pets == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/nopets.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " class="image-adj" style="height: 20px" />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{
                            package?.room?.pets == "true"
                            ? "Allowed"
                            : "Not Allowed"
                            }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.wheelchairaccessibility != ''">
                      <a class="totlo">
                        <img *ngIf="
                            package?.room?.wheelchairaccessibility == 'true'
                          " src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/wheel.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " alt="" class="image-adj" style="height: 25px; margin-top: -5px" />
                        <img *ngIf="
                            package?.room?.wheelchairaccessibility == 'false'
                          " src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/nowheel.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " alt="" class="image-adj" style="height: 25px; margin-top: -5px ;" />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{
                            package?.room?.smoking == "true"
                            ? "Available"
                            : "Not Available"
                            }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.smoking != ''">
                      <a class="totlo">
                        <img *ngIf="package?.room?.smoking == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/smoke.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " class="image-adj" style="height: 20px; margin-top: 0px" />
                        <img *ngIf="package?.room?.smoking == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                            darkMode ? 'darkmode' : 'lightmode'
                          }}/nosmoke.svg" [ngStyle]="
                            darkMode
                              ? { margin: '-10px 0px -5px 0px', height: '40px' }
                              : {}
                          " class="image-adj" style="height: 20px; margin-top: 0px" />
                        <span class="tooltiptext" [ngClass]="
                            darkMode ? 'tooltiptextdark' : 'tooltiptext'
                          ">
                          <p style="margin: 0px">
                            {{
                            package?.room?.smoking == "true"
                            ? "Allowed"
                            : "Not Allowed"
                            }}
                          </p>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 packageCol3">
            <div class="row">
              <div class="season-box2 color-change" style="transform: translateY(0px) !important; height: 133px"
                [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
                <div class="new" id="n1" style="height: 100%">
                  <div style="display: flex; align-items: center; height: 100%">
                    <div style="height: 100%">
                      <img src="assets/img/newstaticicons/rates-packages/promotion.svg" width="90px"
                        alt="promotion image" class="promoTionImg" />
                      <div class="pkg-off" *ngIf="package?.percentage != 0">
                        {{ package?.percentage }}% {{ "rates_and_packages.link_channel.off" | translate }}
                      </div>
                      <div style="font-size: 16px; color: #707070">
                        <b>{{
                          dateFunc2(package.date_from).diff(
                          dateFunc2(package.date_to),
                          "days"
                          )
                          }}</b> {{ "rates_and_packages.link_channel.nights" | translate }}
                        <img src="assets/img/newstaticicons/rates-packages/night.svg" style="width: 20px" />
                      </div>
                      <!-- <div style="color: #707070;"><span style="font-size: 20px;color: red;">{{'rates_and_packages.link_channel.free' | translate}} {{package?.zero_price}}</span> {{'rates_and_packages.link_channel.nights' | translate}}</div> -->
                      <!-- <div style="color: red;">Promotion</div> -->
                      <!-- <span class="breakfast"
												*ngIf=" package.breakfast_include == 'Complimentry'">
												<i class="fa fa-utensils"></i>
												{{"rates_and_packages.list.breakfast_included" | translate}}
											</span>
											<span class="breakfast"
												*ngIf=" package.breakfast_include != 'Complimentry'">
												<i class="fa fa-utensils"></i>
												{{'rates_and_packages.link_channel.breakfast_not_include' | translate}}
											</span> -->
                      <span class="breakfast" *ngIf="package.breakfast_include == 'Yes'">
                        <i class="fa fa-utensils"></i> Breakfast Included
                      </span>

                      <span class="breakfast" *ngIf="
                          package.breakfast_include == 'No' ||
                          package.breakfast_include == ''
                        ">
                        <i class="fa fa-utensils"></i>
                      </span>
                    </div>

                    <div class="DicsDiv">
                      <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="" />
                      <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
                        {{ roundPrice( package.sum_price, package.seasonRoomPrice ) }}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="season-box color-change" style="
                  width: 47%;
                  transform: translateY(0px) !important;
                  height: 133px;
                " [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
                <div class="new" id="n5" style="height: 120px">
                  <div class="rate-up">
                    <div class="rate-1 hei1" style="transform: translateY(2px)">
                      <span class="total pkg_title {{ colors[i % 7] }}" style="
                          font-size: 16px;
                          font-weight: bold;
                          text-transform: uppercase;
                          font-family: robotoblack;
                        ">
                        {{ "rates_and_packages.menu.total" | translate }}</span>
                    </div>
                    <div class="rate-2 hei2">
                      <span class="tax_cl" style="
                          text-decoration: line-through 1px black;
                          font-weight: bold;
                        "> {{current_currency}} {{package?.seasonRoomPrice }}</span>
                      <span class="price {{ colors[i % 7] }}">
                        {{current_currency}} {{ package?.sum_price }}
                      </span>
                      <span class="tax_cl">
                        +{{ "rates_and_packages.menu.tax" | translate }}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div class="rate-down">
                    <div class="rate-3">
                      <a class="btn btn-channel link_to_ota_btn btn-hov"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" (click)="packagePush(package.id)"
                        *ngIf="package.push_status == 'pending'">{{ "rates_and_packages.menu.push" | translate }}</a>
                      <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="
                          darkMode && package.push_status == 'pushed'
                            ? 'btn-hov_dark live-btn'
                            : 'btn-hov live-btn'
                        " *ngIf="package.push_status == 'pushed'" style="cursor: default">{{
                        "rates_and_packages.link_channel.live" | translate
                        }}</a>
                    </div>
                    <div class="rate-4">
                      <a (click)="duplicate_package(package.id)">
                        <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small"
                          [ngClass]="
                            darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
                          " width="25px" height="25px" style="border-radius: 10px" />
                      </a>
                      <a (click)="
                          edit_package(package.id, false);
                          selectpercentage(package.percentage)
                        " data-target="#settings" data-toggle="modal">
                        <img src="assets/img/newstaticicons/rates-packages/edit.svg" class="btn-hov-small" [ngClass]="
                            darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
                          " width="25px" height="25px" style="border-radius: 10px" />
                      </a>
                      <a class="" data-target="#confirmationModal" data-toggle="modal" (click)="delete(package.id,package.package_name,package.created_at)
                        ">
                        <img src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small" [ngClass]="
                                                    darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
                                                  " width="25px" height="25px" style="border-radius: 10px" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Filter By Room Package -->

    <!-- SEASON SKELETON Start -->
    <div class="dragnDrop scroll no_scroll saeson-skeleton" id="dragnDrop" *ngIf="promotion_skeleton"
      [ngClass]="darkMode ? 'saeson-dark-skeleton' : 'card-null '">
      <div class="for-ngFor" *ngFor="let rp of getEmptyElements(4)">
        <div class="season-main dropzone" [ngClass]="darkMode ? 'card-darkS' : ' card-null '">
          <div class="row">
            <!-- promotion view started -->
            <div class="col-md-2" style="padding-right: 5px;padding-top: 7px;">
              <div class="promotion-skeleton-animation season-card">
                <h4 class="head-main-bar"></h4>
                <div class="data-new_light-bar"></div>
                <div>
                  <p class="head-main-bar"></p>
                  <div class="icon-group-bar"></div>
                </div>
              </div>
              <span class="vertical-bar"></span>
            </div>

            <!-- promotion view end -->
            <div class="promo-flex">
              <div class="col-md-10 proWidth" style="padding-left: 10px;">
                <div class="box-flex">
                  <div class="season-box" [ngClass]="darkMode ? 'card-dark-seasone' : 'card-null '"
                    style="width: 20.4%;" *ngFor="let r of getEmptyElements(5); let x = index">
                    <div class="new promotion-skeleton-animation">
                      <div class="top-cal">
                        <div class="d-flex justify-content-between align-items-center">
                          <h5 class="head-main-bar m-0"></h5>
                          <div class="head-main-bar m-0"></div>
                        </div>
                      </div>
                      <div class="days-1 icon-group-bar"></div>
                      <hr class="myhr">
                      <div class="days-1 icon-group-bar"></div>
                      <div style="margin-top: 5px;" class="head-main-bar float-right"></div>
                    </div>
                  </div>
                </div>
                <span class="vertical-bar" style="left: 98%;"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="details d-flex justify-content-between align-items-center" style="padding: 10px;">
          <div class="head-main-bar"></div>
          <div class="data-new_light-bar m-0"></div>
        </div>
      </div>
    </div>
    <!-- SEASON SKELETON END -->

    <!-- SEASON Start -->
    <div cdkDropList class="dragnDrop scroll no_scroll" id="dragnDrop" (cdkDropListDropped)="drop($event)">
      <div class="for-ngFor" *ngFor="let rp of ratePlan; let did = index" id="willsee" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="season-main dropzone" [ngClass]="darkMode ? 'card-darkS' : ' card-null '"
          id="{{ rp.layout_sequence }}" title="{{ rp.id }}">
          <div class="row">

            <div class="col-md-2 seasoneOtaCol">
              <h4 class="head-main {{ colors2[did % 7] }}">
                {{ rp.season_name }}
              </h4>
              <div class="room-names" style="padding-left: 3px">
                <span class="" [ngClass]="darkMode ? 'data-new' : 'data-new_light'">{{ rp.demand_type }}</span>
              </div>
              <div>
                <p class="linked" id="linked6">
                  {{ rp.date_to | date: "MMM dd" | uppercase }} | {{ rp.date_from | date: "MMM dd" | uppercase }}
                </p>
                <div #logoContainer class="icon-group-{{ rp.id }} channelsLine">
                  <ul #logoList class="nav nav-pills otas-list">
                    <li *ngFor=" let logo of rp.season_otas | slice: 0:logos_qty; let logosi = index"
                      class="sp-{{ logo.id }}">
                      <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                          logo.channel.image
                        }}" style="height: 32px" />
                    </li>
                    <li *ngIf="rp.season_otas.length > logos_qty" class="pull-right">
                      <img class="dropdown-toggle last-img" data-toggle="dropdown" href="#" role="button"
                        aria-haspopup="true" aria-expanded="false" src="assets/img/manage_rates/dots.png"
                        class="btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="
                          height: 32px;
                          border-radius: 100px;
                          cursor: pointer;
                        " />
                      <ul class="dropdown-menu" style="right: auto">
                        <li *ngFor="let logo of rp.season_otas | slice: logos_qty" class="sp-{{ logo.id }} btn-hov"
                          [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'">
                          <a href="#">
                            <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                                logo.channel.image
                              }}" style="height: 32px" />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <span *ngIf="rp.season_room_types.length >= 5"
                style="position: absolute; top: 7px; right: -10px; z-index: 1" (click)="scrollLefts(did)">
                <img src="assets/img/manage_rates/arrowleft.png" class="btn-hov"
                  [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="cursor: pointer; border-radius: 10px" />
              </span>
            </div>
            <!-- cursor: pointer;" src="../../../assets/img/manage-rooms/test.png" /> -->
            <div class="div" *ngIf="rp.season_room_types.length <= 1">
              <div class="col-md-6" *ngFor="let r of rp.season_room_types; let x= index">
                <div class="row">
                  <div class="img-box col-md-4 pr-10">
                    <a>
                      <img class="img-responsive hover_shade" style="border-radius:10px; width: 100%;
                                                          height: 133px;
                                                          " src="assets/img/other/no-room.jpg" />

                    </a>
                  </div>
                  <div class="text-box col-md-8 pl-10">
                    <a class="totlo">
                      <span class="description {{ colors2[did % 7] }} hover_shade"
                        style=" text-transform: capitalize">{{ r.room.roomtype.name }}</span>
                      <!-- <span  class="duplicate_text">
                                      {{ "rates_and_packages.list.duplicated" | translate }}
                                    </span> -->
                      <!-- <p  class="desc_text hover_shade" [ngClass]="darkMode ?
                                                        'card-dark-Date' : ' card-null '">
                                                        {{r.description}}

                                </p> -->





                      <!-- <span class="tooltiptext" *ngIf="r.room.display_name" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">

                                    {{ r.room.display_name}}
                                  </span> -->
                    </a>
                    <div *ngIf="!r.room.roomamenities"
                      style="margin: 15px 0 8px 0; height: 89px; text-overflow:ellipsis;overflow:hidden; "></div>
                    <div class="outer-box"
                      style="margin: 15px 0 8px 0; height: 89px; text-overflow:ellipsis;overflow:hidden; "
                      *ngIf="r.room.roomamenities">
                      <span class="label" style="padding: 0 5px 0 0;"
                        *ngFor="let ame of r.room.roomamenities; let a = index" id="{{a}}">
                        <span class="inner-ame" [ngClass]="darkMode ? 'amenitie_dark' : 'inner-ame'"
                          [ngStyle]="{'border':'2px solid '+aminites_colors[a]}" *ngIf="a <= 8">
                          {{ descripArray(ame.title)}}
                        </span>
                      </span>
                      <img src="assets/img/manage_rates/dots.png" class="dota" *ngIf="r.room.roomamenities.length > 12">

                      <!-- <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == false && ame?.title.split(' ').length > 30">
                                    <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == true && ame?.title.split(' ').length > 52"> -->

                      <div class="inner-box" *ngIf="r.room.roomamenities" [ngClass]="darkMode ? 'card-darkS':''">
                        <span class="label" style="padding: 0 5px 0 0;"
                          *ngFor="let ame of r.room.roomamenities; let a = index" id="{{a}}">
                          <span class="inner-ame" [ngClass]="darkMode ? 'amenitie_dark' : 'inner-ame'"
                            [ngStyle]="{'border':'2px solid '+aminites_colors[a]}" *ngIf="a > 8">{{
                            descripArray(ame.title)}}</span>
                        </span>
                      </div>
                    </div>

                    <!-- <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                                    <ul class="nav navbar-nav pull-left">
                                      <li>
                                        <a class="totlo">
                                          <img src="assets/img/newstaticicons/rates-packages/{{ darkMode ? 'darkmode' : 'lightmode' }}/bed.svg" [ngStyle]="darkMode ? {  margin: '-10px 5px -5px 0px',  height: '40px'  }   : {}" style="margin: -10px 5px -5px 0px; height: 40px" />
                                          <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                                            <p style="margin: 0px">
                                              {{r.room?.bedtype.name}} -->


                    <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                      <ul class="nav navbar-nav pull-left">
                        <li>
                          <a class="totlo">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{ darkMode ? 'darkmode' : 'lightmode' }}/bed.svg"
                              [ngStyle]="darkMode ? {  margin: '-10px 5px -5px 0px',  height: '40px'  }   : {}"
                              style="margin: -10px 5px -5px 0px; height: 40px" />
                            <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{r.room?.bedtype.name}}

                              </p>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a class="totlo">
                            <img src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/sleep.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" />
                            <span class="tooltiptext" [ngClass]="darkMode ?
                                                                  'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{
                                "rates_and_packages.list.sleeps" | translate
                                }}
                                {{
                                r.room?.adults +
                                r.room?.children +
                                r.room?.infants
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a class="totlo">
                            <img src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/area.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" />
                            <span class="tooltiptext" [ngClass]="darkMode ?
                                                                  'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{r.room.room_size }}
                                {{
                                r.room?.room_size == "meters"
                                ? "m"
                                : "Sq ft"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="r?.room?.pets != ''">
                          <a class="totlo">
                            <img *ngIf="r?.room?.pets == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/pets.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" class="image-adj" style="height: 20px" />
                            <img *ngIf="r?.room?.pets == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/nopets.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" class="image-adj" style="height: 20px" />
                            <span class="tooltiptext" [ngClass]="darkMode ?
                                                                  'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{
                                r?.room?.pets == "true"
                                ? "Allowed"
                                : "Not Allowed"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="r?.room?.wheelchairaccessibility !=
                                                              ''">
                          <a class="totlo">
                            <img *ngIf="r?.room?.wheelchairaccessibility
                                                                  ==
                                                                  'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/wheel.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" alt="" class="image-adj" style="height:
                                                                  25px; margin-top: -5px" />
                            <img *ngIf="r?.room?.wheelchairaccessibility
                                                                  ==
                                                                  'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/nowheel.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" alt="" class="image-adj" style="height:
                                                                  25px; margin-top: -5px ;" />
                            <span class="tooltiptext" [ngClass]="darkMode ?
                                                                  'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{
                                r?.room?.wheelchairaccessibility ==
                                "true"
                                ? "Available"
                                : "Not Available"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="r?.room?.smoking != ''">
                          <a class="totlo">
                            <img *ngIf="r?.room?.smoking == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/smoke.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" class="image-adj" style="height: 20px;
                                                                  margin-top: 0px" />
                            <img *ngIf="r?.room?.smoking == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                                  darkMode ? 'darkmode' : 'lightmode'
                                                                  }}/nosmoke.svg" [ngStyle]="darkMode
                                                                  ? {
                                                                  margin: '-10px 0px -5px 0px',
                                                                  height: '40px'
                                                                  }
                                                                  : {}" class="image-adj" style="height: 20px;
                                                                  margin-top: 0px" />
                            <span class="tooltiptext" [ngClass]="darkMode ?
                                                                  'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{
                                r?.room?.smoking == "true"
                                ? "Allowed"
                                : "Not Allowed"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="season-box" *ngFor="let r of rp.season_room_types; let x= index"
                  [ngClass]="darkMode ? 'card-dark-seasone' : 'card-null '">
                  <div class="new" id="n3" [ngClass]="darkMode ?
                                                'card-dark-seasone-new' : ' card-null '">
                    <div class="top-cal">
                      <div class="left">
                        <a class="totlo">
                          <h5 class="season-roomname"
                            [ngStyle]="{ color: darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                            {{r.room.roomtype.name }}
                          </h5>
                          <!-- <span class="tooltiptext"  [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style="top: 10%;">

                                      {{ r.room.display_name}}
                                    </span> -->
                        </a>
                      </div>
                      <div class="right">
                        <div class="weekprice"
                          [ngStyle]="{ color: darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code            }">
                          {{current_currency}}{{ r.week_days_rate}}
                        </div>
                      </div>
                    </div>
                    <div class="days-1">
                      <span class="days-name" *ngFor="let wn of
                                                    checkDays(r.week_day)" [ngStyle]="{ opacity: wn.status ?
                                                    '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                    </div>
                    <hr class="myhr" />
                    <div class="days-1">
                      <span class="days-name" *ngFor="let wn of
                                                    checkDays(r.weekend_day)" [ngStyle]="{ opacity:
                                                    wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                    </div>
                    <div class="weekprice" style="margin-top: 5px"
                      [ngStyle]="{
                                                  color: darkMode  ? r.room.roomtype.dark_mode  : r.room.roomtype.color_code }">
                      {{current_currency}}{{ r.weekend_days_rate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-2" style="width: 14.666667%; padding-left: 0px;">
                <div style="height: 130px; width: 100%;" class="season-box"
                  [ngClass]="darkMode ? 'card-dark-seasone' : 'card-null '">
                  <span *ngIf="rp.season_room_types.length >= 5" style="position:
                                absolute; left: -10px; top: 7px" (click)="scrollRights(did)">
                    <img src="assets/img/manage_rates/arrowright.png" class="btn-hov"
                      [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="cursor: pointer; border-radius: 10px" />
                  </span>
                  <div class="divv" style="display: flex; justify-content: end;">
                    <div class="push-div " style="padding-top: 0px;">
                      <div class="">
                        <a class="btn btn-channel push-btn btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"
                          (click)="seasonPush(rp.id)">
                          {{"rates_and_packages.menu.push" | translate }}
                        </a>
                      </div>

                      <!-- <a class="btn btn-channel push-btn btn-hov" [ngClass]="darkMode
                                    && rp.push_status == 'pushed'
                                    ? 'btn-hov_dark live-btn'
                                    : 'btn-hov live-btn'" *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                                    {{ "rates_and_packages.menu.live" | translate }}
                                  </a> -->
                    </div>
                    <div class="push-div" style="padding-top: 0px; padding-right:0px;">
                      <a class="btn btn-channel push-btn btn-hov" style="padding-right: 5px;"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" *ngIf="rp.push_status == 'pending'"
                        (click)="seasonPush(rp.id)">{{
                        "rates_and_packages.menu.push" |
                        translate }}</a>
                      <a class="btn btn-channel push-btn btn-hov" style="padding-right: 0px; " [ngClass]="darkMode
                                    && rp.push_status == 'pushed'
                                    ? 'btn-hov_dark live-btn'
                                    : 'btn-hov live-btn'" *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                        {{ "rates_and_packages.menu.live" | translate }}
                      </a>
                    </div>
                  </div>

                  <!-- <span class="breakfast" *ngIf="
                                  package.breakfast_include == 'Paid' ||  package.breakfast_include == 'Exclude '   ">
                                <i class="fa fa-utensierls"></i> {{ "rates_and_packages.menu.BreakfastNotIncluded" | translate }}

                              </span> -->

                  <div class="edit-delete" style="right: 5px; bottom: 5px; ">

                    <span class="breakfast">
                      <i class="fa fa-utensils"></i>
                      {{ "rates_and_packages.menu.BreakfastIncluded" | translate }}
                    </span>


                    <a (click)="duplicate_rateplan(rp)" style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small" [ngClass]="darkMode ?
                                      'btn-hov-small_dark' : 'btn-hov-small'" width="25px" height="25px"
                        style="border-radius: 10px; cursor: pointer;" />
                    </a>
                    <a style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/edit.svg" class="btn-hov"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" width="25px" height="25px"
                        (click)="editPlan(rp)" (click)="getHeight()" style="cursor: pointer; border-radius: 10px" />
                    </a>
                    <a>
                      <img data-target="#confirmationModal" data-toggle="modal"
                        src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small" [ngClass]="darkMode ?
                                      'btn-hov-small_dark' : 'btn-hov-small'" width="25px" height="25px" width="25px"
                        height="25px" (click)="deleteSeason(rp.season_name,
                                      rp.id, rp.created_at)" style="cursor: pointer;
                                      border-radius: 10px" />
                    </a>
                  </div>

                </div>
              </div>
            </div>

            <div class="div2" *ngIf="rp.season_room_types.length >= 2">
              <div class="col-md-9 seasoneRoomCol">
                <div class="box-flex {{ 'cla' + did }}" id="{{ 'id' + did }}" (click)="dragFunc(did)">
                  <div class="season-box" *ngFor="let r of rp.season_room_types; let x = index"
                    [ngClass]="darkMode ? 'card-dark-seasone' : ' card-null '">
                    <div class="new" id="n3" [ngClass]="
                                              darkMode ? 'card-dark-seasone-new' : ' card-null '
                                            ">
                      <div class="top-cal">
                        <div class="left">
                          <a class="totlo">
                            <h5 class="season-roomname" [ngStyle]="{
                                                    color: darkMode
                                                      ? r.room.roomtype.dark_mode
                                                      : r.room.roomtype.color_code
                                                  }">
                              {{r.room.roomtype.name }}
                            </h5>
                            <!-- <span class="tooltiptext" *ngIf="r.room.display_name" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style="top: 10%;">

                                                                          {{ r.room.display_name}}
                                                                        </span> -->
                          </a>
                        </div>
                        <div class="right">
                          <div class="weekprice" [ngStyle]="{
                                                    color: darkMode
                                                      ? r.room.roomtype.dark_mode
                                                      : r.room.roomtype.color_code
                                                  }">
                            {{current_currency}}{{ r.week_days_rate }}
                          </div>
                        </div>
                      </div>
                      <div class="days-1">
                        <span class="days-name" *ngFor="let wn of checkDays(r.week_day)"
                          [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                      </div>
                      <hr class="myhr" />
                      <div class="days-1">
                        <span class="days-name" *ngFor="let wn of checkDays(r.weekend_day)"
                          [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                      </div>
                      <div class="weekprice" style="margin-top: 5px" [ngStyle]="{
                                                color: darkMode
                                                  ? r.room.roomtype.dark_mode
                                                  : r.room.roomtype.color_code
                                              }">
                        {{current_currency}}{{ r.weekend_days_rate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1 seasoneActionCol">
                <div style="height: 130px">
                  <span *ngIf="rp.season_room_types.length >= 5" style="position: absolute; left: -10px; top: 7px"
                    (click)="scrollRights(did)">
                    <img src="assets/img/manage_rates/arrowright.png" class="btn-hov"
                      [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="cursor: pointer; border-radius: 10px" />
                  </span>
                  <div class="push-div" style="padding-top: 5px">
                    <a class="btn btn-channel push-btn btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"
                      *ngIf="rp.push_status == 'pending'" (click)="seasonPush(rp.id)">{{ "rates_and_packages.menu.push"
                      |
                      translate }}</a>
                    <a class="btn btn-channel push-btn btn-hov" [ngClass]="
                                              darkMode && rp.push_status == 'pushed'
                                                ? 'btn-hov_dark live-btn'
                                                : 'btn-hov live-btn'
                                            " *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                      {{ "rates_and_packages.menu.live" | translate }}
                    </a>
                  </div>

                  <div class="edit-delete">
                    <a (click)="duplicate_rateplan(rp)" style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small" [ngClass]="
                                                darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
                                              " width="25px" height="25px" style="border-radius: 10px" />
                    </a>
                    <a style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/edit.svg " class="btn-hov"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" width="25px" height="25px"
                        (click)="editPlan(rp)" (click)="getHeight()" style="cursor: pointer; border-radius: 10px" />
                    </a>
                    <a>
                      <img data-target="#confirmationModal" data-toggle="modal"
                        src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small" [ngClass]="
                                                darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
                                              " width="25px" height="25px" width="25px" height="25px" (click)="
                                                deleteSeason(rp.season_name, rp.id, rp.created_at)
                                              " style="cursor: pointer; border-radius: 10px" />
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        <div class="details" [ngClass]="darkMode ? 'card-darkS' : ' card-null '">
          <div>
            <span class="evt-name {{ colors2[did % 7] }}">
              {{ "rates_and_packages.menu.Promotions" | translate }}
            </span>
            <i class="fa fa-sort-amount-down {{ colors2[did % 7] }}"
              style="margin-left: 10px; font-size: 16px; cursor: pointer" id="sortArrow{{ did }}"
              (click)="sortPromotions('sortArrow' + did, did)"></i>
            <!-- tool tip  starts-->
            <!-- style="top: 31%; right: 2.5%;" -->
            <span class="ispan">
              <i class="fas fa-info-circle i" [ngClass]="darkMode ? 'i_dark' : 'i'">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width: 155px; top: 190px; left: 180px; padding: 7px">{{ "rates_and_packages.menu.Promotions_i"
                  | translate }}</span>
              </i>
            </span>

            <!-- tool tip ends -->
            <span style="float: right; margin-top: -2px" data-toggle="collapse" attr.data-target="#collapson{{ did }}">
              <img src="assets/img/reports/{{
                  darkMode ? 'button_down_dark' : 'button_down'
                }}.svg" class="btn-hov rotate180" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="
                  width: 18px;
                  cursor: pointer;
                  transition: 0.2s all ease-in-out;
                " id="dropDownCaret{{ did }}" (click)="dropChnge('dropDownCaret' + did)" />
            </span>
          </div>

          <div id="collapson{{ did }}" class="collapse">
            <div class="tabs">
              <div (click)="selectedTab = 'OTAs'">
                <div style="width: 50%; margin: 0 auto" [ngStyle]="{
                    'border-bottom': selectedTab == 'OTAs' ? '3px solid' : ''
                  }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">

                  {{ "rates_and_packages.menu.otas" | translate }}
                </div>
              </div>
              <div (click)="selectedTab = 'Channel'">
                <div style="width: 50%; margin: 0 auto" [ngStyle]="{
                    'border-bottom': selectedTab == 'Channel' ? '3px solid' : ''
                  }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">

                  {{ "rates_and_packages.menu.channels" | translate }}
                </div>
              </div>
              <div (click)="selectedTab = 'Loyalty'">
                <div style="width: 50%; margin: 0 auto" [ngStyle]="{
                    'border-bottom': selectedTab == 'Loyalty' ? '3px solid' : ''
                  }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">

                  {{ "rates_and_packages.menu.Loyalty" | translate }}
                </div>
              </div>
            </div>

            <div *ngIf="showSelectedPackage(rp.packages).length == 0"
              style="font-size: 16px; font-weight: bold; text-align: center">
              {{ "rates_and_packages.menu.no_package_found" | translate }}
              <br />{{ "rates_and_packages.menu.please_create_events" | translate }}
            </div>
            <!-- Season Events -->
            <div class="card" *ngFor="
                let package of showSelectedPackage(rp.packages) | search : 'package_name,roomttype.name,room_rate,is_linked,room_id' : param;
                let i = index
              " style="padding-bottom: 0px; padding-right: 4px; padding-top: 5px"
              [ngClass]="darkMode ? 'card-darkP' : ' card-null '" id="package_id_{{package.id}}">
              <div class="row">
                <div class="col-md-3 pr-10 packageCol1">
                  <div style="height: 65px">
                    <h1 (click)="
                        service.is_preview = true;
                        edit_package(package.id, true);
                        selectpercentage(package.percentage)
                      " data-target="#settings" data-toggle="modal"
                      class="{{ colors2[did % 7] }} pkg_title hover_shade" style="
                        text-transform: capitalize;
                        font-size: 18px;
                        line-height: 1 !important;
                        transform: translateY(-2px);
                      ">
                      {{ package.package_name }}
                    </h1>
                  </div>

                  <p class="linked" id="linked7" [ngStyle]="{
                      'margin-top':
                        package?.package_type != 'otas' ? '7%' : '18%'
                    }" [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '">
                    {{ dateFunc(package.date_to) | date: "MMM dd" | uppercase }} | {{ dateFunc(package.date_from) |
                    date: "MMM dd" | uppercase }}
                  </p>

                  <h4 class="linked" id="linked8"
                    *ngIf="getOTAs(package).length == 0 &&  package?.package_type != 'otas'      "
                    [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '"
                    (click)="openPackageChannels(getOTAs(package), package.id)">
                    {{ package.package_type == "OTAs" ? "No OTAs Connected" : package.package_type == "Channel" ? "No Channels Connected" : package.package_type == "Loyalty" ? "No Loyalties Connected" : "Not Connected"
                    }}
                  </h4>

                  <!-- "rates_and_packages.list.no_channels_connected" | translate -->
                  <div #logoContainer class="icon-group-{{ package.id }} ico1 channelsLine"
                    *ngIf="package?.package_type != 'otas'">
                    <ul #logoList class="nav nav-pills otas-list">
                      <li *ngFor="
                          let logo of getOTAs(package) | slice: 0:logos_quantity;
                          let logosi = index
                        " class="sp-{{ logo.id }}" (click)="openPackageChannels(getOTAs(package), package.id)">
                        <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                            logo.channel.image
                          }}" style="height: 32px" />
                      </li>
                      <li *ngIf="package.otas.length > logos_quantity" class="pull-right">
                        <img class="dropdown-toggle last-img" src="assets/img/manage_rates/dots.png" (click)="
                            openPackageChannels(package.otas, package.id)
                          " class="btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov_dark'" style="
                            height: 32px;
                            border-radius: 100px;
                            cursor: pointer;
                          " />
                        <ul class="dropdown-menu" style="right: auto">
                          <li *ngFor="
                              let logo of getOTAs(package) | slice: logos_quantity
                            " class="sp-{{ logo.id }} btn-hov">
                            <a href="#">
                              <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                                  logo.channel.image
                                }}" style="height: 32px" />
                            </a>
                          </li>

                          <li *ngFor="
                              let logo of filterChannelsByPackage(package) | slice: logos_quantity
                            " class="sp-{{ logo.id }} btn-hov">
                            <a href="#">
                              <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                                  logo.channel.image
                                }}" style="height: 32px" />
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-5 pl-10 pr-10 packageCol2">
                  <div class="row">
                    <div class="img-box col-md-4 pr-10">
                      <a (click)="service.is_preview = true; edit_package(package.id, true);   selectpercentage(package.percentage)
                        " data-target="#settings" data-toggle="modal">
                        <img class="img-responsive hover_shade" style="
                            border-radius: 10px;
                            width: 100%;
                            height: 133px;
                            cursor: pointer;
                          " src="{{
                            (package.room?.images)[0]?.image
                              ? api.imgUrl + package.room.images[0]?.image
                              : 'assets/img/other/no-room.jpg'
                          }}" />
                      </a>
                    </div>
                    <div class="text-box col-md-8 pl-10">
                      <a class="totlo">
                        <span (click)="service.is_preview = true; edit_package(package.id, true); selectpercentage(package.percentage)
                        " data-target="#settings" data-toggle="modal"
                          class="description {{ colors2[did % 7] }} hover_shade"
                          style="cursor: pointer; text-transform: capitalize">
                          {{ package.roomttype.name }}</span>
                        <!-- <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style="top: 10%;" *ngIf="package.roomttype.display_name == null"  >

                          {{package.roomttype.display_name}}
                        </span> -->
                      </a>
                      <span *ngIf="package.is_duplicated == '1'" class="duplicate_text">
                        {{ "rates_and_packages.list.duplicated" | translate }}
                      </span>
                      <p (click)="  service.is_preview = true;
                          edit_package(package.id, true);
                          selectpercentage(package.percentage)
                        " data-target="#settings" data-toggle="modal" class="desc_text hover_shade"
                        *ngIf="package.room != null" [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '">
                        {{ package.room.description }}
                      </p>
                      <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                        <ul class="nav navbar-nav pull-left">
                          <li>
                            <a class="totlo">
                              <img src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/bed.svg" [ngStyle]=" darkMode ? {
                                        margin: '-10px 5px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " style="margin: -10px 5px -5px 0px; height: 40px" />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{ package.room?.bedtype.name }}
                                </p>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a class="totlo">
                              <img
                                src="assets/img/newstaticicons/rates-packages/{{ darkMode ? 'darkmode' : 'lightmode' }}/sleep.svg"
                                [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{
                                  "rates_and_packages.list.sleeps" | translate
                                  }}
                                  {{
                                  package.room?.adults +
                                  package.room?.children +
                                  package.room?.infants
                                  }}
                                </p>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a class="totlo">
                              <img src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/area.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{ package.room?.room_size }}
                                  {{
                                  package.room?.room_size_type == "meters"
                                  ? "m"
                                  : "Sq ft"
                                  }}
                                </p>
                              </span>
                            </a>
                          </li>
                          <li *ngIf="package?.room?.pets != ''">
                            <a class="totlo">
                              <img *ngIf="package?.room?.pets == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/pets.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " class="image-adj" style="height: 20px" />
                              <img *ngIf="package?.room?.pets == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/nopets.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " class="image-adj" style="height: 20px" />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{
                                  package?.room?.pets == "true"
                                  ? "Allowed"
                                  : "Not Allowed"
                                  }}
                                </p>
                              </span>
                            </a>
                          </li>
                          <li *ngIf="package?.room?.wheelchairaccessibility != ''">
                            <a class="totlo">
                              <img *ngIf="
                                  package?.room?.wheelchairaccessibility ==
                                  'true'
                                " src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/wheel.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " alt="" class="image-adj" style="height: 25px; margin-top: -5px" />
                              <img *ngIf="
                                  package?.room?.wheelchairaccessibility ==
                                  'false'
                                " src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/nowheel.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " alt="" class="image-adj" style="height: 25px; margin-top: -5px ;" />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{
                                  package?.room?.wheelchairaccessibility ==
                                  "true"
                                  ? "Available"
                                  : "Not Available"
                                  }}
                                </p>
                              </span>
                            </a>
                          </li>
                          <li *ngIf="package?.room?.smoking != ''">
                            <a class="totlo">
                              <img *ngIf="package?.room?.smoking == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/smoke.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " class="image-adj" style="height: 20px; margin-top: 0px" />
                              <img *ngIf="package?.room?.smoking == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                  darkMode ? 'darkmode' : 'lightmode'
                                }}/nosmoke.svg" [ngStyle]="
                                  darkMode
                                    ? {
                                        margin: '-10px 0px -5px 0px',
                                        height: '40px'
                                      }
                                    : {}
                                " class="image-adj" style="height: 20px; margin-top: 0px" />
                              <span class="tooltiptext" [ngClass]="
                                  darkMode ? 'tooltiptextdark' : 'tooltiptext'
                                ">
                                <p style="margin: 0px">
                                  {{
                                  package?.room?.smoking == "true"
                                  ? "Allowed"
                                  : "Not Allowed"
                                  }}
                                </p>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 packageCol3">
                  <div class="row">
                    <div class="season-box2 color-change" style="
                        transform: translateY(0px) !important;
                        height: 133px;
                      " [ngClass]="
                        darkMode ? 'card-darkPackageBox' : ' card-null '
                      ">
                      <div class="new" id="n1" style="height: 100%">
                        <div style="
                            display: flex;
                            align-items: center;
                            height: 100%;
                          ">
                          <div style="height: 100%">
                            <img src="assets/img/newstaticicons/rates-packages/promotion.svg" width="90px"
                              alt="promotion image" class="promoTionImg" />
                            <div class="pkg-off" *ngIf="package?.percentage != 0">
                              {{ package?.percentage }}% {{ "rates_and_packages.link_channel.off" | translate }}
                            </div>
                            <!--<div style="font-size: 16px;color: #707070;">
																<b>{{((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)), "days"))}}</b>
																{{'rates_and_packages.link_channel.nights' | translate}}
																<img src="assets/img/newstaticicons/rates-packages/night.svg" style="width: 15px; margin-left: 5px;" />
															</div> -->
                            <div style="margin-top: 7px">
                              <div style="font-size: 14px; color: #707070">
                                <b> {{ "rates_and_packages.menu.PerNight" | translate }}

                                </b>

                                <span class="price {{ colors[i % 7] }}" *ngIf="
                                    dateFunc2(package.date_from).diff(
                                      dateFunc2(package.date_to),
                                      'days'
                                    ) >= 1
                                  " style="font-size: 16px !important">
                                  {{current_currency}} {{
                                  roundPricee(
                                  package?.sum_price /
                                  dateFunc2(package.date_from).diff(
                                  dateFunc2(package.date_to),
                                  "days"
                                  )
                                  )
                                  }}
                                </span>

                                <span class="price {{ colors[i % 7] }}" *ngIf="
                                    dateFunc2(package.date_from).diff(
                                      dateFunc2(package.date_to),
                                      'days'
                                    ) == 0
                                  " style="font-size: 16px !important">
                                  {{current_currency}} {{ package?.sum_price }}
                                </span>

                                <!-- <span class="price {{ colors[i % 7] }}"
																	*ngIf="((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)),'days')) >= 1"
																	style="font-size: 16px !important;">
																	${{ roundPricee(package?.sum_price /
																	((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)),"days")))}}

																</span> -->

                                <!-- <span class="price {{ colors[i % 7] }}" *ngIf="((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)),'days'))  == 0"
																	style="font-size: 16px !important;">
																	${{  package?.sum_price }}

																</span>

																<div style="font-size: 16px;color: #707070;">
																	<b>{{((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)),
																		"days"))}}</b>
																	{{'rates_and_packages.link_channel.nights' | translate}}
																	{{'rates_and_packages.link_channel.nights' | translate}}
																	<img src="assets/img/newstaticicons/rates-packages/night.svg"
																		style="width: 20px" />
																</div> -->
                              </div>

                              <span class="breakfast" *ngIf="package.breakfast_include == 'Yes'">
                                <i class="fa fa-utensils"></i>
                                {{ "rates_and_packages.menu.BreakfastIncluded" | translate }}

                              </span>

                              <span class="breakfast" *ngIf="
                                  package.breakfast_include == 'Paid' ||  package.breakfast_include == 'Exclude '   ">
                                <i class="fa fa-utensils"></i> {{ "rates_and_packages.menu.BreakfastNotIncluded" |
                                translate }}

                              </span>
                            </div>
                          </div>

                          <div class="DicsDiv">
                            <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="" *ngIf="
                                getRoundPrice(
                                  package.sum_price,
                                  package.seasonRoomPrice
                                ).length > 0 &&
                                getRoundPrice(
                                  package.sum_price,
                                  package.seasonRoomPrice
                                ).charAt(0) !== '-'
                              " />
                            <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
                              {{ getRoundPrice( package.sum_price, package.seasonRoomPrice ) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="season-box color-change" style="
                        width: 47%;
                        transform: translateY(0px) !important;
                        height: 133px;
                      " [ngClass]="
                        darkMode ? 'card-darkPackageBox' : ' card-null '
                      ">
                      <div class="new" id="n5" style="height: 120px">
                        <div class="rate-up">
                          <div class="rate-1 hei1" style="transform: translateY(2px)">
                            <span class="total pkg_title {{ colors[i % 7] }}" style="
                                font-size: 14px;
                                font-weight: bold;
                                text-transform: uppercase;
                                font-family: robotoblack;
                              ">
                              {{ "rates_and_packages.menu.total" | translate }}
                            </span>
                          </div>

                          <div style="
                              font-size: 14px;
                              color: #707070;
                              margin-bottom: 5px;
                            ">
                            <b>{{
                              dateFunc2(package.date_from).diff(
                              dateFunc2(package.date_to),
                              "days"
                              )
                              }}
                            </b>
                            <!-- {{'rates_and_packages.link_channel.nights' | translate}} -->
                            <img src="assets/img/newstaticicons/rates-packages/night.svg"
                              style="width: 15px; margin-left: 5px" />
                          </div>

                          <div class="rate-2 hei2">
                            <span class="tax_cl" style="
                                text-decoration: line-through 1px black;
                                font-weight: bold;
                              "> {{current_currency}}{{ package?.seasonRoomPrice }}
                            </span>
                            <span class="price {{ colors[i % 7] }}">
                              {{current_currency}} {{package?.sum_price }}
                            </span>
                            <div class="tax_cl">
                              {{ "rates_and_packages.menu.includeTax" | translate }}
                              <!-- Includes tax -->
                            </div>
                          </div>
                        </div>
                        <div class="rate-down">
                          <div class="rate-3" *ngIf="package?.package_type != 'Loyalty'">
                            <a class="btn btn-channel link_to_ota_btn btn-hov"
                              [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" (click)="packagePush(package.id)"
                              *ngIf="package.push_status == 'pending'">{{
                              "rates_and_packages.menu.push" | translate
                              }}</a>
                            <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="
                                darkMode && package.push_status == 'pushed'
                                  ? 'btn-hov_dark live-btn'
                                  : 'btn-hov live-btn'
                              " *ngIf="package.push_status == 'pushed'" style="cursor: default">{{
                              "rates_and_packages.menu.live"
                              | translate
                              }}</a>
                          </div>

                          <div class="rate-3" *ngIf="package?.package_type == 'Loyalty'">
                            <a class="btn btn-channel link_to_ota_btn btn-hov"
                              [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="width: 74px !important"
                              (click)="sendLink(package.id)">
                              Send Link
                            </a>
                          </div>
                          <div class="rate-4">
                            <a (click)="duplicate_package(package.id)">
                              <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small"
                                [ngClass]="
                                  darkMode
                                    ? 'btn-hov-small_dark'
                                    : 'btn-hov-small'
                                " width="25px" height="25px" style="border-radius: 10px" />
                            </a>
                            <a (click)="
                                edit_package(package.id, false);
                                selectpercentage(package.percentage)
                              " data-target="#settings" data-toggle="modal">
                              <img src="assets/img/newstaticicons/rates-packages/edit.svg" class="btn-hov-small"
                                [ngClass]="
                                  darkMode
                                    ? 'btn-hov-small_dark'
                                    : 'btn-hov-small'
                                " width="25px" height="25px" style="border-radius: 10px" />
                            </a>
                            <a class="" data-target="#confirmationModal" data-toggle="modal" (click)="
                                delete(
                                  package.id,
                                  package.package_name,
                                  package.created_at
                                )
                              ">
                              <img src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small"
                                [ngClass]="
                                  darkMode
                                    ? 'btn-hov-small_dark'
                                    : 'btn-hov-small'
                                " width="25px" height="25px" style="border-radius: 10px" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Season Events -->
          </div>
        </div>
      </div>
    </div>
    <!-- SEASON End -->
  </div>
</div>

<!-- Package Modal -->
<div id="settings" class="modal" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="modal-body" style="padding-right: 0px" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
        <div class="row dis-flex">
          <div *ngIf="service.is_preview !== true" class="popup-nav popup-nav-padd">
            <ul class="nav flex-column" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <li class="nav-item setup_package active" routerLinkActive="active"
                routerLink="/app/rates_packages/setup_package" id="setup_package_open"
                style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-package.svg" style="width: 40px" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ !service.is_edit ? "Setup" : "Edit" }}<br />
                    <strong>
                      <!-- {{"rates_and_packages.pop_up.package" | translate }} -->
                      Promotion
                    </strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/setup_rate"
                id="setup_rate_open" style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/rate-dis.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ !service.is_edit ? "" : "" }}
                    <strong>{{
                      "rates_and_packages.menu.rate_distribution" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/setup_restriction"
                id="setup_restrictions_open" style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-policy.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{ !service.is_edit ?
                    "Setup" : "Edit" }}<br />
                    <strong>{{
                      "rates_and_packages.menu.policy" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <!-- <li class="nav-item" routerLinkActive="active"
								routerLink="/app/rates_packages/promo_allocation" id="setup_restrictions_open"
								style="padding: 26px 20px; margin: 5px 0px;" (click)="getHeight22()">
								<a class="nav-link">
									<img src="assets/img/newstaticicons/rates-packages/promo.svg" />
									<span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark':'txt_color'">{{!service.is_edit ? "" : "" }}
										<strong>{{ "rates_and_packages.menu.promo_allocation" | translate }}</strong>
									</span>
								</a>
							</li> -->
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/link_to_ota"
                id="link_to_ota_open" style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{
                    "rates_and_packages.pop_up.link_to" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.menu.otas" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/link_to_channels"
                id="link_to_ota_open" style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ "rates_and_packages.pop_up.link_to" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.menu.channels" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item cg-btn-blank" routerLinkActive="active"
                routerLink="/app/rates_packages/preview_package" id="preview_package_open"
                style="padding: 30px 20px; margin: 10px 0px" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/search.svg" />
                  <span data-toggle="tootip" title="{{
                      'rates_and_packages.pop_up.preview' | translate
                    }}" class="ellipses txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{
                    "rates_and_packages.pop_up.preview" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.pop_up.package" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="pr-0 pl-0 wid-100" [ngClass]="{ '': !service.is_preview, 'pl-20': service.is_preview }"
            id="col101">
            <div class="tab-content scrollo pd-scroll">
              <div class="tab-pane active" id="packageModal">
                <div class="alert alert-info alert-dismissible show ModalBarBlue" role="alert" id="grab-10"
                  [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '" (mouseover)="dodrag1()"
                  (mouseout)="stopDrag1()">
                  <p *ngIf="
                      !['/app/rates_packages/preview_package'].includes(
                        router.url
                      )
                    " style="transform: translateY(2px)">
                    {{ service.is_edit == true ? "Edit Promotion" : "Create Promotion" }}
                  </p>
                  <p *ngIf="
                      ['/app/rates_packages/preview_package'].includes(
                        router.url
                      )
                    " style="transform: translateY(2px)">
                    {{ "rates_and_packages.link_channel.preview_event" | translate }}
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button" (click)="onClose()">
                    <strong id="strong">&#x2716;</strong>
                  </button>
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Package Modal  End-->

<!-- Season modal -->
<div id="settings2" class="modal" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="modal-body" style="padding-right: 0px" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
        <div class="row dis-flex">
          <div *ngIf="service.is_preview !== true" class="popup-nav popup-nav-padd">
            <ul class="nav flex-column" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <li class="nav-item setup_package active" routerLinkActive="active"
                [routerLink]="[{ outlets: { seasonPanel: 'setup_season' } }]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-package.svg" style="width: 40px" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    <!-- {{ !service.is_edit ?"Setup" : "Edit" }} -->
                    {{ 'rates_and_packages.menu.Setup' | translate }}
                    <br />
                    <strong>
                      <!-- {{ "rates_and_packages.menu.rate_plane" | translate }} -->
                      {{ 'rates_and_packages.menu.plan' | translate }}
                    </strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                  { outlets: { seasonPanel: 'setup_season_rates' } }
                ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/rate-dis.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{ !service.is_edit ? ""
                    : "" }}
                    <strong>{{
                      "rates_and_packages.menu.rate_distribution" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                  { outlets: { seasonPanel: 'setup_season_policy' } }
                ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-policy.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    <!-- {{ !service.is_edit ?"Setup" : "Edit" }} -->
                    {{ "rates_and_packages.menu.Setup" | translate }}
                    <br />
                    <strong>{{
                      "rates_and_packages.menu.policy" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[ { outlets: { seasonPanel: 'setup_season_ota' } }
                ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ "rates_and_packages.menu.Setup" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.menu.otas" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                  { outlets: { seasonPanel: 'setup_season_channel' } }
                ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/select-channel.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{
                    "rates_and_packages.pop_up.link_to" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.menu.channels" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item cg-btn-blank" routerLinkActive="active" [routerLink]="[
                  { outlets: { seasonPanel: 'setup_season_preview' } }
                ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/search.svg" />
                  <span data-toggle="tootip" title="{{
                      'rates_and_packages.pop_up.preview' | translate
                    }}" class="ellipses txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ "rates_and_packages.pop_up.preview" | translate }}<br />
                    <strong>{{
                      "rates_and_packages.menu.rate_plane" | translate
                      }}</strong>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="pr-0 pl-0 wid-100" [ngClass]="{ '': !service.is_preview, 'pl-2': service.is_preview }" id="col10">
            <div class="tab-content scrollo pd-scroll">
              <div class="tab-pane active" id="seasoneModal">
                <div class="alert alert-info alert-dismissible show ModalBarBlue" role="alert" id="grab-10"
                  [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '" (mouseover)="dodrag2()"
                  (mouseout)="stopDrag2()">
                  <p *ngIf="
                      ![
                        '/app/rates_packages/(seasonPanel:setup_season_preview)'
                      ].includes(router.url)
                    " style="transform: translateY(2px)">
                    {{ editPlans === true ? "Edit Rate Plan" : " Create Rate Plan" }}
                  </p>
                  <p *ngIf="
                      [
                        '/app/rates_packages/(seasonPanel:setup_season_preview)'
                      ].includes(router.url)
                    ">
                    {{ "rates_and_packages.link_channel.preview_rate_plan" | translate }}
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button" (click)="onClose()">
                    <strong id="strong">&#x2716;</strong>
                  </button>
                </div>
                <router-outlet name="seasonPanel"></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Season modal End-->

<!-- PROMO Code Modal -->
<!-- <div id="settings3" class="modal fade" role="dialog">
	<div class="modal-dialog modal-dialog-centered modal-lg"> -->
<!-- Modal content-->
<!-- <div class="modal-content" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
			<div class="modal-body promoModal" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
				<div class="row" id="promoForm">
					<form class="form-horizontal back-form" [formGroup]="promo_form" id="FormGetPadd">
						<div class="col-md-12" id="RustPromo">
							<div class="promo-bluebar alert-info" style="margin-bottom: 5px;"
								[ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '" (mouseover)="dodrag3()"
								(mouseout)="stopDrag3()"> {{ "rates_and_packages.promo.create_promo_code" | translate }}
								<button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button">
									<strong id="strong">&#x2716;</strong> </button>
							</div>
							<div class="alert alert-danger breadcrum"
								[ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
								<a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close"
									style="color: white;">{{ "rates_and_packages.promo.add_promo_code" | translate
									}}</a>
							</div>
							<div class="row">
								<div class="col-md-12 ">
									<div class="col-md-6 create-promo-div drag_box"
										style="padding-left: 0px;padding-right:5px;">
										<div class="row">
											<div class="col-md-12">
												<div class="col-md-12 create-promo-div"
													style="padding-left: 0px;padding-right:0px;margin-bottom:5px">
													<div class="margin-fix"
														[ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
														<details #dropo open id="dropo">
															<summary>
																<span class="create-head"
																	[ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
																	<span class="promo-info">
																		<span class="create-head"
																			[ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
																			{{ "rates_and_packages.promo.create" |
																			translate }}
																		</span>
																		<i class="fas fa-info-circle">
																			<span class="tooltiptext"
																				[ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
																				style="    padding: 15px 5px 7px 5px;">
																				<p
																					style="margin-bottom: 0px;margin-top: -10px;">
																					{{
																					"rates_and_packages.promo.reference_promocode"
																					| translate }}
																				</p>
																			</span>
																		</i>
																	</span>
																</span>
																<span class="icon-span">
																	<img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
																		id="PCD" (click)="changeDirection('PCD')"
																		class="icon-img" style="height: 25px;" />
																</span>
															</summary>

															<div class="create-main">
																<div>
																	<div class="create-div">
																		<div class="row "
																			style="padding: 0px 15px;  margin-top: 5px;">
																			<div class="col-md-6"
																				style="padding: 0px 3px 0px 5px;">
																				<div class="border-style color-4 bc1">
																					<input type="text"
																						class="input form-control"
																						placeholder="{{ 'rates_and_packages.link_channel.promo_title' | translate }}"
																						style="outline: none;"
																						[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																						formControlName="promo_name">
																				</div>
																				<div *ngIf="submittedPromo && f.promo_name.errors"
																					class="small text-danger no-margin invalid-feedback">
																					<strong
																						*ngIf="f.promo_name.errors.required">{{
																						'rates_and_packages.link_channel.promo_title_required'
																						| translate }}</strong>
																				</div>
																			</div>
																			<div class="col-md-6"
																				style="padding: 0px 5px 0px 2px;">
																				<div class="border-style color-4 bc3">
																					<input type="text"
																						class="input form-control"
																						placeholder="{{ 'rates_and_packages.link_channel.promo_code' | translate }}"
																						style="outline: none;"
																						[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																						formControlName="promo_code">
																				</div>
																				<div *ngIf="submittedPromo && f.promo_code.errors"
																					class="small text-danger no-margin invalid-feedback">
																					<strong
																						*ngIf="f.promo_code.errors.required">{{
																						'rates_and_packages.link_channel.promo_code_required'
																						| translate }}</strong>
																				</div>
																			</div>
																		</div>
																		<div class="row "
																			style="padding: 0px 15px; margin-top: 5px;">
																			<div class="col-md-6"
																				style="padding: 0px 3px 0px 5px;">
																				<div class="border-style color-4 bc4">
																					<input type="text"
																						class="input form-control"
																						placeholder="{{ 'rates_and_packages.link_channel.set_counter' | translate }}"
																						style="outline: none;"
																						[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																						formControlName="counter">
																				</div>
																				<div *ngIf="submittedPromo && f.counter.errors"
																					class="small text-danger no-margin invalid-feedback">
																					<strong
																						*ngIf="f.counter.errors.required">{{
																						'rates_and_packages.link_channel.set_counter'
																						| translate }}</strong>
																				</div>
																			</div>
																			<div class="col-md-6"
																				style="padding: 0px 5px 0px 2px;">
																				<div class="border-style color-4 bc5">
																					<input type="text"
																						class="input form-control"
																						placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }}"
																						style="outline: none;"
																						[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																						formControlName="discount">
																				</div>
																				<div *ngIf="submittedPromo && f.discount.errors"
																					class="small text-danger no-margin invalid-feedback">
																					<strong
																						*ngIf="f.discount.errors.required">{{
																						'rates_and_packages.link_channel.discount'
																						| translate }}</strong>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</details>
													</div>
												</div>
											</div>
										</div>
										<div *ngFor="let op of oldPromos;let i = index">
											<div class="margin-fix"
												[ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
												*ngIf="i % 2 == 0" style="margin-bottom: 5px;">
												<details open id="prop{{i}}">
													<summary>
														<span class="create-head"
															[ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
															{{op.promo_name}}
														</span>
														<span class="icon-span">
															<img src="assets/img/manage-rooms/promodelete.png"
																class="delete-img" (click)="deletePromo(op.id)" />
															<img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
																id="PSD{{i}}" class="icon-img" style="height: 25px;"
																(click)="changeDirection('PSD'+i)" />
														</span>
													</summary>
													<div class="create-main">
														<div>
															<div class="create-div">
																<div class="row "
																	style="padding: 0px 15px;  margin-top: 5px;">
																	<div class="col-md-6"
																		style="padding: 0px 3px 0px 5px;">
																		<div class="border-style color-4 bc1">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.promo_title' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.promo_name" readonly="true">
																		</div>
																	</div>
																	<div class="col-md-6"
																		style="padding: 0px 5px 0px 2px;">
																		<div class="border-style color-4 bc3">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.promo_code' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.promo_code" readonly="true">
																		</div>
																	</div>
																</div>
																<div class="row "
																	style="padding: 0px 15px; margin-top: 5px;">
																	<div class="col-md-6"
																		style="padding: 0px 3px 0px 5px;">
																		<div class="border-style color-4 bc4">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.set_counter' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.counter" readonly="true">
																		</div>
																	</div>
																	<div class="col-md-6"
																		style="padding: 0px 5px 0px 2px;">
																		<div class="border-style color-4 bc5">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.discount" readonly="true">
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</details>
											</div>
										</div>
									</div>
									<div class="col-md-6 create-promo-div drag_box"
										style="padding-left: 0px;padding-right:0px;">
										<div *ngFor="let op of oldPromos;let i = index">
											<div class="margin-fix"
												[ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
												*ngIf="i % 2 != 0" style="margin-bottom: 5px;">
												<details open id="prop{{i}}">
													<summary> <span class="create-head"
															[ngClass]="darkMode ? 'card-dark-txt' : ' card-null '">
															{{op.promo_name}}
														</span> <span class="icon-span">
															<img src="assets/img/manage-rooms/promodelete.png"
																class="delete-img" (click)="deletePromo(op.id)" />
															<img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
																class="icon-img" id="PSD{{i}}" style="height: 25px;"
																(click)="changeDirection('PSD'+i)" />
														</span> </summary>
													<div class="create-main">
														<div>
															<div class="create-div">
																<div class="row "
																	style="padding: 0px 15px;  margin-top: 5px;">
																	<div class="col-md-6"
																		style="padding: 0px 3px 0px 5px;">
																		<div class="border-style color-4 bc1">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.promo_title' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.promo_name" readonly="true">
																		</div>
																	</div>
																	<div class="col-md-6"
																		style="padding: 0px 5px 0px 2px;">
																		<div class="border-style color-4 bc3">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.promo_code' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.promo_code" readonly="true">
																		</div>
																	</div>
																</div>
																<div class="row "
																	style="padding: 0px 15px; margin-top: 5px;">
																	<div class="col-md-6"
																		style="padding: 0px 3px 0px 5px;">
																		<div class="border-style color-4 bc4">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.set_counter' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.counter" readonly="true">
																		</div>
																	</div>
																	<div class="col-md-6"
																		style="padding: 0px 5px 0px 2px;">
																		<div class="border-style color-4 bc5">
																			<input type="text"
																				class="input form-control"
																				placeholder="{{ 'rates_and_packages.link_channel.discount' | translate }}"
																				style="outline: none;"
																				[ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
																				[value]="op.discount" readonly="true">
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</details>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer" id="ModalFooterP" style="    bottom: -45px;right: 10px;">
				<button class="btn btn-info btn-lg modalbtn" style="border: none;" (click)="submitPromo()"
					[ngClass]="darkMode ? 'btnboredark' : 'btnbore'" (click)="rustP()">{{
					'rates_and_packages.setup_package.save' | translate }}</button>
				<button class="btn btn-danger btn-lg modalbtn" aria-label="Close" data-dismiss="modal" type="button"
					[ngClass]="darkMode ? 'card-dark-top-nav' : 'btnbore-null'">{{
					'rates_and_packages.setup_package.cancel' | translate }}</button>
			</div>
		</div>
	</div>
</div> -->
<!-- PROMO Code Modal END-->

<!-- Channel Modal -->
<div class="modal fade" id="ChannelModal" role="dialog">
  <div class="modal-dialog" style="width: 300px">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="
        darkMode
          ? 'card-dark-modal-body modal-content-dark'
          : ' card-null-null '
      ">
      <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
        [ngClass]="darkMode ? 'card-dark-top-nav ' : ' card-null-null '" (mouseover)="dodragCH()"
        (mouseout)="stopDragCH()">
        CONNECTIVITY
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <strong id="strong">&#x2716;</strong>
        </button>
      </div>
      <div class="mainScollor">
        <div class="scrollor">
          <div class="row" style="padding: 0px 5px 0px 20px">
            <div class="col-md-12" style="padding: 0px; padding-right: 20px">
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'OTAs'">
                <div class="row" style="padding-left: 5px">
                  <div>
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      {{ "rates_and_packages.promo.otas" | translate }}
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllOta($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otpd of packageChannels; let i = index">
                  <div class="row">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otpd?.type == 'External'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/otas/{{
                              darkMode ? 'ota_dark/' + otpd?.image : otpd?.image
                            }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otpd.isBinded" (change)="selectOta($event, otpd?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- *ngIf="this.selectedTab==='Channel'" -->
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'Channel'">
                <div class="row" style="padding-left: 5px">
                  <div>
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      {{ "rates_and_packages.promo.channels" | translate }}
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllChannels($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otob of packageChannels; let i = index">
                  <div class="row" *ngIf="
                      otob.name !== 'Loyalty Program' && otob.name !== 'Walk-in'
                    ">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otob?.type == 'Internal'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/channels/{{ otob?.image }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otob?.isBinded" (change)="selectOta($event, otob?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'Loyalty'">
                <div class="row" style="padding-left: 5px">
                  <div>
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      Loyalty
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllChannels($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otob of packageChannels; let i = index">
                  <div class="row" *ngIf="
                      otob.name === 'Loyalty Program' || otob.name === 'Walk-in'
                    ">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otob?.type == 'Internal'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/channels/{{ otob?.image }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otob?.isBinded" (change)="selectOta($event, otob?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="form-control save-buton" [ngClass]="darkMode ? 'save-btn-dark' : 'save-btn-light'"
        data-dismiss="modal" id="closechannel" (click)="saveOtas()">
        {{ "inventory.promo.save" | translate }}
      </button>
    </div>
  </div>
</div>
<!-- Channel Modal END-->

<!-- Send Link Modal -->
<div class="modal fade" id="SendLinkModal" role="dialog">
  <form [formGroup]="sendLinkdForm" (ngSubmit)="onSendLinkSubmit()">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content" [ngClass]="
          darkMode
            ? 'card-dark-modal-body modal-content-dark'
            : ' card-null-null '
        ">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
          [ngClass]="darkMode ? 'card-dark-top-nav ' : ' card-null-null '" (mouseover)="dodragCH()"
          (mouseout)="stopDragCH()">
          Send loyality promotion Link
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <strong id="strong">&#x2716;</strong>
          </button>
        </div>
        <div class="mainScollor" style="height: 120px !important">
          <div class="scrollor">
            <div style="padding: 0px 10px">
              <div class="border-style" [ngClass]="darkMode ? 'orang-brd' : 'color-5'">
                <input type="text" class="input form-control" placeholder="Enter recipient email" style="outline: none"
                  [ngClass]="darkMode ? 'card-dark-side-end' : 'card-null'" formControlName="email" />
                <div *ngIf="sendL && sf.email.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="sf.email.errors.required">Email Required</strong>
                </div>
              </div>
            </div>

            <div style="padding: 0px 10px; margin-top: 20px !important">
              <div class="border-style" [ngClass]="darkMode ? 'orang-brd' : 'color-6'">
                <input type="number" class="input form-control" placeholder="Enter expiration hours"
                  style="outline: none" [ngClass]="darkMode ? 'card-dark-side-end' : 'card-null'"
                  formControlName="expiry_time" />
                <div *ngIf="sendL && sf.expiry_time.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="sf.expiry_time.errors.required">Expiry Hours Required</strong>
                </div>
                <span class="info2"><i class="fas fa-info-circle i" style="font-size: 15px"
                    [ngClass]="darkMode ? 'i_dark' : 'i'">
                    <span class="tooltiptext2" [ngClass]="darkMode ? 'tool_dark' : 'tooltiptext2'">
                      <p class="small no-margin" style="padding: 0px !important; margin: 0px !important">
                        Limit expiration time for promotion.
                      </p>
                    </span>
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="form-control save-buton" [ngClass]="darkMode ? 'save-btn-dark' : 'save-btn-light'">
          {{ "inventory.promo.save" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
<!-- Send Link Modal END-->