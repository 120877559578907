<div class="row mainheader" style="position: fixed; z-index: 99;background-color: #282B93;">
  <div class="col-md-3 col-sm-3">
      <div class="logo-inline">
          <div class="logo-text"><img class="image-height" src="assets/img/terms_of_use/Asset.png" alt="" ></div>
      </div>
  </div>

  <div class="col-md-6 col-sm-6">
      <div class="heading">

      </div>
  </div>
</div>
<!-- <div class="no_scroll scroll"> -->
<div class="row no_scroll scroll" style="height: 100vh; width: 87.98vw;"  [ngClass]="darkMode ? 'row_dark' : 'null'" >

   <div class="section w-clearfix" (mouseover)="scrollFunction()">
      <div class="div-block-8">

         <div class="div-block-15" style="text-align: center;">
            <img src="assets/img/online_help/channel_manager.png" alt="" class="image-38" id="topimage" /></div>
         <div class="div-block-14"><img src="assets/img/online_help/user_guide.png" alt="" class="image-39" /></div>
      </div>
      <div class="div-block-16">
         <div class="div-block-17">
            <img src="assets/img/online_help/search.png" alt="" class="image-40" /></div>
         <div class="form-block-2 w-form">
            <form id="email-form" name="email-form" data-name="Email Form" class="form-3"><input type="text"
                  maxlength="256" name="email" data-name="Email" placeholder="{{ 'back_office.online_help:.search_for_answer' | translate }}" id="email"
                  class="text-field-2 w-input" /></form>
            <div class="w-form-done">
               <div>{{ 'back_office.online_help:.Thank_u' | translate }}</div>
            </div>
            <div class="w-form-fail">
               <div>{{ 'back_office.online_help:.error' | translate }}</div>
            </div>
         </div>
      </div>
   </div>
   <div class="" id="mainrow" [ngClass]="darkMode ? 'sec-1' : 'section-2'" (scroll)="scrollFunction()">
      <div class="container-3 w-container" >
         <!-- <div class="text-block-10 getting-started table bottom-pd cont">Table of Content</div> -->
         <!-- <div class="w-col w-col-2"></div> -->
         <div class="columns-2 w-row">
            <div class="column-4 w-col w-col-4 panel-group" id="terms_of_use">
               <div class="div-block-12">
                  <div class="html-embed w-embed">
                     <hr>
                  </div>
                  <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                  <div>
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-parent="#terms_of_use" href="main/online_help#gettingstarted"
                                 class="text-block-11">
                                 {{"back_office.online_help:.getting_start" | translate }}
                                 <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                              </a>
                           </div>
                           <div class="html-embed w-embed" >
                              <hr>
                           </div>
                           <!-- <div id="collapse1" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="div-block-12">

                  <!-- <div class="div-block-11"></div> -->
                  <div class="" id="terms_of_use">
                     <div class="">
                        <!-- <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-parent="#terms_of_use" href="main/online_help#serviceagreement"
                                 class="text-block-11">Service Agreement
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div>
                           <div class="html-embed w-embed">
                              <hr>
                            </div>
                           <div id="collapse2" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div> -->
                     </div>
                  </div>
               </div>
               <div class="div-block-12">

                  <!-- <div class="div-block-11"></div> -->
                  <!-- <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse3"
                                 class="text-block-11">Service Agreement
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div>
                           <div class="html-embed w-embed">
                              <hr>
                           </div>
                           <div id="collapse3" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div>
                     </div>
                  </div> -->
               </div>

            </div>
            <div class="w-col w-col-4">
               <div class="div-block-12">
                  <div class="html-embed w-embed">
                     <hr>
                  </div>
                  <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'" ></div>
                  <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-parent="#terms_of_use" href="main/online_help#collapse1"
                                 class="text-block-11">{{"back_office.online_help:.Glossary" | translate }}
                                 <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                              </a>
                           </div>
                           <div class="html-embed w-embed">
                              <hr>
                           </div>
                           <!-- <div id="collapse4" class="text panel-collapse collapse ">
                              <a href="main/online_help#policy4" class="w-dropdown-link">Privacy Policy</a>
                              <a [routerLink] = "['/']"  fragment="policy4"> Privacy Policy</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>



               <div class="div-block-12">

                 <!-- <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                 <div class="" id="terms_of_use">
                    <div class="">
                       <div class="dropdown-4 w-dropdown">
                          <div class="dropdown-toggle-4 w-dropdown-toggle">

                             <a data-parent="#terms_of_use" href="main/online_help#serviceagreement"
                                 class="text-block-11">Service Agreement
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                          </div>
                          <div class="html-embed w-embed">
                             <hr>
                          </div>
                          <div id="collapse2" class="text panel-collapse collapse ">
                             <a href="#" class="w-dropdown-link">Link 1</a>
                             <a href="#" class="w-dropdown-link">Link 2</a>
                             <a href="#" class="w-dropdown-link">Link 3</a>
                          </div>
                       </div>
                    </div>
                 </div> -->
              </div>
           </div>

           <div class="w-col w-col-4">
               <div class="div-block-12">
                  <!-- <div class="html-embed w-embed">
                     <hr>
                  </div> -->
                  <!-- <div class="div-block-11"></div>
                  <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse6"
                                 class="text-block-11">Topics
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div>
                           <div class="html-embed w-embed">
                              <hr>
                           </div>
                           <div id="collapse6" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div>
                     </div> -->


                  <!--   <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                  <div class="" id="terms_of_use">
                    <div class="">
                       <div class="dropdown-4 w-dropdown">
                          <div class="dropdown-toggle-4 w-dropdown-toggle">

                             <a data-parent="#terms_of_use" href="main/online_help#serviceagreement"
                                 class="text-block-11">Service Agreement
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                          </div>
                          <div class="html-embed w-embed">
                             <hr>
                          </div>
                          <div id="collapse2" class="text panel-collapse collapse ">
                             <a href="#" class="w-dropdown-link">Link 1</a>
                             <a href="#" class="w-dropdown-link">Link 2</a>
                             <a href="#" class="w-dropdown-link">Link 3</a>
                          </div>
                       </div>
                    </div> -->
                 </div>

               <div class="div-block-12">

                  <!-- <div class="div-block-11"></div>
                  <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse7"
                                 class="text-block-11">Topics
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div>
                           <div class="html-embed w-embed">
                              <hr>
                           </div>
                           <div id="collapse7" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div>
                     </div> -->


                  </div>

               <div class="div-block-12">

                 <!--  <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                  <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-parent="#terms_of_use" href="main/online_help#policy4"
                                 class="text-block-11">Privacy Policy
                                  <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div> --->
                           <div class="html-embed w-embed">
                              <hr>
                           </div>
                           <!----
                            <div id="collapse8" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div>
                     </div>
                  </div> -->
               </div>
               <div class="div-block-12">

                  <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'" ></div>
                  <div class="" id="terms_of_use">
                     <div class="">
                        <div class="dropdown-4 w-dropdown">
                           <div class="dropdown-toggle-4 w-dropdown-toggle">

                              <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse9"
                                 class="text-block-11">{{"back_office.online_help:.role_&_permission" | translate }}
                                 <div class="icon-4 w-icon-dropdown-toggle"></div>
                              </a>
                           </div>
                           <div class="html-embed hr w-embed">
                              <hr>
                           </div>
                           <div id="collapse9" class="text panel-collapse collapse ">
                              <a href="#" class="w-dropdown-link">Link 1</a>
                              <a href="#" class="w-dropdown-link">Link 2</a>
                              <a href="#" class="w-dropdown-link">Link 3</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="w-col w-col-2"></div>
        </div>
     </div>

     <div class="w-container">
           <div class="container" id="gettingstarted" [ngClass]="darkMode ? 'container_dark' : 'container'">
   <!-- <div class="text-block-10 getting-started" id="gettingstarted">Getting Started </div> -->
   <div class="text-block-12 head-2 colorr"  [ngClass]="darkMode ? 'colorr_dark':'colorr'" > {{"back_office.online_help:.getting_start" | translate }}
   </div>

                  <div class="w-row">
   <div class="text-block-12">1. Lorem ipsem dolol sit amet Consectuers adipsince elit sed diam
   </div>

      <div class="text-block-13 text">it is sometimes known, is dummy text used in laying out print, graphic or
         web designs. The passage is attributed to an unknown typesetter in the 15th century who is
         thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
         specimen book.it is sometimes known, is dummy text used in laying out print, graphic or web
         designs. The passage is attributed to an unknown typesetter in the 15th century who is thought
         to have scrambled.
        </div>

   <div class="column-6 w-col w-col-6">
      <img
         src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
         alt="" class="image-25" />
   </div>
   <div class="column-8 w-col w-col-6">
      <div class="div-block-10">
         <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print,
            graphic or web designs. The passage is attributed to an unknown typesetter in the 15th
            century who is thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et
            Malorum for use in a type specimen book.it is sometimes known, is dummy text used in laying
            out print, graphic or web designs.print, graphic or web designs.text used in laying out
            print, graphic.it is sometimes known, is dummy text used in laying
            out print, graphic or web designs.print, graphic or web designs.text used in laying out
            print, graphic
            graphic or web designs.print, graphic or web designs.text used in laying out
            print, graphic</div>
      </div><img src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG.png"
         srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-1600.png 1600w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG.png 1919w"
         sizes="(max-width: 767px) 96vw, (max-width: 991px) 354px, 460px" alt="" class="image-26" />
   </div>
      </div>

                       <div class="w-row">
   <!-- <div class="text-block-12">.1 Lorem ipsem dolol sit amet Consectuers adipsince elit sed diam </div>
   <div>
      <div class="text-block-13 text">it is sometimes known, is dummy text used in laying out print, graphic or
         web designs. The passage is attributed to an unknown typesetter in the 15th century who is
         thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
         specimen book.it is sometimes known, is dummy text used in laying out print, graphic or web
         designs. The passage is attributed to an unknown typesetter in the 15th century who is thought
         to have scrambled.</div>
   </div>
</div> -->
<div class="text-block-12">2. Lorem ipsem dolol sit amet Consectuers adipsince elit
   sed diam
  </div>


<div class="text-block-13 text">
   it is sometimes known, is dummy text used in laying out print, graphic or web
   designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
   scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it is
   sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
   attributed to an unknown typesetter in the 15th century who
  </div>

   <div class="column-7 w-col w-col-6"><img
         src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
         srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
         sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 329px, 435px" alt=""
         class="image-27" /></div>
   <div class="w-col w-col-6">
      <div class="text-block-14 text">
         it is sometimes known, is dummy text used in laying out print, graphic or
         web designs. The passage is attributed to an unknown typesetter in the 15th century who is
         thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
         specimen book.it is sometimes known, is dummy text used in laying out print, graphic or web
         designs. The passage is attributed to an unknown typesetter in the 15th century who is thought
         to have scrambled.<br />designs. </div>
   </div>

<div class="text-block-13 text-pd mr-top text">
   it is sometimes known, is dummy text used in laying out print, graphic or
   web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to
   have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it
   is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
   attributed to an unknown typesetter in the 15th century who.
  </div>
                  </div>

            <div class="w-row">
<div class="text-block-12">3. Lorem ipsem dolol sit amet Consectuers adipsince elit sed
   diam
  </div>
<div class="text-block-13 text">it is sometimes known, is dummy text used in laying out print, graphic or web
   designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
   scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it is
   sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
   attributed to an unknown typesetter in the 15th century who.
  </div>

   <div class="column-5 w-col w-col-3 text">
      <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print, graphic or
         web designs. The passage is attributed to an unknown typesetter in the 15th century who is
         thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum.
     </div>
   </div>
   <div class="w-col w-col-3"><img
         src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
         srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
         sizes="(max-width: 767px) 96vw, (max-width: 991px) 167px, 220px" alt="" class="image-28" />
     </div>
   <div class="w-col w-col-3">
      <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print, graphic or
         web designs. The passage is attributed to an unknown typesetter in the 15th century who is
         thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
         specimen book.
      </div>
   </div>
   <div class="w-col w-col-3"><img
         src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
         srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
         sizes="(max-width: 767px) 96vw, (max-width: 991px) 167px, 220px" alt="" class="image-28 image-34" />
   </div>
        </div>
        </div>
     </div>




        <div class="w-container">

     <div class="container "  [ngClass]="darkMode ? 'container_dark darkctn' : 'container light-ctn'" >
        <div class="" id="collapse1" [ngClass]="darkMode ? 'card-darkk' : 'section-2'"></div>
       <div class="WordSection1"   [ngClass]="darkMode ? 'card-dark' : 'null'">

        <div class="text-block-12 head-2 colorr"  [ngClass]="darkMode ? 'colorr_dark':'colorr'" ><span [ngClass]="darkMode ? 'service_s':'null'">{{ 'back_office.online_help:.Glossary' | translate }}</span>
        </div>
        <div data-toggle="collapse"  href="#collapse100" class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
           <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">A</span>
           <img src="assets/img/reports/{{ darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol1"   (click)="chngedrop('imgcol1')">
        </div>

     <div  id="collapse100"  class="text panel-collapse collapse " >
        <p class="MsoNormal" style="margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style="font-size:19.0pt;font-family: sans-serif; color:#232E38" class="heading1">{{ 'back_office.online_help:.Availability' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt"> {{ 'back_office.online_help:.availibility_text' | translate }} </span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif; color:#232E38" class="heading1">
         {{ 'back_office.online_help:.averge_daily_rate'  | translate}}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">{{ 'back_office.online_help:.averge_daily_rate_text'  | translate}}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">

         {{ 'back_office.online_help:.average_length'  | translate}}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt"> {{ 'back_office.online_help:.average_length_text'  | translate}}</span>
   </p>
     </div>

     <div data-toggle="collapse" href="#collapse1001" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1001" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">B</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol2"  (click)="chngedrop('imgcol2')">
     </div>
     <div id="collapse1001" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.bar_rate' | translate }}
             </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">
              {{ 'back_office.online_help:.bar_rate_text' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">Best
             {{ 'back_office.online_help:.best_rate_guarantee' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"> {{ 'back_office.online_help:.best_rate_guarantee_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
             {{ 'back_office.online_help:.Booking_Engine' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"> {{ 'back_office.online_help:.Booking_Engine_text' | translate }}</span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse1002" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1002" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">C</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol3"  (click)="chngedrop('imgcol3')">
     </div>
     <div id="collapse1002" class="text panel-collapse collapse ">

        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
             {{ 'back_office.online_help:.CRS' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">  {{ 'back_office.online_help:.CRS_text' | translate }} </span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.CM' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"> {{ 'back_office.online_help:.CM_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1"> {{ 'back_office.online_help:.Channel_Manager' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"> {{ 'back_office.online_help:.Channel_Manager_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.CTA' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">
              {{ 'back_office.online_help:.CTA_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.CTD' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.CTD_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1"></span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"></span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse1003" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1003" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">D</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol4"  (click)="chngedrop('imgcol4')">
     </div>
     <div id="collapse1003" class="text panel-collapse collapse ">

        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.Device_Asset' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.Device_Asset_text' | translate }}
             </span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.Distribution_Strategy' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.Distribution_Strategy_text' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.DynamicPackaging' | translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">
             {{ 'back_office.online_help:.DynamicPackaging_text' | translate }}
             </span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse1004" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1004" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">E</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol5" (click)="chngedrop('imgcol5')">
     </div>
     <div id="collapse1004" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.Early_Bird' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">
         {{ 'back_office.online_help:.Early_Bird_text' | translate }}
        </span>
   </p>
     </div>

     <div data-toggle="collapse" href="#collapse1005" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1005" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">F</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol6"  data-toggle="collapse" href="#collapse1005" (click)="chngedrop('imgcol6')">
     </div>
     <div id="collapse1005" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.Flash_Sales' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt"> {{ 'back_office.online_help:.Flash_Sales_text' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.Folio' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">{{ 'back_office.online_help:.Foliotext' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.FrontDeskSystem' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">  {{ 'back_office.online_help:.FrontDeskSystemtext' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.FunctionRoomOccupancy' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt"> {{ 'back_office.online_help:.FunctionRoomOccupancytext' | translate }}
        </span>
   </p>
     </div>

     <div data-toggle="collapse" href="#collapse1006" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1006" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">G</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol7" (click)="chngedrop('imgcol7')">
     </div>
     <div id="collapse1006" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.GDS' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.GDStext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.GrossMargins' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.GrossMarginstext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.GOPPAR' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.GOPPARtext' | translate }}</span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse1007" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1007" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">H</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol8" (click)="chngedrop('imgcol8')">
     </div>
     <div id="collapse1007" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.HostedPaymentSystem' | translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">  {{ 'back_office.online_help:.HostedPaymentSystemtext' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">  {{ 'back_office.online_help:.HousekeepingAsset' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">  {{ 'back_office.online_help:.HousekeepingAssettext' | translate }}</span>
   </p>
     </div>

     <div data-toggle="collapse" href="#collapse1008"  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1008" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">I</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol9"  (click)="chngedrop('imgcol9')">
     </div>
     <div id="collapse1008" class="text panel-collapse collapse ">

        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1"> {{ 'back_office.online_help:.Inventory' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.Inventorytext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.Invoice' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.Invoicetext' | translate }}</span>
        </p>
     </div>

     <!-- <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1009" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">J</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol11" data-toggle="collapse" href="#collapse1009" (click)="chngedrop('imgcol11')">
     </div>
     <div id="collapse1009"></div>

     <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10010" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">K</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol22" data-toggle="collapse" href="#collapse10010" (click)="chngedrop('imgcol22')">
     </div>
     <div id="collapse10010"></div> -->

     <div data-toggle="collapse" href="#collapse10011" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10011" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">L</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol23"  (click)="chngedrop('imgcol23')">
     </div>
     <div id="collapse10011" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.LinenAsset' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.LinenAssettext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.L2B' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.L2Btext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.Lose-itrate' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.Lose-itratetext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.LoyaltyProgram' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.LoyaltyProgramtext' | translate }}</span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse10022" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10022" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">M</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol24" (click)="chngedrop('imgcol24')">
     </div>
     <div id="collapse10022" class="text panel-collapse collapse " >
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.ma' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">  {{ 'back_office.online_help:.ma_text' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">  {{ 'back_office.online_help:.maxls' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">{{ 'back_office.online_help:.maxlstext' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.MinLOS' | translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">{{ 'back_office.online_help:.MinLOStext' | translate }}</span>
   </p>
     </div>

     <div  data-toggle="collapse" href="#collapse10033" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10033" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">N</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol25" (click)="chngedrop('imgcol25')">
     </div>
     <div id="collapse10033" class="text panel-collapse collapse ">


        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.netsale' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.netsaletext' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.NightAudit' | translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt">{{ 'back_office.online_help:.NightAudittext'| translate }}</span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse10044" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10044" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">O</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol26"  (click)="chngedrop('imgcol26')">
     </div>
     <div id="collapse10044" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.occupancyRate'| translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt"> {{ 'back_office.online_help:.occupancyRateText'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1"> {{ 'back_office.online_help:.OTA'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt"> {{ 'back_office.online_help:.OTAtext'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.overbooking'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt">{{ 'back_office.online_help:.overbookingtext'| translate }}</span>
   </p>
     </div>

     <div  data-toggle="collapse" href="#collapse10055" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10055" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">P</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol27" (click)="chngedrop('imgcol27')">
     </div>
     <div id="collapse10055" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.package'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt">{{ 'back_office.online_help:.packageText'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.PMG'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt">{{ 'back_office.online_help:.PMGtext'| translate }}</span>
   </p>
     </div>

     <!-- <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10066" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Q</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol28"  data-toggle="collapse" href="#collapse10066" (click)="chngedrop('imgcol28')">
     </div>
     <div id="collapse10066"></div> -->

     <div data-toggle="collapse" href="#collapse10077" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10077" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">R</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol29" (click)="chngedrop('imgcol29')">
     </div>
     <div id="collapse10077" class="text panel-collapse collapse ">

        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.RateFences'| translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt"> {{ 'back_office.online_help:.RateFencesText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.RateParity'| translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt"> {{ 'back_office.online_help:.RateParityText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.Reservation'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">{{ 'back_office.online_help:.ReservationText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.RevenueManagement'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">{{ 'back_office.online_help:.RevenueManagementText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.RevPAR'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">{{ 'back_office.online_help:.RevPARtext'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.RevPOR'| translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt"> {{ 'back_office.online_help:.RevPORtext'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.RoomBlock'| translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">  {{ 'back_office.online_help:.RoomBlockText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">  {{ 'back_office.online_help:.RoomInventory'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">{{ 'back_office.online_help:.RoomInventoryText'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.RoomType'| translate }}</span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
             white"><span class="_textt">{{ 'back_office.online_help:.RoomTypeText'| translate }}</span>
        </p>
     </div>

     <div data-toggle="collapse" href="#collapse10088" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10088" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">S</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol20" (click)="chngedrop('imgcol20')">
     </div>
     <div id="collapse10088" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.ServiceContract'| translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt"> {{ 'back_office.online_help:.ServiceContractText'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
        white"><span class="_textt"> {{ 'back_office.online_help:.ServiceContractEx'| translate }}</span>
   </p>
   <ul type=disc>
      <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
           normal;'><span style='font-size:12.0pt;font-family: sans-serif;' class="heading1">
            {{ 'back_office.online_help:.ServiceContractLione'| translate }}
           </span>
      </li>
      <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
           normal;'><span class="_textt">
            {{ 'back_office.online_help:.ServiceContractLitwo'| translate }}
          </span>
      </li>
      <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
           normal;'><span class="_textt">
            {{ 'back_office.online_help:.ServiceContractLithree'| translate }}
          </span>
      </li>
      <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
           normal;'><span class="_textt">
            {{ 'back_office.online_help:.ServiceContractLifour'| translate }}
          </span>
      </li>
   </ul>
   <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
         {{ 'back_office.online_help:.SystemofRecord'| translate }}
        </span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;"><span class="_textt"> {{ 'back_office.online_help:.SystemofRecordtext'| translate }}</span>
   </p>
     </div>

     <div data-toggle="collapse" href="#collapse10099" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10099" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">T</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol222" (click)="chngedrop('imgcol222')">
     </div>
     <div id="collapse10099" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
             7.5pt;margin-left:0in;line-height:normal;"><span
              style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">
              {{ 'back_office.online_help:.TrevPAR'| translate }}
            </span>
        </p>
        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
             "><span class="_textt"> {{ 'back_office.online_help:.TrevPARtext'| translate }}</span>
        </p>
     </div>

     <!-- <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10000" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">U</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol223" data-toggle="collapse" href="#collapse10000" (click)="chngedrop('imgcol223')">
     </div>
     <div id="collapse10000"></div> -->

     <div  data-toggle="collapse" href="#collapse100121" aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100121" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">V</span>
        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol224" (click)="chngedrop('imgcol224')">
     </div>
     <div id="collapse100121" class="text panel-collapse collapse ">
        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
        7.5pt;margin-left:0in;line-height:normal;"><span
         style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">{{ 'back_office.online_help:.Vacancy'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><span class="_textt">{{ 'back_office.online_help:.VacancyTextone'| translate }}</span>
   </p>
   <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
        "><b><span class="_textt">{{ 'back_office.online_help:.VacancyBoldText'| translate }}</span></b><span class="_textt">{{ 'back_office.online_help:.VacancyTexttwo'| translate }}</span>
   </p>
     </div>

     <!-- <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100123" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">W</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol225"  data-toggle="collapse" href="#collapse100123" (click)="chngedrop('imgcol225')">
     </div>
     <div id="collapse100123"></div>

     <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100124" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">X</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol226" data-toggle="collapse" href="#collapse100124" (click)="chngedrop('imgcol226')">
     </div>
     <div id="collapse100124"></div>

     <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100125" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Y</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol227" data-toggle="collapse" href="#collapse100125" (click)="chngedrop('imgcol227')">
     </div>
     <div id="collapse100125"></div>

     <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100126" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Z</span>
         <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol228" data-toggle="collapse" href="#collapse100126" (click)="chngedrop('imgcol228')">
     </div>
     <div id="collapse100126"></div> -->




  </div>
     </div>
     </div>

</div>
     <!-- <div class="w-container">

        <div class="text-block-12 head-2 color" [ngClass]="darkMode ? 'card-dark' : 'null'" id="policy4"><span  [ngClass]="darkMode ? 'privacy_p':'null'" >P</span>rivacy <span [ngClass]="darkMode ? 'privacy2_p':'null'">P</span>olicy
        </div>
        <div class="policy_content">
           <p class="indent bullet-points bullet-points">
             The purpose of this Privacy Policy is to lay out the terms and conditions governing use of information and data belonging to Platform users and the Customer.
              This Privacy Policy constitutes an annex to and an integral part of the Platform Terms & Conditions signed with the Properties.
          </p>

          <p class="indent bullet-points bullet-points">
             Except for the purposes and within the scope as agreed in the Platform Terms & Conditions signed with the Property, Point Click Integrate shall not disclose to any third party or use without consent of the Property any information which Point Click Integrate receives through the Platform electronically
             (including, name-surname, company, phone number or e-mail address details, any personally-identifiable information about the Property, user, the Customer, invoice or payment option details). Accordingly, Point Click Integrate undertakes to keep confidential information strictly private and confidential,
             consider this requirement a secret-keeping obligation, ensure and sustain confidentiality of such information, and take any measure and show diligence as required to prevent disclosure to public, unauthorized use, or disclosure to any third party, of whole or part of the confidential information.
          </p>

          <p class="indent bullet-points bullet-points">
             In addition to the circumstances as set forth in the Platform Terms & Conditions, such personal information may also be used to contact the Property,
             user or Customer and improve Platform experience of the Property (such as improving existing services, develop new services or offer tailored services)
             as well as performing various statistical assessments, building databases and conducting market research without compromising identifiable information of the user and the Customer.
             Such information may also be processed, retained, or relayed to third parties for the purposes of direct marketing by Point Click Integrate (or any person collaborating with Point Click Integrate, subject to the Platform’s approval),
             and may be used to contact the User to promote applications and services, and deliver notices about maintenance and support activities.
          </p>

          <p class="indent bullet-points bullet-points">
             Point Click Integrate may turn details of the Property’s transactions on the Platform into anonymous data, and retain, process, or relay to its partners, such data for such period as required to use them in statistical assessments,
             performance reviews, marketing campaigns by Point Click Integrate and its business partners, annual and similar reports.
          </p>

          <p class="indent bullet-points bullet-points">
             Point Click Integrate shall also be entitled to disclose the Property information to third parties in cases where:
          </p>

          <div class="cardcolor">
           <div class="headd">

          <p class="indent sub-bullet-points">
             Disclosure is mandatory for performance by Point Click Integrate of its obligations under the Platform Terms & Conditions,
          </p>

          <p class="indent sub-bullet-points">
             User related information is demanded by a competent or judicial authority as part of an investigation or probe duly executed under the relevant regulations,
          </p>

          <p class="indent sub-bullet-points">
             Relay of information is required to protect or assure the security of the Property’s rights.
          </p>

        </div>
     </div>

          <p class="indent bullet-points bullet-points">
             Point Click Integrate identifies and uses user IP addresses if needed to identify system-related issues and swiftly resolve any potential issue with the Platform.
             IP addresses may also be used to draw an overall user profile and gather comprehensive demographic data.
          </p>

          <p class="indent bullet-points bullet-points">
             Point Click Integrate is entitled to transfer any data related to the above-mentioned purposes to its servers which are located in any country of the world except the one the Property is resident
             (such servers may be owned by Point Click Integrate, its affiliates or subcontractors).
          </p>

          <p class="indent bullet-points bullet-points">
             The Platform may contain links to other website and applications. Point Click Integrate is not liable in any way whatsoever for privacy policies or contents thereof.
          </p>

          <p class="indent bullet-points bullet-points">
             Point Click Integrate may amend from time to time this Privacy Policy through publication on the Platform.
             Any amendment to the provisions of this Privacy Policy by Point Click Integrate shall be effective on the date of publication on the Platform.
          </p>

          <p class="indent bullet-points bullet-points">
             The Property agrees, represents and undertakes to ensure that any agreement to which it is a party will comply with this Privacy Policy,
             and to obtain such consents from the Customers as may be required by this Privacy Policy.
          </p>
          </div>
          </div>

          <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'" id="serviceagreement"><span [ngClass]="darkMode ? 'service_s':'null'">S</span>ervice <span [ngClass]="darkMode ? 'service_a':'null'">A</span>greement
        </div>

           <div class="w-container">
              <div class="translation panel-group" id="terms_of_use">
                  <div class="panel panel-default">
                      <div class="abc">
                          <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse1" class="collapsed abc"
                              aria-expanded="false" (click)="arrow_toggle('col_1')">
                              1. Terms of Hosting Service
                              <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
                          </a>
                      </div>
                      <hr class="hr-pd">
                      <div id="servicecollapse1" class="text panel-collapse collapse">
                       <div class="service_content">
                          <p class="indent bullet-points">
                             These Terms govern the principles and procedures that apply to Properties which build a
                             Property website on the Platform in order to use the Service provided by Point Click Integrate by uploading their content on the Property website,
                             as well as the rights and obligations of the parties with regards to hosting.
                          </p>

                          <p class="indent bullet-points">
                             As part of the Service, the Property may use the ready-to-use templates and content offered on the Platform while building the website provided that it complies with the Platform
                             Terms & Conditions. Alternatively, it may purchase an additional Service to upload customized templates and content, developed by or on behalf of the Property, on Point Click Integrate.
                             The Property may also request Point Click Integrate to design a customized website. Once the Property notifies Point Click Integrate of such a request,
                             the parties will separately agree on the terms and conditions governing such website design. Use of the website shall be limited to the scope of the Service.
                             Activities, products and services of the Property that do not fall within the scope of the Service may only be uploaded subject to the consent of Point Click Integrate.
                          </p>

                          <p class="indent bullet-points">
                             The Property shall obtain the domain name of the web site and implement the settings and parameters instructed by Point Click Integrate in order to redirect
                             the domain name to Point Click Integrate. Alternatively, it may request Point Click Integrate to obtain and redirect the domain name by purchasing an additional
                             Point Click Integrate service. In case domain name registration and redirection is Point Click Integrate's responsibility,
                             Point Click Integrate will rent the domain name for a period of 1 (one) year and perform domain name redirection. Unless the Property renews the Service by the end of such 1 (one) year period,
                             Point Click Integrate will not be responsible for renewing relevant domain name rental and will reserve the right to end the Service.
                          </p>

                          <p class="indent bullet-points">
                             The Property may create and translate website content in one of the supported languages. If the Property decides to use the professional translation services provided by Point Click Integrate,
                             the parties will separately agree on the conditions governing such service.
                          </p>

                          <p class="indent bullet-points">
                             Point Click Integrate enjoys no control over the content provided by the Property on the website and all the rights and responsibilities with regards to such content will
                             rest with the Property. The Property will benefit from the Service provided that it publishes its own content, which holds necessary licenses and consents and/or is
                             obtained in accordance with law, on the website that will be developed as part of the Service. In cases where Point Click Integrate has determined that the Property,
                             the Customer or other third persons have or may have published on the website the content that contradicts facts, codes of conduct or laws; or have or may have used content
                             without necessary consents and licenses; and/or that such content violates or may violate the rights of real or legal persons (including personal and intellectual property rights),
                             Point Click Integrate may ask the Property to remove of such content within a certain time limit, personally remove the content and/or suspend the access
                             to the Property website until such content is removed. In case of repeat of such violations,
                             Point Click Integrate may suspend in parts or whole and/or terminate the Service.
                          </p>

                          <p class="indent bullet-points">
                             Under the scope of the Service, Point Click Integrate reserves the right to use its own servers or the servers of its third party business partners and outsourcers.
                             The Property will be responsible for ensuring that the transactions performed and content provided on its website are in accordance with the terms of use and of
                             limitations as prescribed under the Service, as well as for preventing extraordinary overload on the Platform, servers and systems due to such actions. As part of the Service,
                             the Property may not transfer, retransfer or store the materials that may harm the systems. In cases where external service providers end or interrupt their services or where
                             Point Click Integrate replace external service providers, Point Click Integrate will have the right to suspend the Service temporarily,
                             amend the terms and conditions of the Service, and change the physical areas on which the Service is provided.
                          </p>

                          <p class="indent bullet-points">
                             The Property will be solely responsible for taking security, protection and backup measures, including use of encryption technologies,
                             to prevent unauthorized access to, and archiving of, website content.
                          </p>

                          <p class="indent bullet-points">
                             The Property will ensure that Customers’ use of the website complies with the law, Platform Terms & Conditions and the terms specified herein,
                             as well as with any recommendations for use provided to the Property by Point Click Integrate as part of any online or offline user manuals and support materials, and
                             the Property will be solely liable to and loss or damages arising from Customers’ use of the website (either incurred by the Customers or third persons).
                          </p>

                          <p class="indent bullet-points">
                             Point Click Integrate reserves the right to audit compliance with the terms herein, and may request from the Property the information and relevant materials regarding
                             the website content as part of such audit. The Property agrees to not obstruct or interrupt such audits, and to provide any facilities required for such audits.
                             The Property will collaborate with Point Click Integrate for resolution of the problems that may arise from the content or the Property-controlled materials.
                          </p>

                          <p class="indent bullet-points">
                             When and where deemed necessary, Point Click Integrate may perform updates, additions, adjustments and other similar maintenance works on the website,
                             or make modifications on the existing infrastructure of the website to an extent that do not substantially affect the Property’s use of the Service.
                             For the purposes of such actions, the Property agrees to act in accordance with the maintenance
                             terms and conditions requested by Point Click Integrate, and to provide the support required for performance of the actions requested by Point Click Integrate.
                          </p>

                          <p class="indent bullet-points">
                             The fees payable to Point Click Integrate by the Property in consideration of the Service governed by the terms herein will be determined in
                             line with the scope of the Service provided.
                             Point Click Integrate may suspend use of the website in case of any delayed or interrupted payment of such fees.
                          </p>

                          <p class="indent bullet-points">
                             The Property agrees that all the rights, ownership and interests on the software, design and derivative works thereof developed as part of the Service are retained by
                             Point Click Integrate and that the Property is granted a non-assignable and non-exclusive right to use the website that is limited to the scope and term of the contractual
                             relationship between the parties. No provision herein will be construed to transfer relevant rights, ownership and interests either in parts or as a whole to the Property.
                             The Property may not rent, sublicense, resell or share the rights granted herein, and neither will it authorize third persons to use such rights or transfer the same.
                          </p>

                          <p class="indent bullet-points">
                             The Property shall under no circumstances use the trade name, trademark, service mark, logo, domain name etc. of Point Click Integrate
                             (or its subsidiaries) on its website, as part of its marketing materials or in other channels.
                          </p>

                          <p class="indent bullet-points">
                             All the services related to the Property’s website, except customized services developed by Point Click Integrate as described under Article 2,
                             are provided “AS IS” and Point Click Integrate assumes no responsibility or commitment whatsoever with regards to the accuracy, completeness and reliability thereof.
                          </p>

                          </div>
                      </div>
                 </div>

                  <div class="panel panel-default">
                    <div class="abc">
                        <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse2" class="collapsed abc"
                            aria-expanded="false" (click)="arrow_toggle('col_1')">
                            2. Terms of Domain Name Registration and Administration
                            <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
                        </a>
                    </div>
                    <hr class="hr-pd">
                    <div id="servicecollapse2" class="text panel-collapse collapse">
                       <div class="service_content">
                       <p class="indent bullet-points bullet-points">
                          Domain Name Registration and Administration Service refers to the service whereby the Property purchases
                          a domain name for the website through Point Click Integrate, authorizing Point Click Integrate to register and redirect domain names.
                      </p>

                      <p class="indent bullet-points bullet-points">
                       In cases where Domain Name Registration and Administration Service is engaged,
                       Point Click Integrate will rent the domain name for a period of 1 (one) year and perform domain name redirection.
                   </p>

                   <p class="indent bullet-points bullet-points">
                    Unless the Property renews the Domain Name Registration and Administration Service by the end of such 1 (one) year period, Point Click Integrate will not be
                    responsible for renewing relevant domain name rental and will reserve the right to end the Service.
                    Once the Service term for the Property ends, Point Click Integrate will not be responsible for
                    redeeming the domain name in cases where the rights to Point Click Integrate Service, domain name renewal and domain name use are transferred to third parties.
                </p>

                <p class="indent bullet-points bullet-points">
                 As part of the Domain Name Registration and Administration Service, Point Click Integrate is solely responsible for settings and redirections that will allow the website,
                 which will be redirected to relevant domain name,
                 to be published through the Platform. The Property will be responsible for all the domain name settings and redirections to be made for other purposes.
             </p>

             <p class="indent bullet-points bullet-points">
              For the domain name to be registered and administered, Point Click Integrate may use the services of external service providers as part of the Domain Name Registration and Administration Service. Therefore,
              the Property agrees and acknowledges that the data it provides for domain name registration and administration may be disclosed to such service providers.
          </p>

          <p class="indent bullet-points bullet-points">
           Point Click Integrate disclaims any control over the domain name registered and displayed on the Platform, and the content provided through such domain name as part of
           the Domain Name Registration and Administration Service, and all the rights to, and related responsibilities on such domain name and content shall be assumed by the Property.
           The Property will benefit from such service provided that it provides the content that holds necessary licenses and consents and/or is obtained in accordance with law.
           In cases where Point Click Integrate is determined that the Property, the Client or other third persons have or may have published on the website content that contradicts facts,
           codes of conduct or laws or have or may have used the content without necessary consents and licenses; and/or that such content violates or may violate the rights of real or
           legal persons (including personal and intellectual property rights), Point Click Integrate may ask the Property to remove of such content within a certain time limit,
           personally remove the content, discontinue the domain name service and/or suspend the access to the Property website until such content is removed or
           the domain name service is discontinued. In case of repeat of such violations, Point Click Integrate may suspend in parts or whole and/or terminate the Service.
       </p>

       <p class="indent bullet-points bullet-points">
        Point Click Integrate will not be held liable for errors, problems or interruptions that may arise from the modifications made by
        the Property in domain name administrative settings through the Platform, or for the permanent damages that may arise from the
        errors due to the modifications made by external service providers of domain name registration and administration service on their systems.
    </p>
   </div>
  </div>
                 </div>

                <div class="panel panel-default">
                 <div class="abc">
                     <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse3" class="collapsed abc"
                         aria-expanded="false" (click)="arrow_toggle('col_1')">
                         3. Terms of Payment System
                         <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
                     </a>
                 </div>
                 <hr class="hr-pd">
                 <div id="servicecollapse3" class="text panel-collapse collapse">

                    <div class="service_content">

                    <p class="indent bullet-points">
                       By selecting the options provided on the Platform, the Property will be able to receive Customer’ payments via different methods supported by the Platform.
                       Depending on the method selected by the Property, Point Click Integrate will charge the Service fees,
                       which are displayed either on the Platform or on the Website, in consideration of the technical integration services to be executed by Point Click Integrate.
                   </p>
                   <p class="indent bullet-points">
                    Regardless of the payment method selected by the Property, the following rules shall apply in cases where Customers’
                    credit card information is requested to be kept on the Platform:
                   </p>


                 <div class="cardcolor">
                    <div class="head">
                       <div class="service_contentt">

                        <p class="indent sub-bullet-points">The Property will use the credit card information in accordance with law and only for the purposes of collecting
                           and provisioning the fees of the services it provides. It will not use and disclose
                           such information in processes other than those described in the preceding sentence, and not keep the same after the completion of relevant transaction.
                        </p>
                        <p class="indent sub-bullet-points">
                          Point Click Integrate will under no circumstances have access to Customers’ credit card information, and Point Click Integrate will only allow the Property,
                          upon the request of the Property, to host the credit card information on electronic medium.
                        </p>
                        <p class="indent sub-bullet-points">
                          Point Click Integrate does not warrant that the credit card information stored is accurate or updated, nor does it guarantee that it belongs to a real person,
                          it is a real card or it has sufficient amount of balance.
                          Point Click Integrate does not assume any responsibility with regards to the accuracy and up-to-dateness of such data.
                        </p>
                        <p class="indent sub-bullet-points">
                          Storing by the Property such credit card information on the Platform does not fall under the scope of any collection services,
                          and the Property will be responsible for receiving the payment through the credit card, management thereof and transmitting the same to third persons.
                          Point Click Integrate will not be a party to any disputes between the Property and Customers with regards to credit card information.
                        </p>
                        <p class="indent sub-bullet-points">
                          For the purposes of storing credit card information, Point Click Integrate offers a secure storing environment either internally or through an external service provider; however,
                          Point Click Integrate does not warrant the security of the information stored.
                        </p>

                        <p class="indent sub-bullet-points">
                          Point Click Integrate does not store credit card details nor does it share customer details with any 3rd parties.
                        </p>
                        </div>
                    </div>
                </div>

                 <p class="indent bullet-points">
                    The Property will be liable for taxation and invoicing requirements arising from sales of the services provided by the Property.
                    No provision herein will be construed in a way that such liabilities will be assumed by Point Click Integrate.
                </p>

                <p class="indent bullet-points">
                 Point Click Integrate will not act as the intermediary, agency or insurer of the Property in commercial relationships the Property will establish
                 by using the Platform with including but not limited to payment, collection and financial transaction providers, financial institutions,
                 banks and all the other organizations and institutions from which the Property will receive support services for receiving payments and following up collections
                 (“Payment Providers”). Point Click Integrate will be solely responsible for providing the Service under the Agreement.
                 The Property declares and agrees that Point Click Integrate is not a party to the relationship between the Property and Clients and/or Payment Providers,
                 that Point Click Integrate's sole liability is to bring together technically the related parties, and that Point Click Integrate will not be held liable for any
                 contractual or regulatory non-compliance on the side of the Customers and/or Payment Providers.
                 Any requests by the Property with regards to Customers or Payment Providers shall be directly communicated to the party in question.
                </p>

                <p class="indent bullet-points">
                 The Property acknowledges, agrees and declares that Point Click Integrate is not a party in the transactions performed by using the Platform and that
                 Point Click Integrate will not be held responsible and the entire liability will belong to the Property, in cases of, including but not limited to,
                 service fees not transferred to the account due to failure of collection, Customers’ objections against the transaction or requests of returning the service,
                 and claims of defective services, as well as disputes with regards to the Law on Protection of Consumers, Law on Liabilities and related legislation, and any other claims,
                 objections, questions, problems, complaints and penalties or sanctions of third persons and/or authorities. Point Click Integrate will redirect any claims,
                 objections, questions, problems, complaints and other similar notifications it receives to the Property. The Property will respond in writing to
                 Point Click Integrate in no later than maximum 2 (two) days after being notified of such claim, question, problem, complaint and similar situation,
                 and unless otherwise is specified, find a resolution to such claim, question, problem, complaint etc. in no later than 2 (two) days after notification.
                 Without prejudice to its right to collect the existing or future receivables, the Property agrees, declares and warrants that it will indemnify
                 Point Click Integrate in cash and advance upon Point Click Integrate's first demand for any penalties, penal obligations, and all the other fees and losses that
                 Point Click Integrate incurs against third parties or authorities.
                 The Property agrees, declares and warrants that it will not claim anything on, or object, its liability described above.
                </p>
                    </div>
                 </div>
              </div>

             <div class="panel panel-default">
              <div class="abc">
                  <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse4" class="collapsed abc"
                      aria-expanded="false" (click)="arrow_toggle('col_1')">
                      4. Terms of Channel Management Service
                      <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
                  </a>
              </div>
              <hr class="hr-pd">
              <div id="servicecollapse4" class="text panel-collapse collapse">

                 <div class="service_content">
                 <p class="indent bullet-points">
                    As part of the Channel Management Service, the Property will be able to connect through the Platform-contracted online tourism portals
                    (“Channel”) including but not limited to expedia.com, booking.com and traveleurope.com , where Customers can access Property information and complete booking transactions,
                    manage sales channels via bidirectional channel manager and update the same concurrently.
                    The Property will share the Channel and room information on the Platform and be able to access booking info from the Channels through the Platform.
                </p>
                <p class="indent bullet-points">
                    The list of the channels Point Click Integrate contracts as part of the Channel Management Service is displayed and updated on the Website.
                    Channels are determined at sole discretion of
                    Point Click Integrate. Furthermore, Point Click Integrate may at any time add or remove channels in and from the list of contracted Channels.
                 </p>

                <p class="indent bullet-points">
                 Activation of the Chanel Management through Contracted Channels requires configuration with the Channel. At the configuration phase, and depending on the scope of the
                 Service received by the Property, Point Click Integrate will support the Property. However, the Property will be responsible for Channel settings, determination of the
                 rooms to be displayed on the channel, and relevant adjustments. The Property agrees and declares that the room information uploaded through the Platform for publication
                 on the Channel is complete, accurate and truthful. Moreover, the Property may purchase an additional Service to request Point Click Integrate to do Channel settings,
                 determine the rooms to be displayed on the Channel and handle the relevant adjustments, in accordance with the instructions and consents of the Property.
                 Once such a Service is purchased,
                 the Property will convey such instructions verbally (via phone) or in written (e-mail) and be solely responsible for the actions to be executed by Point Click Integrate.
                </p>
                <p class="indent bullet-points">
                 The Property agrees that the Channel Management Service is not retroactive and that
                 Point Click Integrate does not warrant, as part of the Service, the transfer of the data previously obtained or uploaded on the Channels.
                </p>
                <p class="indent bullet-points">
                 In cases where the Property changes some data, including but not limited to room types and rates, to be published on the Channels,
                 it will be responsible for verifying whether such changes are reflected on the Channel. Moreover, the Property will notify Point Click Integrate promptly of possible errors
                 and interruptions that may arise due to such changes.
                 The Property agrees that Point Click Integrate is not responsible for the interruptions that arise from such changes.
                </p>
                <p class="indent bullet-points">
                 In order to synchronize the information to be uploaded on the Channels, Point Click Integrate may use the services of external service providers from time to time,
                 as part of the Channel Management Service. Therefore,
                 the Property agrees and acknowledges that the data it provides for synchronization between Platform and the Channel may be disclosed to such service providers.
                </p>
                <p class="indent bullet-points">
                   Point Click Integrate agrees to notify the Property of the errors it may diagnose during synchronization with the Channels.
                </p>
                <p class="indent bullet-points">
                 Point Click Integrate will not be held liable for errors, problems and interruptions that arise from changes the Property carries out in its system or for
                 errors arising from the changes made by the Channels on their systems or for the permanent damages arising from interruptions in the integration technology of the Channels.
                 The Property agrees that such changes may require re-integration that may incur additional service fees,
                 in which case Point Click Integrate will not be held liable for failure to synchronize or convey some information due to integration problems.
                </p>
                <p class="indent bullet-points">
                 The Property agrees that some information may not be accessible (e.g. booking prices displayed in lump sum instead of daily rates) due to technical
                 specifications of the Channels’ integration systems, or depending on their integration with Point Click Integrate technologies; that some information may
                 not be updated; and that Point Click Integrate may not be held liable for
                 interruptions arising from integration systems or from failure to integrate certain information with Point Click Integrate technology.
                </p>
                <p class="indent bullet-points">
                 Point Click Integrate will not be held liable for errors and omissions that arise from Channels’ own adjustments without Point Click Integrate's involvement.
                 Point Click Integrate will notify the Property of the recognized channel errors within a reasonable time period.
                </p>
                <p class="indent bullet-points">
                 As part of the Channel Management Service, Point Click Integrate may also provide access to some free of charge channels including the websites of local agencies.
                 The responsibility for any
                 interruptions arising from use of such Channels will be borne by the Property, and Point Click Integrate will not indemnify the losses arising from such interruptions.
                </p>


             <p class="indent bullet-points">
              In cases where the Services are interrupted by maintenance works or similar situations, Point Click Integrate will make necessary updates as soon as possible.
              In consideration of the bookings received from the Channels,
              the Property will not pay fees to Point Click Integrate other than those determined for the Service. Channels reserve the right to charge a commission rate.
             </p>

             <p class="indent bullet-points">
              Point Click Integrate reserves the right to record and log all the information, requests and adjustments provided by the Property to the Channels or vice versa,
              in addition to its right to put forth the same as legal proof in case of disputes among the Property,
              Point Click Integrate and relevant Channels. The Property expressly authorizes Point Click Integrate to record and use such data when deemed necessary.
             </p>

             <p class="indent bullet-points">
              Service provider name or title of Point Click Integrate on the Channels will be viewed as Point Click Integrate,
              Point Click Integrate Distribution Network (HDN), HDN or HDN Global.
             </p>
             </div>
              </div>
           </div>

          <div class="panel panel-default">
           <div class="abc">
               <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse5" class="collapsed abc"
                   aria-expanded="false" (click)="arrow_toggle('col_1')">
                   5. Terms of Content Management Service
                   <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
               </a>
           </div>
           <hr class="hr-pd">
           <div id="servicecollapse5" class="text panel-collapse collapse">

              <div class="service_content">
              <p class="indent bullet-points">
                 Content Management Service refers to the service which allows the Property to upload on
                 Point Click Integrate the templates and content developed by or on behalf of the Property and to make the same available on the Platform.
             </p>
             <p class="indent bullet-points">
              The Property will provide image- and text-based documentation for development of the website
              (“Customized Website”) to be published on the Platform that will be developed by using the Content Management Service.
             </p>

             <p class="indent bullet-points">
              The Property will be responsible for development of the Customized Website, necessary adjustments and settings on the Platform, and creating,
              uploading and management of relevant content.
              Point Click Integrate will be only responsible for providing the technology service that allows the Customized Website to be uploaded and published on the Platform.
             </p>
             <p class="indent bullet-points">
              The Property will receive necessary permissions and consents with regards to visual and written materials uploaded on the Platform while developing the Customized Website.
              In case of failure to satisfy this requirement, the Property will be held liable for all the losses incurred by Point Click Integrate which will be indemnified by
              the Property in accordance with Point Click Integrate's requests. Point Click Integrate has no control whatsoever over content registered and published on the
              Platform as part of the Content Management Service, and all rights and liabilities on such domain name and content shall rest with the Property.
              The Property will benefit from such service provided that it provides the content that holds necessary licenses and consents and/or is obtained in accordance with law.
              In cases where Point Click Integrate is determined that the Property, the Customer or other third persons have or may have published on the website content that contradicts facts,
              codes of conduct or laws or have or may have used the content without necessary consents and licenses; and/or that such content violates or may violate the rights of real or
              legal persons (including personal and intellectual property rights), Point Click Integrate may ask the Property to remove of such content within a certain time limit,
              personally remove the content and/or suspend the access to the Property website until such content is removed.
              In case of repeat of such violations, Point Click Integrate may suspend in parts or whole and/or terminate the Service.
             </p>
             <p class="indent bullet-points">
              The Property may also ask Point Click Integrate to develop the Customized Website or request Point Click Integrate's assistance while developing the same.
              Once the Property notifies Point Click Integrate of such a request, the parties will separately agree on the conditions governing website development.
              Use of the website shall be limited to the scope of the Service.
              Activities, products and services of the Property that do not fall within the scope of the Service may only be uploaded subject to the consent of Point Click Integrate.
             </p>
             </div>
         </div>
        </div>

       <div class="panel panel-default">
        <div class="abc">
            <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse6" class="collapsed abc"
                aria-expanded="false" (click)="arrow_toggle('col_1')">
                6.  Terms of Promotion Service
                <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
            </a>
        </div>
        <hr class="hr-pd">
        <div id="servicecollapse6" class="text panel-collapse collapse">
           <div class="service_content">
           <p class="indent bullet-points">
              The Promotion Service refers to the service
              that is integrated with the online booking module of the Platform and allows the Property,
              during online sales, to carry out promotions listed on the Platform.
          </p>

          <p class="indent bullet-points">
           Also available for use on social media, the Promotion Service covers a service that is integrated with the online booking module of the
           Platform and allows the Clients to enjoy discounts provided that they share their booking information of Facebook before closing the purchasing screen or website.
           The post that shows the Customers’ booking information includes a link to the
           Property website which will enable other Clients redirected to the website to receive discounts offered by the Property.
          </p>

          <p class="indent bullet-points">
           As part of the Promotion Service, the Property will create fixed or percentage-based discounts and enable Clients visiting the website to receive promotional discounts,
           thanks to Point Click Integrate's option that requires Customers to share their booking info on Facebook. Accordingly, the Property will determine the intervals,
           frequency and applicable room types for the “Social Media Promotions” offered to Clients.
           The Property will be responsible for ensuring that the “Social Media Coupons” are used by Customers in accordance with the manner agreed by the Property.
          </p>
           </div>
        </div>
     </div>

    <div class="panel panel-default">
     <div class="abc">
         <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse7" class="collapsed abc"
             aria-expanded="false" (click)="arrow_toggle('col_1')">
             7. Terms of Search Engine Optimization Service
             <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
         </a>
     </div>
     <hr class="hr-pd">
     <div id="servicecollapse7" class="text panel-collapse collapse">

        <div class="service_content">
        <p class="indent bullet-points">
           Point Click Integrate Search Engine Optimization Service includes an online booking engine service which involves basic-level, embedded Search Engine Optimization (“SEO”).
       </p>

       <p class="indent bullet-points">
        By using SEO, Point Click Integrate may channel traffic from Google, Yandex, Bing and Yahoo to the Property website. However,
        Point Click Integrate does not warrant that traffic for the Property website or sales will increase as a consequence of this Service.
       </p>

       <p class="indent bullet-points">
        Point Click Integrate website employs methods including H1, headline and meta tags, while automatically creating sitemaps.xml files to quickly display
        the changes in the Property services or on the website in search engines.
        Point Click Integrate reserves the right to change from time to time the techniques employed at its discretion.
       </p>

       <p class="indent bullet-points">
        In line with the integrated analytic reporting service provided as part of the SEO Service, Point Click Integrate will partially or wholly share with
        the Property the information including how the Customers access the Property website, their countries of origin, URL addresses that link to the Property website,
        key words that ensure access to the Property website,
        number of page views and number of individual visitors, as well as statistics that list the best-selling or most profitable services.
       </p>
       </div>
     </div>
  </div>

   <div class="panel panel-default">
  <div class="abc">
      <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse8" class="collapsed abc"
          aria-expanded="false" (click)="arrow_toggle('col_1')">
          8. Miscellaneous Terms Regarding Services
          <img id="col_1" src="assets/img/terms_of_use/collapse.png" alt="" class="pull-right">
      </a>
  </div>
  <hr class="hr-pd">
  <div id="servicecollapse8" class="text panel-collapse collapse">

     <div class="service_content">
     <p class="indent bullet-points">
        The fees payable to Point Click Integrate by the Property in consideration of the Services governed by the Terms
        herein are specified on the Website or Platform in line with the Service provided.
        Point Click Integrate may suspend use of the Services in case of any delayed or interrupted payment of such fees.
    </p>

    <p class="indent bullet-points">
     The Services governed by these Terms and Conditions are additional services provided through the Platform and they
     shall terminate in cases where the Property ends its use of the Platform for whatever reason or where the integration required
     for the Services cannot be performed/completed due to issues on the side of Property.
     In this case, Point Click Integrate will not be held liable to refund the fees already paid by the Property for the Services.
    </p>

    <p class="indent bullet-points">
     The entire liability of Point Click Integrate as part of the Services will be limited to direct damages and losses arising from issues on the side of
     Point Click Integrate, and the issues leading to such damages and losses will be notified to Point Click Integrate in no later than 5 (five) days
     following occurrence of the same. However, the compensation payable by
     Point Click Integrate will under no circumstances exceed the amount paid by the Property for the Service which caused such damages and losses.
    </p>

  </div>
  </div>
  </div>
 </div>
</div>
  -->


   <div class="section-3" [ngClass]="darkMode ? 'card-dark' : 'null'">
      <div class="container-4 w-container" >
         <div class="text-block-12 head-2 footer-head">{{ 'back_office.online_help:.WASTHISARTICLEHELPFUL'| translate }}</div>
         <!-- <img src="assets/img/online_help/smile_open.png"alt="" /> -->
         <!-- <img src="assets/img/newstaticicons/back-office/smile-open.svg" alt="" /> -->
         <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/backdark/emoji3.svg' : 'assets/img/newstaticicons/back-office/smile-open.svg'}}" alt="" style="width:5.5vh;">

         <!-- <img src="assets/img/online_help/smile.png" alt="" class="image-35" /> -->
         <!-- <img src="assets/img/newstaticicons/back-office/smile.svg" alt="" class="image-35" /> -->
         <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/backdark/emoji2.svg' : 'assets/img/newstaticicons/back-office/smile.svg'}}" alt="" style="width: 5.5vh;
         margin: 0px 20px;">


         <!-- <img src="assets/img/online_help/smile_flat.png" alt="" class="image-36" /> -->
         <img src="assets/img/newstaticicons/back-office/flat.svg" alt="" class="image-36" />

         <!-- <img src="assets/img/online_help/smile_sad.png" alt="" class="image-37" /> -->
         <img src="assets/img/newstaticicons/back-office/sad.svg" alt="" class="image-37" />

         <!-- <img src="assets/img/online_help/smile_sad_open.png" alt="" /> -->
         <img src="assets/img/newstaticicons/back-office/open-sad.svg" alt="" />
         <div class="text-block-15 text" [ngClass]="darkMode ? 'textt':'text'">
          {{ 'back_office.online_help:.supportteam'| translate }}
          </div><a routerLink="../../app/back_office/contact_support"
            class="button-3 w-button">{{ 'back_office.online_help:.ContactSupport'| translate }}</a>
      </div>
   </div>
</div>
<!-- </div> -->


<div class="scroll-to-top"  [ngClass]="{'show-scrollTop': windowScrolled}" >
 <a href="main/online_help#topimage"> <button >
  <i class="fa fa-chevron-up"></i>
</button></a>
</div>
