<div class="main_body_sec w-clearfix" [ngClass]="darkMode ? 'main_body_sec_dark' : 'main_body_sec'">

    <!-- dashboard skeleton start (working on saud 👇) -->
    <ul id="drag_box" style="list-style-type: none;" class="pl-0" *ngIf="dashboard_skeleton">
        <div class="dashboard-skeleton" 
        [ngClass]="darkMode ? 'dark_dashboard_skeleton' : 'dashboard-skeleton'">
            <!-- Stays -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="win_header bg-dark"></div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Stays end -->

            <!-- Performancestart -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="card-1">
                        <div class="win_header bg-dark">
                        </div>
                        <div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Performance  End-->

            <!-- Reservations -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="win_header bg-dark">
                    </div>
                    <div class="">
                        <div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Reservations End -->

            <!-- Stays -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="win_header bg-dark"></div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Stays end -->

            <!-- Today's Weather start -->
            <li class="drag_item ">
                <div class="dash_window dashboard-card ">
                    <div class="win_header bg-dark">
                    </div>
                    <div id="box4" class="box">
                        <div class="weather_bg w-clearfix pt-0 skeleton-animation-front">
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-8">
                                    <div class="weather-bar-line1"></div>
                                    <div class="weather-bar-line2"></div>
                                    <div class="weather-bar-line3"></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="weather-sky-bar"></div>
                                </div>
                            </div>
                            <div class="row weather-barline" style="display: flex; align-items: baseline; margin: 0;">
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Today's Weather end -->

            <!-- Reservations -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="win_header bg-dark">
                    </div>
                    <div class="">
                        <div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Reservations End -->

        </div>
    </ul>
    <!-- dashboard skeleton end (working on saud 👇) -->

    <div class="row no-gutters " [ngClass]="darkMode ? 'card-dark' : 'null'">
        <div id="drag_box" class="col-md-12">
            <ng-container *ngFor="let card of this.cardLayout; let i = index">
                <ng-container *ngFor="let card of cardLayout;">
                    <div class="col-md-3 col-sm-6 drag_item"
                        *ngIf="card.cardName == 'Connectivity Status' &&  card.cardKey==i+1" index="1"
                        index-data="Connectivity Status">
                        <app-connectivity-status [activeOTAs]="activeOta"
                            [activeChannel]="activeCha" [dashboard]="dashboard"></app-connectivity-status>
                    </div>

                    <div class="col-md-3 col-sm-6 drag_item"
                        *ngIf="card.cardName == 'OTA Revenue' &&  card.cardKey==i+2" index="2" index-data="OTA Revenue">
                        <app-ota-revenue [dashboard]="dashboard"
                            (dashboardDataOTA)="getDashboardData()"></app-ota-revenue>
                    </div>

                    <div class="col-md-3 col-sm-6 drag_item"
                        *ngIf="card.cardName == 'Reservations' &&  card.cardKey==i+3" index="3"
                        index-data="Reservations">
                        <app-reservation [dashboard]="dashboard"
                            (readReservation)="readReserve($event)"></app-reservation>
                    </div>

                    <div class="col-md-3 col-sm-6 drag_item"
                        *ngIf="card.cardName == 'Todays Weather' &&  card.cardKey==i+4" index="4"
                        index-data="Todays Weather">
                        <app-weather></app-weather>
                    </div>

                    <div class="col-md-3 col-sm-6 drag_item" *ngIf="card.cardName == 'Front Desk' &&  card.cardKey==i+5"
                        index="5" index-data="Front Desk">
                        <app-front-desk [frontDesk]="frontDesk" (dashboardData)="getDashboardData()"></app-front-desk>
                    </div>

                    <div class="col-md-3 col-sm-6 drag_item"
                        *ngIf="card.cardName == 'Notifications' &&  card.cardKey==i+6" index="6"
                        index-data="Notifications">
                        <app-notifications [dashboard]="dashboard"></app-notifications>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div id="" style="list-style-type: none;" class="pl-0">
        <div class="row">
            <div class="col-md-12" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="col-md-6 zero-padding " id="list-1">
                    <div class="drag-itemi" *ngFor="let compare of compare_array; let i = index">
                        <div style="width: 100%;float: left;" *ngIf="i % 2 == 0" id="mouseE1" class="rc">
                            <div class="dash_window no_margin w-clearfix dashboard-card2 hoviorange"
                                [ngClass]="darkMode ? 'dash_window_dark_report' : 'null'"
                                style="height: auto;border-radius: 10px;">
                                <div class="reportCardDiv" id="RPC1">
                                    <h4 class="heading donutHeading">
                                        <img *ngIf="compare?.channel_type == 'External'"
                                            src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/'+imgr(compare?.image):imgr(compare?.image)}}.svg"
                                            alt="" class="no-user ota-icon" />
                                        <img *ngIf="compare?.channel_type == 'Internal'"
                                            src="assets/img/svgicons/channels/{{imgr(compare?.image)}}.svg" alt=""
                                            class="no-user ota-icon" />
                                    </h4>
                                    <span style="float: right;">
                                        <a data-toggle="collapse" href="#collapse{{ i }}" aria-expanded="false"
                                            aria-controls="collapseOne" id="chkCollape{{i}}">
                                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                class="delImgReport dropo" id="dropCollapse{{i}}"
                                                (click)="checkCollapse('chkCollape'+i , 'dropCollapse'+i)">
                                            <!-- <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" *ngIf="pkg_label.classList.contains('collapsed')" class="delImgReport" style="transform: rotate(180deg);" > -->
                                        </a>
                                    </span>

                                    <span style="float: right;"><img
                                            src="assets/img/newstaticicons/rates-packages/delete.svg"
                                            class="delImgReport delto"
                                            (click)="deleteReport(compare.pin_point_id)"></span>

                                </div>
                                <div class="row panel-collapse collapse in" id="collapse{{ i }}">
                                    <div class="col-md-6" style="width: 100%;">
                                        <div class="" [ngClass]="darkMode ? 'topBoro' : 'topBorolight'">
                                            <div class="row">
                                                <div class="col-md-6">

                                                    <div class="dash_window2">
                                                        <div class="comp_revenue2">
                                                            <div class="flex">
                                                                <h4 class="channel_name2">
                                                                    {{ "reports.occupancy_rate" | translate }}
                                                                </h4>
                                                                <span class="numbers">{{compare.occupany_rate +
                                                                    "%"}}</span>
                                                            </div>
                                                            <div class="channel_bar_bg2">
                                                                <div class="channel_bar_progress2 occupany-rate"
                                                                    [ngStyle]="{'width' : compare.per_occupany_rate > 10 ? compare.per_occupany_rate + '%' : '10%'}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">
                                                                {{"reports.avg_night_stayed-per_room" | translate}}</h4>
                                                            <div class="two-values">
                                                                <span class="numbers">{{compare.avg_nights_per_room + "
                                                                    Nights"}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 night-stayed-per-room"
                                                                [ngStyle]="{'width' : compare.per_avg_nights_per_room > 10 ? compare.per_avg_nights_per_room + '0%' : '10%'}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{"reports.avg_daily_rate_adr" |
                                                                translate}}</h4>
                                                            <span class="numbers">{{compare.avg_daily_rate |
                                                                currency}}</span>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 avg-daily-rate"
                                                                [ngStyle]="{width: compare.per_avg_daily_rate}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.revenue_per_room" |
                                                                translate }}</h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.rev_par |
                                                                    currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 revenue-per-room"
                                                                [ngStyle]="{ width: compare.per_rev_par }"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.avg_lead_time" |
                                                                translate }}</h4>
                                                            <div class="two-values">
                                                                <span class="numbers">{{compare.avg_lead}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 avg-lead-time"
                                                                [ngStyle]="{ width: compare.per_avg_lead }"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{"reports.channel_commission" |
                                                                translate}}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.channel_commission_value
                                                                    | currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 channel-commision"
                                                                [ngStyle]="{width: compare.per_channel_commission}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">31 Days
                                                                <!-- {{"reports.revenue_earned_in" | translate}}
                                  {{ choosen_range.length }}
                                  {{ "reports.days" | translate }} -->
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers2">{{compare.net_revenue +
                                                                    compare.channel_commission_value | currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 revenue-earned-in"
                                                                [ngStyle]="{width: compare.per_revenue_earned+'%'}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.net_revenue" |
                                                                translate }}</h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.net_revenue |
                                                                    currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 net-revenue"
                                                                [ngStyle]="{width: compare.per_net_revenue+'%'}"></div>
                                                        </div>
                                                    </div>

                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{
                                                                'rates_and_packages.extra.cancellation' | translate }}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.cancellation}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 cancellation"
                                                                [ngStyle]="{width: compare.cancellation+'%'}"></div>
                                                        </div>
                                                    </div>

                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{
                                                                'rates_and_packages.extra.reservation' | translate }}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.reservation}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 reservation"
                                                                [ngStyle]="{width: compare.reservation+'%'}"></div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="col-md-6 svgDonutColumn">



                                                    <div class="donutAlign">
                                                        <svg width="100%" height="260px" viewBox="0 0 42 42"
                                                            class="donut">
                                                            <text x="50%" y="53%"
                                                                style="font-size: 6px; font-weight: bold;"
                                                                dominant-baseline="middle" text-anchor="middle"
                                                                [attr.fill]="border_colors[i]">
                                                                {{ compare.total | currency: "USD":"symbol":"1.0-0"}}
                                                            </text>

                                                            <circle class="donut-ring" cx="21" cy="21"
                                                                r="15.91549430918954" fill="transparent"
                                                                stroke="#f3f3f3" stroke-width="7"></circle>

                                                            <circle attr.class="donut-segment{{ i }}"
                                                                [attr.data-per]="compare.rev_percentage" cx="21" cy="21"
                                                                r="15.91549430918954" fill="transparent"
                                                                [attr.stroke]="border_colors[i]" stroke-width="7"
                                                                [attr.stroke-dasharray]="compare.rev_percentage + ' ' + (100 - compare.rev_percentage )"
                                                                stroke-dashoffset="25"></circle>
                                                        </svg>

                                                        <ul class="nav text-center">
                                                            <li *ngFor="let small of compare_array | slice: 0:8; let idx = index"
                                                                style="display: inline-block;padding: 0px 20px;">
                                                                <div class="lined">
                                                                    <div class="square-booking"
                                                                        [ngStyle]="{ 'background': border_colors[idx] }">
                                                                    </div>
                                                                    <img src="assets/img/svgicons/smallicons/{{imgr(small.image)}}.svg"
                                                                        class="legends" alt="" />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 zero-padding " id="list-2">
                    <div class="drag-itemi" *ngFor="let compare of compare_array; let i = index">
                        <div style="width: 100%;float: left;" *ngIf="i % 2 != 0" id="mouseE2" class="rc">
                            <div class="dash_window no_margin w-clearfix dashboard-card2 hoviorange"
                                [ngClass]="darkMode ? 'dash_window_dark_report' : 'null'"
                                style="height: auto;border-radius: 10px;">
                                <div class="reportCardDiv" id="RPC2">
                                    <h4 class="heading donutHeading">
                                        <img *ngIf="compare?.channel_type == 'External'"
                                            src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/'+imgr(compare?.image):imgr(compare?.image)}}.svg"
                                            alt="" class="no-user ota-icon" />
                                        <img *ngIf="compare?.channel_type == 'Internal'"
                                            src="assets/img/svgicons/channels/{{imgr(compare?.image)}}.svg" alt=""
                                            class="no-user ota-icon" />
                                    </h4>
                                    <span style="float: right;">
                                        <a data-toggle="collapse" href="#collapse{{ i }}" aria-expanded="false"
                                            aria-controls="collapseOne" id="chkCollapeO{{i}}">
                                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                class="delImgReport dropo" id="dropCollapseO{{i}}"
                                                (click)="checkCollapse('chkCollapeO'+i , 'dropCollapseO'+i)">
                                            <!-- <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" *ngIf="pkg_label.classList.contains('collapsed')" class="delImgReport" style="transform: rotate(180deg);" > -->
                                        </a>
                                    </span>

                                    <span style="float: right;"><img
                                            src="assets/img/newstaticicons/rates-packages/delete.svg"
                                            class="delImgReport delto"
                                            (click)="deleteReport(compare.pin_point_id)"></span>

                                </div>
                                <div class="row panel-collapse collapse in" id="collapse{{ i }}">
                                    <div class="col-md-6" style="width: 100%;">
                                        <div class="" [ngClass]="darkMode ? 'topBoro' : 'topBorolight'">
                                            <div class="row">
                                                <div class="col-md-6">

                                                    <div class="dash_window2">
                                                        <div class="comp_revenue2">
                                                            <div class="flex">
                                                                <h4 class="channel_name2">
                                                                    {{ "reports.occupancy_rate" | translate }}
                                                                </h4>
                                                                <span class="numbers">{{compare.occupany_rate +
                                                                    "%"}}</span>
                                                            </div>
                                                            <div class="channel_bar_bg2">
                                                                <div class="channel_bar_progress2 occupany-rate"
                                                                    [ngStyle]="{'width' : compare.per_occupany_rate > 10 ? compare.per_occupany_rate + '%' : '10%'}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">
                                                                {{"reports.avg_night_stayed-per_room" | translate}}</h4>
                                                            <div class="two-values">
                                                                <span class="numbers">{{compare.avg_nights_per_room + "
                                                                    Nights"}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 night-stayed-per-room"
                                                                [ngStyle]="{'width' : compare.per_avg_nights_per_room > 10 ? compare.per_avg_nights_per_room + '0%' : '10%'}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{"reports.avg_daily_rate_adr" |
                                                                translate}}</h4>
                                                            <span class="numbers">{{compare.avg_daily_rate |
                                                                currency}}</span>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 avg-daily-rate"
                                                                [ngStyle]="{width: compare.per_avg_daily_rate}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.revenue_per_room" |
                                                                translate }}</h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.rev_par |
                                                                    currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 revenue-per-room"
                                                                [ngStyle]="{ width: compare.per_rev_par }"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.avg_lead_time" |
                                                                translate }}</h4>
                                                            <div class="two-values">
                                                                <span class="numbers">{{compare.avg_lead}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 avg-lead-time"
                                                                [ngStyle]="{ width: compare.per_avg_lead }"></div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{"reports.channel_commission" |
                                                                translate}}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.channel_commission_value
                                                                    | currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 channel-commision"
                                                                [ngStyle]="{width: compare.per_channel_commission}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">31 {{
                                                                'rates_and_packages.extra.days' | translate}}
                                                                <!-- {{"reports.revenue_earned_in" | translate}}
                                  {{ choosen_range.length }}
                                  {{ "reports.days" | translate }} -->
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers2">{{compare.net_revenue +
                                                                    compare.channel_commission_value | currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 revenue-earned-in"
                                                                [ngStyle]="{width: compare.per_revenue_earned+'%'}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{ "reports.net_revenue" |
                                                                translate }}</h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.net_revenue |
                                                                    currency}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 net-revenue"
                                                                [ngStyle]="{width: compare.per_net_revenue+'%'}"></div>
                                                        </div>
                                                    </div>



                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{
                                                                'rates_and_packages.extra.cancellation' | translate }}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.cancellation}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 cancellation"
                                                                [ngStyle]="{width: compare.cancellation+'%'}"></div>
                                                        </div>
                                                    </div>

                                                    <div class="comp_revenue2">
                                                        <div class="flex">
                                                            <h4 class="channel_name2">{{
                                                                'rates_and_packages.extra.reservation' | translate}}
                                                            </h4>
                                                            <div class="values">
                                                                <span class="numbers">{{compare.reservation}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="channel_bar_bg2">
                                                            <div class="channel_bar_progress2 reservation"
                                                                [ngStyle]="{width: compare.reservation+'%'}"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 svgDonutColumn">
                                                    <div class="donutAlign">
                                                        <svg width="100%" height="260px" viewBox="0 0 42 42"
                                                            class="donut">
                                                            <text x="50%" y="53%"
                                                                style="font-size: 6px; font-weight: bold;"
                                                                dominant-baseline="middle" text-anchor="middle"
                                                                [attr.fill]="border_colors[i]">
                                                                {{ compare.total | currency: "USD":"symbol":"1.0-0"}}
                                                            </text>

                                                            <circle class="donut-ring" cx="21" cy="21"
                                                                r="15.91549430918954" fill="transparent"
                                                                stroke="#f3f3f3" stroke-width="7"></circle>

                                                            <circle attr.class="donut-segment{{ i }}"
                                                                [attr.data-per]="compare.rev_percentage" cx="21" cy="21"
                                                                r="15.91549430918954" fill="transparent"
                                                                [attr.stroke]="border_colors[i]" stroke-width="7"
                                                                [attr.stroke-dasharray]="compare.rev_percentage + ' ' + (100 - compare.rev_percentage )"
                                                                stroke-dashoffset="25"></circle>
                                                        </svg>

                                                        <ul class="nav text-center">
                                                            <li *ngFor="let small of compare_array | slice: 0:8; let idx = index"
                                                                style="display: inline-block;padding: 0px 20px;">
                                                                <div class="lined">
                                                                    <div class="square-booking"
                                                                        [ngStyle]="{ 'background': border_colors[idx] }">
                                                                    </div>
                                                                    <img src="assets/img/svgicons/smallicons/{{imgr(small.image)}}.svg"
                                                                        class="legends" alt="" />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>