<div class="row no-gutters" [ngClass]="darkMode ? 'card-dark' : 'null'">
  <div id="drag_box" class="col-md-12">
    <div class="col-md-4 drag_item">
      <a class="block-btn modal-btn" routerLink="invoice">
        <div class="jumbotron blue-shade ">
          <div class="jumbo-icon">
            <!-- <img src="assets/img/billing/list.png" class="img-responsive">   -->
            <img src="assets/img/newstaticicons/back-office/list.svg" class="img-responsive hov" style="width: 268px;" />
            <div class="jumbo-text">
              <p>{{ 'back_office.billing.invoices' | translate }}</p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-4 drag_item">
      <a class="block-btn modal-btn" routerLink="manage_how_you_pay">
        <div class="jumbotron purple-shade">
          <div class="jumbo-icon">
            <!-- <img src="assets/img/billing/money.png" class="img-responsive"> -->
            <img src="assets/img/newstaticicons/back-office/mony.svg" class="img-responsive hov" style="width: 197px;" />
            <div class="jumbo-text">
              <p>{{ 'back_office.billing.manage_how_you_pay' | translate }}</p>
            </div>
          </div>

        </div>
      </a>
    </div>
    <div class="col-md-4 drag_item">
      <a class="block-btn" routerLink="subscription">
        <div class="jumbotron maroon-shade">
          <div class="jumbo-icon">
            <!-- <img src="assets/img/billing/analytics.png" class="img-responsive"> -->
            <img src="assets/img/newstaticicons/back-office/analytics.svg" class="img-responsive hov"
              style="width: 237px;" />
            <div class="jumbo-text">
              <p>{{ 'back_office.billing.subscription_plan' | translate }}</p>
            </div>
          </div>

        </div>
      </a>
    </div>
  </div>
</div>
