import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.css']
})
export class ContactSupportComponent implements OnInit {
selectedTime:any;
  submitted=false;
  base_url;
  email;
  inlineDate;
  inlineDateTime;
  zoneTime;
  countryByTime
  city;
  date:any;
  times:any;
  duaration:any;
  darkMode:boolean = false;
  selectedDateTime = [
    {
      startTime:'09:00',
      endTime:'10:00',
      date:'Tue Jul 14 2020'
    },
    {
      startTime:'11:00',
      endTime:'12:00',
      date:'Tue Jul 14 2020'
    },
    {
      startTime:'12:00',
      endTime:'01:00',
      date:'Tue Jul 14 2020'
    },
    {
      startTime:'02:00',
      endTime:'03:00',
      date:'Tue Jul 14 2020'
    },



  ];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    ) { }

  contact_support_form: FormGroup;
  contact_support_form2: FormGroup;

  ngOnInit() {
  this. checkDarkMode();

    // this.contact_support_form = this.fb.group({
    //   web_email: ['',[Validators.email , Validators.required]],
    //   property_name: ['',Validators.required],
    //   subject: ['',Validators.required],
    //   product_using: [null,Validators.required],
    //   request_related_to: [null,Validators.required],
    //   need_assistance_with: [null,Validators.required],
    //   user_id: ['',Validators.required],
    //   description: ['',[Validators.required , Validators.minLength(50)]],
    // });
    // this.base_url=this.api.base_url;

    this.contact_support_form = this.fb.group({
        subject: ['',Validators.required],
        description: ['',Validators.required],
        user_id: ['',Validators.required],
      });


      this.base_url=this.api.base_url;
console.log(this.selectedDateTime)
this.getTimeZone()
  }

  get f() { return this.contact_support_form.controls; }

  onSubmit(){
    // console.log('Your form has been submitted', data);

    this.submitted = true;
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.contact_support_form.get('user_id').setValue(id);
    console.log(id);

    if (this.contact_support_form.invalid) {
      return;
    }
    console.log(this.contact_support_form.invalid)

    this.api.post('api/online_help/store', this.contact_support_form.value,true).subscribe((res:any) => {
      this.helper.alert_success("Request submitted successfully");
      this.contact_support_form.reset()
      this.submitted = false;

    }, err => this.error_handling.handle_error(err.status));
  }


  subletmit2(){
let obj ={
  user_id: JSON.parse(localStorage.getItem('user')).id,
  call_duration: this.duaration,
  call_date: this.date.toDateString(),
  timezone: this.city,
  call_time: this.times
}
    this.api.post('api/helper/timeslot_store', obj,true).subscribe((res:any) => {
      this.helper.alert_success("Request submitted successfully");

      this.submitted = false;
    }, err => this.error_handling.handle_error(err.status));
  }

  resetform(){
    this.contact_support_form.reset();
    this.contact_support_form2.reset();
  }




  eventClicked(e){
    let d = e.startDate._d;
    console.log(d);
    this.date = d;
    let b = document.getElementById("redCard").style.display = 'block';
  }



  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }



  getTime(timess){
    console.log(timess);
    this.times = timess.startTime + '-' + timess.endTime;
  }

  getTimeZone(){
   let a = new Date().toString().split(" ");
   this.city =Intl.DateTimeFormat().resolvedOptions().timeZone
   this.countryByTime = a[6].split("(")
   this.zoneTime = a[5]
  }

}
