import { Component, OnInit } from "@angular/core";
declare var $: any;
import * as moment from "moment";
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: "app-arrivals",
  templateUrl: "./arrivals.component.html",
  styleUrls: ["./arrivals.component.css"],
})
export class ArrivalsComponent implements OnInit {
  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService
  ) {}

  current_arrival;
  current_arrivall;
  pagintion: any;
  arrivals = [];
  pdfgen: boolean = false;

  pagi = [];
  pagi2 = [];
  pagi3 = [];
  pagi4 = [];
  lastP4;
  lastP;
  TaxInclusivee;
  lastP2;
  lastP3;
  current_currency;
  today_arrivals = [];
  lastweek = [];
  inhouse = [];

  all_arr: boolean = true;
  today_arr: boolean = true;
  last_arr: boolean = true;
  in_house: boolean = true;
  selectedIndex4 = 0;
  selectedIndex3 = 0;
  selectedIndex2 = 0;
  selectedIndex = 0;

  lastpage;
  lastpage2;
  lastpage3;
  lastpage4;

  lastpageNm;
  lastpageNm2;
  lastpageNm3;
  lastpageNm4;

  scll;
  allPcount;
  darkMode: boolean = false;
  an;
  WHcolors = [
    "#ffc107",
    "#ec1361",
    "#09fb3d",
    "#ff51f3",
    "#48fff3",
    "#9a00ff",
    "#ff6f00",
  ];
  colors = ["#283ed8", "#ec13a0", "#09fb3d", "#a7002d", "#ffc107", "#9a00ff"];
  weather_update = [];
	loader: boolean = true;

  ngOnInit() {
    this.checkDarkMode();
    this.currency();
    this.get_arrivals();
    this.getWeather();
    $(window).on("scroll", function () {
      var $w = $(window);
      $(".position-fixed-x").css("left", $w.scrollLeft());
    });

    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate:
          "<div class='placeHolder' style=''>" +
          "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div>" +
          "</div>",
        cursor: "move",
      });
      $("#all-arri").mouseup(function () {
        $("li").css({ cursor: "grab" });
      });
      $("#all-arri").mousedown(function () {
        $("li").css({ cursor: "grabbing" });
      });
      $("#last-week-arri").mouseup(function () {
        $("li").css({ cursor: "grab" });
      });
      $("#last-week-arri").mousedown(function () {
        $("li").css({ cursor: "grabbing" });
      });
      $("#todays-arri").mouseup(function () {
        $("li").css({ cursor: "grab" });
      });
      $("#todays-arri").mousedown(function () {
        $("li").css({ cursor: "grabbing" });
      });
      $("#summary").mouseup(function () {
        $("#grab-3").css({ cursor: "grab" });
      });
      $("#summary").mousedown(function () {
        $("#grab-3").css({ cursor: "grabbing" });
      });
      $("#myModal").mouseup(function () {
        $("#grab-4").css({ cursor: "grab" });
      });
      $("#myModal").mousedown(function () {
        $("#grab-4").css({ cursor: "grabbing" });
      });
    });

    $(function () {
      $("#all-arri").sortable({});
      $("#last-week-arri").sortable({});
      $("#todays-arri").sortable({});
      $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
          $("#paginav").hide();
        } else $("#paginav").show();
      });
    });
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  get_arrivals() {
    let property_id = localStorage.getItem("current_property");

    this.api
      .post("api/booking/arrivals", { property_id: property_id }, true)
      .subscribe(
        (res: any) => {
          console.log(res.body);
          this.lastP = res.body.data.all.last_page;
          this.lastP2 = res.body.data.today.last_page;
          this.lastP3 = res.body.data.last_week.last_page;

          for (let i = 1; i <= res.body.data.all.last_page; i++) {
            this.pagi.push({
              page_no: i,
              url: "http://cm.com/pmsbackend/public/api/booking/arrivals?page=",
            });
          }
          this.lastpage = this.pagi[this.pagi.length - 1];
          this.lastpageNm = this.lastpage.page_no;

          for (let i = 1; i <= res.body.data.today.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url: "http://cm.com/pmsbackend/public/api/booking/arrivals?page=",
            });
          }

          this.lastpage2 = this.pagi2[this.pagi2.length - 1];
          this.lastpageNm2 = this.lastpage2.page_no;

          for (let i = 1; i <= res.body.data.last_week.last_page; i++) {
            this.pagi3.push({
              page_no: i,
              url: "http://cm.com/pmsbackend/public/api/booking/arrivals?page=",
            });
          }

          this.lastpage3 = this.pagi3[this.pagi3.length - 1];
          this.lastpageNm3 = this.lastpage3.page_no;
          this.arrivals = res.body.data.all.data;
          this.today_arrivals = res.body.data.today.data;
          this.lastweek = res.body.data.last_week.data;
          this.allPcount = res.body.data.all.last_page;
          this.loader = false;

          this.pagintion = {
            first_page_url: res.body.data.first_page_url,
            current_page: res.body.data.current_page,
            last_page: res.body.data.last_page,
            last_page_url: res.body.data.last_page_url,
            next_page_url: res.body.data.next_page_url,
            per_page: res.body.data.per_page,
            prev_page_url: res.body.data.prev_page_url,
            to: res.body.data.to,
            total: res.body.data.total,
          };
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    this.all_arr = true;
    this.last_arr = false;
    this.today_arr = false;
    this.in_house = false;
  }

  show_arrival(arrivl) {
    this.current_arrival = arrivl;
    console.log(this.current_arrival);

    this.current_arrival.bookingitems.map((val) => {
      try {
        val.pricesjson = JSON.parse(val.pricesjson);
        console.log(JSON.parse(val.pricesjson));
      } catch (err) {}
    });
    this.TaxInclusivee = arrivl.TaxInclusive
    this.changeSegment(arrivl.TaxInclusive, "segmon");
    this.fliBox();
    $("#summary").modal({ backdrop: "static", keyboard: false });
    $("#summary").draggable();
    $("#summary").draggable("disable");
  }

  show_arrivall(arrivl) {
    this.current_arrival = arrivl;
    this.fliBox();
    $("#myModal").modal({ backdrop: "static", keyboard: false });
    $("#myModal").draggable();
    $("#myModal").draggable("enable");
  }

  previousPage() {
    var property_id = localStorage.getItem("current_property");
    let p;
    if (this.pagintion.prev_page_url != null) {
      p = this.pagintion.prev_page_url.split("?");
      this.api
        .post(
          "api/booking/arrivals?" + p[1],
          { property_id: property_id },
          true
        )
        .subscribe(
          (res: any) => {
            this.arrivals = res.body.data.data;
            this.pagintion = {
              first_page_url: res.body.data.first_page_url,
              current_page: res.body.data.current_page,
              last_page: res.body.data.last_page,
              last_page_url: res.body.data.last_page_url,
              next_page_url: res.body.data.next_page_url,
              per_page: res.body.data.per_page,
              prev_page_url: res.body.data.prev_page_url,
              to: res.body.data.to,
              total: res.body.data.total,
            };
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  makePdf() {
    const options = {
      background: undefined,
      scale: 1,
    };
    html2canvas(document.querySelector(".capture"), options).then((canvas) => {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF("p", "mm", [200, 210]);
      const bufferX = 3;
      const bufferY = 3;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(
        img,
        "PNG",
        bufferX,
        bufferY,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );
      // doc.internal.pageSize.height = 50.08333333333333
      doc.save("Arrivals.pdf");
      this.pdfgen = false;
    });
  }

  makePDF() {
    const options = {
      background: "#ffffff",
      scale: 1,
      pagebreak: { mode: "avoid" },
    };
    html2canvas(document.querySelector(".capture"), options).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 209;
      var pageHeight = 296;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm", "a4");
      var position = 5; // give some top padding to first page
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Arrivals.pdf");
    });
  }

  nextPage() {
    var property_id = localStorage.getItem("current_property");
    let p;
    if (this.pagintion.prev_page_url != null) {
      p = this.pagintion.next_page_url.split("?");
      this.api
        .post(
          "api/booking/arrivals?" + p[1],
          { property_id: property_id },
          true
        )
        .subscribe(
          (res: any) => {
            this.arrivals = res.body.data.data;
            this.pagintion = {
              first_page_url: res.body.data.first_page_url,
              current_page: res.body.data.current_page,
              last_page: res.body.data.last_page,
              last_page_url: res.body.data.last_page_url,
              next_page_url: res.body.data.next_page_url,
              per_page: res.body.data.per_page,
              prev_page_url: res.body.data.prev_page_url,
              to: res.body.data.to,
              total: res.body.data.total,
            };
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  pdf_css() {
    this.pdfgen = true;
    setTimeout(() => {
      this.makePdf();
    }, 1000);
  }

  paginationPage(url, pn) {
    this.scCustom(pn);
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + pn,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          if (this.all_arr == true) {
            this.arrivals = res.body.data.all.data;
            this.allPcount = res.body.data.all.current_page;
            // console.log(this.today_arrivals);
          } else if (this.today_arr == true) {
            this.today_arrivals = res.body.data.today.data;
            this.allPcount = res.body.data.today.current_page;
          } else if (this.last_arr == true) {
            this.lastweek = res.body.data.last_week.data;
            this.allPcount = res.body.data.last_week.current_page;
          } else if (this.in_house == true) {
            console.log(res.body.data.in_House.data);
            this.inhouse = res.body.data.in_House.data;
            // this.allPcount = res.body.data.in_House.current_page
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  last_page() {
    var property_id = localStorage.getItem("current_property");
    if (this.all_arr == true) {
      this.lastP = this.lastP;
      this.active1();
    } else if (this.today_arr == true) {
      this.lastP = this.lastP2;
      this.active2();
    } else if (this.last_arr == true) {
      this.lastP = this.lastP3;
      this.active3();
    } else if (this.in_house == true) {
      this.lastP = this.lastP4;
      this.active4();
    }
    this.api
      .post(
        "api/booking/arrivals?page=" + this.lastP,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          if (this.all_arr == true) {
            this.arrivals = res.body.data.all.data;
            document.getElementById("cuspagidiv1").scrollLeft += 100000;
          } else if (this.today_arr == true) {
            this.today_arrivals = res.body.data.today.data;
            document.getElementById("cuspagidiv2").scrollLeft += 100000;
          } else if (this.last_arr == true) {
            this.lastweek = res.body.data.last_week.data;
            document.getElementById("cuspagidiv3").scrollLeft += 100000;
          } else if (this.in_house == true) {
            console.log(res.body.data.in_House.data);
            this.inhouse = res.body.data.in_House.data;
            document.getElementById("cuspagidiv3").scrollLeft += 100000;
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  first_page(one) {
    this.selectedIndex = 0;
    let property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + one,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          if (this.all_arr == true) {
            this.arrivals = res.body.data.all.data;
            document.getElementById("cuspagidiv1").scrollLeft -= 100000;
          } else if (this.today_arr == true) {
            this.today_arrivals = res.body.data.today.data;
            document.getElementById("cuspagidiv2").scrollLeft -= 100000;
          } else if (this.last_arr == true) {
            this.lastweek = res.body.data.last_week.data;
            document.getElementById("cuspagidiv3").scrollLeft -= 100000;
          } else if (this.in_house == true) {
            this.inhouse = res.body.data.in_House.data;
            console.log(res.body.data.in_House.data);
            document.getElementById("cuspagidiv4").scrollLeft -= 100000;
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  prev() {
    if (this.all_arr == true) {
      this.an = this.selectedIndex -= 1;
    }
    if (this.today_arr == true) {
      this.an = this.selectedIndex2 -= 1;
    }
    if (this.last_arr == true) {
      this.an = this.selectedIndex3 -= 1;
    }
    let property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + this.an,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          let amm;
          this.all_arr == true
            ? (amm = "cuspagidiv1")
            : this.today_arr == true
            ? (amm = "cuspagidiv2")
            : this.last_arr == true
            ? (amm = "cuspagidiv3")
            : this.in_house == true
            ? (amm = "cuspagidiv4")
            : (amm = "cuspagidiv1");
          if (this.all_arr == true) {
            this.arrivals = res.body.data.all.data;
            if (res.body.data.all.current_page > 100) {
              document.getElementById(amm).scrollLeft -= 50;
            } else {
              document.getElementById(amm).scrollLeft -= 30;
            }
          } else if (this.today_arr == true) {
            this.today_arrivals = res.body.data.today.data;
            if (res.body.data.today.current_page > 100) {
              document.getElementById(amm).scrollLeft -= 50;
            } else {
              document.getElementById(amm).scrollLeft -= 30;
            }
          } else if (this.last_arr == true) {
            this.lastweek = res.body.data.last_week.data;
            if (res.body.data.last_week.current_page > 100) {
              document.getElementById(amm).scrollLeft -= 50;
            }
          } else if (this.in_house == true) {
            this.lastweek = res.body.data.in_House.data;
            if (res.body.data.in_House.current_page > 100) {
              console.log(res.body.data.in_House.data);
              document.getElementById(amm).scrollLeft -= 50;
            } else {
              document.getElementById(amm).scrollLeft -= 30;
            }
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  next() {
    if (this.all_arr == true) {
      this.an = this.selectedIndex += 1;
    }
    if (this.today_arr == true) {
      this.an = this.selectedIndex2 += 1;
    }
    if (this.last_arr == true) {
      this.an = this.selectedIndex3 += 1;
    }
    let property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + this.an,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          let amm;
          this.all_arr == true
            ? (amm = "cuspagidiv1")
            : this.today_arr == true
            ? (amm = "cuspagidiv2")
            : this.last_arr == true
            ? (amm = "cuspagidiv3")
            : (amm = "cuspagidiv1");
          if (this.all_arr == true) {
            this.arrivals = res.body.data.all.data;
            if (res.body.data.all.current_page > 100) {
              document.getElementById(amm).scrollLeft += 50;
            } else {
              document.getElementById(amm).scrollLeft += 30;
            }
          } else if (this.today_arr == true) {
            this.today_arrivals = res.body.data.today.data;
            if (res.body.data.today.current_page > 100) {
              document.getElementById(amm).scrollLeft += 50;
            } else {
              document.getElementById(amm).scrollLeft += 30;
            }
          } else if (this.last_arr == true) {
            this.lastweek = res.body.data.last_week.data;
            if (res.body.data.last_week.current_page > 100) {
              document.getElementById(amm).scrollLeft += 50;
            } else {
              document.getElementById(amm).scrollLeft += 30;
            }
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  all_arrivals_pagi() {
    this.all_arr = true;
    this.today_arr = false;
    this.last_arr = false;
    this.in_house = false;
  }

  today_arrivals_pagi() {
    this.all_arr = false;
    this.today_arr = true;
    this.last_arr = false;
    this.in_house = false;
  }

  lastweek_arrivals_pagi() {
    this.all_arr = false;
    this.today_arr = false;
    this.last_arr = true;
    this.in_house = false;
  }
  in_house_pagi() {
    this.loader = true;
    this.all_arr = false;
    this.today_arr = false;
    this.last_arr = false;
    this.in_house = true;
    let property_id = localStorage.getItem("current_property");
    this.api
      .post("api/booking/arrivals?", { property_id: property_id }, true)
      .subscribe(
        (res: any) => {
          this.inhouse = res.body.data.in_House.data;
          this.loader = false;
          console.log(res.body.data.in_House.data);
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  active1() {
    let a = this.lastpageNm - 1;
    this.selectedIndex = a;
  }

  active2() {
    let a = this.lastpageNm2 - 1;
    this.selectedIndex2 = a;
  }

  active3() {
    let a = this.lastpageNm3 - 1;
    this.selectedIndex3 = a;
  }
  active4() {
    let a = this.lastpageNm4 - 1;
    this.selectedIndex4 = a;
  }

  goToBottom() {
    $(window).scroll(function () {
      var height = $(window).scrollTop();
    });
    document.getElementById("goBottom1").scrollIntoView({
      behavior: "smooth",
    });
    document.getElementById("goBottom2").scrollIntoView({
      behavior: "smooth",
    });
    document.getElementById("goBottom3").scrollIntoView({
      behavior: "smooth",
    });
  }

  imgr(name) {
    let a = name.split(".");
    return a[0];
  }

  nextS() {
    var a = document.getElementById("allUl1");
    a.scrollLeft += 5000;
  }

  scCustom(no) {
    var element = document.getElementById("cuspagidiv1");
    var element2 = document.getElementById("cuspagidiv2");
    var element3 = document.getElementById("cuspagidiv3");
    if (this.allPcount > no) {
      if (this.allPcount > 100) {
        element.scrollLeft -= 50;
        element2.scrollLeft -= 50;
        element3.scrollLeft -= 50;
      } else {
        element.scrollLeft -= 30;
        element2.scrollLeft -= 30;
        element3.scrollLeft -= 30;
      }
    } else if (this.allPcount < no) {
      if (this.allPcount > 100) {
        element.scrollLeft += 50;
        element2.scrollLeft += 50;
        element3.scrollLeft += 50;
      } else {
        element.scrollLeft += 30;
        element2.scrollLeft += 30;
        element3.scrollLeft += 30;
      }
    }
  }

  setBorder(arrival) {
    if (arrival.payment_status == "unpaid" && arrival.status != "cancelled") {
      return "unpaid";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status != "cancelled"
    ) {
      return "unpaid";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status != "cancelled"
    ) {
      return "paid";
    } else if (
      arrival.payment_status == "unpaid" &&
      arrival.status == "cancelled"
    ) {
      return "cancelled";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status == "cancelled"
    ) {
      return "cancelled";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status == "cancelled"
    ) {
      return "cancelled";
    }
  }

  setBorderArr(arrival) {
    if (
      arrival.booking.payment_status == "unpaid" &&
      arrival.booking.status != "cancelled"
    ) {
      return "unpaid";
    } else if (
      arrival.booking.payment_status == "paynow" &&
      arrival.booking.status != "cancelled"
    ) {
      return "unpaid";
    } else if (
      arrival.booking.payment_status == "paid" &&
      arrival.booking.status != "cancelled"
    ) {
      return "paid";
    } else if (
      arrival.booking.payment_status == "unpaid" &&
      arrival.booking.status == "cancelled"
    ) {
      return "cancelled";
    } else if (
      arrival.booking.payment_status == "paid" &&
      arrival.booking.status == "cancelled"
    ) {
      return "cancelled";
    } else if (
      arrival.booking.payment_status == "paynow" &&
      arrival.booking.status == "cancelled"
    ) {
      return "cancelled";
    }
  }

  departurPending(arrival) {
    if (arrival.payment_status == "unpaid" && arrival.status != "cancelled") {
      return "check-out-orange";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status != "cancelled"
    ) {
      return "check-out-orange";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status != "cancelled"
    ) {
      return "check-out-green";
    } else if (
      arrival.payment_status == "unpaid" &&
      arrival.status == "cancelled"
    ) {
      return "check-out-red";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status == "cancelled"
    ) {
      return "check-out-red";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status == "cancelled"
    ) {
      return "check-out-red";
    }
  }

  chkIn(arrival) {
    if (arrival.payment_status == "unpaid" && arrival.status != "cancelled") {
      return "check-in-orange";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status != "cancelled"
    ) {
      return "check-in-orange";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status != "cancelled"
    ) {
      return "check-in-green";
    } else if (
      arrival.payment_status == "unpaid" &&
      arrival.status == "cancelled"
    ) {
      return "check-in-red";
    } else if (
      arrival.payment_status == "paid" &&
      arrival.status == "cancelled"
    ) {
      return "check-in-red";
    } else if (
      arrival.payment_status == "paynow" &&
      arrival.status == "cancelled"
    ) {
      return "check-in-red";
    }
  }

  dodrag() {
    $("#summary").draggable("enable");
  }

  stopDrag() {
    $("#summary").draggable("disable");
  }

  toggleTray(id) {
    let tray = <HTMLInputElement>document.getElementById(id);
    tray.style.right == "-245px"
      ? (tray.style.right = "-1px")
      : (tray.style.right = "-245px");
  }

  segmonLeft: boolean = true;

  changeSegment(con, id) {
    let segment = <HTMLInputElement>document.getElementById(id);
    con == "true"
      ? ((segment.style.left = "2%"),
        setTimeout(() => {
          this.segmonLeft = true;
        }, 200))
      : con == "false"
      ? ((segment.style.left = "52%"),
        setTimeout(() => {
          this.segmonLeft = false;
        }, 200))
      : (segment.style.display = "none");
  }

  convertTime(t) {
    return t != null ? moment(t, ["HH.mm"]).format("hh:mm a") : "--:--";
  }

  nightsCount(date_arrival, date_departure) {
    return moment(date_departure).diff(moment(date_arrival), "days");
  }

  specialReq(sr) {
    let a;
    try {
      if (sr) {
        let val: string = "";
        let a = JSON.parse(sr);
        a.SpecialRequest.map((el) => {
          val += el.Text["@content"] + ", ";
        });
        return val;
      }
    } catch (err) {
      return sr;
    }
    // if(sr){
    //   let val: string = '';
    //   let a = JSON.parse(sr);
    //   a.SpecialRequest.map((el)=>{
    //     val += el.Text['@content']+', ';
    //   })
    //   return val;
    // }
  }

  fliBox() {
    $(function () {
      setTimeout(() => {
        $("#flip").flipbox({
          vertical: true,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 5000,
          autoplayPauseOnHover: true,
        });
      }, 100);
    });
  }

  colorRadio(id, idt) {
    var a = document.getElementById(id);
    var b = document.getElementById(idt);
    a.classList.contains("radioByJs")
      ? a.classList.remove("radioByJs")
      : a.classList.add("radioByJs");
    b.classList.contains("radioByJs")
      ? b.classList.remove("radioByJs")
      : b.classList.add("radioByJs");
  }

  getWeather() {
    let cities = ["karachi", "new york", "toronto", "london", "cape town"];
    let user = JSON.parse(localStorage.getItem("user"));

    cities.forEach((city) => {
      let req =
        "https://api.openweathermap.org/data/2.5/weather?q=" +
        city +
        "&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1";
      let forcast =
        "https://api.openweathermap.org/data/2.5/forecast/daily?q=" +
        city +
        "&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4";
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split(".")[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString("en-us", { weekday: "short" }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: [],
        };

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach((element) => {
            var day = new Date(element.dt * 1000).toLocaleTimeString("en-us", {
              weekday: "short",
            });
            var icon = element.weather[0].icon.split(".")[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }
  currency() {
    this.current_currency = localStorage.getItem("current_currency");
  }
  subtotal(a: any, x: any) {
    console.log(`total - ${a}  Extra Person - ${x}`);
    if (null != a) {
      var str = a.split(",");
      var sum = str.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
      return (sum + parseInt(x));
    }
  }
  taxcaclute(a:any,tex:any){
    console.log(tex);
    var texper:any = (`0.${tex}`);
    if (null != a) {
      var str = a.split(",");
      var sum = str.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
     return(texper*sum) ;
    }
  }
}
