import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from "@angular/forms";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as CanvasJS from '../../../assets/js/canvasjs.min';
const moment = extendMoment(Moment);

declare var Chart: any;



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  charts = [
    { id: 1, name: 'Line View' },
    { id: 2, name: 'Bar View' },
    { id: 3, name: 'List View' },
  ];

  darkMode: boolean = false;

  selectedChart = 1;
  restriction: boolean = true
  all_data;
  date_to;
  date_from;
  channels;
  current_currency = localStorage.getItem("current_currency");

  compare_channels = [];
  compare_array = [];
  page_arr = [];
  pdfgen: boolean = false;

  choosen_date = { startDate: moment().subtract('15', 'days'), endDate: moment().add('15', 'days'), days: 14 };
  room_choosen_date = { startDate: moment(), endDate: moment() };

  date_ranges: any = [
    { name: '1 Month', range: { startDate: moment().subtract('15', 'days'), endDate: moment().add('16', 'days'), days: 15 } },
    { name: '2 Months', range: { startDate: moment().subtract('30', 'days'), endDate: moment().add('31', 'days'), days: 30 } },
    { name: '3 Months', range: { startDate: moment().subtract('45', 'days'), endDate: moment().add('46', 'days'), days: 45 } },
  ];
  choosen_range = [];
  showing_dates = [];

  graph_arr = [];
  points = [];
  dropDown;

  border_colors = ['#ff51f3', '#48fff3', '#ffc107', '#ec1361', '#09fb3d', '#9a00ff', '#e46300', 'red'];


  stats_box = { x: 760, y: 0 };
  graph_line_switch = 0;
  stats_box_data = {
    id: 0,
    image: '',
    checkins: 0,
    date: moment(),
    revenue: 0,
    rev_par: 0,
    avg_daily_rate: 0,
    avrr: 0,
    type: ''
  }

  current_page = 1;
  pagination = [];
  total_set = {
    occupany_rate: 0,
    total_nights: 0,
    avg_nights_per_room: 0,
    avg_daily_rate: 0,
    rev_par: 0,
    avg_lead: 0,
    channel_commission: 0,
    revenue_earned: 0,
    net_revenue: 0,
    cancellation: 0,
    reservations: 0
  };

  metrics = [
    { slug: '', name: 'Metrics' },
    { slug: 'checkins', name: 'Occupancy Rate' },
    { slug: 'avg_daily_rate', name: 'Average Daily Rate' },
    { slug: 'rev_par', name: 'Revenue Per Available Room' },
    // {slug: 'arr', name: 'Average Room Rate'},
  ];
  current_metric = 'checkins';
  pinDate = {
    startDate: '',
    endDate: ''
  };
  showTool: boolean = false;
  toolColor: any = 0;
  dropCH = 'By Channel';
  @ViewChild('grid_background', { static: false }) grid_background: ElementRef;

  roomWiseData = [];
  rdate_to: any;
  rdate_from: any;
  roomColors = [
    'red', '#9100ff', '#00aa06', '#0019ff', '#00ffee', '#3c008b', '#d8d800', '#ff7700', '#ff51f3', '#48fff3', '#ffc107', '#ec1361', '#09fb3d', '#9a00ff', '#e46300',
  ]

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {

    this.checkDarkMode();
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });
    $(function () {
      $(".headingOne0").mouseup(function () {
        $("#grab0").css({ "cursor": "grab" })
      });
      $(".headingOne0").mousedown(function () {
        $("#grab0").css({ "cursor": "grabbing" })
      });
    })
    // drag function start----------------------------------------------
    $(function () {
      setTimeout(function () {
        $(".drag_box").dragsort({
          dragSelector: ".headingOne0",
          dragBetween: true,
          dragEnd: () => {
            var data = $(".drag_box .drag_item").map(function () {
              return $(this).children().html();
            }).get();
          },
          placeHolderTemplate: "<div class='placeHolder' style='height:auto !important; width: 100%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='margin:3px;'>&nbsp;</div>" +
            "</div>",
          cursor: "grab"
        });
      }, 1000)
    });

    // drag function end---------------------------------------------

    this.getChannels();
    this.get_range();
  }

  move_box(e) {
    if (e.layerX > 0 && e.layerX < 1600) {
      this.stats_box.x = e.layerX - 105;
      this.stats_box.y = e.layerY - 90;
      if (this.graph_line_switch != undefined) {
        this.box_data(e);
      }
    }
  }

  makePDF() {
    const options = {
      background: 'white',
      scale: 1,
    };
    html2canvas(document.querySelector(".capture"), options).then((canvas) => {
      let img = canvas.toDataURL('image/png');
      let doc;
      if (this.channels.length <= 10) {
        doc = new jsPDF('p', 'mm', [305, 210]);
      } else if (this.channels.length > 10) {
        doc = new jsPDF('p', 'mm', [450, 210]);
      } else if (this.channels.length > 14) {
        doc = new jsPDF('p', 'mm', [550, 210]);
      }
      const bufferX = 3;
      const bufferY = 3;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      doc.save('Reports.pdf');
    });
    this.pdfgen = false;
  }

  box_data(evt) {
    let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);

    // Find your root SVG element
    let svg = document.querySelector('svg');

    // Create an SVGPoint for future math
    let pt = svg.createSVGPoint();
    pt.x = evt.clientX; pt.y = evt.clientY;
    let pos = pt.matrixTransform(svg.getScreenCTM().inverse());

    if (current_line) {
      let res = current_line.data.find((cl, idx, com) => {
        if (com[idx] && com[idx + 1]) {
          let min = com[idx].width || 0;
          let max = com[idx + 1].width || 1600;
          return pos.x >= Math.ceil(min) && pos.x <= Math.ceil(max);
        }
        return false;
      });
      if (res) {
        this.stats_box_data.checkins = res.check_in;
        this.stats_box_data.revenue = res.revenue;
        this.stats_box_data.rev_par = res.rev_par;
        this.stats_box_data.avg_daily_rate = res.avg_daily_rate;
        this.stats_box_data.avrr = res.avrr;
        this.stats_box_data.date = moment(res.date, 'YYYY-MM-DD');
      }
    }
  }

  get_range() {
    let range = [];
    let cd_start = this.choosen_date.startDate;
    let cd_end = this.choosen_date.endDate;
    let start = moment(cd_start).toDate();
    let end = moment(cd_end).toDate();
    let space = 0;
    let duration = moment.duration(cd_end.diff(cd_start)).asDays();
    let box_width = 1500 / duration;
    let counter = 1;
    this.showing_dates = [];
    while (start < end) {
      range.push({ date: moment(start), location: box_width * counter });
      start = new Date(start.setDate(start.getDate() + 1));
      counter++;
    }
    this.choosen_range = range;
    space = range.length > 30 ? range.length > 60 ? 6 : 4 : 3;
    let date_arr = range.map(x => x.date);
    this.showing_dates = date_arr.filter((date, index) => {
      return index % space == 0 ? date : false;
    });
    this.get_graph_data();
  }

  pdf_css() {
    this.helper.alert_success("Please Wait Your PDF will be Generated in 10 sec");
    this.pdfgen = true;
    setTimeout(() => {
      this.makePDF();
    }, 3000)
  }

  get_points(ch) {
    const smoothing = 0.15;
    const options = {
      yMin: 0,
      yMax: 380,
      xMin: 0,
      xMax: 1500
    }

    const map = (value, inMin, inMax, outMin, outMax) => {
      return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    };

    const pointsPositionsCalc = (points, w, h, options) => points.map(e => {
      const x = map(e.width, options.xMin, options.xMax, 0, w)
      const y = map(e.height, options.yMin, options.yMax, h, 0)
      return [x, y]
    })

    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
      }
    }

    const controlPoint = (line, smooth) => (current, previous, next, reverse) => {
      const p = previous || current
      const n = next || current
      const l = line(p, n)

      const angle = l.angle + (reverse ? Math.PI : 0)
      const length = l.length * smooth
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (controlPoint) => (point, i, a) => {
      const cps = controlPoint(a[i - 1], a[i - 2], point)
      const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
      const close = i === a.length - 1 ? ' z' : ''
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`
    }

    const svgPath = (points, command, h) => {
      const d = points.reduce((acc, e, i, a) => i === 0
        ? `M ${a[a.length - 1][0]},${h} L 0,${h} L 0,${e[1]} l0,0`
        : `${acc} ${command(e, i, a)}`
        , '')
      return d;
    }

    const w = 1500;
    const h = 380;
    const pointsPositions = pointsPositionsCalc(ch, w, h, options)
    const bezierCommandCalc = bezierCommand(controlPoint(line, smoothing))
    const path = svgPath(pointsPositions, bezierCommandCalc, h);
    return path;
  }

  switch_paths(name) {
    this.graph_line_switch = name;
    if (this.graph_arr) {
      let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);
      this.stats_box_data.id = current_line.channel_id;
      this.stats_box_data.image = current_line.channel_img;
      this.stats_box_data.type = current_line.type
    }
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range();
      this.getChannels();
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getChannels() {

    let start = this.choosen_date.startDate.format('YYYY-MM-DD');
    let end = this.choosen_date.endDate.format('YYYY-MM-DD');
    let property_id = localStorage.getItem("current_property");
    this.api.post('api/report/channel_performance', { property_id: property_id, date_departure_from: start, date_departure_to: end }, true).subscribe((res: any) => {
      this.channels = res.body.data;
      if (this.channels != undefined) {
        this.total_set.revenue_earned = 0;
        this.channels.map(c => this.total_set.revenue_earned += c.total);
        this.total_set.avg_daily_rate = this.channels.map(c => c.avg_daily_rate).reduce((a, b) => a + b, 0);
        this.total_set.total_nights = this.channels.map(c => c.total_nights).reduce((a, b) => a + b, 0);
        this.total_set.channel_commission = this.channels.map(c => c.channel_commission).reduce((a, b) => a + b, 0);
        this.total_set.avg_lead = this.channels.map(c => c.avg_lead).reduce((a, b) => a + b, 0);
        this.total_set.net_revenue = this.channels.map(c => c.net_revenue).reduce((a, b) => a + b, 0);
        this.total_set.rev_par = this.channels.map(c => c.rev_par).reduce((a, b) => a + b, 0);
        this.total_set.occupany_rate = this.channels.map(c => c.occupany_rate).reduce((a, b) => a + b, 0);
        this.total_set.avg_nights_per_room = this.channels.map(c => c.total_nights / c.room_count).reduce((a, b) => a + b, 0);
        this.total_set.cancellation = this.channels.map(c => c.cancellation).reduce((a, b) => a + b, 0);
        this.total_set.reservations = this.channels.map(c => c.reservations).reduce((a, b) => a + b, 0);
        console.log(this.channels)
        this.channels.forEach((c, i) => {
          c.color = this.border_colors[i % this.border_colors.length];
          c.avg_nights_per_room = Math.round(c.total_nights / c.room_count);
          c.rev_percentage = Math.round(((c.net_revenue + c.channel_commission) / this.total_set.revenue_earned) * 100);
          c.per_occupany_rate = Math.round(parseInt(((c.occupany_rate / this.total_set.occupany_rate) * 100).toFixed(2))) + '%';
          c.per_rev_par = Math.round(parseInt(((c.rev_par / this.total_set.rev_par) * 100).toFixed(2))) + '%';
          c.per_total_nights = Math.round(parseInt(((c.total_nights / this.total_set.total_nights) * 100).toFixed(2))) + '%';
          c.per_avg_daily_rate = Math.round(parseInt(((c.avg_daily_rate / this.total_set.avg_daily_rate) * 100).toFixed(2))) + '%';
          c.per_avg_lead = Math.round(parseInt(((c.avg_lead / this.total_set.avg_lead) * 100).toFixed(2))) + '%';
          c.per_channel_commission = Math.round(parseInt(((c.channel_commission / this.total_set.channel_commission) * 100).toFixed(2))) + '%';
          c.per_revenue_earned = Math.round(parseInt(((c.total / this.total_set.revenue_earned) * 100).toFixed(2))) + '%';
          c.per_net_revenue = Math.round(parseInt(((c.net_revenue / this.total_set.net_revenue) * 100).toFixed(2))) + '%';
          c.per_avg_nights_per_room = Math.round(parseInt(((c.avg_nights_per_room / this.total_set.avg_nights_per_room) * 100).toFixed(2))) + '%';
        });
      } else {
      }
      this.set_compare_channels();
    })
  }

  drawDoughnut() {
    var DonutChart = function (canvas, radius, lineWidth, arraySlices, label) {
      this._radius = radius;
      this._lineWidth = lineWidth; //px
      this._arraySlices = arraySlices;
      this._label = label;
      this._x_center = canvas.width / 2;
      this._y_center = canvas.height / 2;
      this._context = canvas.getContext("2d");

      this.drawCircle = function () {
        var context = this._context;
        context.lineWidth = this._lineWidth;
        var radius = this._radius;
        var offset_radians = -0.5 * Math.PI;
        var start_radians = offset_radians;
        var counterClockwise = true;
        var self = this;
        this._arraySlices.forEach(function (slice) {
          context.beginPath();
          context.strokeStyle = slice.color;
          var end_radians = start_radians - (Math.PI * 2) * slice.percent / 100;
          context.arc(self._x_center, self._y_center, radius,
            start_radians, end_radians, counterClockwise);
          context.stroke();
          start_radians = end_radians;
        });
      };

      this.drawText = function () {
        var fontSize = this._label.font_size;
        var context = this._context;
        context.font = 'bolder 45px Roboto Black';
        context.textAlign = 'center';
        context.fillStyle = this._label.color;
        context.background_color = this._label.background_color
        var text = this._label.text;
        context.fillText(text, this._x_center, this._y_center + fontSize / 2);
      };

      this.render = function () {
        this.drawCircle();
        this.drawText();
      };

    };



    $(function () {
      //create canvas
      var canvasElem$ = $("<canvas/>");
      $("#content").append(canvasElem$);
      var canvas = $(canvasElem$).get(0);
      var sideLength = 250;
      canvas.width = canvas.height = sideLength;
      var lineWidth = 50;
      var radius = (sideLength - lineWidth) / 2;
      // var label = "Label text";

      var slices = [{
        percent: 15,
        color: "red"
      }, {
        percent: 1,
        color: "#ffffff"
      }, {
        percent: 15,
        color: "#ec13a0"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 15,
        color: "#283ed8"
      }, {
        percent: 1,
        color: "#ffffff"
      }, {
        percent: 8,
        color: "#9a00ff"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 8,
        color: "#a7002d"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 5,
        color: "#48fff3"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 6,
        color: "#e46300"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 22,
        color: "#09fb3d"
      },
      {
        percent: 1,
        color: "#ffffff"
      }
      ];

      var label = {
        text: "73%",
        color: "#7e7cea",
        font_size: 25,
        font_weight: 800,
        font_family: 'robotoblack',
        background_color: "#ffffff",

      };

      var donutChart = new DonutChart(canvas, radius, lineWidth, slices, label);
      donutChart.render();
    });
  }

  dougnuts() {

    this.compare_array.forEach((c, i) => {
      setTimeout(() => {
        const segments = document.querySelectorAll(".donut-segment" + i);
        const firstSegmentOffset = parseInt(segments[0].getAttribute("stroke-dashoffset"));
        let preSegmentsTotalLength = parseInt(segments[0].getAttribute("data-per"));

        segments.forEach((segment, i) => {
          // percentage occupied by current segment
          let percent = parseInt(segment.getAttribute("data-per"));

          // current segments stroke-dasharray calculation
          let strokeDasharray = `${percent} ${100 - percent}`;
          // setting stroke-dasharray for all segments
          segment.setAttribute("stroke-dasharray", strokeDasharray);
          if (i != 0) {
            // current segments stroke-dashoffset calculation
            let strokeDashoffset = `${100 - preSegmentsTotalLength + firstSegmentOffset}`;
            // setting stroke-dasharray for all segments
            segment.setAttribute("stroke-dashoffset", strokeDashoffset);
            // Updating total of all preceding segments length
            preSegmentsTotalLength += percent;
          }
        })
      }, 1000);
    })

  }

  set_compare_channels() {
    this.compare_array = [];
    if (this.compare_channels.length > 1) {
      this.compare_array = this.channels.filter(c => this.compare_channels.indexOf(c.channel_id) != -1);
      this.compare_array
    } else {
      this.compare_array = this.channels != undefined ? JSON.parse(JSON.stringify(this.channels)) : [];
      this.compare_array
    }
    this.create_pagination();
    this.dougnuts();
    //console.log(this.compare_array)
  }

  //tested this
  change_page(move_to) {
    if (move_to == 'forward') {
      this.current_page < this.pagination.length ? this.current_page++ : null;
    } else if (move_to == 'backward') {
      this.current_page > 1 ? this.current_page-- : null;
    } else if (move_to == 'first') {
      this.current_page = 1;
    } else if (move_to == 'last') {
      this.current_page = this.pagination.length;
    } else {
      this.current_page = move_to;
    }
  }

  create_pagination() {
    let chunk = 8;
    this.page_arr = [];
    for (let i = 0; i < this.compare_array.length; i += chunk) {
      this.page_arr.push(this.compare_array.slice(i, i + chunk));
    };
    this.page_arr.forEach(e => {
      e.sort((a, b) => a.total < b.total ? -1 : (a.total > b.total ? 1 : 0));
      e.reverse();
    });
    this.pagination = this.page_arr.map((m, i) => i + 1);
  }

  // test this
  reset_filter() {
    this.selectedChart = 1;
    this.compare_channels = [];
    this.choosen_date = { startDate: moment().subtract('15', 'days'), endDate: moment().add('15', 'days'), days: 15 }
    this.current_page = 1;

    this.get_range();
    this.getChannels();
  }

  get_graph_data() {
    let graph = [];
    //let property_id = 10;
    let property_id = localStorage.getItem("current_property");
    let date_departure_from = this.choosen_date.startDate.format('YYYY-MM-DD');
    let date_departure_to = this.choosen_date.endDate.format('YYYY-MM-DD');
    this.pinDate = {
      startDate: date_departure_from,
      endDate: date_departure_to
    }
    this.api.post('api/report/get_graph_data', { property_id, date_departure_to, date_departure_from }, true).subscribe((res: any) => {
      let data = res.body.data;
      let max_value = Math.max.apply(null, data.map(m => m.total_sum));
      data = data.reduce((a, b) => {
        if (b.channel != null) {
          a[b.channel.id] = [...a[b.channel.id] || [], b];
        }
        return a;
      }, [])
      data = data.sort(function (a, b) {
        return a[0].total_sum - b[0].total_sum;
      });
      data = data.reverse();
      data.forEach((ch) => {
        let arr = [];
        this.choosen_range.forEach((r, i) => {
          let date = r.date.format('YYYY-MM-DD');
          let already = ch.find(f => date == f.date_departure);
          if (!already) {
            arr.push({
              id: i,
              date: date,
              check_in: 0,
              revenue: 0,
              rev_par: 0,
              avg_daily_rate: 0,
              avrr: 0,
              width: r.location,
              height: (360 / 100) * ((0 / max_value) * 100),
            });
          } else {
            arr.push({
              id: i,
              date: date,
              check_in: already.check_in_count,
              revenue: already.total_sum,
              rev_par: already.rev_par,
              avg_daily_rate: already.avg_daily_rate,
              avrr: parseInt(already.avrr),
              width: r.location,
              height: (360 / 100) * ((already.total_sum / max_value) * 100),
            });
          }
        });
        arr.sort((a, b) => {
          return new Date(b.date) > new Date(a.date) ? -1 : new Date(b.date) < new Date(a.date) ? 1 : 0;
        });
        graph.push({ channel_id: ch[0].channel.id, channel_img: ch[0].channel.image, data: arr, points: this.get_points(arr), type: ch[0].channel.type })
      });

      this.graph_arr = graph;
      //this.setGraph();
      if (graph.length != 0) this.switch_paths(graph[0].channel_id);
    });
    // setTimeout(()=>{
    //   this.setGraph();
    // },1000)
  }

  //test this
  date_change(e) {
    if (e) {
      this.get_range();
      this.getChannels();

    }
  }

  change_center_date(date) {
    let days = this.choosen_date.days;
    this.choosen_date = { startDate: moment(date).subtract(days, 'days'), endDate: moment(date).add(days, 'days'), days: days };
    this.get_range();
    this.getChannels();
  }

  donutSet(page) {
    let cr = [];
    let rem = 0;

    if (page.length >= 8) {
      for (let c = 0; c < 8; c++) {
        cr.push(page[c]);
        rem += page[c].rev_percentage;
      }
    } else {
      for (let c = 0; c < page.length; c++) {
        cr.push(page[c]);
        rem += page[c].rev_percentage;
      }
    }

    let a = 100 - rem;
    let b = a / 8;
    let f = 0;
    cr.map((val) => {
      val.rev_percentage += Math.round(b);
      f += val.rev_percentage;
    });
    let o = 100 - f;
    cr[0].rev_percentage += o;
    return cr;
  }

  compreSet(arr) {
    let r = [];
    for (let i = 0; i < 8; i++) {
      r.push(arr[i]);
    }
    return r;
  }

  changeGrp($event) {
    setTimeout(() => {
      //this.selectedChart == 1 ? this.setGraph() : null
    }, 300);
  }

  changeMet($event) {
    //console.log(this.current_metric);
    //this.setGraph();
  }

  // setGraph(){
  //   console.log(this.graph_arr);
  //   if(this.graph_arr.length<= 0){
  //     return false;
  //   }
  //   let arr = this.graph_arr;
  //   let a = [];
  //   let b = [];
  //   let f = [];
  //   let maxY = 0;
  //   arr.map((val,i)=>{
  //     val.data.map((val2)=>{
  //       b.push({
  //         x: new Date(val2.date),
  //         y: val2.revenue,
  //         cn: val2.check_in,
  //         rp: val2.rev_par,
  //         arr: val2.avrr,
  //         adr: val2.avg_daily_rate,
  //         date:  moment(val2.date).format('Do, MMMM'),
  //         day: moment(val2.date).format('dddd')
  //       })
  //     })

  //     a.push({
  //       type: "spline",
  //       //axisYType: "secondary",
  //       name: val.channel_img.split('.')[0].toUpperCase(),
  //       markerSize: 1,
  //       showInLegend: true,
  //       dataPoints: b,
  //       label: val.channel_img,
  //       lineColor: this.border_colors[i]
  //     })
  //     b = [];
  //   })

  //   if(a.length >= 5 ){
  //     for(let x = 0; x < 5; x++){
  //       f.push(a[x])
  //     }
  //   }else{
  //     for(let x = 0; x < a.length; x++){
  //       f.push(a[x])
  //     }
  //   }

  //   //f.splice(0, 1)
  //   //f.shift();
  //   var chart = new CanvasJS.Chart("chartContainer", {
  //      animationEnabled: true,
  //      theme: "light2",
  //      backgroundColor: '#f3f3f3',
  //      axisY: {
  //       //suffix: "%",
  //       //minimum: 100,

  //       labelFormatter: function(e) {
  //         return Math.round((e.value/maxY*100)) +"%";
  //       },
  //       //maximum: '100',
  //      },
  //      axisX:{
  //       // gridThickness: 1,
  //       // valueFormatString: "DD/MMM",
  //       gridThickness: 1,
  //       tickLength: 0,
  //       lineThickness: 0,
  //       labelFormatter: function(){
  //         return " ";
  //       }
  //      },
  //      legend: {
  //        cursor: "pointer",
  //        itemclick: this.toogleDataSeries
  //      },
  //      toolTip: {
  //        shared: false,
  //        animationEnabled: true,
  //        //content: '<div>Name: {name}<br/>Revenue: {y}</div>',
  //         content: this.current_metric == 'checkins' ?
  //         `<div style='\"'width: 210px;'\"'>
  //           <img src='\"'assets/img/svgicons/allicons/{label}'\"' style='\"'height: 20px;'\"'/>
  //           <hr style='\"'margin-bottom: 3px;margin-top: 4px;border-width: medium;'\"'/>
  //             <div style='\"'display:flex;height:50px;'\"'>
  //               <div  style='\"'width:50%; text-align:left'\"'>
  //                   <div class='\"'checkin-box'\"'>
  //                       <p style='\"'color: #ca365f;font-family: 'robotoblack';margin: 0'\"'>Occupancy</p>
  //                       <img style='\"'margin-right: 5px;margin-top: 2px;float: left!important;'\"' src='\"'assets/img/reports/checkin.png'\"'>
  //                       <span style='\"'margin-top: -27px; margin-right: -10px;color: #ca365f; font-family: robotoblack;font-size: 20px;'\"'>{cn}</span>
  //                   </div>
  //               </div>

  //               <div style='\"'width:50%; text-align:right'\"'>
  //                   <div style='\"'font-weight: 600;font-size: 14px;font-family: 'robotoblack';'\"'>{date}</div>
  //                   <div style='\"'font-size: 10px; margin-top: -3px;font-family: 'robotoblack';'\"'>{day}</div>
  //                   <div style='\"'text-align: end;font-weight: 600;font-size: 18px;font-family: 'robotoblack'; margin-bottom:2px;'\"'>'$'{y}</div>
  //               </div>
  //             </div>
  //         </div>`
  //         : this.current_metric == 'avg_daily_rate' ?
  //         `<div style='\"'width: 210px;'\"'>
  //           <img src='\"'assets/img/svgicons/allicons/{label}'\"' style='\"'height: 20px;'\"'/>
  //           <hr style='\"'margin-bottom: 3px;margin-top: 4px;border-width: medium;'\"'/>
  //             <div style='\"'display:flex;height:50px;'\"'>
  //               <div  style='\"'width:50%; text-align:left'\"'>
  //                   <div class='\"'checkin-box'\"'>
  //                       <p style='\"'color: #ca365f;font-family: 'robotoblack';margin: 0'\"'>ADR</p>
  //                       <img style='\"'margin-right: 5px;margin-top: 2px;float: left!important;'\"' src='\"'assets/img/reports/checkin.png'\"'>
  //                       <span style='\"'margin-top: -27px; margin-right: -10px;color: #ca365f; font-family: robotoblack;font-size: 20px;'\"'>{adr}</span>
  //                   </div>
  //               </div>

  //               <div style='\"'width:50%; text-align:right'\"'>
  //                   <div style='\"'font-weight: 600;font-size: 14px;font-family: 'robotoblack';'\"'>{date}</div>
  //                   <div style='\"'font-size: 10px; margin-top: -3px;font-family: 'robotoblack';'\"'>{day}</div>
  //                   <div style='\"'text-align: end;font-weight: 600;font-size: 18px;font-family: 'robotoblack'; margin-bottom:2px;'\"'>'$'{y}</div>
  //               </div>
  //             </div>
  //         </div>`
  //         :  this.current_metric == 'rev_par' ?
  //         `<div style='\"'width: 210px;'\"'>
  //         <img src='\"'assets/img/svgicons/allicons/{label}'\"' style='\"'height: 20px;'\"'/>
  //         <hr style='\"'margin-bottom: 3px;margin-top: 4px;border-width: medium;'\"'/>
  //           <div style='\"'display:flex;height:50px;'\"'>
  //             <div  style='\"'width:50%; text-align:left'\"'>
  //                 <div class='\"'checkin-box'\"'>
  //                     <p style='\"'color: #ca365f;font-family: 'robotoblack';margin: 0'\"'>RevPAR</p>
  //                     <img style='\"'margin-right: 5px;margin-top: 2px;float: left!important;'\"' src='\"'assets/img/reports/checkin.png'\"'>
  //                     <span style='\"'margin-top: -27px; margin-right: -10px;color: #ca365f; font-family: robotoblack;font-size: 20px;'\"'>{rp}</span>
  //                 </div>
  //             </div>

  //             <div style='\"'width:50%; text-align:right'\"'>
  //                 <div style='\"'font-weight: 600;font-size: 14px;font-family: 'robotoblack';'\"'>{date}</div>
  //                 <div style='\"'font-size: 10px; margin-top: -3px;font-family: 'robotoblack';'\"'>{day}</div>
  //                 <div style='\"'text-align: end;font-weight: 600;font-size: 18px;font-family: 'robotoblack'; margin-bottom:2px;'\"'>'$'{y}</div>
  //             </div>
  //           </div>
  //       </div>`
  //         : this.current_metric == 'arr' ?
  //         `<div style='\"'width: 210px;'\"'>
  //           <img src='\"'assets/img/svgicons/allicons/{label}'\"' style='\"'height: 20px;'\"'/>
  //           <hr style='\"'margin-bottom: 3px;margin-top: 4px;border-width: medium;'\"'/>
  //             <div style='\"'display:flex;height:50px;'\"'>
  //               <div  style='\"'width:50%; text-align:left'\"'>
  //                   <div class='\"'checkin-box'\"'>
  //                       <p style='\"'color: #ca365f;font-family: 'robotoblack';margin: 0'\"'>ARR</p>
  //                       <img style='\"'margin-right: 5px;margin-top: 2px;float: left!important;'\"' src='\"'assets/img/reports/checkin.png'\"'>
  //                       <span style='\"'margin-top: -27px; margin-right: -10px;color: #ca365f; font-family: robotoblack;font-size: 20px;'\"'>{arr}</span>
  //                   </div>
  //               </div>

  //               <div style='\"'width:50%; text-align:right'\"'>
  //                   <div style='\"'font-weight: 600;font-size: 14px;font-family: 'robotoblack';'\"'>{date}</div>
  //                   <div style='\"'font-size: 10px; margin-top: -3px;font-family: 'robotoblack';'\"'>{day}</div>
  //                   <div style='\"'text-align: end;font-weight: 600;font-size: 18px;font-family: 'robotoblack'; margin-bottom:2px;'\"'>'$'{y}</div>
  //               </div>
  //             </div>
  //         </div>`: null,
  //         cornerRadius: '8'
  //      },
  //       data: f
  //   });

  //   // for (var i=0; i<chart.options.data[0].dataPoints.length; i++) {
  //   //   maxY = Math.max(chart.options.data[0].dataPoints[i].y, maxY);
  //   // }

  //   if(a.length> 0){
  //     // for (var i=0; i<chart.options.data[0].dataPoints.length; i++) {
  //     //   maxY = Math.max(chart.options.data[0].dataPoints[i].y, maxY);
  //     // }
  //     let na = [];
  //     chart.options.data.forEach((val)=>{
  //       //console.log(JSON.stringify(val));
  //       val.dataPoints.forEach((val2)=>{
  //         na.push(val2.y)
  //       })
  //     })
  //     maxY = Math.max(...na);
  //     chart.options.axisY.maximum = maxY;
  //     chart.options.axisY.interval = maxY / 10;
  //   }
  //   chart.render();

  //   //chart.toolTip.showAtX(new Date());
  //   let dat = new Date()

  //   setTimeout(()=>{
  //     console.log(f);
  //     let d1:any = dat.getFullYear();
  //     let d2:any = dat.getMonth();
  //     let d3:any = dat.getDate();
  //     let m1:any = parseInt(d2) <= 9 ? '0'+ parseInt(d2) : d2;
  //     let m2 :any = parseInt(m1) <= 9 ? parseInt(m1)+1 : parseInt(m1);
  //     let m3:any = parseInt(m2) <= 9 ? '0'+ parseInt(m2) : m2;
  //     console.log(new Date(d1+'-'+m3+'-'+ d3))
  //     chart.toolTip.showAtX(new Date(d1+'-'+ m3 +'-'+d3));
  //   }, 500)


  // }

  //   toogleDataSeries(e) {
  //     if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
  //       e.dataSeries.visible = false;
  //     } else {
  //       e.dataSeries.visible = true;
  //     }
  //     e.chart.render();
  //   }

  pinIt(c) {
    let pinObj = {
      user_id: JSON.parse(localStorage.getItem('user')).id,
      property_id: localStorage.getItem('current_property'),
      channel_id: c.channel_id,
      date_departure_from: this.pinDate.startDate,
      date_departure_to: this.pinDate.endDate
    };
    this.api.post('api/helper/pin_point_store', pinObj, true).subscribe((res: any) => {
      this.helper.alert_success(res.body.message);
    })
  }

  ppp(pa) {
    console.log(pa);
  }

  changeDrop() {
    this.dropDown = !this.dropDown
  }

  di(i) {
    console.log(i);
    return i.length == 8 ? true : false
  }

  sortCompareArr(items) {
    return items.sort((a, b) => b.total - a.total);
  }

  roundNo(no) {
    return Math.round(no)
  }

  //Reports Room wise
  room_date_change(date) {
    this.getroomWiseData(this.room_choosen_date.startDate.format('YYYY-MM-DD'));
  }

  getroomWiseData(date) {
    let d = {
      day_select: date,
      property_id: localStorage.getItem("current_property")
    }
    if (d.day_select != undefined) {
      if (this.dropCH == 'By Room') {
        this.api.post('api/report/report_room_type', d, true).subscribe((res: any) => {
          this.roomWiseData = res.body.data;
          console.log(this.roomWiseData);
          setTimeout(() => {
            this.roomWiseData.map((val, i) => {
              let l = [];
              let d = [];
              val.otas.map((val2) => {
                l.push(val2.name);
                d.push(val2.rate)
              })
              this.setRoomDonut(d, l, i);
            })
          }, 500)
        },
          err => this.error_handling.handle_error(err.status, err.message));
      }
    }
    else {
      this.getChannels();
      this.get_range();
    }
  }

  setRoomDonut(d, l, id) {
    let ctx = document.getElementById('roomChart' + id);
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: { position: 'bottom' },
        datasets: [{
          data: d,
          backgroundColor: this.roomColors,
          borderWidth: 0
        }]
      },
      options: {
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            padding: 20
          }
        },
        aspectRatio: 0,
        cutoutPercentage: 60,
        responsive: false,
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
      }
    });
  }
  roomdonut(a) {
    a.forEach(element => {
      return element;
    });
  }

  getOTAs(OTAs: any[]) {
    return OTAs;
  }

}
