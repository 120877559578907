import { Component, OnInit } from '@angular/core';
declare var $:any;
declare var google:any;

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  constructor() { }

  language = 'English';
  darkMode: boolean = false;

  ngOnInit() {
    this.checkDarkMode();
  }

  arrow_toggle(id){
    $('img:not(#'+id+')').each(function(){
      $(this).removeClass('arr_down');
    });
    $('#'+id).toggleClass('arr_down');
  }


  chngedrop(id){
    let a = (<HTMLInputElement>document.getElementById(id))
  if(a.classList.contains('trans180')){
   a.classList.remove('trans180')
  }
  else{
   a.classList.add('trans180')
  }
}

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }


}
