import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from './dashboard.service';
import { first } from 'rxjs/operators';
// import { Observable, Subscription} from 'rxjs';
import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/forkJoin';
import { forkJoin } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;
import * as moment from 'moment';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { GlobaldataService } from '../../common/globaldata.service';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { CardLayout } from "./CardLayout";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  cardLayout: CardLayout[] = [];
  general = false;
  temp: number;
  city: string;
  state: string;
  capitals = [];
  selectedCity;
  cardCity;
  gif;

  showNote = false;
  followedCM = false;
  sub1;
  hum: number;
  wind: number;
  today: string;
  daysForecast: Object;
  index = [];
  cityIllustrationPath: string;
  sub2: Subscription;
  errorMessage: string;
  tweets$: Observable<any>;
  todayDate;
  DayState;
  req;
  forcast;
  user = '';
  weather_update = [];
  weather_classes = ['txt-orangered', 'txt-purple', 'txt-skyblue', 'txt-green', 'txt-blue', 'txt-orange', 'txt-aqua']
  dashboard: any;
  darkMode: boolean = false;
  activeOta = [];
  activeCha = [];
  nonactive: any;
  awaiting: any;
  compare_array: any[] = [];
  border_colors = ['#f53232', '#0fdc96', 'green', 'yellow', 'purple', 'orange', 'blue', '#00a6ff'];
  colors = ['#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  color_border = ['#ff5e57', '#a07cc6', '#6af686', '#48fff3', '#b3bf00'];
  color_borders_dark = ['#f53232', 'green', 'purple', 'orange', 'blue', 'olive'];
  grph: boolean = false;
  frontDesk: any;
  graphDays = [
    { name: 'Today' },
    { name: 'Previous Month' },
    { name: 'Current Month' }
  ];
  graphDaysota = [
    { name: 'Today' },
    { name: 'Previous Month' },
    { name: 'Current Month' }
  ];
  graphDaysP;
  graphDaysPota;
  dashboard_skeleton: boolean = true;

  constructor(
    public http: HttpClient,
    public weather: DashboardService,
    private api: ApiService,
    private error_handling: ErrorHandlingService
  ) { }

  ngOnInit() {

    console.log('dashboard')
    //this.speedometter();
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });

    this.getWH();

    this.checkDarkMode();
    
    this.api.get('api/user/me', true).subscribe((res: any) => {
      this.getDashboardData();
      this.getHolidays();
    }, err => this.error_handling.handle_error(err.status));


    $(function () {
      setTimeout(function () {
        $("#drag_box").dragsort({
          dragSelector: ".jumbo-icon",
          // dragBetween: true,
          dragEnd: () => {

            var data = $("#drag_box .drag_item").map(function () {

            }).get();

            let arr = [];

            // $("#drag_box .drag_item").map(function () {
            //   let v = $(this).attr("index");
            //   let k = $(this).attr("index-data");
            //   let value: CardLayout = {
            //     cardName: k,
            //     cardKey: v,
            //   };
            //   arr.push(value);

            // });

            var drag_item = document.getElementsByClassName('drag_item');
            var d = [];
            for (let i = 0; i < 6; i++) {
              d.push({ "cardKey": drag_item[i].getAttribute('index'), cardName: drag_item[i].getAttribute('index-data') });
            }


            localStorage.setItem("CardLayout", JSON.stringify(d));
            var settings = {
              "url": "https://cmtest.pointclickintegrate.com/api/user/sorting",
              "method": "POST",
              "timeout": 0,
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('auth_token'),
              },
              "data": JSON.stringify({
                "type": "Dashboard",
                "data": d
              }),
            };

            $.ajax(settings).done(function (response) {
              console.log(response);
            });







          },

          placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: transparent !important; height: 100%!important'>&nbsp;</div>" +
            "</div>",
          cursor: "move"
        });
      }, 1000)
    });
    this.loadCardLayout();
    // this.updateCardLayout();
  }

  // startTour() {
  //   this.joyrideService.startTour(
  //     {
  //       steps: ["firstStep", "secondStep", "thirdStep", "fourthStep", "fifthStep", "sixthStep"],
  //       waitingTime: 200
  //     }
  //   );
  // }

  getWH() {
    let cities = ["karachi"];
    //   var cities:any ='';
    // var prop;cities=null!=JSON.parse(localStorage.getItem("property"))?[(prop=JSON.parse(localStorage.getItem("property"))).city]:["Toronto"];
    cities.forEach(city => {
      let req = 'https://api.openweathermap.org/data/2.5/weather?q=' + city + '&units=imperial&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1&lang=en';
      let forcast = 'https://api.openweathermap.org/data/2.5/forecast/daily?q=' + city + '&cnt=7&lang=en&units=imperial&APPID=bfab95ebe3bbb8966c54139aefd539b4';
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split('.')[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString('en-us', { weekday: 'short' }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: []
        }

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach(element => {
            var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', { weekday: 'short' });
            var icon = element.weather[0].icon.split('.')[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getDashboardData() {
    let dashObj = {
      property_id: localStorage.getItem("current_property"),
      date_departure_from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      date_departure_to: moment().format('YYYY-MM-DD'),
      filter: this.graphDaysP
    }

    this.api.post('api/report/dashboard', dashObj, true).subscribe((res: any) => {
      this.dashboard = res.body.data;
      this.compare_array = res.body.data.pin_points;
      this.frontDesk = res.body.data.front_desk;
      this.dashboard_skeleton = false;
      this.activeOta = res.body.data.channels.filter((e) => { return e.status == "Active" && e.type == 'External' });
      this.activeCha = res.body.data.channels.filter((e) => { return e.status == "Active" && e.type == 'Internal' });
      this.nonactive = res.body.data.channels.filter((e) => { return e.status == "Disabled" });
      this.awaiting = res.body.data.channels.filter((e) => { return e.status == "Awaiting" });
      //this.speedometter();
      //this.startTour();
    },
      err => this.error_handling.handle_error(err.status, err.message));
  }

  per(value, outOff) {
    let fv = (value * 100) / outOff;
    return fv > 20 ? fv : 50;
  }

  getHolidays() {
    this.api.get('api/helper/get_holidays', true).subscribe((res: any) => {
      GlobaldataService.holidays = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  imgr(name) {
    let a = name.split('.');
    return a[0];
  }

  donutSet(page) {
    let cr = [];
    let rem = 0;

    if (page.length >= 8) {
      for (let c = 0; c < 8; c++) {
        cr.push(page[c]);
        rem += page[c].rev_percentage;
      }
    } else {
      for (let c = 0; c < page.length; c++) {
        cr.push(page[c]);
        rem += page[c].rev_percentage;
      }
    }

    let a = 100 - rem;
    let b = a / 8;
    let f = 0;
    cr.map((val) => {
      val.rev_percentage += Math.round(b);
      f += val.rev_percentage;
    });
    let o = 100 - f;
    cr[0].rev_percentage += o;
    return cr;
  }

  deleteReport(id) {
    console.log(id);
    this.api.get('api/helper/pin_point_delete/' + id, true).subscribe((res: any) => {
      this.getDashboardData();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  readReserve(id) {
    this.api.get('api/booking/mark_as_read/' + id, true).subscribe((res: any) => {
      this.getDashboardData();
    })
  }

  readNotif(id) {
    this.api.get('api/user_keynote/mark_as_read_keynote/' + id, true).subscribe((res: any) => {
      this.getDashboardData();
    })
  }

  dragHeaderTrue() {
    $(".dashboard-card").draggable({
      revert: true,
      opacity: 1,
      zIndex: 10000,
      disabled: false
    });
    alert('dfs');
  }

  dragHeaderFalse() {
    $(".dashboard-card").draggable({
      revert: true,
      opacity: 1,
      zIndex: 10000,
      disabled: true
    });
  }

  checkCollapse(Aid, Iid) {
    let anchor = document.getElementById(Aid);
    let image = document.getElementById(Iid);
    anchor.classList.contains('collapsed') ? image.classList.remove('rotate180') : image.classList.add('rotate180')
  }

  speedometter() {
    var canvasId = "myCanvas";

    function speedometer(value, text) {
      var c = <HTMLCanvasElement>document.getElementById(canvasId);
      var ctx = c.getContext("2d");
      var width = 200;
      var x = 130;
      var y = 80;
      var radius = 70;
      var minRad = 0.75 * Math.PI;
      var maxRad = 2.25 * Math.PI;
      var valueRad = (maxRad - minRad) * value + minRad;
      var toolTipWidth = 0;
      var toolTipHeight = 0;
      var toolTipArrowHeight = 0;

      // Create gradient
      var grd = ctx.createLinearGradient(x - radius, 0, x - radius + width, 0);
      // this.darkMode ? grd.addColorStop(0,"#7ea5ff") : grd.addColorStop(0,"#E2125D")
      grd.addColorStop(0, "#7ea5ff");
      // grd.addColorStop(0.25,"#E2125D");
      // grd.addColorStop(0.5, "#E2125D");
      // grd.addColorStop(0.75,"#E2125D");
      // grd.addColorStop(1,"#E2125D");

      var grd2 = ctx.createLinearGradient(0, 0, toolTipWidth, 0);
      grd2.addColorStop(0, "white");
      grd2.addColorStop(1, "#E2125D");

      //background
      ctx.beginPath();
      ctx.arc(x, y, radius, minRad, maxRad);
      ctx.lineWidth = 20;
      ctx.lineCap = "round";
      ctx.strokeStyle = "#dbe5fe";
      ctx.stroke();

      //foreground
      ctx.beginPath();
      ctx.arc(x, y, radius, minRad, valueRad);
      ctx.lineWidth = 20;
      ctx.lineCap = "round";
      ctx.strokeStyle = grd;
      ctx.stroke();

      ctx.beginPath();
      ctx.translate(x, y);
      ctx.rotate(valueRad);
      ctx.rect(radius + toolTipArrowHeight, -toolTipWidth / 2, toolTipHeight, toolTipWidth);
      ctx.moveTo(radius + toolTipArrowHeight, -toolTipWidth / 2);
      ctx.lineTo(radius, 0);
      ctx.lineTo(radius + toolTipArrowHeight, toolTipWidth / 2);
      ctx.fillStyle = grd2;
      ctx.fill();

      ctx.rotate(0.5 * Math.PI);
      ctx.textAlign = "center";
      ctx.font = "30px Helvetica";
      ctx.fillStyle = "#dbe5fe";
      ctx.fillText(text, 0, -radius - toolTipArrowHeight - (toolTipHeight / 4));
    }

    speedometer(0.72, "");
  }

  meterCount(p) {
    return Math.round(p * 180 / 100) - 45;
  }
  loadCardLayout() {
    // CardLayout[] = localStorage.getItem("CardLayout");
    this.api.get("api/user/me", true).subscribe(
      (res: any) => {
        if (res.body.data.widgets_dashboard) {
          localStorage.setItem(
            "CardLayout",
            res.body.data.widgets_dashboard
          );
          //this.cardLayout = res.body.data.widgets_backoffice;
        }

        let CardLayoutarr = JSON.parse(
          localStorage.getItem("CardLayout")
        );
        if (CardLayoutarr == undefined) {
          let Connectivity: CardLayout = { cardName: "Connectivity Status", cardKey: 1 };
          let OTA: CardLayout = { cardName: "OTA Revenue", cardKey: 2 };
          let Reservations: CardLayout = { cardName: "Reservations", cardKey: 3 };
          let Today: CardLayout = { cardName: "Todays Weather", cardKey: 4 };
          let Front: CardLayout = { cardName: "Front Desk", cardKey: 5 };
          let Notifications: CardLayout = { cardName: "Notifications", cardKey: 6 };


          let layoutList: CardLayout[] = [];

          layoutList.push(Connectivity);
          layoutList.push(OTA);
          layoutList.push(Reservations);
          layoutList.push(Today);
          layoutList.push(Front);
          layoutList.push(Notifications);


          this.cardLayout = layoutList;
          localStorage.setItem(
            "CardLayout",
            JSON.stringify(this.cardLayout)
          );
        } else {
          console.log(CardLayoutarr);
          this.cardLayout = CardLayoutarr;
        }
      },
      (err) => this.error_handling.handle_error(err.status)
    );
    // this.addEventsToCloseButton();
  }

  checkLayoutChanges() {
    // console.clear();
    // console.log(this.cardLayout);
    // let pendingUpdate = JSON.parse(localStorage.getItem('pendingLayoutUpdate'))

    // if (pendingUpdate) {
    //   let dropped = pendingUpdate.dropped;
    //   let dropper = pendingUpdate.dropper;

    //   this.cardLayout.forEach(element => {

    //     if (element.cardName == dropped.value) {
    //       element.cardKey = dropper.key;
    //     }

    //     if (element.cardName == dropper.value) {
    //       element.cardKey = dropped.key;
    //     }
    //   });

    // localStorage.setItem('CardLayout', JSON.stringify(this.cardLayout))

    this.api.post('/api/user/sorting',
      {
        type: 'Dashboard',
        data: JSON.stringify(this.cardLayout)
      }, true)
      .subscribe((res: any) => {
        console.log(res)
        localStorage.removeItem('pendingLayoutUpdate')
      }, err => this.error_handling.handle_error(err.status, err.message));

  }

  getCardKey(cardName) {
    return this.cardLayout.find((x) => x.cardName === cardName).cardKey;
    //  let key = this.cardLayout.find(x => x.cardName == cardName)[0].Key
  }



}
