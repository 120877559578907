import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from "@angular/forms";
import { HelperService } from "../../services/helper.service";
import { Router } from "@angular/router";
import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
import { MatSlideToggleChange, MatSlideToggle, MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
declare var $: any;
@Component({
  selector: 'app-manage-otas',
  templateUrl: './manage-otas.component.html',
  styleUrls: ['./manage-otas.component.css'],
  providers: [
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { disableToggleValue: false, disableDragValue: true } },
  ]
})
export class ManageOtasComponent implements OnInit {
  // @Output()
  // dragChange: EventEmitter<void>
  // @Input()
  // checked: boolean;

  @Output()
  change: EventEmitter<MatSlideToggleChange>
  internal_channels;
  external_channels;
  awaiting_channels;
  id;
  settings;
  accent;
  col;
  pd;
  isModelShow;
  manage_otas;
  currency;
  submitted = false;
  class: boolean = true;
  opac: number = 3;
  rate;
  username;
  hotel_code;
  password;
  channel_id;
  all_data;
  updateData;
  default_currency;
  currency_from;
  currency_to;
  checked: boolean;
  base_url;
  settings_form: FormGroup;
  settings_update: FormGroup;
  add_setup_ota: FormGroup
  disabled = true;
  user_name;
  hotelcode;
  pass_word;
  name;
  website;
  commission;
  type;
  selectMe = 1;
  dashboard: any;
  darkMode: boolean = false;
  pricing_data;
  pricing_model: any;
  OTA_skeleton: boolean = true;


  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public helper: HelperService,
    public fb: FormBuilder,
    public router: Router,
    public confirm: ConfirmationAlertService
  ) {
    this.base_url = this.api.base_url;
  }
  ngOnInit() {
    console.log(this.helper.selectMe, "selectMe");

    this.settings_form = this.fb.group({
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'channel_id': ['', Validators.required],
      'pricing_model': ['', Validators.required]
    });
    this.settings_update = this.fb.group({
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'channel_id': ['', Validators.required],
      'currency_from': ['', Validators.required],
      'default_currency': ['', Validators.required],
      'currency_to': ['', Validators.required],
      'status': '',
      'pricing_model': ['', Validators.required]
    });
    this.add_setup_ota = this.fb.group({
      'name': ['', Validators.required],
      'website': ['', Validators.required],
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'type': '',
      'commission': '',
      'pricing_model': ['', Validators.required]
    })
    this.checkDarkMode();
    this.get_channels();
    this.get_currency();
    this.pricing_dataa();
    $(document).ready(() => {
      $('.modal').on('hidden.bs.modal', () => {
        this.add_setup_ota.reset()
      });
      $(".dd").draggable({
        revert: true,
        opacity: 1,
        zIndex: 10000
      });
      $(".main").droppable({
        accept: '.dd',
        drop: function (event, ui) {
          if ($(this).children().length > 0) {
            var move = $(this).children().detach();
            $(ui.draggable).parent().append(move);
          }
          $(this).append($(ui.draggable));
        }
      });
    });

    $(function () {
      $(".modal").mouseup(function () {
        $(".alert-info").css({ "cursor": "grab" })
      });
      $(".modal").mousedown(function () {
        $(".alert-info").css({ "cursor": "grabbing" })
      });

      $('#add_setup_ota').draggable();
      $('#add_setup_ota').draggable("disable")
    });

  }
  enable = new FormControl();

  onDragChange(id) {
    $("#ck_" + id).click();
  }

  get f() { return this.settings_form.controls; }
  get s() { return this.add_setup_ota.controls; }


  pricing_dataa() {
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/properties_channel/pricing', { property_id: property_id }, true).subscribe((res: any) => {

      this.pricing_data = res.body.data;
      if (this.pricing_data == 'occupancy') {
        this.pricing_model = 'OccupancyBasedPricing';

      }
      else {
        this.pricing_model = 'PerDayPricing';
      }
      console.log(this.pricing_data);
      // this.helper.alert_success('Arsalan !');
      // this.get_channels();
      // $('#settings').modal('hide');
    }, err => this.error_handling.handle_error(err.status));
  }


  get_channels() {
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/channels/index?per_page=50', { property_id: property_id }, true).subscribe((res: any) => {
      this.all_data = res.body.data;
      //External
      this.external_channels = res.body.data.filter(obj => {
        return obj.type === 'External' && obj.connected === true
      });
      this.external_channels.reverse();
      //Internal
      this.internal_channels = res.body.data.filter(obj => {
        return (obj.type === 'Internal' || obj.type === null) && obj.connected === true
      });
      this.internal_channels.reverse();
      //Awaiting
      this.awaiting_channels = res.body.data.filter(obj => {
        return obj.connected === false
      });
      this.OTA_skeleton = false;
    }, err => this.error_handling.handle_error(err.status, err.message));

  }

  get_currency() {
    this.api.get('api/helper/currency', true).subscribe((res: any) => {
      this.currency = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  onChange(ob: MatSlideToggleChange) {

  }

  div_clicked(id) {
    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });
  }

  toggle_change(event) {
    let e = event.event;
    let val = event.value;
    let id = event.id;
    console.log(event, "event ota setup");

    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });
    this.channel_id = id;
    if (e.checked == false && this.settings.property_channel_id != false) {
      this.ota_setting_modal({ id: this.settings.property_channel_id, connected: e.checked });
    }
    else if (val == false) {
      $('#settings').modal({ backdrop: 'static', keyboard: false });
      $('#settings').draggable();
      $('#settings').draggable("disable")
    }
    else if (val == true) {
      $(document).ready(function () {
        $("#confirm_modal").modal('show');
      });
      let that = this;
      this.confirm.confirm(
        "Disable Channel (" + this.settings.name + ")",
        'Are your sure you want to DISABLE this CHANNEL?',
        ['This action can be undone', 'You can ENABLE your channel at anytime'],
        function () {
          that.api.post('api/properties_channel/update/' + that.settings.property_channel_id, { 'disabled_by': 'User', 'status': 'Disabled' }, true).subscribe((res: any) => {
            that.helper.alert_success('Channel has been Disabled successfully !');
          }, err => this.error_handling.handle_error(err.status, err.message));
        },
        '',
        ''
      );
    }
  }

  mat_change(e, val, id) {
    e.source.checked = (val == 'Active' ? true : false);
    e.checked = val;
    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });
    this.channel_id = id;
    if (val == 'Active') {
      this.api.post('api/properties_channel/update/' + this.settings.property_channel_id, { 'status': 'Disabled' }, true).subscribe((res: any) => {
        this.helper.alert_success('Channel has been Disabled successfully !');
        this.get_channels();
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
    if (val == 'Disabled') {
      this.api.post('api/properties_channel/update/' + this.settings.property_channel_id, { 'status': 'Active' }, true).subscribe((res: any) => {
        this.helper.alert_success('Channel has been Enabled successfully !');
        this.get_channels();
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  ota_setting_modal(event) {
    let id = event.id;
    let connected = event.connected;

    if (id !== false) {
      this.api.get('api/properties_channel/show/' + id, true).subscribe((res: any) => {
        this.updateData = res.body.data;
        console.log(this.updateData)
        this.settings_update.patchValue({
          property_id: this.updateData.property_id,
          user_id: this.updateData.user_id,
          rate: this.updateData.rate,
          username: this.updateData.username,
          hotel_code: this.updateData.hotel_code,
          password: this.updateData.password,
          channel_id: this.updateData.channel_id,
          status: this.updateData.status,
        });
        this.default_currency = this.updateData.default_currency;
        this.currency_from = this.updateData.currency_from;
        this.currency_to = this.updateData.currency_to;
      }, err => this.error_handling.handle_error(err.status));

      $('#ota_settings').modal({ backdrop: 'static', keyboard: false });
      $('#ota_settings').draggable();
      $('#ota_settings').draggable("disable")
    }
  }















  updateSettings() {
    console.log(this.settings_update.value)

    var data = JSON.parse(JSON.stringify(this.settings_update.value));
    var a = $(".channel_status_" + this.updateData.id);

    var status;
    if (a[0].value == "true" || a[0].value == "on") {
      if (this.updateData.status == 'Active') {
        status = 'Active';
      }
      else {
        status = 'Awaiting';
      }
    }
    else if (a[1].value == "true" || a[1].value == "on") {
      status = 'Disabled';
    }
    data['status'] = status;
    this.api.post('api/properties_channel/update/' + this.updateData.id, data, true).subscribe((res: any) => {
      this.helper.alert_success('Changes has been saved successfully !');
      this.get_channels();
    }, err => this.error_handling.handle_error(err.status));
  }

  onSubmit() {
    var property_id = localStorage.getItem("current_property");
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    this.settings_form.patchValue({
      property_id: property_id,
      user_id: user_id,
      rate: this.rate,
      username: this.username,
      hotel_code: this.hotel_code,
      password: this.password,
      channel_id: this.channel_id,
      pricing_model: this.pricing_model
    });
    this.submitted = true;
    if (this.settings_form.invalid) {
      Object.keys(this.settings_form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.settings_form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
          });
        }
      });
      return;
    }

    this.api.post('api/properties_channel/store', this.settings_form.value, true).subscribe((res: any) => {
      this.helper.alert_success('Channel butler has completed setup !');
      this.get_channels();
      $('#settings').modal('hide');
    }, err => this.error_handling.handle_error(err.status));
  }

  onSetup() {

    if (
      this.add_setup_ota.value.username !== null && this.add_setup_ota.value.username !== "" &&
      this.add_setup_ota.value.username !== undefined &&

      this.add_setup_ota.value.password !== null && this.add_setup_ota.value.password !== "" &&
      this.add_setup_ota.value.password !== undefined &&

      this.add_setup_ota.value.hotel_code !== null &&
      this.add_setup_ota.value.hotel_code !== undefined && this.add_setup_ota.value.hotel_code !== "" &&

      this.add_setup_ota.value.name !== undefined && this.add_setup_ota.value.name !== null &&
      this.add_setup_ota.value.name !== undefined &&

      this.add_setup_ota.value.website !== null &&
      this.add_setup_ota.value.website !== undefined && this.add_setup_ota.value.website !== "" &&

      this.add_setup_ota.value.pricing_model !== null &&
      this.add_setup_ota.value.pricing_model !== undefined && this.add_setup_ota.value.pricing_model !== "") {

      var property_id = localStorage.getItem("current_property");
      let user_id = JSON.parse(localStorage.getItem('user')).id;
      this.add_setup_ota.patchValue({
        property_id: property_id,
        user_id: user_id,
        type: "Custom",
        commission: 0
      });
      this.submitted = true;
      console.log(this.add_setup_ota.value);
      this.api.post('api/channels/store', this.add_setup_ota.value, true).subscribe((res: any) => {
        this.helper.alert_success('Successfully Added OTA!');
        this.get_channels();
        $('#add_setup_ota').modal('hide');
        this.add_setup_ota.reset();
      }, err => this.error_handling.handle_error(err.status));

    } else {
      this.helper.alert_error('Please fill in the required fields to connect!');
    }
  }

  activeMultiplier() {
    if (this.settings_form.value.username !== null && this.settings_form.value.username !== "" &&
      this.settings_form.value.username !== undefined && this.settings_form.value.password !== null && this.settings_form.value.password !== "" &&
      this.settings_form.value.password !== undefined && this.settings_form.value.hotel_code !== null &&
      this.settings_form.value.hotel_code !== undefined && this.settings_form.value.hotel_code !== "" &&
      this.settings_form.value.pricing_model !== undefined && this.settings_form.value.pricing_model !== ""
    ) {
      $("#multiplierli").click();
    }
    else {
      // console.log(this.pricing_model);
      this.helper.alert_error('Please fill in the required fields to proceed to next step !');
    }
    // this.onSubmit();
  }

  activePreview() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#previewli").click();
  }

  activeChannel() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#setupchannelli").click();
  }

  activePreviewSetup() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#preview_li").click();
  }

  activeChannelSetup() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#setupchannel_li").click();
  }

  activeSetupMultiplier() {
    if (
      this.add_setup_ota.value.username !== null && this.add_setup_ota.value.username !== "" &&
      this.add_setup_ota.value.username !== undefined &&

      this.add_setup_ota.value.password !== null && this.add_setup_ota.value.password !== "" &&
      this.add_setup_ota.value.password !== undefined &&

      this.add_setup_ota.value.hotel_code !== null &&
      this.add_setup_ota.value.hotel_code !== undefined && this.add_setup_ota.value.hotel_code !== "" &&

      this.add_setup_ota.value.name !== undefined && this.add_setup_ota.value.name !== null &&
      this.add_setup_ota.value.name !== undefined &&

      this.add_setup_ota.value.website !== null &&
      this.add_setup_ota.value.website !== undefined && this.add_setup_ota.value.website !== "") {
      $("#multiplier_li").click();
    }
    else {
      console.log(this.pricing_model);
      // this.helper.alert_error('Please fill in the required fields to proceed to next step !');
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  dodrag1() {
    $('#add_setup_ota').draggable("enable")
  }

  stopDrag1() {
    $('#add_setup_ota').draggable("disable")
  }

  dodrag2() {
    $('#settings').draggable("enable")
  }

  stopDrag2() {
    $('#settings').draggable("disable")
  }

  dodrag3() {
    $('#ota_settings').draggable("enable")
  }

  stopDrag3() {
    $('#ota_settings').draggable("disable")
  }


  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }



}
